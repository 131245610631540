import { FrameEnd } from './frame-end';
import { WagonLocation } from './wagon-location';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_EXTRCHA */
export class WagonFrameEnd {
     /** WEXTCH_WCAR_MEX_PFK VARCHAR2(14 BYTE) */
     mex: string;
     /** inventory.code = WEXTCH_WCAR_INV_CODE_PFK VARCHAR2(2 BYTE) */
     inventory: Inventory;
    /** frameEnd.code = WEXTCH_EXTCH_CODE_PFK VARCHAR2(1 BYTE) */
    frameEnd: FrameEnd;
    /** location.code = WEXTCH_EMPL_CODE_FK VARCHAR2(3 BYTE) */
    location: WagonLocation;
    /** WEXTCH_NBRE NUMBER(3,0) */
    amount: number;
}
