import { OverhaulFrequency } from './overhaul-frequency';
import { OverhaulPlateUpdate } from './overhaul-plate-update';
import { Workshop } from './workshop';

export class CartridgeATS {

  overhaulPlateUpdate: OverhaulPlateUpdate;

  unexpectedFrequency: OverhaulFrequency;

  rankOther: number;

  overhaulNatureCodeOther: string;

  periodicityATS: string;

  establishmentATS: Workshop;

  dateATS: Date;
}
