import { Injectable, ElementRef, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { ActionBarConfig } from '../../components/action-bar/action-bar-config';
import { ControlBarConfig } from '../../components/control-bar/control-bar-config';

/**
 * This service provide ability for components to retrieve & pass pages'specific configuration object for Action Bar and Control bar.
 */
@Injectable({
  providedIn: 'root'
})
export class BarConfigService {
  /** Subject for action bar config monitoring */
  private actionSubject = new BehaviorSubject<ActionBarConfig>(undefined);
  /** Subject for control bar config monitoring */
  private controlSubject = new BehaviorSubject<ControlBarConfig>(undefined);
  /** Array of button elements */
  public actionButtons: ElementRef<HTMLButtonElement>[];
  /** Event emitter for when selected tab changes */
  @Output() selectedTabChanged = new EventEmitter();
  /** `BarConfigService` constructor */
  constructor() {}
  // TODO : .complete() somewhere on BehaviorSubject
  /**
   * used by ActionBarComponent to share action buttons ElementRef, usefull for overlay positioning
   * @param actionButtons action button ElementRef
   */
  setActionsRef(actionButtons: ElementRef<HTMLButtonElement>[]): void {
    this.actionButtons = actionButtons;
  }
  /**
   * Send a new action bar configuration
   * @param conf action bar configuration object
   */
  sendActionBarConfig(conf: ActionBarConfig) {
    this.actionSubject.next(conf);
  }
  /**
   * Retrieve actual action bar config
   * @returns return actual action bar configuration object + injection context as Observable
   */
  getActionBarConfig(): Observable<ActionBarConfig> {
    return this.actionSubject.asObservable();
  }
  /**
   * Send a new control bar configuration
   * @param conf control bar configuration object
   */
  sendControlBarConfig(conf: ControlBarConfig) {
    this.controlSubject.next(conf);
  }
  /**
   * Retrieve actual control bar config
   * @returns return actual `ControlBarConfig` as Observable
   */
  getControlBarConfig(): Observable<ControlBarConfig> {
    return this.controlSubject.asObservable();
  }
  /**
   * Send simultaneously action bar & control bar configurations
   * @param action action bar configuration object + injection context
   * @param ctrl control bar configuration object
   */
  sendConfig(action: ActionBarConfig, ctrl: ControlBarConfig) {
    this.actionSubject.next(action);
    this.controlSubject.next(ctrl);
  }
  /** Clear action bar & control bar configuration, use this when navigate away from component who sets configuration */
  clearConfig() {
      this.actionSubject.next(undefined);
      this.controlSubject.next(undefined);
  }
}
