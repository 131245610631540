import { Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CamsDefault } from 'src/app/shared/models/cams-default';
import { WagonUpdate } from 'src/app/shared/models/wagon-update';
import { MonitoringWorkService } from 'src/app/shared/services/monitoring-work/monitoring-work.service';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TemplatePopupDialogComponent } from '../../template-popup-dialog/template-popup-dialog.component';
import { CamLinkedTaskDialogComponent } from '../monitoring-works/cam-linked-task-dialog/cam-linked-task-dialog.component';


@Component({
  selector: 'app-cams-faults-table',
  templateUrl: './cams-faults-table.component.html',
  styleUrls: ['./cams-faults-table.component.scss']
})
export class CamsFaultsTableComponent implements OnInit, OnDestroy {

  @Input() public wagonId: number;
  @Input() public isMonitoringWork: boolean;
  @Input() public toDoColor: string;
  @Input() public realizedColor: string;
  @Input() public camFaultList: CamsDefault[];
  /** Update datas */
  @Input() public update: WagonUpdate;
  @Output() maintenancePlanTableUpdated = new EventEmitter<boolean>();
  @Output() camFaultsTableUpdated = new EventEmitter<boolean>();
  /** get the Material sort component ref  for cams defaults*/
  @ViewChild('camsTableSort', { static: true }) camsSort: MatSort;
  /** Create an empty Material MatTable datasource of CamsDefauts */
  dataSourceCamsDefaults = new MatTableDataSource<CamsDefault>();
  displayedColumnsCamsDefaults = [
    { def: 'type', hide: false },
    { def: 'code', hide: false },
    { def: 'label', hide: false },
    { def: 'origin', hide: false },
    { def: 'state', hide: false },
    { def: 'edition', hide: false }
  ];

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  updatingSubject = new BehaviorSubject<boolean>(false);
  /** CAMS et C.U.U.S */
  listCAMSDefault: CamsDefault[];

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private overlay: Overlay,
    private referenceService: ReferenceService,
    private monitoringWorkService: MonitoringWorkService) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.camFaultList && !changes.camFaultList.firstChange) {
      this.ngOnInit();
    }
  }
  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.loadInitDatas();
  }

  getDisplayedColumns(): string[] {
    this.displayedColumnsCamsDefaults[3].hide = !this.isMonitoringWork;
    this.displayedColumnsCamsDefaults[4].hide = !this.isMonitoringWork;
    this.displayedColumnsCamsDefaults[5].hide = !this.isMonitoringWork;
    return this.displayedColumnsCamsDefaults
      .filter(cd => !cd.hide)
      .map(cd => cd.def);
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
    this.loadingSubject.complete();
  }

  /**
   * Methode pour init datas 
   */
  private loadInitDatas() {
    this.listCAMSDefault = this.camFaultList.filter( e => this.isMonitoringWork || (['I', 'IE'].includes(e.origin) && e.type ==='CAM' 
                                                                                || ['I', 'IC'].includes(e.origin) && e.type ==='C.U.U'));
    this.dataSourceCamsDefaults.data = this.listCAMSDefault;
    this.setCAMDefautChecked();
    this.loadingSubject.next(false);
    
  }


  /**
  * Methode pour init datas 
  */
  public filterCamsDefaults(filterStatusToDO: boolean, filterStatusRealized: boolean): void {
    this.loadingSubject.next(true);
    this.listCAMSDefault = this.camFaultList.filter(e => 
        (!this.isMonitoringWork && (['I', 'IE'].includes(e.origin) && e.type ==='CAM' 
                                || ['I', 'IC'].includes(e.origin) && e.type ==='C.U.U'))
      || (this.isMonitoringWork && (
          (!filterStatusToDO && !filterStatusRealized && (['I', 'IE', 'E'].includes(e.origin) && e.type ==='CAM' 
                                                      || ['I', 'IC', 'C'].includes(e.origin) && e.type ==='C.U.U'))
          ||
          (filterStatusToDO && e.origin === 'I')
          ||
          (filterStatusRealized && (['IE', 'E'].includes(e.origin) && e.type ==='CAM' 
                                || ['IC', 'C'].includes(e.origin) && e.type ==='C.U.U'))

        ))
      );
    this.dataSourceCamsDefaults.data = this.listCAMSDefault;
    this.setCAMDefautChecked();
    this.loadingSubject.next(false);
  }

  /**
  * Methode pour supprimer un camDefault
  * @param mpu : CamsDefault
  */
  deleteCamsDefaults(cam: CamsDefault): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '38.125rem';
    const title = this.translateService.instant('wagon-update.preparation-work.cams_defaults.delete.title');
    const message_cam = this.translateService.instant('wagon-update.preparation-work.cams_defaults.delete.msg_cam', { code: cam.code, label: cam.label });
    const message_cuu = this.translateService.instant('wagon-update.preparation-work.cams_defaults.delete.msg_cuu', { code: cam.code, label: cam.label });

    dialogConfig.data = {
      namePopUp: 'delete_task',
      titlePopUp: title,
      msgPopUp: cam.type === 'CAM' ? message_cam : message_cuu
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.subscriptionRefs.push(
          this.referenceService.deleteCamsDefaults(this.wagonId, cam.code, cam.type)
            .subscribe(response => {
              if (response.data != undefined) {
                this.showAlertMessage();
              } else {
                this.camFaultsTableUpdated.emit(true);
                this.dialog.closeAll();
              }
            }, (error) => console.log(error))
        );
      }
    });
  }

  showAlertMessage(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';
    const message = this.translateService.instant('wagon-update.preparation-work.cams_defaults.delete.msg_igl_wagon');
    dialogConfig.data = {
      namePopUp: 'alert_msg',
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      this.dialog.closeAll();
    });

  }


  /**
   * Methode pour faire la mise a jour des CAM/CUU
   * @param event
   * @param state
   */
  updateCAMDefault(event: Event, index: number, type: string, code: string, state: string): void {
    this.updatingSubject.next(true);
    event.preventDefault();
    event.stopPropagation();
    switch (type) {
      case 'CAM': {
        this.dataSourceCamsDefaults.data[index].checked = !this.dataSourceCamsDefaults.data[index].checked;
        let status: string;
        if (state === 'I') {
          status = 'IE';
        } else if (state === 'IE') {
          status = 'I';
        }
        //Contrôle des données
        if (status === 'IE') { //Cas 1 : Modification d’un CAM d’un état « A faire » à un état « Réalisé 
          /* operationType = 0 --> Check to create CAM
             operationType = 1 --> Check to update CAM*/
          const operationType = 1;
          this.subscriptionRefs.push(
            this.monitoringWorkService.checkCAM(this.update.wagonId, code, operationType)
              .subscribe((msg => {
                let message;
                if (msg.data.message === null) {
                  this.subscriptionRefs.push(
                    this.monitoringWorkService.updateCAM(this.update.wagonId, code, status)
                      .subscribe((cam => {
                        const iglCam = cam.data;
                        if (!iglCam) {
                          message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.add_line.msg_error_unknown_wagon');
                          this.showAlertMessageCAMDef(message, true);
                        } else {
                          this.updateDone();
                        }
                        this.updatingSubject.next(false);
                      }))
                  );
                } else if (msg.data.message === 'CAM_ERROR_2') {
                  message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.add_line.msg_error_cam_2');
                  this.showAlertMessageCAMDef(message, false);
                  this.updatingSubject.next(false);
                } else {
                  //ajouter une tache
                  this.showAddTaskDialog(this.update.wagonId, code, msg.data.message, 'MODIFY_CAM');
                  this.updatingSubject.next(false);
                }

              }))
          );

        } else { //Cas 2 : Modification d’un CAM d’un état « Réalisé » à un état « A faire »
          this.subscriptionRefs.push(
            this.monitoringWorkService.updateCAM(this.update.wagonId, code, status)
              .subscribe((cam => {
                const iglCam = cam.data;
                if (!iglCam) {
                  const message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.add_line.msg_error_unknown_wagon');
                  this.showAlertMessageCAMDef(message, true);
                } else {
                  this.updateDone();
                }
                this.updatingSubject.next(false);
              }))
          );
        }
        break;
      }
      case 'C.U.U': {
        this.dataSourceCamsDefaults.data[index].checked = !this.dataSourceCamsDefaults.data[index].checked;
        let status: string;
        if (state === 'I') {
          status = 'IC';
        } else if (state === 'IC') {
          status = 'I';
        }
        this.subscriptionRefs.push(
          this.monitoringWorkService.updateDefault(this.update.wagonId, code, status)
            .subscribe((def => {
              const iglDef = def.data;
              if (!iglDef) {
                const message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.add_line.msg_error_unknown_wagon');
                this.showAlertMessageCAMDef(message, true);
              } else {
                this.updateDone();
              }
              this.updatingSubject.next(false);
            }))
        );
        break;
      }
      default: {
        this.updatingSubject.next(false);
        break;
      }
    }
  }

  /**
   * Show Add task dialog.
   * @param cam code
   * @param typeCase if (CASE 1 or CASE 2) the type is 'add CAM' (CASE 3 or CASE 4) the type is 'modify CAM'
   */
  showAddTaskDialog(currentWagon: number, cams: string, case_number: string, typeCase: string): void {
    const scrollStrat = this.overlay.scrollStrategies.reposition();
    const dialogRef = this.dialog.open(CamLinkedTaskDialogComponent
      , {
        data: { wagon: currentWagon, cam: cams, caseNumber: case_number, caseType: typeCase },
        disableClose: true,
        scrollStrategy: scrollStrat
      });
    dialogRef.afterClosed().subscribe(confirm => {
      if(confirm) {
        this.maintenancePlanTableUpdated.emit(true);
        this.camFaultsTableUpdated.emit(true);
      }
      if(confirm===false) {
        this.camFaultsTableUpdated.emit(true);
      }
    });

  }

  /**
   * Init if the CAM/def is checked or not
   */
  setCAMDefautChecked(): void {
    if (this.dataSourceCamsDefaults && this.dataSourceCamsDefaults.data && this.dataSourceCamsDefaults.data.length > 0){
      this.dataSourceCamsDefaults.data.forEach(camDefault => {
        if (camDefault.origin === 'I') {
          camDefault.checked = true;
        } else {
          camDefault.checked = false;
        }
      });
    }
  }

  /**
   * Show Alert message of CAM / Defaut
   * @param msg
   */
  private showAlertMessageCAMDef(msg: any, saveData?: boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';

    dialogConfig.data = {
      namePopUp: 'alert_msg',
      msgPopUp: msg
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      if (saveData === true) {
        this.updateDone();
      } else {
        this.dialog.closeAll();
      }
    });
  }

  /**
   * Le message se ferme quand 5 seconds sont pasé
   */
  updateDone(): void {
    this.maintenancePlanTableUpdated.emit(true);
    this.camFaultsTableUpdated.emit(true);
   
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const title = this.translateService.instant('wagon-update.task.update-done.msg');
    const message = this.translateService.instant('wagon-update.task.update-done.msg');
    const timeout = UtilityService.UPDATE_DONE_TIMEOUT;
    dialogConfig.data = {
      namePopUp: 'update-done-alert',
      titlePopUp: title,
      msgPopUp: message
    };
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
        this.dialog.closeAll();
      }, timeout)
    });
  }

}
