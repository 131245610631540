import { ErrorHandler } from '@angular/core';

import { UncaugthErrorHandler } from './uncaugth-error.handler';
import { environment } from '../../../../environments/environment';

/** Error handler provider for `CoreComponent`  */
const errorHandlerProviders = [];

// Le gestionnaire d'erreur est activé seulement en production
if (environment.production) {
  errorHandlerProviders.push({
    provide: ErrorHandler,
    useClass: UncaugthErrorHandler
  });
}
/**
 * Error handler provider configuration for `CoreModule`
 */
export { errorHandlerProviders };
