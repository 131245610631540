import { Component, Input, AfterContentInit, ContentChild, ViewEncapsulation,
  HostListener, ElementRef, Renderer2  } from '@angular/core';
import { InputDirective } from './input.directive';

export interface DropdownOption {
  label: string;
  value: string | number | boolean;
}
export interface DropdownAddonConfig {
  placeholder: string;
  items: DropdownOption[];
  callback: (selectedValue: string | number | boolean) => void;
  position?: 'right' | 'left';
}
export interface ButtonAddonConfig {
  /** Icon name (eg.material icons) */
  icon?: string;
  /** Icon class to apply to i tag */
  icon_class?: string;
  callback: ($e: Event) => void;
}
export interface InputConfig {
  small?: boolean;
  toolbar?: {};
  icon?: {
    position: 'left' | 'right' | '';
    /** Icon name (eg.material icons) */
    name?: string;
    /** Icon class to apply to i tag */
    class?: string;
  };
  append?: {
    type: 'button' | 'dropdown';
    config: ButtonAddonConfig | DropdownAddonConfig;
  };
  prepend?: {
    type: 'button' | 'dropdown';
    config: ButtonAddonConfig | DropdownAddonConfig;
  };
}

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormInputComponent implements AfterContentInit {
  @Input() config: InputConfig;
  @ContentChild(InputDirective) controller: InputDirective;
  altInput: HTMLInputElement;

  pretouched = false;
  constructor(private elRef: ElementRef, private renderer: Renderer2) { }

  @HostListener('document:click', ['$event.target']) onClick(el) {
    // Picker Only : Simulate normal behavior for pickr input since it is never really touch , only altInput is
    if (this.altInput && this.elRef.nativeElement.contains(el)) {
      this.pretouched = true;
    }
    if (this.altInput && this.pretouched && !this.elRef.nativeElement.contains(el)) {
      this.controller.setTouched();
    }
  }

  ngAfterContentInit() {
    if (this.config?.small) {
      this.controller.addClass('form-control-sm');
    }
    const label = ((this.elRef.nativeElement).querySelector('label') as HTMLElement);
    if (label) {
      if (this.controller.required !== undefined) {
        this.renderer.addClass(label, 'required');
      }
      this.renderer.setAttribute(label, 'for', this.controller.id);
    }
    const clearbtn = ((this.elRef.nativeElement).querySelector('.btn-clear') as HTMLButtonElement);
    if (clearbtn) {
      this.renderer.addClass(clearbtn, 'btn-primary');
      const span = this.renderer.createElement('span');
      this.renderer.addClass(span, 'sr-only');
      const text = this.renderer.createText('Clear text');
      this.renderer.appendChild(span, text);
      const i = this.renderer.createElement('i');
      this.renderer.addClass(i, 'icons-close');
      this.renderer.appendChild(clearbtn, span);
      this.renderer.appendChild(clearbtn, i);
      this.controller.addClass('clear-option');
      clearbtn.addEventListener('click', ($event) => {
        $event.stopPropagation();
        this.controller.setTouched();
        this.controller.setValue(null);
      });
    }
    const helper = ((this.elRef.nativeElement).querySelector('.helper-group') as HTMLElement);
    if (helper) {
      if (helper.id) {
        this.controller.addAttribute('ariaDescribedby', helper.id);
      }
      this.renderer.addClass(helper, 'form-text');
      this.renderer.addClass(helper, 'text-muted');
    }
    this.altInput = ((this.elRef.nativeElement ).querySelector('.flatpickr-input.input') as HTMLInputElement);
    if (this.altInput && !this.controller.ngControl) {
      // needed for validation perform on native elem
      this.controller.setPickerInput();
    }
    const counter = ((this.elRef.nativeElement).querySelector('.counter-group') as HTMLElement);
    if (counter) {
      this.renderer.addClass(counter, 'mt-2');
      this.renderer.addClass(counter, 'font-weight-medium');
      this.renderer.setAttribute(counter, 'data-role', 'counter');
    }
  }
}
