import { Directive, ElementRef, HostBinding, Self, Optional, HostListener, Input, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInput]'
})
export class InputDirective {

  @HostBinding('class.form-control') formcontrolClass = true;

  @Input() required;
  @Input() id: string;

  nativeDirty = false;
  nativeTouched = false;

  constructor(
    private elRef: ElementRef, private renderer: Renderer2,
    @Self() @Optional() public ngControl: NgControl
  ) { }

  @HostListener('input', ['$event']) onInput(event: Event) {
    this.nativeDirty = true;
    if (this.ngControl && this.ngControl.pristine) {
      this.ngControl.control.markAsDirty();
    }
  }
  @HostListener('blur', ['$event']) onBlur(event: Event) {
    this.nativeTouched = true;
  }

  setPickerInput() {
    this.renderer.setAttribute(this.elRef.nativeElement, 'type', 'text');
    this.renderer.setStyle(this.elRef.nativeElement, 'display', 'none');
    // fix IE11 initial validitity where :valid is true even when required cause value is populated by placeholder
    // set value = null where value === placeholder
    if (this.elRef.nativeElement.value === this.elRef.nativeElement.placeholder) {
      this.elRef.nativeElement.value = null;
    }
  }

  addClass(newClass: string) {
    this.renderer.addClass(this.elRef.nativeElement, newClass);
  }

  addAttribute(newAttr: string, val: string) {
    this.renderer.setAttribute(this.elRef.nativeElement, newAttr, val);
  }

  isValid(): boolean {
    return this.ngControl ? this.ngControl.status === 'VALID'
                          : this.elRef.nativeElement.validity.valid;
  }

  isDirty(): boolean {
    return this.ngControl ? this.ngControl.dirty
                          : this.nativeDirty;
  }

  isTouched(): boolean {
    return this.ngControl ? this.ngControl.touched
    : this.nativeTouched;
  }

  setTouched(): void {
    if ( this.ngControl && this.ngControl.dirty && this.ngControl.untouched) {
      this.ngControl.control.markAsTouched();
    } else if (!this.ngControl && this.nativeDirty && !this.nativeTouched) {
      this.nativeTouched = true;
    }
  }

  setValue(value) {
    if (this.ngControl) {
      this.ngControl.control.setValue(value);
    } else {
      this.elRef.nativeElement.value = value;
    }
  }
}
