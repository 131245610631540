import { Component, OnInit , OnDestroy} from '@angular/core';
import { ExpertiseOverview } from '../../../../shared/models/expertise-overview';
import { ExpertiseService } from '../../../../modules/consultation/expertise/services/expertise.service';
import {WagonService} from '../../../../shared/services/wagon/wagon.service';
import { Subscription, forkJoin } from 'rxjs';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { WagonUpdate } from 'src/app/shared/models/wagon-update';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-expertises',
  templateUrl: './expertises.component.html',
  styleUrls: ['./expertises.component.scss'],
})
export class ExpertisesComponent implements OnInit, OnDestroy {

    /** Retain all subscriptions */
    subscriptionRefs: Subscription[] = [];
    /** Data */
    expertisesOverviews: ExpertiseOverview[] = [];
    wagonUpdate: WagonUpdate;
    /** Value of relevant hourly cost */
    hourlyCost: number;
    /** Whether datas are still loading */
    isLoadingResults = true;
    /** browser language */
    lang: string;
    /** Data */
    results: any = [];

  constructor(
    private expertiseService: ExpertiseService,
    private wagonService: WagonService,
    private translateService: TranslateService) {
      this.lang = this.translateService.getBrowserLang().match(/en|fr/)
                ? this.translateService.getBrowserLang() : 'en';
    }

  ngOnInit() {
    this.getExpertises();
  }

  /**
   * This function compute and return quote estimation
   * @param matSum - Sum of referenced materials amounts
   * @param moSum - Sum of referenced works amounts
   * @returns Amount of quote estimation
   */
  estimate(matSum: number, moSum: number): number {
    return Number.parseFloat((matSum + moSum * this.hourlyCost).toFixed(2));
  }

  getExpertises(): void {
    const pageable = new Pageable();
    pageable.size = 3;
    pageable.sort = 'creation';
    pageable.order = 'desc';
    this.subscriptionRefs.push(
      forkJoin([
        this.expertiseService.getAllOverview(false, pageable),
        this.expertiseService.getHourlyCosts('1')
      ]).subscribe(([expertisesOverviews, hourlyCosts]) => {
        this.hourlyCost =  hourlyCosts.data.length && hourlyCosts.data[0].value || 0;
        if (expertisesOverviews.data.length !== 0) {
          expertisesOverviews.data.forEach(expertiseOverview => {
            this.wagonService.getWagonUpdate(expertiseOverview.wagonId)
            .pipe(
              map((wagonUpdate) => {
                const index = expertisesOverviews.data.indexOf(expertiseOverview);
                this.results[index] = [expertiseOverview, wagonUpdate];
              })
            ).subscribe(() => {
              this.isLoadingResults = false;
            });
          });
        } else {
          this.isLoadingResults = false;
        }

      })
    );
  }

  getQuotationAmount(expertise: ExpertiseOverview) {
    return (expertise.refMaterialSum + (expertise.refWorkSum * this.hourlyCost)).toFixed(2);
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
  }

}
