import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { Anomaly } from 'src/app/shared/models/anomaly';
import { CamsDefault } from 'src/app/shared/models/cams-default';
import { MaintenancePlanUpdate } from 'src/app/shared/models/maintenance-plan-update';
import { PreparationWorkCamsFaults } from 'src/app/shared/models/preparation-work-cams-faults';
import { WagonUpdate } from 'src/app/shared/models/wagon-update';
import { MaintenancePlanUpdateService } from 'src/app/shared/services/maintenance-plan-update/maintenance-plan-update.service';
import { PreparationWorkCamsFaultsService } from 'src/app/shared/services/preparation-work-cams-faults/preparation-work-cams-faults.service';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { TemplatePopupDialogComponent } from '../../../template-popup-dialog/template-popup-dialog.component';
import { StateJob } from 'src/app/shared/models/state-job';
import { ComponentGroup } from 'src/app/shared/models/component-group';
import { Job } from 'src/app/shared/models/job';
import { Defect } from 'src/app/shared/models/defect';
import { JobDefect } from 'src/app/shared/models/job-defect';
import { TTache } from 'src/app/shared/models/t-tache';


@Component({
  selector: 'app-cams-fault',
  templateUrl: './cams-fault.component.html',
  styleUrls: ['./cams-fault.component.scss']
})
export class CamsFaultComponent implements OnInit, OnDestroy, OnChanges { 
 
  @Input() public pMex: string;
  @Input() public pWagon: number;
  /** Update datas */
  @Input() public update: WagonUpdate;
  @Input() public maintenancePlanList: MaintenancePlanUpdate[];
  @Input() public camFaultList: CamsDefault[];  
  @Input() public taskDialogDatas: {
    stateJobs: StateJob[],
    componentGroups: ComponentGroup[],
    components: Component[],
    jobs: Job[],
    defects: Defect[],
    jobDefects: JobDefect[],
    tTaches: TTache[]
  };
  @Output() maintenancePlanTableUpdated = new EventEmitter<boolean>();
  @Output() camFaultsTableUpdated = new EventEmitter<boolean>();
  /** get the Material paginator component ref */
  @ViewChild('reformsTablePaginator', {static: true}) reformsPaginator: MatPaginator;
  @ViewChild('incidentsTablePaginator', {static: true}) incidentsPaginator: MatPaginator;
  @ViewChild('camsTablePaginator', {static: true}) camsPaginator: MatPaginator;
  @ViewChild('maintenancePlanTablePaginator', {static: true}) maintenancePlanPaginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  /** get the Material sort component ref for incidents*/
  @ViewChild('incidentsTableSort', {static: true}) incidentsSort: MatSort;
  /** get the Material sort component ref  for reforms*/
  @ViewChild('reformsTableSort', {static: true}) reformsSort: MatSort;
  /** get the Material sort component ref  for maintenance Plan*/
  @ViewChild('maintenancePlanTableSort', {static: true}) maintenancePlanSort: MatSort;
  /** get the Material sort component ref  for cams defaults*/
  @ViewChild('camsTableSort', {static: true}) camsSort: MatSort;
  /**  Historic poses*/
  //listHistoricPoses : PoseHistory[];
  /** Historic Poses avec mex du wagon */
  //listHistPosesTable : PoseHistoryTableAxle[] = [];
  /**  Historic states*/
  //listHistoricStates : AxleStateHistory[];
  /** Create an empty Material MatTable datasource of Incidents `Anomaly` */
  dataSourceIncidents = new MatTableDataSource<Anomaly>();
  /** Create an empty Material MatTable datasource of Reforms `Anomaly */
  dataSourceReforms = new MatTableDataSource<Anomaly>();
  /** Create an empty Material MatTable datasource of CamsDefauts */
  dataSourceCamsDefaults = new MatTableDataSource<CamsDefault>();
  /** Create an empty Material MatTable datasource of MaintenancePlan */
  dataSourceMaintenancePlan = new MatTableDataSource<MaintenancePlanUpdate>();

  /* Columns of Incidents table 
  displayedColumnsStates: string[] = ['date', 'code', 'label'];*/
  displayedColumnsIncidents: string[] = ['number','date', 'region','number_train','dom', 'event_sheet', 'type_origin'];
  //transformedArrayStates: any; // used in tasks list treatment before set DataSource values
  /* Columns of Reforms */
  //displayedColumnsPoses : string[] = ['number', 'position', 'date_pose','esta_pose','date_deposit','esta_deposit','pattern'];
  displayedColumnsReforms : string[] = ['number','date', 'efu','gare','model', 'type', 'organe','label','numCuu'];
  //transformedArrayPoses: any; // used in tasks list treatment before set DataSource values
  //displayedColumnsMaintenancePlan : string[] = ['group', 'component','componentLabel','job','defect','amount','edition'];
  //displayedColumnsCamsDefaults : string[] = ['type','code','label'];

  /** Preparation Work Cams Faults Datas page */
  prepWorkCamsFaultsDatasPage : PreparationWorkCamsFaults;
  /** CAM's & défaults pages */
  nextPage : boolean;
  /** langage du navigateur */
  lang : string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Subject to manage loading status Plan Maintenance */
  loadingPMSubject = new BehaviorSubject<boolean>(false);
  /** CAMs proposés */
  camOffered : string;
  /** Identified faults */
  identifiedFaults : string;
  /** CAMS et C.U.U.S */
  listCAMSDefault : CamsDefault[];
  /** RI_MAJ_002_1_1 : Si l’opération de maintenance du wagon autorise l’affichage */
  camOfferedDisplayed : boolean;

  @Output()
  startWorkBtEvent = new EventEmitter<boolean>();
  @Output()
  maintenancePlanInit= new EventEmitter<{mpu: MaintenancePlanUpdate[], cams: CamsDefault[]}>();

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private preparationWorkCamsFaultsService: PreparationWorkCamsFaultsService,
    private referenceService: ReferenceService,
    private maintenancePlanUpdateService: MaintenancePlanUpdateService) {
      this.lang = this.translateService.getBrowserLang().match(/en|fr/)
                ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.loadingPMSubject.next(true);
    this.resetDatas();
    this.loadInitDatas(); 
  }

  ngOnChanges(changes: SimpleChanges) {
    
    
    if (changes.maintenancePlanList && changes.maintenancePlanList.previousValue !== undefined) {
      this.initMaintenancePlan();
    }
  }
  /**
   * Reset les donnes 
   */
  private resetDatas(): void {
    this.nextPage = false;
    this.camOffered = '';
    this.identifiedFaults = '';
    this.camOfferedDisplayed = false;
  }

  /**
   * Methode pour init datas 
   */
  private loadInitDatas() {
    
    // Rechercher l’établissement de l’opérateur de saisie
    this.subscriptionRefs.push(
      this.preparationWorkCamsFaultsService.loadPreparationWorkCAMsAndFaultsPageDatas(this.pWagon, this.pMex)
        .subscribe(prepWorkCamsFaults => {
          this.prepWorkCamsFaultsDatasPage = prepWorkCamsFaults.data;
          if (this.prepWorkCamsFaultsDatasPage && this.prepWorkCamsFaultsDatasPage.wagonProduction && this.prepWorkCamsFaultsDatasPage.wagonProduction.opeCode){
            this.subscriptionRefs.push(
                this.referenceService.isDisplayedMaintenanceOperation(this.prepWorkCamsFaultsDatasPage.wagonProduction.opeCode)
              .subscribe((displayed) => {
                this.camOfferedDisplayed = displayed.data;
              })
            );
          }else{
            this.camOfferedDisplayed = false;
          }
          this.initNullDatas();
          this.createListCAMsOffered();
          this.createListIdentifiedFaults();
          this.dataSourceReforms.data = this.prepWorkCamsFaultsDatasPage?this.prepWorkCamsFaultsDatasPage.reforms:undefined;
          this.dataSourceIncidents.data = this.prepWorkCamsFaultsDatasPage?this.prepWorkCamsFaultsDatasPage.incidents:undefined;
          this.loadingSubject.next(false);
          this.checkInitMaintenancePlan();
      })
    );
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
    this.loadingSubject.complete();
    this.loadingPMSubject.complete();
  }

  /** Transform la liste CAMs Offered in string. Separate each occurrence with a comma */
  createListCAMsOffered(): void{
    if (this.prepWorkCamsFaultsDatasPage && this.prepWorkCamsFaultsDatasPage.cams){
      for(let i = 0; i < this.prepWorkCamsFaultsDatasPage.cams.length; i++){
        if (this.camOffered ===''){
          this.camOffered = this.prepWorkCamsFaultsDatasPage.cams[i].toString();
        }
        else{
          this.camOffered = this.camOffered + ', ' + this.prepWorkCamsFaultsDatasPage.cams[i].toString();
        }
      }
    }
  }

  /** Transform la liste IdentifiedFaults in string. Separate each occurrence with a comma */
  createListIdentifiedFaults(){
    if (this.prepWorkCamsFaultsDatasPage && this.prepWorkCamsFaultsDatasPage.faults){
      for(let i = 0; i < this.prepWorkCamsFaultsDatasPage.faults.length; i++){
        if (this.identifiedFaults ===''){
          if(this.prepWorkCamsFaultsDatasPage.faults[i].gcu){
             this.identifiedFaults = this.prepWorkCamsFaultsDatasPage.faults[i].gcu.code;
          }
        }
        else{
          if(this.prepWorkCamsFaultsDatasPage.faults[i].gcu){
            this.identifiedFaults = this.identifiedFaults + ', ' + this.prepWorkCamsFaultsDatasPage.faults[i].gcu.code;
          }
        }
      }
    }
  }

  initNullDatas(): void {
     
     if (this.prepWorkCamsFaultsDatasPage && this.prepWorkCamsFaultsDatasPage.wagonProduction){
      const wagProd = this.prepWorkCamsFaultsDatasPage.wagonProduction;
      if(!wagProd.iwagCcrevDate){
        wagProd.iwagCcrevDate = new Date();
      }
      this.prepWorkCamsFaultsDatasPage.wagonProduction = wagProd;
     }
  }

  /**Left padding a String with Zeros */
  leftpad(num:number, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  /**
   * access data in the way needed to correctly sort & filter
   * @param data - the row model
   * @param property - the column property name for searched data
   * @returns the value to display/sort on for the given column property
   */
  incidentsDataAccessor = (data: any, sortHeaderId: string) => {
    switch (sortHeaderId) {
        case 'number': 
          if (data.year && data.number ){
            return data.year + ' ' + this.leftpad(data.number, 8);
          }else{
              return '';
          }
        case 'date':
          if (data.event ){
            return data.event;
          }else{
              return '';
          }
        case 'region':
          if (data.incident &&  data.incident.region){
            return data.incident.region.label
          }else{
              return '';
          }
        case 'number_train':
          if (data.trainNumber){
            return data.trainNumber
          }else{
              return '';
          }
        case 'dom':
          if (data.incident){
            return data.incident.originDomain + data.incident.originPseudoKART;
          }else{
              return '';
          }
        case 'event_sheet':
          if (data.incident){
            return data.incident.eventSheetNumber;
          }else{
              return '';
          }
        case 'type_origin':
          if (data.incident){
            return data.incident.originType;
          }else{
              return '';
          }
        default:
            return '';
    }
  }

  /**
   * access data in the way needed to correctly sort & filter
   * @param data - the row model
   * @param property - the column property name for searched data
   * @returns the value to display/sort on for the given column property
   */
  reformsDataAccessor = (data: any, sortHeaderId: string) => {
      switch (sortHeaderId) {
          case 'number': 
            if (data.year && data.number ){
              return data.year + ' ' + this.leftpad(data.number, 8);
            }else{
                return '';
            }
          case 'date':
            if (data.event ){
              return data.event;
            }else{
                return '';
            }
          case 'efu':
            if (data.ru ){
              return data.ru.code;
            }else{
                return '';
            }
          case 'gare':
            if (data.reform && data.reform.detectStation ){
              return data.reform.detectStation.code + ' ' + data.reform.detectStation.label;
            }else{
                return '';
            }
          case 'model':
            if (data.reform && data.reform.model ){
              return data.reform.model.code + ' ' + data.reform.model.complementTagModel;
            }else{
                return '';
            }
          case 'type':
            if (data.reform && data.reform.type ){
              return data.reform.type.code + ' ' + data.reform.type.label;
            }else{
                return '';
            }
          case 'organe':
            if (data.reform && data.reform.reason ){
              return data.reform.reason.code + ' ' + data.reform.reason.label;
            }else{
                return '';
            }
              
          case 'label':
            if (data.reform){
              return data.reform.label;
            }else{
                return '';
            }
          case 'numCuu':
            if (data.gcuMediaList){
              return data.gcuMediaList.length();
            }else{
                return '0';
            }
          default:
              return '';
      }
  }

  /**
   * Pop-up Preparation Work pour imprimer : Bulletin de Suivi, Suivi des Défauts, Suivi des CAM's, Plan Maintenance
   */
  printPreparationWorkPopUp(): void{
    const dialogConfig = new MatDialogConfig();
 
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '31.25rem';
    const title = this.translateService.instant('wagon-update.preparation-work.print-popup.title'); 
    const message = this.translateService.instant('wagon-update.preparation-work.print-popup.message');

    dialogConfig.data = {
        namePopUp : 'printPreparationWork',
        titlePopUp : title,
        msgPopUp : message,
        prepWorkCamsFaultsDatas : this.prepWorkCamsFaultsDatasPage,
        mex: this.pMex,
        maintenancePlan: this.maintenancePlanList
    };

    this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
  }

  /**
   * check if the initialization of the maintenance plan can be offered to the user
   */
  checkInitMaintenancePlan():void {
    
    if (this.update && this.update.maintenanceOperation && (this.update.maintenanceOperation.code==='Z' 
    || this.update.maintenanceOperation.code==='MAG')){
      //dirigé vers l’écran MAJ_002_2 Préparation des travaux / Plan de maintenance.
      this.loadInitDatasMaintenancePlanUpdtPage();
    }else{
      this.initMaintenancePlan();
    }
  }

  /**
   * Initialization control of the maintenance plan
   */
  initMaintenancePlan(): void{
    this.listCAMSDefault = this.camFaultList.filter(e => (e.type === 'C.U.U' && ['I', 'IC'].includes(e.origin)) || (e.type === 'CAM' && ['I', 'IE'].includes(e.origin)));// cams.data;
    if (this.maintenancePlanList && this.maintenancePlanList.length > 0) {
      //-- Vérifier si le plan de maintenance du wagon contient déjà une tâche (en cas de retour arrière par exemple)
      this.nextPage = true;
      this.startWorkBtEvent.emit(this.nextPage);
      this.dataSourceMaintenancePlan.data = this.maintenancePlanList;
      this.dataSourceCamsDefaults.data = this.listCAMSDefault;
      this.loadingPMSubject.next(false);
    } else {
      if (this.update && this.update.expId) {
        //-- Vérifier si une expertise a été sélectionnée à l’ouverture 
        this.nextPage = true;
        this.startWorkBtEvent.emit(this.nextPage);
        this.dataSourceMaintenancePlan.data = this.maintenancePlanList;
        this.dataSourceCamsDefaults.data = this.listCAMSDefault;
        this.loadingPMSubject.next(false);
      } else {
        //Sinon (pas d’expertise sélectionnée en ouverture), le système affiche une pop-up « Plan de maintenance » avec un message « Voulez-vous initialiser le plan de maintenance à partir du code opération, du motif et des CAM et défauts identifiés sur le wagon? » 
        // this.initPlanMaintenancePopUp();
        this.subscriptionRefs.push(
          this.referenceService.findCamsDefaults(this.pWagon, true).subscribe (cams => {
            if (cams.data) {
              this.maintenancePlanInit.emit({mpu: null, cams:cams.data});
              this.dataSourceCamsDefaults.data = this.listCAMSDefault;
              this.nextPage = true;
              this.startWorkBtEvent.emit(this.nextPage);
              this.loadingPMSubject.next(false);
            }
          }) 
        );
      }
    }
  }

  /**
   * Methode pour initialiser un plan maintenance
   * @param mpu : MaintenancePlanUpdate
   */
  initPlanMaintenancePopUp():void{
    this.loadingPMSubject.next(true);
    /*
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '38.125rem';
    const title = this.translateService.instant('wagon-update.preparation-work.initMaintenancePlan.title'); 
    const message = this.translateService.instant('wagon-update.preparation-work.initMaintenancePlan.msg');

    dialogConfig.data = {
        namePopUp : 'init_plan',
        titlePopUp : title,
        msgPopUp : message
    };*/

    /*const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe( confirm => {
      if (confirm ){*/
        //initialiser le plan de maintenance à partir du code opération et du motif renseignés en ouverture et des défauts et CAM identifiés sur le wagon.
        this.subscriptionRefs.push(
          forkJoin([
          this.maintenancePlanUpdateService.initMaintenancePlanUpdate(this.pWagon, (this.update && this.update.maintenanceOperation)?this.update.maintenanceOperation.code:undefined
          , (this.update && this.update.interventionReason)?this.update.interventionReason.code:undefined),
          this.referenceService.findCamsDefaults(this.pWagon, true)]
          )
          .subscribe(([mpupdates, cams]) => {
            const mpu = mpupdates.data;
            if (mpu){
              this.dataSourceMaintenancePlan.data = mpu;
              this.maintenancePlanInit.emit({mpu, cams:cams.data});
              this.dataSourceCamsDefaults.data = this.listCAMSDefault;
              this.nextPage = true;
              this.startWorkBtEvent.emit(this.nextPage);
              this.loadingPMSubject.next(false);
            }
          }, (error) => console.log(error))
        );
      /*}else{
        this.subscriptionRefs.push(
          this.referenceService.findCamsDefaults(this.pWagon, true).subscribe (cams => {
            if (cams.data) {
              this.maintenancePlanInit.emit({mpu: null, cams:cams.data});
              this.dataSourceCamsDefaults.data = this.listCAMSDefault;
              this.nextPage = true;
              this.startWorkBtEvent.emit(this.nextPage);
              this.loadingPMSubject.next(false);
            }
          }) 
        );
      }*/
      
    //});
  }


  /**
   * Return to init page
   */
  returnPageClick(){
    this.nextPage = false;
    this.startWorkBtEvent.emit(this.nextPage);
  }

  /**
   * Methode pour init datas 
   */
  private loadInitDatasMaintenancePlanUpdtPage() {
    
    this.listCAMSDefault = this.camFaultList.filter(e => (e.type === 'C.U.U' && ['I', 'IC'].includes(e.origin)) || (e.type === 'CAM' && ['I', 'IE'].includes(e.origin)));
    this.dataSourceMaintenancePlan.data = this.maintenancePlanList;
    this.dataSourceCamsDefaults.data = this.listCAMSDefault;
    this.nextPage = true;
    this.startWorkBtEvent.emit(this.nextPage);
    this.loadingPMSubject.next(false);
  }

  /**
   * Get value from child component to refresh or not the page
   * @param loadPage 
   */
  refreshPage(loadPage: boolean){
    if (loadPage) {
      this.maintenancePlanTableUpdated.emit(true);
    }
  }

  onCamFaultTableUpdateEvent(ev: boolean) {
    if (ev) {
      this.camFaultsTableUpdated.emit(true);
    }
  }

}
