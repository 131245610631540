import { WagonLocation } from './wagon-location';
import { DrawSpring } from './draw-spring';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_RESST */
export class WagonDrawSpring {
    /** WRESST_WCAR_MEX_PFK VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = WRESST_WCAR_INV_CODE_PFK VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** drawSpring.code = WRESST_RESST_CODE_PFK VARCHAR2(2 BYTE) */
    drawSpring: DrawSpring;
    /** location.code = WRESST_EMPL_CODE_FK VARCHAR2(3 BYTE) */
    location: WagonLocation;
    /** WRESST_NBRE NUMBER(3,0) */
    amount: number;
}
