import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { ComponentGroup } from 'src/app/shared/models/component-group';
import { Defect } from 'src/app/shared/models/defect';
import { Gcu } from 'src/app/shared/models/gcu';
import { Job } from 'src/app/shared/models/job';
import { JobDefect } from 'src/app/shared/models/job-defect';
import { MaintenanceAid } from 'src/app/shared/models/maintenance-aid';
import { StateJob } from 'src/app/shared/models/state-job';
import { TTache } from 'src/app/shared/models/t-tache';
import { TaskDialogComponent } from '../../task-dialog/task-dialog.component';

@Component({
  selector: 'app-add-task',
  templateUrl: './add-line.component.html'
})
export class AddLineComponent implements OnInit {
  @Output() maintenancePlanTableUpdated = new EventEmitter<boolean>();
  @Output() camFaultsTableUpdated = new EventEmitter<boolean>();
  @Output() reload = new EventEmitter<boolean>();
  addTask = false;

  constructor(
    public dialogRef: MatDialogRef<TaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      wagonId: number,
      taskDialogDatas: {
        stateJobs: StateJob[],
        componentGroups: ComponentGroup[],
        components: Component[],
        jobs: Job[],
        defects: Defect[],
        jobDefects: JobDefect[],
        tTaches: TTache[]
      },
      maintenanceAids: MaintenanceAid[], gcus: Gcu[]
    }) {
  }

  ngOnInit() {
    //this.dialogRef.afterClosed().subscribe( res => console.log(res) );
  }

  onTaskAdded(event) {
    if (event) {
      this.addTask = false;
      this.maintenancePlanTableUpdated.emit(true);
    } else {
      this.addTask = false;
    }
  }
  onCamAdded() {
    this.camFaultsTableUpdated.emit(true);
  }
  onReload() {
    this.reload.emit(true);
  }
}
