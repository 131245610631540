import { Component, EventEmitter, HostListener, Inject, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Agent } from 'src/app/shared/models/agent';

@Component({
  templateUrl: './profil-dialog.component.html',
  styleUrls: ['./profil-dialog.component.scss']
})
export class ProfilDialogComponent {
  agent: Agent;
  @Output() public instr = new EventEmitter<string>();
  mode = false;
  code = '';
  codeList=['MEX','KAAM', 'OSS', 'CHUCK'];
  constructor(public dialogRef: MatDialogRef<ProfilDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.agent = data.agent;
    }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if (this.mode) {
      this.checkFlag(event);
    }
  }
  checkFlag(event: KeyboardEvent) {
    this.code += event.key.toUpperCase();
    if (this.codeList.includes(this.code)) {
      this.instr.emit(this.code);
    }
  }
  public makeInstr() {
    this.mode = true;
      setTimeout(_=> {
        this.mode = false;
        this.code ="";
      },3000);
  }

  cbChange() {
    this.instr.emit('MEX');
  }
}
