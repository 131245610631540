import { Regulator } from './regulator';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_REGL */
export class WagonRegulator {
    /** WREGL_WCAR_MEX_PFK VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = WREGL_WCAR_INV_CODE_PFK VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** regulator.code = WREGL_REGL_CODE_PFK VARCHAR2(2 BYTE) */
    regulator: Regulator;
    /** WREGL_NBRE NUMBER(3,0) */
    amount: number;
}
