import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TemplatePopupDialogComponent } from 'src/app/shared/components/template-popup-dialog/template-popup-dialog.component';
import { BumpOrgan } from 'src/app/shared/models/bump-organ';
import { DrawHook } from 'src/app/shared/models/draw-hook';
import { DrawSpring } from 'src/app/shared/models/draw-spring';
import { FrameEnd } from 'src/app/shared/models/frame-end';
import { TechSpecReferences } from 'src/app/shared/models/tech-spec-references';
import { WagonBumpOrgan } from 'src/app/shared/models/wagon-bump-organ';
import { WagonDrawHook } from 'src/app/shared/models/wagon-draw-hook';
import { WagonDrawSpring } from 'src/app/shared/models/wagon-draw-spring';
import { WagonFrameEnd } from 'src/app/shared/models/wagon-frame-end';
import { WagonLocation } from 'src/app/shared/models/wagon-location';
import { WagonTechnicalDataDetail } from 'src/app/shared/models/wagon-technical-data-detail';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TechSpecShockTraction } from "../../../../../../models/tech-spec-shock-traction";
import { WagonTechnicalData } from "../../../../../../models/wagon-technical-data";
import { MonitoringWorkService } from "../../../../../../services/monitoring-work/monitoring-work.service";


@Component({
  selector: 'app-open-wagon-bump-traction',
  templateUrl: './open-wagon-bump-traction.component.html',
  styleUrls: ['./open-wagon-bump-traction.component.scss']
})
export class OpenWagonBumpTractionComponent implements OnInit, OnDestroy {

  @Input() public wagonTechnicalData: WagonTechnicalData;
  @Input() public techSpecReferences: TechSpecReferences;
  @Output() modifiedDataEvent = new EventEmitter();
  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  bumpTractionForm: FormGroup;

  displayedColumns: string[] = ['type', 'location', 'number', 'nature', 'action'];
  frameEndDS: MatTableDataSource<FormGroup>;
  drawSpringDS: MatTableDataSource<FormGroup>;
  drawHookDS: MatTableDataSource<FormGroup>;
  bumpOrganDS: MatTableDataSource<FormGroup>;
  filteredBumpOrganList: BumpOrgan[];
  filteredDrawHookList: DrawHook[];
  filteredDrawSpringList: DrawSpring[];
  filteredFrameEndList: FrameEnd[];
  shockTraction: TechSpecShockTraction;
  numberWagon = 0;
  /** Error list*/
  errorAddList = [];
  errorSubmitList = [];
  flagActivateAddList  = false;
  flagActivateSubmitList  = false;
  /** message error */
  messageAddError = '';
  msgSubmitError = '';

  case1ControlAdd1 = false;
  case1ControlAdd2 = false;
  case2ControlAdd1 = false;
  case2ControlAdd2 = false;
  empty_field_fe_1 = false;
  empty_field_fe_3 = false;
  empty_field_fe_5 = false;
  empty_field_fe_7 = false;
  not_unique_fe_loc = false;
  not_unique_fs_loc = false;
  not_unique_dh_loc = false;
  not_unique_bo_loc = false;
  controlCoh2Flag = false;

  msg_error_add_1: string;
  msg_error_add_2: string;
  msg_error_add_3: string;
  msg_error_add_4: string;
  empty_field_msg: string;
  unique_location: string;

  submitted: boolean;
  modifSub: Subscription;
  alert: boolean;
  constructor(private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private monitoringWorkService: MonitoringWorkService,
              private dialog: MatDialog) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.bumpTractionForm = this.formBuilder.group({
      frameEnd: this.formBuilder.array([]),
      drawSpring: this.formBuilder.array([]),
      drawHook: this.formBuilder.array([]),
      bumpOrgan: this.formBuilder.array([])
    });
    
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.modifSub = this.bumpTractionForm.valueChanges.subscribe(
      _ => this.modifiedDataEvent.emit(this.bumpTractionForm.dirty));

    this.loadInitDatas();
  }

  /**
   * Reset les donnes
   */
  resetDatas(): void {
    this.submitted = false;
    this.frameEnd.clear();
    this.drawSpring.clear();
    this.drawHook.clear();
    this.bumpOrgan.clear();
    this.errorAddList = [];
    this.errorSubmitList = [];
    this.flagActivateAddList = false;
    this.flagActivateSubmitList = false;

    const listFrameEnd = this.shockTraction.wagonFrameEnd;
    listFrameEnd.forEach(elem => this.frameEnd.push(this.addFormGroup(this.techSpecReferences.listFrameEnd, false, elem.location.code, elem.amount, elem.frameEnd.code)));
    this.frameEnd.push(this.addFormGroup(this.techSpecReferences.listFrameEnd));
    this.frameEndDS = new MatTableDataSource<FormGroup>(this.frameEnd.controls as FormGroup[]);

    const listDrawSpring = this.shockTraction.wagonDrawSpring;
    listDrawSpring.forEach(elem => this.drawSpring.push(this.addFormGroup(this.techSpecReferences.listDrawSpring, false, elem.location.code, elem.amount, elem.drawSpring.code)));
    this.drawSpring.push(this.addFormGroup(this.techSpecReferences.listDrawSpring));
    this.drawSpringDS = new MatTableDataSource<FormGroup>(this.drawSpring.controls as FormGroup[]);

    const listDrawHook = this.shockTraction.wagonDrawHook;
    listDrawHook.forEach(elem => this.drawHook.push(this.addFormGroup(this.techSpecReferences.listDrawHook, false, elem.location.code, elem.amount, elem.drawHook.code)));
    this.drawHook.push(this.addFormGroup(this.techSpecReferences.listDrawHook));
    this.drawHookDS = new MatTableDataSource<FormGroup>(this.drawHook.controls as FormGroup[]);

    const listBumpOrgan = this.shockTraction.wagonBumpOrgan;
    listBumpOrgan.forEach(elem => this.bumpOrgan.push(this.addFormGroup(this.techSpecReferences.listBumpOrgan, false, elem.location.code, elem.amount, elem.bumpOrgan.code)));
    this.bumpOrgan.push(this.addFormGroup(this.techSpecReferences.listBumpOrgan));
    this.bumpOrganDS = new MatTableDataSource<FormGroup>(this.bumpOrgan.controls as FormGroup[]);
    this.bumpTractionForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }

  /**
   * Methode pour init datas
   */
  private loadInitDatas() {

    this.subscriptionRefs.push(
      this.monitoringWorkService.loadShockTractionPageDatas(this.wagonTechnicalData.wagon.id)
        .subscribe(update => {
          this.shockTraction = update.data;
          this.filteredBumpOrganList = this.techSpecReferences.listBumpOrgan;
          this.filteredDrawHookList = this.techSpecReferences.listDrawHook;
          this.filteredDrawSpringList = this.techSpecReferences.listDrawSpring;
          this.filteredFrameEndList =  this.techSpecReferences.listFrameEnd;
          this.resetDatas();
          this.loadingSubject.next(false);
        })
    );
    this.monitoringWorkService.getNumberOfElementsOfWagon(this.wagonTechnicalData.wagon.id)
        .subscribe(result => {
          this.numberWagon = result.data;             
        });
  }

  /**
   * Methode to submit the form
   */
  async onSubmit(alert?: boolean): Promise<void> {
    this.alert = alert === undefined ? true : alert;
    this.msgSubmitError = '';
    this.submitted = true;
    this.flagActivateAddList = true;
    this.errorSubmitList = [];
    const checkControlsFailed = this.checkOnSubmitControls();
    let shockTractionToSave = new TechSpecShockTraction();
    if (checkControlsFailed == false){
      shockTractionToSave = this.initializeObjectToSend();
      this.loadingSubject.next(true);
      const techSpecShockTraction = (await this.monitoringWorkService.updateTechSpecShockTraction(shockTractionToSave).pipe(first()).toPromise()).data;
      this.loadingSubject.next(false);
      this.updateDone();
      this.bumpTractionForm.markAsPristine();
      this.modifiedDataEvent.emit(false);
    }
  }

  initializeObjectToSend(): TechSpecShockTraction {
    const shockTractionToSave = new TechSpecShockTraction();
    shockTractionToSave.wagonId = this.shockTraction.wagonId;
    shockTractionToSave.wagonUpdate = this.shockTraction.wagonUpdate;
    const wagonTechnicalDataToSend = new WagonTechnicalDataDetail();
    wagonTechnicalDataToSend.mex = this.shockTraction.wagonTechnicalData.mex;
    shockTractionToSave.wagonTechnicalData = wagonTechnicalDataToSend;
    // save wagon frame end values
    const listWagonFrameEnd : WagonFrameEnd[] = [];
    let index = 1;
    this.bumpTractionForm.value.frameEnd.forEach(frameend => {
      if (index < this.bumpTractionForm.value.frameEnd.length) {
        const wagonFrameEndTosend = new WagonFrameEnd();
        const frameEndToSend = new FrameEnd();

        const locationToSend = new WagonLocation();
        locationToSend.code = frameend.location;

        frameEndToSend.code = frameend.nature;

        wagonFrameEndTosend.frameEnd = frameEndToSend; 
        wagonFrameEndTosend.amount = frameend.amount;
        wagonFrameEndTosend.location = locationToSend;
        listWagonFrameEnd.push(wagonFrameEndTosend);
      }
      index++;
    });
    shockTractionToSave.wagonFrameEnd = listWagonFrameEnd;
    
    // save wagon draw spring values
    const listWagonDrawSpring : WagonDrawSpring[] = [];
    index = 1;
    this.bumpTractionForm.value.drawSpring.forEach(drawSp => {
      if (index < this.bumpTractionForm.value.drawSpring.length) {
        const wagonDrawSpTosend = new WagonDrawSpring();
        const drawSpToSend = new DrawSpring();

        const locationToSend = new WagonLocation();
        locationToSend.code = drawSp.location;

        drawSpToSend.code = drawSp.nature;

        wagonDrawSpTosend.drawSpring = drawSpToSend; 
        wagonDrawSpTosend.amount = drawSp.amount;
        wagonDrawSpTosend.location = locationToSend;
        listWagonDrawSpring.push(wagonDrawSpTosend);
      }
      index++;    
    });
    shockTractionToSave.wagonDrawSpring = listWagonDrawSpring;

    // save wagon draw hook values
    const listWagonDrawHook : WagonDrawHook[] = [];
    index = 1;
    this.bumpTractionForm.value.drawHook.forEach(drawHook => {
      if (index < this.bumpTractionForm.value.drawHook.length) {
        const wagonDrawHookTosend = new WagonDrawHook();
        const drawHookToSend = new DrawHook();

        const locationToSend = new WagonLocation();
        locationToSend.code = drawHook.location;

        drawHookToSend.code = drawHook.nature;

        wagonDrawHookTosend.drawHook = drawHookToSend; 
        wagonDrawHookTosend.amount = drawHook.amount;
        wagonDrawHookTosend.location = locationToSend;
        listWagonDrawHook.push(wagonDrawHookTosend);
      }
      index++;
    });
    shockTractionToSave.wagonDrawHook = listWagonDrawHook;

    // save wagon bump organ values
    const listWagonBumpOrgan : WagonBumpOrgan[] = [];
    index = 1;
    this.bumpTractionForm.value.bumpOrgan.forEach(bumpOrg => {
      if (index < this.bumpTractionForm.value.bumpOrgan.length) {
        const wagonBumpOrganTosend = new WagonBumpOrgan();
        const bumpOrganToSend = new BumpOrgan();

        const locationToSend = new WagonLocation();
        locationToSend.code = bumpOrg.location;

        bumpOrganToSend.code = bumpOrg.nature;

        wagonBumpOrganTosend.bumpOrgan = bumpOrganToSend; 
        wagonBumpOrganTosend.amount = bumpOrg.amount;
        wagonBumpOrganTosend.location = locationToSend;
        listWagonBumpOrgan.push(wagonBumpOrganTosend);
      }
      index++;
    });
    shockTractionToSave.wagonBumpOrgan = listWagonBumpOrgan;

    return shockTractionToSave;
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

  addFormGroup(list: any[], isNew = true, location?: string, amount?: number, type?: string): FormGroup {
    return this.formBuilder.group({
      isNew: this.formBuilder.control(isNew, Validators.required),
      location: this.formBuilder.control(location, Validators.required),
      amount: this.formBuilder.control(amount, [Validators.required, Validators.pattern(/^\d{1,3}$/)]),
      nature: this.formBuilder.control(type, list ? [Validators.required, this.validateCodeInList(list)] : Validators.required)
    });
  }

  addRow(array: FormArray, index: number, type: string, list: any[]): void {
    
    this.flagActivateAddList = true;
    this.errorAddList = [];
    this.messageAddError= '';
    this.controlAddCase1(type);
    this.controlAddCase2(type);
    if(!(this.case1ControlAdd1 || this.case1ControlAdd2) && !(this.case2ControlAdd1 || this.case2ControlAdd2)){
      array.push(this.addFormGroup(list));
      this.tables.forEach(table => table.renderRows());
    } else if(this.errorAddList.length >= 1){
      this.errorAddList.forEach(element => {
        this.messageAddError = this.messageAddError + element + '\n';
      })
    }
    
  }

  removeRow(array: FormArray, index: number): void {
    array.removeAt(index);
    array.markAsDirty();
    this.modifiedDataEvent.emit(true);
    this.tables.forEach(table => table.renderRows());
  }

  // Cas 1 : Groupes Extrémité de châssis, Ressort de traction et Crochet de Traction 
  controlAddCase1 (type: string): boolean {
    this.case1ControlAdd1 = false;
    this.case1ControlAdd2 = false;
    this.case1Control1();
    if(!this.case1ControlAdd1){
      this.errorAddList = [];
      this.case1Control2(type);
    }
    return (this.case1ControlAdd1 || this.case1ControlAdd2 );
  }

  // Le nombre saisi n'est pas compatible avec l'emplacement 1x2 ou OPPosé
  case1Control1(): boolean {
    this.bumpTractionForm.value.frameEnd.forEach(element =>{
      if(element.isNew && (element.location == '1x2' || element.location == 'OPP') && element.amount != 1) {
        this.case1ControlAdd1 = true;
        this.msg_error_add_1 = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_1');
        if(this.errorAddList.indexOf(this.msg_error_add_1) === -1) {
          this.errorAddList.push(this.msg_error_add_1);
        }   
      }
    });
    this.bumpTractionForm.value.drawHook.forEach(element =>{
      if(element.isNew && (element.location == '1x2' || element.location == 'OPP') && element.amount != 1) {
        this.case1ControlAdd1 = true;
        this.msg_error_add_1 = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_1');
        if(this.errorAddList.indexOf(this.msg_error_add_1) === -1) {
          this.errorAddList.push(this.msg_error_add_1);
        }
      }
    });
    this.bumpTractionForm.value.drawSpring.forEach(element =>{
      if(element.isNew && (element.location == '1x2' || element.location == 'OPP') && element.amount != 1) {
        this.case1ControlAdd1 = true;
        this.msg_error_add_1 = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_1');
        if(this.errorAddList.indexOf(this.msg_error_add_1) === -1) {
          this.errorAddList.push(this.msg_error_add_1);
        }
      }
    });
    return this.case1ControlAdd1;
  }

  // La somme des nombres de <Nom du groupe> n'est pas compatible avec le nombre d'éléments du wagon 
  case1Control2(type: string): boolean {
    
    if(type == 'Ext'){
      let sum = 0;
      let index = 1;
      this.bumpTractionForm.value.frameEnd.forEach(element => {
        if (index < this.bumpTractionForm.value.frameEnd.length) {
          sum += +(element.amount);
        }
        index++;
      });
      
      if(sum > (this.numberWagon * 2)){
        this.case1ControlAdd2 = true;
        this.msg_error_add_2 = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first_part')
         + ' ' + this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group1') +
        ' '+ this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.second_part');
        if(this.errorAddList.indexOf(this.msg_error_add_2)  === -1){
          this.errorAddList.push(this.msg_error_add_2);
        }
        
      }
    } else if (type == 'Res'){
      let sum = 0;
      let index = 1;
      this.bumpTractionForm.value.drawSpring.forEach(element => {
        if (index < this.bumpTractionForm.value.drawSpring.length) {
          sum += +(element.amount);
        }
        index++;
      });
      if(sum > (this.numberWagon * 2)){
        this.case1ControlAdd2 = true;
        this.msg_error_add_2 = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first_part')
         + ' ' + this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group2') +
        ' '+ this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.second_part');
        if(this.errorAddList.indexOf(this.msg_error_add_2)  === -1){
          this.errorAddList.push(this.msg_error_add_2);
        }
      }
    } else if(type == 'Cro') {
      let sum = 0;
      let index = 1;
      this.bumpTractionForm.value.drawHook.forEach(element => {
        if (index < this.bumpTractionForm.value.drawHook.length) {
          sum += +(element.amount);
        }
        index++;
      });
      if(sum > (this.numberWagon * 2)){
        this.case1ControlAdd2 = true;
        this.msg_error_add_2 = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first_part')
         + ' ' + this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group3') +
        ' '+ this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.second_part');
        if(this.errorAddList.indexOf(this.msg_error_add_2)  === -1){
          this.errorAddList.push(this.msg_error_add_2);
        }
      }
    }
    return this.case1ControlAdd2;
  }

  // Cas 2 : Groupe Organe de choc 
  controlAddCase2 (type: string): boolean {
    this.case2ControlAdd1 = false;
    this.case2ControlAdd2 = false;
    if(type == 'Org'){
      this.case2Control1();
      if(!this.case2ControlAdd1) {
        this.errorAddList = [];
        this.case2Control2();
      }
    }
    return this.case2ControlAdd1 || this.case2ControlAdd2 ;
  }

  case2Control1(): boolean {
    this.bumpTractionForm.value.bumpOrgan.forEach(element =>{
      if(element.isNew && (element.location == '1x2' || element.location == 'OPP') && element.amount != 1 && element.amount != 2) {
        this.case2ControlAdd1 = true;
        
        this.msg_error_add_3 = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_3');
        if(this.errorAddList.indexOf(this.msg_error_add_3)  === -1){
          this.errorAddList.push(this.msg_error_add_3);
        }
      }
    });
    return this.case2ControlAdd1;
  }

  case2Control2(): boolean {
    
    let sum = 0;
    this.bumpTractionForm.value.bumpOrgan.forEach(element =>{
        sum += +(element.amount);   
    });
    if(sum > (this.numberWagon * 4)){
      this.case2ControlAdd2 = true;
        this.msg_error_add_4 = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first_part')
        + ' ' + this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group4') +
       ' '+ this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.second_part');
        if(this.errorAddList.indexOf(this.msg_error_add_4)  === -1){
          this.errorAddList.push(this.msg_error_add_4);
        }
      }
    return this.case2ControlAdd2;
  }
  
  
  checkOnSubmitControls(): boolean {
    
    this.flagActivateSubmitList = true;
    let checkControlsFailed = false;
    const checkNotCoh1 = this.controlCoh1();
    let checkCoh5_bis = false;
    checkControlsFailed = checkNotCoh1; // true -> error
    if(this.errorSubmitList.length >= 1){
      this.errorSubmitList.forEach(element => {
        this.msgSubmitError = this.msgSubmitError + element + '\n';
      })
    }
   
    if(checkNotCoh1 != true) { 
      
      this.errorSubmitList= [];
      this.controlCoh2_3_4('1x2');
      checkControlsFailed = this.controlCoh2Flag;
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        })
      }
      if(this.controlCoh2Flag != true) {
        this.errorSubmitList= [];
        this.controlCoh2_3_4('OPP');
        checkControlsFailed = this.controlCoh2Flag;
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          })
        }
        if(!this.controlCoh2Flag) {
          if (this.numberWagon > 1) {
            this.errorSubmitList= [];
            this.controlCoh2_3_4('INT');
            checkControlsFailed = this.controlCoh2Flag;
            if(this.errorSubmitList.length >= 1){
              this.errorSubmitList.forEach(element => {
                this.msgSubmitError = this.msgSubmitError + element + '\n';
              })
            }
          }
          if(!this.controlCoh2Flag ) {
            this.errorSubmitList= [];
            // Cas 1: Groupes Extrémité de châssis, Ressort de traction et Crochet de Traction
            let res1 = false;
            let res2 = false;
            res1 = this.controlCoh5();
            res2 = this.controlCoh6_1_and_6_2();
            checkControlsFailed = res1 || res2;
            if(this.errorSubmitList.length >= 1){
              this.errorSubmitList.forEach(element => {
                this.msgSubmitError = this.msgSubmitError + element + '\n';
              })
            }
            if (!checkControlsFailed) {
              // Cas 2 : Groupe Organe de choc 
              checkCoh5_bis = this.controlCoh5_bis();
              if(!checkCoh5_bis){
                this.errorSubmitList= [];
                checkControlsFailed = this.controlCoh6_1_bis_and_6_2_bis();
                if(this.errorSubmitList.length >= 1){
                  this.errorSubmitList.forEach(element => {
                    this.msgSubmitError = this.msgSubmitError + element + '\n';
                  });
                }
              }
            }
          }
        }
      }
    }
    return checkControlsFailed;
  }
  controlCoh1 (): boolean{

    this.empty_field_fe_1 = false;
    this.empty_field_fe_3 = false;
    this.empty_field_fe_5 = false;
    this.empty_field_fe_7 = false;
    
    this.bumpTractionForm.value.frameEnd.forEach(element => {
      if ((element.amount == null || element.amount == "" || element.location == null)
          && !(element.amount == null && element.location == null && element.nature == null)) {
        
        this.empty_field_fe_1 = true;
        this.empty_field_msg = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.empty_field_msg');
        
        if(this.errorSubmitList.indexOf(this.empty_field_msg) === -1){
          this.errorSubmitList.push(this.empty_field_msg);
        }
      }
    });

    this.bumpTractionForm.value.drawSpring.forEach(element => {
      if ((element.amount == null || element.amount == "" || element.location == null) 
        && !(element.amount == null && element.location == null && element.nature == null)) {
        this.empty_field_fe_3 = true;
        this.empty_field_msg = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.empty_field_msg');
        if(this.errorSubmitList.indexOf(this.empty_field_msg) === -1){
          this.errorSubmitList.push(this.empty_field_msg);
          
        } 
      }
    });

    this.bumpTractionForm.value.drawHook.forEach(element => {
      if ((element.amount == null || element.amount == "" || element.location == null)
        && !(element.amount == null && element.location == null && element.nature == null)) {
        this.empty_field_fe_5 = true;
        this.empty_field_msg = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.empty_field_msg');
        if(this.errorSubmitList.indexOf(this.empty_field_msg) === -1){
          this.errorSubmitList.push(this.empty_field_msg);
          
        } 
      }
    });

    this.bumpTractionForm.value.bumpOrgan.forEach(element => {
      if ((element.amount == null || element.amount == "" || element.location == null)
        && !(element.amount == null && element.location == null && element.nature == null)) {
        this.empty_field_fe_7 = true;
        this.empty_field_msg = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.empty_field_msg');
        if(this.errorSubmitList.indexOf(this.empty_field_msg) === -1){
          this.errorSubmitList.push(this.empty_field_msg);
          
        } 
      }
    });
    return this.empty_field_fe_1  || this.empty_field_fe_3  || this.empty_field_fe_5 || this.empty_field_fe_7 ;
  }
  
  // RG_MAJ_003_4_4_3, RG_MAJ_003_4_4_4, RG_MAJ_003_4_4_5 
  controlCoh2_3_4 (location: string): boolean {
    let fe_loc_count = 0;
    let ds_loc_count = 0;
    let dh_loc_count = 0;
    let bo_loc_count = 0;
    let typeGroup: string;
    this.errorSubmitList = [];
    this.msgSubmitError = '';

    this.controlCoh2Flag = false;
    this.not_unique_fe_loc = false;
    this.not_unique_fs_loc = false;
    this.not_unique_dh_loc = false;
    this.not_unique_bo_loc= false;
    this.controlCoh2Flag = false;

    this.bumpTractionForm.value.frameEnd.forEach(element => { 
      if (element.location == location && !(element.amount == null && element.location == null && element.nature == null)) {
        fe_loc_count ++;
      }
    });
    this.bumpTractionForm.value.drawSpring.forEach(element => {
      if (element.location == location && !(element.amount == null && element.location == null && element.nature == null)) {
        ds_loc_count ++;
      }
    });
    this.bumpTractionForm.value.drawHook.forEach(element => {
      if (element.location == location && !(element.amount == null && element.location == null && element.nature == null)) {
        dh_loc_count ++;
      }
    });
    this.bumpTractionForm.value.bumpOrgan.forEach(element => {
      if (element.location == location && !(element.amount == null && element.location == null && element.nature == null)) {
        bo_loc_count ++;
      }
    });
    
    if (fe_loc_count == 0 || fe_loc_count > 1){
      
      this.not_unique_fe_loc = true;
      typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group1');
      this.unique_location = typeGroup + ' ' + this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.uniqueLoc.first_part') + location +
      this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.uniqueLoc.second_part');
      if(this.errorSubmitList.indexOf(this.unique_location) === -1){
        this.errorSubmitList.push(this.unique_location);
      }    
    }
    if (ds_loc_count == 0 || ds_loc_count > 1){
      this.not_unique_fs_loc = true;
      typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group2');
      this.unique_location = typeGroup + ' ' + this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.uniqueLoc.first_part') + location +
      this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.uniqueLoc.second_part');
      if(this.errorSubmitList.indexOf(this.unique_location) === -1){
        this.errorSubmitList.push(this.unique_location);
      }
    }
    if (dh_loc_count == 0 || dh_loc_count > 1){
      this.not_unique_dh_loc = true;
      typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group3');
      this.unique_location = typeGroup + ' ' + this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.uniqueLoc.first_part') + location +
      this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.uniqueLoc.second_part');
      if(this.errorSubmitList.indexOf(this.unique_location) === -1){
        this.errorSubmitList.push(this.unique_location);
      }
    }
    if (bo_loc_count == 0 || bo_loc_count > 1){
      this.not_unique_bo_loc = true;
      typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group4');
      this.unique_location = typeGroup + ' ' + this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.uniqueLoc.first_part') + location +
      this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.uniqueLoc.second_part');
      if(this.errorSubmitList.indexOf(this.unique_location) === -1){
        this.errorSubmitList.push(this.unique_location);
      }
    }

    this.controlCoh2Flag = this.not_unique_fe_loc || this.not_unique_fs_loc || this.not_unique_dh_loc || this.not_unique_bo_loc;
    return this.controlCoh2Flag;
  }

  // RG_MAJ_003_4_4_1_1 
  controlCoh5 (): boolean {
    const result = this.case1Control1();
    this.errorSubmitList = [];
    if(result) {
      this.errorAddList.forEach(element => {
        if(this.errorSubmitList.indexOf(element) === -1){
          this.errorSubmitList.push(element);
        }
      });     
    }
    return result;
  }

  // RG_MAJ_003_4_4_2_1 and RG_MAJ_003_4_4_2_1 
  controlCoh6_1_and_6_2(): boolean {
      
      let countNumberFrameEnd = 0;
      let countNumberDrawHook = 0;
      let countNumberDrawSpring = 0;
      let msg_error = '';
      let typeGroup = '';
      let index = 1;
      this.bumpTractionForm.value.frameEnd.forEach(element =>{
        if(element.amount != null && index < this.bumpTractionForm.value.frameEnd.length) {
          countNumberFrameEnd += +(element.amount);
        }
        index++;
      });
      index = 1;
      this.bumpTractionForm.value.drawHook.forEach(element =>{
        if(element.amount != null && index < this.bumpTractionForm.value.drawHook.length) {
          countNumberDrawHook += +(element.amount);
        } 
        index ++;
      });
      index = 1;
      this.bumpTractionForm.value.drawSpring.forEach(element =>{
        if(element.amount != null && index < this.bumpTractionForm.value.drawSpring.length) { 
          countNumberDrawSpring += +(element.amount);
        }
        index++;
      });

      // RG_MAJ_003_4_4_2_1 
      if(this.numberWagon > 1 && countNumberFrameEnd > (this.numberWagon * 2)) {
        typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group1');
        msg_error = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first-part') + typeGroup +
        this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.second_part');
        if(this.errorSubmitList.indexOf(msg_error) === -1){
          this.errorSubmitList.push(msg_error);
        }
      }
      if(this.numberWagon > 1 && countNumberDrawSpring > (this.numberWagon * 2)) {
        typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group2');
        msg_error = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first-part') + typeGroup +
        this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.second_part');
        if(this.errorSubmitList.indexOf(msg_error) === -1){
          this.errorSubmitList.push(msg_error);
        }
      }
      if(this.numberWagon > 1 && countNumberDrawHook > (this.numberWagon * 2)) {
        typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group3');
        msg_error = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first-part') + typeGroup +
        this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.second_part');
        if(this.errorSubmitList.indexOf(msg_error) === -1){
          this.errorSubmitList.push(msg_error);
        }
      }
      // RG_MAJ_003_4_4_2_1 
      if((this.numberWagon <= 1 || this.numberWagon == null) && countNumberFrameEnd > (this.numberWagon * 2)) {
        typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group1');
        msg_error = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first_part') + typeGroup +
        this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.third_part');
        if(this.errorSubmitList.indexOf(msg_error) === -1){
          this.errorSubmitList.push(msg_error);
        }
      }
      if((this.numberWagon <= 1 || this.numberWagon == null)  && countNumberDrawSpring > (this.numberWagon * 2)) {
        typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group2');
        msg_error = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first_part') + typeGroup +
        this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.third_part');
        if(this.errorSubmitList.indexOf(msg_error) === -1){
          this.errorSubmitList.push(msg_error);
        }
      }
      if((this.numberWagon <= 1 || this.numberWagon == null)  && countNumberDrawHook > (this.numberWagon * 2)) {
        typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group3');
        msg_error = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first_part') + typeGroup +
        this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.third_part');
        if(this.errorSubmitList.indexOf(msg_error) === -1){
          this.errorSubmitList.push(msg_error);
        }
      }

      if(this.errorSubmitList.length === 0){
        return false;
      } else {
        return true;
      }
       
  }
  // RG_MAJ_003_4_4_1_2 
  controlCoh5_bis (): boolean {
    let msg_error = '';
    this.bumpTractionForm.value.bumpOrgan.forEach(element =>{
      msg_error = '';
      if((element.location == '1x2' || element.location == 'OPP') && element.amount!= null && (element.amount != 1 || element.amount !=2)) {      
        msg_error = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_1');
        if(this.errorSubmitList.indexOf(msg_error) === -1) {
          this.errorSubmitList.push(msg_error);
        }   
      }
    });
    return msg_error != '';
  }
  // RG_MAJ_003_4_4_2_2 
  controlCoh6_1_bis_and_6_2_bis (): boolean {
    let countNumberBumpOrg = 0;
    let typeGroup = '';
    let msg_error = '';
    this.bumpTractionForm.value.bumpOrgan.forEach(element =>{
      if(element.amount != null) {
        countNumberBumpOrg += +(element.amount);
      }  
    });

    if(this.numberWagon > 1 && countNumberBumpOrg > (this.numberWagon * 4)) {
      typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group4');
      msg_error = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first_part') + typeGroup +
      this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.second_part');
      if(this.errorSubmitList.indexOf(msg_error) === -1){
        this.errorSubmitList.push(msg_error);
      }
    }
    if((this.numberWagon <= 1 || this.numberWagon == null) && countNumberBumpOrg != 4) {
      typeGroup = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.group4');
      msg_error = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.first_part') + typeGroup +
      this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.bump-traction.msg_control.msg_error_add_2.third_part_bis');
      if(this.errorSubmitList.indexOf(msg_error) === -1){
        this.errorSubmitList.push(msg_error);
      }
    }
    return msg_error != '';
  }
  get frameEnd(): FormArray {
    return this.bumpTractionForm.get('frameEnd') as FormArray;
  }

  get drawSpring(): FormArray {
    return this.bumpTractionForm.get('drawSpring') as FormArray;
  }

  get drawHook(): FormArray {
    return this.bumpTractionForm.get('drawHook') as FormArray;
  }

  get bumpOrgan(): FormArray {
    return this.bumpTractionForm.get('bumpOrgan') as FormArray;
  }

  /**
   * Popup message. Closed after 5 seconds.
   */
  private updateDone() {
    this.ngOnInit();
    if (this.alert) {
      const dialogConfig = new MatDialogConfig();    
      dialogConfig.disableClose = true;
      const message = this.translateService.instant('wagon.technical.update-done.msg');    
      const timeout = UtilityService.UPDATE_DONE_TIMEOUT;    
      dialogConfig.data = {
        namePopUp: 'update-done-alert',
        titlePopUp: message,
        msgPopUp: message
      };    
      const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
      dialogRef.afterOpened().subscribe(_ => {
          setTimeout(() => {
          dialogRef.close();
          this.dialog.closeAll();
          }, timeout)
      });
    }
  }
  
  filterWithCodeLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + ' - ' + v.label).toUpperCase().includes(filterValue))
      ) : list;
  }
  validateCodeInList(list: {code: string}[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let val = control.value;
      if (!val) {
        if (val === '') {
          control.setValue(null);
        }
        return null;
      } else {
        
        val = val.toUpperCase();
        const type = list.filter( (elem) => elem.code === val);
        if (type.length) {
          return null;
        }
      }
      return { validateCodeInList: true };
    };
  }
  displayCodeLabelFromFrameEndList = (obj: any) => {
    const f = this.techSpecReferences.listFrameEnd.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromBumpOrganList = (obj: any) => {
    const f = this.techSpecReferences.listBumpOrgan.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromDrawHookList = (obj: any) => {
    const f = this.techSpecReferences.listDrawHook.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromDrawSpringList = (obj: any) => {
    const f = this.techSpecReferences.listDrawSpring.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  
  isValid(): boolean {
    return (this.bumpTractionForm.get('frameEnd') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
     (this.bumpTractionForm.get('drawSpring') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
     (this.bumpTractionForm.get('drawHook') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
     (this.bumpTractionForm.get('bumpOrgan') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true);
 }
}
