import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatPaginatorIntlFr } from './mat-paginator-intl-fr';
import { OverlayModule, Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { TextFieldModule } from '@angular/cdk/text-field';
export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}
/*
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
*/
/*
export class MyPaginatorIntl extends MatPaginatorIntl {
  constructor() {
    super();
    this.getRangeLabel = (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return `0 sur ${length}`;
      }
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return `${startIndex + 1} - ${endIndex} sur ${length}`;
    };
    this.itemsPerPageLabel = 'Eléments par page:';
    this.nextPageLabel = 'Page suivante';
    this.previousPageLabel = 'Page prècedente';
    this.firstPageLabel = 'Début';
    this.lastPageLabel = 'Fin';
  }
}
*/
/*
export class MyStepperIntl extends MatStepperIntl {
  constructor() {
    super();
    this.optionalLabel = 'Optionnel';
  }
}*/

@NgModule({
  imports: [
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatExpansionModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTabsModule,
    MatRippleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatGridListModule,
    MatSliderModule,
    MatProgressBarModule,
    OverlayModule,
    PortalModule,
    MatTreeModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatDatetimepickerModule,
    MatMomentDatetimeModule,
    MatBadgeModule,
    TextFieldModule
  ],
  exports: [
    MatButtonModule,
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatExpansionModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatChipsModule,
    MatPaginatorModule,
    MatTabsModule,
    MatRippleModule,
    MatSnackBarModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatGridListModule,
    MatSliderModule,
    MatProgressBarModule,
    OverlayModule,
    PortalModule,
    MatTreeModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatDatetimepickerModule,
    MatMomentDatetimeModule,
    MatBadgeModule,
    TextFieldModule
  ],
  providers : [
    {provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr},
    /*{provide: MatStepperIntl, useClass: MyStepperIntl},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},*/
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: false }},
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 0,
        hideDelay: 0,
        touchendHideDelay: 7000
      }
    }
  ]
})
export class MaterialModule { }
