import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/services/app-api/api-response.model';
import { AppApiService } from 'src/app/core/services/app-api/app-api.service';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { ComponentGroup } from '../../models/component-group';
import { Components } from '../../models/components';
import { Defect } from '../../models/defect';
import { Job } from '../../models/job';
import { JobDefect } from '../../models/job-defect';
import { MaintenancePlanUpdate } from '../../models/maintenance-plan-update';
import { RefCamUpdate } from '../../models/ref-cam-udpate';
import { TTache } from '../../models/t-tache';

@Injectable({
  providedIn: 'root'
})
export class MaintenancePlanUpdateService {

  /**
   * MaintenancePlanUpdateService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) { }

  /**
   * Find the list of Maintenance Plans, the methode can filter by status
   * @param id the wagon's id 
   */
  public findMaintenancePlanUpdate(id: number, statusToDO?: boolean, statusInProgress?: boolean, statusWaiting?: boolean, statusRealized?: boolean): Observable<ApiResponse<MaintenancePlanUpdate[]>> {
    let parameters: HttpParams = new HttpParams();
    if (statusToDO !== undefined) {
      parameters = parameters.set('statusToDO', `${statusToDO}`);
    }
    if (statusInProgress !== undefined) {
      parameters = parameters.set('statusInProgress', `${statusInProgress}`);
    }
    if (statusWaiting !== undefined) {
      parameters = parameters.set('statusWaiting', `${statusWaiting}`);
    }
    if (statusRealized !== undefined) {
      parameters = parameters.set('statusRealized', `${statusRealized}`);
    }
    return this.api.get<ApiResponse<MaintenancePlanUpdate[]>>(`/maintenance-plan-update/${id}`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findMaintenancePlanUpdate', { data: [] }))
      );
  }

  /**
   * Find the list of Groups or the group
   * @param component the component 
   * @param byComponent 
   */
  public findComponentGroup(search?: string, byComponent?:boolean, pageable?: Pageable): Observable<ApiResponse<ComponentGroup[]>> {
    let parameters: HttpParams = new HttpParams();
    if (search !== undefined) {
      parameters = parameters.set('search', `${search}`);
    }
    if (byComponent !== undefined) {
      parameters = parameters.set('byComponent', `${byComponent}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<ComponentGroup[]>>(`/maintenance-plan-update/component-group`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findComponentGroup', { data: [] }))
      );
  }

  /**
   * Find the list of Components or the component
   * @param search search
   * @param byGroup boolean
   */
  public findComponent(search?: string, byGroup?: boolean, pageable?: Pageable): Observable<ApiResponse<Components[]>> {
    let parameters: HttpParams = new HttpParams();
    if (search !== undefined) {
      parameters = parameters.set('search', `${search}`);
    }
    if (byGroup !== undefined) {
      parameters = parameters.set('byGroup', `${byGroup}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<Components[]>>(`/maintenance-plan-update/component`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findComponent', { data: [] }))
      );
  }

  /**
   * Find the list of jobs related to the component
   * @param component the component
   */
  public findJobs(component?: string): Observable<ApiResponse<Job[]>> {
    let parameters: HttpParams = new HttpParams();
    if (component !== undefined) {
      parameters = parameters.set('component', `${component}`);
    }
    return this.api.get<ApiResponse<Job[]>>(`/maintenance-plan-update/jobs`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findJobs', { data: [] }))
      );
  }

  /**
   * Find the list of Defects
   * @param job the job
   * @param component the component
   */
  public findDefects(job?: string, component?: string): Observable<ApiResponse<Defect[]>> {
    let parameters: HttpParams = new HttpParams();
    if (job !== undefined) {
      parameters = parameters.set('job', `${job}`);
    }
    if (component !== undefined) {
      parameters = parameters.set('component', `${component}`);
    }

    return this.api.get<ApiResponse<Defect[]>>(`/maintenance-plan-update/defects`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findDefects', { data: [] }))
      );
  }
  /**
   * Find the list of job Defect relations
   * @param job the job
   * @param component the component
   */
  public findJobDefects(): Observable<ApiResponse<JobDefect[]>> {
    return this.api.get<ApiResponse<JobDefect[]>>(`/maintenance-plan-update/job-defects`)
      .pipe(
        catchError(this.handleError<any>('findJobDefects', { data: [] }))
      );
  }

  public findTTaches(): Observable<ApiResponse<TTache[]>> {
    return this.api.get<ApiResponse<TTache[]>>(`/maintenance-plan-update/t-taches`)
      .pipe(
        catchError(this.handleError<any>('findTTaches', { data: [] }))
      );
  }
  /**
   * Find out if the Maintenance Plan exist
   * @param wagon the wagon
   * @param job the job
   * @param component the component
   * @param defect the defect
   */
  public exist(wagon: number, job: string, component: string, defect?: string): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagon', `${wagon}`);
    parameters = parameters.set('job', `${job}`);
    parameters = parameters.set('component', `${component}`);
    if (defect !== undefined) {
      parameters = parameters.set('defect', `${defect}`);
    }
    return this.api.get<ApiResponse<boolean>>(`/maintenance-plan-update/exist`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('exist', { data: [] }))
      );
  }

  /**
   * Delete a Maintenance Update Plan
   * @param wagonId wagon id
   * @param position axle position
   */
  public deleteMaintenancePlanUpdate(wagon: number, job: string, component: string, defect: string, amount: number): Observable<{}> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wagon}`);
    parameters = parameters.set('job', `${job}`);
    parameters = parameters.set('component', `${component}`);
    parameters = parameters.set('defect', `${defect}`);
    parameters = parameters.set('amount', `${amount}`);
    return this.api.delete<{}>(`/maintenance-plan-update`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('deleteMaintenancePlanUpdate', null))
      );
  }

  /**
   * create MaintenancePlanUpdate record
   * @param element MaintenancePlanUpdate element datas
   */
  public createMaintenancePlanUpdate(element: MaintenancePlanUpdate): Observable<ApiResponse<MaintenancePlanUpdate>> {
    return this.api.post<ApiResponse<MaintenancePlanUpdate>>(`/maintenance-plan-update`, element)
      .pipe(
        catchError(this.handleError<any>('createMaintenancePlanUpdate', null))
      );
  }

  /**
   * update MaintenancePlanUpdate data
   * @param mex the wagon's mex
   * @param workshop workshop code
   * @param exit updated exit date
   */
  public updateMaintenancePlanUpdate(element: MaintenancePlanUpdate, id: number, job: string, component: string, status: string, defect?: string, amount?: number): Observable<Response> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('job', `${job}`);
    parameters = parameters.set('component', `${component}`);
    parameters = parameters.set('status', `${status}`);
    if (defect !== undefined) {
      parameters = parameters.set('defect', `${defect}`);
    }
    if (amount !== undefined) {
      parameters = parameters.set('amount', `${amount}`);
    }
    return this.api.put<Response>(`/maintenance-plan-update/${id}`, element, { params: parameters, observe: 'response' })
      .pipe(
        catchError(this.handleError<any>('updateMaintenancePlanUpdate', { data: null }))
      );
  }

  /**
   * Init Maintenance plan
   * @param id the wagon's id 
   */
  public initMaintenancePlanUpdate(id: number, code?: string, reason?: string): Observable<ApiResponse<MaintenancePlanUpdate[]>> {
    let parameters: HttpParams = new HttpParams();
    if (code != undefined) {
      parameters = parameters.set('code', `${code}`);
    }
    if (reason != undefined) {
      parameters = parameters.set('reason', `${reason}`);
    }
    return this.api.get<ApiResponse<MaintenancePlanUpdate[]>>(`/maintenance-plan-update/${id}/init`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('initMaintenancePlanUpdate', { data: [] }))
      );
  }

  /**
   * Download Bulletin Suivi datas report
   * @param id the wagon's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadPDFPreparationWorks(id: number, idIgloo: number, flagTracking: boolean, flagDefaults: boolean, flagCAM: boolean, flagMaintenance: boolean, mex: string): Observable<{ blob: Blob, filename: string }> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('idIgloo', `${idIgloo}`);
    parameters = parameters.set('flagTracking', `${flagTracking}`);
    parameters = parameters.set('flagDefaults', `${flagDefaults}`);
    parameters = parameters.set('flagCAM', `${flagCAM}`);
    parameters = parameters.set('flagMaintenance', `${flagMaintenance}`);

    return this.api.get<{ blob: Blob, filename: string }>(`/maintenance-plan-update/preparation-works/${id}/pdf`, { params: parameters, responseType: 'blob', observe: 'response' })
      .pipe(
        catchError(this.handleError<any>('downloadPDFPreparationWorks', null)),
        map((resp) => {
          const contentDisposition = resp.headers.get('Content-Disposition');
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
          return { blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'Suivi_' + mex + '.pdf' };
        })
      );
  }

  /**
   * Find out if there is a CAM produced Relating CAM series
   * @param job the task
   * @param component the component 
   */
  public existsCAMrelatedCAMseries(wagonId: number, job: string, component: string): Observable<ApiResponse<RefCamUpdate[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('job', `${job}`);
    parameters = parameters.set('component', `${component}`);

    return this.api.get<ApiResponse<RefCamUpdate[]>>(`/maintenance-plan-update/${wagonId}/cam-produced-relating-cam-series`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('existsCAMrelatedCAMseries', { data: [] }))
      );
  }

  /**
   * Find out if there is a non-realized organ CAM associated with the task
   * @param job the task
   * @param component the component 
   */
  public existsNonOrganeCAMassociatedTask(wagonId: number, job: string, component: string): Observable<ApiResponse<RefCamUpdate[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('job', `${job}`);
    parameters = parameters.set('component', `${component}`);

    return this.api.get<ApiResponse<RefCamUpdate[]>>(`/maintenance-plan-update/${wagonId}/non-organe-cam-associated-task`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('existsNonOrganeCAMassociatedTask', { data: [] }))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
