import { Injectable, OnDestroy, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { AuthResponse } from './auth-response';
import { UserInfos } from '../auth/user-infos';
import { Jwt } from './jwt.interface';
import { AuthRoleEnum } from './auth-role.enum';
import { LoggerService } from '../logging/logger.service';
import { StorageService } from '../storage/storage.service';
import { environment } from '../../../../environments/environment';


/**
 * Service pour la gestion de l'authentification
 */
@Injectable()
export class AuthService implements OnDestroy {

  /**
   * Nombre de minute avant l'expiration du token ou le token sera renouvlé
   */
  private readonly TOKEN_RENEW_BEFORE_EXPIRATION_DELAY_MS = 1 * 60 * 1000;

  /**
   * Information sur l'utilisateur connecté
   */
  private user: UserInfos;
  /**
   * id_token et refresh_token
   */
  private auth: AuthResponse;
  /**
   * Date d'expiration du token
   */
  private expires: number;
  /**
   * Timeout pour la déconnexion automatique
   */
  private logouTimeout;
  /**
   * Timeout pour le refresh automatique du token
   */
  private refreshTokenTimeout;
  /**
   * Observable pour savoir si l'utilisateur est connecté
   */
  private isLoggedSubject = new BehaviorSubject<boolean>(false);
  /**
   * storage property's name
   */
  private readonly STORAGE_AUTH_DATA_KEY = 'auth';

  /**
   * AuthService constructor
   * @param http angular Http
   * @param logger log service
   * @param router angular router
   * @param storage storage service
   */
  constructor(private http: HttpClient,
              private logger: LoggerService,
              private router: Router,
              private storage: StorageService,
              @Inject( DOCUMENT ) private document: Document,
              // Get the `PLATFORM_ID` so we can check if we're in a browser.
              @Inject( PLATFORM_ID ) private platformId: InjectionToken<object>) {
    this.logger = logger.newLogger('auth_service');
    // Restore l'authentification depuis le localstorage
    this.restoreAuth();
  }

  /**
   * OnDestroy hook
   */
  ngOnDestroy(): void {
    clearTimeout(this.logouTimeout);
    clearTimeout(this.refreshTokenTimeout);
    this.isLoggedSubject.complete();
  }

  /**
   * Authentifie l'utilisateur au niveau de la FID à partir
   * d'un code d'authorisation
   * @param code - Code d'authorisation
   */
  async authenticate(code: string): Promise<void> {
    try {
      this.auth = await this.getAuthData(code);
      const jwt = this.parseJwt(this.auth.id_token);
      this.user = {
        name: jwt.family_name[0],
        firstName: jwt.first_name[0],
        authorities: jwt.roles,
        cp: jwt.sub,
        mail: jwt.Mail[0]
      };
      this.storeAuth();
      this.handleExpiration();
      this.logger.info('Utilisateur {} connecté avec succès', this.getUsername());
    } catch (e) {
      this.logout();
      this.logger.error('Echec de l\'authentification', e);
      throw e;
    }
  }

  /**
   * Demande un nouveau token à partir du refresh_token
   */
  private refreshToken() {
    this.logger.info('Renouvellement du token');
    this.http.get<AuthResponse>(environment.api_url + '/login', {
        headers: {
        'x-refresh-token': this.getRefreshToken()
      }
    }).subscribe(data => {
      this.auth = data;
      this.storeAuth();
      this.handleExpiration();
    });
  }

  /**
   * Détermine si l'utilisateur est connecté
   */
  public isLogged(): BehaviorSubject<boolean> {
    return this.isLoggedSubject;
  }

  /**
   * Récupère le nom de l'utilisateur connecté
   */
  public getUsername(): string {
    if (!this.user) {
      return null;
    }
    return this.user.name;
  }

  /**
   * Récupère le jeton d'authentification
   */
  public getAccessToken(): string {
    return this.auth.id_token;
  }

  /**
   * Récupère le refresh token
   */
  public getRefreshToken(): string {
    return this.auth.refresh_token;
  }

  /**
   * Récupère la date d'expiration du token
   */
  public getExpires(): number {
    return this.expires;
  }

  /**
   * Enregistre les informations d'authentification
   * dans le localstorage
   */
  private storeAuth() {
    this.logger.debug('Sauvegarde de l\'authentification dans le localstorage');
    this.storage.putJson(this.STORAGE_AUTH_DATA_KEY, {
      user: this.user,
      auth: this.auth
    });

    if (!this.user || !this.auth) {
      this.isLoggedSubject.next(false);
    } else {
      // Only set to true if it is not already, avoid mastheader to perform request with agent service
      if (!this.isLoggedSubject.getValue()) {
        this.isLoggedSubject.next(true);
      }
    }
  }

  /**
   * Récupère l'authentification depuis le localstorage
   */
  private restoreAuth() {
    const storedAuthData = this.storage.getJson(this.STORAGE_AUTH_DATA_KEY);

    if (storedAuthData) {
      this.user = storedAuthData.user || null;
      this.auth = storedAuthData.auth || null;

      if (this.auth && this.auth.id_token) {
        const isTokenValid = this.handleExpiration();
        this.isLoggedSubject.next(isTokenValid);
      } else {
        this.isLoggedSubject.next(false);
      }

      this.logger.debug('Récupération de l\'authentification depuis le localstorage');
    } else {
      this.logger.debug('Authentification non trouvée dans le localstorage');
    }
  }

  /**
   * Récupération de l'id_token et du refresh_token
   * @param code - code d'authorisation
   */
  private getAuthData(code: string): Promise<AuthResponse> {
    return this.http.get<AuthResponse>(environment.api_url + '/login', { params: { code } }).toPromise();
  }

  /**
   * Récupération des informations sur l'utilisateur
   * connnecté
   */
  getUserInfos(): UserInfos {
    return this.user;
  }

  /**
   * Parse un jwt token en objet JSON
   * @param token - Le jwt token
   * @returns le token dechiffré
   */
  private parseJwt(token): Jwt {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  /**
   * Gestion de l'expiration du token
   * @returns true si le token est encore valide
   */
  handleExpiration(): boolean {
    const jwt = this.parseJwt(this.auth.id_token);
    this.expires = jwt.exp * 1000;
    const validForMs = this.expires - new Date().getTime();

    // Si le token est déjà expiré
    if (validForMs <= 0) {
      this.logger.info('Le token est epxiré depuis {} minutes', (-validForMs / 1000) / 60);
      this.logout();
      this.router.navigate(['/session-expired']);
      return false;
    }

    this.logger.info('Expiration du token dans {} minutes', (validForMs / 1000) / 60);

    // Déconnexion automatique
    clearTimeout(this.logouTimeout);
    this.logouTimeout = setTimeout(() => {
      this.logger.info('Expiration de la session');
      this.logout();
      this.router.navigate(['/session-expired']);
    }, validForMs);

    // Refresh automatique du token
    clearTimeout(this.refreshTokenTimeout);
    this.refreshTokenTimeout = setTimeout(() => {
      this.refreshToken();
    }, Math.min( 9 * 60 * 1000, validForMs - this.TOKEN_RENEW_BEFORE_EXPIRATION_DELAY_MS) ); // TODO FIX "inactivity timeout ?"" // validForMs - this.TOKEN_RENEW_BEFORE_EXPIRATION_DELAY_MS);

    return true;
  }

  /**
   * Déconnexion de l'utilisateur
   */
  logout(): void {
    this.logger.info('Déconnexion  de l\'utilisateur');
    this.cleanSessionCookie();
    this.user = null;
    this.auth = null;
    this.expires = null;
    clearTimeout(this.logouTimeout);
    clearTimeout(this.refreshTokenTimeout);
    this.storeAuth();
  }

  cleanSessionCookie() {
    if (isPlatformBrowser( this.platformId )) {
      // delete JSESSIONID cookie avoid matters on deco/reco with short interval
      let cookieString: string = encodeURIComponent( 'JSESSIONID' ) + '=' + encodeURIComponent( '' ) + ';';
      cookieString += 'expires=' + new Date('Thu, 01 Jan 1970 00:00:01 GMT').toUTCString() + ';';
      cookieString += 'path=' + '/margo-wr' + ';';
      cookieString += 'domain=' + this.document.domain + ';';
      this.document.cookie = cookieString;
    }
  }
  /**
   * Autorise l'activation si l'utilisateur est authentifié
   * et possède certains rôles
   * @param roles - Condition sur les rôles
   * @returns true si l'utilisateur possède les bons rôles
   */
  hasRoles(roles: [[AuthRoleEnum] | AuthRoleEnum]): boolean {

    return roles.map((roleDef) => {
      // Si c'est un tableau de rôle => il faut que l'utilisateur
      // possède tous ces rôles
      if (roleDef instanceof Array) {
        return this.hasAllRoles(roleDef);
      } else {
        return this.hasRole(roleDef);
      }
    })
    // Au moins une condition de rôle est satisfaite
      .some(validRole => validRole);
  }

  /**
   * Détermine si l'utilisateur est connecté et possède
   * tous les rôles spécifiés
   * @param roleList - Liste des rôles requis
   * @returns true si l'utilisateur est connecté et possède tous les rôles
   */
  hasAllRoles(roleList: [AuthRoleEnum]): boolean {
    return roleList.every(
      (role) => this.hasRole(role)
    );
  }

  /**
   * Vérifie que l'utilisateur connecté possède un rôle
   * @param role - Le rôle
   * @returns true si l'utilisateur est connecté
   * et possède le rôle
   */
  hasRole(role: AuthRoleEnum): boolean {
    if (!this.user || !this.user.authorities) {
      return false;
    }
    return this.isLogged().getValue() && this.user.authorities.indexOf(role) !== -1;
  }
}
