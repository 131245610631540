import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardService } from '../services/dashboard.service';
import { MissingMaterialProd } from 'src/app/shared/models/missing-material-prod';
import { AgentService } from 'src/app/core/services/agent/agent.service';

@Component({
  selector: 'app-missing-materials',
  templateUrl: './missing-materials.component.html',
  styleUrls: ['./missing-materials.component.scss']
})
export class MissingMaterialsComponent implements OnInit {

  /** Whether datas are still loading */
  isLoadingResults = true;
  /** Retain all subscriptions */
  subscriptionRefs: Subscription[] = [];
  /** Columns to display */
  displayedColumns = ['row'];
  /** Data */
  missingMaterials: MissingMaterialProd[];
  hasData: boolean;
  constructor(
    private dashboardService: DashboardService,
    private agentService: AgentService
  ) { }

  ngOnInit() {
    this.subscriptionRefs.push(
      this.agentService.getAgentData().subscribe(agentData => {
        if (agentData) {
          this.dashboardService.getMissingMaterials(agentData.company.code)
          .subscribe((missingMaterials => {
            this.missingMaterials = missingMaterials.data.filter(missingMaterial => missingMaterial.refMaterialUpdateList.length !== 0);
            this.hasData = this.missingMaterials.length !== 0;
            this.isLoadingResults = false;
          }));
        }
      }
      ));
  }
}
