import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, of, Subscription } from 'rxjs';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { DerogationSystemList } from 'src/app/shared/models/derogation-system-list';
import { LogicalWagon } from 'src/app/shared/models/logical-wagon';
import { TechSpecReferences } from 'src/app/shared/models/tech-spec-references';
import { WagonUpdate } from 'src/app/shared/models/wagon-update';
import { Workshop } from 'src/app/shared/models/workshop';
import { MonitoringWorkService } from 'src/app/shared/services/monitoring-work/monitoring-work.service';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { WagonTechnicalData } from "../../../../models/wagon-technical-data";
import { DialogCancelConfirmComponent } from '../../../dialog-cancel-confirm/dialog-cancel-confirm.component';
import { FamilyRestrictionsComponent } from './family-restrictions/family-restrictions.component';
import { MaintenanceCartridgeComponent } from './maintenance-cartridge/maintenance-cartridge.component';
import { TareLoadsComponent } from './tare-loads/tare-loads.component';
import { TechnicalDataComponent } from './technical-data/technical-data.component';


@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, OnDestroy {

  @Input() public pWagonId: number;
  @Input() public pMex: string;
  @Input() public pInv: string;
  //@Input() public pIsLevel34: boolean;
  @Input() public wagonUpdate: WagonUpdate;
  @Input() public logicalWagons: LogicalWagon[];  
  @Input() public wagonTechnicalDatas: WagonTechnicalData[];
  @ViewChild('maintenanceTab') maintenanceTab: MaintenanceCartridgeComponent;
  @ViewChild('technicalDataTab') technicalDataTab: TechnicalDataComponent;
  @ViewChild('tareLoadsTab') tareLoadsTab: TareLoadsComponent;
  @ViewChild('familyRestrictionsTab') familyRestrictionsTab: FamilyRestrictionsComponent;

  wagonTechnicalData: WagonTechnicalData;
  wagonTechnicalDataWO: WagonTechnicalData;
  maintenanceCartridgeTabDatas: {
    revNormalList: string[],
    revOtherNormalList: string[],
    establishmentList: Workshop[],
    establishmentATSList: Workshop[]
  };
  derogationSystemList: DerogationSystemList;
  techSpecReferences: TechSpecReferences;
  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);

  private _currentTab: number;
  get currentTab(): number {
    return this._currentTab;
  }
  set currentTab(value: number) {
    this._currentTab = value;
    const hostElement = this.host.nativeElement as HTMLElement;
    if (this._currentTab) {
      hostElement.classList.add('opened');
    } else {
      hostElement.classList.remove('opened');
    }
  }
  tabsDirty = [false, false, false, false];
  //showConfirmOnChange: boolean;

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog,
    private monitoringWorkService: MonitoringWorkService,
    private storageService: StorageService,
    private referenceService: ReferenceService,
    private host: ElementRef) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.wagonTechnicalData = this.wagonTechnicalDatas.reduce(
      (prev, curr) => ['13', '14', 'WO'].indexOf(curr.inventory.code) === -1 ? curr : prev, null);
    this.wagonTechnicalDataWO = this.wagonTechnicalDatas.reduce(
        (prev, curr) => curr.inventory.code === 'WO' ? curr : prev, null);
    this.loadingSubject.next(true);
    this.resetDatas();
    this.loadInitDatas();
  }

  /**
   * Reset les donnes
   */
  private resetDatas(): void {
    this.currentTab = undefined;
    this.tabsDirty = [false, false, false, false];
  }

  changeTab(value: number): void {
    if (value === this.currentTab) return;

    if (this.currentTab) {
      if (this.tabsDirty[this.currentTab - 1]) {
        // Show confirm dialog if there's modified data
        const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
          data: {
            title: 'core.messages.not_saved_data',
            message: 'core.messages.save_question',
            cancelLabel: 'actions.quit',
            confirmLabel: 'actions.save'
          }
        });
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.saveCurrentTab();
          } else {
            this.currentTab = value;
          }
        });
      } else {
        this.currentTab = value;
      }
    } else {
      // Change without confirmation
      this.currentTab = value;
    }
  }
  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
    this.subscriptionRefs.push(
      forkJoin([
      (this.storageService.existsStorage('tech-spec-references')
      ? of({data: this.storageService.getStorageJson('tech-spec-references')}) 
      : this.monitoringWorkService.loadTechSpecReferences()),
      //tare-loads
      this.referenceService.getDerogationSystem(this.pWagonId),
      //maintenance-cartridge
      this.referenceService.findNatureREVNormal(this.wagonUpdate.type.code, this.wagonUpdate.maintenanceOperation.code),
      this.referenceService.findNatureREVOtherNormal(this.wagonUpdate.maintenanceOperation.code),
      this.referenceService.findEstablishment(this.wagonUpdate.type.code, this.wagonUpdate.maintenanceOperation.code),
      this.referenceService.findEstablishmentATS(this.wagonUpdate.type.code, this.wagonUpdate.maintenanceOperation.code, this.wagonUpdate.interventionWorkshop.code),
    ])
      .subscribe(([techSpecReferences,
                  derogationSystem,
                  revNormal, revOtherNormal, establishments, establishmentsATS]) => {
      
        this.derogationSystemList = derogationSystem.data,
        this.maintenanceCartridgeTabDatas = {          
          revNormalList: revNormal.data,
          revOtherNormalList: revOtherNormal.data,
          establishmentList: establishments.data,
          establishmentATSList: establishmentsATS.data};
        this.techSpecReferences = techSpecReferences.data;
        if (!this.storageService.existsStorage('tech-spec-references')) {
          this.storageService.putStorageJson('tech-spec-references', this.techSpecReferences);
        }
      }));
      this.loadingSubject.next(false);
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

  close(): void {
    if (this.tabsDirty.reduce((p, c) => p || c, false)) {
      // Show confirm dialog if there's modified data
      const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
        data: {
          title: 'core.messages.not_saved_data',
          message: 'core.messages.save_question',
          cancelLabel: 'actions.quit',
          confirmLabel: 'actions.save'
        }
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.saveDirtyTabs();
        } else {
          this.currentTab = undefined;
        }
      });
    } else {
      // Close without confirmation
      this.currentTab = undefined;
    }
  }

  setTabDirty(formIsDirty: boolean, tab: number): void {
    this.tabsDirty[tab] = formIsDirty;
  }

  async saveDirtyTabs() {
    this.loadingSubject.next(true);
    if (this.tabsDirty[0]) {
      await this.maintenanceTab.onSubmit(false);
    } 
    if (this.tabsDirty[1]) {
      await this.technicalDataTab.saveDirtyTabs();
    } 
    if (this.tabsDirty[2]) {
      await this.tareLoadsTab.onSubmit(false);
    } 
    if (this.tabsDirty[3]) {
      await this.familyRestrictionsTab.onSubmit(false);
    }     
    this.loadingSubject.next(false);
  }

  saveCurrentTab(): void {
    if (this.currentTab === 1) {
      this.maintenanceTab.onSubmit();
    } else if (this.currentTab === 2) {
      this.technicalDataTab.saveDirtyTabs();
    } else if (this.currentTab === 3) {
      this.tareLoadsTab.onSubmit();
    } else if (this.currentTab === 4) {
      this.familyRestrictionsTab.onSubmit();
    }
  }

  
  updateWagTechWO(value: WagonTechnicalData) {
    this.wagonTechnicalDataWO = value;
  }
}
