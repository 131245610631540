import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, merge, Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { TemplatePopupDialogComponent } from 'src/app/shared/components/template-popup-dialog/template-popup-dialog.component';
import { LogicalWagon } from 'src/app/shared/models/logical-wagon';
import { TechSpecReferences } from 'src/app/shared/models/tech-spec-references';
import { WagonUpdate } from 'src/app/shared/models/wagon-update';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { ConstructionType } from "../../../../../models/construction-type";
import { FamilyRestrictions } from "../../../../../models/family-restrictions";
import { NoRIV } from "../../../../../models/no-riv";
import { RepartitionCode } from "../../../../../models/repartition-code";
import { SpecialAgreement } from "../../../../../models/special-agreement";
import { StatisticalCode } from "../../../../../models/statistical-code";
import { UsageRestriction } from "../../../../../models/usage-restriction";
import { WagonTechnicalData } from "../../../../../models/wagon-technical-data";
import { MonitoringWorkService } from "../../../../../services/monitoring-work/monitoring-work.service";
import { ReferenceService } from "../../../../../services/reference/reference.service";
import { MatCheckboxChange } from '@angular/material/checkbox';


@Component({
  selector: 'app-family-restrictions',
  templateUrl: './family-restrictions.component.html',
  styleUrls: ['./family-restrictions.component.scss']
})
export class FamilyRestrictionsComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public wagon: number;
  @Input() public wagonTechnicalData: WagonTechnicalData;
  @Input() public wagonTechnicalDataWO: WagonTechnicalData;
  @Input() public wagonUpdate: WagonUpdate;
  @Input() public logicalWagons: LogicalWagon[];
  @Input() public techSpecReferences: TechSpecReferences;
  @Output() modifiedDataEvent = new EventEmitter();

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);

  wagonLogical: LogicalWagon;
  distributionCodesList: RepartitionCode[];
  constructionTypesList: ConstructionType[];
  statisticalCodesList: StatisticalCode[];
  reasonsList: NoRIV[];
  specialAgreementsList: SpecialAgreement[];
  useRestrictionsList: UsageRestriction[];

  filteredDistributionCodesList: RepartitionCode[];
  filteredConstructionTypesList: ConstructionType[];
  filteredStatisticalCodesList: StatisticalCode[];
  filteredReasonsList: NoRIV[];
  filteredSpecialAgreementsList: SpecialAgreement[];

  familyRestrictionsForm: FormGroup;
  distributionCodeCtrl: FormControl;
  constructionTypeCtrl: FormControl;
  statisticalCodeCtrl: FormControl;
  reasonCtrl: FormControl;
  specialAgreementsCtrl: FormControl;
  useRestrictionsCtrl: FormControl;

  showFamily: boolean;
  showStatisticalCode: boolean
  showRestrictions: boolean;
  showRestriction01Only = false;
  codeRestrictionPossible: boolean;
  statisticalCodesLabel: string;
  reasonLabel: string;
  specialAgreementsLabel: string;
  showSpecialAgreements: boolean;
  useRestrictionsLabel: string;
  submitted: boolean;
  errorMessage: string;
  modifSub: Subscription;
  alert: boolean;
  z1ble = true;
  constructor(private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private referenceService: ReferenceService,
              private monitoringWorkService: MonitoringWorkService,
              private dialog: MatDialog) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.wagonTechnicalDataWO && !changes.wagonTechnicalDataWO.firstChange ) {
      this.subscriptionRefs.push(
          this.referenceService.findUsageRestriction(this.wagonTechnicalData.mex, 'WO')
        .subscribe(useRestrictions => {
          this.useRestrictionsList = useRestrictions.data;

          this.familyRestrictionsForm.get('isHumpBanned').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isHumpBanned : false);
          this.familyRestrictionsForm.get('isRearMandatory').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isRearMandatory : false);
          this.familyRestrictionsForm.get('isCourseLimit').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isCourseLimit : false);
          this.familyRestrictionsForm.get('isPressBanned').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isPressBanned : false);
          this.familyRestrictionsForm.get('isUnalterableTrain').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isUnalterableTrain : false);
          this.familyRestrictionsForm.get('isYardsBanned').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isYardsBanned : false);
          this.familyRestrictionsForm.get('z1').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.code === '01' : false);
          this.useRestrictionsCtrl.setValue(this.wagonTechnicalDataWO.usageRestriction);
          /*this.showRestrictions = this.showRestrictions 
                          && ((this.wagonTechnicalDataWO.materialCategory.materialCategory3.code === '2' && this.wagonTechnicalDataWO.contiguousAxlesSpacing !== 0)
                              || (this.wagonTechnicalDataWO.materialCategory.materialCategory3.code === '1' &&  this.wagonTechnicalDataWO.wheelbase !== 0));*/
          this.showRestriction01Only = ((this.wagonTechnicalDataWO.materialCategory.materialCategory3.code === '2' && this.wagonTechnicalDataWO.contiguousAxlesSpacing === 0)
            || (this.wagonTechnicalDataWO.materialCategory.materialCategory3.code === '1' &&  this.wagonTechnicalDataWO.wheelbase === 0));
        })
      );
    }
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.distributionCodeCtrl = this.formBuilder.control(null, this.validateObject());
    this.constructionTypeCtrl = this.formBuilder.control(null, this.validateObject());
    this.statisticalCodeCtrl = this.formBuilder.control(null, this.validateObject());
    this.reasonCtrl = this.formBuilder.control(null, this.validateObject());
    this.specialAgreementsCtrl = this.formBuilder.control(null, this.validateObject());
    this.useRestrictionsCtrl = this.formBuilder.control(null, this.validateObject());
    this.familyRestrictionsForm = this.formBuilder.group({
      distributionCode: this.distributionCodeCtrl,
      constructionType: this.constructionTypeCtrl,
      statisticalCode: this.statisticalCodeCtrl,
      reason: this.reasonCtrl,
      specialAgreements: this.specialAgreementsCtrl,
      useRestrictions: this.useRestrictionsCtrl,
      isHumpBanned: this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isHumpBanned : false,
      isRearMandatory: this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isRearMandatory : false,
      isCourseLimit: this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isCourseLimit : false,
      isPressBanned: this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isPressBanned : false,
      isUnalterableTrain: this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isUnalterableTrain : false,
      isYardsBanned: this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isYardsBanned : false,
      z1: this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.code === '01' : false,
    });
    
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.modifSub = this.familyRestrictionsForm.valueChanges.subscribe(
      _ => this.modifiedDataEvent.emit(this.familyRestrictionsForm.dirty));

    this.loadInitDatas();
  }

  /**
  * Methode pour init datas
  */
  private loadInitDatas() {
      if (this.wagonTechnicalData) {
        this.wagonLogical = this.getLogicalWagon(this.logicalWagons);
        this.subscriptionRefs.push(
          forkJoin(
            [this.referenceService.findRepartitionCodes(this.wagon, 'WO'),
            this.referenceService.findConstructionType(this.wagon, 'WO'),
            this.referenceService.findStatisticalCode(this.wagon, 'WO'),
            this.referenceService.findNoRIVReason(this.wagonTechnicalData.mex.substr(0, 2)),
            this.referenceService.findUsageRestriction(this.wagonTechnicalDataWO.mex, 'WO')]
          ).subscribe(([rCodes, cTypes, sCodes,reasons, useRestrictions]) => {
            this.distributionCodesList = rCodes.data;
            this.constructionTypesList = cTypes.data;
            this.statisticalCodesList = sCodes.data;
            this.reasonsList = reasons.data;
            this.specialAgreementsList = this.techSpecReferences.specialAgreementList;
            this.useRestrictionsList = useRestrictions.data;

            this.filteredDistributionCodesList = this.distributionCodesList;
            this.filteredConstructionTypesList = this.constructionTypesList;
            this.filteredStatisticalCodesList = this.statisticalCodesList;
            this.filteredReasonsList = this.reasonsList;
            this.filteredSpecialAgreementsList = this.specialAgreementsList;

            this.subscriptionRefs.push(this.familyRestrictionsForm.get('distributionCode').valueChanges
                .pipe(debounceTime(10)).subscribe( value => {
                  this.filteredDistributionCodesList = this.filterWithCodeLabel(value, this.distributionCodesList);
            }));
            this.subscriptionRefs.push(this.familyRestrictionsForm.get('constructionType').valueChanges
                .pipe(debounceTime(10)).subscribe( value => {
                  this.filteredConstructionTypesList = this.filterWithCodeLabel(value, this.constructionTypesList);
            }));
            this.subscriptionRefs.push(this.familyRestrictionsForm.get('statisticalCode').valueChanges
                .pipe(debounceTime(10)).subscribe( value => {
                  this.filteredStatisticalCodesList = this.filterWithCodeLabel(value, this.statisticalCodesList);
            }));
            this.subscriptionRefs.push(this.familyRestrictionsForm.get('reason').valueChanges
                .pipe(debounceTime(10)).subscribe( value => {
                  this.filteredReasonsList = this.filterWithCodeLabel(value, this.reasonsList);
            }));
            this.subscriptionRefs.push(this.familyRestrictionsForm.get('specialAgreements').valueChanges
                .pipe(debounceTime(10)).subscribe( value => {
                  this.filteredSpecialAgreementsList = this.filterWithCodeLabel(value, this.specialAgreementsList);
            }));
            
            this.resetDatas();
            
            this.subscriptionRefs.push(
              merge(this.familyRestrictionsForm.get('isHumpBanned').valueChanges,
                this.familyRestrictionsForm.get('isRearMandatory').valueChanges,
                this.familyRestrictionsForm.get('isCourseLimit').valueChanges,
                this.familyRestrictionsForm.get('isPressBanned').valueChanges,
                this.familyRestrictionsForm.get('isUnalterableTrain').valueChanges,
                this.familyRestrictionsForm.get('isYardsBanned').valueChanges).subscribe( value => {
                  const useRestriction = this.useRestrictionsList.find( e => e.code !== '01' 
                                                      && e.isCourseLimit === this.familyRestrictionsForm.get('isCourseLimit').value 
                                                      && e.isHumpBanned === this.familyRestrictionsForm.get('isHumpBanned').value 
                                                      && e.isPressBanned === this.familyRestrictionsForm.get('isPressBanned').value 
                                                      && e.isRearMandatory === this.familyRestrictionsForm.get('isRearMandatory').value 
                                                      && e.isUnalterableTrain === this.familyRestrictionsForm.get('isUnalterableTrain').value 
                                                      && e.isYardsBanned === this.familyRestrictionsForm.get('isYardsBanned').value );
                  this.z1ble = !(this.familyRestrictionsForm.get('isCourseLimit').value
                            || this.familyRestrictionsForm.get('isHumpBanned').value 
                            || this.familyRestrictionsForm.get('isPressBanned').value 
                            || this.familyRestrictionsForm.get('isRearMandatory').value 
                            || this.familyRestrictionsForm.get('isUnalterableTrain').value 
                            || this.familyRestrictionsForm.get('isYardsBanned').value);
                  if (useRestriction) {
                    this.useRestrictionsCtrl.setValue(useRestriction);
                  } else {
                    if (this.z1ble) {
                      this.useRestrictionsCtrl.setValue(null);
                      //this.useRestrictionsCtrl.setValue(this.useRestrictionsList.find( e => e.code === '01'));
                      //this.familyRestrictionsForm.get('z1').setValue(true);
                    } else {
                      this.useRestrictionsCtrl.setValue('Combinaison de restrictions invalide pour ce wagon');
                    }
                  }
              })
            );
            this.loadingSubject.next(false);
          })
        );
      } else {
        this.loadingSubject.next(false);
      }
  }
  set01(event: MatCheckboxChange ) {
    if (event.checked) {
      this.useRestrictionsCtrl.setValue(this.useRestrictionsList.find( e => e.code === '01'));
    } else {
      const useRestriction = this.useRestrictionsList.find( e => e.code !== '01' 
                                                      && !e.isCourseLimit
                                                      && !e.isHumpBanned
                                                      && !e.isPressBanned
                                                      && !e.isRearMandatory
                                                      && !e.isUnalterableTrain
                                                      && !e.isYardsBanned);
      if (useRestriction) {
        this.useRestrictionsCtrl.setValue(useRestriction);
      } else {
        this.useRestrictionsCtrl.setValue(null);
      }
    }
    this.familyRestrictionsForm.markAsDirty();
  }

  deleteRestriction() {
    this.familyRestrictionsForm.get('isHumpBanned').setValue(false);
    this.familyRestrictionsForm.get('isRearMandatory').setValue(false);
    this.familyRestrictionsForm.get('isCourseLimit').setValue(false);
    this.familyRestrictionsForm.get('isPressBanned').setValue(false);
    this.familyRestrictionsForm.get('isUnalterableTrain').setValue(false);
    this.familyRestrictionsForm.get('isYardsBanned').setValue(false);
    this.familyRestrictionsForm.get('z1').setValue(false);
    this.useRestrictionsCtrl.setValue(null);
    this.familyRestrictionsForm.markAsDirty();
  }
  /**
   * Reset les donnes
   */
  resetDatas(): void {
    if (this.wagonTechnicalDataWO) {
      if (!this.distributionCodesList) {
        this.distributionCodesList = [];
      }
      this.distributionCodeCtrl.setValue( this.distributionCodesList.find(e => e.code === this.wagonTechnicalDataWO.divisionCode));
      this.constructionTypeCtrl.setValue(this.wagonTechnicalDataWO.constructionType);
      this.statisticalCodeCtrl.setValue(this.wagonTechnicalDataWO.statisticalCode);
      this.reasonCtrl.setValue(this.wagonTechnicalDataWO.noRIV);
      this.specialAgreementsCtrl.setValue(this.wagonTechnicalDataWO.specialAgreement);
      // this.familyRestrictionsForm.get('useRestrictions').setValue(this.wagonTechnicalDataWO.usageRestriction);
      this.familyRestrictionsForm.get('isHumpBanned').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isHumpBanned : false);
      this.familyRestrictionsForm.get('isRearMandatory').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isRearMandatory : false);
      this.familyRestrictionsForm.get('isCourseLimit').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isCourseLimit : false);
      this.familyRestrictionsForm.get('isPressBanned').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isPressBanned : false);
      this.familyRestrictionsForm.get('isUnalterableTrain').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isUnalterableTrain : false);
      this.familyRestrictionsForm.get('isYardsBanned').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.isYardsBanned : false);
      this.familyRestrictionsForm.get('z1').setValue(this.wagonTechnicalDataWO.usageRestriction ? this.wagonTechnicalDataWO.usageRestriction.code === '01' : false);
      this.useRestrictionsCtrl.setValue(this.wagonTechnicalDataWO.usageRestriction);
      // RG_MAJ_003_6_2
      this.showFamily = (["00", "01", "03", "05", "06"].filter(e => e === this.wagonUpdate.type.code).length > 0);
      // RI_MAJ_003_6_3
      this.showStatisticalCode = (["2", "3", "4", "5"].filter(e => e === this.wagonLogical.operatingGroup.code).length > 0);
      // RG_MAJ_003_6_3
      this.showRestrictions = ["00", "01", "03", "05"].filter(e => e === this.wagonUpdate.type.code).length > 0;
      // RG_MAJ_003_6_1
      /*this.showRestrictions = this.showRestrictions 
        && ((this.wagonTechnicalDataWO.materialCategory.materialCategory3.code === '2' && this.wagonTechnicalDataWO.contiguousAxlesSpacing !== 0)
            || (this.wagonTechnicalDataWO.materialCategory.materialCategory3.code === '1' &&  this.wagonTechnicalDataWO.wheelbase !== 0));*/
      this.showRestriction01Only = ((this.wagonTechnicalDataWO.materialCategory.materialCategory3.code === '2' && this.wagonTechnicalDataWO.contiguousAxlesSpacing === 0)
            || (this.wagonTechnicalDataWO.materialCategory.materialCategory3.code === '1' &&  this.wagonTechnicalDataWO.wheelbase === 0));
      this.showSpecialAgreements = !!this.reasonCtrl.value;
      if (this.reasonsList.length) {
        this.reasonCtrl.setValidators([Validators.required, this.validateObject()]);
      }
      if (!this.statisticalCodesList && this.wagonTechnicalDataWO.statisticalCode) {
        this.statisticalCodesLabel = this.wagonTechnicalDataWO.statisticalCode.code 
                + " - " + this.wagonTechnicalDataWO.statisticalCode.label;
      }
      
      if (!this.reasonsList) {
        this.reasonLabel = this.displayCodeComplLabel(this.wagonTechnicalDataWO.noRIV);
      }
      this.specialAgreementsLabel = this.displayCodeComplLabel(this.wagonTechnicalDataWO.specialAgreement);
      
      if (!this.useRestrictionsList) {
        this.useRestrictionsLabel = this.displayCodeLabel(this.wagonTechnicalDataWO.usageRestriction);
      }
    } else {
      this.distributionCodeCtrl.setValue(null);
      this.constructionTypeCtrl.setValue(null);
      this.statisticalCodeCtrl.setValue(null);
      this.reasonCtrl.setValue(null);
      this.specialAgreementsCtrl.setValue(null);
      this.familyRestrictionsForm.get('isHumpBanned').setValue(false);
      this.familyRestrictionsForm.get('isRearMandatory').setValue(false);
      this.familyRestrictionsForm.get('isCourseLimit').setValue(false);
      this.familyRestrictionsForm.get('isPressBanned').setValue(false);
      this.familyRestrictionsForm.get('isUnalterableTrain').setValue(false);
      this.familyRestrictionsForm.get('isYardsBanned').setValue(false);
      this.familyRestrictionsForm.get('z1').setValue(false);
      this.useRestrictionsCtrl.setValue(null);
    }
    this.errorMessage = '';
    this.familyRestrictionsForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
    this.z1ble = !(this.familyRestrictionsForm.get('isCourseLimit').value
                            || this.familyRestrictionsForm.get('isHumpBanned').value 
                            || this.familyRestrictionsForm.get('isPressBanned').value 
                            || this.familyRestrictionsForm.get('isRearMandatory').value 
                            || this.familyRestrictionsForm.get('isUnalterableTrain').value 
                            || this.familyRestrictionsForm.get('isYardsBanned').value);
  }

  /**
   * Methode to submit the form
   */
  async onSubmit(alert?: boolean): Promise<void> {
    this.alert = alert === undefined ? true : alert;
    this.submitted = true;
    this.errorMessage = this.checkValidationRules();
    if (this.errorMessage !== '') {
      return;
    }

    await this.controlFamilyRestrictions();
  }

  async doSubmit(): Promise<void> {
    const familyRestrictions = new FamilyRestrictions();
    familyRestrictions.divisionCode = this.distributionCodeCtrl.value?.code;
    familyRestrictions.constructionType = this.constructionTypeCtrl.value;
    familyRestrictions.statisticalCode = this.statisticalCodeCtrl.value;
    familyRestrictions.mexToBeModifier = this.wagonTechnicalDataWO.mex;
    if (this.reasonCtrl.value) {
      familyRestrictions.noRIV = this.reasonCtrl.value;
    }
    familyRestrictions.specialAgreement = this.specialAgreementsCtrl.value;
    familyRestrictions.usageRestriction = this.useRestrictionsCtrl.value;
    familyRestrictions.wagonUpdate = this.wagonUpdate;

    this.loadingSubject.next(true);
    const tech = await this.monitoringWorkService.updateFamilyRestrictions(this.wagonTechnicalData.wagon.id, familyRestrictions).pipe(first()).toPromise();
    
    Object.entries(tech.data).forEach( pair => {
      this.wagonTechnicalDataWO[pair[0]] = pair[1];
    });
    if (tech.data.mex !== this.wagonUpdate.mex) {
      this.wagonUpdate.exMex = this.wagonUpdate.mex;
      this.wagonUpdate.mex = tech.data.mex;
    }
    this.loadingSubject.next(false);
    this.familyRestrictionsForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
    this.submitted = false;
    this.updateDone();
  }

  checkValidationRules(): string {
    
    // RI_MAJ_003_6_7
    if (!this.constructionTypeCtrl.value) {
      this.constructionTypeCtrl.setErrors(Validators.required);
      return this.translateService.instant("wagon.technical.family_restrictions.validation_error");
    }

    // RI_MAJ_003_6_7 
    if (this.wagonLogical.operatingGroup.code === "1" && !this.distributionCodeCtrl.value) {
      this.distributionCodeCtrl.setErrors(Validators.required);
      return this.translateService.instant("wagon.technical.family_restrictions.validation_error");
    }

    // RI_MAJ_003_6_8
    if ((this.wagonLogical.operatingGroup.code === "2" || this.wagonLogical.operatingGroup.code === "3"
        || this.wagonLogical.operatingGroup.code === "4") 
        && (!this.statisticalCodeCtrl.value)) {
      this.statisticalCodeCtrl.setErrors(Validators.required);
      return this.translateService.instant("wagon.technical.family_restrictions.validation_error");
    }

    return '';
  }

  async controlFamilyRestrictions(): Promise<void> {
    const familyRestrictions = new FamilyRestrictions();
    familyRestrictions.divisionCode = this.distributionCodeCtrl.value?.code;
    familyRestrictions.constructionType = this.constructionTypeCtrl.value;
    familyRestrictions.mexToBeModifier = this.wagonTechnicalDataWO.mex;
    const errorMessage = await this.monitoringWorkService.controlFamilyRestrictions(this.wagonTechnicalData.wagon.id, familyRestrictions).pipe(first()).toPromise();
    if (errorMessage && errorMessage.data && errorMessage.data.message) {
        await this.showAlertMessage(errorMessage.data.message);
    } else {
        await this.doSubmit();
    }
  }
      
  /**
  * Show Alert message
  * @param msg
  */
  async showAlertMessage(msg: string): Promise<void> {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.minWidth = '20rem';
  
      dialogConfig.data = {
        namePopUp: 'alert_msg',
        msgPopUp: msg
      };
  
      const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
      const confirm = await dialogRef.afterClosed().pipe(first()).toPromise()
      this.dialog.closeAll();
  }

  private getValueByCode<T>(list: T[], code: string): T {
    if (!list) return null;
    const el = list.find(e => e['code'] === code);
    return el?el['code']:null;
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

  reasonModified(option: MatSelectChange): void {
    if (option) {
      this.showSpecialAgreements = true;
    } else {
      this.showSpecialAgreements = false;
    }
  }

  /**
   * Popup message. Closed after 5 seconds.
   */
  private updateDone() {
  if (this.alert) {
    const dialogConfig = new MatDialogConfig();      
    dialogConfig.disableClose = true;
    const message = this.translateService.instant('wagon.technical.update-done.msg');        
    const timeout = UtilityService.UPDATE_DONE_TIMEOUT;        
    dialogConfig.data = {
      namePopUp: 'update-done-alert',
      titlePopUp: message,
      msgPopUp: message
    };        
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(_ => {
        setTimeout(() => {
        dialogRef.close();
        this.dialog.closeAll();
        }, timeout)
    });
  }
}

    /**
    * Filter array of logical wagon to distinguish inventory
    * @param datas array of logical wagon
    */
  private getLogicalWagon(datas: LogicalWagon[]): LogicalWagon {
    return datas.filter(w => w.inventory.code === this.wagonTechnicalData.inventory.code)[0];
  }

  validateObject(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let val = control.value;
      if (!val) {
        if (val === ''){
          control.setValue(null);
        }
        return null;
      } else if (typeof val === 'object') {
        return null;
      }
      return { validateObject: true };
    };
  }
  private filterWithCodeLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + v.label).toUpperCase().includes(filterValue))
      ) : list;
  }
  displayCodeLabel = (obj: any) => {
    return obj ? (obj.code ? obj.code + ' - ' + obj.label : obj) : '';
  }

  displayCodeComplLabel = (obj: any) => {
    return obj ? (obj.code ? obj.code + ' - ' + (obj.complement ? obj.complement + ' - ' : '') + obj.label : obj) : '';
  }
  
  displayCode = (obj: any) => {
    return obj ? (obj.code ? obj.code : obj) : '';
  }
}
