import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import { Injectable } from '@angular/core';
@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {

  public handlers: { [key: string]: DetachedRouteHandle } = {};

  private reusedRoutes: RegExp[] = [
    /^\/update\/visits\/$/,
    /^\/update\/billing\/$/,
    /^\/update\/deals\/$/,
    /^\/update\/vpi.*$/,
    /^\/update\/anomalies\/$/,
    /^\/administration\/cleareances\/search\/users$/,
    /^\/consultation\/tabco\/$/
  ];

  previousUrl: string;

  constructor() {}

  public calcKey(route: ActivatedRouteSnapshot) {
    let next = route;
    while (next.firstChild) {
      next = next.firstChild;
    }
    const segments: string[] = [];
    while (next) {
      segments.push(next.url.join('/'));
      next = next.parent;
    }
    const url = segments.reverse().join('/');
    return url;
  }
  /**
   * Determines if this route (and its subtree) should be detached to be reused later.
   */
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const key = this.calcKey(route);
    for (const iterator of this.reusedRoutes) {
      if (key.match(iterator)) {
        return true;
       }
    }
    return false;
  }
  /**
   * Stores the detached route.
   */
  public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.handlers[this.calcKey(route)] = handle;
  }
  /**
   * Determines if this route (and its subtree) should be reattached.
   */
  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    /*if (this.previousUrl === '/update/deals/' && this.calcKey(route) === '/update/deals/new-deal') {
      return false;
    }*/
    return !!route.routeConfig && !!this.handlers[this.calcKey(route)];
  }
  /**
   * Retrieves the previously stored route.
   */
  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig) {
        return null;
    }
    return this.handlers[this.calcKey(route)];
  }
  /**
   * Determines if a route should be reused.
   */
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    this.previousUrl = this.calcKey(curr);
    return (this.calcKey(curr) === this.calcKey(future));
  }
}
