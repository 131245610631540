import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CamTask } from 'src/app/shared/models/cam-task';
import { MonitoringWorkService } from 'src/app/shared/services/monitoring-work/monitoring-work.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TemplatePopupDialogComponent } from '../../../template-popup-dialog/template-popup-dialog.component';



@Component({
  selector: 'app-cam-linked-task-dialog',
  templateUrl: './cam-linked-task-dialog.component.html',
  styleUrls: ['./cam-linked-task-dialog.component.scss']
})
export class CamLinkedTaskDialogComponent implements OnInit, OnDestroy {

  /** Open form group */
  taskForm: FormGroup;
  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** submitted property */
  submitted: boolean;
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Current wagon */
  currentWagon: number;
  /** CAM */
  cam: string;
  /** CASE */
  number_case: number;
  /** TYPE CASE */
  type_case: string;

  taskChecked: boolean;

  /** CamTask datas*/
  camTaskList: CamTask[];
  /** CamTask Added datas */
  camTaskCheckedList: CamTask[] = [];

  displayedColumns: string[] = ['codeGroup', 'codeOrgane', 'labelOrgane', 'job'];

  camTaskDS: MatTableDataSource<CamTask>;

  constructor(
    public dialogRef: MatDialogRef<CamLinkedTaskDialogComponent>,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private monitoringWorkService: MonitoringWorkService) {

    this.currentWagon = data.wagon;
    const str: string = data.caseNumber;
    this.number_case = + str.charAt(str.length - 1);
    this.cam = data.cam;
    this.type_case = data.caseType;
    
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.taskForm = this.formBuilder.group({
      //refMaterials: this.formBuilder.control(this.formBuilder.array([]))
    });
    this.taskForm.addControl('camTasks', this.formBuilder.array([]));
    this.resetDatas();
    this.loadInitDatas();

  }

  /**
   * Reset les donnes 
   */
  private resetDatas(): void {
    this.submitted = false;
    this.taskChecked = false;

  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
    this.loadingSubject.complete();
  }

  /**
   * Methode pour init datas 
   */
  private loadInitDatas() {
    this.subscriptionRefs.push(
      this.monitoringWorkService.getCamTask(this.cam, this.number_case)
        .subscribe((tasks) => {
          this.camTaskList = tasks.data;
          this.camTaskDS = new MatTableDataSource<CamTask>(this.camTaskList);

          if (this.camTaskList && this.camTaskList.length === 1) {

            this.getCamTask().push(this.createCamTask(this.camTaskList[0], true));

          } else if (this.camTaskList && this.camTaskList.length > 1) {
            this.camTaskList.forEach(element => {
              this.getCamTask().push(this.createCamTask(element, false));
            });
          }
          this.taskChecked = this.taskIsChecked();
          this.loadingSubject.next(false);
        })
    );
  }

  /**
   * Methode to create a CamTask element
   * @param cam 
   * @param checked 
   */
  createCamTask(cam: CamTask, pChecked: Boolean): FormGroup {
    return this.formBuilder.group({
      checked: this.formBuilder.control(pChecked),
      codeGroup: this.formBuilder.control(cam.codeGroup),
      codeOrgane: this.formBuilder.control(cam.codeOrgane),
      labelGroup: this.formBuilder.control(cam.labelGroup),
      labelOrgane: this.formBuilder.control(cam.labelOrgane),
      job: this.formBuilder.control(cam.job),
      labelJob: this.formBuilder.control(cam.labelJob),
      amount: this.formBuilder.control(cam.amount),
      cam: this.formBuilder.control(cam.cam)

    });
  }


  getCamTask(): FormArray {
    return (this.taskForm.get('camTasks') as FormArray)
  }

  /**
   * convenience getter for easy access to form fields
   */
  get f() { return this.taskForm.controls; }

  displayByCode(data: any) {
    return data ? (data.code ? data.code : '') : '';
  }

  displayCodeLabel(data: any) {
    return data ? (data.code ? data.code + ': ' + data.label : data) : '';
  }

  /**
  * Methode to clean alerts errors.
  */
  disableAlerts() {
    if (this.submitted) {
      this.submitted = false;
    }
  }

  /**
   * Methode to cancel operation
  */
  cancel() {
    this.dialog.closeAll();
  }

  /**
   * Methode to show openCancellationCamLinkedTaskDialog Dialog
   */
  openCancellationCamLinkedTaskDialog() {

    if (this.taskChecked) {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      let title = this.translateService.instant('wagon-update.preparation-work.monitoring-work.cam_linked_task.table.title_cancellation');
      let message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.cam_linked_task.table.cancellation_message');

      dialogConfig.data = {
        namePopUp: 'cancellationCamLinkedTask',
        titlePopUp: title,
        msgPopUp: message
      };

      const dRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
      dRef.afterClosed().subscribe (res => {
        if (res) {
          this.dialogRef.close(false);
        }
      });
    } else {
      this.dialogRef.close(false);
    }
  }

  /**
   * Click sur checkBox de CAM linked Task
   * @param index 
   */
  clickCkeckBox(index: number) {
    this.taskChecked = this.taskIsChecked();
  }

  /**
   * Submit method
   */
  onSubmit() {
    this.taskIsChecked();
    this.getTaskChecked();
    this.subscriptionRefs.push(
      this.monitoringWorkService.createCamTask(this.currentWagon, this.camTaskCheckedList, this.number_case)
      .subscribe((msg => {
        if (msg.data == undefined) {
           //Creation end, we show the 'Update taks' pop up
           this.updateDone();
                
        } else {
          //show alert Message Pop UP
          this.showAlertMessage();
        }
      }))
    );
  }

   /**
   * Le message se ferme quand 5 seconds sont pasé
   */
    updateDone(): void {
      const dialogConfig = new MatDialogConfig();  
      dialogConfig.disableClose = true;
      const title = this.translateService.instant('wagon-update.task.update-done.msg');
      const message = this.translateService.instant('wagon-update.task.update-done.msg');  
      const timeout = UtilityService.UPDATE_DONE_TIMEOUT;  
      dialogConfig.data = {
        namePopUp: 'update-done-alert',
        titlePopUp: title,
        msgPopUp: message
      };  
      const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
      dialogRef.afterOpened().subscribe(_ => {
        setTimeout(() => {
          dialogRef.close();
          this.dialogRef.close(true);
        }, timeout)
      })
  
    }

  /**
   * Show PopUp Alert Message
   */
  showAlertMessage() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';
    let message = this.translateService.instant('wagon-update.preparation-work.cams_defaults.delete.msg_igl_wagon');
    dialogConfig.data = {
      namePopUp: 'alert_msg',
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      this.dialog.closeAll();
      this.ngOnInit();
    });
  }

  /**
   * Methode to check if there are task checked
   * return boolean
   */
  taskIsChecked(): boolean {
    let checked: boolean;
    checked = false;
    for (var i = 0; i < this.getCamTask().controls.length; i++) {
      if (this.getCamTask().controls[i].value.checked) {
        checked = true;
      }
    }
    return checked;
  }

  /**
   * Methode to get task checked
   */
  getTaskChecked(){
    for (var i = 0; i < this.getCamTask().controls.length; i++) {
      if (this.getCamTask().controls[i].value.checked) {
        this.camTaskCheckedList.push(this.getCamTask().controls[i].value);
      }
    }
  }
}
