import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from "moment";
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TemplatePopupDialogComponent } from 'src/app/shared/components/template-popup-dialog/template-popup-dialog.component';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TechSpecSuperstructure } from "../../../../../../models/tech-spec-superstructure";
import { WagonTechnicalData } from "../../../../../../models/wagon-technical-data";
import { MonitoringWorkService } from "../../../../../../services/monitoring-work/monitoring-work.service";


@Component({
  selector: 'app-open-wagon-superstructure',
  templateUrl: './open-wagon-superstructure.component.html',
  styleUrls: ['./open-wagon-superstructure.component.scss']
})
export class OpenWagonSuperstructureComponent implements OnInit, OnDestroy {

  @Input() public wagonTechnicalData: WagonTechnicalData;
  @Output() modifiedDataEvent = new EventEmitter();

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  superstructureForm: FormGroup;
  dateCtrl: FormControl;
  constructionWorkshopCtrl: FormControl;
  wallCtrl: FormControl;
  roofNatCtrl: FormControl;
  roofTypeCtrl: FormControl;
  floorCtrl: FormControl;
  volumeCtrl: FormControl;
  usefulAreaCtrl: FormControl;
  unloadingAmountCtrl: FormControl;
  loadingAmountCtrl: FormControl;
  sideAmountCtrl: FormControl;
  usefulLengthCtrl: FormControl;
  usefulWidthCtrl: FormControl;
  usefulWallHeightCtrl: FormControl;
  usefulMiddleHeightCtrl: FormControl;
  loadPlanHeightCtrl: FormControl;
  unloadingLengthCtrl: FormControl;
  unloadingWidthCtrl: FormControl;
  unloadingHeightRailCtrl: FormControl;
  loadingLengthCtrl: FormControl;
  loadingWidthCtrl: FormControl;
  sideHeightCtrl: FormControl;
  sideWidthCtrl: FormControl;

  techSpecSuperstructure: TechSpecSuperstructure;

  showVehicle: boolean;
  showUsefulDimensions: boolean;
  showLoadingOpening: boolean;
  showUnloadingOpening: boolean;
  showSideOpening: boolean;

  submitted: boolean;
  alert: boolean;
   /** Error message*/
  errorMessage: string;

  dateToday = new Date();
  modifSub: Subscription;

  constructor(private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private monitoringWorkService: MonitoringWorkService,
              private dialog: MatDialog) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.dateCtrl = this.formBuilder.control(new Date());
    this.constructionWorkshopCtrl = this.formBuilder.control(null);
    this.wallCtrl = this.formBuilder.control(null);
    this.roofNatCtrl = this.formBuilder.control(null);
    this.roofTypeCtrl = this.formBuilder.control(null);
    this.floorCtrl = this.formBuilder.control(null);
    this.volumeCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,3}(\.\d{1,3})?$/));
    this.usefulAreaCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,3}(\.\d)?$/));
    this.unloadingAmountCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,3}$/));
    this.loadingAmountCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,3}$/));
    this.sideAmountCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,3}$/));
    this.usefulLengthCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,2}(\.\d)?$/));
    this.usefulWidthCtrl = this.formBuilder.control(null, Validators.pattern(/^\d(\.\d{1,2})?$/));
    this.usefulWallHeightCtrl = this.formBuilder.control(null, Validators.pattern(/^\d(\.\d{1,2})?$/));
    this.usefulMiddleHeightCtrl = this.formBuilder.control(null, Validators.pattern(/^\d(\.\d{1,2})?$/));
    this.loadPlanHeightCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,2}(\.\d{1,2})?$/));
    this.unloadingLengthCtrl = this.formBuilder.control(null, Validators.pattern(/^\d(\.\d{1,2})?$/));
    this.unloadingWidthCtrl = this.formBuilder.control(null, Validators.pattern(/^\d(\.\d{1,2})?$/));
    this.unloadingHeightRailCtrl = this.formBuilder.control(null, Validators.pattern(/^\d(\.\d{1,2})?$/));
    this.loadingLengthCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,2}(\.\d{1,2})?$/));
    this.loadingWidthCtrl = this.formBuilder.control(null, Validators.pattern(/^\d(\.\d{1,2})?$/));
    this.sideHeightCtrl = this.formBuilder.control(null, Validators.pattern(/^\d(\.\d{1,2})?$/));
    this.sideWidthCtrl = this.formBuilder.control(null, Validators.pattern(/^\d(\.\d{1,2})?$/));

    this.superstructureForm = this.formBuilder.group({
      date: this.dateCtrl,
      constructionWorkshop: this.constructionWorkshopCtrl,
      wall: this.wallCtrl,
      roofNat: this.roofNatCtrl,
      roofType: this.roofTypeCtrl,
      floor: this.floorCtrl,
      volume: this.volumeCtrl,
      usefulArea: this.usefulAreaCtrl,
      unloadingAmount: this.unloadingAmountCtrl,
      loadingAmount: this.loadingAmountCtrl,
      sideAmount: this.sideAmountCtrl,
      usefulLength: this.usefulLengthCtrl,
      usefulWidth: this.usefulWidthCtrl,
      usefulWallHeight: this.usefulWallHeightCtrl,
      usefulMiddleHeight: this.usefulMiddleHeightCtrl,
      loadPlanHeight: this.loadPlanHeightCtrl,
      unloadingLength: this.unloadingLengthCtrl,
      unloadingWidth: this.unloadingWidthCtrl,
      unloadingHeightRail: this.unloadingHeightRailCtrl,
      loadingLength: this.loadingLengthCtrl,
      loadingWidth: this.loadingWidthCtrl,
      sideHeight: this.sideHeightCtrl,
      sideWidth: this.sideWidthCtrl
    });

    
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.modifSub = this.superstructureForm.valueChanges.subscribe(
      _ => this.modifiedDataEvent.emit(this.superstructureForm.dirty));

    this.loadInitDatas();
  }

  /**
   * Reset les donnes
   */
  resetDatas(): void {
    if (!this.techSpecSuperstructure) {
      this.techSpecSuperstructure = new TechSpecSuperstructure();
    }

    this.dateCtrl.setValue(this.techSpecSuperstructure.dateConstruction);
    this.constructionWorkshopCtrl.setValue(this.techSpecSuperstructure.constructionWorkshop?this.techSpecSuperstructure.constructionWorkshop.code:null);
    this.wallCtrl.setValue(this.techSpecSuperstructure.wallNature?this.techSpecSuperstructure.wallNature.code:null);
    this.roofNatCtrl.setValue(this.techSpecSuperstructure.roofNature?this.techSpecSuperstructure.roofNature.code:null);
    this.roofTypeCtrl.setValue(this.techSpecSuperstructure.roofType?this.techSpecSuperstructure.roofType.code:null);
    this.floorCtrl.setValue(this.techSpecSuperstructure.floorNature?this.techSpecSuperstructure.floorNature.code:null);
    this.volumeCtrl.setValue(this.techSpecSuperstructure.volume?this.techSpecSuperstructure.volume:0);
    this.usefulAreaCtrl.setValue(this.techSpecSuperstructure.usefulArea?this.techSpecSuperstructure.usefulArea:0);
    this.usefulLengthCtrl.setValue(this.techSpecSuperstructure.usefulLength?this.techSpecSuperstructure.usefulLength:0);
    this.usefulWidthCtrl.setValue(this.techSpecSuperstructure.usefulWidth?this.techSpecSuperstructure.usefulWidth:0);
    this.usefulWallHeightCtrl.setValue(this.techSpecSuperstructure.usefulWallHeight?this.techSpecSuperstructure.usefulWallHeight:0);
    this.usefulMiddleHeightCtrl.setValue(this.techSpecSuperstructure.usefulMiddleHeight?this.techSpecSuperstructure.usefulMiddleHeight:0);
    this.loadPlanHeightCtrl.setValue(this.techSpecSuperstructure.loadPlanHeight?this.techSpecSuperstructure.loadPlanHeight:0);
    this.unloadingAmountCtrl.setValue(this.techSpecSuperstructure.unloadingOpeningCount?this.techSpecSuperstructure.unloadingOpeningCount:0);
    this.unloadingLengthCtrl.setValue(this.techSpecSuperstructure.unloadingOpeningLength?this.techSpecSuperstructure.unloadingOpeningLength:0);
    this.unloadingWidthCtrl.setValue(this.techSpecSuperstructure.unloadingOpeningWidth?this.techSpecSuperstructure.unloadingOpeningWidth:0);
    this.unloadingHeightRailCtrl.setValue(this.techSpecSuperstructure.unloadingOpeningHeight?this.techSpecSuperstructure.unloadingOpeningHeight:0);
    this.loadingAmountCtrl.setValue(this.techSpecSuperstructure.loadingOpeningCount?this.techSpecSuperstructure.loadingOpeningCount:0);
    this.loadingLengthCtrl.setValue(this.techSpecSuperstructure.loadingOpeningLength?this.techSpecSuperstructure.loadingOpeningLength:0);
    this.loadingWidthCtrl.setValue(this.techSpecSuperstructure.loadingOpeningWidth?this.techSpecSuperstructure.loadingOpeningWidth:0);
    this.sideAmountCtrl.setValue(this.techSpecSuperstructure.sideOpeningCount?this.techSpecSuperstructure.sideOpeningCount:0);
    this.sideHeightCtrl.setValue(this.techSpecSuperstructure.sideOpeningHeight?this.techSpecSuperstructure.sideOpeningHeight:0);
    this.sideWidthCtrl.setValue(this.techSpecSuperstructure.sideOpeningWidth?this.techSpecSuperstructure.sideOpeningWidth:0);

    this.refreshPanels();

    this.submitted = false;
    this.errorMessage = "";

    if (this.techSpecSuperstructure.wallNatures) {
      this.techSpecSuperstructure.wallNatures.forEach(w => w['codeLabel'] = w.code + ' - ' + w.label);
    }
    if (this.techSpecSuperstructure.roofNatures) {
      this.techSpecSuperstructure.roofNatures.forEach(r => r['codeLabel'] = r.code + ' - ' + r.label);
    }
    if (this.techSpecSuperstructure.roofTypes) {
      this.techSpecSuperstructure.roofTypes.forEach(r => r['codeLabel'] = r.code + ' - ' + r.label);
    }
    if (this.techSpecSuperstructure.floorNatures) {
      this.techSpecSuperstructure.floorNatures.forEach(f => f['codeLabel'] = f.code + ' - ' + f.label);
    }
    this.superstructureForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }

  /**
   * convenience getter for easy access to form fields
   */
   get f() { return this.superstructureForm.controls; }

  /**
   * Rules for showing wach block
   * RI_MAJ_003_4_1_20
   * RI_MAJ_003_4_1_15
   * RI_MAJ_003_4_1_16
   * RI_MAJ_003_4_1_17
   * RI_MAJ_003_4_1_18
   * RI_MAJ_003_4_1_19
   */
  refreshPanels(): void {
    this.showVehicle = !!this.techSpecSuperstructure.wallNatures || !!this.techSpecSuperstructure.roofTypes
                  || !!this.techSpecSuperstructure.floorNatures;
    this.showUsefulDimensions = this.techSpecSuperstructure.showVolume || this.techSpecSuperstructure.showSurface
                       || this.techSpecSuperstructure.showUsefulWallHeight || this.techSpecSuperstructure.showUsefulMiddleHeight
                        || this.techSpecSuperstructure.showLoadPlanHeight;
    this.showUnloadingOpening = this.techSpecSuperstructure.showUnloadingOpening;
    this.showLoadingOpening = this.techSpecSuperstructure.showLoadingOpening;
    this.showSideOpening = this.techSpecSuperstructure.showSideOpening;
  }

  /**
   * Method pour init datas
   */
  private loadInitDatas() {
    if (!this.wagonTechnicalData) {
      this.loadingSubject.next(false);
      return;
    }

    this.subscriptionRefs.push(
      this.monitoringWorkService.loadTechSpecSuperstructureDatas(this.wagonTechnicalData.wagon.id)
        .subscribe(update => {
          this.techSpecSuperstructure = update.data;
          this.resetDatas();
          this.loadingSubject.next(false);
        })
    );
  }

  /**
   * Method to submit the form
   */
  public async onSubmit(alert?: boolean): Promise<void> {
    this.alert = alert === undefined ? true : alert;
    this.submitted = true;
    this.errorMessage = this.checkValidationRules();
    if (this.errorMessage !== "") {
      return;
    }
    const techSpecSuperstructure = new TechSpecSuperstructure();
    techSpecSuperstructure.wagonID = this.wagonTechnicalData.wagon.id;
    techSpecSuperstructure.mex = this.techSpecSuperstructure.mex;

    techSpecSuperstructure.volume = this.volumeCtrl.value;
    techSpecSuperstructure.usefulArea = this.usefulAreaCtrl.value;
    techSpecSuperstructure.usefulLength = this.usefulLengthCtrl.value;
    techSpecSuperstructure.usefulWidth = this.usefulWidthCtrl.value;
    techSpecSuperstructure.usefulWallHeight = this.usefulWallHeightCtrl.value;
    techSpecSuperstructure.usefulMiddleHeight = this.usefulMiddleHeightCtrl.value;
    techSpecSuperstructure.loadPlanHeight = this.loadPlanHeightCtrl.value;
    techSpecSuperstructure.unloadingOpeningCount = this.unloadingAmountCtrl.value;
    techSpecSuperstructure.unloadingOpeningLength = this.unloadingLengthCtrl.value;
    techSpecSuperstructure.unloadingOpeningWidth = this.unloadingWidthCtrl.value;
    techSpecSuperstructure.unloadingOpeningHeight = this.unloadingHeightRailCtrl.value;
    techSpecSuperstructure.loadingOpeningCount = this.loadingAmountCtrl.value;
    techSpecSuperstructure.loadingOpeningLength = this.loadingLengthCtrl.value;
    techSpecSuperstructure.loadingOpeningWidth = this.loadingWidthCtrl.value;
    techSpecSuperstructure.sideOpeningCount = this.sideAmountCtrl.value;
    techSpecSuperstructure.sideOpeningHeight = this.sideHeightCtrl.value;
    techSpecSuperstructure.sideOpeningWidth = this.sideWidthCtrl.value;
    techSpecSuperstructure.modified = true;
    techSpecSuperstructure.dateConstruction = this.dateCtrl.value;
    if (this.constructionWorkshopCtrl.value) {
      techSpecSuperstructure.constructionWorkshop = this.techSpecSuperstructure.workshops.filter(w => w.code === this.constructionWorkshopCtrl.value)[0];
    }
    if (this.wallCtrl.value) {
      techSpecSuperstructure.wallNature = this.techSpecSuperstructure.wallNatures.filter(t => t.code === this.wallCtrl.value)[0];
    }
    if (this.roofTypeCtrl.value) {
      techSpecSuperstructure.roofType = this.techSpecSuperstructure.roofTypes.filter(t => t.code === this.roofTypeCtrl.value)[0];
    }
    if (this.roofNatCtrl.value) {
      techSpecSuperstructure.roofNature = this.techSpecSuperstructure.roofNatures.filter(t => t.code === this.roofNatCtrl.value)[0];
    }
    if (this.floorCtrl.value) {
      techSpecSuperstructure.floorNature = this.techSpecSuperstructure.floorNatures.filter(t => t.code === this.floorCtrl.value)[0];
    }

    this.loadingSubject.next(true);
    const update = (await this.monitoringWorkService.updateTechSpecSuperstructure(techSpecSuperstructure).pipe(first()).toPromise()).data;
    this.submitted = false;
    this.loadingSubject.next(false);
    this.updateDone();
    this.superstructureForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }

  checkValidationRules(): string {

    if (this.dateCtrl.value && !this.constructionWorkshopCtrl.value) {
        this.constructionWorkshopCtrl.setErrors(Validators.required);
        return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_1");
    }
    if (this.showVehicle) {
      if (this.techSpecSuperstructure.trancheTechnicalData.trpParoi === "1") {
          if (!this.wallCtrl.value) {
            this.wallCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_2");
          }
      } else if (this.techSpecSuperstructure.trancheTechnicalData.trpParoi === "0") {
          if (this.roofNatCtrl.value) {
            this.roofNatCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_33");
          }
      }
      if (this.techSpecSuperstructure.trancheTechnicalData.trpToiture === "1") {
          if (!this.roofNatCtrl.value) {
            this.roofNatCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_3");
          }
          if (!this.roofTypeCtrl.value) {
            this.roofTypeCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_3");
          }
      } else if (this.techSpecSuperstructure.trancheTechnicalData.trpToiture === "0") {
          if (this.roofTypeCtrl.value) {
            this.roofTypeCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_34");
          }
          if (this.roofNatCtrl.value) {
            this.roofNatCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_35");
          }
      }
      if (this.techSpecSuperstructure.trancheTechnicalData.trpPlancher === "0") {
          if (this.floorCtrl.value) {
            this.floorCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_36");
          }
      } else if (this.techSpecSuperstructure.trancheTechnicalData.trpPlancher === "1") {
          if (!this.floorCtrl.value) {
            this.floorCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_12");
          }
       }
    }

    if (this.showUsefulDimensions) {
      if (this.techSpecSuperstructure.trancheTechnicalData.trpVolumeCapacite === "1") {
          if (!this.volumeCtrl.value) {
            this.volumeCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_4");
          } else if (this.volumeCtrl.value <=1 || this.volumeCtrl.value >= 200) {
            this.volumeCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_1");
          }
      } else if (this.techSpecSuperstructure.trancheTechnicalData.trpVolumeCapacite === "0") {
          if (this.volumeCtrl.value) {
            this.volumeCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_2");
          }
      }
      if (this.techSpecSuperstructure.trancheTechnicalData.trpDimUtil === "0") {
          if (this.usefulLengthCtrl.value) {
            this.usefulLengthCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_3");
          }
          if (this.usefulWidthCtrl.value) {
            this.usefulWidthCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_4");
          }
          if (this.usefulAreaCtrl.value) {
            this.usefulAreaCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_5");
          }
      } else if (this.techSpecSuperstructure.trancheTechnicalData.trpDimUtil === "1") {
          if (!this.usefulAreaCtrl.value) {
            this.usefulAreaCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_5");
          }
          if (!this.usefulLengthCtrl.value) {
            this.usefulLengthCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_5");
          }
          if (!this.usefulWidthCtrl.value) {
            this.usefulWidthCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_5");
          }
          if (this.usefulWidthCtrl.value
            && (this.usefulWidthCtrl.value < 1.5 || this.usefulWidthCtrl.value > 3.49)) {
            this.usefulWidthCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_19");
          }
      }
      if ((this.techSpecSuperstructure.trancheTechnicalData.trpExceptionControle !== "1") && (this.techSpecSuperstructure.showSurface)) {
        if (this.usefulAreaCtrl.value < 4.5 || this.usefulAreaCtrl.value > 90) {
          this.usefulAreaCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_20");
        }
        if (this.usefulAreaCtrl.value < 4.5 || this.usefulAreaCtrl.value > 99.9) {
          this.usefulAreaCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_20b");
        }
        if (this.usefulLengthCtrl.value < 2.9 || this.usefulLengthCtrl.value > 30) {
          this.usefulLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_21");
        }
        if (this.usefulLengthCtrl.value < 2.9 || this.usefulLengthCtrl.value > 60) {
          this.usefulLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_21b");
        }
      }
      if (this.techSpecSuperstructure.trancheTechnicalData.trpHautUtilParoi === "0"
        && this.usefulWallHeightCtrl.value) {
        this.usefulWallHeightCtrl.setErrors(Validators.required);
        return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_6");
      }
      if (this.techSpecSuperstructure.trancheTechnicalData.trpHautUtilParoi === "1"
        && !this.usefulWallHeightCtrl.value) {
        this.usefulWallHeightCtrl.setErrors(Validators.required);
        return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_6");
      }
      if (this.techSpecSuperstructure.trancheTechnicalData.trpHautUtilParoi === "1"
        && this.usefulWallHeightCtrl.value
        && (this.usefulWallHeightCtrl.value < 0.1 || this.usefulWallHeightCtrl.value > 4)) {
        this.usefulWallHeightCtrl.setErrors(Validators.required);
        return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_22");
      }
      if (this.techSpecSuperstructure.trancheTechnicalData.trpHautUtilMilieu === "0"
        && this.usefulMiddleHeightCtrl.value) {
        this.usefulMiddleHeightCtrl.setErrors(Validators.required);
        return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_7");
      }
      if (this.techSpecSuperstructure.trancheTechnicalData.trpHautUtilMilieu === "1") {
        if (!this.usefulMiddleHeightCtrl.value) {
          this.usefulMiddleHeightCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_7");
        } else if (this.usefulMiddleHeightCtrl.value < 0.1 || this.usefulMiddleHeightCtrl.value > 4) {
          this.usefulMiddleHeightCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_23");
        }
      }
      if (this.techSpecSuperstructure.trancheTechnicalData.trpHautCharg === "0"
        && this.loadPlanHeightCtrl.value) {
        this.loadPlanHeightCtrl.setErrors(Validators.required);
        return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_8");
      } else if (this.techSpecSuperstructure.trancheTechnicalData.trpHautCharg === "1") {
        if (!this.loadPlanHeightCtrl.value) {
          this.loadPlanHeightCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_10");
        }
        if (this.loadPlanHeightCtrl.value
          && (this.loadPlanHeightCtrl.value < this.unloadingHeightRailCtrl.value || this.loadPlanHeightCtrl.value > 4.28)) {
          this.loadPlanHeightCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_29");
        }
      }
    }

    if (this.showUnloadingOpening) {
      if (this.techSpecSuperstructure.trancheTechnicalData.trpOrifChargDecharg === "0") {
        if (this.unloadingAmountCtrl.value) {
          this.unloadingAmountCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_9");
        }
        if (this.unloadingLengthCtrl.value) {
          this.unloadingLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_10");
        }
        if (this.unloadingWidthCtrl.value) {
          this.unloadingWidthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_11");
        }
        if (this.unloadingHeightRailCtrl.value) {
          this.unloadingHeightRailCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_12");
        }
      } else if (this.techSpecSuperstructure.trancheTechnicalData.trpOrifChargDecharg === "1") {
        if (!this.unloadingAmountCtrl.value) {
          this.unloadingAmountCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_8");
        } else if (this.unloadingAmountCtrl.value < 1 || this.unloadingAmountCtrl.value > 99) {
          this.unloadingAmountCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_24");
        }
        if (!this.unloadingLengthCtrl.value) {
          this.unloadingLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_8");
        }
        if (!this.unloadingHeightRailCtrl.value) {
          this.unloadingHeightRailCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_8");
        }
        if (this.unloadingLengthCtrl.value && this.unloadingWidthCtrl.value
          && (this.unloadingLengthCtrl.value < 0.1 || this.unloadingLengthCtrl.value > 9.99)) {
          this.unloadingLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_25");
        }
        if (this.unloadingLengthCtrl.value && this.unloadingWidthCtrl.value
          && (this.unloadingWidthCtrl.value < 0.1 || this.unloadingWidthCtrl.value > 2.99)) {
          this.unloadingWidthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_25b");
        }
        if (this.unloadingLengthCtrl.value && !this.unloadingWidthCtrl.value
          && (this.unloadingLengthCtrl.value < 0.1 || this.unloadingLengthCtrl.value > 0.8)) {
          this.unloadingLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_25c");
        }
        if (this.unloadingHeightRailCtrl.value
          && (this.unloadingHeightRailCtrl.value < 0.14 || this.unloadingHeightRailCtrl.value > 4.28)) {
          this.unloadingHeightRailCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_26");
        }
      }
    }

    if (this.showLoadingOpening) {
      if (this.techSpecSuperstructure.trancheTechnicalData.trpOrifChargDecharg === "0") {
        if (this.loadingAmountCtrl.value) {
          this.loadingAmountCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_13");
        }
        if (this.loadingLengthCtrl.value) {
          this.loadingLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_14");
        }
        if (this.loadingWidthCtrl.value) {
          this.loadingWidthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_15");
        }
      } else if (this.techSpecSuperstructure.trancheTechnicalData.trpOrifChargDecharg === "1") {
        if (!this.loadingAmountCtrl.value) {
          this.loadingAmountCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_9");
        } else if (this.loadingAmountCtrl.value < 1 || this.loadingAmountCtrl.value > 99) {
          this.loadingAmountCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_27");
        }
        if (!this.loadingLengthCtrl.value) {
          this.loadingLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_9");
        }
        if (this.loadingLengthCtrl.value && this.loadingWidthCtrl.value
          && (this.loadingAmountCtrl.value < 0.1 || this.loadingAmountCtrl.value > 19.99)) {
          this.loadingLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_28");
        }
        if (this.loadingLengthCtrl.value && this.loadingWidthCtrl.value
          && (this.loadingWidthCtrl.value < 0.1 || this.loadingWidthCtrl.value > 2.99)) {
          this.loadingWidthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_28b");
        }
        if (this.loadingLengthCtrl.value && !this.loadingWidthCtrl.value
          && (this.loadingLengthCtrl.value < 0.1 || this.loadingLengthCtrl.value > 0.8)) {
          this.loadingLengthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_28c");
        }
      }
    }

    if (this.showSideOpening) {
      if (this.techSpecSuperstructure.trancheTechnicalData.trpPorteLaterale === "0") {
        if (this.sideAmountCtrl.value) {
          this.sideAmountCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_16");
        }
        if (this.sideHeightCtrl.value) {
          this.sideHeightCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_17");
        }
        if (this.sideWidthCtrl.value) {
          this.sideWidthCtrl.setErrors(Validators.required);
          return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_18");
        }
      } else if (this.techSpecSuperstructure.trancheTechnicalData.trpPorteLaterale === "1") {
          if (!this.sideAmountCtrl.value) {
            this.sideAmountCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_11");
          } else if (!(this.sideAmountCtrl.value in [2,4,6,8,9])) {
            this.sideAmountCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_30");
          }
          if (!this.sideHeightCtrl.value) {
            this.sideHeightCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_11");
          } else if (this.sideHeightCtrl.value < 1.5 || this.sideHeightCtrl.value > 2.99) {
            this.sideHeightCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_31");
          }
          if (!this.sideWidthCtrl.value) {
            this.sideWidthCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RI_MAJ_003_4_1_11");
          } else if (this.techSpecSuperstructure.trancheTechnicalData.trpExceptionControle !== "1"
              && (this.sideWidthCtrl.value < 0.6 || this.sideWidthCtrl.value > 4.99)) {
              this.sideWidthCtrl.setErrors(Validators.required);
              return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_32");
          } else if (this.techSpecSuperstructure.trancheTechnicalData.trpExceptionControle === "1"
            && (this.sideWidthCtrl.value < 0.6 || this.sideWidthCtrl.value > 9.99)) {
            this.sideWidthCtrl.setErrors(Validators.required);
            return this.translateService.instant("wagon.technical.superstructure.RG_MAJ_003_4_1_32b");
          }
       }
    }
    
    return "";
  }

  /**
   * Get the date of DatePicker control
   * @param ctrl
   */
  getDateFromCtrl(ctrl: AbstractControl): string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : new Date(ctrl.value);
  }

  /**
   * Popup message. Closed after 5 seconds.
   */
  private updateDone() {
    this.ngOnInit();
    if (this.alert) {
      const dialogConfig = new MatDialogConfig();    
      dialogConfig.disableClose = true;
      const message = this.translateService.instant('wagon.technical.update-done.msg');    
      const timeout = UtilityService.UPDATE_DONE_TIMEOUT;    
      dialogConfig.data = {
        namePopUp: 'update-done-alert',
        titlePopUp: message,
        msgPopUp: message
      };    
      const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
      dialogRef.afterOpened().subscribe(_ => {
          setTimeout(() => {
          dialogRef.close();
          this.dialog.closeAll();
          }, timeout)
      });
    }
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }


}
