import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { DialogCancelConfirmComponent } from 'src/app/shared/components/dialog-cancel-confirm/dialog-cancel-confirm.component';
import { TemplatePopupDialogComponent } from 'src/app/shared/components/template-popup-dialog/template-popup-dialog.component';
import { CartridgeATS } from 'src/app/shared/models/cartridge-ats';
import { OperationGroupRelation } from 'src/app/shared/models/operation-group-relation';
import { OverhaulNature } from 'src/app/shared/models/overhaul-nature';
import { OverhaulPlateUpdate } from 'src/app/shared/models/overhaul-plate-update';
import { TechSpecReferences } from 'src/app/shared/models/tech-spec-references';
import { MonitoringWorkService } from 'src/app/shared/services/monitoring-work/monitoring-work.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { OverhaulFrequency } from '../../../../../models/overhaul-frequency';
import { OverhaulPlate } from '../../../../../models/overhaul-plate';
import { PlateChangeReason } from '../../../../../models/plate-change-reason';
import { WagonMaintenance } from '../../../../../models/wagon-maintenance';
import { WagonUpdate } from '../../../../../models/wagon-update';
import { Workshop } from '../../../../../models/workshop';
import { ReferenceService } from '../../../../../services/reference/reference.service';
import { WagonService } from '../../../../../services/wagon/wagon.service';
import { isMoment } from 'moment';
import * as moment from 'moment';

@Component({
  selector: 'app-maintenance-cartridge',
  templateUrl: './maintenance-cartridge.component.html',
  styleUrls: ['./maintenance-cartridge.component.scss']
})
export class MaintenanceCartridgeComponent implements OnInit, OnDestroy {

  @Input() public wagon: number;
  @Input() public wagonUpdate: WagonUpdate;
  @Input() public techSpecReferences: TechSpecReferences;
  @Input() public maintenanceCartridgeTabDatas: {
    revNormalList: string[],
    revOtherNormalList: string[],
    establishmentList: Workshop[],
    establishmentATSList: Workshop[]
  };
  @Output() modifiedDataEvent = new EventEmitter();

  /** langage du navigateur */
  lang: string;
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  entranceCartridge: string;
  proposedCartridge: string;
  maintenanceCartridgeForm: FormGroup;
  periodicityCtrl: FormControl;
  revCtrl: FormControl;
  establishmentCtrl: FormControl;
  dateCtrl: FormControl;
  rangNormalCtrl: FormControl;
  rangOtherCtrl: FormControl;
  yearNormalCtrl: FormControl;
  yearOtherCtrl: FormControl;
  natureNormalCtrl: FormControl;
  natureOtherCtrl: FormControl;
  desurizationCtrl: FormControl;
  periodicityNormalCtrl: FormControl;
  modificationReasonCtrl: FormControl;
  periodicityATSCtrl: FormControl;
  atsCtrl: FormControl;
  establishmentATSCtrl: FormControl;
  dateATSCtrl: FormControl;
  wagonMaintenance: WagonMaintenance;
  periodicityList: OverhaulFrequency[] = [];
  periodicityATSList: string[] = [];
  establishmentList: Workshop[] = [];
  establishmentATSList: Workshop[] = [];
  filteredEstablishmentList: Workshop[] = [];
  filteredEstablishmentATSList: Workshop[] = [];
  revRankList: string[] = [];
  revNormalList: string[] = [];
  revRankOtherNormalList: string[] = [];
  revOtherNormalList: string[] = [];
  reasonList: PlateChangeReason[] = [];
  opGroupsRelation: OperationGroupRelation[];
  periodicityNormalList: OverhaulFrequency[] = [];
  overhaulPlateUpdate: OverhaulPlateUpdate[];
  showREVOther: boolean;
  showDesurization: boolean;
  desurizationIsEnabled: any;
  desurizationEnabled: boolean;
  submitted: boolean;
  errorMessage: string;
  dateToday = new Date();
  // JIRA-155 RI_MAJ_003_3_13
  showModificationImpossible: boolean;
  modifSub: Subscription;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  alert: boolean;
  constructor(private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private wagonService: WagonService,
              private referenceService: ReferenceService,
              private monitoringWorkService: MonitoringWorkService,
              private dialog: MatDialog,
              private datepipe: DatePipe) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.loadInitDatas();

    this.periodicityCtrl = this.formBuilder.control(null, Validators.required);
    this.revCtrl = this.formBuilder.control({value: 'REV', disabled: true});
    this.establishmentCtrl = this.formBuilder.control(null, Validators.required);
    this.dateCtrl = this.formBuilder.control(new Date(), Validators.required);
    this.rangNormalCtrl = this.formBuilder.control(null, Validators.required);
    this.rangOtherCtrl = this.formBuilder.control(null);
    this.yearNormalCtrl = this.formBuilder.control({value: null, disabled: true});
    this.yearOtherCtrl = this.formBuilder.control({value: null, disabled: true});
    this.natureNormalCtrl = this.formBuilder.control(null, Validators.required);
    this.natureOtherCtrl = this.formBuilder.control(null);
    this.desurizationCtrl = this.formBuilder.control(false);
    this.periodicityNormalCtrl = this.formBuilder.control(null);
    this.modificationReasonCtrl = this.formBuilder.control(null);
    this.periodicityATSCtrl = this.formBuilder.control(null);
    this.atsCtrl = this.formBuilder.control({value: 'ATS', disabled: true});
    this.establishmentATSCtrl = this.formBuilder.control(null);
    this.dateATSCtrl = this.formBuilder.control(null);
    this.maintenanceCartridgeForm = this.formBuilder.group({
      periodicity: this.periodicityCtrl,
      rev: this.revCtrl,
      establishment: this.establishmentCtrl,
      date: this.dateCtrl,
      rangNormal: this.rangNormalCtrl,
      rangOther: this.rangOtherCtrl,
      yearNormal: this.yearNormalCtrl,
      yearOther: this.yearOtherCtrl,
      natureNormal: this.natureNormalCtrl,
      natureOther: this.natureOtherCtrl,
      desurization: this.desurizationCtrl,
      periodicityNormal: this.periodicityNormalCtrl,
      modificationReason: this.modificationReasonCtrl,
      periodicityATS: this.periodicityATSCtrl,
      ats: this.atsCtrl,
      establishmentATS: this.establishmentATSCtrl,
      dateATS: this.dateATSCtrl
    });
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.modifSub = this.maintenanceCartridgeForm.valueChanges.subscribe(
      _ => this.modifiedDataEvent.emit(this.maintenanceCartridgeForm.dirty));
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

  /**
   * convenience getter for easy access to form fields
   */
   get f() { return this.maintenanceCartridgeForm.controls; }

  /**
   * Methode to submit the form
   */
  async onSubmit(alert?: boolean): Promise<void> {
    this.alert = alert === undefined ? true : alert;
    this.submitted = true;
    this.errorMessage = await this.checkValidationRules();
    if (this.errorMessage !== '') {
      return;
    }
  }

  async doSubmit(): Promise<void> {
    this.loadingSubject.next(true);
    const cartridgeATS = new CartridgeATS();
    cartridgeATS.overhaulPlateUpdate = new OverhaulPlateUpdate();
    cartridgeATS.overhaulPlateUpdate.wagonId = this.wagon;
    cartridgeATS.overhaulPlateUpdate.rank = this.rangNormalCtrl.value;
    cartridgeATS.overhaulPlateUpdate.originFrequency = this.periodicityCtrl.value;
    cartridgeATS.overhaulPlateUpdate.overhaulNature = new OverhaulNature();
    cartridgeATS.overhaulPlateUpdate.overhaulNature.code = this.natureNormalCtrl.value;
    cartridgeATS.overhaulPlateUpdate.originWorkshop = this.establishmentCtrl.value;
    cartridgeATS.overhaulPlateUpdate.actual = this.dateCtrl.value;
    cartridgeATS.overhaulPlateUpdate.changeReason = this.modificationReasonCtrl.value;
    cartridgeATS.unexpectedFrequency = this.periodicityNormalCtrl.value;
    cartridgeATS.rankOther = this.rangOtherCtrl.value;
    cartridgeATS.overhaulNatureCodeOther = this.natureOtherCtrl.value;
    cartridgeATS.periodicityATS = this.periodicityATSCtrl.value;
    cartridgeATS.establishmentATS = this.establishmentATSCtrl.value;
    cartridgeATS.dateATS = this.dateATSCtrl.value;
    const tech = await this.monitoringWorkService.updateMaintenanceCartridge(cartridgeATS).pipe(first()).toPromise();
      
    this.wagonUpdate.trailedMaterialStatus = tech.data.trailedMaterialStatus;
    this.loadingSubject.next(false);        
    this.maintenanceCartridgeForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
    if (tech && tech.data) {
      this.submitted = false;
      this.updateDone();
    } else {
      const title = this.translateService.instant('wagon.maintenance.overhaul_plate');
      const message = this.translateService.instant('wagon-update.maintenance-cartridge.FONCT_ERROR');
      this.showErrorPopUpDialog(title, message);
    }
  }

  async checkValidationRules(): Promise<string> {
    let message = this.checkValidationMandatories();
    if (message !== '') {
      return message;
    }
    message = await this.checkGlobalValidation();
    if (message !== '') {
      return message;
    }
    return '';
  }

  checkValidationMandatories(): string {
    const plAB = this.wagonMaintenance ? this.wagonMaintenance.plateList.find(pl => pl.type === 'AB') : undefined ;
    const plSD = this.wagonMaintenance ? this.wagonMaintenance.plateList.find(pl => pl.type === 'SD') : undefined ;
    const plList = plSD || plAB;

    let valError = false;

   
    if (this.wagonUpdate.type.code !== '01') {
       // RG_MAJ_003_3_10
      if (!this.periodicityCtrl.value) {
        this.periodicityCtrl.setErrors(Validators.required);
        valError = true;
      }
      if (!this.establishmentCtrl.value) {
        this.establishmentCtrl.setErrors(Validators.required);
        valError = true;
      }
      if (!this.dateCtrl.value) {
        this.dateCtrl.setErrors(Validators.required);
        valError = true;
      }
    } else if (plList && this.dateCtrl.value != plList.actual && this.rangNormalCtrl.value === plList.rank) {
      // RG_MAJ_003_3_11
      this.modificationReasonCtrl.setErrors(Validators.required);
      valError = true;
    } else {
      // RG_MAJ_003_3_16
      if (!this.periodicityCtrl.value) {
        this.periodicityCtrl.setErrors(Validators.required);
        valError = true;
      }
      if (!this.establishmentCtrl.value) {
        this.establishmentCtrl.setErrors(Validators.required);
        valError = true;
      }
      if (!this.dateCtrl.value) {
        this.dateCtrl.setErrors(Validators.required);
        valError = true;
      }
      if (!this.rangNormalCtrl.value) {
        this.rangNormalCtrl.setErrors(Validators.required);
        valError = true;
      }
      if (!this.natureNormalCtrl.value) {
        this.natureNormalCtrl.setErrors(Validators.required);
        valError = true;
      }
    }
    if (valError) {
      return this.translateService.instant('wagon-update.maintenance-cartridge.validation-impossible');
    }
    return '';
  }

  /**
   * RG_MAJ_003_3_3
   * RG_MAJ_003_3_5
   * RG_MAJ_003_3_6
   * RG_MAJ_003_3_7
   * RG_MAJ_003_3_8
   * RG_MAJ_003_3_9
   */
  async checkGlobalValidation(): Promise<string> {
    if (this.periodicityNormalCtrl.value && (!this.natureOtherCtrl.value || !this.rangOtherCtrl.value)) {
      this.periodicityNormalCtrl.setErrors(Validators.required);
      return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_3');
    }

    const grp011OpeList = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '011' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    const grp014OpeList = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '014' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    const grp019OpeList = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '019' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
      
      if ((grp011OpeList.length > 0) && !this.periodicityNormalCtrl.value && (this.natureOtherCtrl.value || this.rangOtherCtrl.value)) {
        this.periodicityNormalCtrl.setErrors(Validators.required);
        return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_5');
    }
    if ((grp019OpeList.length > 0)
        && !this.modificationReasonCtrl.value && this.overhaulPlateUpdate.length > 0) {
          const plSD =  this.wagonMaintenance ? this.wagonMaintenance.plateList.find(pl => pl.type === 'SD'): undefined;
          const type = plSD ? 'SD' : 'AB';
          const overhaulUpdate = this.overhaulPlateUpdate.filter(ov => ov.type === type)[0];
          if (overhaulUpdate
            && (this.reasonList && this.reasonList.length > 0)
            && ( (new Date(overhaulUpdate.actual).getTime() !== new Date(this.dateCtrl.value).getTime())
            || (overhaulUpdate.frequency.code !== this.periodicityCtrl.value.code)
            || (overhaulUpdate.originWorkshop.code !== this.establishmentCtrl.value?.code))) {
            this.modificationReasonCtrl.setErrors(Validators.required);
            return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_6');
          }
    }
    if ((grp014OpeList.length == 0)
      && this.establishmentCtrl.value?.code !== this.wagonUpdate.interventionWorkshop.code) {
        this.establishmentCtrl.setErrors(Validators.required);
        return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_7');
    }
    if (grp014OpeList.length > 0) {
      let valError = false;
      if (!this.periodicityATSCtrl.value) {
        this.periodicityATSCtrl.setErrors(Validators.required);
        valError = true;
      }
      if (!this.establishmentATSCtrl.value) {
        this.establishmentATSCtrl.setErrors(Validators.required);
        valError = true;
      }
      if (!this.dateATSCtrl.value) {
        this.dateATSCtrl.setErrors(Validators.required);
        valError = true;
      }
      if (valError) {
        return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_8');
      }
      if (this.establishmentATSCtrl.value?.code !== this.wagonUpdate.interventionWorkshop.code) {
        this.establishmentATSCtrl.setErrors(Validators.required);
        return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_9');
      }
    }
    await this.checkCartridgeATS();

    return '';
  }

  /**
   * RG_MAJ_003_3_12
   * RG_MAJ_003_3_13
   * RG_MAJ_003_3_14
   * RG_MAJ_003_3_15
   * RG_MAJ_003_3_24
   * RG_MAJ_003_3_25
   * RG_MAJ_003_3_21
   * RG_MAJ_003_3_23
   * RG_MAJ_003_3_20
   * RG_MAJ_003_3_19
   * RG_MAJ_003_3_16
   */
  async checkValidationsPostATS(): Promise<string> {
    const grp007OpeList = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '007' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    const grp011OpeList = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '011' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    const grp013OpeList = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '013' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    const grp014OpeList = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '014' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    const grp024OpeList = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '024' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    
    if (!this.periodicityATSCtrl.value && (this.establishmentATSCtrl.value || this.dateATSCtrl.value)) {
      this.periodicityATSCtrl.setErrors(Validators.required);
      return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_12');
    }
    if (!this.establishmentATSCtrl.value && (this.periodicityATSCtrl.value || this.dateATSCtrl.value)) {
      this.establishmentATSCtrl.setErrors(Validators.required);
      return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_12');
    }
    if (!this.dateATSCtrl.value && (this.periodicityATSCtrl.value || this.establishmentATSCtrl.value)) {
      this.dateATSCtrl.setErrors(Validators.required);
      return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_12');
    }
    if ((this.wagonUpdate.maintenanceOperation.overhaulNature || this.wagonUpdate.type.code === '00' || this.wagonUpdate.type.code === '01')
      && (this.establishmentATSCtrl.value && this.establishmentATSCtrl.value?.code !== this.establishmentCtrl.value?.code)) {
        this.establishmentATSCtrl.setErrors(Validators.required);
        return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_13');
    }
    const date = new Date(this.getDateFromCtrl(this.dateCtrl)).getTime();
    const dateATS = new Date(this.getDateFromCtrl(this.dateATSCtrl)).getTime();
    if (this.dateATSCtrl.value) {
      if ((this.wagonUpdate.maintenanceOperation.overhaulNature) && (date !== dateATS)) {
          this.dateATSCtrl.setErrors(Validators.required);
          return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_14');
      }
      if (this.wagonUpdate.type.code === '01' && (dateATS < date)) {
          this.dateATSCtrl.setErrors(Validators.required);
          return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_15');
      }
      if (this.wagonUpdate.type.code !== '01' && (grp014OpeList.length > 0) && (dateATS < date)) {
        this.dateATSCtrl.setErrors(Validators.required);
        return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_24');
      }
    }
    if (this.wagonUpdate.type.code !== '01' 
      && !this.wagonUpdate.maintenanceOperation.overhaulNature
      && (grp024OpeList.length == 0)
      && (this.periodicityATSCtrl.dirty || this.establishmentATSCtrl.dirty || this.dateATSCtrl.dirty)) {
      return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_25');
    }
    if (this.desurizationEnabled) {
      if (((grp011OpeList.length > 0) || (grp013OpeList.length > 0)) 
      && (this.getDateFromCtrl(this.dateCtrl) < new Date())) {
        this.dateCtrl.setErrors(Validators.required);
        return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_21');
      }
      if (this.isWagonTypeCode030506()
        && this.wagonUpdate.maintenanceOperation.overhaulNature) {
          const plAB =  this.wagonMaintenance ? this.wagonMaintenance.plateList.find(pl => pl.type === 'AB') :undefined;
          const plSD =  this.wagonMaintenance ? this.wagonMaintenance.plateList.find(pl => pl.type === 'SD') :undefined;
          let pl = plAB;
          if (plSD){
            if ((plSD.overhaulNature.code === 'D' && plSD.origin > plAB.origin) || 
                plSD.overhaulNature.code === 'S' || plSD.overhaulNature.code === 'SUR') {
                pl = plSD;
            }
          }
          if (pl){
            const entryDate = new Date(pl.actual);
            entryDate.setMonth(entryDate.getMonth() + 1);
            if (this.getDateFromCtrl(this.dateCtrl) < entryDate) {
              this.dateCtrl.setErrors(Validators.required);
              return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_23');
            }
          }
      }
    }
    if ((this.rangOtherCtrl && !this.natureOtherCtrl) || (!this.rangOtherCtrl && this.natureOtherCtrl)) {
        this.rangOtherCtrl.setErrors(Validators.required);
        this.natureOtherCtrl.setErrors(Validators.required);
        return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_20');
    }
    if (!this.rangOtherCtrl && !this.natureOtherCtrl && (grp007OpeList.length > 0)) {
      this.rangOtherCtrl.setErrors(Validators.required);
      this.natureOtherCtrl.setErrors(Validators.required);
      return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_19');
    }
    if (!this.periodicityCtrl) {
      this.periodicityCtrl.setErrors(Validators.required);
      return this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_16');
    }
    if (this.natureOtherCtrl.value && this.periodicityNormalCtrl.value) {
      await this.checkNatRevPerRev(this.natureOtherCtrl.value, this.periodicityNormalCtrl.value);
    } else {
      await this.checkControlTheoricalDate();
    }

    return '';
  }

  /**
   * RG_MAJ_003_3_26
   */
  async checkCartridgeATS(): Promise<void> {
    const res = await this.monitoringWorkService.checkFrequencyATS(this.wagon).pipe(first()).toPromise();
    if (res && res.data) {
      if (new Date(this.dateCtrl.value) !== new Date(this.dateATSCtrl.value)
        || this.establishmentCtrl.value?.code !== this.establishmentATSCtrl.value?.code) {
        let nMm = this.periodicityATSCtrl.value;
        if (!nMm) {
          nMm = res.data < 10 ? '0' + res.data : res.data;  
        }
        const mEtab = this.establishmentCtrl.value;
        const mDate = this.dateCtrl.value;
        let msg = this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_26');
        msg = msg.replace('<mNMM>', nMm);
        msg = msg.replace('<mETAB>', mEtab.initials ? mEtab.initials : mEtab.code);
        msg = msg.replace('<mDate>', formatDate(mDate, 'shortDate', this.lang));
        const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
          data: {
            title: 'wagon-update.maintenance-cartridge.ats-cartridge',
            message: msg,
            cancelLabel: 'wagon.button_dialog.no',
            confirmLabel: 'wagon.button_dialog.yes',
          }
        });
        const result = await dialogRef.afterClosed().pipe(first()).toPromise();
        //.subscribe((result: boolean) => {
          if (result) {
            this.periodicityATSCtrl.setValue(nMm);
            this.establishmentATSCtrl.setValue(this.establishmentCtrl.value);
            this.dateATSCtrl.setValue(this.dateCtrl.value);
          }
          // Continue with the validation of rules
          this.errorMessage = await this.checkValidationsPostATS();
        //});
      } else {
        // Continue with the validation of rules
        this.errorMessage = await this.checkValidationsPostATS();
      }
    } else {
      // Continue with the validation of rules
      this.errorMessage = await this.checkValidationsPostATS();
    }
  }

  /**
   * RG_MAJ_003_3_17
   * @param natureRev
   * @param frequencRev 
   */
  async checkNatRevPerRev(natureRev: string, frequencRev: OverhaulFrequency): Promise<void> {
    const res = await this.monitoringWorkService.checkNatRevPerRev(natureRev, frequencRev.code).pipe(first()).toPromise();
    if (!res) {
      this.periodicityCtrl.setErrors(Validators.required);
      this.errorMessage = this.translateService.instant('wagon-update.maintenance-cartridge.RG_MAJ_003_3_17');
    } else {
      await this.checkControlTheoricalDate();
    }
  }

  /**
   * RG_MAJ_003_3_18
   */
  async checkControlTheoricalDate(): Promise<void> {
    const grp001OpeList = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '001' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
   
    const revDate = new Date(this.dateCtrl.value);
    const period = Number(this.periodicityCtrl.value.code);
    revDate.setFullYear(revDate.getFullYear() + period);
    if ((grp001OpeList.length === 0) 
        && this.wagonMaintenance
        && this.wagonMaintenance.scheduledPlan
        && revDate > new Date(this.wagonMaintenance.scheduledPlan)) {
          const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
            data: {
              title: 'wagon-update.maintenance-cartridge.popup_advertisement',
              message: 'wagon-update.maintenance-cartridge.RG_MAJ_003_3_18',
              cancelLabel: 'actions.cancel',
              confirmLabel: 'actions.confirm'
            }
          });
          const result = await dialogRef.afterClosed().pipe(first()).toPromise();
          if (result) {
            this.doSubmit();
          }
      } else {
        this.doSubmit();
      }
  }

  /**
   * MARGO-167
   * RI_MAJ_003_3_14
   */
  onDateChange(ctrl: AbstractControl): void {
    const userDate = this.getDateFromCtrl(ctrl);
    const strUserDate = this.datepipe.transform(userDate, 'dd/MM/yyyy');
    this.subscriptionRefs.push(
      this.referenceService.getReasonModificationByDate(this.wagon, strUserDate)
        .subscribe(reasons => {
          this.reasonList = reasons.data ? reasons.data.plateChangeReasonList : [];
        })
    );
  }

  /**
   * Get the date of DatePicker control
   * @param ctrl
   */
  getDateFromCtrl(ctrl: AbstractControl): string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : new Date(ctrl.value);
  }

  /**
   * Reset les donnes
   */
  resetDatas(): void {
    this.periodicityCtrl.enable();
    this.establishmentCtrl.enable();
    this.dateCtrl.enable();
    this.rangNormalCtrl.enable();
    this.natureNormalCtrl.enable();
    this.yearNormalCtrl.enable();
    this.rangOtherCtrl.enable();
    this.natureOtherCtrl.enable();
    this.yearOtherCtrl.enable();
    
    if (this.wagonMaintenance) {
      const plAB = this.wagonMaintenance.plateList.find(pl => pl.type === 'AB');
      const plSD = this.wagonMaintenance.plateList.find(pl => pl.type === 'SD');
      
      // RI_MAJ_003_3_3
      if (this.wagonUpdate.type.code !== '00' && this.wagonUpdate.type.code !== '01') {
        this.entranceCartridge =
           this.generateCartridgeText(plAB, plSD, true);
      }

      /** Proposed cartridge */
      if (this.isValidProposedCartridge()) {
        this.proposedCartridge =
          this.generateCartridgeText(plAB, plSD, false);
      }
    }

    if (this.overhaulPlateUpdate) {
      const plAB = this.overhaulPlateUpdate.find(pl => pl.type === 'AB');
      const plSD = this.overhaulPlateUpdate.find(pl => pl.type === 'SD');
      let plateList = plAB;
      if (plSD && plSD.overhaulNature &&
        ((plSD.overhaulNature.code === 'D' && plSD.origin > plAB.origin) || 
        plSD.overhaulNature.code === 'S' || plSD.overhaulNature.code === 'SUR')) {
          plateList = plSD;
      }

      if (plateList) {
        // R1 Périodicité
        this.periodicityCtrl.setValue(plateList.frequency);
        // R2 Etablissement

        this.establishmentCtrl.setValue(this.establishmentList.find(e => e.code === plateList.changeWorkshop?.code));
        // R3 Date
        this.dateCtrl.setValue(plateList.actual);
        
        if (plSD && plAB && plSD.overhaulNature.code === 'D' && plSD.origin > plAB.origin) {
          plateList = plSD;
        } else {
          plateList = plAB;
        }
        if (plateList){
          // R4 Rang de la dernière REV Normale (A, B ou D)
          this.rangNormalCtrl.setValue(plateList.rank < 10 ? '0' + plateList.rank : plateList.rank.toString());
          // R5 Nature de la dernière REV Normale (A, B ou D)
          if (plateList.overhaulNature) {
            // MARGO-155
            if (this.revNormalList && (this.revNormalList.length === 1)) {
                this.natureNormalCtrl.setValue(this.revNormalList[0]);
            } else {
              this.natureNormalCtrl.setValue(plateList.overhaulNature.code);
            }
          } else {
            this.natureNormalCtrl.setValue(null);
          }
          // R6 Année de la dernière REV Normale ( A, B ou D)
          this.yearNormalCtrl.setValue(plateList.origin ? new Date(plateList.origin).getFullYear() : null);
        }

        if (plSD && (plSD.overhaulNature.code === 'S' || plSD.overhaulNature.code === 'SUR')) {
          this.showREVOther = true;
          // R7 Rang de la dernière REV Autre que Normale (S ou SUR)
          this.rangOtherCtrl.setValue(plSD.rank < 10 ? '0' + plSD.rank : plSD.rank.toString());
          // R8 Nature de la dernière REV Autre que Normale (S ou SUR)
          this.natureOtherCtrl.setValue(plSD.overhaulNature.code);
          // R9 Année de la dernière REV Autre que normale (S ou SUR)
          this.yearOtherCtrl.setValue(plSD.origin ? new Date(plSD.origin).getFullYear() : null);
        } else {
          this.showREVOther = false;
        }

        // Motif de modification
        this.modificationReasonCtrl.setValue(plateList ? plateList.changeReason : '');
      }

      const grp007OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '007' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
      const grp011OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '011' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
      const grp013OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '013' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
      const grp014OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '014' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
      const grp015OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '015' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
      const grp019OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '019' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
      const grp001OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '001' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);

      if (!this.revRankList || this.revRankList.length === 0) {
        this.rangNormalCtrl.disable();
      }
      if (!this.revNormalList || this.revNormalList.length === 0) {
        this.natureNormalCtrl.disable();
      }
      if (this.showREVOther && (!this.revRankOtherNormalList || this.revRankOtherNormalList.length === 0)) {
        this.rangOtherCtrl.disable();
      }
      if (this.showREVOther && (!this.revOtherNormalList || this.revOtherNormalList.length === 0)) {
        this.natureOtherCtrl.disable();
      }

      if (this.isWagonTypeCode030506() && (grp007OpeList.length > 0)) {
        this.showREVOther = true;
      }

      // RI_MAJ_003_3_8, RI_MAJ_003_3_11, RI_MAJ_003_3_13
      if ((this.isWagonTypeCode030506() && (grp007OpeList.length > 0))
          || (this.isWagonTypeCode030506() && (grp019OpeList.length > 0))
          || (this.wagonUpdate.type.code === '05' && this.wagonUpdate.maintenanceOperation.code === 'RAG')) {
        this.rangNormalCtrl.disable();
        this.natureNormalCtrl.disable();
        this.yearNormalCtrl.disable();
        if (this.revRankList.indexOf(this.rangNormalCtrl.value) < 0) {
          this.revRankList.push(this.rangNormalCtrl.value);
        }
        if (this.revNormalList.indexOf(this.natureNormalCtrl.value) < 0) {
          this.revNormalList.push(this.natureNormalCtrl.value);
        }
      }

      // RI_MAJ_003_3_9
      if (this.isWagonTypeCode030506() && (grp013OpeList.length > 0)) {
        this.rangOtherCtrl.setValue(null);
        this.rangOtherCtrl.disable();
        this.natureOtherCtrl.setValue(null);
        this.natureOtherCtrl.disable();
      }
      // RI_MAJ_003_3_12
      if (this.isWagonTypeCode030506() && (grp019OpeList.length > 0)) {
        this.rangOtherCtrl.setValue(null);
        this.rangOtherCtrl.disable();
        this.natureOtherCtrl.setValue(null);
        this.natureOtherCtrl.disable();
        this.rangNormalCtrl.disable();
        this.natureNormalCtrl.disable();
      }
      // RI_MAJ_003_3_13 MARGO-155
      if (grp015OpeList.length > 0) {
        this.showModificationImpossible = true;
      }
      // RI_MAJ_003_3_17
      if (this.isWagonTypeCode030506() && (grp014OpeList.length > 0)) {
        this.periodicityCtrl.disable();
        this.establishmentCtrl.disable();
        this.dateCtrl.disable();
        this.rangNormalCtrl.disable();
        this.natureNormalCtrl.disable();
        this.yearNormalCtrl.disable();
        this.rangOtherCtrl.setValue(null);
        this.rangOtherCtrl.disable();
        this.natureOtherCtrl.setValue(null);
        this.natureOtherCtrl.disable();
        this.yearOtherCtrl.disable();
        this.showDesurization = false;
        this.modificationReasonCtrl.disable();
        if (this.revRankList.indexOf(this.rangNormalCtrl.value) < 0) {
          this.revRankList.push(this.rangNormalCtrl.value);
        }
        if (this.revNormalList.indexOf(this.natureNormalCtrl.value) < 0) {
          this.revNormalList.push(this.natureNormalCtrl.value);
        }
      }

      // RI_MAJ_003_3_9
      if (grp013OpeList.length > 0 || !this.yearOtherCtrl.value) {
        this.yearOtherCtrl.setValue(null);
        this.yearOtherCtrl.disable();
      }

      // Pas escompté de la révision normale
      // RG_MAJ_003_3_3
      if (this.showREVOther && this.natureNormalCtrl.value) {
        // RI_MAJ_003_3_8, RI_MAJ_003_3_11, RI_MAJ_003_3_13
        if ((this.isWagonTypeCode030506() && (grp007OpeList.length > 0))
          || (this.isWagonTypeCode030506() && (grp013OpeList.length > 0))
          || (!grp001OpeList)
          || (this.wagonUpdate.type.code === '05' && this.wagonUpdate.maintenanceOperation.code === 'RAG')) {
          this.periodicityNormalCtrl.disable();
        } else if ((this.natureNormalCtrl.value === 'A' || this.natureNormalCtrl.value === 'B')
                  && this.natureOtherCtrl.value === 'S' || this.natureOtherCtrl.value === 'SUR') {
          this.periodicityNormalCtrl.setValue(plAB.frequency);
          this.subscriptionRefs.push(
            this.referenceService.findNotExpectedNormalOverhaul(this.wagon, this.natureOtherCtrl.value, this.wagonUpdate.type.code)
                .subscribe(periodicitiesNormal => {
                  this.periodicityNormalList = periodicitiesNormal.data
                }));
        } else {
          this.periodicityNormalCtrl.setValue(null);
        }
      } else {
        this.periodicityNormalCtrl.disable();
      }

      // Desurisation
      if (this.desurizationIsEnabled == null) {
        this.showDesurization = false;
      } else {
        this.showDesurization = true;
        // RI_MAJ_003_3_18
        if (this.wagonMaintenance.plateList.length === 2 && this.overhaulPlateUpdate.length === 1) {
          this.desurizationEnabled = true;
        } else {
          this.desurizationEnabled = this.desurizationIsEnabled;
        }
      }

      // Motif de modification
      // RI_MAJ_003_3_6, RI_MAJ_003_3_7, RI_MAJ_003_3_8, RI_MAJ_003_3_10, RI_MAJ_003_3_11
      if (this.wagonUpdate.type.code === '00' 
         || this.wagonUpdate.type.code === '01'
         || (this.isWagonTypeCode030506() && (grp007OpeList.length > 0))
         || (this.isWagonTypeCode030506() && (grp011OpeList.length > 0))
         || (this.isWagonTypeCode030506() && (grp013OpeList.length > 0))) {
        this.modificationReasonCtrl.disable();
        this.modificationReasonCtrl.setValue(null);
      }

      this.subscriptionRefs.push(
        this.referenceService.getReasonModification(this.wagon)
          .subscribe(reasons => {
            this.reasonList = reasons.data ? reasons.data.plateChangeReasonList : [];
          })
      );

      // Cartouche ATS
      if (this.wagonMaintenance && this.wagonMaintenance.otherSystematicWorkUpdate) {
        this.periodicityATSCtrl.setValue(this.wagonMaintenance.otherSystematicWorkUpdate.frequency);
        if (this.wagonMaintenance.otherSystematicWorkUpdate.workshop) {
          let etabATSFilter: Workshop[];
          if (this.wagonMaintenance.otherSystematicWorkUpdate.workshop.initials) {
            etabATSFilter = this.establishmentATSList.filter(et => et.initials === this.wagonMaintenance.otherSystematicWorkUpdate.workshop.initials);
          } else {
            etabATSFilter = this.establishmentATSList.filter(et => et.code === this.wagonMaintenance.otherSystematicWorkUpdate.workshop.code);
          }
          if (etabATSFilter.length > 0) {
            this.establishmentATSCtrl.setValue(etabATSFilter[0]);
          }
        }
        this.dateATSCtrl.setValue(this.wagonMaintenance.otherSystematicWorkUpdate.datetime);
      } else {
        //BLM-4413
        //this.periodicityATSCtrl.setValue(this.wagonMaintenance.otherSystematicWork.frequency);
        this.periodicityATSCtrl.setValue(null);
        /*let etabATSFilter: Workshop[];
        if (this.wagonMaintenance.otherSystematicWork.workshop.initials) {
           etabATSFilter = this.establishmentATSList.filter(et => et.initials === this.wagonMaintenance.otherSystematicWork.workshop.initials);
        } else {
           etabATSFilter = this.establishmentATSList.filter(et => et.code === this.wagonMaintenance.otherSystematicWork.workshop.code);
        }
        if (etabATSFilter.length > 0) {
          this.establishmentATSCtrl.setValue(etabATSFilter[0].code);
        }*/
        this.establishmentATSCtrl.setValue(null);
        //this.dateATSCtrl.setValue(this.wagonMaintenance.otherSystematicWork.datetime);
        this.dateATSCtrl.setValue(null);
      }
    } else {
      this.periodicityCtrl.setValue(null);
      this.establishmentCtrl.setValue(null);
      this.dateCtrl.setValue(new Date());
      this.rangNormalCtrl.setValue(null);
      this.rangOtherCtrl.setValue(null);
      this.yearNormalCtrl.setValue(null);
      this.yearOtherCtrl.setValue(null);
      this.natureNormalCtrl.setValue(null);
      this.natureOtherCtrl.setValue(null);
      this.desurizationCtrl.setValue(false);
      this.periodicityNormalCtrl.setValue(null);
      this.modificationReasonCtrl.setValue(null);
      this.periodicityATSCtrl.setValue(null);
      this.establishmentATSCtrl.setValue(null);
      this.dateATSCtrl.setValue(null);
    }
    this.maintenanceCartridgeForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }

  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
    this.subscriptionRefs.push(
      forkJoin([
        this.wagonService.getWagonMaintenanceByMex(this.wagonUpdate.exMex ? this.wagonUpdate.exMex : this.wagonUpdate.mex),
        this.referenceService.findPeriodicity(this.wagon, this.wagonUpdate.maintenanceOperation.code, this.wagonUpdate.type.code),
        this.referenceService.findNormalREVRank(this.wagon, this.wagonUpdate.type.code, this.wagonUpdate.maintenanceOperation.code),
        this.referenceService.findREVRankOtherNormal(this.wagonUpdate.type.code, this.wagonUpdate.maintenanceOperation.code, this.wagon),
        this.referenceService.desurizationIsEnabled(this.wagon),
        this.referenceService.findOverhaulPlateUpdate(this.wagon)
      ]).subscribe((
        [
          wm,
          periodicities,
          revRank,
          revRankOtherNormal,
          desurizationIsEnabled,
          overhaulPlUpdate
        ]
      ) => {
        this.wagonMaintenance = wm.data;
        this.periodicityList = periodicities.data;
        this.periodicityATSList = this.fillPeriodicityATSList();
        if (this.maintenanceCartridgeTabDatas.establishmentList) {
          this.establishmentList = this.maintenanceCartridgeTabDatas.establishmentList.sort((a,b) => (a.initials ? a.initials : a.code ) > (b.initials ? b.initials : b.code ) ? 1 : -1);
        }
        this.establishmentATSList = this.maintenanceCartridgeTabDatas.establishmentATSList;
        if (this.establishmentATSList) {
          this.establishmentATSList = this.establishmentATSList.sort((a,b) => (a.initials ? a.initials : a.code ) > (b.initials ? b.initials : b.code ) ? 1 : -1);
        }
        this.filteredEstablishmentList = this.establishmentList;
        this.filteredEstablishmentATSList = this.establishmentATSList;
        this.revRankList = revRank.data;
        this.revNormalList = this.maintenanceCartridgeTabDatas.revNormalList;
        this.revRankOtherNormalList = revRankOtherNormal.data;
        this.revOtherNormalList = this.maintenanceCartridgeTabDatas.revOtherNormalList;
        this.overhaulPlateUpdate = overhaulPlUpdate.data;
        this.desurizationIsEnabled = desurizationIsEnabled.data;
        this.opGroupsRelation = this.techSpecReferences.operationGroupRelationList;

        this.subscriptionRefs.forEach((s) => {
          if (s && !s.closed) {
            s.unsubscribe();
          }
        });
        this.subscriptionRefs.push(this.maintenanceCartridgeForm.get('establishment').valueChanges
          .pipe(debounceTime(10)).subscribe( value => {
            this.filteredEstablishmentList = this.filterWorkshop(value, this.establishmentList);
        }));
        this.subscriptionRefs.push(this.maintenanceCartridgeForm.get('establishmentATS').valueChanges
            .pipe(debounceTime(10)).subscribe( value => {
              this.filteredEstablishmentATSList = this.filterWorkshop(value, this.establishmentATSList);
        }));
        this.resetDatas();
        this.loadingSubject.next(false);
      })
    );
  }

  private generateCartridgeText(plAB: OverhaulPlate, plSD: OverhaulPlate, isCartridgeEntrance: boolean): string {
    let pl = plAB;
    if (plSD &&
        ((plAB && plSD.overhaulNature.code === 'D' && plSD.origin > plAB.origin) || 
          plSD.overhaulNature.code === 'S' || plSD.overhaulNature.code === 'SUR')) {
        pl = plSD;
    }
    
    // R1 Périodicité
    let r1 = '';
    if (isCartridgeEntrance) {
      r1 = pl.frequency.code;
    } else {
      r1 = this.wagonMaintenance && this.wagonMaintenance.previsionOfMarking && this.wagonMaintenance.previsionOfMarking.overhaulFrequency? this.wagonMaintenance.previsionOfMarking.overhaulFrequency.code : '';
    }
    // R2 Etablissement
    const r2 = (pl.changeWorkshop.initials ? pl.changeWorkshop.initials : pl.changeWorkshop.code);
    // R3 Date
    let r3 = '';
    if (isCartridgeEntrance) {
      r3 = formatDate(pl.actual, 'shortDate', this.lang);
    } else {
      const currentDateInf = new Date();
      currentDateInf.setMonth(currentDateInf.getMonth() - 2);
      const currentDateSup = new Date();
      currentDateSup.setMonth(currentDateSup.getMonth() + 2);

      if (this.wagonMaintenance && this.wagonMaintenance.previsionOfMarking) {
        if (currentDateInf < this.wagonMaintenance.previsionOfMarking.datetime &&
          this.wagonMaintenance.previsionOfMarking.datetime < currentDateSup) {
            r3 = formatDate(new Date(), 'shortDate', this.lang);
        } else {
            r3 = formatDate(this.wagonMaintenance.previsionOfMarking.datetime, 'shortDate', this.lang);
        }
      }
    }
    if (plSD && plAB && plSD.overhaulNature.code === 'D' && plSD.origin > plAB.origin) {
      pl = plSD;
    } else {
      pl = plAB;
    }
    // R4 Rang de la dernière REV A, B ou D
    const r4 = (pl.rank < 10 ? '0' + pl.rank : pl.rank.toString());
    // R5 Nature de la dernière REV A, B ou D
    const r5 = pl.overhaulNature.code;
    // R6 Année de la dernière REV A, B ou D
    const r6 = new Date(pl.origin).getFullYear().toString();
    // R7 Rang de la dernière REV S ou SUR
    let r7 = '';
    // R8 Nature de la dernière REV S ou SUR
    let r8 = '';
    // R9 Année de la dernière REV S ou SUR
    let r9 = '';
    if (plSD && (plSD.overhaulNature.code === 'S' || plSD.overhaulNature.code === 'SUR')) {
        r7 = (plSD.rank < 10 ? '0' + plSD.rank : plSD.rank.toString());
        r8 = plSD.overhaulNature.code;
        r9 = new Date(plSD.origin).getFullYear().toString();
    }
    
    return r1 + ' ' + 'REV' + ' ' + r2 + ' ' + r3 + ' ' + r4 + ' ' + r5 + ' ' +  r6 + ' ' + r7 + ' ' + r8 + ' ' + r9;
  }

  private isValidProposedCartridge(): boolean {
    const opeDapplic: moment.Moment = moment(this.wagonUpdate.maintenanceOperation.opeDapplic);
    const opeFapplic: moment.Moment = moment(this.wagonUpdate.maintenanceOperation.opeFapplic);
    const currentDate = moment();
    return this.wagonUpdate.maintenanceOperation 
        && this.wagonUpdate.maintenanceOperation.opeFlagBsCcrev == 1
        && opeDapplic.isBefore(currentDate)
        && (!this.wagonUpdate.maintenanceOperation.opeFapplic 
            || opeFapplic.isAfter(currentDate))
        && !!this.wagonMaintenance.previsionOfMarking
        && !!this.wagonMaintenance.previsionOfMarking.overhaulFrequency;
  }

  private fillPeriodicityATSList(): string[] {
    const mList = [];
    mList.push('');
    for (let i=1; i<=99; i++) {
      mList.push(i < 10 ? '0' + i : i.toString());
    }
    return mList;
  }

  compareWithPeriodicity = (o1: OverhaulFrequency, o2: OverhaulFrequency): boolean =>  {
    return o1 && o2 && o1.code === o2.code;
  }

  compareWithReason = (r1: PlateChangeReason, r2: PlateChangeReason): boolean =>  {
    return r1.code === r2.code;
  }

    /**
   * Popup message. Closed after 5 seconds.
   */
  private updateDone() {
      this.ngOnInit();
      if (this.alert) {
        const dialogConfig = new MatDialogConfig();        
        dialogConfig.disableClose = true;
        const message = this.translateService.instant('wagon.technical.update-done.msg');        
        const timeout = UtilityService.UPDATE_DONE_TIMEOUT;        
        dialogConfig.data = {
          namePopUp: 'update-done-alert',
          titlePopUp: message,
          msgPopUp: message
        };        
        const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
        dialogRef.afterOpened().subscribe( _ => {
            setTimeout(() => {
            dialogRef.close();
            this.dialog.closeAll();
            }, timeout)
        });
      }
  }

  /**
   * RG_MAJ_003_3_4
   */
  setDesurization(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    if (this.desurizationEnabled) {
      // Le bouton à bascule <Désurisation> ne peut passer que de l'état 'inactif' à l'état 'actif'.
      return;
    }
    this.desurizationEnabled = true;
    this.maintenanceCartridgeForm.markAsDirty();
    const plAB =  this.wagonMaintenance ? this.wagonMaintenance.plateList.find(pl => pl.type === 'AB'): undefined;
    const grp011OpeList = this.opGroupsRelation.filter(rel => 
          rel.operationGroup.code === '011' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    const grp013OpeList = this.opGroupsRelation.filter(rel => 
          rel.operationGroup.code === '013' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    if ((grp011OpeList.length > 0) || (grp013OpeList.length > 0)) {
      this.periodicityNormalCtrl.setValue(null);
      this.periodicityNormalCtrl.disable();
      this.periodicityCtrl.setValue(plAB? plAB.frequency : undefined);
      this.establishmentCtrl.setValue(this.wagonUpdate.interventionWorkshop);
      this.establishmentCtrl.enable();
      this.dateCtrl.setValue(new Date());
      this.onDateChange(this.dateCtrl);
      this.dateCtrl.disable();
      this.rangNormalCtrl.setValue(plAB && (plAB.rank+1) < 10 ? '0' + (plAB.rank+1) : (plAB.rank+1).toString());
      this.rangNormalCtrl.disable();
      this.natureNormalCtrl.setValue(plAB ? plAB.overhaulNature.code: '');
      this.natureNormalCtrl.disable();
      this.yearNormalCtrl.setValue(plAB && plAB.origin ? new Date(plAB.origin).getFullYear() : null);
      this.yearNormalCtrl.disable();
      this.rangOtherCtrl.setValue(null);
      this.rangOtherCtrl.disable();
      this.natureOtherCtrl.setValue(null);
      this.natureOtherCtrl.disable();
      this.yearOtherCtrl.setValue(null);
      this.yearOtherCtrl.disable();
      return;
    }

    const grp019OpeList = this.opGroupsRelation.filter(rel => 
          rel.operationGroup.code === '019' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    if (grp019OpeList.length > 0) {
      this.periodicityNormalCtrl.setValue(null);
      this.periodicityNormalCtrl.disable();
      this.periodicityCtrl.setValue(plAB ? plAB.originFrequency : undefined);
      this.establishmentCtrl.setValue(plAB ? plAB.changeWorkshop : undefined);
      this.dateCtrl.setValue(new Date());
      this.onDateChange(this.dateCtrl);
      this.rangNormalCtrl.setValue(plAB && plAB.rank < 10 ? '0' + plAB.rank : plAB.rank.toString());
      this.rangNormalCtrl.disable();
      this.natureNormalCtrl.setValue(plAB ? plAB.overhaulNature.code: '');
      this.natureNormalCtrl.disable();
      this.yearNormalCtrl.setValue(plAB && plAB.origin ? new Date(plAB.origin).getFullYear() : null);
      this.yearNormalCtrl.disable();
      this.rangOtherCtrl.setValue(null);
      this.rangOtherCtrl.disable();
      this.natureOtherCtrl.setValue(null);
      this.natureOtherCtrl.disable();
      this.yearOtherCtrl.setValue(null);
      this.yearOtherCtrl.disable();
    }
  }

  private isWagonTypeCode030506(): boolean {
    return (this.wagonUpdate.type.code === '03' || this.wagonUpdate.type.code === '05'
            || this.wagonUpdate.type.code === '06')
  }
  
  /**
   * Method to show an error Dialog
   */
  showErrorPopUpDialog(title: string, message: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '12.5rem';
    dialogConfig.minWidth = '13.75rem';
    dialogConfig.data = {
      namePopUp: 'errorOpenWagon',
      titlePopUp: title,
      msgPopUp: message
    };
    this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
  }

  displayWorkshop = (obj: any) => {
    return obj ? (obj.initials ? obj.initials : obj.code) : '';
  }
  
  private filterWorkshop(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? (value.initials ? value.initials : value.code) : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + v.initials).toUpperCase().includes(filterValue))
      ) : list;
  }
}
