import { Injectable } from '@angular/core';
import { Pageable } from '../../../../core/services/app-api/pageable.model';
import { Observable, of } from 'rxjs';
import { ExpertiseOverview } from '../../../../shared/models/expertise-overview';
import { catchError, map } from 'rxjs/operators';
import { Expertise } from '../../../../shared/models/expertise';
import { ExpertiseTask } from '../../../../shared/models/expertise-task';
import { HourlyCost } from '../../../../shared/models/hourly-cost';
import { Media } from '../../../../shared/models/media';
import { AppApiService } from '../../../../core/services/app-api/app-api.service';
import { ApiResponse } from '../../../../core/services/app-api/api-response.model';
import { HttpParams } from '@angular/common/http';
import { MaintenancePlanUpdate } from 'src/app/shared/models/maintenance-plan-update';
import { Cam4 } from 'src/app/shared/models/cam4';
import { RefWork } from 'src/app/shared/models/ref-work';
import { RefMaterial } from 'src/app/shared/models/ref-material';
import { ExpertiseDetail } from 'src/app/shared/models/expertise-detail';
import { CustomHttpParamEncoder } from 'src/app/shared/services/custom-http-param-encoder';

/**
 * `ExpertiseService` is responsible for retrieve expertises related datas through API calls
 */
@Injectable({
  providedIn: 'root'
})
export class ExpertiseService {
  /**
   * ExpertiseService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) {  }

  /**
   * Retrieve expertise with given id
   * @param id the expertise's id
   * @returns encapsulated expertise datas as `Observable`
   */
  public get(id: number): Observable<ApiResponse<Expertise>> {
    return this.api.get<ApiResponse<Expertise>>(`/expertises/${id}`)
    .pipe(
      catchError(this.handleError<any>('get', {data: null}))
    );
  }
  /**
   * Retrieve expertise with given id avec la restriction d'etablissement habilité
   * @param id the expertise's id
   * @returns encapsulated expertise datas as `Observable`
   */
   public getByIdEtabHab(id: number): Observable<ApiResponse<Expertise>> {
    return this.api.get<ApiResponse<Expertise>>(`/expertises/etab-hab/${id}`)
    .pipe(
      catchError(this.handleError<any>('getByIdEtabHab', {data: null}))
    );
  }
  /**
   * Retrieve the expertises associated with the given wagonId
   * @param wagonId physical wagon id
   * @returns encapsulated expertise datas as `Observable`
   */
   public getByWagonId(wagonId: number): Observable<ApiResponse<Expertise[]>> {
    return this.api.get<ApiResponse<Expertise[]>>(`/expertises/wagon/${wagonId}`)
    .pipe(
      catchError(this.handleError<any>('getByWagonId', {data: null}))
    );
  }
  /**
   * Retrieve all expertises
   * @returns encapsulated array of expertise datas as `Observable`
   */
  public getAll(isClose?: boolean, pageable?: Pageable): Observable<ApiResponse<Expertise[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isClose !== undefined) {
      parameters = parameters.set('isClose', `${isClose}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<Expertise[]>>('/expertises', { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getAll', {data: []}))
    );
  }
  /**
   * Retrieve all expertises overviews
   * @returns encapsulated array of expertise overview datas as `Observable`
   */
  public getAllOverview(isClose?: boolean, pageable?: Pageable): Observable<ApiResponse<ExpertiseOverview[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isClose !== undefined) {
      parameters = parameters.set('isClose', `${isClose}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<ExpertiseOverview[]>>('/expertises/overviews', { params: parameters })
    .pipe(
      catchError( this.handleError<any>('getAllOverview', {data: []}))
    );
  }
  /**
   * Retrieve all expertise tasks
   * @param id the expertise's id
   * @returns encapsulated array of expertise task datas as `Observable`
   */
  public getAllTasks(id: number /*pageable: Pageable*/): Observable<ApiResponse<ExpertiseTask[]>> {
    return this.api.get<ApiResponse<ExpertiseTask[]>>(`/expertises/${id}/tasks` /*, { params: pageable.build() }*/)
    .pipe(
      catchError(this.handleError<any>('getAllTasks', {data: []}))
    );
  }
  /**
   * Retrieve all expertise tasks with the media photos associated
   * @param id the expertise's id
   * @returns encapsulated array of expertise task datas as `Observable`
   */
   public getAllTasksWithPhotos(id: number): Observable<ApiResponse<ExpertiseTask[]>> {
    return this.api.get<ApiResponse<ExpertiseTask[]>>(`/expertises/${id}/tasks-photos`)
    .pipe(
      catchError(this.handleError<any>('getAllTasksWithPhotos', {data: []}))
    );
  }
  /**
   * Retrieve all expertise tasks associated to the wagon maintenance plan
   * @param mex the wagon mex
   * @returns encapsulated array of expertise task datas as `Observable`
   */
   public getAllTasksFromMaintenancePlan(id: number): Observable<ApiResponse<ExpertiseTask[]>> {
    return this.api.get<ApiResponse<ExpertiseTask[]>>(`/expertises/${id}/tasks-maintenance`)
    .pipe(
      catchError(this.handleError<any>('getAllTasksFromMaintenancePlan', {data: []}))
    );
  }
  /**
   * Retrieve all expertise tasks associated to the wagon maintenance plan
   * @param mex the wagon mex
   * @returns encapsulated array of expertise task datas as `Observable`
   */
   public getAllTasksInitMaintenancePlan(id: number, mex: string, opeCode: string, reasonCode: string): Observable<ApiResponse<ExpertiseTask[]>> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    parameters = parameters.set('mex', mex);
    parameters = parameters.set('opeCode', opeCode);
    parameters = parameters.set('reasonCode', reasonCode);
    return this.api.get<ApiResponse<ExpertiseTask[]>>(`/expertises/${id}/tasks-init-maintenance`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getAllTasksInitMaintenancePlan', {data: []}))
    );
  }
  /**
   * Retrieve hourly costs
   * @param wbtCode working Bill Type code
   * @param wsCode workshop code
   * @returns encapsulated array of hourly cost datas as `Observable`
   */
  public getHourlyCosts(wbtCode?: string, wsCode?: string/*, pageable?: Pageable*/): Observable<ApiResponse<HourlyCost[]>> {
    let parameters: HttpParams = new HttpParams();
    if (wbtCode !== undefined) {
      parameters = parameters.set('workingBillType', wbtCode);
    }
    if (wsCode !== undefined) {
      parameters = parameters.set('workshop', wsCode);
    }
    /*if (pageable !== undefined) {
      let pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }*/
    return this.api.get<ApiResponse<HourlyCost[]>>('/hourly-costs' , { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getHourlyCosts', {data: []}))
    );
  }
  /**
   * Remove a media from expertise
   * @param id the expertise's id
   * @param file The media to delete
   * @returns void `Observable`
   */
  public deleteMedia(id: number, file: Media): Observable<{}> {
    return this.api.delete<{}>(`/expertises/${id}/medias/${file.id}`)
    .pipe(
      catchError(this.handleError<any>('deleteMedia', null))
    );
  }
  /**
   * Download a media
   * @param id the expertise's id
   * @param file The media to download
   * @returns file content as an `Observable<Blob>`
   */
  public downloadMedia(id: number, file: Media): Observable<Blob> {
    return this.api.get<Blob>(`/expertises/${id}/medias/${file.id}`, {responseType: 'blob', observe: 'response'})
      .pipe(
        catchError(this.handleError<any>('downloadMedia', null)),
        map((resp) => {
          const contentDisposition = resp.headers.get('Content-Disposition');
          const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
          if (matches !== null && matches[1]) {
            file.filename = matches[1];
          }
          return resp.body;
        })
      );
  }
  /**
   * Download expertise report
   * @param id the expertise's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<Blob>(`/expertises/${id}/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'BDT_' + id + '.pdf'};
      })
    );
  }
  /**
   *  Create maintenance plans updates
   * @param expertise the expertise
   */
  public createMaintenancePlanUpdate(expertise: any): Observable<ApiResponse<MaintenancePlanUpdate[]>> {
    return this.api.post<ApiResponse<MaintenancePlanUpdate[]>>(`/expertises/maintenance-plan-update`, expertise)
    .pipe(
      catchError(this.handleError<any>('createMaintenancePlanUpdate', null))
    );
  }
  /**
   * Recuperation des listes CAM à réaliser
   * @param orgCode
   * @param orgTraCode
   * @returns encapsulated array of lists as `Observable`
   */
   public getCamListsByExpertiseTask(orgCode: string, orgTraCode: string): Observable<ApiResponse<Cam4[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('orgCode', orgCode);
    parameters = parameters.set('orgTraCode', orgTraCode);
    return this.api.get<ApiResponse<Cam4[]>>(`/expertises/cam-list`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getCamListsByExpertiseTask', {data: []}))
    );
  }
  /**
   * Recuperation de tous les compléments correspondant au CAM sélectionné de la table T_CAM
   * @param codeCam
   * @returns encapsulated array as `Observable`
   */
   public getCamListNbPos(codeCam: string): Observable<ApiResponse<string[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('codeCam', codeCam);
    return this.api.get<ApiResponse<string[]>>(`/expertises/cam-nb-pos`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getCamListNbPos', {data: []}))
    );
  }
  /**
   * Recuperation des listes Matieres
   * @param orgCode
   * @param orgTraCode
   * @param mWPhy
   * @returns encapsulated array of lists as `Observable`
   */
   public getMaterialsByExpertise(orgCode: string, orgTraCode: string, mWPhy: number): Observable<ApiResponse<RefMaterial[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('orgCode', orgCode);
    parameters = parameters.set('orgTraCode', orgTraCode);
    parameters = parameters.set('mWPhy', mWPhy.toString());
    return this.api.get<ApiResponse<RefMaterial[]>>(`/expertises/materials-list`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getMaterialsByExpertise', {data: []}))
    );
  }
  /**
   * Recuperation des listes Main d'ouvre
   * @param orgCode
   * @param orgTraCode
   * @returns encapsulated array of lists as `Observable`
   */
   public getWorksByExpertise(orgCode: string, orgTraCode: string): Observable<ApiResponse<RefWork[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('orgCode', orgCode);
    parameters = parameters.set('orgTraCode', orgTraCode);
    return this.api.get<ApiResponse<RefWork[]>>(`/expertises/works-list`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWorksByExpertise', {data: []}))
    );
  }
  /**
   * Saves the expertise in DB
   * @param expertise the expertise
   */
   public saveExpertise(expertise: ExpertiseDetail): Observable<ApiResponse<Expertise>> {
    return this.api.post<ApiResponse<Expertise>>(`/expertises/save`, expertise)
    .pipe(
      catchError(this.handleError<any>('saveExpertise', null))
    );
  }
  /**
   * Vérification existence devis
   * @param idExpertise the expertise ID
   */
   public existsDevisExpertise(idExpertise: number): Observable<ApiResponse<number>> {
    return this.api.get<ApiResponse<number>>(`/expertises/exists-devis/${idExpertise}`)
    .pipe(
      catchError(this.handleError<any>('existsDevisExpertise', {data: null}))
    );
  }
  /**
   * Initialises the devis
   * @param expertise the expertise
   */
   public initDevisExpertise(expertise: number): Observable<ApiResponse<number>> {
    return this.api.post<ApiResponse<number>>(`/expertises/init-devis`, expertise)
    .pipe(
      catchError(this.handleError<any>('initDevisExpertise', null))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
