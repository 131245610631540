import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../../services/auth/auth.service';
import { AgentService } from '../../services/agent/agent.service';
import { first } from 'rxjs/operators';

/**
 * Gestion des différentes phases de connexion
 */
enum AuthPhase {
  DISCONNECTING, CONNECTING, LOGGED, ERROR, UNAUTHORIZED
}


/**
 * `LoginComponent` manage authentification phases
 */
@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  /**
   * Expose l'enum AuthPhase
   */
  readonly AuthPhase = AuthPhase;
  /**
   * Phase d'authentification
   */
  state: AuthPhase = null;
  /**
   * URL pour la connexion SSO
   */
  private readonly LOGIN_URL: string = environment.api_url + '/login';
  /**
   * Délais avant redirection après une (dé)connexion
   */
  private readonly REDIRECT_DELAY: number = 2000;
  /**
   * Timeout pour la redirection
   */
  private redirectTimeout;

  /**
   * LoginComponent constructor
   * @param router Angular router
   * @param route Angular router component's activated route to query for params
   * @param authService Inject application's authentication service
   */
  constructor(private router: Router,
              private route: ActivatedRoute,
              private authService: AuthService,
              private agentService: AgentService) { }

  /**
   * OnInit hook retrieve the connection status and redirect the user accordingly when needed
   */
  ngOnInit() {
    // Action de déconnexion
    if (this.router.url === '/unauthorized') {
      this.state = AuthPhase.UNAUTHORIZED;
      this.disconnect();
      // Redirection automatique
      this.redirectTimeout = setTimeout(() => {
        this.router.navigate(['/home']);
      }, this.REDIRECT_DELAY);
    } else if (this.router.url === '/logout') {
      this.state = AuthPhase.DISCONNECTING;
      this.disconnect();
      // Redirection automatique
      this.redirectTimeout = setTimeout(() => {
        this.router.navigate(['/home']);
      }, this.REDIRECT_DELAY);
    } else if (this.authService.isLogged().getValue()) {
      this.state = AuthPhase.LOGGED;
    } else if (this.route.snapshot.queryParams.code) {
      const authCode: string = this.route.snapshot.queryParams.code;
      this.handleAuthentification(authCode);
      this.state = AuthPhase.CONNECTING;
    } else if (this.route.snapshot.queryParams.error) {
      this.state = AuthPhase.ERROR;
    } else {
      // Si l'utilisateur arrive sur la page, de connexion
      // => redirection direct vers la page SSO
      document.location.assign(this.LOGIN_URL);
    }
  }

  /**
   * Authentificatino de l'utilisateur à partir du code retourné par la FID
   * @param code - Code d'authorisation de la FID
   */
  handleAuthentification(code: string): void {
    this.authService.authenticate(code)
      .then(() => {
        this.state = AuthPhase.LOGGED;
        this.agentService.getHabilitationsLoaded().subscribe (_ => {
          this.redirectTimeout = setTimeout(async () => {
            await this.agentService.updateLastConnection().pipe(first()).toPromise()
            this.router.navigate(['/dashboard']);
          }, this.REDIRECT_DELAY);
        });
      })
      .catch((_) => {
        this.state = AuthPhase.ERROR;
      });
  }

  /**
   * Déconnexion de l'utilisateur
   */
  disconnect(): void {
    this.authService.logout();
  }
  /**
   * OnDestroy hook clean timeout to avoid memory leaks
   */
  ngOnDestroy(): void {
    clearTimeout(this.redirectTimeout);
  }
}
