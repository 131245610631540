import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { AgentService } from 'src/app/core/services/agent/agent.service';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { DealService } from 'src/app/modules/update/deal/services/deal.service';
import { InitUpdateSpace } from 'src/app/shared/models/init-update-space';
import { ReducedUpdateInput } from 'src/app/shared/models/reduced-update-input';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { Agent } from '../../../models/agent';
import { Anomaly } from '../../../models/anomaly';
import { DamageReport } from '../../../models/damage-report';
import { Deal } from '../../../models/deal';
import { EventsHistory } from '../../../models/events-history';
import { EventsHistoryUpdate } from '../../../models/events-history-update';
import { Expertise } from '../../../models/expertise';
import { Imputation } from '../../../models/imputation';
import { InterventionCause } from '../../../models/intervention-cause';
import { InterventionReason } from '../../../models/intervention-reason';
import { LogicalWagon } from '../../../models/logical-wagon';
import { MaintenanceOperation } from '../../../models/maintenance-operation';
import { TrailedMaterialStatus } from '../../../models/trailed-material-status';
import { UpdateType } from '../../../models/update-type';
import { Wagon } from '../../../models/wagon';
import { WagonUpdateHistory } from '../../../models/wagon-update-history';
import { Workshop } from '../../../models/workshop';
import { ReferenceService } from '../../../services/reference/reference.service';
import { WagonService } from '../../../services/wagon/wagon.service';
import { TemplatePopupDialogComponent } from '../../template-popup-dialog/template-popup-dialog.component';
import * as moment from 'moment';

const MY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-open-wagon-dialog',
  templateUrl: './open-wagon-dialog.component.html',
  styleUrls: ['./open-wagon-dialog.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT }
    , DatePipe],
})
export class OpenWagonDialogComponent implements OnInit, OnDestroy {
  agent: Agent;
  /** Open form group */
  openWagonForm: FormGroup;
  /** new workshop form control */
  workshopCtrl: FormControl;
  /** new updateType form control */
  updateTypeCtrl: FormControl;
  /** new updateType form control */
  maintenanceOperationCtrl: FormControl;
  /** new pattern form control */
  patternCtrl: FormControl;
  /** new cause form control */
  causeCtrl: FormControl;
  /** new responsability form control */
  responsabilityCtrl: FormControl;
  /** new Entry date form control */
  entryDateCtrl: FormControl;
  /** new Entry time form control */
  entryTimeCtrl: FormControl;
  /** new Exit date form control */
  releaseDateCtrl: FormControl;
  /** new Exit time form control */
  releaseTimeCtrl: FormControl;
  /** new Estimated Release Date form control */
  estReleaseDateCtrl: FormControl;
  /** new deal Number form control */
  dealNumberCtrl: FormControl;
  /** new pvca form control */
  pvcaCtrl: FormControl;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** wether search return an error */
  searchError: number;
  /** Workshop Default*/
  workshop: Workshop;
  /** Workshop Agent */
  workshopAgent: Workshop;
  /** workshop selected */
  workshopSelected: Workshop;
  /** la liste des établissements valides */
  workshopList: Workshop[];
  /** filtered workshop list */
  filteredWorkshopList: Workshop[];
  /* case_number selected*/
  caseNumberSelected: Deal;
  /** Current mex */
  currentMex: string;
  /** physical wagon */
  wagon: Wagon;
  /** Current inventory logical wagon */
  currentLogWagon: LogicalWagon;
  /** Anomalies datas */
  anomaly: Anomaly;
  /** Deal datas */
  deals: Deal[];
  /** Damage report datas */
  damageReports: DamageReport[];
  /** Expertise wagon */
  expertise: Expertise;
  /** Expertises wagon datas */
  expertises: Expertise[];
  /** submitted property */
  submitted: boolean;
  /** langage du navigateur */
  lang: string;
  /** updateType selected */
  updateTypeSelected: UpdateType;
  /** all types*/
  updateTypes: UpdateType[];
  reasons: InterventionReason[];
  imputations: Imputation[];
  causes: InterventionCause[];
  maxDefect: string;
  isRevPlanned: boolean;
  /** filtered updateType list */
  filteredUpdateTypeList: UpdateType[];
  /** La liste des codes opérations dispo */
  updateTypeList: UpdateType[];
  /** filtered Maintenance Operation list */
  filteredMaintenanceOperationList: MaintenanceOperation[];
  /** La liste des operations Maintenance  */
  maintenanceOperationList: MaintenanceOperation[];
  /** MaintenanceOperation selected*/
  maintenanceOperationSelected: MaintenanceOperation;
  /** La liste des motifs*/
  patternList: InterventionReason[];
  /** filtered intervention Reason list */
  filteredPatternList: InterventionReason[];
  /** updateType selected */
  patternSelected: InterventionReason;
  causeSelected: InterventionCause;
  responsabilitySelected: Imputation;
  /** La liste des causes */
  causeList: InterventionCause[];
  /** filtered intervention Cause list */
  filteredCauseList: InterventionCause[];
  /** La liste des responsabilities */
  responsibilityList: Imputation[];
  /** filtered Responsability list */
  filteredResponsibilityList: Imputation[];
  /** Rechercher la liste des affaires à faire ou en cours rattachées au Wagon (non traitées) pour l’établissement intervenant (renseigné par défaut)*/
  dealWagonList: Deal[];
  /** filtered DealWagon list */
  filteredDealWagonList: Deal[];
  /**  Rechercher la liste des affaires à faire ou en cours cours non rattachée au wagon sur laquelle on peu encore rattacher un wagon avec pour chacune affaire  */
  dealWagonNonAttache: Deal[];
  /** La liste des P.V.C.A */
  pvcaList: DamageReport[];
  /** filtered PVCA list */
  filteredPVCAList: DamageReport[];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** La date de la prochaine révision N est prévue dans les 12 mois  */
  isRevisionN: boolean;
  /** validité du wagon de référence */
  validWagonReference: boolean;

  isReasonMandatory: boolean;
  /** max number wagon */
  isMaxNumber: boolean;
  /** Show reason XXX */
  showPatternXXX: boolean;
  /** Date system */
  dateSystem: Date;
  /** Time entry System */
  entryTimeDf: string;
  /** Entry Date */
  entry: Date;
  /** Time release System */
  releaseTimeDf: string;
  /** Release Date */
  release: Date;
  /** Estimation Release Date */
  estRelease: Date;
  /** is ReleaseDate mineur Entry Date*/
  isNotValidReleaseDate: boolean;
  /** is Estiman. ReleaseDate mineur Entry Date*/
  isNotValidEstReleaseDate: boolean;
  /** is ReleaseDate majeur System Date*/
  isNotValidSystemReleaseDate: boolean;
  /** Whether datas are still loading */
  isLoadingResults: boolean;
  /** Is Open Wagon */
  isOpenWagon: boolean;

  addObsrv2: string;
  addObsrv1: string;
  observations: string;
  reference_wagon: string;
  releaseDatOrig: Date;
  releaseTimeOrig: string;

  reducedUpdatedEnable: boolean;
  wagonKnown: boolean;
  // Object used to initialize the popup when performing the Return action from Mise a jour reduit
  initReducedUpdateData: ReducedUpdateInput;

  dateToday = new Date();
  autoSubmitDone = false;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private referenceService: ReferenceService,
    private wagonService: WagonService,
    private agentService: AgentService,
    private dealService: DealService,
    private utilityService: UtilityService,
    private translateService: TranslateService,
    public datepipe: DatePipe) {
    this.currentMex = data.mex;
    this.initReducedUpdateData = data.initReducedUpdateData;

    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.agent = this.agentService.getAgentData().getValue();
    this.resetDatas();
    this.workshopCtrl = this.formBuilder.control('', Validators.required);
    this.updateTypeCtrl = this.formBuilder.control('', Validators.required);
    this.maintenanceOperationCtrl = this.formBuilder.control({ value: undefined, disabled: true }, Validators.required);
    this.patternCtrl = this.formBuilder.control({ value: undefined, disabled: true });
    this.causeCtrl = this.formBuilder.control({ value: undefined, disabled: true });
    this.responsabilityCtrl = this.formBuilder.control({ value: undefined, disabled: true },);
    this.entryDateCtrl = this.formBuilder.control(this.dateSystem, Validators.required);
    this.entryTimeCtrl = this.formBuilder.control(this.entryTimeDf, Validators.required);
    this.releaseDateCtrl = this.formBuilder.control(this.dateSystem);
    this.releaseDatOrig = this.releaseDateCtrl.value;
    this.releaseTimeCtrl = this.formBuilder.control(this.releaseTimeDf);
    this.releaseTimeOrig = this.releaseTimeCtrl.value;
    this.estReleaseDateCtrl = this.formBuilder.control(null);
    this.dealNumberCtrl = this.formBuilder.control(null);
    this.pvcaCtrl = this.formBuilder.control(null);
    this.openWagonForm = this.formBuilder.group({
      reducedUpdateCheckbox: false,
      workshop: this.workshopCtrl,
      updateType: this.updateTypeCtrl,
      maintenanceOperation: this.maintenanceOperationCtrl,
      pattern: this.patternCtrl,
      cause: this.causeCtrl,
      responsability: this.responsabilityCtrl,
      entryDate: this.entryDateCtrl,
      entryTime: this.entryTimeCtrl,
      releaseDate: this.releaseDateCtrl,
      releaseTime: this.releaseTimeCtrl,
      estReleaseDate: this.estReleaseDateCtrl,
      dealNumber: this.dealNumberCtrl,
      reference_wagon: ['',],
      observations: ['',],
      additional_obsrv_1: ['',],
      additional_obsrv_2: ['',],
      pvca: this.pvcaCtrl,
      expertiseCheckbox: false
    }, {
      validator: [this.maintenanceOperationControlValidator('maintenanceOperation', 'pattern', 'cause', 'responsability'),
      this.releaseDateValidator('entryDate', 'entryTime', 'releaseDate', 'releaseTime', 'estReleaseDate', 'reducedUpdateCheckbox')]
    });
    // Initialize Mise a jour reduit checkbox (from the Return action)
    if (this.initReducedUpdateData) {
      this.openWagonForm.controls.reducedUpdateCheckbox.setValue(true);
    }
    
    this.loadInitDatas();

    this.subscriptionRefs.push(this.workshopCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredWorkshopList = this.filterWorkshops(value)
      ));

    this.subscriptionRefs.push(this.updateTypeCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredUpdateTypeList = this.filterUpdateType(value)
      ));

    this.subscriptionRefs.push(this.maintenanceOperationCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredMaintenanceOperationList = this.filterMaintenanceOperation(value)
      ));

    this.subscriptionRefs.push(this.patternCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredPatternList = this.filterPattern(value)
      ));

    this.subscriptionRefs.push(this.causeCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredCauseList = this.filterCause(value)
      ));

    this.subscriptionRefs.push(this.responsabilityCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredResponsibilityList = this.filterResponsability(value)
      ));

    this.subscriptionRefs.push(this.dealNumberCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredDealWagonList = this.filterDealWagon(value)

      ));

    this.subscriptionRefs.push(this.pvcaCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredPVCAList = this.filterPVCA(value)

      ));
  }

  /**
  * Validator to check that InterventionReason, InterventionCause and 
  * imputation are requerid if MaintenanceOperation.isReasonMandatory =1
  * @param maintOpecontrolName 
  * @param reasonControlName 
  * @param causeControlName 
  * @param imputationControlName 
  */
  maintenanceOperationControlValidator(maintOpecontrolName: string, reasonControlName: string,
    causeControlName: string, imputationControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[maintOpecontrolName];
      const reasonControl = formGroup.controls[reasonControlName];
      const causeControl = formGroup.controls[causeControlName];
      const imputationControl = formGroup.controls[imputationControlName];
      if (control.value && control.value.isReasonMandatory) {
        if (!reasonControl.value) {
          reasonControl.setErrors(Validators.required);
        }
        if (!causeControl.value) {
          causeControl.setErrors(Validators.required);
        }
        if (!imputationControl.value) {
          imputationControl.setErrors(Validators.required);
        }
      }
    }
  }

  /**
   * Validator to check that ReleaseDate et estimatino ReleaseDate
   * @param entryDateControlName 
   * @param entryTimeControlName 
   * @param releaseDateControlName 
   * @param releaseTimeControlName 
   * @param estReleaseDateControlName 
   * @param reducedUpdateControlName 
   */
  releaseDateValidator(entryDateControlName: string, entryTimeControlName: string, releaseDateControlName: string, releaseTimeControlName: string, estReleaseDateControlName: string, reducedUpdateControlName: string) {
    return (formGroup: FormGroup) => {
      const entryDateControl = formGroup.controls[entryDateControlName];
      const entryTimeControl = formGroup.controls[entryTimeControlName];
      const releaseDateControl = formGroup.controls[releaseDateControlName];
      const releaseTimeControl = formGroup.controls[releaseTimeControlName];
      const estReleaseDateControl = formGroup.controls[estReleaseDateControlName];
      const reducedUpdateControl = formGroup.controls[reducedUpdateControlName];
      
      const entryDate = this.getDateFromCtrl(entryDateControl);
      if (entryDate && entryTimeControl.value) {
        this.entry = this.addTimeToDateCtrl(entryDate, entryTimeControl);
      }

      //Mise a jour reduite, on utilise releaseDate
      if (reducedUpdateControl.value) {
        estReleaseDateControl.setErrors(null);

        const releaseDate = this.getDateFromCtrl(releaseDateControl);
        const releaseDateTime = this.addTimeToDateCtrl(releaseDate, releaseTimeControl);
        const entryDateTime = this.addTimeToDateCtrl(entryDate, entryTimeControl);
        let valDateEntry = null;
        let valReleaseDate = null;
        if (entryDate.toString().indexOf('T') !== -1) {
          // Store entry date
          valDateEntry = new Date(entryDate);
          // Store release date
          valReleaseDate = new Date(releaseDate);
        }

        if ((releaseDateControl.invalid || (releaseDateControl.valid && (releaseDateControl.value == undefined)))
            && (valDateEntry.getTime() > valReleaseDate.getTime())) {
          releaseDateControl.setErrors(Validators.required);
        } else if ((releaseTimeControl.value == undefined) || (releaseTimeControl.value == '')) {
          releaseTimeControl.setErrors(Validators.required);
        } else if (releaseTimeControl.invalid && (valDateEntry.getTime() > valReleaseDate.getTime())) {
          releaseTimeControl.setErrors(Validators.required);
        } else if (entryDateControl.value && entryTimeControl.value) {
            // Store entry date at midnight
            const valDateEntryMid = new Date(entryDate);
            valDateEntryMid.setHours(0);
            valDateEntryMid.setMinutes(0);
            valDateEntryMid.setSeconds(0);
            valDateEntryMid.setMilliseconds(0);
            // Store release date at midnight
            const valReleaseDateMid = new Date(releaseDate);
            valReleaseDateMid.setHours(0);
            valReleaseDateMid.setMinutes(0);
            valReleaseDateMid.setSeconds(0);
            valReleaseDateMid.setMilliseconds(0);
            // Entry day bigger than release day
            if (valDateEntryMid.getTime() > valReleaseDateMid.getTime()) {
              releaseDateControl.setErrors(Validators.required);
              this.isNotValidReleaseDate = true;
              this.isNotValidSystemReleaseDate = false;
            // Entry day same than release day but hours are greater than release time
            } else if ((valDateEntryMid.getTime() == valReleaseDateMid.getTime()) && (entryDateTime > releaseDateTime)) {
              releaseTimeControl.setErrors(Validators.required);
              this.isNotValidReleaseDate = true;
              this.isNotValidSystemReleaseDate = false;
            // Release date greater than system date
            } else if (releaseDateTime > new Date()) {
              releaseDateControl.setErrors(Validators.required);
              this.isNotValidSystemReleaseDate = true;
            // Valid release date: day greater than entry day or same day but greater hour
            } else {
              this.release = releaseDateTime;
              this.entry = entryDateTime;
              this.isNotValidReleaseDate = false;
              this.isNotValidSystemReleaseDate = false;
              releaseDateControl.setErrors(null);
              releaseTimeControl.setErrors(null);
            }
        }
      } else { //non Mise a jour reduite- On utilise estim. Release Date

        releaseDateControl.setErrors(null);
        releaseTimeControl.setErrors(null);

        if (estReleaseDateControl.value == undefined) {
          this.estRelease = null;
          this.isNotValidEstReleaseDate = false;
          estReleaseDateControl.setErrors(null);
          return;
        }

        let estReleaseDate = this.getDateFromCtrl(estReleaseDateControl);
        if (typeof estReleaseDate === 'string') {
          estReleaseDate = new Date(estReleaseDate);
        }
        const entryDateTime = this.addTimeToDateCtrl(entryDate, entryTimeControl);

        if ((estReleaseDateControl.invalid || (estReleaseDateControl.valid && (estReleaseDateControl.value == undefined)))
            && (entryDateTime > estReleaseDate)) {
          estReleaseDateControl.setErrors(Validators.required);
        }
        else if (entryDateControl.value && entryTimeControl.value) {
          if (entryDateTime > estReleaseDate) {
            estReleaseDateControl.setErrors(Validators.required);
            this.isNotValidEstReleaseDate = true;
          } else {
            this.estRelease = estReleaseDate;
            this.entry = entryDateTime;
            this.isNotValidEstReleaseDate = false;
            estReleaseDateControl.setErrors(null);
          }
        }
      }
    }
  }

  /**
   * Return Date with Time in string
   * @param date 
   * @param ctrl 
   */
  private addTimeToDateCtrl(date: string | Date, ctrl: AbstractControl): Date {
    const indexT = date.toString().indexOf('T');
    const subStr = date.toString().substring(indexT, indexT + 6);
    const dateStr = date.toString().replace(subStr, 'T' + ctrl.value);
    const targetTime = new Date(dateStr);
    const indexZ = dateStr.lastIndexOf('Z');
    if (indexZ > -1){
      const hourZoneOffset =  targetTime.getTimezoneOffset()/60;
      targetTime.setHours(targetTime.getHours() + hourZoneOffset);
    }
    return targetTime;
  }

  /**
   * Get the date of DatePicker control
   * @param ctrl 
   */
  getDateFromCtrl(ctrl: AbstractControl): string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : moment(ctrl.value).toISOString(true);
  }

  /**
   * Reset les donnes 
   */
  private resetDatas(): void {
    this.workshop = undefined;
    this.currentLogWagon = undefined;
    this.wagon = undefined;
    this.anomaly = undefined;
    this.damageReports = undefined;
    this.expertise = undefined;
    this.expertises = undefined;
    this.submitted = false;
    this.workshopSelected = undefined;
    this.updateTypeSelected = undefined;
    this.maintenanceOperationSelected = undefined;
    this.caseNumberSelected = undefined;
    this.isRevisionN = false;
    this.validWagonReference = true;
    this.isReasonMandatory = false;
    this.patternSelected = undefined;
    this.causeSelected = undefined;
    this.responsabilitySelected = undefined;
    this.isMaxNumber = false;
    this.showPatternXXX = true;
    this.dateSystem = new Date((new Date().getTime()));
    this.dateSystem.setSeconds(0);
    this.dateSystem.setMilliseconds(0);
    this.entryTimeDf = this.getHourSystem();
    this.isNotValidReleaseDate = false;
    this.isNotValidEstReleaseDate = false;
    this.entry = null;
    this.release = null;
    this.estRelease = null;
    this.addObsrv2 = '';
    this.addObsrv1 = '';
    this.observations = '';
    this.reference_wagon = '';
    this.isLoadingResults = false;
    this.isOpenWagon = false;
    this.utilityService.setValue(this.isOpenWagon);
    this.reducedUpdatedEnable = false;
    this.wagonKnown = false;

    // Initialize the popup when performing the Return action from Mise a jour reduit
    if (this.initReducedUpdateData) {
      this.updateTypeSelected = this.initReducedUpdateData.updateType;
      this.patternSelected = this.initReducedUpdateData.pattern;
      this.causeSelected = this.initReducedUpdateData.cause;
      this.responsabilitySelected = this.initReducedUpdateData.imputation;
      this.maintenanceOperationSelected = this.initReducedUpdateData.maintenanceOperation;
      this.observations = this.initReducedUpdateData.observations; 
      this.addObsrv1 = this.initReducedUpdateData.option1;
      this.addObsrv2 = this.initReducedUpdateData.option2;
      this.releaseTimeDf = this.getHourSystem();
    }
  }

  /**
   * Methode pour init datas 
   */
  private async loadInitDatas() {
    const pageable = new Pageable();
    pageable.sort = 'id';
    pageable.order = 'desc';
    const pageableCode = new Pageable();
    pageableCode.sort = 'code';
    pageableCode.order = 'asc';

    // Rechercher l’établissement de l’opérateur de saisie
    this.workshopAgent =  (await this.agentService.getWorkshop().pipe(first()).toPromise()).data;
    this.workshop = this.workshopAgent;

    this.updateTypes = ( await this.referenceService.getUpdateType(pageableCode).pipe(first()).toPromise()).data;
    this.currentLogWagon = this.data.logicals && this.data.logicals.reduce(
      (prev, curr) => ['13', '14', 'WO'].indexOf(curr.inventory.code) === -1 ? curr : prev, null);
    if (this.currentLogWagon) {
      this.wagonKnown = true;
      this.wagonService.setCurrentWagon(this.currentLogWagon.wagonId);
      //RG_MAJ_001_13
      this.reducedUpdatedEnable = this.currentLogWagon.inventory.code === '10';
      this.wagon = this.wagonService.getSharedDatas().value.wagon;
      this.subscriptionRefs.push(
        forkJoin(
          // Rechercher les informations Incidents et réformes du Wagon
          this.wagonService.findAnomalyUndepreciatedReform(this.currentLogWagon.wagonId),
          // Rechercher les informations PVCA du Wagon
          this.wagonService.getDamageReports(this.currentLogWagon.wagonId, pageable),
          // Rechercher la liste des expertises en cours sur le wagon
          this.wagonService.getWagonExpertise(this.currentLogWagon.wagonId, false),
          //this.referenceService.getUpdateType(pageableCode),
          this.wagonService.getMaxClassDefectCode(this.currentLogWagon.wagonId),
          this.wagon && this.wagon.maintenanceContract && this.wagon.maintenanceContract.isLevel34 ? 
              this.referenceService.getInterventionsReason(this.currentLogWagon.mex, this.currentLogWagon.inventory.code, pageableCode)
              : this.referenceService.getAllInterventionsReason(pageableCode, true),
          this.referenceService.getImputations(pageableCode, true),
          this.referenceService.getInterventionsCause(pageableCode, true),
          this.wagonService.isRevisionNplanned(this.currentLogWagon.mex, '10')
        )
          .subscribe(([anomaly, damageReports, expertisesWagons/*, updateTypes*/, maxDefect, reasons, imputations, causes, isRevPlanned]) => {
            this.anomaly = anomaly.data;
            this.damageReports = damageReports.data;
            // this.updateTypes = updateTypes.data;
            this.expertises = expertisesWagons.data;
            this.maxDefect = maxDefect;
            this.reasons = reasons.data;
            this.imputations = imputations.data;
            this.causes = causes.data;
            this.isRevPlanned = isRevPlanned.data;
            this.getWagonExpertise();
            this.getPVCA();
            this.initWorkshops();
          })
      );
    } else {
      this.subscriptionRefs.push(
        forkJoin([
          this.referenceService.getAllInterventionsReason(pageableCode, true),
          this.referenceService.getImputations(pageableCode, true),
          this.referenceService.getInterventionsCause(pageableCode, true)]
        )
          .subscribe(([ reasons, imputations, causes]) => {
            this.reasons = reasons.data;
            this.imputations = imputations.data;
            this.causes = causes.data;
            this.initWorkshops();
          })
      );
      
      this.subscriptionRefs.push(
        this.wagonService.getLogicalWagonsExistsForMex(this.currentMex)
          .subscribe((res) => this.searchError = res.data ? 2 : 1)
      );
      //RG_MAJ_001_13	La mise à jour réduite n’est possible que pour les wagons avec un inventaire courant « 10 » (ou wagons inconnus qui seront créés à l’inventaire 10)
      if (!this.searchError) {
        this.reducedUpdatedEnable = true;
      }
    }
  }

  initWorkshops() {
     //Rechercher la liste des établissements valides
    const pgbWorkshop = new Pageable();
    pgbWorkshop.sort = 'code';
    pgbWorkshop.order = 'asc';

   this.loadingSubject.next(false);
    // MARGO-322
    const dealInfo = this.wagonService.getDealInfos();
    if (dealInfo && dealInfo.id) {
      this.subscriptionRefs.push(
        this.referenceService.getWorkshopsForAffaire(dealInfo.id.toString(), pgbWorkshop)
          .subscribe((workshops) => {
            this.workshopList = workshops.data;
            this.filteredWorkshopList = this.workshopList;
            if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (this.filteredWorkshopList.some( e => e.code === dealInfo.multiple?.workshop?.code ))) {
              this.workshopCtrl.setValue(dealInfo.multiple.workshop);
              this.entryDateCtrl.setValue(dealInfo.multiple.entryDate);
              this.entryTimeCtrl.setValue(dealInfo.multiple.entryTime);
              this.estReleaseDateCtrl.setValue(dealInfo.multiple.prevDate);
              this.openWagonForm.get('observations').setValue(dealInfo.multiple.observations);
              this.openWagonForm.get('additional_obsrv_1').setValue(dealInfo.multiple.additional_obsrv_1);
              this.openWagonForm.get('additional_obsrv_2').setValue(dealInfo.multiple.additional_obsrv_2);
            } else {
              this.workshopCtrl.setValue(this.workshopList&&(this.workshopList.length>0)?this.workshopList[0]:null);
            }
          }));
    } else {
      this.subscriptionRefs.push(
        this.referenceService.getWorkshopsForUser(pgbWorkshop)
          .subscribe((workshops) => {
            this.workshopList = workshops.data;
            this.filteredWorkshopList = this.workshopList;
            this.workshopCtrl.setValue(this.workshop);
          }));
    }
  }
  /**
   * Methode to check if exist a logical Wagon with a inventory
   */
  private existInventoryInLogicalWagonArray(inventory: string): boolean {
    let exist = false;
    if (this.data.logicals) {
      const logicalWagon = this.data.logicals.reduce(
        (prev, curr) => curr.inventory.code === inventory ? curr : prev, null);
      exist = logicalWagon != undefined;
    }
    return exist;
  }

  /**
   * convenience getter for easy access to form fields
   */
  get f() { return this.openWagonForm.controls; }

  private filterWorkshops(value: string | Workshop): Workshop[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return (filterValue && this.workshopList) ? this.workshopList
      .filter(
        ws => ((ws.code + ws.label).includes(filterValue))
      ) : this.workshopList;
  }

  private filterUpdateType(value: string | UpdateType): UpdateType[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return (filterValue && this.updateTypeList) ? this.updateTypeList
      .filter(
        ws => ((ws.code + ws.label).includes(filterValue))
      ) : this.updateTypeList;
  }

  private filterMaintenanceOperation(value: string | MaintenanceOperation): MaintenanceOperation[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return (filterValue && this.maintenanceOperationList) ? this.maintenanceOperationList
      .filter(
        ws => ((ws.code + ws.label).includes(filterValue))
      ) : this.maintenanceOperationList;
  }

  private filterPattern(value: string | InterventionReason): InterventionReason[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    let auxPatternList = this.patternList;
    if (!this.showPatternXXX) {
      auxPatternList = auxPatternList.filter(item => ['XXX'].indexOf(item.code) === -1);
    }
    return (filterValue && auxPatternList) ? auxPatternList
      .filter(
        ws => ((ws.code + ws.label).includes(filterValue))
      ) : auxPatternList;
  }

  private filterCause(value: string | InterventionCause): InterventionCause[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return (filterValue && this.causeList) ? this.causeList
      .filter(
        ws => ((ws.code + ws.label).includes(filterValue))
      ) : this.causeList;
  }

  private filterResponsability(value: string | Imputation): Imputation[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return (filterValue && this.responsibilityList) ? this.responsibilityList
      .filter(
        ws => ((ws.code).includes(filterValue))
      ) : this.responsibilityList;
  }

  private filterDealWagon(value: string | Deal): Deal[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? ('' + value.id) : (value as string));
    return (filterValue && this.dealWagonList) ? this.dealWagonList
      .filter(
        ws => (('' + ws.id).includes(filterValue))
      ) : this.dealWagonList;
  }

  private filterPVCA(value: string | DamageReport): DamageReport[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? ('' + value.id) : (value as string));
    return (filterValue && this.pvcaList) ? this.pvcaList
      .filter(
        ws => (('' + ws.id).includes(filterValue))
      ) : this.pvcaList;
  }


  /**
   * On click Workshop Selection
   */
  onWorkshopSelection(): void {
    if (this.workshopCtrl.value !== undefined && this.workshopCtrl.value.code !== undefined) {

      if ((this.workshopCtrl.value !== this.workshopSelected) && (this.currentLogWagon && this.currentLogWagon.inventory && this.currentLogWagon.inventory.code === '11')) {
        this.isApprEstablishments();
      } else if (this.workshopCtrl.value !== this.workshopSelected) {
        this.workshopSelected = this.workshopCtrl.value;
      }
      // Rechercher la liste des codes opérations
      this.initUpdateTypeList();
      //Rechercher la liste des affaires ouvertes et/ou en cours rattachées au wagon pour l’établissement intervenant (renseigné par défaut)
      this.initDealWagonList();
    }
  }

  /**
   * verify that the intervening sur wagon est habilité «Construction»
   */
  isApprEstablishments(): boolean {
    let result = false;
    this.subscriptionRefs.push(
      this.referenceService.isApprovedEstablishments(this.workshopCtrl.value.code)
        .subscribe((approvEstab) => {
          result = approvEstab.data;
          if (!result) {
            this.openWagonInconsistencyPopUpDialog();
          }
        }));
    return result;
  }

  /**
   * On click sur updateType
   */
  onUpdateTypeSelection(): void {
    if (this.updateTypeCtrl.value !== undefined && this.updateTypeCtrl.value.code !== undefined && this.updateTypeCtrl.value !== this.updateTypeSelected) {
      this.updateTypeSelected = this.updateTypeCtrl.value;
      this.initMaintenanceOperationList();
    }
  }

  /**
   * On click sur Deal Wagon
   */
  onDealWagonSelection(): void {
    this.caseNumberSelected = this.dealNumberCtrl.value;
  }

  /**
   * On click sur Maintenance Operation
   */
  async onMainteanceOperationSelection(): Promise<void> {
    let opeMaintList: MaintenanceOperation[];
    if (this.maintenanceOperationList) {
      opeMaintList = this.maintenanceOperationList.filter(mOp => mOp.code === this.maintenanceOperationCtrl.value?.code);
      if (opeMaintList && opeMaintList.length > 0) {
        const found = opeMaintList.some(r => ['ATS', 'VSP', 'REVS', 'REVSUR', 'REVD', 'ATP120', 'ATPTMD', 'ATPSUR', 'ATPPR'].includes(r.code))
        if (found && this.currentLogWagon && this.currentLogWagon.inventory.code === '10') {
          this.isRevisionN = this.isRevPlanned;
        }

        if (!opeMaintList[0].isReasonMandatory) {
          this.patternList = [];
          this.filteredPatternList = [];
          this.causeList = [];
          this.filteredCauseList = [];
          this.responsibilityList = [];
          this.filteredResponsibilityList = [];
        } else {
          if (this.wagon || !this.currentLogWagon) {
            this.patternList = this.reasons;
            this.responsibilityList = this.imputations;
            this.causeList = this.causes;
            this.getInterventionReason();
            this.initInterventionsCause();
            this.initImputations();
          }
        }
      }
    }
  }

  /** 
   * Set Time System 
   * 
   */
  getHourSystem(): string {
    const now = new Date();
    const hours = ("0" + now.getHours()).slice(-2);
    const minutes = ("0" + now.getMinutes()).slice(-2);
    const str = hours + ':' + minutes;
    return str;
  }

  /**
   * Event change checkBox 
   */
  onChangeReduceUpdateCheckbox(): void {
    this.showPatternXXX = true;
    this.initUpdateTypeList();
    this.maintenanceOperationSelected = undefined;
    this.releaseTimeDf = this.getHourSystem();
    this.releaseTimeCtrl.setValue(this.releaseTimeDf);
    const dealInfo = this.wagonService.getDealInfos();
    if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (dealInfo.multiple?.reducedUpdateCheckbox)) {
      this.releaseTimeDf=dealInfo.multiple.releaseTime;
      this.releaseDateCtrl.setValue(dealInfo.multiple.releaseDate);
      this.releaseTimeCtrl.setValue(dealInfo.multiple.releaseTime);
      this.checkAutoMode();
    }
    //- Le champ Date/heure de sortie devient visible et renseigné par défaut avec la date/heure système Margo.
    //- Le champ « Date prévisionelle de sortie » n’apparaît plus.
    // Si l’Opération de Maintenance saisie = [REP ou DEP] (groupe 015) et que le champ motif est renseigné à ‘XXX’
    // (motif de réforme provisoir accepté en ouverture mais qui doit avoir été modifié au moment de la certification)
    // alors le champ motif est réinitialisé à vide.
    this.getInterventionReason();
    if (this.f.pattern && this.f.pattern.value && this.f.pattern.value.code === 'XXX'
      && (this.f.maintenanceOperation.value.code === 'REP' || this.f.maintenanceOperation.value.code === 'DEP')) {
      this.cleanPattern();
    }
  }

  /** 
   * Obtener la liste d'intervention Reason 
   */
  getInterventionReason(): void {
    if (this.f.reducedUpdateCheckbox && this.f.reducedUpdateCheckbox.value
      && this.patternList && this.f.maintenanceOperation.value
      && (this.f.maintenanceOperation.value.code === 'REP' || this.f.maintenanceOperation.value.code === 'DEP')) {
      this.showPatternXXX = false;
      this.patternCtrl.enable();
      this.filteredPatternList = this.patternList.filter(item =>['XXX'].indexOf(item.code) === -1);
    } else if (!this.patternList || this.patternList.length === 0) {
      this.patternCtrl.disable();
      this.filteredPatternList = this.patternList;
    } else {
      this.patternCtrl.enable();
      this.filteredPatternList = this.patternList;
    }
    //Initialiser le motif à partir du code défaut RG_MAJ_001_11 
    if (this.currentLogWagon && this.currentLogWagon.wagonId) {
      if (this.maxDefect) {
        const intervenantReasonStr = this.maxDefect + "";
        const interventionArray = this.patternList? this.patternList.filter(item => [intervenantReasonStr].indexOf(item.code) > -1) : [];
        if (interventionArray && interventionArray.length === 1) {
          this.patternSelected = interventionArray[0];
        }
      }
    }
    
    const dealInfo = this.wagonService.getDealInfos();
    if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (this.filteredPatternList.some( e => e.code === dealInfo.multiple?.pattern?.code ))) {
      this.patternCtrl.setValue(dealInfo.multiple.pattern);
      if (dealInfo.multiple.reducedUpdateCheckbox && !this.openWagonForm.get('reducedUpdateCheckbox').value ) {
        this.openWagonForm.get('reducedUpdateCheckbox').setValue(dealInfo.multiple.reducedUpdateCheckbox);
      }
      this.checkAutoMode();
    }
  }

  /** 
   * Obtenir le Wagon Expertise 
   */
  getWagonExpertise(): void {
    if (this.expertises && this.expertises.length === 1) {
      this.expertise = this.expertises[0];
    }
  }

  /** 
   * Obtenir la liste composée d'une ligne par PVCA créée depuis la moins recent réforme non amortie du wagon
   */
  getPVCA(): void {
    if (this.anomaly) {
      this.pvcaList = this.damageReports.filter(
        damRep => (damRep.arrival > this.anomaly.event));
      this.filteredPVCAList = this.pvcaList;
    }
  }


  /** 
   * Recherche la liste des causes valides (dates début/fin) de la table  
   */
  initInterventionsCause(): void {
    this.causeCtrl.enable();
    this.filteredCauseList = this.causeList;
    const dealInfo = this.wagonService.getDealInfos();
    if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (this.filteredCauseList.some( e => e.code === dealInfo.multiple?.cause?.code ))) {
      this.causeCtrl.setValue(dealInfo.multiple.cause);
      this.checkAutoMode();
    }
  }

  /** 
   * Recherche la liste des Responsabilité  
   */
  initImputations(): void {
    this.responsabilityCtrl.enable();
    this.filteredResponsibilityList = this.responsibilityList;
    const dealInfo = this.wagonService.getDealInfos();
    if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (this.filteredResponsibilityList.some( e => e.code === dealInfo.multiple?.responsability?.code ))) {
      this.responsabilityCtrl.setValue(dealInfo.multiple.responsability);
      this.checkAutoMode();
    }
  }

  /**
   * Recherche liste des codes operation
   */
  initUpdateTypeList(): void {
    const dealInfo = this.wagonService.getDealInfos();
    if (this.openWagonForm.controls.reducedUpdateCheckbox.value) {
      this.updateTypeList = this.updateTypes.filter(
        updateType => (updateType.code === '06'));
      this.updateTypeCtrl.setValue(this.updateTypeList[0]);
      this.updateTypeSelected = this.updateTypeCtrl.value;
      this.updateTypeCtrl.disable();
    } else {
      this.updateTypeCtrl.enable();
      if (!this.currentLogWagon || (this.wagon && this.wagon.maintenanceContract && this.wagon.maintenanceContract.isLevel34 === false)) {
        //RG_MAJ_001_5
        this.updateTypeList = this.updateTypes.filter(
          updateType => (updateType.code === '06' || updateType.code === '33'));
      }else if (this.currentLogWagon && this.currentLogWagon.inventory && this.currentLogWagon.inventory.code === '11') {
        //RG_MAJ_001_4 
        this.updateTypeList = this.updateTypes.filter(
          updateType => (updateType.code === '00' || updateType.code === '01'));
      } else {
        this.updateTypeList = this.updateTypes.filter(
          updateType => (updateType.code !== '00' && updateType.code !== '01'));

        if (!this.existInventoryInLogicalWagonArray('13') && !this.existInventoryInLogicalWagonArray('14')) {
          this.updateTypeList = this.updateTypeList.filter(
            updateType => (updateType.code !== '03'));
        }
      }
    }
    this.filteredUpdateTypeList = this.updateTypeList;
    if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (this.filteredUpdateTypeList.some( e => e.code === dealInfo.multiple?.updateType?.code ))) {
      this.updateTypeCtrl.setValue(dealInfo.multiple.updateType);
    }
  }

  /**
   * Rechercher la liste des affaires ouvertes et/ou en cours rattachées au wagon pour l’établissement intervenant (renseigné par défaut)
  */
  initDealWagonList(): void {

    this.dealWagonList = [];
    if (this.currentLogWagon && this.workshopSelected) {
      const pageable = new Pageable();
      pageable.sort = 'id';
      pageable.order = 'asc';
      this.subscriptionRefs.push(
        this.dealService.getDealOpenOrAttachedPage(this.currentLogWagon.mex, this.workshopSelected.code, pageable)
          .subscribe(dealwg => {
            this.dealWagonList = dealwg.data;
            this.addStarToDealObject();
            this.getDealNotAttached(pageable);
          })
      );

    }
  }

  /** 
   * Ajouter une '*' to object pour separer des affaires rataches par un wagon 
   */
  addStarToDealObject(): void {
    for (const deal of this.dealWagonList) {
      deal.object = '* ' + deal.object;
    }
  }

  getDealNotAttached(pageable: Pageable): void {
    this.subscriptionRefs.push(
      this.dealService.getDealOpenOrNotAttachedPage(this.currentLogWagon.mex, this.workshopSelected?.code, pageable)
        .subscribe(dealwg => {
          this.dealWagonNonAttache = dealwg.data;
          this.getDeals();
        })
    )
  }

  getDeals(): void {
    const dealInfo = this.wagonService.getDealInfos();
    if (this.dealWagonList) {
      // S'il existe une seule affaire (séparé par * ) ouverte ou en cours rattachée au wagon et sur laquelle
      // le wagon n'est pas encore traité  alors le champ numéro d’affaire est initiée par défaut avec cette valeur
      // Sinon non renseigné par défaut
      this.caseNumberSelected = null;
      this.estReleaseDateCtrl.setValue(null);
      if (!(dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done')) && this.dealWagonList.length == 1 && (this.dealWagonList[0].object.indexOf('*') >= 0)) {
        this.caseNumberSelected = this.dealWagonList[0];
        this.estReleaseDateCtrl.setValue(this.caseNumberSelected.expected);
      }
      for (const deal of this.dealWagonNonAttache) {
        const dealFounded = this.dealWagonList.find(element => element.id === deal.id);
        if (!dealFounded) {
          this.dealWagonList.push(deal);
        }
      }
    } else {
      this.dealWagonList = this.dealWagonNonAttache;
    }
    this.filteredDealWagonList = this.dealWagonList;
    if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (this.filteredDealWagonList.some( e => e.id === dealInfo.multiple?.dealNumber?.id ))) {
      this.dealNumberCtrl.setValue(dealInfo.multiple.dealNumber);
      if (!this.estReleaseDateCtrl.value) {
        this.estReleaseDateCtrl.setValue(dealInfo.multiple.prevDate);
      }
      this.checkAutoMode();
    } else if (dealInfo && this.filteredDealWagonList.some( e => e.id === dealInfo.id)) {
      this.dealNumberCtrl.setValue(this.filteredDealWagonList.find( e => e.id === dealInfo.id));
    }
  }


  /**
   * Rechercher initialiser la liste des opérations de maintenance 
  */
  initMaintenanceOperationList(): void {
    const pageable = new Pageable();
    pageable.sort = 'code';
    pageable.order = 'asc';
    let listOpMaintZ: Array<MaintenanceOperation>;
    if (this.wagon && this.wagon.id) {
      if (this.updateTypeList.some (e => e.code === this.updateTypeSelected?.code)) {
        this.subscriptionRefs.push(
          this.referenceService.getMaintenanceOperation(this.wagon.id, this.workshopSelected ? this.workshopSelected.code : undefined, this.updateTypeSelected ? this.updateTypeSelected.code : undefined, pageable)
            .subscribe(mOpe => {
              this.maintenanceOperationList = mOpe.data;
              listOpMaintZ = mOpe.data.filter(
                mOpe => (mOpe.code === 'Z'));
              if (this.workshop.type.code === '0' && listOpMaintZ && listOpMaintZ.length === 1) {
                this.maintenanceOperationSelected = listOpMaintZ[0];

              }

              this.maintenanceOperationCtrl.enable();
              this.filteredMaintenanceOperationList = this.maintenanceOperationList;
              const dealInfo = this.wagonService.getDealInfos();
              if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (this.filteredMaintenanceOperationList.some( e => e.code === dealInfo.multiple?.maintenanceOperation?.code ))) {
                this.maintenanceOperationCtrl.setValue(dealInfo.multiple.maintenanceOperation);
              }
            })
        );
      }
    } else {
      this.subscriptionRefs.push(
        this.referenceService.getMaintenanceOperation(undefined, this.workshopSelected ? this.workshopSelected.code : undefined, this.updateTypeSelected ? this.updateTypeSelected.code : undefined, pageable)
          .subscribe(mOpe => {
            this.maintenanceOperationList = mOpe.data;
            listOpMaintZ = mOpe.data.filter(
              mOpe => (mOpe.code === 'Z'));
            if (this.workshop.type.code === '0' && listOpMaintZ && listOpMaintZ.length === 1) {
              this.maintenanceOperationSelected = listOpMaintZ[0];
            }

            this.maintenanceOperationCtrl.enable();
            this.filteredMaintenanceOperationList = this.maintenanceOperationList;
            const dealInfo = this.wagonService.getDealInfos();
            if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (this.filteredMaintenanceOperationList.some( e => e.code === dealInfo.multiple?.maintenanceOperation?.code ))) {
              this.maintenanceOperationCtrl.setValue(dealInfo.multiple.maintenanceOperation);
            }
          })
      );
    }

  }


  /**
   * Methode to show WagonInconsistency Dialog
   */
  openWagonInconsistencyPopUpDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('wagon.title_wagon_estab_inconsistency');
    const message = this.translateService.instant('wagon.title_estab_not_enabled');

    dialogConfig.data = {
      namePopUp: 'wagon_inconsistency',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      if (this.workshopCtrl.value === this.workshop) {
        this.workshopSelected = undefined;
      } else {
        this.workshopSelected = this.workshop;
      }
    });
  }

  /**
   * Methode to show CancellationOpenWagon Dialog
   */
  openCancellationOpenWagonPopUpDialog(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('wagon.title_cancellation');
    const message = this.translateService.instant('wagon.cancellation_message');

    dialogConfig.data = {
      namePopUp: 'cancellationOpenWagon',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.isOpenWagon = false;
        this.utilityService.setValue(this.isOpenWagon);
        this.dialog.closeAll();
        this.wagonService.setCurrentWagonId(null);
        this.wagonService.setWagonWorkDone(this.wagon ? this.wagon.id : -1);
      }
    });
  }


  /**
   * Methode to show openingPerformedPopUpDialog Dialog
   * @param isLoadingResults 
   */
  openingPerformedPopUpDialog(isLoadingResults: boolean, logicalWagon: LogicalWagon, dialogSpinner: MatDialogRef<TemplatePopupDialogComponent, any>): MatDialogRef<TemplatePopupDialogComponent, any> {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '12.5rem';
    dialogConfig.minWidth = '13.75rem';
    const title = this.translateService.instant('wagon.title_confirmation');
    const message = this.translateService.instant('wagon.confirmation_message');
    const timeout = UtilityService.UPDATE_DONE_TIMEOUT;
    dialogConfig.data = {
      namePopUp: 'confirmationOpenWagon',
      titlePopUp: title,
      msgPopUp: message,
      isLoadingResults: isLoadingResults
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        if (dialogSpinner) {
          this.isOpenWagon = true;
          this.utilityService.setValue(this.isOpenWagon);
          if (logicalWagon) {
            this.wagonService.setCurrentMex(logicalWagon.mex);
            this.wagonService.setCurrentWagon(logicalWagon.wagonId);
          }
          dialogSpinner.close();
          this.dialog.closeAll();
          this.wagonService.setWagonWorkDone(this.wagon ? this.wagon.id : -1);
        }
      }, timeout);
    });
    return dialogRef;
  }


  /**
   * Methode to show errorOpenPopUpDialog Dialog
   */
  errorOpenPopUpDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '12.5rem';
    dialogConfig.minWidth = '13.75rem';
    const title = this.translateService.instant('wagon.title_erreur_open_wagon');
    const message = this.translateService.instant('wagon.erreur_open_wagon_message');
    dialogConfig.data = {
      namePopUp: 'errorOpenWagon',
      titlePopUp: title,
      msgPopUp: message
    };
    this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
  }


  /**
   * Methode to show Expertise PopUp Dialog
   */
  openExpertisePopUp(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('wagon.opening.confirm_expertise_title');
    const pipe = new DatePipe('en-US'); // Use your own locale
    const myFormattedDate = pipe.transform(this.expertise.creation, 'dd/MM/yyyy');
    const message = this.translateService.instant('wagon.opening.confirm_expertise_msg', { id: this.expertise.id, creation: myFormattedDate, workshop: (this.expertise.workshop ? this.expertise.workshop.code : ''), opMaint: (this.expertise.maintenanceOperation ? this.expertise.maintenanceOperation.code : ''), intReason: (this.expertise.interventionReason ? this.expertise.interventionReason.code : '') });

    dialogConfig.data = {
      namePopUp: 'expertise',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.confirmOpeningWagon();
      }

    });
  }

  /**
   * Methode to show the new ENtry Date 
   */
  changeEntryDate(): string | Date {
    const request = this.getDateFromCtrl(this.openWagonForm.get('entryDate'));
    return request;
  }

  /**
   * Methode to show the new Entry Time
   */
  changeEntryTime(): void {
    const request = this.openWagonForm.get('entryTime').value;
  }

  /**
  * Methode to show the new Release Date
  */
  changeReleaseDate(): void {
    const request = this.getDateFromCtrl(this.openWagonForm.get('releaseDate'));

  }

  /**
   * Methode to show the new Est Release Date
   */
  changeEstReleaseDate(): void {
    const request = this.getDateFromCtrl(this.openWagonForm.get('estReleaseDate'));
  }

  /**
   * Methode to show the new Release Time
   */
  changeReleaseTime(): void {
    const request = this.openWagonForm.get('releaseTime').value;
  }

  /**
   * check the maximum number of wagons for the deal and wagon
   */
  checkMaxNumberWagonByDeal(): void {
    this.caseNumberSelected = this.dealNumberCtrl.value;
    if (this.openWagonForm.controls.dealNumber.value && this.openWagonForm.controls.dealNumber.value.id) {
      const affaireId = this.openWagonForm.controls.dealNumber.value.id;
      this.subscriptionRefs.push(
        this.dealService.isMaxNumberWagonByDeal(affaireId, this.currentLogWagon.wagonId)
          .subscribe((resp) => {
            this.isMaxNumber = this.isMaxNumber = resp.data;
          })
      );
      this.subscriptionRefs.push(
        this.dealService.getDealDetail(affaireId)
          .subscribe((resp) => {
            const dw = resp.data.wagonList.find(e => e.wagonId === this.currentLogWagon.wagonId);
            if(dw && dw.expected && !this.estReleaseDateCtrl.value ) {
              this.estReleaseDateCtrl.setValue(dw.expected);
              this.releaseDateCtrl.setValue(dw.expected);
            }
          })
      );
    }
  }

  /**
   * New object WagonUpdateHistory
   * @param procedure 
   * @param agentCp 
   * @param wagonId 
   */
  createNewObjectWagonUpdateHistory(procedure: string, agentCp: string, wagonId: number): WagonUpdateHistory {
    const wagonUpdateHistory = new WagonUpdateHistory();
    wagonUpdateHistory.wagonId = wagonId;
    wagonUpdateHistory.update = new Date();
    wagonUpdateHistory.functionalProcedureCode = procedure;
    wagonUpdateHistory.agentCp = agentCp;
    return wagonUpdateHistory;
  }

  /**
   * New Object Expertise
   * @param wagonId 
   * @param idExp 
   */
  createNewObjectExpertiseElement(wagonId: number, idExp: number): Expertise {
    const expertise = new Expertise();
    expertise.wagonId = wagonId;
    expertise.id = idExp;
    return expertise;
  }

  /**
   * New Object EventsHistory
   * @param wagonId 
   * @param sevtCode 
   * @param dateEntry 
   */
  createNewObjectEventsHistory(wagonId: number, sevtCode: string, dateEntry: Date): EventsHistory {
    const eventsHistory = new EventsHistory();
    eventsHistory.wphy = wagonId;
    eventsHistory.sSevtCode = sevtCode;
    eventsHistory.dateEvt = dateEntry;
    eventsHistory.dateSyst = new Date();
    eventsHistory.mex = this.currentMex;
    return eventsHistory;
  }

  /**
   * New Object EventsHistoryUpdate
   * @param id 
   * @param workshop 
   * @param trailedMaterialStatus 
   */
  createNewObjectEventsHistoryUpdate(id: number, workshop: Workshop, trailedMaterialStatus: TrailedMaterialStatus): EventsHistoryUpdate {
    const eventsHistoryUpdate = new EventsHistoryUpdate();
    eventsHistoryUpdate.id = id;
    eventsHistoryUpdate.emrCode = trailedMaterialStatus ? trailedMaterialStatus.code : null;
    eventsHistoryUpdate.etabCode = workshop;
    return eventsHistoryUpdate;
  }

  /**
   * Initializing the update space
   * @param wagon 
   * @param curLogWagon 
   * @param listLogWagon 
   */
  initUpdateOpeningWagon(wagon: number, curLogWagon: LogicalWagon, listLogWagon: LogicalWagon[], reduceUpdate: boolean): void {
    this.isLoadingResults = true;
    const openPerfDialog = !reduceUpdate ?this.openingPerformedPopUpDialog(this.isLoadingResults, null, null) : null;
    const initUpdateSpace = this.createNewObjectInitUpdateSpace(wagon, curLogWagon, listLogWagon, this.wagonKnown, reduceUpdate);
    this.subscriptionRefs.push(
      this.wagonService.initUpdateSpace(initUpdateSpace)
        .subscribe((value) => {
          const logWagon = value.data;
          this.utilityService.setReducedUpdateInput(null);
          if (logWagon) {
            if (!reduceUpdate){
              this.isLoadingResults = false;
              this.wagonService.setCurrentWagon(wagon);
              if (!this.wagonService.getDealInfos().multiple || logWagon.wagonId !== this.wagonService.getDealInfos().currentWagonId) {
                this.wagonService.setCurrentWagonId(logWagon.wagonId);
              }
              // Show opening Performed effectué 
              this.openingPerformedPopUpDialog(this.isLoadingResults, logWagon, openPerfDialog);
            } else {
              this.isLoadingResults = false;
              this.isOpenWagon = true;
              this.utilityService.setValue(this.isOpenWagon);
              this.wagonService.setCurrentMex(logWagon.mex);
              this.wagonService.setCurrentWagon(wagon);
              this.wagonService.setCurrentWagonId(logWagon.wagonId);
              const releaseDate = this.datepipe.transform(this.release, 'yyyy-MM-dd');
              this.subscriptionRefs.push(
                this.wagonService.hasDuplicateInterventionHistory(logWagon.wagonId, releaseDate , this.f.workshop.value.code)
                .subscribe(duplicateInterv => {
                  const hasDuplicateInterv = duplicateInterv.data;
                  if (hasDuplicateInterv){
                    this.openWagonDuplicateInterventionPopUpDialog();
                  } else {
                    const timeout = UtilityService.UPDATE_DONE_TIMEOUT;
                    setTimeout(() => {
                      // Set values to page lot 5 (ReducedUpdate component)
                      const reducedUpdateInput =  this.createReducedUpdateInput(reduceUpdate);
                      this.utilityService.setReducedUpdateInput(reducedUpdateInput);
                      this.dialog.closeAll();
                      // TODO: restore next line if reduced update is registered in WAGON_MAJ
                      // this.wagonService.setWagonWorkDone(wagon);
                    }, timeout);
                  }
                })
              );
            }
          } else {
            this.errorOpenPopUpDialog();
            openPerfDialog.close();
          }
        })
    );
  }

  /**
   * Create reduceUpdate
   * @param reduceUpdate 
   * @returns ReducedUpdateInput
   */
  createReducedUpdateInput(reduceUpdate: boolean): ReducedUpdateInput{
    const reducedUpdateInput = new ReducedUpdateInput();
    reducedUpdateInput.reducedUpdateCheckbox = reduceUpdate;
    reducedUpdateInput.workshop = this.workshopSelected;
    reducedUpdateInput.updateType = this.updateTypeSelected; 
    reducedUpdateInput.maintenanceOperation = this.maintenanceOperationSelected;
    reducedUpdateInput.pattern = (this.f.pattern && this.f.pattern.value) ? this.f.pattern.value : null;
    reducedUpdateInput.cause = (this.f.cause && this.f.cause.value) ? this.f.cause.value : null;
    reducedUpdateInput.imputation = (this.f.responsability && this.f.responsability.value) ? this.f.responsability.value : null;
    reducedUpdateInput.entryDate = this.entry;
    reducedUpdateInput.releaseDate = this.release;
    reducedUpdateInput.observations = (this.f.observations && this.f.observations.value && this.f.observations.value !== '') ? this.f.observations.value : null;
    reducedUpdateInput.option1 = (this.f.additional_obsrv_1 && this.f.additional_obsrv_1.value && this.f.additional_obsrv_1.value !== '') ? this.f.additional_obsrv_1.value : null;
    reducedUpdateInput.option2 = (this.f.additional_obsrv_2 && this.f.additional_obsrv_2.value && this.f.additional_obsrv_2.value !== '') ? this.f.additional_obsrv_2.value : null;
    reducedUpdateInput.dealNumber = (this.f.dealNumber && this.f.dealNumber.value && this.f.dealNumber.value) ? this.f.dealNumber.value.id : null;
    if (this.f.reference_wagon && this.f.reference_wagon.value && this.f.reference_wagon.value !== '') {
      reducedUpdateInput.referenceWagon = this.f.reference_wagon.value;
    }
    reducedUpdateInput.expertiseChecked = this.openWagonForm.value.expertiseCheckbox;
    reducedUpdateInput.expertise = this.expertise;
    return reducedUpdateInput;
  }

  /**
  * Methode to get InitUpdateSpace object 
  * @param wagonId 
  * @param curLogWagon 
  * @param listLogWagon 
  */
  createNewObjectInitUpdateSpace(wagonId: number, curLogWagon: LogicalWagon, listLogWagon: LogicalWagon[], wagonKnown: boolean, reduceUpdate: boolean): InitUpdateSpace {
    const initUpdateSpace = new InitUpdateSpace();
    initUpdateSpace.wagonKnown = wagonKnown;
    initUpdateSpace.reduceUpdate = reduceUpdate;
    initUpdateSpace.wagonId = wagonId;
    initUpdateSpace.currentLogicalWagon = curLogWagon;
    initUpdateSpace.logicalWagons = listLogWagon;
    initUpdateSpace.workshop = this.f.workshop.value;
    initUpdateSpace.updateType = this.f.updateType.value;
    initUpdateSpace.currentMex = this.currentMex;
    if (this.f.reference_wagon && this.f.reference_wagon.value && this.f.reference_wagon.value !== '') {
      initUpdateSpace.refWagon = this.f.reference_wagon.value;
    }
    initUpdateSpace.dataEntryWorkshop = this.workshopAgent;
    initUpdateSpace.maintenanceOperation = (this.f.maintenanceOperation && this.f.maintenanceOperation.value) ? this.f.maintenanceOperation.value : null;
    initUpdateSpace.interventionReason = (this.f.pattern && this.f.pattern.value) ? this.f.pattern.value : null;
    initUpdateSpace.interventionCause = (this.f.cause && this.f.cause.value) ? this.f.cause.value : null;
    initUpdateSpace.imputation = (this.f.responsability && this.f.responsability.value) ? this.f.responsability.value : null;
    initUpdateSpace.trailedMaterialStatus = (initUpdateSpace.maintenanceOperation && initUpdateSpace.maintenanceOperation.trailedMaterialStatus) ? initUpdateSpace.maintenanceOperation.trailedMaterialStatus : null;
    initUpdateSpace.observation = (this.f.observations && this.f.observations.value && this.f.observations.value !== '') ? this.f.observations.value : null;
    initUpdateSpace.option1 = (this.f.additional_obsrv_1 && this.f.additional_obsrv_1.value && this.f.additional_obsrv_1.value !== '') ? this.f.additional_obsrv_1.value : null;
    initUpdateSpace.option2 = (this.f.additional_obsrv_2 && this.f.additional_obsrv_2.value && this.f.additional_obsrv_2.value !== '') ? this.f.additional_obsrv_2.value : null;
    initUpdateSpace.dealId = (this.f.dealNumber && this.f.dealNumber.value && this.f.dealNumber.value) ? this.f.dealNumber.value.id : null;
    initUpdateSpace.pvcaId = (this.f.pvca && this.f.pvca.value && this.f.pvca.value !== '') ? this.f.pvca.value.id : null
    initUpdateSpace.expId = this.expertise ? this.expertise.id : 0;
    initUpdateSpace.exit = this.estRelease;
    initUpdateSpace.exitSys = null;
    initUpdateSpace.entry = this.entry;
    initUpdateSpace.hasExpertise = this.openWagonForm.value.expertiseCheckbox;
    initUpdateSpace.agentCp = this.agent.cp;
    return initUpdateSpace;

  }

  /**
   * Method to confirm Opening Wagon
   */
  confirmOpeningWagon(): void {
    const wagonId =  this.wagonService.getCurrentWagon()
    const reduceUpdate = this.openWagonForm.controls.reducedUpdateCheckbox.value;
    if (!this.currentLogWagon) {
      this.initUpdateOpeningWagon(wagonId, null, null, reduceUpdate);
    } else {
      this.initUpdateOpeningWagon(wagonId, this.currentLogWagon, this.data.logicals, reduceUpdate);
    }
  }

  /**
   * Methode to check if select combo is valid
   * @param ctrl 
   */
  ctrlSelectValid(ctrl: FormControl): boolean {
    if (ctrl.value && ctrl.value.code) {
      return true;
    } else {
      ctrl.setErrors({ 'incorrect': true });
      return false;
    }
  }

  /**
   * Methode to check if select combo is id valid
   * @param ctrl 
   */
  ctrlIdSelectValid(ctrl: FormControl): boolean {
    if (ctrl.value) {
      if (ctrl.value.id) {
        return true;
      } else {
        ctrl.setErrors({ 'incorrect': true });
        return false;
      }
    } else {
      return true;
    }
  }

  /**
   * Methode to check the combo value selected
   */
  checkSelectComboValue(): boolean {
    const workshop = this.ctrlSelectValid(this.workshopCtrl);
    const updateType = this.ctrlSelectValid(this.updateTypeCtrl);
    const maintenanceOperation = this.ctrlSelectValid(this.maintenanceOperationCtrl);
    const pvca = this.ctrlIdSelectValid(this.pvcaCtrl);
    const dealNumber = this.ctrlIdSelectValid(this.dealNumberCtrl);
    if (this.maintenanceOperationCtrl.value && this.maintenanceOperationCtrl.value.isReasonMandatory) {
      const pattern = this.ctrlSelectValid(this.patternCtrl);
      const cause = this.ctrlSelectValid(this.causeCtrl);
      const responsability = this.ctrlSelectValid(this.responsabilityCtrl);
      return (workshop && updateType && maintenanceOperation && pattern && cause && responsability && pvca && dealNumber);
    } else {
      return (workshop && updateType && maintenanceOperation && pvca && dealNumber);
    }
  }

  /**
   * Methode to submit the form
   */
  onSubmit(): void {
    this.submitted = true;
    if (!this.checkSelectComboValue() || this.openWagonForm.invalid || this.isMaxNumber || !this.validWagonReference) {
      return;
    } else {
      
      const dealInfo = this.wagonService.getDealInfos();
      if (dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'record') && (this.dealNumberCtrl.value?.id === dealInfo.id)) {
        dealInfo.multiple = {
          auto: dealInfo.multiple.auto,
          state: 'done',
          workshop: this.workshopCtrl.value,
          updateType: this.updateTypeCtrl.value,
          maintenanceOperation: this.maintenanceOperationCtrl.value,
          dealNumber: this.dealNumberCtrl.value,
          pattern: this.patternCtrl.value,
          cause: this.causeCtrl.value,
          responsability: this.responsabilityCtrl.value,
          entryDate: this.entryDateCtrl.value,
          entryTime: this.entryTimeCtrl.value,
          releaseDate: this.releaseDateCtrl.value,
          releaseTime: this.releaseTimeCtrl.value,
          prevDate: this.estReleaseDateCtrl.value,
          observations: this.openWagonForm.get('observations').value,
          additional_obsrv_1: this.openWagonForm.get('additional_obsrv_1').value,
          additional_obsrv_2: this.openWagonForm.get('additional_obsrv_2').value,
          reducedUpdateCheckbox: this.openWagonForm.get('reducedUpdateCheckbox').value,
        }
        this.wagonService.setDealInfos(dealInfo.id, dealInfo.interveningWorkshopList, dealInfo.multiple);        
      }
      if (this.expertise) {
        if (this.openWagonForm.value.expertiseCheckbox) {
          this.confirmOpeningWagon();
        } else {
          this.openExpertisePopUp();
        }
      } else {
        this.confirmOpeningWagon();
      }
    }
  }

  /**
   * Contrôle 1 : Vérifier la validité du wagon de référence. 
   */
  onCheckReferenceWagon(): void {
    this.validWagonReference = true;
    if (this.f.reference_wagon && this.f.reference_wagon.value && this.f.reference_wagon.value.length === 12) {
      this.subscriptionRefs.push(
        this.wagonService.isValidReferenceWagon(this.currentLogWagon.wagonId, this.f.reference_wagon.value, this.currentLogWagon.mex)
          .subscribe(wr => {
            this.validWagonReference = wr.data;
          })
      );
    }
  }

  displayByCode(data: any) {
    return data ? (data.code ? data.code : '') : '';
  }

  displayById(data: any) {
    return data ? (data.id ? data.id : '') : '';
  }

  displayByCodLabel(data: any) {
    return data ? ((data.code ? data.code : '') + (data.label ? ': ' + data.label : '')) : '';
  }

  displayDeal(data: any) {
    return data ? ((data.id ? data.id : '') + (data.object ? ' ' + data.object : '')) : '';
  }

  displayPVCA(data: any) {
    const pipe = new DatePipe('en-US'); // Use your own locale
    return data ?
      (
        (data.id ? data.id +
          (data.arrival ? ' du ' + (pipe.transform(data.arrival, 'dd/MM/yyyy')) +
            (data.gcuList[0] ? ' - Défauts : ' + data.gcuList[0].code +
              (data.gcuList[1] ? ' ' + data.gcuList[1].code +
                (data.gcuList[2] ? ' ' + data.gcuList[2].code
                  : '')
                : '')
              : '')
            : '')
          : '')
      ) : '';

  }

  /**Clean WorkShop */
  cleanWorkShop(): void {
    this.workshopCtrl.setValue(undefined);
    this.cleanUpdateType();
  }

  /** Clean Update Type and MaintenanceOperation */
  cleanUpdateType(): void {
    this.updateTypeCtrl.setValue(undefined);
    this.maintenanceOperationCtrl.disable();
    this.cleanMaintenanceOperation();
  }

  /** Clean Date Entry */
  cleanEntryDate(): void {
    this.entryDateCtrl.setValue(undefined);
  }

  /** Clean Time Entry */
  cleanEntryTime(): void {
    this.entryTimeCtrl.setValue(undefined);
  }

  /** Clean Release Date */
  cleanReleaseDate(): void {
    this.releaseDateCtrl.setValue(undefined);
  }

  /** Clean ReleaseTime */
  cleanReleaseTime(): void {
    this.releaseTimeCtrl.setValue(undefined);
  }

  /** Clean Est. Release Date entry */
  cleanEstReleaseDate(): void {
    this.estReleaseDateCtrl.setValue(undefined);
  }

  /** Clean Maintenance Operation */
  cleanMaintenanceOperation(): void {
    this.maintenanceOperationCtrl.setValue(undefined);
    this.patternCtrl.disable();
    this.causeCtrl.disable();
    this.responsabilityCtrl.disable();
    this.cleanPattern();
    this.cleanCause();
    this.cleanResponsability();
    this.isRevisionN = false;
  }

  cleanPattern(): void {
    this.patternCtrl.setValue(undefined);
  }
  cleanCause(): void {
    this.causeCtrl.setValue(undefined);
  }
  cleanResponsability(): void {
    this.responsabilityCtrl.setValue(undefined);

  }
  cleanDealNumber(): void {
    this.dealNumberCtrl.setValue(undefined);
  }

  cleanPVCA(): void {
    this.pvcaCtrl.setValue(undefined);
  }

  /**
   * Methode to clean alerts errors.
   */
  disableAlerts(): void {
    if (this.submitted) {
      this.submitted = false;
    }
    if (this.isRevisionN) {
      this.isRevisionN = false;
    }
    if (this.isMaxNumber) {
      this.isMaxNumber = false;
    }
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
    this.loadingSubject.complete();
  }

  /**
   * Methode to show Duplicate intervention Dialog
   */
   openWagonDuplicateInterventionPopUpDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('wagon.title_duplicate_intervention');
    const message = this.translateService.instant('wagon.erreur_duplicate_intervention_message');

    dialogConfig.data = {
      namePopUp: 'duplicate_intervention',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(() => {
      this.releaseDateCtrl.setValue(this.releaseDatOrig);
      this.releaseTimeCtrl.setValue(this.releaseTimeOrig);
      this.wagonKnown = true;
      return;
    });
  }

  checkAutoMode() {
    const dealInfo = this.wagonService.getDealInfos();
    if (dealInfo.multiple?.auto && !this.autoSubmitDone && dealInfo && dealInfo.id && (dealInfo.multiple?.state === 'done') && (this.dealNumberCtrl.value?.id === dealInfo.id) 
      && (dealInfo.multiple.workshop.code === this.workshopCtrl.value?.code
      && dealInfo.multiple.updateType.code === this.updateTypeCtrl.value?.code
      && dealInfo.multiple.maintenanceOperation.code === this.maintenanceOperationCtrl.value?.code
      && dealInfo.multiple.dealNumber.id === this.dealNumberCtrl.value?.id
      && dealInfo.multiple.pattern?.code === this.patternCtrl.value?.code
      && dealInfo.multiple.cause?.code === this.causeCtrl.value?.code
      && dealInfo.multiple.responsability?.code === this.responsabilityCtrl.value?.code
      && dealInfo.multiple.entryDate === this.entryDateCtrl.value
      && dealInfo.multiple.entryTime === this.entryTimeCtrl.value
      && (!dealInfo.multiple.reducedUpdateCheckbox || dealInfo.multiple.releaseDate === this.releaseDateCtrl.value)
      && (!dealInfo.multiple.reducedUpdateCheckbox || dealInfo.multiple.releaseTime === this.releaseTimeCtrl.value)
      && (dealInfo.multiple.reducedUpdateCheckbox || dealInfo.multiple.prevDate === this.estReleaseDateCtrl.value)
      && dealInfo.multiple.observations === this.openWagonForm.get('observations').value
      && dealInfo.multiple.additional_obsrv_1 === this.openWagonForm.get('additional_obsrv_1').value
      && dealInfo.multiple.additional_obsrv_2 === this.openWagonForm.get('additional_obsrv_2').value
      && dealInfo.multiple.reducedUpdateCheckbox === this.openWagonForm.get('reducedUpdateCheckbox').value)) {
        this.autoSubmitDone = true;
        this.onSubmit();
    }
  }

}
