import { WagonTechnicalData } from './wagon-technical-data';
import { WagonUpdate } from './wagon-update';
import { ConstructionType } from './construction-type';
import { StatisticalCode } from './statistical-code';
import { NoRIV } from './no-riv';
import { SpecialAgreement } from './special-agreement';
import { UsageRestriction } from './usage-restriction';

/** API model: Page MAJ_003_6 Suivi des Travaux / Famille et Restriction */
export class FamilyRestrictions {

  wagonTechnicalData: WagonTechnicalData;

  wagonUpdate: WagonUpdate;

  divisionCode: string;

  constructionType: ConstructionType;

  statisticalCode: StatisticalCode;

  mexToBeModifier: string;

  mexModified: string;

  noRIV: NoRIV;

  specialAgreement: SpecialAgreement;

  usageRestriction: UsageRestriction;
}
