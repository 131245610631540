import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ComponentGroup } from 'src/app/shared/models/component-group';
import { Components } from 'src/app/shared/models/components';
import { Defect } from 'src/app/shared/models/defect';
import { Job } from 'src/app/shared/models/job';
import { JobDefect } from 'src/app/shared/models/job-defect';
import { MaintenancePlanUpdate } from 'src/app/shared/models/maintenance-plan-update';
import { TTache } from 'src/app/shared/models/t-tache';
import { TaskParams } from 'src/app/shared/models/task-params';
import { MaintenancePlanUpdateService } from 'src/app/shared/services/maintenance-plan-update/maintenance-plan-update.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { StateJob } from "../../../models/state-job";
import { TemplatePopupDialogComponent } from '../../template-popup-dialog/template-popup-dialog.component';


@Component({
  selector: 'app-task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss']
})
export class TaskDialogComponent implements OnInit, OnDestroy {

  @Input() fromAddLine: boolean;
  @Input() public taskDialogDatas: {
    stateJobs: StateJob[],
    componentGroups: ComponentGroup[],
    components: Components[],
    jobs: Job[],
    defects: Defect[],
    jobDefects: JobDefect[],
    tTaches: TTache[]
  };
  @Output() onTaskAdded = new EventEmitter<boolean>();

  /** Title of Dialog */
  title: string;

  /** Open form group */
  taskForm: FormGroup;
  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** submitted property */
  submitted: boolean;
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Current wagon */
  currentWagon: number;

  /** new Component form control */
  componentCtrl: FormControl;
  /** component selected */
  componentSelected: Components;
  /** la liste des établissements valides */
  componentList: Components[];
  /** filtered component list */
  filteredComponentList: Components[];

  /** new ComponentGroup form control */
  componentGrpCtrl: FormControl;
  /** componentGroup selected */
  componentGroupSelected: ComponentGroup;
  /** la liste des établissements valides */
  componentGroupList: ComponentGroup[];
  /** filtered componentGroup list */
  filteredComponentGroupList: ComponentGroup[];
  /** new Job form control */
  jobCtrl: FormControl;
  /** Job selected */
  jobSelected: Job;
  /** la liste des établissements valides */
  jobList: Job[];
  /** filtered Job list */
  filteredJobList: Job[];
  /** new Defect form control */
  defectCtrl: FormControl;
  /** new Amount form control */
  amountCtrl: FormControl;
  /** Defect selected */
  defectSelected: Defect;
  /** la liste des defects valides */
  defectList: Defect[];
  /** filtered defects list */
  filteredDefectList: Defect[];
  /** Amount */
  amount: number;
  /** new Status form control */
  statusCtrl: FormControl;
  /** Status selected */
  statusSelected: StateJob;
  /** Current MaintenancePlanUpdate */
  currentMpu: MaintenancePlanUpdate = undefined;
  /** Current MaintenancePlanUpdate */
  mpuToUpdate: MaintenancePlanUpdate = undefined;
  /** mise a jour */
  operationUpdate: boolean = undefined;

  modifyUpdateTask: boolean = undefined;

  modifyTaskPk: boolean = undefined;

  amountModify: boolean = undefined;

  statusModify: boolean = undefined;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TaskDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TaskParams,
    private translateService: TranslateService,
    private maintenancePlanUpdateService: MaintenancePlanUpdateService) {
    if (data.wagonId) {
      this.currentWagon = data.wagonId;
      this.operationUpdate = false;
    } else if (data.mpu) {
      this.currentMpu = data.mpu;
      this.currentWagon = this.currentMpu.wagonId
      this.operationUpdate = true;
      this.fromAddLine = data.fromAddLine;
    }
    
    if (data.taskDialogDatas) {
      this.taskDialogDatas = data.taskDialogDatas;
    }
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.resetDatas();
    if (this.operationUpdate && this.fromAddLine){
      this.componentCtrl = this.formBuilder.control({value:'', disabled: true} , Validators.required);
      this.componentGrpCtrl = this.formBuilder.control({value:'', disabled: true} , Validators.required);
      this.jobCtrl = this.formBuilder.control({value: '', disabled: true}, Validators.required);
      this.defectCtrl = this.formBuilder.control({value: ''});
      this.statusCtrl = this.formBuilder.control({value: this.currentMpu.state});
    }else{
      this.componentCtrl = this.formBuilder.control({value:'', disabled: false} , Validators.required);
      this.componentGrpCtrl = this.formBuilder.control({value:'', disabled: false} , Validators.required);
      this.jobCtrl = this.formBuilder.control({value: '', disabled: true}, Validators.required);
      this.defectCtrl = this.formBuilder.control({value: '', disabled: true});
      this.statusCtrl = this.formBuilder.control(null);
    }
    
    if (!this.operationUpdate && this.fromAddLine){
      //Initialise seulement pour ajouter une tache depuis monitoringWorks
      this.statusCtrl.setValue(this.taskDialogDatas?.stateJobs?this.taskDialogDatas.stateJobs[0]:null);
    }
    this.amountCtrl = this.formBuilder.control(null);
    

    this.taskForm = this.formBuilder.group({
      component: this.componentCtrl,
      componentGrp: this.componentGrpCtrl,
      job: this.jobCtrl,
      defect: this.defectCtrl,
      amountCN: this.amountCtrl,
      status: this.statusCtrl,
    }, {
      validator: []
    });
    
    this.componentGroupList = this.taskDialogDatas.componentGroups
    this.componentList = this.taskDialogDatas.components;
    if (this.currentMpu) {
      this.loadMaintenancePlanUpdate(); 
    }
    this.loadingSubject.next(false);

    this.subscriptionRefs.push(this.componentCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => (this.filteredComponentList = this.filterWithCodeLabel(value, this.componentList),
        this.modifyUpdateTask = this.verifyModifyUpdateTask())
      ));

    this.subscriptionRefs.push(this.componentGrpCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => (this.filteredComponentGroupList = this.filterWithCodeLabel(value, this.componentGroupList),
        this.modifyUpdateTask = this.verifyModifyUpdateTask())
      ));

    this.subscriptionRefs.push(this.jobCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => (this.filteredJobList = this.filterWithCodeLabel(value, this.jobList),
        this.modifyUpdateTask = this.verifyModifyUpdateTask())
      ));

    this.subscriptionRefs.push(this.defectCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => (this.filteredDefectList = this.filterWithCodeLabel(value, this.defectList),
        this.modifyUpdateTask = this.verifyModifyUpdateTask())
      ));

    this.subscriptionRefs.push(this.amountCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.modifyUpdateTask = this.verifyModifyUpdateTask()
      ));

    this.subscriptionRefs.push(this.statusCtrl.valueChanges
        .pipe(debounceTime(10)).subscribe(
          value => this.modifyUpdateTask = this.verifyModifyUpdateTask()
        ));
  }

  /**
   * Reset les donnes
   */
  private resetDatas(): void {
    this.submitted = false;
    this.amount = undefined;
    this.modifyTaskPk = false;
    this.amountModify = false;
    this.statusModify = false;
    this.modifyUpdateTask = false;
    this.title= this.translateService.instant('wagon-update.task.add.title');
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

  /**
   * Charge les donnes du Plan Maintenance a faire la mise a jour
   */
  loadMaintenancePlanUpdate(): void {
    this.title= this.translateService.instant('wagon-update.task.update.title');
    this.mpuToUpdate = new MaintenancePlanUpdate();
    this.mpuToUpdate.amount = this.currentMpu.amount === 0 ? undefined : this.currentMpu.amount ;
    this.mpuToUpdate.defect = this.currentMpu.defect;
    this.mpuToUpdate.component = this.currentMpu.component;
    this.mpuToUpdate.job = this.currentMpu.job;
    this.mpuToUpdate.wagonId = this.currentMpu.wagonId;
    this.mpuToUpdate.state = this.currentMpu.state;
    if (this.currentMpu.defect.code === '999') {
      this.mpuToUpdate.defect = undefined;
    }
    
    /**Initialise la liste avec les valeurs reinsegnes */
    this.componentGroupSelected = this.mpuToUpdate.component.group;
    this.componentSelected = this.mpuToUpdate.component;
    this.jobSelected = this.mpuToUpdate.job;
    this.defectSelected = this.mpuToUpdate.defect;
    
    this.amount = this.mpuToUpdate.amount;

    if (this.defectSelected) {
      this.defectCtrl.setValue(this.defectSelected);
    } else {
      this.defectCtrl.setValue(null);
    }
    this.componentList = this.taskDialogDatas.components.filter( e => e.group.code === this.componentGroupSelected.code);
    this.jobList = this.taskDialogDatas.tTaches.filter( e => e.componentCode === this.componentSelected.code).map(e=> this.taskDialogDatas.jobs.find(j => j.code === e.jobCode));
    this.defectList =  this.taskDialogDatas.jobDefects.filter(e => e.jobCode === this.jobSelected.code && e.componentCode === this.componentSelected.code)
                          .map ( e=> this.taskDialogDatas.defects.find( d => d.code === e.defectCode));
    this.filteredComponentList = this.componentList;        
    this.filteredJobList = this.jobList;
    this.filteredDefectList = this.defectList;
    if (this.operationUpdate && this.fromAddLine){
      this.statusCtrl.setValue(this.mpuToUpdate.state);
    }
    if (this.jobCtrl.disabled && ((!this.operationUpdate && this.fromAddLine ) || (!this.fromAddLine))) {
      this.jobCtrl.enable();
    }
    if (this.jobCtrl.value){
      this.defectCtrl.enable();
    }

    

  }

  /**
   * convenience getter for easy access to form fields
   */
  get f() {
    return this.taskForm.controls;
  }

  private filterWithCodeLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + v.label).toUpperCase().includes(filterValue))
      ) : list;
  }

  /**
   * On click ComponentGroup Selection
   */
  onComponentGrpSelection() {
    if (this.componentGrpCtrl.value === '' || !this.componentGrpCtrl.value || !this.componentGrpCtrl.value.code) {

      this.componentGroupList = this.taskDialogDatas.componentGroups;
      this.componentCtrl.setValue(undefined);
      this.filteredComponentGroupList = this.componentGroupList;
      this.componentList = this.taskDialogDatas.components;
      this.filteredComponentList = this.componentList;
    } else if (this.componentGrpCtrl.value.code && (this.componentGroupSelected === undefined || this.componentGrpCtrl.value.code !== this.componentGroupSelected.code)) {
      this.componentGroupSelected = this.componentGrpCtrl.value;
      this.componentList = this.taskDialogDatas.components.filter( e => e.group.code === this.componentGroupSelected.code);
      if (!this.componentCtrl.value || this.componentCtrl.value.group.code != this.componentGroupSelected.code) {
        this.componentCtrl.setValue(undefined);
      }
      this.filteredComponentList = this.componentList;
    }

  }

  /**
   * On click sur Component
   */
  onComponentSelection() {
    if (this.componentCtrl.value === '' || !this.componentCtrl.value || !this.componentCtrl.value.code) {
      if (this.componentGrpCtrl.value && this.componentGrpCtrl.value.code && (this.componentGroupSelected === undefined || this.componentGrpCtrl.value.code !== this.componentGroupSelected.code)) {
              this.componentList = this.taskDialogDatas.components.filter( e => e.group.code === this.componentGrpCtrl.value.code);
        this.componentCtrl.setValue(undefined);
        this.filteredComponentList = this.componentList;
      }
      this.jobCtrl.setValue(undefined);
      this.jobCtrl.disable();
    } else if (this.componentCtrl.value.code && (this.componentSelected === undefined || this.componentCtrl.value.code !== this.componentSelected.code)) {
      this.componentSelected = this.componentCtrl.value;
      const component = this.taskDialogDatas.components.find(e => e.code===this.componentSelected.code);
      this.componentGroupList = this.taskDialogDatas.componentGroups.filter(g => g.code === component.group.code);
      this.filteredComponentGroupList = this.componentGroupList;
      if (!this.componentGrpCtrl.value || this.componentGrpCtrl.value.code != this.componentSelected.group.code) {
        this.componentGrpCtrl.setValue(this.componentGroupList[0]);
      }
      this.jobList = this.taskDialogDatas.tTaches.filter( e => e.componentCode === this.componentSelected.code).map(e=> this.taskDialogDatas.jobs.find(j => j.code === e.jobCode));
      if (this.jobCtrl.disabled && ((!this.operationUpdate && this.fromAddLine ) || (!this.fromAddLine))) {
        this.jobCtrl.enable();
      }
      this.filteredJobList = this.jobList;
    }
  }

  /**
   * On click sur job
   */
  onJobSelection(): void {
    if (this.jobCtrl.value === '' || !this.jobCtrl.value || !this.jobCtrl.value.code) {

      this.defectCtrl.setValue(undefined);
      this.defectCtrl.disable();

    } else if (this.jobCtrl.value.code && (this.jobSelected === undefined || this.jobCtrl.value.code !== this.jobSelected.code)) {
      this.jobSelected = this.jobCtrl.value;
      this.defectList =  this.taskDialogDatas.jobDefects.filter(e => e.jobCode === this.jobSelected.code && e.componentCode === this.componentSelected.code)
        .map ( e=> this.taskDialogDatas.defects.find( d => d.code === e.defectCode));
      if (this.defectList === undefined || this.defectList.length===0) {
        this.defectCtrl.setValue(undefined);
        this.defectCtrl.disable();
      } else {
        if (this.defectCtrl.disabled) {
          this.defectCtrl.enable();
        }
      }
      this.filteredDefectList = this.defectList;
    }
  }

  /**
   * On click sur defect
   */
  onDefectSelection(): void {
    if (this.defectCtrl.value && this.defectCtrl.value.code && (this.defectSelected === undefined|| this.defectCtrl.value.code !== this.defectSelected.code)) {
      this.defectSelected = this.defectCtrl.value;
    } else {
      this.defectSelected = undefined;
    }
  }

  /**
   * Remettre le champs à blanc quand l'utilisateur change le focus si aucune valeur
   * de la liste a été sélectionnée
   */
  defectListFocusOut(): void {
    if (!this.defectSelected) {
      this.defectCtrl.setValue(null);
    }
  }

  displayCodeLabel(data: any) {
    return data ? (data.code ? data.code + ' - ' + data.label : data) : '';
  }

  /**Clean ComponentGrp */
  cleanComponentGrp(): void {
    this.componentGrpCtrl.setValue(undefined);
    if (this.componentCtrl.value == undefined) {
      this.componentList = this.taskDialogDatas.components;
      this.componentGroupList = this.taskDialogDatas.componentGroups;
      this.filteredComponentGroupList = this.componentGroupList;
      this.filteredComponentList = this.componentList;
    }
  }

  /** Clean Update Type and MaintenanceOperation */
  cleanComponent(): void {
    this.componentCtrl.setValue(undefined);
    this.cleanJob();
    if (this.componentGrpCtrl.value == undefined) {
      this.componentList = this.taskDialogDatas.components;
      this.componentGroupList = this.taskDialogDatas.componentGroups;
      this.filteredComponentGroupList = this.componentGroupList;
      this.filteredComponentList = this.componentList;
    }
    this.jobCtrl.disable();
  }

  cleanJob(): void {
    this.jobCtrl.setValue(undefined);
    this.cleanDefect();
    this.defectCtrl.disable();
  }

  cleanDefect(): void {
    this.defectCtrl.setValue(null);
  }

  /**
   * Methode to clean alerts errors.
   */
  disableAlerts(): void {
    if (this.submitted) {
      this.submitted = false;
    }
  }

  /**
   * Methode to cancel operation
   */
  cancelTask(): void {
    if (this.operationUpdate){
      if (this.verifyModifyUpdateTask()){
        this.unsavedChangesPopUpDialog();
      } else {
        this.onTaskAdded.emit(false);
        if (this.data && this.data.mpu) {
          this.dialogRef.close();
        }        
      }
    } else{
      if (this.verifyRequiredDataTaskAdded()) {
        this.unsavedChangesPopUpDialog();
      } else {
        this.onTaskAdded.emit(false);
        if (this.data && this.data.mpu) {
          this.dialogRef.close();
        }        
      }
    }
  }

  /**
   * Methode pour sauvegarder une tâche
   */
  saveTask(): void {
      this.submitted = true;
      this.subscriptionRefs.push(
        this.maintenancePlanUpdateService.exist(this.currentWagon, this.jobSelected.code, this.componentSelected.code, (this.defectSelected ? this.defectSelected.code : undefined))
          .subscribe(exist => {
            const existTask = exist.data;
            if (existTask) {
              this.existTaskPopUpDialog();
            } else {
              const maintenancePUpdate: MaintenancePlanUpdate = new MaintenancePlanUpdate();
              maintenancePUpdate.wagonId = this.currentWagon;
              maintenancePUpdate.amount = (this.amount == undefined) ? 0 : this.amount;
              maintenancePUpdate.component = this.componentCtrl.value;
              maintenancePUpdate.job = this.jobCtrl.value;
              maintenancePUpdate.defect = this.defectCtrl.value;
              maintenancePUpdate.state = this.statusCtrl.value;
              this.subscriptionRefs.push(
                this.maintenancePlanUpdateService.createMaintenancePlanUpdate(maintenancePUpdate)
                  .subscribe(mpu => {
                   if (this.fromAddLine) {
                      this.updateDone(false);
                      this.onTaskAdded.emit(true);
                    } else {
                      this.dialogRef.close(true);
                   }
                  }, (error) => console.log(error))
              );
            }
          })
      );
  }

  /**
   * Methode pour fiare la mise a jour d'une tâche
   */
  updateTask(): void {
    this.submitted = true;
    if (this.verifyRequiredDataTaskAdded()){
      if( this.modifyTaskPk){
        this.subscriptionRefs.push(
          this.maintenancePlanUpdateService.exist(this.currentWagon, this.jobSelected.code, this.componentSelected.code, (this.defectSelected ? this.defectSelected.code : undefined))
            .subscribe(exist => {
              const existTask = exist.data;
              if (existTask) {
                this.existTaskPopUpDialog();
              } else {
                this.subscriptionRefs.push(
                  this.maintenancePlanUpdateService.updateMaintenancePlanUpdate(this.mpuToUpdate, this.currentWagon, this.jobSelected.code, this.componentSelected.code, (this.fromAddLine? this.statusCtrl.value.code :this.mpuToUpdate.state.code) ,(this.defectSelected ? this.defectSelected.code : undefined), this.amount)
                    .subscribe(() => {
                      this.updateDone(true);
                    })
                );
              }
          })
        );
      }else if (this.amountModify || this.statusModify){
        this.subscriptionRefs.push(
          this.maintenancePlanUpdateService.updateMaintenancePlanUpdate(this.mpuToUpdate, this.currentWagon, this.jobSelected.code, this.componentSelected.code,(this.fromAddLine? this.statusCtrl.value.code :this.mpuToUpdate.state.code), (this.defectSelected ? this.defectSelected.code : undefined), this.amount)
            .subscribe(() => {
              this.updateDone(true);
            })
        );
      }
    }
  }

  /**
   * Le message se ferme quand 5 seconds sont pasé
   */
  updateDone(closeWindows?:boolean): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    const title = this.translateService.instant('wagon-update.task.update-done.msg');
    const message = this.translateService.instant('wagon-update.task.update-done.msg');

    const timeout = UtilityService.UPDATE_DONE_TIMEOUT;

    dialogConfig.data = {
      namePopUp: 'update-done-alert',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
        if(closeWindows == undefined || closeWindows){
          this.dialogRef.close(true);
        }
      }, timeout)
    });
  }


  /**
   * verifier s'il y a tous les donnes requises sur le form task
   */
  verifyRequiredDataTaskAdded(): boolean {
    return (this.jobCtrl.value && this.componentCtrl.value && this.componentGrpCtrl.value);
  }

  /**
   * verifier s'il y a des modifications
   */
  verifyModifyUpdateTask(): boolean {
    if (this.operationUpdate){
      this.amountModify = !this.mpuToUpdate.amount && (this.amount && this.amount.toString()!=='') || this.mpuToUpdate.amount != this.amount ;
      const defectModify =  (this.defectCtrl.value && this.mpuToUpdate.defect!=undefined && this.defectCtrl.value.code != this.mpuToUpdate.defect.code) || (!this.defectCtrl.value && this.mpuToUpdate.defect!=undefined)
      || (this.defectCtrl.value && this.mpuToUpdate.defect==undefined);
      if (this.fromAddLine){
        // Modify task from Suivi des travaux
        //Seuls les champs défaillance, nombre et statut sont modifiables, les autres sont en recture seule (Il n’est pas possible de modifier l’organe ou le travail à cette étape car certains CAM saisis peuvent y être rattachés)
        this.statusModify = this.mpuToUpdate.state.code != this.statusCtrl.value.code;
        this.modifyTaskPk = defectModify;
       
        return (this.amountModify || defectModify || this.statusModify);
      }else{
        // Modify task from 'Preparation des travaux'
        const jobModify = !this.jobCtrl.value || this.mpuToUpdate.job.code != this.jobCtrl.value.code;
        const componentModify = !this.componentCtrl.value || this.mpuToUpdate.component.code != this.componentCtrl.value.code;
        const componentGrpModify =!this.componentGrpCtrl.value || this.mpuToUpdate.component.group.code != this.componentGrpCtrl.value.code;
        this.modifyTaskPk = jobModify || defectModify || componentModify || componentGrpModify;
        return ( this.amountModify || this.modifyTaskPk);
      }
    }else{
      return false;
    }
  }

  /**
   * Methode to show Unsaved Changes Dialog
   */
  existTaskPopUpDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('wagon-update.task.exist_task.title');
    const message = this.translateService.instant('wagon-update.task.exist_task.msg');

    dialogConfig.data = {
      namePopUp: 'exist_task',
      titlePopUp: title,
      msgPopUp: message
    };

    this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
  }

  /**
   * Methode to show Unsaved Changes Dialog
   */
  unsavedChangesPopUpDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '18.75rem';
    dialogConfig.width = '34.375rem';
    const title = this.translateService.instant('wagon-update.task.unsaved_changes.title');
    const message = this.translateService.instant('wagon-update.task.unsaved_changes.msg');

    dialogConfig.data = {
      namePopUp: 'unsaved_changes_task',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {        
        this.onTaskAdded.emit(false);
        if (this.data && this.data.mpu) {
          this.dialogRef.close();
        }        
      }
    });
  }

  /**
   * Compare two elements
   * @param o1 
   * @param o2 
   * @returns 
   */
  compareWith = (o1: any, o2: any): boolean =>  {
    if (o1 && o2){
      return o1.code === o2.code;
    }else{
      return false;
    }
   
  }

  /**
   * Methode to submit the form
   */
  onSubmit(): void {
    this.submitted = true;
  }

}
