import { Expertise } from './expertise';
import { Imputation } from './imputation';
import { InterventionCause } from './intervention-cause';
import { InterventionReason } from './intervention-reason';
import { MaintenanceOperation } from './maintenance-operation';
import { UpdateType } from './update-type';
import { Workshop } from './workshop';

export class ReducedUpdateInput {

  reducedUpdateCheckbox: boolean;

  workshop: Workshop;

  updateType: UpdateType;

  maintenanceOperation: MaintenanceOperation;

  pattern: InterventionReason;

  cause: InterventionCause;

  imputation: Imputation;

  entryDate: Date;

  releaseDate: Date;

  observations: string;

  option1: string;

  option2: string;

  dealNumber: number;

  referenceWagon: number;

  expertise: Expertise;

  expertiseChecked: boolean;
}
