/* tslint:disable:max-line-length */
import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskModule } from 'ngx-mask';
import { TableFiltersComponent } from '../core/components/table-filters/table-filters.component';
import { SelectAxleDialogComponent } from '../modules/consultation/axle/axle-detail/select-axle-dialog/select-axle-dialog.component';
import { ExMexDialogComponent } from '../modules/consultation/wagon/wagon-detail/ex-mex-dialog/ex-mex-dialog.component';
import { HolderSheetDialogComponent } from '../modules/consultation/wagon/wagon-detail/holder-sheet-dialog/holder-sheet-dialog.component';
import { WagActionBarCustomComponent } from '../modules/consultation/wagon/wagon-detail/wag-action-bar-custom/wag-action-bar-custom.component';
import { AxleToggleComponent } from './components/axle-toggle/axle-toggle.component';
import { DialogCancelConfirmComponent } from './components/dialog-cancel-confirm/dialog-cancel-confirm.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { ConfirmationAlertComponent } from './components/end-of-work-alert/confirmation-alert.component';
import { RedirectAlertComponent } from './components/end-of-work-alert/redirect-alert.component';
import { AddTaskExpertiseDialogComponent } from './components/expertise/add-task-expertise-dialog/add-task-expertise-dialog.component';
import { ExpertiseTasksComponent } from './components/expertise/expertise-tasks/expertise-tasks.component';
import { InitExpertiseComponent } from './components/expertise/init-expertise/init-expertise.component';
import { ModifyExpertiseDialogComponent } from './components/expertise/modify-expertise-dialog/modify-expertise-dialog.component';
import { FilePreviewOverlayComponent } from './components/file-preview-overlay/file-preview-overlay.component';
import { FormInputComponent } from './components/form-input/form-input.component';
import { InputDirective } from './components/form-input/input.directive';
import { MaxDateLengthDirective } from './components/form-input/max-date-length.directive';
import { FormSelectComponent } from './components/form-select/form-select.component';
import { SelectDirective } from './components/form-select/select.directive';
import { NotExistWagonAlertComponent } from './components/not-exist-wagon-alert/not-exist-wagon-alert.component';
import { AresDialogComponent } from './components/open-wagon/ares-dialog/ares-dialog.component';
import { CamsFaultsTableComponent } from './components/open-wagon/cams-faults-table/cams-faults-table.component';
import { CertificationComponent } from './components/open-wagon/certification/certification.component';
import { ModifyWagonDialogComponent } from './components/open-wagon/modify-wagon-dialog/modify-wagon-dialog.component';
import { AddLineComponent } from './components/open-wagon/monitoring-works/add-task/add-line.component';
import { CamLinkedTaskDialogComponent } from './components/open-wagon/monitoring-works/cam-linked-task-dialog/cam-linked-task-dialog.component';
import { ManagementExpectationsComponent } from './components/open-wagon/monitoring-works/management-expectations/management-expectations.component';
import { MonitoringWorksTaskComponent } from './components/open-wagon/monitoring-works/monitoring-works-task/monitoring-works-task.component';
import { MonitoringWorksComponent } from './components/open-wagon/monitoring-works/monitoring-works.component';
import { FamilyRestrictionsComponent } from './components/open-wagon/monitoring-works/tabs/family-restrictions/family-restrictions.component';
import { MaintenanceCartridgeComponent } from './components/open-wagon/monitoring-works/tabs/maintenance-cartridge/maintenance-cartridge.component';
import { TabsComponent } from './components/open-wagon/monitoring-works/tabs/tabs.component';
import { TareLoadsComponent } from './components/open-wagon/monitoring-works/tabs/tare-loads/tare-loads.component';
import { OpenWagonBearingComponent } from './components/open-wagon/monitoring-works/tabs/technical-data/open-wagon-bearing/open-wagon-bearing.component';
import { OpenWagonBrakeComponent } from './components/open-wagon/monitoring-works/tabs/technical-data/open-wagon-breake/open-wagon-brake.component';
import { OpenWagonBumpTractionComponent } from './components/open-wagon/monitoring-works/tabs/technical-data/open-wagon-bump-traction/open-wagon-bump-traction.component';
import { OpenWagonFrameSuspensionComponent } from './components/open-wagon/monitoring-works/tabs/technical-data/open-wagon-frame-suspension/open-wagon-frame-suspension.component';
import { OpenWagonParticularitiesComponent } from './components/open-wagon/monitoring-works/tabs/technical-data/open-wagon-particularities/open-wagon-particularities.component';
import { OpenWagonSuperstructureComponent } from './components/open-wagon/monitoring-works/tabs/technical-data/open-wagon-superstructure/open-wagon-superstructure.component';
import { TechnicalDataComponent } from './components/open-wagon/monitoring-works/tabs/technical-data/technical-data.component';
import { OpenWagonDialogComponent } from './components/open-wagon/open-wagon-dialog/open-wagon-dialog.component';
import { MaintenancePlanTableComponent } from './components/open-wagon/plan-maintenance-table/maintenance-plan-table.component';
import { CamsFaultComponent } from './components/open-wagon/preparation-work/cams-faults/cams-fault.component';
import { ReducedUpdateComponent } from './components/open-wagon/reduced-update/reduced-update.component';
import { TaskDialogComponent } from './components/open-wagon/task-dialog/task-dialog.component';
import { TemplatePopupDialogComponent } from './components/template-popup-dialog/template-popup-dialog.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { WagonToggleComponent } from './components/wagon-toggle/wagon-toggle.component';
import { WagonComponent } from './components/wagon/wagon.component';
import { AutoControlDirective } from './directive/auto-control.directive';
import { MaxValDirective } from './directive/max-val.directive';
import { MinValDirective } from './directive/min-val.directive';
import { MaterialModule } from './modules/material/material.module';
import { UtilityService } from './services/Utility/utility.service';
import { InputFile2Component } from '../modules/update/vpi/input-file2/input-file2.component';
/* tslint:enable:max-line-length */

registerLocaleData(localeFr, 'fr');


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    MaterialModule,
    TranslateModule.forChild(),
    NgxMaskModule.forChild(),
    ReactiveFormsModule
  ],
  exports: [
    MaterialModule,
    InputDirective,
    SelectDirective,
    MaxDateLengthDirective,
    FormSelectComponent,
    FormInputComponent,
    WagonComponent,
    WagonToggleComponent,
    AxleToggleComponent,
    FilePreviewOverlayComponent,
    TableFiltersComponent,
    AutoControlDirective,
    DialogComponent,
    SelectAxleDialogComponent,
    MinValDirective,
    MaxValDirective,
    OpenWagonDialogComponent,
    TemplatePopupDialogComponent,
    NotExistWagonAlertComponent,
    CamsFaultComponent,
    WagActionBarCustomComponent,
    MonitoringWorksComponent,
    MaintenancePlanTableComponent,
    CamsFaultsTableComponent,
    TabsComponent,
    MaintenanceCartridgeComponent,
    TareLoadsComponent,
    FamilyRestrictionsComponent,
    TechnicalDataComponent,
    OpenWagonSuperstructureComponent,
    OpenWagonParticularitiesComponent,
    OpenWagonBearingComponent,
    OpenWagonBrakeComponent,
    OpenWagonBumpTractionComponent,
    OpenWagonFrameSuspensionComponent,
    TaskDialogComponent,
    ManagementExpectationsComponent,
    MonitoringWorksTaskComponent,
    CamLinkedTaskDialogComponent,
    AddLineComponent,
    ExMexDialogComponent,
    CertificationComponent,
    RedirectAlertComponent,
    ConfirmationAlertComponent,
    DialogCancelConfirmComponent,
    ReducedUpdateComponent,
    ModifyWagonDialogComponent,
    HolderSheetDialogComponent,
    InitExpertiseComponent,
    ExpertiseTasksComponent,
    ModifyExpertiseDialogComponent,
    AddTaskExpertiseDialogComponent,
    InputFile2Component
  ],
  declarations: [
    InputDirective,
    SelectDirective,
    MaxDateLengthDirective,
    FormSelectComponent,
    FormInputComponent,
    WagonComponent,
    WagonToggleComponent,
    AxleToggleComponent,
    FilePreviewOverlayComponent,
    TableFiltersComponent,
    AutoControlDirective,
    DialogComponent,
    SelectAxleDialogComponent,
    MinValDirective,
    MaxValDirective,
    OpenWagonDialogComponent,
    TemplatePopupDialogComponent,
    NotExistWagonAlertComponent,
    CamsFaultComponent,
    WagActionBarCustomComponent,
    MonitoringWorksComponent,
    CamsFaultsTableComponent,
    MaintenancePlanTableComponent,
    TabsComponent,
    MaintenanceCartridgeComponent,
    TareLoadsComponent,
    FamilyRestrictionsComponent,
    TechnicalDataComponent,
    OpenWagonSuperstructureComponent,
    OpenWagonParticularitiesComponent,
    OpenWagonBearingComponent,
    OpenWagonBrakeComponent,
    OpenWagonBumpTractionComponent,
    OpenWagonFrameSuspensionComponent,
    TaskDialogComponent,
    ManagementExpectationsComponent,
    MonitoringWorksTaskComponent,
    CamLinkedTaskDialogComponent,
    AddLineComponent,
    ToggleSwitchComponent,
    CertificationComponent,
    AresDialogComponent,
    ExMexDialogComponent,
    RedirectAlertComponent,
    ConfirmationAlertComponent,
    DialogCancelConfirmComponent,
    ReducedUpdateComponent,
    ModifyWagonDialogComponent,
    HolderSheetDialogComponent,
    InitExpertiseComponent,
    ExpertiseTasksComponent,
    ModifyExpertiseDialogComponent,
    AddTaskExpertiseDialogComponent,
    InputFile2Component
  ],
  entryComponents: [
    ExMexDialogComponent,
    AresDialogComponent
  ],
  providers: [
    UtilityService
  ]
})
export class SharedModule {
}
