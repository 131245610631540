import { RefWork } from './ref-work';
/** API model:Table: R_EXP_TCH_WREF  */
export class ExpertiseTaskRefWork {
  /** refWork.code	ETCHWREF_WREF_CODE_PFK VARCHAR2(3 BYTE) */
  refWork: RefWork;
  /** ETCHWREF_NBRE NUMBER(3,0) */
  amount: number;
  /** ETCHWREF_TPS_UNIT NUMBER(5,2) */
  unitTime: number;
  /** ETCHWREF_TPS_EXP NUMBER(5,2) */
  expertiseTime: number;
  /** ETCHWREF_TPS_REEL NUMBER(5,2) */
  realTime: number;
}
