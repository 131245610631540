import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TranslateService } from '@ngx-translate/core';
import { Moment } from "moment";
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TemplatePopupDialogComponent } from 'src/app/shared/components/template-popup-dialog/template-popup-dialog.component';
import { FrameLink } from 'src/app/shared/models/frame-link';
import { TechSpecReferences } from 'src/app/shared/models/tech-spec-references';
import { WagonSuspension } from 'src/app/shared/models/wagon-suspension';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { Suspension } from "../../../../../../models/suspension";
import { TechSpecSuspensionChassis } from "../../../../../../models/tech-spec-suspension-chassis";
import { WagonTechnicalData } from "../../../../../../models/wagon-technical-data";
import { MonitoringWorkService } from "../../../../../../services/monitoring-work/monitoring-work.service";
import { WagonService } from 'src/app/shared/services/wagon/wagon.service';

@Component({
  selector: 'app-open-wagon-frame-suspension',
  templateUrl: './open-wagon-frame-suspension.component.html',
  styleUrls: ['./open-wagon-frame-suspension.component.scss']
})
export class OpenWagonFrameSuspensionComponent implements OnInit, OnDestroy {

  @Input() public wagonTechnicalData: WagonTechnicalData;
  @Input() public wagonTechnicalDataWO: WagonTechnicalData;
  @Input() public techSpecReferences: TechSpecReferences;
  @Output() modifiedDataEvent = new EventEmitter();
  @Output() wagonTechnicalDataWOChange = new EventEmitter<WagonTechnicalData>();
  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  /** langage du navigateur */
  lang: string;
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  frameSuspensionForm: FormGroup;
  suspensionsCtrl: FormArray;
  dateCtrl: FormControl;
  constructionWorkshopCtrl: FormControl;
  elementAmountCtrl: FormControl;
  frameLinkCtrl: FormControl;
  overallLengthCtrl: FormControl;
  frameLengthCtrl: FormControl;
  wheelBaseCtrl: FormControl;
  axleSpacingCtrl: FormControl;
  cantileveredCtrl: FormControl;
  curvatureRadiusCtrl: FormControl;
  humpRadiusCtrl: FormControl;
  displayedColumns: string[] = ['type', 'number', 'nature', 'action'];
  suspensionsDS: MatTableDataSource<FormGroup>;
  /** Error list*/
  errorList: string[];
  /** submitted property */
  submitted: boolean;
  /** message error */
  messageError: string;
  /**Init SuspensionList */
  initSuspensionList: WagonSuspension[];
  filteredSuspensionList: Suspension[];
  filteredFrameLinkList: FrameLink[];
  dateToday = new Date();

  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];

  techSpecSupChassis: TechSpecSuspensionChassis;
  modifSub: Subscription;
  alert: boolean;
  constructor(private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private wagonService: WagonService,
    private monitoringWorkService: MonitoringWorkService) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.dateCtrl = this.formBuilder.control(new Date());
    this.constructionWorkshopCtrl = this.formBuilder.control(null, Validators.required);
    this.elementAmountCtrl = this.formBuilder.control(null, Validators.required);
    this.frameLinkCtrl = this.formBuilder.control(null, Validators.required);
    this.suspensionsCtrl = this.formBuilder.array([]);
    this.overallLengthCtrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,2}(\.\d{1,2})?$/)]);
    this.frameLengthCtrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,2}(\.\d{1,2})?$/)]);
    this.wheelBaseCtrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,2}(\.\d{1,2})?$/)]);
    this.axleSpacingCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,2}(\.\d{1,2})?$/));
    this.cantileveredCtrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,2}(\.\d{1,2})?$/)]);
    this.curvatureRadiusCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,3}$/));
    this.humpRadiusCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,5}$/));

    this.frameSuspensionForm = this.formBuilder.group({
      date: this.dateCtrl,
      constructionWorkshop: this.constructionWorkshopCtrl,
      elementAmount: this.elementAmountCtrl,
      frameLink: this.frameLinkCtrl,
      suspensions: this.suspensionsCtrl,
      overallLength: this.overallLengthCtrl,
      frameLength: this.frameLengthCtrl,
      wheelBase: this.wheelBaseCtrl,
      axleSpacing: this.axleSpacingCtrl,
      cantilevered: this.cantileveredCtrl,
      curvatureRadius: this.curvatureRadiusCtrl,
      humpRadius: this.humpRadiusCtrl
    });

    
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.modifSub = this.frameSuspensionForm.valueChanges.subscribe(
      _ => this.modifiedDataEvent.emit(this.frameSuspensionForm.dirty));
    this.loadInitDatas();
  }


  get suspensions(): FormArray {
    return this.frameSuspensionForm.get('suspensions') as FormArray;
  }

  /**
   * convenience getter for easy access to form fields
   */
   get f() { return this.frameSuspensionForm.controls; }

  /**
   * Reset les donnes
   */
  resetDatas(): void {
    this.messageError = '';
    this.errorList = [];
    this.submitted = false;
    this.suspensions.clear();
    this.dateCtrl.setValue(this.techSpecSupChassis.frameConstruction);
    this.constructionWorkshopCtrl.setValue(this.techSpecSupChassis.frameConstructionWorkshop?this.techSpecSupChassis.frameConstructionWorkshop.code:null);
    this.elementAmountCtrl.setValue(this.techSpecSupChassis.elementAmount);

    this.frameLinkCtrl.setValue(this.techSpecSupChassis.frameLink?this.techSpecSupChassis.frameLink.code:null);

    this.suspensionsCtrl.clear();
    const suspensionList = this.initSuspensionList;
    suspensionList.forEach(elem => this.suspensionsCtrl.push(this.addFormGroup(true, elem.amount, elem.suspension)));
    this.suspensionsCtrl.push(this.addFormGroup(false));
    this.suspensionsDS = new MatTableDataSource<FormGroup>(this.suspensionsCtrl.controls as FormGroup[]);

    this.overallLengthCtrl.setValue(this.techSpecSupChassis.overallLength);
    this.frameLengthCtrl.setValue(this.techSpecSupChassis.frameLength);
    this.wheelBaseCtrl.setValue(this.techSpecSupChassis.wheelbase);
    this.axleSpacingCtrl.setValue(this.techSpecSupChassis.contiguousAxlesSpacing);
    this.cantileveredCtrl.setValue(this.techSpecSupChassis.cantilevered);
    this.curvatureRadiusCtrl.setValue(this.techSpecSupChassis.curvatureRadius);
    this.humpRadiusCtrl.setValue(this.techSpecSupChassis.humpRadius);

    this.frameSuspensionForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }

  initSuspensionsLists(): void {
    this.initSuspensionList = this.techSpecSupChassis.wagonTechnicalDataDetail.frameSuspension.suspensionList;
    if ( this.initSuspensionList) {
      this.initSuspensionList.forEach(s => s['code'] = s.suspension.code);
    }
  }

  /**
   * Methode to submit the form
   */
  async onSubmit(alert?: boolean): Promise<void> {
    this.alert = alert === undefined ? true : alert;
    this.submitted = true;
    await this.checkModifyInputControl();
  }

  /**
   * Update Frame Suspension Form
   */
  async updateFrameSuspension(): Promise<void> {
    this.setUpdateValues();
    this.loadingSubject.next(true);
    //Mise à jour de la base de données
    const techSpecSupChassis = (await this.monitoringWorkService.updateTechSpecSuspensionChassis(this.techSpecSupChassis).pipe(first()).toPromise()).data;
    this.wagonTechnicalDataWO = (await this.wagonService.getWagonTechnicalData(this.wagonTechnicalDataWO.mex, 'WO').pipe(first()).toPromise()).data;
    this.wagonTechnicalDataWOChange.emit(this.wagonTechnicalDataWO);
    if (techSpecSupChassis) {
      this.submitted = false;
      this.loadingSubject.next(false);
      this.updateDone();
      this.frameSuspensionForm.markAsPristine();
      this.modifiedDataEvent.emit(false);
    }
  }

  setUpdateValues(): void {
    this.setSuspensionsLists();
    this.techSpecSupChassis.frameConstructionWorkshop = this.techSpecSupChassis.workshops.filter(w => w.code === this.constructionWorkshopCtrl.value)[0];
    this.techSpecSupChassis.frameConstruction = this.dateCtrl.value;
    this.techSpecSupChassis.elementAmount = this.elementAmountCtrl.value;
    if (this.frameLinkCtrl.value) {
      this.techSpecSupChassis.frameLink = this.techSpecReferences.frameLinks.filter(f => f.code === this.frameLinkCtrl.value)[0];
    }
    this.techSpecSupChassis.overallLength = this.overallLengthCtrl.value;
    this.techSpecSupChassis.frameLength = this.frameLengthCtrl.value;
    this.techSpecSupChassis.wheelbase = this.wheelBaseCtrl.value;
    this.techSpecSupChassis.contiguousAxlesSpacing = this.axleSpacingCtrl.value;
    this.techSpecSupChassis.cantilevered = this.cantileveredCtrl.value;
    this.techSpecSupChassis.curvatureRadius = this.curvatureRadiusCtrl.value;
    this.techSpecSupChassis.humpRadius = this.humpRadiusCtrl.value;
    this.techSpecSupChassis.wagonId = this.wagonTechnicalData.wagon.id;
    /** ‘1’ si modification d’un moins un des éléments suivants:
    *  -	Date de construction
       -	Etablissement de construction
       -	Longueur hors tout
       -	Longueur du châssis
       -	Empattement
       -	Distance entre essieux contigus
       -	Rayon minimal d’inscription en courbe
       -	Rayon vertical minimum des bosses de triage
       -	Porte à faux
    */
    this.techSpecSupChassis.modifiedGeoChasis = this.dateCtrl.touched || this.constructionWorkshopCtrl.touched || this.overallLengthCtrl.touched || this.frameLengthCtrl.touched || this.wheelBaseCtrl.touched || this.axleSpacingCtrl.touched || this.cantileveredCtrl.touched || this.curvatureRadiusCtrl.touched || this.humpRadiusCtrl.touched;

    /**
     * ‘1’ si modification d’au moins un des élements suivants :
        -	Ressort de suspension
        -	Liaison avec le châssis
     */
    this.techSpecSupChassis.modifiedGeoRoul = this.frameLinkCtrl.touched || this.suspensionsCtrl.touched;

  }

  /**
  * Le message se ferme quand 5 seconds sont pasé
  */
  updateDone(): void {
    this.ngOnInit();
    if (this.alert) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      const title = this.translateService.instant('wagon-update.task.update-done.msg');
      const message = this.translateService.instant('wagon-update.task.update-done.msg');
      const timeout = UtilityService.UPDATE_DONE_TIMEOUT;
      dialogConfig.data = {
        namePopUp: 'update-done-alert',
        titlePopUp: title,
        msgPopUp: message
      };
      const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
      dialogRef.afterOpened().subscribe(_ => {
        setTimeout(() => {
          dialogRef.close();
          this.dialog.closeAll();
          }, timeout)
      });
    }

  }

  /**
   * Get the date of DatePicker control
   * @param ctrl
   */
  getDateFromCtrl(ctrl: AbstractControl): string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : new Date(ctrl.value);
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

  addFormGroup(add: boolean, amount?: number, type?: Suspension): FormGroup {
    return this.formBuilder.group({
      isAdded: this.formBuilder.control(add, Validators.required),
      amount: this.formBuilder.control(amount, Validators.required),
      nature: this.formBuilder.control(type?type.code:null, Validators.required)
    });
  }

  addRow(array: FormArray, index: number, add: boolean): void {
    if (!this.checkIfExistControlError(index)) {
      array.controls[index].value.isAdded = !add;
      array.push(this.addFormGroup(add));
      this.tables.forEach(table => table.renderRows());
    }
  }

  removeRow(array: FormArray, index: number): void {
    array.removeAt(index);
    this.tables.forEach(table => table.renderRows());
    array.markAsDirty();
    this.modifiedDataEvent.emit(true);
  }

  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
    this.subscriptionRefs.push(
      this.monitoringWorkService.loadTechSpecSuspensionChassisPageDatas(this.wagonTechnicalData.wagon.id)
        .subscribe(update => {
          this.techSpecSupChassis = update.data;
          this.filteredSuspensionList = this.techSpecReferences.suspensions;
          this.filteredFrameLinkList = this.techSpecReferences.frameLinks;
          this.initSuspensionsLists();
          this.resetDatas();
          this.loadingSubject.next(false);
        })
    );
  }

  /**
  * Methode to check Input controls for changed numbers
  */
  async checkModifyInputControl(): Promise<boolean> {
    let message = null;
    this.messageError = '';
    this.errorList = [];
    let errors = false;

    // Contrôle 1
    if (!this.elementAmountCtrl.value) {
      message = this.translateService.instant('wagon.technical.frame_suspension.RI_CONTROL_1');
      this.elementAmountCtrl.setErrors(Validators.required);
      this.errorList.push(message);
      this.messageError = message;
      errors = true;
    }
    //RI_MAJ_003_4_5_9
    if (this.errorList.length === 0 && !this.overallLengthCtrl.value) {
      message = this.translateService.instant('wagon.technical.frame_suspension.RI_MAJ_003_4_5_9');
      this.overallLengthCtrl.setErrors(Validators.required);
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        this.messageError = message;
        errors = true;
      }
    }
    //RI_MAJ_003_4_5_10
    if (this.errorList.length === 0 && !this.frameLengthCtrl.value) {
      message = this.translateService.instant('wagon.technical.frame_suspension.RI_MAJ_003_4_5_10');
      this.frameLengthCtrl.setErrors(Validators.required);
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        this.messageError = message;
        errors = true;
      }
    }
    //RI_MAJ_003_4_5_11
    if (this.errorList.length === 0 && !this.wheelBaseCtrl.value) {
      message = this.translateService.instant('wagon.technical.frame_suspension.RI_MAJ_003_4_5_11');
      this.wheelBaseCtrl.setErrors(Validators.required);
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        this.messageError = message;
        errors = true;
      }
    }
    //RG_MAJ_003_4_5_21 
    if (this.errorList.length === 0 && this.techSpecSupChassis.wagonLogique && this.techSpecSupChassis.wagonLogique.operatingGroup && this.techSpecSupChassis.wagonLogique.operatingGroup.code === '4') {
      if (!this.cantileveredCtrl.valid) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_21');
        this.cantileveredCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }
    //RI_MAJ_003_4_5_1
    if (this.errorList.length === 0 && this.dateCtrl.value && !this.constructionWorkshopCtrl.value) {
      message = this.translateService.instant('wagon.technical.frame_suspension.RI_MAJ_003_4_5_1');
      this.constructionWorkshopCtrl.setErrors(Validators.required);
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        this.messageError = message;
        errors = true;
      }
    }

    //RG_MAJ_003_4_5_14
    if (this.errorList.length === 0 && this.techSpecSupChassis.trancheTechnicalData && Number(this.techSpecSupChassis.trancheTechnicalData.elementCount) === 0) {
      if (this.elementAmountCtrl.value && (Number(this.elementAmountCtrl.value) < 1 || Number(this.elementAmountCtrl.value) > 99)) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_14');
        this.elementAmountCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }

    //RG_MAJ_003_4_5_15
    if (this.errorList.length === 0 && this.techSpecSupChassis.trancheTechnicalData && Number(this.techSpecSupChassis.trancheTechnicalData.elementCount) > 0) {
      if (this.elementAmountCtrl.value && (Number(this.elementAmountCtrl.value) !== Number(this.techSpecSupChassis.trancheTechnicalData.elementCount))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_15', { tranche: this.techSpecSupChassis.trancheTechnicalData.elementCount });
        this.elementAmountCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }


    if (this.errorList.length === 0 && this.techSpecSupChassis.trancheTechnicalData && Number(this.techSpecSupChassis.trancheTechnicalData.trpExceptionControle) === 0) {
      //RG_MAJ_003_4_5_1 
      if (this.overallLengthCtrl.value && ((Number(this.overallLengthCtrl.value) <= 4.90) || (Number(this.overallLengthCtrl.value) >= 40))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_1');
        this.overallLengthCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }

      //RG_MAJ_003_4_5_4
      if (this.frameLengthCtrl.value && ((Number(this.frameLengthCtrl.value) <= 3.79) || (Number(this.frameLengthCtrl.value) >= 39))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_4');
        this.frameLengthCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }

      //Contrôle 16 : RG_MAJ_003_4_5_7_1
      if (this.errorList.length === 0 && this.techSpecSupChassis.wagonLogique && this.techSpecSupChassis.wagonLogique.operatingGroup &&
        (this.techSpecSupChassis.wagonLogique.operatingGroup.code === '1' || this.techSpecSupChassis.wagonLogique.operatingGroup.code === '3'
          || this.techSpecSupChassis.wagonLogique.operatingGroup.code === '4' || this.techSpecSupChassis.wagonLogique.operatingGroup.code === '5')) {
        if (this.wheelBaseCtrl.value && ((Number(this.wheelBaseCtrl.value) <= 2.99) || (Number(this.wheelBaseCtrl.value) > 34.99))) {
          message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_7_1');
          this.wheelBaseCtrl.setErrors(Validators.required);
          if (!this.errorList || this.errorList.indexOf(message) === -1) {
            this.errorList.push(message);
            this.messageError = message;
            errors = true;
          }
        }
      }

      //Contrôle 17 : RG_MAJ_003_4_5_7_2
      if (this.errorList.length === 0 && this.techSpecSupChassis.wagonLogique && this.techSpecSupChassis.wagonLogique.operatingGroup &&
        (this.techSpecSupChassis.wagonLogique.operatingGroup.code === '2')) {
        if (this.wheelBaseCtrl.value && ((Number(this.wheelBaseCtrl.value) <= 2.49) || (Number(this.wheelBaseCtrl.value) > 34.99))) {
          message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_7_2');
          this.wheelBaseCtrl.setErrors(Validators.required);
          if (!this.errorList || this.errorList.indexOf(message) === -1) {
            this.errorList.push(message);
            this.messageError = message;
            errors = true;
          }
        }
      }
    }

    //RG_MAJ_003_4_5_2 
    if (this.errorList.length === 0 && this.techSpecSupChassis.trancheTechnicalData && Number(this.techSpecSupChassis.trancheTechnicalData.trpExceptionControle) === 1) {
      if (this.overallLengthCtrl.value && ((Number(this.overallLengthCtrl.value) <= 0.0) || (Number(this.overallLengthCtrl.value) > 99.90))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_2');
        this.overallLengthCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }

      //RG_MAJ_003_4_5_5
      if (this.frameLengthCtrl.value && ((Number(this.frameLengthCtrl.value) <= 0.0) || (Number(this.frameLengthCtrl.value) > 99.90))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_5');
        this.frameLengthCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }

      //Controle 18
      if (this.wheelBaseCtrl.value && ((Number(this.wheelBaseCtrl.value) <= 0.0) || (Number(this.wheelBaseCtrl.value) > 99.99))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_7_3');
        this.wheelBaseCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }

    //RG_MAJ_003_4_5_3 
    if (this.errorList.length === 0) {
      if (this.overallLengthCtrl.value && this.overallLengthCtrl.valid && this.frameLengthCtrl.value && this.frameLengthCtrl.valid &&
        (Number(this.overallLengthCtrl.value) <= Number(this.frameLengthCtrl.value))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_3');
        this.overallLengthCtrl.setErrors(Validators.required);
        this.frameLengthCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }
    //RG_MAJ_003_4_5_11 
    if (this.errorList.length === 0) {
      if (this.overallLengthCtrl.value && this.overallLengthCtrl.valid && this.frameLengthCtrl.value && this.frameLengthCtrl.valid) {
        const dif = (Number(this.overallLengthCtrl.value) - Number(this.frameLengthCtrl.value));
        if (dif < 0.1 || dif > 1.5) {
          message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_11');
          this.overallLengthCtrl.setErrors(Validators.required);
          this.frameLengthCtrl.setErrors(Validators.required);
          if (!this.errorList || this.errorList.indexOf(message) === -1) {
            this.errorList.push(message);
            this.messageError = message;
            errors = true;
          }
        }
      }
    }

    //RG_MAJ_003_4_5_6 
    if (this.errorList.length === 0) {
      if (this.frameLengthCtrl.value && this.wheelBaseCtrl.value &&
        (Number(this.frameLengthCtrl.value) <= Number(this.wheelBaseCtrl.value))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_6');
        this.frameLengthCtrl.setErrors(Validators.required);
        this.wheelBaseCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }

    //Controle 19:RG_MAJ_003_4_5_9 
    if (this.errorList.length === 0) {
      if (this.axleSpacingCtrl.value && this.techSpecSupChassis.amountAxles === 2 &&
        (Number(this.axleSpacingCtrl.value) !== Number(this.wheelBaseCtrl.value))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_9');
        this.axleSpacingCtrl.setErrors(Validators.required);
        this.wheelBaseCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }

    //Controle 20:RG_MAJ_003_4_5_9_1 
    if (this.errorList.length === 0) {
      if (this.axleSpacingCtrl.value && this.techSpecSupChassis.amountAxles !== 2 &&
        (Number(this.axleSpacingCtrl.value) > Number(this.wheelBaseCtrl.value))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_9_1');
        this.axleSpacingCtrl.setErrors(Validators.required);
        this.wheelBaseCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }
    //Contrôle 21 : RG_MAJ_003_4_5_10
    if (this.errorList.length === 0) {
      if (this.cantileveredCtrl.value && ((Number(this.cantileveredCtrl.value) <= 0.39) || (Number(this.cantileveredCtrl.value) >= 5.00))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_10');
        this.cantileveredCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }
    //Contrôle 22 : RG_MAJ_003_4_5_12
    if (this.errorList.length === 0) {
      if (this.cantileveredCtrl.value && this.cantileveredCtrl.valid && this.frameLengthCtrl.value && this.frameLengthCtrl.valid) {
        //– l’empattement – le porte à faux – 0,4 doit être inférieur ou égal à 4,5
        const amount = Number(this.wheelBaseCtrl.value) + Number(this.cantileveredCtrl.value) + 0.4;
        const limitDiff = Number(this.frameLengthCtrl.value) - amount;
        if ((Number(this.frameLengthCtrl.value) < amount) || (limitDiff > 4.5)) {
          message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_12');
          this.frameLengthCtrl.setErrors(Validators.required);
          this.wheelBaseCtrl.setErrors(Validators.required);
          this.cantileveredCtrl.setErrors(Validators.required);
          if (!this.errorList || this.errorList.indexOf(message) === -1) {
            this.errorList.push(message);
            this.messageError = message;
            errors = true;
          }
        }
      }
    }
    /*Contrôle 23 : RG_MAJ_003_4_5_16*/
    if (this.errorList.length === 0) {
      if (Number(this.techSpecSupChassis.trancheTechnicalData.bogieCount) === 0 && this.suspensionsCtrl.controls.length <= 1) { // on ne doit pas compter la ligne vide
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_16');
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }
    /** Controle 24 */
    if (this.errorList.length === 0) {
      this.checkAmountControlIsValid();
    }

    /** Controle 25 */
    if (this.errorList.length === 0) {
      this.checkNatureControlDuplicated();
    }

    /** Controle 26 */
    if (this.errorList.length === 0) {
      if (Number(this.techSpecSupChassis.trancheTechnicalData.bogieCount) === 0 && (!this.frameLinkCtrl.value || this.frameLinkCtrl.value === '')) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_17');
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          this.messageError = message;
          errors = true;
        }
      }
    }

    /** Controle 27 */
    if (this.errorList.length === 0) {
      const liaison = this.frameLinkCtrl && this.frameLinkCtrl.value ? this.frameLinkCtrl.value : null;
      const suspensionList: string[] = [];
      for (const index in this.suspensionsDS.data) {

        const natureCtrl = this.suspensionsDS.data[index].get('nature');
        if (Number(index) < (this.suspensionsDS.data.length - 1)) {
          suspensionList.push(natureCtrl.value);
        }
      }

      if (liaison != null && suspensionList.length > 0 && this.techSpecSupChassis.showFrameLink) {
        const errorMessage = await this.monitoringWorkService.checkCompatibilitySuspensionChassis(liaison, suspensionList).pipe(first()).toPromise();
        if (errorMessage && errorMessage.data && errorMessage.data.message) {
          if ('RG_MAJ_003_4_5_17_1' === errorMessage.data.message) {
            this.messageError = this.translateService.instant('wagon.technical.frame_suspension.' + errorMessage.data.message);
            this.frameLinkCtrl.setErrors(Validators.required);
            this.errorList.push(this.messageError);
            errors = true;
            return errors;
          }
        } else {
          /** Controle 28 */
          if (this.errorList.length === 0) {
            if (Number(this.techSpecSupChassis.trancheTechnicalData.bogieCount) === 0) {
              errors = this.checkAmountControlError(this.suspensionsCtrl.controls.length - 2);// on ne prend pas la dernière ligne et -1 plus d'index
              if (errors) {
                return errors;
              }
            }
          }
          /** Controle 29 et 30 */
          if (this.errorList.length === 0) {
            if (this.techSpecSupChassis.showSuspension === true && Number(this.techSpecSupChassis.trancheTechnicalData.bogieCount) !== 0) {
              //Controle 29 :RG_MAJ_003_4_5_19 
              const nbSuspensions = this.suspensionsCtrl.controls.length;
              if ((nbSuspensions == 1 && this.suspensions.controls[0].value.isAdded) || nbSuspensions > 1) {
                message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_19');
                if (!this.errorList || this.errorList.indexOf(message) === -1) {
                  this.errorList.push(message);
                  this.messageError = message;
                  errors = true;
                  return errors;
                }
              }
            }
            //Controle 30 :RG_MAJ_003_4_5_20
            if (this.errorList.length === 0) {
              if (this.techSpecSupChassis.showFrameLink === true && Number(this.techSpecSupChassis.trancheTechnicalData.bogieCount) !== 0) {
                if (this.frameLinkCtrl.value && this.frameLinkCtrl.value !== '') {
                  message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_20');
                  if (!this.errorList || this.errorList.indexOf(message) === -1) {
                    this.errorList.push(message);
                    this.messageError = message;
                    errors = true;
                    return errors;
                  }
                }
              }
            }
          }
        }
        await this.updateFrameSuspension();
      } else {
        /** Controle 28 */
        if (this.errorList.length === 0) {
          if (Number(this.techSpecSupChassis.trancheTechnicalData.bogieCount) === 0) {
            errors = this.checkAmountControlError(this.suspensionsCtrl.controls.length - 2); // on ne prend pas la dernière ligne et -1 plus d'index
            if (errors) {
              return errors;
            }
          }
        }
        /** Controle 29 et 30 */
        if (this.errorList.length === 0) {
          if (this.techSpecSupChassis.showSuspension === true && Number(this.techSpecSupChassis.trancheTechnicalData.bogieCount) !== 0) {
            //Controle 29 :RG_MAJ_003_4_5_19 
            const nbSuspensions = this.suspensionsCtrl.controls.length;
            if ((nbSuspensions == 1 && this.suspensions.controls[0].value.isAdded) || nbSuspensions > 1) {
              message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_19');
              if (!this.errorList || this.errorList.indexOf(message) === -1) {
                this.errorList.push(message);
                this.messageError = message;
                errors = true;
                return errors;
              }
            }
          }
          //Controle 30 :RG_MAJ_003_4_5_20
          if (this.errorList.length === 0) {
            if (this.techSpecSupChassis.showFrameLink === true && Number(this.techSpecSupChassis.trancheTechnicalData.bogieCount) !== 0) {
              if (this.frameLinkCtrl.value && this.frameLinkCtrl.value !== '') {
                message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_20');
                if (!this.errorList || this.errorList.indexOf(message) === -1) {
                  this.errorList.push(message);
                  this.messageError = message;
                  errors = true;
                  return errors;
                }
              }
            }
          }
        }
        await this.updateFrameSuspension();
      }
    } else {
      return errors;
    }

  }


  /**
  * Methode to check errors and put in a List
  */
  checkIfExistControlError(index: number): boolean {
    let message = null;
    this.messageError = '';
    this.errorList = [];
    let errors = false;
    const suspensionAdded = this.suspensionsDS.data[index];
    let natureSuspensionAddedCtrl;
    let amountSuspensionAddedCtrl;
    if (suspensionAdded) {
      natureSuspensionAddedCtrl = suspensionAdded.get('nature');
      amountSuspensionAddedCtrl = suspensionAdded.get('amount');
    }
    let countSuspension = 0;
    let amountSuspension = 0;

    this.suspensionsDS.data.forEach(element => {
      const amountCtrl = element.get('amount');
      amountSuspension = amountSuspension + Number(amountCtrl.value);

      if (amountSuspensionAddedCtrl && element.value.nature === natureSuspensionAddedCtrl.value) {
        countSuspension++;
      } else if (natureSuspensionAddedCtrl && natureSuspensionAddedCtrl.value && element.value) {
        if (element.value.nature === natureSuspensionAddedCtrl.value) {
          countSuspension++;
        }
      }

      if (countSuspension > 1) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_CONTROL_1');
        element.get('nature').setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }
    });

    if (amountSuspension !== (this.techSpecSupChassis.amountAxles) * 2) {
      message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_18');
      amountSuspensionAddedCtrl.setErrors(Validators.required);
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        errors = true;
      }
    }

    this.errorList.forEach(element => {
      this.messageError = this.messageError + element + '\n'
    })

    return errors;
  }


  /**
    * Methode to check errors and put in a List
  */
  checkAmountControlError(index: number): boolean {
    let message = null;
    this.messageError = '';
    this.errorList = [];
    let errors = false;
    const suspensionAdded = this.suspensionsDS.data[index];    
    let amountSuspensionAddedCtrl;
    if (suspensionAdded) {
      amountSuspensionAddedCtrl = suspensionAdded.get('amount');
      if (amountSuspensionAddedCtrl.invalid && (Number(index) < (this.suspensionsDS.data.length - 1))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_16_1');
        amountSuspensionAddedCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }
    }
    if (!errors){

      let amountSuspension = 0;

      this.suspensionsDS.data.forEach(element => {
        const amountCtrl = element.get('amount');
        amountSuspension = amountSuspension + Number(amountCtrl.value);
      });

      if (amountSuspension !== (this.techSpecSupChassis.amountAxles) * 2) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_18_1');
        amountSuspensionAddedCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }
    }

    this.errorList.forEach(element => {
      this.messageError = this.messageError + element + '\n'
    })

    return errors;
  }


  /**
    * Methode to check errors of amount textField
  */
  checkAmountControlIsValid(): boolean {

    let message = null;
    this.messageError = '';
    this.errorList = [];
    let errors = false;
    for (const index in this.suspensionsDS.data) {

      const amountCtrl = this.suspensionsDS.data[index].get('amount');
      if (amountCtrl.invalid && (Number(index) < (this.suspensionsDS.data.length - 1))) {
        message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_16_1');
        amountCtrl.setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }
    }

    this.errorList.forEach(element => {
      this.messageError = this.messageError + element + '\n'
    })

    return errors;
  }

  /**
    * Methode to check if there are nature duplicated
  */
  checkNatureControlDuplicated(): boolean {
    let message = null;
    this.messageError = '';
    this.errorList = [];
    let errors = false;
    let countSuspension = 0;
    let index2 = '0';
    let index = '0';

    for (index in this.suspensionsDS.data) {
      if (Number(index) < (this.suspensionsDS.data.length - 1)) {
        const natureCtrl = this.suspensionsDS.data[index].get('nature');
        for (index2 in this.suspensionsDS.data) {
          if (Number(index2) < (this.suspensionsDS.data.length - 1)) {
            const natureCtrl2 = this.suspensionsDS.data[index2].get('nature');
            if (natureCtrl && natureCtrl.value && natureCtrl2 && natureCtrl2.value) {
              if (natureCtrl2.value === natureCtrl.value) {
                countSuspension++;
              }
            }
            if (countSuspension > 1) {
              message = this.translateService.instant('wagon.technical.frame_suspension.RG_MAJ_003_4_5_16_2');
              natureCtrl.setErrors(Validators.required);
              natureCtrl2.setErrors(Validators.required);
              if (!this.errorList || this.errorList.indexOf(message) === -1) {
                this.errorList.push(message);
                errors = true;
              }
            }
          }
        }
        index2 = '0';
        countSuspension = 0;
      }
    }
    this.errorList.forEach(element => {
      this.messageError = this.messageError + element + '\n'
    })

    return errors;
  }

  changeValue(): void {
    this.messageError = '';
    this.errorList = [];
    this.submitted = false;

    this.dateCtrl.setErrors(null);
    this.constructionWorkshopCtrl.setErrors(null);
    this.elementAmountCtrl.setErrors(null);

    this.frameLinkCtrl.setErrors(null);
    this.suspensionsCtrl.setErrors(null);

    this.overallLengthCtrl.setErrors(null);
    this.frameLengthCtrl.setErrors(null);
    this.wheelBaseCtrl.setErrors(null);
    this.axleSpacingCtrl.setErrors(null);
    this.cantileveredCtrl.setErrors(null);
    this.curvatureRadiusCtrl.setErrors(null);
    this.humpRadiusCtrl.setErrors(null);
  }


  /**
   * Set all modifications of differents list
   */
  setSuspensionsLists(): void {
    this.techSpecSupChassis.wagonTechnicalDataDetail.frameSuspension.suspensionList = [];
    for (const index in this.suspensions.controls) {
      if (this.suspensions.controls[index].value.isAdded) {
        const wagonSuspension = new WagonSuspension();
        wagonSuspension.amount = this.suspensions.value[index].amount;
        wagonSuspension.suspension = this.techSpecReferences.suspensions.filter(s => s.code === this.suspensions.value[index].nature)[0];
        this.techSpecSupChassis.wagonTechnicalDataDetail.frameSuspension.suspensionList.push(wagonSuspension);
      }
    }
  }

  
  filterWithCodeLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + ' - ' + v.label).toUpperCase().includes(filterValue))
      ) : list;
  }
  displayCodeLabelFromSuspensionList = (obj: any) => {
    const f = this.techSpecReferences.suspensions.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label.replace(/ {2} */g, ' ')  : '';
  }
  displayCodeLabelFromFrameLinkList = (obj: any) => {
    const f = this.techSpecReferences.frameLinks.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label.replace(/ {2} */g, ' ')  : '';
  }
}
