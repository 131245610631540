import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { PcMessageService } from '../../services/pc-message/pc-message.service';
import { first } from 'rxjs/operators';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

/**
 * `HomeComponent` display a home page
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  support_mail = environment.support_mail;
  userMessages: SafeHtml[] = [];
/**
 * `HomeComponent` constructor
 * @param authService Inject application's authentication service
 */
  constructor(
    private authService: AuthService,
    private router: Router,
    private pcMessageService: PcMessageService,
    private sanitizer: DomSanitizer) {
    if (this.authService.isLogged().getValue()) {
      this.router.navigate(['/dashboard']);
    }
  }
  /** OnInit hook  */
  async ngOnInit() {
    if (!this.authService.isLogged().getValue()) {
      const msgs = await this.pcMessageService.getUserMessages().pipe(first()).toPromise();
      this.userMessages = msgs.data.filter(m => m.label !== null && m.ihmType !== 'B').map( e => this.sanitizer.bypassSecurityTrustHtml(e.label));
    }
  }
}
