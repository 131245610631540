import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MexHistory } from 'src/app/shared/models/mex-history';
import { WagonService } from 'src/app/shared/services/wagon/wagon.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-ex-mex-dialog',
  templateUrl: './ex-mex-dialog.component.html',
  styleUrls: ['./ex-mex-dialog.component.scss']
})
export class ExMexDialogComponent implements OnDestroy {
  /** histo mex datas */
  mexHistoryList: MexHistory[];
  /** Whether report is downloading */
  isLoadingReport = false;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** browser language */
  lang: string;
  /**
   * `ExMexDialogComponent` constructor
   * @param dialogRef reference to this component as a popable MatDialog
   * @param data Dialog injected data
   */
  constructor(
    private wagonService: WagonService,
    public dialogRef: MatDialogRef<ExMexDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.mexHistoryList = data.mexHistoryList;
      this.lang = data.lang;
  }
  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
  }

  downloadMexHistoryReport() {
    this.isLoadingReport = true;
    this.subscriptionRefs.push(
      this.wagonService.downloadMexHistoryReport(this.wagonService.getCurrentWagonId().getValue())
      .pipe(
        finalize(() => this.isLoadingReport = false)
      )
      .subscribe(
          (report) => {
            saveAs(report.blob, report.filename);
        })
    );
  }
}
