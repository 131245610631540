import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/services/app-api/api-response.model';
import { AppApiService } from 'src/app/core/services/app-api/app-api.service';
import { FaqTheme } from '../models/faq-theme';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private api: AppApiService) { }

  public getAllFAQ(): Observable<ApiResponse<FaqTheme[]>> {
    return this.api.get<ApiResponse<FaqTheme[]>>(`/faqs`)
      .pipe(
        catchError(this.handleError<any>('getFAQ', { data: [] }))
      );
  }

  save(faqs: FaqTheme[]): Observable<ApiResponse<FaqTheme[]>> {
    return this.api.post<ApiResponse<FaqTheme[]>>(`/faqs`, faqs)
      .pipe(
        catchError(this.handleError<any>('save', { data: [] }))
      );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
