import { Injectable } from '@angular/core';
import { AppApiService } from '../../../core/services/app-api/app-api.service';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '../../../core/services/app-api/api-response.model';
import { catchError, map } from 'rxjs/operators';
import { OperationTrackingTypeRelation } from '../../models/operation-tracking-type-relation';
import { AidTrackingTypeRelation } from '../../models/aid-tracking-type-relation';
import { OperationGroupRelation } from '../../models/operation-group-relation';
import { ThresholdVisitRelation } from '../../models/threshold-visit-relation';
import { TreatmentMonitoring } from '../../models/treatment-monitoring';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { AxleBoxRelation } from '../../models/axle-box-relation';
import { AxleWheelRelation } from '../../models/axle-wheel-relation';
import { AxleTypeWheelTypeRelation } from '../../models/axle-type-wheel-type-relation';
import { WheelSteelWheelTypeRelation } from '../../models/wheel-steel-wheel-type-relation';
import { BogieAxleRelation } from '../../models/bogie-axle-relation';
import { AuxTankRecomAuxTankRelation } from '../../models/aux-tank-recom-aux-tank-relation';
import { BogieBogieRelation } from '../../models/bogie-bogie-relation';
import { ReformModelWagonStatusComplementRelation } from '../../models/reform-model-wagon-status-complement-relation';
import { MaintenanceOperationForfaitRelation } from '../../models/maintenance-operation-forfait-relation';
import { WagonUpdate } from '../../models/wagon-update';
import { CustomerQualpRelation } from '../../models/customer-qualp-relation';
import { TreatmentResult } from '../../models/treatment-result';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { Liaison } from 'src/app/modules/consultation/customer-space/upload/models/liaison';
import { CollectedStream } from 'src/app/modules/consultation/customer-space/upload/models/collected-stream';
import { WorkingBillCategoryTypeRelation } from '../../models/working-bill-type-relation';
import { NumClassOpGroupRelation } from '../../models/num-class-op-group-relation';
import { ExchangeRegimeOperatingGroupRelation } from '../../models/exchange-regime-operating-group-relation';
import { MaterialCategoryOperatingGroupRelation } from '../../models/material-category-operating-group-relation';
import { StatusUsageRelation } from '../../models/status-usage-relation';
import { InventoryFunctionalProcedureRelation } from '../../models/inventory-functional-procedure-relation';
import { ConveyedProductMaterialCategoryRelation } from '../../models/conveyed-product-material-category-relation';
import { IntervReasonMatCat1Relation } from '../../models/interv-reason-mat-cat1-relation';
import { IntervReasonMatCat3Relation } from '../../models/interv-reason-mat-cat3-relation';
import { OperationUpdateTypeRelation } from '../../models/operation-update-type-relation';
import { OverhaulNatureFrequencyRelation } from '../../models/overhaul-nature-frequency-relation';
import { HabilitationWorkshopRelation } from '../../models/habilitation-workshop-relation';
import { MaintenanceAid4GcuRelation } from '../../models/maintenance-aid4-gcu-relation';

@Injectable({
  providedIn: 'root'
})
export class RelationService {
  /**
   * WagonService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) { }

  /**
   * Retrieve operation tracking type relations
   * @param tracking the tracking code
   * @returns encapsulated array of operation tracking type relations datas as `Observable`
   */
  public getOperationTrackingTypes(tracking?: string): Observable<ApiResponse<OperationTrackingTypeRelation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (tracking !== undefined) {
      parameters = parameters.set('tracking', `${tracking}`);
    }
    return this.api.get<ApiResponse<OperationTrackingTypeRelation[]>>(`/relations/operation-tracking`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getOperationTrackingTypes', {data: []}))
    );
  }
  /**
   * Retrieve aid tracking type relations
   * @param tracking the tracking code
   * @returns encapsulated array of aid tracking type relations datas as `Observable`
   */
  public getAidTrackingTypes(tracking?: string): Observable<ApiResponse<AidTrackingTypeRelation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (tracking !== undefined) {
      parameters = parameters.set('tracking', `${tracking}`);
    }
    return this.api.get<ApiResponse<AidTrackingTypeRelation[]>>(`/relations/aid-tracking`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getAidTrackingTypes', {data: []}))
    );
  }

  
  public getMaintenanceOperationForfaitRelations(): Observable<ApiResponse<MaintenanceOperationForfaitRelation[]>> {
    return this.api.get<ApiResponse<MaintenanceOperationForfaitRelation[]>>(`/relations/operation-forfait`)
    .pipe(
      catchError(this.handleError<any>('getOperationForfaitRelations', {data: []}))
    );
  }
  /**
   * Retrieve operation group relations
   * @param group the group code
   * @returns encapsulated array of operation group relations datas as `Observable`
   */
  public getOperationGroups(group?: string): Observable<ApiResponse<OperationGroupRelation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (group !== undefined) {
      parameters = parameters.set('group', `${group}`);
    }
    return this.api.get<ApiResponse<OperationGroupRelation[]>>(`/relations/operation-group`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getOperationGroups', {data: []}))
    );
  }

  /**
   * Retrieve threshold visit relations
   * @param op the maintanance operation code
   * @param customer the customer code
   * @returns encapsulated array of threshold visit relations datas as `Observable`
   */
  public getThresholdVisits(op?: string, customer?: string): Observable<ApiResponse<ThresholdVisitRelation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (op !== undefined) {
      parameters = parameters.set('op', `${op}`);
    }
    if (op !== undefined) {
      parameters = parameters.set('customer', `${customer}`);
    }
    return this.api.get<ApiResponse<ThresholdVisitRelation[]>>(`/relations/threshold-visit`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getThresholdVisits', {data: []}))
    );
  }

  /**
   * Retrieve axles - boxes relation datas
   * @returns encapsulated array of axles - boxes relation datas as `Observable`
   */
  public getAxleBoxRelations(): Observable<ApiResponse<AxleBoxRelation[]>> {
    return this.api.get<ApiResponse<AxleBoxRelation[]>>(`/relations/axle-box`)
    .pipe(
      catchError(this.handleError<any>('getAxleBoxRelations', {data: []}))
    );
  }

  /**
   * Retrieve axles - wheels relation datas
   * @returns encapsulated array of axles - wheels relation datas as `Observable`
   */
  public getAxleWheelRelations(): Observable<ApiResponse<AxleWheelRelation[]>> {
    return this.api.get<ApiResponse<AxleWheelRelation[]>>(`/relations/axle-wheel`)
    .pipe(
      catchError(this.handleError<any>('getAxleWheelRelations', {data: []}))
    );
  }

  /**
   * Retrieve axle types - wheel types relation datas
   * @returns encapsulated array of axle types - wheel types relation datas as `Observable`
   */
  public getAxleTypeWheelTypeRelations(): Observable<ApiResponse<AxleTypeWheelTypeRelation[]>> {
    return this.api.get<ApiResponse<AxleTypeWheelTypeRelation[]>>(`/relations/axle-type-wheel-type`)
    .pipe(
      catchError(this.handleError<any>('getAxleTypeWheelTypeRelations', {data: []}))
    );
  }

  /**
   * Retrieve wheels - wheel types - steels relation datas
   * @returns encapsulated array of wheel - wheel types - steels relation datas as `Observable`
   */
  public getWheelSteelWheelTypeRelations(): Observable<ApiResponse<WheelSteelWheelTypeRelation[]>> {
    return this.api.get<ApiResponse<WheelSteelWheelTypeRelation[]>>(`/relations/wheel-steel-wheel-type`)
    .pipe(
      catchError(this.handleError<any>('getWheelSteelWheelTypeRelations', {data: []}))
    );
  }

  /**
   * Retrieve bogies - axles relation datas
   * @returns encapsulated array of bogies - axles relation datas as `Observable`
   */
  public getBogieAxleRelations(): Observable<ApiResponse<BogieAxleRelation[]>> {
    return this.api.get<ApiResponse<BogieAxleRelation[]>>(`/relations/bogie-axle`)
    .pipe(
      catchError(this.handleError<any>('getBogieAxleRelations', {data: []}))
    );
  }

  /**
   * Retrieve auxiliary tanks - recom auxiliary tanks relation datas
   * @returns encapsulated array of auxiliary tanks - recom auxiliary tanks relation datas as `Observable`
   */
  public getAuxTankRecomAuxTankRelations(): Observable<ApiResponse<AuxTankRecomAuxTankRelation[]>> {
    return this.api.get<ApiResponse<AuxTankRecomAuxTankRelation[]>>(`/relations/aux-tank-recom-aux-tank`)
    .pipe(
      catchError(this.handleError<any>('getAuxTankRecomAuxTankRelations', {data: []}))
    );
  }

  /**
   * Retrieve bogies - bogies relation datas
   * @returns encapsulated array of bogies - bogies relation datas as `Observable`
   */
   public getBogieBogieRelations(): Observable<ApiResponse<BogieBogieRelation[]>> {
    return this.api.get<ApiResponse<BogieBogieRelation[]>>(`/relations/bogie-bogie`)
    .pipe(
      catchError(this.handleError<any>('getBogieBogieRelations', {data: []}))
    );
  }

  /**
   * Retrieve Reform model - wagon status complement relation datas
   * @returns encapsulated array of Reform model - wagon status complement relation datas as `Observable`
   */
   public getReformModelWagonStatusComplementRelations(): Observable<ApiResponse<ReformModelWagonStatusComplementRelation[]>> {
    return this.api.get<ApiResponse<ReformModelWagonStatusComplementRelation[]>>(`/relations/reform-model-status-complement`)
    .pipe(
      catchError(this.handleError<any>('getReformModelWagonStatusComplementRelations', {data: []}))
    );
  }

  /**
   * Retrieve customer - qualp relation datas
   * @returns encapsulated array of  customer - qualp relation datas as `Observable`
   */
  public getCustomerQualpRelations(): Observable<ApiResponse<CustomerQualpRelation[]>> {
    return this.api.get<ApiResponse<CustomerQualpRelation[]>>(`/relations/customer-qualp`)
    .pipe(
      catchError(this.handleError<any>('getCustomerQualpRelations', {data: []}))
    );
  }

  /**
   * Retrieve typbt - catbt relation datas
   * @returns encapsulated array of  typbt - catbt relation datas as `Observable`
   */
  public getWorkingBillCategoryTypeRelations(): Observable<ApiResponse<WorkingBillCategoryTypeRelation[]>> {
    return this.api.get<ApiResponse<WorkingBillCategoryTypeRelation[]>>(`/relations/type-category`)
    .pipe(
      catchError(this.handleError<any>('getWorkingBillCategoryTypeRelations', {data: []}))
    );
  }

  /**
   * Retrieve treatment monitoring datas
   * @param code the treatment code
   * @returns encapsulated array of threshold visit relations datas as `Observable`
   */
  public getTreatmentMonitorings(code: string): Observable<ApiResponse<TreatmentMonitoring[]>> {
    return this.api.get<ApiResponse<TreatmentMonitoring[]>>(`/treatments/${code}/monitoring`)
    .pipe(
      catchError(this.handleError<any>('getTreatmentMonitorings', {data: []}))
    );
  }

  getLiaison(liaisonCode: string) : Observable<ApiResponse<Liaison>> {
    return this.api.get<ApiResponse<AidTrackingTypeRelation[]>>(`/treatments/liaisons/${liaisonCode}`)
    .pipe(
      catchError(this.handleError<any>('getAidTrackingTypes', {data: null}))
    );
  }

  public getLiaisonsByAgent(): Observable<ApiResponse<Liaison[]>> {
    return this.api.get<ApiResponse<Liaison[]>>(`/treatments/liaisons`)
    .pipe(
      catchError(this.handleError<any>('getLiaisonsByAgent', {data: []}))
    );
  }

  public getLastCollectByLiaisonCode(code: string): Observable<ApiResponse<CollectedStream>> {
    return this.api.get<ApiResponse<CollectedStream>>(`/treatments/liaisons/${code}/last-collect`)
    .pipe(
      catchError(this.handleError<any>('getLastCollectByLiaisonCode', null))
    );
  }

  public uploadFile(file: File, code: string): Observable<ApiResponse<TreatmentResult>>  {
    const data = new FormData();
    data.append('file', file, file.name);
    return this.api.post<ApiResponse<any>>(`/treatments/liaisons/${code}/upload`, data).pipe(
      catchError(this.getErrorMessage<any>())
    );
  }

  public uploadFile2(file: File, code: string): Observable<any>  {
    const data = new FormData();
    data.append('file', file, file.name);
    return this.api.post<any>(`/treatments/liaisons/${code}/upload`, data, {responseType: 'blob', observe: 'events', reportProgress: true})
    .pipe(
      catchError(this.handleError<any>('downloadAllZip', null)),
      map((resp) => resp)
    );
  }

  public getResultsByLiaisonCode(liaisonCode: string, pageable?: Pageable): Observable<ApiResponse<TreatmentResult[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<TreatmentResult[]>>(`/treatments/liaisons/${liaisonCode}/results`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getResultsByLiaisonCode', null))
    );
  }

  public getWagonUpdates(workshopCode: string): Observable<ApiResponse<WagonUpdate[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('code', `${workshopCode}`);
    return this.api.get<ApiResponse<WagonUpdate[]>>(`/treatments/wagon-updates`, {params: parameters})
    .pipe(
      catchError(this.handleError<any>('getWagonUpdates', {data: []}))
    );
  }

  
  downloadCr(tm: TreatmentMonitoring): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/treatments/${tm.id}/cr`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadCr', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: (matches != null && matches[1] ? matches[1] : 'CR_' + tm.id) + '.txt'};
      })
    );
  }

  /**
   * Retrieve n - gt relation datas
   * @returns encapsulated array of n - gr relation datas as `Observable`
   */
  public getNumClassOpGroupRelations(isValid?: boolean): Observable<ApiResponse<NumClassOpGroupRelation[]>> {
    
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<NumClassOpGroupRelation[]>>(`/relations/numbering-opgroup`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getNumClassOpGroupRelations', {data: []}))
    );
  }

  getConveyedProductMaterialCategoryRelations(isValid: boolean): Observable<ApiResponse<ConveyedProductMaterialCategoryRelation[]>> {
     
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<ConveyedProductMaterialCategoryRelation[]>>(`/relations/conveyed-product-material-category`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getConveyedProductMaterialCategoryRelations', {data: []}))
    );
  }
  public getExchangeRegimeOperatingGroupRelations(isValid?: boolean): Observable<ApiResponse<ExchangeRegimeOperatingGroupRelation[]>> {
    
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<ExchangeRegimeOperatingGroupRelation[]>>(`/relations/exchange-regime-operating-group`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getExchangeRegimeOperatingGroupRelations', {data: []}))
    );
  }

  public getStatusUsageRelations(isValid: boolean): Observable<ApiResponse<StatusUsageRelation[]>> {
    
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<StatusUsageRelation[]>>(`/relations/status-usage`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getStatusUsageRelations', {data: []}))
    );
  }

  public getInventoryFunctionalProcedureRelations(isValid: boolean): Observable<ApiResponse<InventoryFunctionalProcedureRelation[]>> {
    
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<InventoryFunctionalProcedureRelation[]>>(`/relations/inventory-functional-procedure`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getInventoryFunctionalProcedureRelations', {data: []}))
    );
  }

  public getMaterialCategoryOperatingGroupRelations(isValid?: boolean): Observable<ApiResponse<MaterialCategoryOperatingGroupRelation[]>> {
    
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<MaterialCategoryOperatingGroupRelation[]>>(`/relations/mat-category-operating-group`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getMaterialCategoryOperatingGroupRelations', {data: []}))
    );
  }

  public getOperationUpdateTypeRelations(isValid?: boolean): Observable<ApiResponse<OperationUpdateTypeRelation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<OperationUpdateTypeRelation[]>>(`/relations/operation-update-type`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getOperationUpdateTypeRelations', {data: []}))
    );
  }

  public getIntervReasonMatCat1Relations(isValid?: boolean): Observable<ApiResponse<IntervReasonMatCat1Relation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<IntervReasonMatCat1Relation[]>>(`/relations/reason-material-category1`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getIntervReasonMatCat1Relations', {data: []}))
    );
  }

  public getIntervReasonMatCat3Relations(isValid?: boolean): Observable<ApiResponse<IntervReasonMatCat3Relation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<IntervReasonMatCat3Relation[]>>(`/relations/reason-material-category3`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getIntervReasonMatCat3Relations', {data: []}))
    );
  }

  public getOverhaulNatureFrequencyRelations(isValid?: boolean): Observable<ApiResponse<OverhaulNatureFrequencyRelation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<OverhaulNatureFrequencyRelation[]>>(`/relations/overhaul-nature-frequency`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('publicgetOverhaulNatureFrequencyRelations', {data: []}))
    );
  }
  
  public getHabilitationWorkshopRelations(isValid?: boolean): Observable<ApiResponse<HabilitationWorkshopRelation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<HabilitationWorkshopRelation[]>>(`/relations/habilitation-workshop`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getHabilitationWorkshopRelations', {data: []}))
    );
  }
  
  public getMaintenanceAid4GcuRelations(isValid?: boolean): Observable<ApiResponse<MaintenanceAid4GcuRelation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<MaintenanceAid4GcuRelation[]>>(`/relations/maintenance-aid-4-gcu`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getMaintenanceAid4GcuRelations', {data: []}))
    );
  }
  private getErrorMessage<T>(){
    return (error: any): Observable<T> => {
      return of(error.error.errors[0] as T);
    };
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      console.log(error);
      
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
