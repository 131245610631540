import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/core/services/app-api/app-api.service';
import { Observable, of } from 'rxjs';
import { ApiResponse } from 'src/app/core/services/app-api/api-response.model';
import { AxleType } from '../../models/axle-type';
import { catchError, map } from 'rxjs/operators';
import { Workshop } from '../../models/workshop';
import { HttpParams } from '@angular/common/http';
import { Damage } from '../../models/damage';
import { Bogie } from '../../models/bogie';
import { Regulator } from '../../models/regulator';
import { BrakeMainOrgan } from '../../models/brake-main-organ';
import { OrganType } from '../../models/organ-type';
import { Measure } from '../../models/measure';
import { Axle } from '../../models/axle';
import { Box } from '../../models/box';
import { Wheel } from '../../models/wheel';
import { DealStatus } from '../../models/deal-status';
import { BillingStatus } from '../../models/billing-status';
import { DealClaimant } from '../../models/deal-claimant';
import { DealCustomer } from '../../models/deal-customer';
import { RU } from '../../models/ru';
import { TrainStation } from '../../models/train-station';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { WorkshopHabilitation } from '../../models/workshop-habilitation';
import { AxleMaintenance } from '../../models/axle-maintenance';
import { Owner } from '../../models/owner';
import { Company } from '../../models/company';
import { Steel } from '../../models/steel';
import { Maker } from '../../models/maker';
import { DiscType } from '../../models/disc-type';
import { WheelType } from '../../models/wheel-type';
import { BoxType } from '../../models/box-type';
import { AvailableTip } from '../../models/available-tip';
import { BearingType } from '../../models/bearing-type';
import { GreaseType } from '../../models/grease-type';
import { UpdateType } from '../../models/update-type';
import { InterventionReason } from '../../models/intervention-reason';
import { InterventionCause } from '../../models/intervention-cause';
import { Imputation } from '../../models/imputation';
import { MaintenanceOperation } from '../../models/maintenance-operation';
import { RefCamUpdate } from '../../models/ref-cam-udpate';
import { DerogationSystem } from '../../models/derogation-system';
import { SystematicWorkPlate } from '../../models/systematic-work-plate';
import { OverhaulPlate } from '../../models/overhaul-plate';
import { SystematicWorkPlateUpdate } from '../../models/systematic-work-plat-update';
import { OverhaulPlateUpdate } from '../../models/overhaul-plate-update';
import { IglCam } from '../../models/igl-cam';
import { IglDefautCuu } from '../../models/igl-defaut-cuu';
import { EventsHistory } from '../../models/events-history';
import { EventsHistoryUpdate } from '../../models/events-history-update';
import { WagonProduction } from '../../models/wagon-production';
import { CamsDefault } from '../../models/cams-default';
import { TrailedMaterialStatus } from '../../models/trailed-material-status';
import { RepartitionCode } from '../../models/repartition-code';
import { ConstructionType } from '../../models/construction-type';
import { StatisticalCode } from '../../models/statistical-code';
import { ModifiedTrademark } from '../../models/modified-trademark';
import { NoRIV } from '../../models/no-riv';
import { SpecialAgreement } from '../../models/special-agreement';
import { UsageRestriction } from '../../models/usage-restriction';
import { OverhaulFrequency } from '../../models/overhaul-frequency';
import { ReasonModification } from '../../models/reason-modification';
import { MaintenanceAid } from '../../models/maintenance-aid';
import { Gcu } from '../../models/gcu';
import { StateJob } from '../../models/state-job';
import { DerogationSystemList } from '../../models/derogation-system-list';
import { WorkshopGroup } from '../../models/workshop-group';
import { TransitRegime } from '../../models/transit-regime';
import { TechVariable } from '../../models/tech-variable';
import { ReformModel } from '../../models/reform-model';
import { ReformReason } from '../../models/reform-reason';
import { ReformType } from '../../models/reform-type';
import { Region } from '../../models/region';
import { Components } from '../../models/components';
import { ReformModelComplement } from '../../models/reform-model-complement';
import { IncidentType } from '../../models/incident-type';
import { WagonStatusComplement } from '../../models/wagon-status-complement';
import { Consistency } from '../../models/consistency';
import { Operation } from '../../models/operation';
import { ConstructiveRule } from '../../models/constructive-rule';
import { RecomAuxiliaryTank } from '../../models/recom-auxiliary-tank';
import { TankMaker } from '../../models/tank-maker';
import { CodTable } from '../../models/cod-table';
import { Application } from '../../models/application';
import { RefWork } from '../../models/ref-work';
import { RefMaterial } from '../../models/ref-material';
import { Holder } from '../../models/holder';
import { Customer } from '../../models/customer';
import { Forfait } from '../../models/forfait';
import { WorkingBillType } from '../../models/working-bill-type';
import { WorkingBillCategory } from '../../models/working-bill-category';
import { NonRefWM } from '../../models/non-ref-w-m';
import { BrakeTest } from '../../models/brake-test';
import { CustomHttpParamEncoder } from '../custom-http-param-encoder';
import { TrancheType } from '../../models/tranche-type';
import { MaterialCategory3 } from '../../models/material-category3';
import { ManagementType } from '../../models/management-type';
import { MaintenanceAidLevel } from '../../models/maintenance-aid-level';
import { LineCategory } from '../../models/line-category';
import { AssignmentType } from '../../models/assignment-type';
import { ScaleMgmtGroup } from '../../models/scale-mgmt-group';
import { AxleMaintenanceInterval } from '../../models/axle-maintenance-interval';
import { LiteralMarking } from '../../models/literal-marking';
import { MaterialCategory } from '../../models/material-category';
import { Subseries } from '../../models/subseries';
import { WorkTimeAuthorization } from '../../models/work-time-authorization';
import { Inventory } from '../../models/inventory';
import { ConveyedProduct } from '../../models/conveyed-product';
import { Rid } from '../../models/rid';
import { EmergencyDegree } from '../../models/emergency-degree';
import { CommercialContract } from '../../models/commercial-contract';
import { MaintenanceContract } from '../../models/maintenance-contract';
import { PlateChangeReason } from '../../models/plate-change-reason';
import { FunctionalControl } from '../../models/functional-control';

/**
 * `ReferenceService` is responsible for retrieve references tables datas through API calls.
 */
@Injectable({
  providedIn: 'root'
})
export class ReferenceService {
  /**
   * ReferenceService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) { }

  /**
   * Retrieve workshops list
   * @returns encapsulated axle types list as `Observable`
   */
  public getWorkshops(isValid?: boolean, pageable?: Pageable): Observable<ApiResponse<Workshop[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<Workshop[]>>(`/references/workshops`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getWorkshops', { data: [] }))
      );
  }

  
  /**
   * Retrieve customer list
   * @returns encapsulated customer list as `Observable`
   */
  public getCustomers(isValid?: boolean, pageable?: Pageable): Observable<ApiResponse<Customer[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<Customer[]>>(`/references/customers`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getCustomers', { data: [] }))
      );
  }

  
  getConsistencies(isValid?: boolean, pageable?: Pageable): Observable<ApiResponse<Consistency[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<Workshop[]>>(`/references/consistencies`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getConsistencies', { data: [] }))
      );
  }

  getComponents(): Observable<ApiResponse<Components[]>> {
    return this.api.get<ApiResponse<Components[]>>(`/references/components`)
      .pipe(
        catchError(this.handleError<any>('getComponents', { data: [] }))
      );
  }

  getValidComponents(): Observable<ApiResponse<Components[]>> {
    return this.api.get<ApiResponse<Components[]>>(`/references/valid-components`)
      .pipe(
        catchError(this.handleError<any>('getValidComponents', { data: [] }))
      );
  }
  /**
   * Retrieve workshops list for the deal
   * @returns encapsulated workshop list as `Observable`
   */
   public getWorkshopsForAffaire(affaireId: string, pageable?: Pageable): Observable<ApiResponse<Workshop[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('affaireId', `${affaireId}`);
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<Workshop[]>>(`/references/workshops-affaire`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getWorkshopsForAffaire', { data: [] }))
      );
  }

  /**
   * Retrieve workshops list
   * @returns encapsulated workshop list as `Observable`
   */
  public getWorkshopsForUser(pageable?: Pageable): Observable<ApiResponse<Workshop[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<Workshop[]>>(`/references/workshops-user`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getWorkshopsForUser', { data: [] }))
      );
  }

  /**
   * Retrieve workshops lvl 2 list
   * @returns encapsulated workshop list as `Observable`
   */
  public getWorkshopsLvl2ForUser(pageable?: Pageable): Observable<ApiResponse<Workshop[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<Workshop[]>>(`/references/workshops-user-lvl2`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getWorkshopsForUser', { data: [] }))
      );
  }

  /**
   * Retrieve axles types list
   * @returns encapsulated axle types list as `Observable`
   */
   public getAxleTypes(): Observable<ApiResponse<AxleType[]>> {
    return this.api.get<ApiResponse<AxleType[]>>(`/references/axle-types`)
      .pipe(
        catchError(this.handleError<any>('getAxleTypes', { data: [] }))
      );
  }

  /**
   * Retrieve regions list
   * @returns encapsulated axle types list as `Observable`
   */
  public getRegions(isValid?: boolean, pageable?: Pageable): Observable<ApiResponse<Region[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<Region[]>>(`/references/regions`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getRegions', { data: [] }))
      );
  }

  /**
   * Retrieve holder list
   * @returns encapsulated holder list as `Observable`
   */
  public getHolders(isValid?: boolean, pageable?: Pageable): Observable<ApiResponse<Holder[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<Holder[]>>(`/references/holders`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getHolders', { data: [] }))
      );
  }

  /**
   * Retrieve damage list
   * @returns encapsulated damage list as `Observable`
   */
  public getDamages(): Observable<ApiResponse<Damage[]>> {
    return this.api.get<ApiResponse<AxleType[]>>(`/references/damages`)
      .pipe(
        catchError(this.handleError<any>('getDamages', { data: [] }))
      );
  }

  /**
   * Retrieve bogie list
   * @returns encapsulated bogie list as `Observable`
   */
  public getBogies(): Observable<ApiResponse<Bogie[]>> {
    return this.api.get<ApiResponse<Bogie[]>>(`/references/bogies`)
      .pipe(
        catchError(this.handleError<any>('getBogies', { data: [] }))
      );
  }

  /**
   * Retrieve regulator list
   * @returns encapsulated regulator list as `Observable`
   */
  public getRegulators(): Observable<ApiResponse<Regulator[]>> {
    return this.api.get<ApiResponse<Regulator[]>>(`/references/regulators`)
      .pipe(
        catchError(this.handleError<any>('getRegulators', { data: [] }))
      );
  }

  /**
   * Retrieve brake main organ list
   * @returns encapsulated brake main organ list as `Observable`
   */
  public getBrakeMainOrgans(): Observable<ApiResponse<BrakeMainOrgan[]>> {
    return this.api.get<ApiResponse<BrakeMainOrgan[]>>(`/references/brake-main-organs`)
      .pipe(
        catchError(this.handleError<any>('getRegulators', { data: [] }))
      );
  }

  /**
   * Retrieve organ type list
   * @returns encapsulated organ type list as `Observable`
   */
  public getOrganTypes(): Observable<ApiResponse<OrganType[]>> {
    return this.api.get<ApiResponse<OrganType[]>>(`/references/organ-types`)
      .pipe(
        catchError(this.handleError<any>('getOrganTypes', { data: [] }))
      );
  }

  /**
   * Retrieve measure list
   * @returns encapsulated measure list as `Observable`
   */
  getMeasures(): Observable<ApiResponse<Measure[]>> {
    return this.api.get<ApiResponse<Measure[]>>(`/references/measures`)
      .pipe(
        catchError(this.handleError<any>('getMeasures', { data: [] }))
      );
  }

  /**
   * Retrieve axle list
   * @returns encapsulated axle list as `Observable`
   */
  public getAxles(): Observable<ApiResponse<Axle[]>> {
    return this.api.get<ApiResponse<Axle[]>>(`/references/axles`)
      .pipe(
        catchError(this.handleError<any>('getAxles', { data: [] }))
      );
  }

  /**
   * Retrieve box list
   * @returns encapsulated box list as `Observable`
   */
  public getBoxes(): Observable<ApiResponse<Box[]>> {
    return this.api.get<ApiResponse<Box[]>>(`/references/boxes`)
      .pipe(
        catchError(this.handleError<any>('getBoxes', { data: [] }))
      );
  }

  /**
   * Retrieve box list
   * @returns encapsulated box list as `Observable`
   */
  public getWheels(): Observable<ApiResponse<Wheel[]>> {
    return this.api.get<ApiResponse<Wheel[]>>(`/references/wheels`)
      .pipe(
        catchError(this.handleError<any>('getWheels', { data: [] }))
      );
  }

  /**
   * Retrieve deal status list
   * @returns encapsulated deal status list as `Observable`
   */
  public getDealStatus(): Observable<ApiResponse<DealStatus[]>> {
    return this.api.get<ApiResponse<DealStatus[]>>(`/references/deal-status`)
      .pipe(
        catchError(this.handleError<any>('getDealStatus', { data: [] }))
      );
  }

  /**
   * Retrieve bt/devis status list
   * @returns encapsulated status list as `Observable`
   */
  public getBillingStatus(isValid?: boolean): Observable<ApiResponse<BillingStatus[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<BillingStatus[]>>(`/references/billing-status`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getBillingStatus', { data: [] }))
      );
  }

  /**
   * Retrieve tranche type list
   * @returns encapsulated tranche type list as `Observable`
   */
  public getTrancheTypes(isValid?: boolean): Observable<ApiResponse<TrancheType[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<TrancheType[]>>(`/references/tranche-types`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getTrancheTypes', { data: [] }))
      );
  }
  /**
   * Retrieve management type list
   * @returns encapsulated management type list as `Observable`
   */
  public getManagementTypes(isValid?: boolean): Observable<ApiResponse<ManagementType[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<ManagementType[]>>(`/references/management-types`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getManagementTypes', { data: [] }))
      );
  }
  
  /**
   * Retrieve management type list
   * @returns encapsulated management type list as `Observable`
   */
  public getAssignmentTypes(isValid?: boolean): Observable<ApiResponse<AssignmentType[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<AssignmentType[]>>(`/references/assignment-types`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getAssignmentTypes', { data: [] }))
      );
  }
  
  /**
   * Retrieve management type list
   * @returns encapsulated management type list as `Observable`
   */
  public getLiteralMarkings(isValid?: boolean): Observable<ApiResponse<LiteralMarking[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<LiteralMarking[]>>(`/references/literal-markings`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getLiteralMarkings', { data: [] }))
      );
  }
  
  /**
   * Retrieve axle maint interval list
   * @returns encapsulated axle maint interval list as `Observable`
   */
  public getAxleMaintenanceIntervals(isValid?: boolean): Observable<ApiResponse<AxleMaintenanceInterval[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<AxleMaintenanceInterval[]>>(`/references/axle-maintenance-intervals`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getAxleMaintenanceIntervals', { data: [] }))
      );
  }
  
  /**
   * Retrieve scale mgmt group list
   * @returns encapsulated scale mgmt group list as `Observable`
   */
  public getScaleMgmtGroups(): Observable<ApiResponse<ScaleMgmtGroup[]>> {
    return this.api.get<ApiResponse<AssignmentType[]>>(`/references/scale-mgmt-groups`)
      .pipe(
        catchError(this.handleError<any>('getScaleMgmtGroups', { data: [] }))
      );
  }
  
  /**
   * Retrieve maintenace iad level list
   * @returns encapsulated maintenace iad level list as `Observable`
   */
  public getMaintenanceAidLevels(isValid?: boolean): Observable<ApiResponse<MaintenanceAidLevel[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<MaintenanceAidLevel[]>>(`/references/maintenance-aid-levels`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getMaintenanceAidLevels', { data: [] }))
      );
  }
  /**
   * Retrieve cmat3 list
   * @returns encapsulated cmat3 list as `Observable`
   */
  public getMaterialCategories3(isValid?: boolean): Observable<ApiResponse<MaterialCategory3[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<TrancheType[]>>(`/references/material-category-3`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getMaterialCategories3', { data: [] }))
      );
  }
  /**
   * Retrieve cmat list
   * @returns encapsulated cmat list as `Observable`
   */
  public getMaterialCategories(isValid?: boolean): Observable<ApiResponse<MaterialCategory[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<MaterialCategory[]>>(`/references/material-categories`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getMaterialCategories', { data: [] }))
      );
  }

  /**
   * Retrieve deal claimant list
   * @returns encapsulated deal claimant list as `Observable`
   */
  public getDealClaimantsValid(): Observable<ApiResponse<DealClaimant[]>> {
    return this.api.get<ApiResponse<DealClaimant[]>>(`/references/deal-claimants-valid`)
      .pipe(
        catchError(this.handleError<any>('getDealClaimants', { data: [] }))
      );
  }

  /**
   * Retrieve deal claimant list
   * @returns encapsulated deal claimant list as `Observable`
   */
  public getDealClaimants(): Observable<ApiResponse<DealClaimant[]>> {
    return this.api.get<ApiResponse<DealClaimant[]>>(`/references/deal-claimants`)
      .pipe(
        catchError(this.handleError<any>('getDealClaimants', { data: [] }))
      );
  }

  /**
   * Retrieve deal customer list
   * @returns encapsulated deal claimant list as `Observable`
   */
  public getDealCustomers(): Observable<ApiResponse<DealCustomer[]>> {
    return this.api.get<ApiResponse<DealCustomer[]>>(`/references/deal-customers`)
      .pipe(
        catchError(this.handleError<any>('getDealCustomers', { data: [] }))
      );
  }

  /**
   * Retrieve WorkingBillType list
   * @returns encapsulated wb type list as `Observable`
   */
  public getWorkingBillTypes(isValid?: boolean): Observable<ApiResponse<WorkingBillType[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<WorkingBillType[]>>(`/references/working-bill-types`, {params: parameters})
      .pipe(
        catchError(this.handleError<any>('getWorkingBillTypes', { data: [] }))
      );
  }

  /**
   * Retrieve WorkingBillCategory list
   * @returns encapsulated wb cat list as `Observable`
   */
  public getWorkingBillCategories(isValid?: boolean): Observable<ApiResponse<WorkingBillCategory[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<WorkingBillCategory[]>>(`/references/working-bill-categories`, {params: parameters})
      .pipe(
        catchError(this.handleError<any>('getWorkingBillCategories', { data: [] }))
      );
  }

  /**
   * Retrieve brake test list
   * @returns encapsulated brake test list as `Observable`
   */
  public getBrakeTests(isValid?: boolean): Observable<ApiResponse<BrakeTest[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<BrakeTest[]>>(`/references/brake-tests`, {params: parameters})
      .pipe(
        catchError(this.handleError<any>('getBrakeTests', { data: [] }))
      );
  }
 /**
  * Retrieve ru list
  * @returns encapsulated ru list as `Observable`
  */
 public getRUs(): Observable<ApiResponse<RU[]>> {
  return this.api.get<ApiResponse<RU[]>>(`/references/rus`)
    .pipe(
      catchError(this.handleError<any>('getRUs', { data: [] }))
    );
}
/**
 * Retrieve exchange regime list
 * @returns encapsulated re list as `Observable`
 */
 public getExchangeRegimes(): Observable<ApiResponse<{code: string, label: string}[]>> {
   return this.api.get<ApiResponse<{code: string, label: string}[]>>(`/references/exchange-regimes`)
     .pipe(
       catchError(this.handleError<any>('getExchangeRegimes', { data: [] }))
     );
 }
 /**
  * Retrieve ru list
  * @returns encapsulated ru list as `Observable`
  */
  public getValidRUs(): Observable<ApiResponse<RU[]>> {
    return this.api.get<ApiResponse<RU[]>>(`/references/valid-rus`)
      .pipe(
        catchError(this.handleError<any>('getValidRUs', { data: [] }))
      );
  }

  /**
   * Retrieve train station list
   * @returns encapsulated train station list as `Observable`
   */
  public getTrainStations(pageable?: Pageable): Observable<ApiResponse<TrainStation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<TrainStation[]>>(`/references/train-stations`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getTrainStations', { data: [] }))
      );
  }

  public getWorkshopGroups(pageable?: Pageable): Observable<ApiResponse<WorkshopGroup[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<WorkshopGroup[]>>(`/references/workshop-groups`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getWorkshopGroups', { data: [] }))
      );
  }
  /**
   * Initialise the table R_WPHY_CAM_MAJ
   * @param listCam ?
   * @param wagonId ?
   * @param mex wagon's mex
   * @param updateType update type code
   * @param hasExpertise has wagon an expertise
   * @param intervention intervention
   * @param idExp expertise's id
   */
  public initialiseWagonCamUpdate(
    element: any, mex: string, updateType: string, hasExpertise: boolean, intervention?: string, idExp?: number
  ): Observable<ApiResponse<RefCamUpdate[]>> {

    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('type', `${updateType}`);
    parameters = parameters.set('hasExpertise', `${hasExpertise}`);
    if (intervention !== undefined && intervention !== null) {
      parameters = parameters.set('intervention', `${intervention}`);
    }
    if (idExp !== undefined && idExp !== null) {
      parameters = parameters.set('idExp', `${idExp}`);
    }
    return this.api.post<RefCamUpdate[]>(`/references/initialise-cam`, element, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('initialiseWagonCamUpdate', null))
      );
  }

  /**
   * create derogation system type record
   * @param element DerogationSystem type  datas
   */
  public createListDerogationSystem(element: DerogationSystem[]): Observable<ApiResponse<DerogationSystem[]>> {
    return this.api.post<ApiResponse<DerogationSystem[]>>(`/references/list-derogation-system`, element)
      .pipe(
        catchError(this.handleError<any>('createListDerogationSystem', { data: [] }))
      );
  }


  /**
   * Finds derogation system records
   * @param wagonId the wagon's id
   */
  public getDerogationSystem(wagonId: number): Observable<ApiResponse<DerogationSystemList>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wagonId}`);
    return this.api.get<ApiResponse<DerogationSystemList>>(`/references/derogations-system`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getDerogationSystem', { data: [] }))
      );
  }

  /**
   * Rechercher la liste T084_REG_ACHEMINEMENT
   */
  public getLineCategoryList(isValid?: boolean): Observable<ApiResponse<LineCategory[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<LineCategory[]>>(`/references/line-categories`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getLineCategoryList', { data: [] }))
      );
  }

  /**
   * Rechercher la liste T083_CAT_LIGNE
   */
  public getTransitRegimeList(): Observable<ApiResponse<TransitRegime[]>> {
    return this.api.get<ApiResponse<TransitRegime[]>>(`/references/transit-regime-list`)
      .pipe(
        catchError(this.handleError<any>('getTransitRegimeList', { data: [] }))
      );
  }

  /**
   * Retrieve Workshop Habilitation list for the user
   * @returns encapsulated Workshop Habilitation list as `Observable`
   */
  public getWorkshopDealHabilitations(): Observable<ApiResponse<WorkshopHabilitation[]>> {
    return this.api.get<ApiResponse<WorkshopHabilitation[]>>(`/references/workshop-deal-habilitations`)
      .pipe(
        catchError(this.handleError<any>('getWorkshopDealHabilitations', { data: [] }))
      );
  }


  /**
   * Retrieve update type list
   * @returns encapsulated update type list as `Observable`
   */
  public getUpdateType(pageable?: Pageable): Observable<ApiResponse<UpdateType[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<UpdateType[]>>(`/references/update-type`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getUpdateType', { data: [] }))
      );
  }

  /**
   * Retrieve intervention reason list. If the wagon is out of maintenance contract or if the wagon does not exist
   * @returns encapsulated Intervention reason valid list as `Observable`
   */
  public getAllInterventionsReason(pageable?: Pageable, isValid?: boolean): Observable<ApiResponse<InterventionReason[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<InterventionReason[]>>(`/references/all-interventions-reason`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getInterventionsReason', { data: [] }))
      );
  }

  /**
   * Retrieve intervention reason list. If the wagon is under maintenance contract
   * @returns encapsulated Intervention reason list as `Observable`
   */
  public getInterventionsReason(mex: string, inventory: string, pageable?: Pageable): Observable<ApiResponse<InterventionReason[]>> {
    let parameters: HttpParams = new HttpParams();
    if (mex !== undefined && mex !== null && mex !== '') {
      parameters = parameters.set('mex', `${mex}`);
    }

    if (inventory !== undefined && inventory !== null && inventory !== '') {
      parameters = parameters.set('inventory', `${inventory}`);
    }

    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<InterventionReason[]>>(`/references/interventions-reason`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getInterventionsReason', { data: [] }))
      );
  }

  /**
   * Retrieve intervention cause list
   * @returns encapsulated Intervention cause list as `Observable`
   */
  public getInterventionsCause(pageable?: Pageable, isValid?: boolean): Observable<ApiResponse<InterventionCause[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<InterventionCause[]>>(`/references/interventions-cause`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getInterventionsCause', { data: [] }))
      );
  }

  /**
   * Retrieve imputation list
   * @returns encapsulated imputation valid list as `Observable`
   */
  public getImputations(pageable?: Pageable, isValid?: boolean): Observable<ApiResponse<Imputation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<Imputation[]>>(`/references/imputations`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getImputations', { data: [] }))
      );
  }

  /**
   * Retrieve MaintenanceOperation list respecting the following 3 rules:
   * - R1: Authorized (RT_OPE_HAB) depending on the authorization of the establishment operating on the wagon (RT_ETAB_HAB).
   * - R2: Authorized according to the entered operation code (RT_OPE_TMAJ).
   * - R3: Authorized according to the Wagon maintenance contract (RT_OPE_CMAINT)
   * @returns encapsulated imputation valid list as `Observable`
   */
  public getMaintenanceOperation(wagonId?: number, workshop?: string, codeOp?: string, pageable?: Pageable)
    : Observable<ApiResponse<MaintenanceOperation[]>> {
    let parameters: HttpParams = new HttpParams();

    if (wagonId !== undefined && wagonId !== null) {
      parameters = parameters.set('wagonId', `${wagonId}`);
    }

    if (workshop !== undefined && workshop !== null && workshop !== '') {
      parameters = parameters.set('workshop', `${workshop}`);
    }

    if (codeOp !== undefined && codeOp !== null && codeOp !== '') {
      parameters = parameters.set('code_op', `${codeOp}`);
    }

    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<MaintenanceOperation[]>>(`/references/maintenance-operation`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getMaintenanceOperation', { data: [] }))
      );
  }


  /**
   * Service qui retourn Si l’opération de maintenance du wagon autorise l’affichage du cartouche proposé :
   * IGL_WAGON.IWAG_OPE_CODE_FK du wagon appartient à OPE_CODE_PK de la table T_OPE_MAINTENANCE
   * avec OPE_FLAG_BS_CCREV = 1 et dates d’application D/F valides
   * @returns encapsulated imputation valid list as `Observable`
   */
  public isDisplayedMaintenanceOperation(operation: string): Observable<ApiResponse<boolean>> {

    return this.api.get<ApiResponse<boolean>>(`/references/maintenance-operation-display/${operation}`)
      .pipe(
        catchError(this.handleError<any>('isDisplayedMaintenanceOperation', { data: null }))
      );
  }


  /**
   * Vérifier que l’établissement intervenant sur wagon est habilité « Construction » (code 2 dans RT_ETAB_HAB)
   * @returns encapsulated boolean element as `Observable`
   */
  public isApprovedEstablishments(code: string): Observable<ApiResponse<boolean>> {
    return this.api.get<ApiResponse<boolean>>(`/references/approved-establishments/${code}`)
      .pipe(
        catchError(this.handleError<any>('isApprovedEstablishments', { data: null }))
      );
  }

  /**
   * Check if Etablisment Agent has rights to modify a wagon
   * @param agentCompanyCode the etablishment agent's code
   * @param technicalDataOwnerCode the owner's code
   * @param technicalDataEcmCode the maintenance entity's code
   * @returns encapsulated boolean as `Observable`
   */
  getEtablisementAgentRightsByEtabCli(agentCompanyCode: string, technicalDataOwnerCode?: string, technicalDataEcmCode?: string)
    : Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('companyCode', `${agentCompanyCode}`);
    if (technicalDataOwnerCode !== undefined && technicalDataOwnerCode !== null) {
      parameters = parameters.set('ownerCode', `${technicalDataOwnerCode}`);
    }
    if (technicalDataEcmCode !== undefined && technicalDataEcmCode !== null) {
      parameters = parameters.set('ecmCode', `${technicalDataEcmCode}`);
    }

    return this.api.get<ApiResponse<boolean>>('/references/technicaldata/rights', { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getEtablisementAgentRightsByEtabCli', { data: null }))
      );
  }

  /**
   * Check if Etablisment Agent has rights to modify a wagon
   * @param agentCompanyCode the etablishment agent's code
   * @param holderSheetCode the owner's code
   * @returns encapsulated boolean as `Observable`
   */
  getEtablisementAgentRightsByEtabDet(agentCompanyCode: string, holderSheetCode?: string): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('companyCode', `${agentCompanyCode}`);
    if (holderSheetCode !== undefined && holderSheetCode !== null) {
      parameters = parameters.set('holderSheetCode', `${holderSheetCode}`);
    }
    return this.api.get<ApiResponse<boolean>>('/references/holdersheet/rights', { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getEtablisementAgentRightsByEtabDet', { data: null }))
      );
  }

  /**
   * Check if Etablisment Agent has rights to modify a wagon
   * @param agentCompanyCode the etablishment agent's code
   * @returns encapsulated boolean as `Observable`
   */
  hasRestrictionEtabDet(agentCompanyCode: string): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('companyCode', `${agentCompanyCode}`);
    return this.api.get<ApiResponse<boolean>>('/references/holdersheet/rights-open-wagon', { params: parameters })
      .pipe(
        catchError(this.handleError<any>('hasRestrictionEtabDet', { data: null }))
      );
  }

  /**
   * Check if Etablisment Agent has rights to modify a wagon hors contrat
   * @param agentCompanyCode the etablishment agent's code
   * @returns encapsulated boolean as `Observable`
   */
  hasRestrictionEtabCli(agentCompanyCode: string): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('companyCode', `${agentCompanyCode}`);
    return this.api.get<ApiResponse<boolean>>('/references/technicaldata/rights-open-wagon', { params: parameters })
      .pipe(
        catchError(this.handleError<any>('hasRestrictionEtabDet', { data: null }))
      );
  }

  /**
   * Check if there are two identique interventions introduced the same day
   * @returns encapsulated boolean as `Observable`
   */
  getDoubleInterventionsHistoryPage(id: number, agentWorkshopCode: string, exitDate: Date): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('agentWorkshopCode', `${agentWorkshopCode}`);
    parameters = parameters.set('exitDate', `${exitDate}`);
    /*if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }*/
    return this.api.get<ApiResponse<boolean>>(`/references/double-intervention/${id}`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getDoubleInterventionsHistoryPage', { data: null }))
      );
  }

  /**
   * Retrieve SystemWorkPlate list by pls param
   * @param pls serial
   */
  getSystemWorkPlateList(pls: string): Observable<ApiResponse<SystematicWorkPlate[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('pls', `${pls}`);

    return this.api.get<ApiResponse<SystematicWorkPlate[]>>(`/references/systematic-work-plate`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getSystemWorkPlateList', { data: [] }))
      );
  }

  /**
   * create SystematicWorkPlateUpdate system type record
   * @param element SystematicWorkPlateUpdate type  datas
   */
  public createListSystematicWorkPlateUpdate(element: SystematicWorkPlateUpdate[]): Observable<ApiResponse<SystematicWorkPlateUpdate[]>> {
    return this.api.post<ApiResponse<SystematicWorkPlateUpdate[]>>(`/references/systematic-work-plate-update`, element)
      .pipe(
        catchError(this.handleError<any>('createListSystematicWorkPlateUpdate', null))
      );
  }

  /**
   * Retrieve OverhaulPlate list by pls param
   * @param pls serial
   */
  getOverhaulPlateList(pls: string): Observable<ApiResponse<OverhaulPlate[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('pls', `${pls}`);

    return this.api.get<ApiResponse<OverhaulPlate[]>>(`/references/overhaul-plate`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getOverhaulPlateList', { data: [] }))
      );
  }

  /**
   * create OverhaulPlateUpdate system type record
   * @param element OverhaulPlateUpdate type  datas
   */
  public createListOverhaulPlateUpdate(element: OverhaulPlateUpdate[]): Observable<ApiResponse<OverhaulPlateUpdate[]>> {
    return this.api.post<ApiResponse<OverhaulPlateUpdate[]>>(`/references/overhaul-plate-update`, element)
      .pipe(
        catchError(this.handleError<any>('createListOverhaulPlateUpdate', null))
      );
  }

  /**
   * create OverhaulPlate system type record
   * @param element OverhaulPlate type  datas
   */
  public createOverhaulPlate(element: OverhaulPlate): Observable<ApiResponse<OverhaulPlate>> {
    return this.api.post<ApiResponse<OverhaulPlate>>(`/references/overhaul-plate`, element)
      .pipe(
        catchError(this.handleError<any>('createOverhaulPlate', null))
      );
  }

  /**
   * Create the list of Maintenance Help Codes associated with the wagon
   * @param element wagon id datas
   */
  public createIglCam(element: any): Observable<ApiResponse<IglCam[]>> {
    return this.api.post<ApiResponse<IglCam[]>>(`/references/igl-cam`, element)
      .pipe(
        catchError(this.handleError<any>('createIglCam', null))
      );
  }


  /**
   * Create the list of Igloo defect cuu
   * @param element wagon id datas
   */
  public createIglDefautCuu(element: any): Observable<ApiResponse<IglDefautCuu[]>> {
    return this.api.post<ApiResponse<IglDefautCuu[]>>(`/references/igl-default-cuu`, element)
      .pipe(
        catchError(this.handleError<any>('createIglDefautCuu', null))
      );
  }

  /**
   * Create EventsHistory system type record
   * @param element EventsHistory data
   */
  public createEventsHistory(element: EventsHistory): Observable<ApiResponse<EventsHistory>> {
    return this.api.post<ApiResponse<EventsHistory>>(`/references/event-history`, element)
      .pipe(
        catchError(this.handleError<any>('createEventsHistory', null))
      );
  }

  /**
   * Create EventsHistoryUpdate system type record
   * @param element EventsHistory data
   */
  public createEventsHistoryUpdate(element: EventsHistoryUpdate): Observable<ApiResponse<EventsHistoryUpdate>> {
    return this.api.post<ApiResponse<EventsHistoryUpdate>>(`/references/event-history/update`, element)
      .pipe(
        catchError(this.handleError<any>('createEventsHistoryUpdate', null))
      );
  }

  /*
   * Create a list of cars in or out of the facility for less than 31 days.
   * @param element WagonUpdate datas
   */
  public createWagonProduction(element: any, mex: string, inventory: string): Observable<ApiResponse<WagonProduction>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inventory', `${inventory}`);
    return this.api.post<ApiResponse<WagonProduction>>(`/references/igl-wagon`, element, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('createWagonProduction', null))
      );
  }

  /**
   * Search the list of CAM / C.U.U
   * @param id cam default id
   */
  public findCamsDefaults(id: number, monitoringWork: boolean, statusToDO?: boolean, statusRealized?: boolean)
    : Observable<ApiResponse<CamsDefault[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('monitoringWork', `${monitoringWork}`);
    if (statusToDO !== undefined) {
      parameters = parameters.set('statusToDO', `${statusToDO}`);
    }
    if (statusRealized !== undefined) {
      parameters = parameters.set('statusRealized', `${statusRealized}`);
    }
    return this.api.get<CamsDefault[]>(`/references/cams-default/${id}`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findCamsDefaults', { data: [] }))
      );
  }

  /**
   * Search the list of CAM / C.U.U
   * @param id cams default reduced id
   */
  public findCamsDefaultsReducedMAJ(id: number, mex: string, reason: string, codeEtab: string, expertiseId: number)
    : Observable<ApiResponse<CamsDefault[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('reason', `${reason}`);
    parameters = parameters.set('codeEtab', `${codeEtab}`);
    parameters = parameters.set('expertiseId', `${expertiseId}`);

    return this.api.get<CamsDefault[]>(`/references/cams-default-reduced/${id}`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findCamsDefaultsReducedMAJ', { data: [] }))
      );
  }

  /**
   * Search the list of periodicity
   * @param id wagon's id
   * @param opeCode operation code
   * @param tmajCode update type code
   */
  public findPeriodicity(id?: number, opeCode?: string, tmajCode?: string): Observable<ApiResponse<OverhaulFrequency[]>> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
    if (id !== undefined) {
      parameters = parameters.set('wagId', `${id}`);
    }
    if (opeCode !== undefined) {
      parameters = parameters.set('opeCode', `${opeCode}`);
    }
    if (tmajCode !== undefined) {
      parameters = parameters.set('tmajCode', `${tmajCode}`);
    }
    return this.api.get<OverhaulFrequency[]>(`/references/periodicity`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findPeriodicity', { data: [] }))
      );
  }

  /**
   * Search the list of Establishment
   * @param tmajCode update type code
   * @param opeCode operation code
   * @param workshop workshop code
   */
  public findEstablishment(tmajCode: string, opeCode?: string, workshop?: string): Observable<ApiResponse<Workshop[]>> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    parameters = parameters.set('tmajCode', `${tmajCode}`);

    if (opeCode !== undefined) {
      parameters = parameters.set('opeCode', `${opeCode}`);
    }
    if (workshop !== undefined) {
      parameters = parameters.set('workshop', `${workshop}`);
    }
    return this.api.get<Workshop[]>(`/references/establishment`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findEstablishment', { data: [] }))
      );
  }

  /**
   * Search the list of Establishment ATS
   * @param tmajCode update type code
   * @param opeCode operation code
   * @param workshop workshop code
   */
  public findEstablishmentATS(tmajCode: string, opeCode?: string, workshop?: string): Observable<ApiResponse<Workshop[]>> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    parameters = parameters.set('tmajCode', `${tmajCode}`);

    if (opeCode !== undefined) {
      parameters = parameters.set('opeCode', `${opeCode}`);
    }
    if (workshop !== undefined) {
      parameters = parameters.set('workshop', `${workshop}`);
    }
    return this.api.get<Workshop[]>(`/references/establishment-ats`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findEstablishmentATS', { data: [] }))
      );
  }

  /**
   * Search the list of Normal REV Rank
   * @param tmajCode update type code
   * @param opeCode operation code
   */
  public findNormalREVRank(wphy: number, tmajCode: string, opeCode?: string): Observable<ApiResponse<string[]>> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    parameters = parameters.set('wphy', `${wphy}`);
    parameters = parameters.set('tmajCode', `${tmajCode}`);

    if (opeCode !== undefined) {
      parameters = parameters.set('opeCode', `${opeCode}`);
    }

    return this.api.get<string[]>(`/references/normal-REV-rank`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findNormalREVRank', { data: [] }))
      );
  }

  /**
   * Search the list of Nature REV Normal
   * @param tmajCode update type code
   * @param opeCode operation code
   */
  public findNatureREVNormal(tmajCode: string, opeCode?: string): Observable<ApiResponse<string[]>> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    parameters = parameters.set('tmajCode', `${tmajCode}`);

    if (opeCode !== undefined) {
      parameters = parameters.set('opeCode', `${opeCode}`);
    }

    return this.api.get<string[]>(`/references/nature-REV-normal`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findNatureREVNormal', { data: [] }))
      );
  }

  /**
   * Search the list REV Rank Other than Normal
   * @param tmajCode update type code
   * @param opeCode operation code
   * @param wphy wagon's id
   */
  public findREVRankOtherNormal(tmajCode: string, opeCode?: string, wphy?: number): Observable<ApiResponse<string[]>> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    parameters = parameters.set('tmajCode', `${tmajCode}`);

    if (opeCode !== undefined) {
      parameters = parameters.set('opeCode', `${opeCode}`);
    }

    if (wphy !== undefined) {
      parameters = parameters.set('wphy', `${wphy}`);
    }

    return this.api.get<string[]>(`/references/REV-rank-other-normal`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findREVRankOtherNormal', { data: [] }))
      );
  }


  /**
   * Search the list Nature REV Other than Normal
   * @param opeCode operation code
   */
  public findNatureREVOtherNormal(opeCode: string): Observable<ApiResponse<string[]>> {
    let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });

    parameters = parameters.set('opeCode', `${opeCode}`);

    return this.api.get<string[]>(`/references/nature-REV-other-normal`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findNatureREVOtherNormal', { data: [] }))
      );
  }

  /**
   * Search the information against the drop-down list "Not expected from normal review
   * @param wagonId wagon's id
   * @param natREVOtherNormal Rev nature other than normal
   * @param tmajCode update type code
   */
  public findNotExpectedNormalOverhaul(wagonId: number, natREVOtherNormal?: string, tmajCode?: string)
    : Observable<ApiResponse<OverhaulFrequency[]>> {
    let parameters: HttpParams = new HttpParams();

    if (wagonId !== undefined) {
      parameters = parameters.set('wPhyId', `${wagonId}`);
    }

    if (natREVOtherNormal !== undefined) {
      parameters = parameters.set('natREVOtherNormal', `${natREVOtherNormal}`);
    }

    if (tmajCode !== undefined) {
      parameters = parameters.set('tmajCode', `${tmajCode}`);
    }

    return this.api.get<OverhaulFrequency[]>(`/references/not-expected-normal-overhaul`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findNotExpectedNormalOverhaul', { data: [] }))
      );
  }


  /**
   * Search the list of valid codes and descriptions of availability in relation to the maintenance operation
   * @param maintenanceOperation maintenance operation code
   */
  public getTrailedMaterialStatusList(): Observable<ApiResponse<TrailedMaterialStatus[]>> {

    return this.api.get<TrailedMaterialStatus[]>(`/references/trailed-material-status`)
      .pipe(
        catchError(this.handleError<any>('getTrailedMaterialStatusList', { data: [] }))
      );
  }

  /**
   * Search the list of valid codes and descriptions of availability in relation to the maintenance operation
   * @param maintenanceOperation maintenance operation code
   */
  public findTrailedMaterialStatusByMaintenanceOperation(maintenanceOperation: string): Observable<ApiResponse<TrailedMaterialStatus[]>> {

    return this.api.get<TrailedMaterialStatus[]>(`/references/trailed-material-status/${maintenanceOperation}`)
      .pipe(
        catchError(this.handleError<any>('findTrailedMaterialStatusByMaintenanceOperation', { data: [] }))
      );
  }

  /**
   * Search the list of repartition codes
   * @param wagonId wagon's id
   * @param inventoryCode inventory code
   */
  public findRepartitionCodes(wagonId: number, inventoryCode: string): Observable<ApiResponse<RepartitionCode[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wagonId}`);
    parameters = parameters.set('inventoryCode', `${inventoryCode}`);
    return this.api.get<RepartitionCode[]>(`/references/repartition-codes`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findRepartitionCodes', { data: [] }))
      );
  }

  /**
   * Search the list of ConstructionType
   * @param wagonId wagon's id
   * @param inventoryCode inventory code
   */
  public findConstructionType(wagonId: number, inventoryCode: string): Observable<ApiResponse<ConstructionType[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wagonId}`);
    parameters = parameters.set('inventoryCode', `${inventoryCode}`);
    return this.api.get<ConstructionType[]>(`/references/types-of-construction`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findConstructionType', { data: [] }))
      );
  }

  /**
   * Search the list of StatisticalCode
   * @param wagonId wagon's id
   * @param inventoryCode inventory code
   */
  public findStatisticalCode(wagonId: number, inventoryCode: string): Observable<ApiResponse<StatisticalCode[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wagonId}`);
    parameters = parameters.set('inventoryCode', `${inventoryCode}`);
    return this.api.get<StatisticalCode[]>(`/references/statistical-codes`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findStatisticalCode', { data: [] }))
      );
  }

  /**
   * Search the list of modified-trademarks
   * @param wagonId wagon's id
   * @param inventoryCode inventory code
   */
  public findModifiedTrademarks(wagonId: number, inventoryCode: string): Observable<ApiResponse<ModifiedTrademark[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wagonId}`);
    parameters = parameters.set('inventoryCode', `${inventoryCode}`);
    return this.api.get<ModifiedTrademark[]>(`/references/modified-trademarks`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findModifiedTrademarks', { data: [] }))
      );
  }

  /**
   * Search the list of NoRIV
   * @param nRIVCode NoRIV Code
   */
  public findNoRIVReason(nRIVCode: string): Observable<ApiResponse<NoRIV[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('NRIVCode', `${nRIVCode}`);
    return this.api.get<NoRIV[]>(`/references/reason-non-RIV`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findNoRIVReason', { data: [] }))
      );
  }

  /**
   * Search the list of SpecialAgreement
   */
  public findSpecialAgreement(): Observable<ApiResponse<SpecialAgreement[]>> {

    return this.api.get<SpecialAgreement[]>(`/references/special-agreement`)
      .pipe(
        catchError(this.handleError<any>('findSpecialAgreement', { data: [] }))
      );
  }

  /**
   * Search the list of UsageRestriction
   * @param mex wagon's mex
   * @param inv inventory code
   */
  public findUsageRestriction(mex: string, inv: string): Observable<ApiResponse<UsageRestriction[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    parameters = parameters.set('inv', `${inv}`);
    return this.api.get<UsageRestriction[]>(`/references/use-restrictions`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findUsageRestriction', { data: [] }))
      );
  }

  /**
   * Search the list of PlateChangeReason
   * @param wphy wagon's id
   */
  public getReasonModification(wphy: number): Observable<ApiResponse<ReasonModification>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wphy}`);
    return this.api.get<ReasonModification>(`/references/reason-modification`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getReasonModification', { data: [] }))
      );
  }

  /**
   * Search the list of PlateChangeReason
   * @param wphy wagon's id
   */
   public getReasonModificationByDate(wphy: number, date: string): Observable<ApiResponse<ReasonModification>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wphy}`);
    parameters = parameters.set('sDate', `${date}`);
    return this.api.get<ReasonModification>(`/references/reason-modification-date`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getReasonModificationByDate', { data: [] }))
      );
  }

  /**
   * Search the list of UsageRestriction
   * @param wagCaractId tranche's id
   */
  public checkDataConsistency(repartitionCode: string, constructionType: string, wCarTrId: number): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('repartitionCode', `${repartitionCode}`);
    parameters = parameters.set('constructionType', `${constructionType}`);
    parameters = parameters.set('wCarTrId', `${wCarTrId}`);
    return this.api.get<boolean>(`/references/data-consistency-check`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findUsageRestriction', { data: null }))
      );
  }

  /**
   * Check if la desurisation is not showed or is disabled (null not show, false is showed by disabled)
   * @param wagId wagon's id
   */
  public desurizationIsEnabled(wagId: number): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagId', `${wagId}`);

    return this.api.get<boolean>(`/references/desurization`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('desurizationIsEnabled', { data: null }))
      );
  }

  /**
   * Get the OverhaulPlateUpdate related to wagId
   * @param wagId wagon's id
   */
  public findOverhaulPlateUpdate(wagonId: number): Observable<ApiResponse<OverhaulPlateUpdate[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wagonId}`);

    return this.api.get<OverhaulPlateUpdate[]>(`/references/overhaul-plate-update`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('findOverhaulPlateUpdate', { data: null }))
      );
  }

  /**
   * Service qui retourn Contrôle 13: RG_MAJ_003_4_2_13 : Chaque code roue doit être compatible avec au moins l’un
   * des codes de la zone ‘Essieux’ (présence du couple Code Essieu / Code Roue dans la table RT_ESS_ROUE avec Dates D/F valides
   */
  public isWheelCompatibleWithAxle(wheelCode: string, element: Axle[]): Observable<ApiResponse<boolean>> {
    return this.api.get<boolean>(`/references/wheel-compatible-axle/${wheelCode}`, { body: element })
      .pipe(
        catchError(this.handleError<any>('isWheelCompatibleWithAxle', { data: null }))
      );
  }

  /**
   * Service qui retourn Contrôle 14: RG_MAJ_003_4_2_14 : Chaque code boîte doit être compatible avec au moins
   * l’un des codes de la zone ‘Essieux’ (présence du couple Code Essieu / Code Boîte dans la table RT_ESS_BOIT avec Dates D/F valides
   */
  public isBoxCompatibleWithAxle(boxCode: string, element: Axle[]): Observable<ApiResponse<boolean>> {
    return this.api.get<boolean>(`/references/box-compatible-axle/${boxCode}`, { body: element })
      .pipe(
        catchError(this.handleError<any>('isBoxCompatibleWithAxle', { data: null }))
      );
  }

  /**
   * Service qui retourn Contrôle 15: RG_MAJ_003_4_2_15 : Chaque code essieu doit être compatible avec au moins
   * l’un des codes de la zone ‘Roues (présence du couple Code Essieu / Code Roue dans la table RT_ESS_BOIT avec Dates D/F valides
   */
  public isAxleCompatibleWithWheel(axleCode: string, element: Wheel[]): Observable<ApiResponse<boolean>> {
    return this.api.get<boolean>(`/references/axle-compatible-wheel/${axleCode}`, { body: element })
      .pipe(
        catchError(this.handleError<any>('isAxleCompatibleWithWheel', { data: null }))
      );
  }

  /**
   * Service qui retourn RG_MAJ_003_4_2_16 : Chaque code essieu doit être compatible avec au moins l’un des codes de la zone ‘Boîte’
   * (présence du couple Code Essieu / Code Boîte dans la table RT_ESS_BOIT avec Dates D/F valides
   */
  public isAxleCompatibleWithBox(axleCode: string, element: Box[]): Observable<ApiResponse<boolean>> {
    return this.api.get<boolean>(`/references/axle-compatible-box/${axleCode}`, { body: element })
      .pipe(
        catchError(this.handleError<any>('isAxleCompatibleWithBox', { data: null }))
      );
  }

  /**
   * Service qui retourn RG_MAJ_003_4_2_17 : chaque code essieu doit être compatible avec au moins l’un des codes de la zone ‘Bogie
   * (présence du couple Code Essieu / Code Bogie dans la table RT_BOG_ESS avec Dates D/F valides
   */
  public isAxleCompatibleWithBogie(axleCode: string, element: Bogie[]): Observable<ApiResponse<boolean>> {
    return this.api.get<boolean>(`/references/axle-compatible-bogie/${axleCode}`, { body: element })
      .pipe(
        catchError(this.handleError<any>('isAxleCompatibleWithBogie', { data: null }))
      );
  }

  /**
   * Service qui retourn RG_MAJ_003_4_2_18 : chaque code bogie doit être compatible avec les autres codes de la zone ‘Bogie
   * (présence du couple Code Bogie / Code Bogie dans la table RT_BOG_BOG avec Dates D/F valides
   */
  public isBogieCompatibleWithBogie(bogieCode: string, element: Bogie[]): Observable<ApiResponse<boolean>> {
    return this.api.get<boolean>(`/references/bogie-compatible-bogie/${bogieCode}`, { body: element })
      .pipe(
        catchError(this.handleError<any>('isBogieCompatibleWithBogie', { data: null }))
      );
  }

  /**
   * Service qui retourn RG_MAJ_003_4_2_19 : chaque code bogie doit être compatible avec au moins l’un des codes de la zone ‘Essieux’
   * (présence du couple Code Essieu / Code Bogie dans la table RT_BOG_ESS avec Dates D/F valides
   */
  public isBogieCompatibleWithAxle(bogieCode: string, element: Axle[]): Observable<ApiResponse<boolean>> {
    return this.api.get<boolean>(`/references/bogie-compatible-axle/${bogieCode}`, { body: element })
      .pipe(
        catchError(this.handleError<any>('isBogieCompatibleWithAxle', { data: null }))
      );
  }

  /**
   * update MaintenancePlanUpdate data
   * @param mex the wagon's mex
   * @param workshop workshop code
   * @param exit updated exit date
   */
  public deleteCamsDefaults(id: number, code: string, type: string): Observable<any> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('code', `${code}`);
    parameters = parameters.set('type', `${type}`);
    return this.api.delete<any>(`/references/cams-default/${id}`, { params: parameters, observe: 'response' })
      .pipe(
        catchError(this.handleError<any>('deleteCamsDefaults', { data: null }))
      );
  }

  /**
   * Retrieve MaintenanceAid list
   * @returns encapsulated MaintenanceAid types list as `Observable`
   */
  public getMaintenanceAids(search?: string, pageable?: Pageable, isValid?: boolean): Observable<ApiResponse<MaintenanceAid[]>> {
    let parameters: HttpParams = new HttpParams();
    if (search !== undefined) {
      parameters = parameters.set('search', `${search}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<MaintenanceAid[]>>(`/references/maintenance-aid`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getMaintenanceAids', { data: [] }))
      );
  }

  /**
   * Retrieve Axle Maintenance list
   * @returns encapsulated Axle Maintenance list as `Observable`
   */
  public getAxleMaintenances(): Observable<ApiResponse<AxleMaintenance[]>> {
    return this.api.get<ApiResponse<AxleMaintenance[]>>(`/references/axle-maintenances`)
      .pipe(
        catchError(this.handleError<any>('getAxleMaintenances', { data: [] }))
      );
  }

  /**
   * Retrieve owner list
   * @returns encapsulated owner list as `Observable`
   */
  public getOwners(): Observable<ApiResponse<Owner[]>> {
    return this.api.get<ApiResponse<Owner[]>>(`/references/owners`)
      .pipe(
        catchError(this.handleError<any>('getOwners', { data: [] }))
      );
  }

  /**
   * Retrieve company list
   * @returns encapsulated company list as `Observable`
   */
  public getCompanies(): Observable<ApiResponse<Company[]>> {
    return this.api.get<ApiResponse<Company[]>>(`/references/companies`)
      .pipe(
        catchError(this.handleError<any>('getCompanies', { data: [] }))
      );
  }

  /**
   * Retrieve steel list
   * @returns encapsulated steel list as `Observable`
   */
  public getSteels(): Observable<ApiResponse<Steel[]>> {
    return this.api.get<ApiResponse<Steel[]>>(`/references/steels`)
      .pipe(
        catchError(this.handleError<any>('getSteels', { data: [] }))
      );
  }

  /**
   * Retrieve maker list
   * @returns encapsulated maker list as `Observable`
   */
  public getMakers(): Observable<ApiResponse<Maker[]>> {
    return this.api.get<ApiResponse<Maker[]>>(`/references/makers`)
      .pipe(
        catchError(this.handleError<any>('getMakers', { data: [] }))
      );
  }

  /**
   * Retrieve disc type list
   * @returns encapsulated disc type list as `Observable`
   */
  public getDiscTypes(): Observable<ApiResponse<DiscType[]>> {
    return this.api.get<ApiResponse<DiscType[]>>(`/references/disc-types`)
      .pipe(
        catchError(this.handleError<any>('getDiscTypes', { data: [] }))
      );
  }

  /**
   * Retrieve wheel type list
   * @returns encapsulated wheel type list as `Observable`
   */
  public getWheelTypes(): Observable<ApiResponse<WheelType[]>> {
    return this.api.get<ApiResponse<WheelType[]>>(`/references/wheel-types`)
      .pipe(
        catchError(this.handleError<any>('getWheelTypes', { data: [] }))
      );
  }

  /**
   * Retrieve box type list
   * @returns encapsulated box type list as `Observable`
   */
  public getBoxTypes(): Observable<ApiResponse<BoxType[]>> {
    return this.api.get<ApiResponse<BoxType[]>>(`/references/box-types`)
      .pipe(
        catchError(this.handleError<any>('getBoxTypes', { data: [] }))
      );
  }

  /**
   * Retrieve available tip list
   * @returns encapsulated available tip list as `Observable`
   */
  public getAvailableTips(): Observable<ApiResponse<AvailableTip[]>> {
    return this.api.get<ApiResponse<AvailableTip[]>>(`/references/available-tips`)
      .pipe(
        catchError(this.handleError<any>('getAvailableTips', { data: [] }))
      );
  }

  /**
   * Retrieve bearing type list
   * @returns encapsulated bearing type list as `Observable`
   */
  public getBearingTypes(): Observable<ApiResponse<BearingType[]>> {
    return this.api.get<ApiResponse<BearingType[]>>(`/references/bearing-types`)
      .pipe(
        catchError(this.handleError<any>('getBearingTypes', { data: [] }))
      );
  }

  /**
   * Retrieve grease type list
   * @returns encapsulated grease type list as `Observable`
   */
  public getGreaseTypes(): Observable<ApiResponse<GreaseType[]>> {
    return this.api.get<ApiResponse<GreaseType[]>>(`/references/grease-types`)
      .pipe(
        catchError(this.handleError<any>('getGreaseTypes', { data: [] }))
      );
  }

  /**
   * Retrieve Gcu list
   * @returns encapsulated Gcu types list as `Observable`
   */
  public getGcus(search?: string, pageable?: Pageable, isValid?: boolean): Observable<ApiResponse<Gcu[]>> {
    let parameters: HttpParams = new HttpParams();
    if (search !== undefined) {
      parameters = parameters.set('search', `${search}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<Gcu[]>>(`/references/gcu`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getGcus', { data: [] }))
      );
  }

  /**
   * Retrieve states list
   * @returns encapsulated states list as `Observable`
   */
  public getStateJobs(): Observable<ApiResponse<StateJob[]>> {
    return this.api.get<ApiResponse<StateJob[]>>(`/references/state-job`)
      .pipe(
        catchError(this.handleError<any>('getStateJobs', { data: [] }))
      );
  }

  public getServerUploadLimits(): Observable<ApiResponse<{maxFileSize: string, maxRequestSize: string}>> {
    return this.api.get<ApiResponse<{maxFileSize: string, maxRequestSize: string}>>(`/references/upload-limits`)
    .pipe(
      catchError(this.handleError<any>('getServerUploadLimits', {data: null}))
    );
  }

  getReformModels(): Observable<ApiResponse<ReformModel[]>> {
    return this.api.get<ApiResponse<ReformModel[]>>(`/references/reform-models`)
      .pipe(
        catchError(this.handleError<any>('getReformModels', { data: [] }))
      );
  }

  getReformReasons(): Observable<ApiResponse<ReformReason[]>> {
    return this.api.get<ApiResponse<ReformReason[]>>(`/references/reform-reasons`)
      .pipe(
        catchError(this.handleError<any>('getReformReasons', { data: [] }))
      );
  }

  getReformTypes(): Observable<ApiResponse<ReformType[]>> {
    return this.api.get<ApiResponse<ReformType[]>>(`/references/reform-types`)
      .pipe(
        catchError(this.handleError<any>('getReformTypes', { data: [] }))
      );
  }

  getValidReformModels(): Observable<ApiResponse<ReformModel[]>> {
    return this.api.get<ApiResponse<ReformModel[]>>(`/references/valid-reform-models`)
      .pipe(
        catchError(this.handleError<any>('getValidReformModels', { data: [] }))
      );
  }

  getValidReformReasons(): Observable<ApiResponse<ReformReason[]>> {
    return this.api.get<ApiResponse<ReformReason[]>>(`/references/valid-reform-reasons`)
      .pipe(
        catchError(this.handleError<any>('getValidReformReasons', { data: [] }))
      );
  }

  getValidReformTypes(): Observable<ApiResponse<ReformType[]>> {
    return this.api.get<ApiResponse<ReformType[]>>(`/references/valid-reform-types`)
      .pipe(
        catchError(this.handleError<any>('getValidReformTypes', { data: [] }))
      );
  }

  getWagonStatusComplements(): Observable<ApiResponse<WagonStatusComplement[]>> {
    return this.api.get<ApiResponse<WagonStatusComplement[]>>(`/references/status-complements`)
      .pipe(
        catchError(this.handleError<any>('getWagonStatusComplements', { data: [] }))
      );
  }

  /**
   * Search the list of repartition codes
   */
   public getRepartitionCodes(): Observable<ApiResponse<RepartitionCode[]>> {
    return this.api.get<RepartitionCode[]>(`/references/repart-codes`)
      .pipe(
        catchError(this.handleError<any>('getRepartitionCodes', { data: [] }))
      );
  }
  /**
   * Search the list of statistical codes
   */
   public getStatisticalCodes(): Observable<ApiResponse<StatisticalCode[]>> {
    return this.api.get<StatisticalCode[]>(`/references/stat-codes`)
      .pipe(
        catchError(this.handleError<any>('getStatisticalCodes', { data: [] }))
      );
  }

  public getReformModelComplements(isValid?: boolean, pageable?: Pageable): Observable<ApiResponse<ReformModelComplement[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ReformModelComplement[]>(`/references/reform-model-complements`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getReformModelComplements', { data: [] }))
      );
  }
  
  public getMaintenanceOperations(isValid?: boolean): Observable<ApiResponse<MaintenanceOperation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<MaintenanceOperation[]>(`/references/maintenance-operations`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getMaintenanceOperations', { data: [] }))
      );
  }
  public getSubseries(isValid?: boolean): Observable<ApiResponse<Subseries[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<Subseries[]>(`/references/subseries`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getSubseries', { data: [] }))
      );
  }

  public getOperations(isValid?: boolean): Observable<ApiResponse<Operation[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<Operation[]>(`/references/operations`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getOperations', { data: [] }))
      );
  }

  getRids(isValid?: boolean): Observable<ApiResponse<Rid[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<Rid[]>(`/references/rids`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getRids', { data: [] }))
      );
  }
  /**
   * Search the list of incidents types
   */
  public getIncidentTypes(): Observable<ApiResponse<IncidentType[]>> {
    return this.api.get<IncidentType[]>(`/references/incident-types`)
      .pipe(
        catchError(this.handleError<any>('getIncidentTypes', { data: [] }))
      );
  }

  /**
   * Search the list of incidents types
   */
  public getValidIncidentTypes(): Observable<ApiResponse<IncidentType[]>> {
    return this.api.get<IncidentType[]>(`/references/valid-incident-types`)
      .pipe(
        catchError(this.handleError<any>('getValidIncidentTypes', { data: [] }))
      );
  }
  /**
   * Retrieve the value from TECH_VARIABLE
   * Ce service retourne la valeur associé dans la table Tech_Variable
   * @returns encapsulated states list as `Observable`
   */
   public getTechVariable(variableName: string): Observable<ApiResponse<TechVariable>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('variableName', `${variableName}`);
    return this.api.get<ApiResponse<TechVariable>>(`/references/tech-variable`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getTechVariable', { data: [] }))
      );
  }

  public getConstructiveRules(): Observable<ApiResponse<ConstructiveRule[]>> {
    return this.api.get<ConstructiveRule[]>(`/references/constructives-rules`)
      .pipe(
        catchError(this.handleError<any>('getConstructiveRules', { data: [] }))
      );
  }
  public getRecomAuxTanks(): Observable<ApiResponse<RecomAuxiliaryTank[]>> {
    return this.api.get<RecomAuxiliaryTank[]>(`/references/recom-auxiliary-tanks`)
      .pipe(
        catchError(this.handleError<any>('getRecomAuxTanks', { data: [] }))
      );
  }
  public getTankMakers(): Observable<ApiResponse<TankMaker[]>> {
    return this.api.get<TankMaker[]>(`/references/tank-makers`)
      .pipe(
        catchError(this.handleError<any>('getTankMakers', { data: [] }))
      );
  }

  public getCodeTableList(value: {'application': Application, 'type': string, 'search': string,
                                  'filter': string, 'order': string}): Observable<ApiResponse<CodTable[]>> {
    let parameters: HttpParams = new HttpParams();
    if (value.application ) {
      parameters = parameters.set('application', `${value.application.code}`);
    }
    if (value.type ) {
      parameters = parameters.set('type', `${value.type}`);
    }
    if (value.search ) {
      parameters = parameters.set('search', `${value.search}`);
    }
    if (value.filter ) {
      parameters = parameters.set('filter', `${value.filter}`);
    }
    if (value.order ) {
      parameters = parameters.set('order', `${value.order}`);
    }
    return this.api.get<CodTable[]>(`/references/code-tables`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getCodeTableList', { data: [] }))
      );
  }

  public getCodeTable(id: number): Observable<ApiResponse<CodTable>> {
    return this.api.get<CodTable[]>(`/references/code-tables/${id}`)
      .pipe(
        catchError(this.handleError<any>('getCodeTable', { data: null }))
      );
  }
  public getCodeTableSelect(id: number, col: number): Observable<ApiResponse<{code: string, label: string}[]>> {
    return this.api.get<{code: string, label: string}[]>(`/references/code-tables/${id}/select/${col}`)
      .pipe(
        catchError(this.handleError<any>('getCodeTableSelect', { data: [] }))
      );
  }
  public getCodeTableContent(id: number): Observable<ApiResponse<any[]>> {
    return this.api.get<any[]>(`/references/code-tables/${id}/all`)
      .pipe(
        catchError(this.handleError<any>('getCodeTableContent', { data: [] }))
      );
  }
  public addCodeTableRecord(id: number, value: any): Observable<ApiResponse<any>> {
    return this.api.post<any>(`/references/code-tables/${id}`, value)
      .pipe(
        catchError(this.handleError<any>('addCodeTableRecord', { data: null }))
      );
  }

  public getApplicationList(): Observable<ApiResponse<Application[]>> {
    return this.api.get<Application[]>(`/references/applications`)
      .pipe(
        catchError(this.handleError<any>('getApplicationList', { data: [] }))
      );
  }
  public updateCodeTable(id: number, value: {motivation: string, report: boolean, updateList: any[]}): Observable<ApiResponse<any>> {
    return this.api.post<any>(`/references/code-tables/${id}`, value)
      .pipe(
        catchError(this.handleError<any>('updateCodeTable', { data: null }))
      );
  }

  
  public getRefWorks(isValid?: boolean): Observable<ApiResponse<RefWork[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<RefWork[]>(`/references/ref-works`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getRefWorks', { data: [] }))
      );
  }
  
  public getForfaits(isValid?: boolean): Observable<ApiResponse<Forfait[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<Forfait[]>(`/references/forfaits`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getForfaits', { data: [] }))
      );
  }
  
  public getNonRefWMs(type?: string, workshopCode?: string): Observable<ApiResponse<NonRefWM[]>> {
    let parameters: HttpParams = new HttpParams();
    if (type !== undefined) {
      parameters = parameters.set('type', `${type}`);
    }
    if (workshopCode !== undefined) {
      parameters = parameters.set('workshop', `${workshopCode}`);
    }
    return this.api.get<RefMaterial[]>(`/references/non-ref-wms`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getNonRefWMs', { data: [] }))
      );
  }
  
  public getRefMaterials(isValid?: boolean, isWagon?: number): Observable<ApiResponse<RefMaterial[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    
    if (isWagon !== undefined) {
      parameters = parameters.set('isWagon', `${isWagon}`);
    }
    return this.api.get<RefMaterial[]>(`/references/ref-materials`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getRefMaterials', { data: [] }))
      );
  }

  public getConveyedProducts(isValid?: boolean): Observable<ApiResponse<ConveyedProduct[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ConveyedProduct[]>(`/references/conveyed-products`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getConveyedProducts', { data: [] }))
      );
  }

  public getWorkTimeAuthorizations(isValid?: boolean): Observable<ApiResponse<WorkTimeAuthorization[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<WorkTimeAuthorization[]>(`/references/work-time-authorizations`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getWorkTimeAuthorizations', { data: [] }))
      );
  }
  
  getEmergencyDegrees(isValid?: boolean): Observable<ApiResponse<EmergencyDegree[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<EmergencyDegree[]>(`/references/emergency-degrees`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getEmergencyDegrees', { data: [] }))
      );
  }

  getMaintenanceContracts(isValid?: boolean): Observable<ApiResponse<MaintenanceContract[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<MaintenanceContract[]>(`/references/maintenance-contracts`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getMaintenanceContracts', { data: [] }))
      );
  }

  getCommercialContracts(isValid?: boolean): Observable<ApiResponse<CommercialContract[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<CommercialContract[]>(`/references/commercial-contracts`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getCommercialContracts', { data: [] }))
      );
  }

  getOverhaulPlateChangeReasons(isValid?: boolean): Observable<ApiResponse<PlateChangeReason[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<PlateChangeReason[]>(`/references/plate-change-reasons`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getOverhaulPlateChangeReasons', { data: [] }))
      );
  }

  getFunctionalControls(isValid?: boolean): Observable<ApiResponse<FunctionalControl[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<FunctionalControl[]>(`/references/functional-controls`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getFunctionalControls', { data: [] }))
      );
  }
  downloadCodeTableUpdatePDF(id: any, value: { motivation: string; report: boolean; updateList: any[]; }) {
    return this.api.post<{blob: Blob, filename: string}>(`/references/code-tables/${id}/update-pdf`, value, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadCodeTableUpdatePDF', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'TBR004.pdf'};
      })
    );
  }

  downloadCodeTableRecordPDF(id: any, value: { value: any[], relations: any[] }) {
    return this.api.post<{blob: Blob, filename: string}>(`/references/code-tables/${id}/record-pdf`, value, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadCodeTableRecordPDF', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'TBR003.pdf'};
      })
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
