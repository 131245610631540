/** API model:Table: ELEMENT_OPF */
export class BrakeMainOrganElement {
  /** ELTOPF_NUM_OPF VARCHAR2(10 BYTE) */
  number: string;
  /** ELTOPF_CPT_PK NUMBER(3,0) */
  counter: number;
  /** ELTOPF_DH_DERN_REV_OPF DATE */
  review: Date;
  /** ELTOPF_PLS_CODE_FK */
  wagonSerialCode: string;
  /** ELTOPF_TYPE_ARES */
  aresType: string;
}
