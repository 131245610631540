import { Directive, OnInit, Input, forwardRef } from '@angular/core';
import { Validator, ValidatorFn, AbstractControl, NG_VALIDATORS } from '@angular/forms';

/**
 * `ValidatorFn` that parses a flatpickr input value & validate number of parsed element (dates) against `maxLength` value
 * @param maxLength max length of date pickr input
 */
export function maxDateLength(maxLength: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const val = control.value;
    if (!val) {
      return null;
    }
    if ( val instanceof Array && val.length <= maxLength ) {
      return null;
    } else {
      if (val.split(/,|au/g).length <= maxLength) {
        return null;
      }
    }
    return { maxDateLength: false };
  };
}
/** NG_VALIDATORS Directive that assert that a flatpickr input has no more than `appMaxDateLength` selected dates */
@Directive({
  selector: '[appMaxDateLength]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxDateLengthDirective), multi: true }
  ]
})
export class MaxDateLengthDirective implements OnInit, Validator {
  /** input max length value to check against */
  @Input() appMaxDateLength: number;
  /** the validation function */
  validator: ValidatorFn;
  /** MaxDateLengthDirective constructor */
  constructor() { }
  /** OnInit hook set the validator function */
  ngOnInit() {
    this.validator = maxDateLength(this.appMaxDateLength);
  }
  /**
   * Validate that the control value satisfy validator
   * @param c The control to validate
   */
  validate(c: AbstractControl) {
    return this.validator(c);
  }
}
