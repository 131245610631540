import { Workshop } from './workshop';

/** API model:Table: HISTO_EVT_MAJ */
export class EventsHistoryUpdate {
    /** HEVTMAJ_HEVT_ID_PFK	NUMBER(10,0) */
    id: number;
    /** HEVTMAJ_EMR_CODE_FK	VARCHAR2(2 BYTE) */
    emrCode: string;
    /** HEVTMAJ_ETAB_CODE_UG_FK	VARCHAR2(5 BYTE) */
    etabCode: Workshop;
}
