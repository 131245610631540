import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  title: string;
  message: string;
  confirm: boolean;
  customConfirm: string;
  customCancel: string;
  /**
   * `DialogComponent` constructor
   * @param dialogRef reference to this component as a popable MatDialog
   * @param data Dialog injected data
   */
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = data.title;
      this.message = data.message;
      this.confirm = data.confirm;
      this.customConfirm = data.customConfirm;
      this.customCancel = data.customCancel;
  }
}
