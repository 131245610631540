import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/** `AppComponent` is the root's application component */
@Component({
  selector: 'app-root',
  template: `<app-core></app-core>
  <!--{{ 'TEST.SELECT' | translate }}
  <select #langSelect (change)="translateService.use(langSelect.value)">
    <option *ngFor="let lang of translateService.getLangs()"
    [value]="lang" [selected]="lang === translateService.currentLang">{{ lang }}</option>
  </select>-->`,
})
export class AppComponent {
  /**
   *  AppComponent constructor is responsible for initialize translation service
   * @param translateService Application's translation service
   */
  constructor(private translateService: TranslateService) {
    this.configureI18n();
  }
  /**
   * Function to configure translation service
   */
  configureI18n(): void {
    this.translateService.addLangs(['fr', 'en']);
    this.translateService.setDefaultLang('en');
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }
}
