import { Overlay } from '@angular/cdk/overlay';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CamsDefault } from 'src/app/shared/models/cams-default';
import { ComponentGroup } from 'src/app/shared/models/component-group';
import { Defect } from 'src/app/shared/models/defect';
import { Gcu } from 'src/app/shared/models/gcu';
import { Job } from 'src/app/shared/models/job';
import { JobDefect } from 'src/app/shared/models/job-defect';
import { LogicalWagon } from 'src/app/shared/models/logical-wagon';
import { MaintenanceAid } from 'src/app/shared/models/maintenance-aid';
import { MaintenancePlanUpdate } from 'src/app/shared/models/maintenance-plan-update';
import { MonitoringOfWork } from 'src/app/shared/models/monitoring-work';
import { RefMaterial } from 'src/app/shared/models/ref-material';
import { StateJob } from 'src/app/shared/models/state-job';
import { TTache } from 'src/app/shared/models/t-tache';
import { TrailedMaterialStatus } from 'src/app/shared/models/trailed-material-status';
import { WagonTechnicalData } from 'src/app/shared/models/wagon-technical-data';
import { WagonUpdate } from 'src/app/shared/models/wagon-update';
import { MonitoringWorkService } from 'src/app/shared/services/monitoring-work/monitoring-work.service';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TemplatePopupDialogComponent } from '../../template-popup-dialog/template-popup-dialog.component';
import { CamsFaultsTableComponent } from "../cams-faults-table/cams-faults-table.component";
import { MaintenancePlanTableComponent } from "../plan-maintenance-table/maintenance-plan-table.component";
import { AddLineComponent } from './add-task/add-line.component';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { RefMaterialUpdate } from 'src/app/shared/models/ref-material-udpate';

const MY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-monitoring-works',
  templateUrl: './monitoring-works.component.html',
  styleUrls: ['./monitoring-works.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMAT}
  , DatePipe],
})
export class MonitoringWorksComponent implements OnInit, OnDestroy {

  @Input() public wagon: number;
  @Input() public wagonUpdate: WagonUpdate;
  @Input() public pMex: string;
  @Input() public pInv: string;
  @Input() public isLevel34: boolean;
  @Input() public wagonTechnicalDatas: WagonTechnicalData[];
  @Input() public logicalWagons: LogicalWagon[];
  @Input() public maintenancePlanList: MaintenancePlanUpdate[];
  @Input() public camFaultList: CamsDefault[];
  @Input() public maintenanceAids: MaintenanceAid[];
  @Input() public gcus: Gcu[];
  @Input() public taskDialogDatas: {
    stateJobs: StateJob[],
    componentGroups: ComponentGroup[],
    components: Component[],
    jobs: Job[],
    defects: Defect[],
    jobDefects: JobDefect[],
    tTaches: TTache[]
  };
  @Output() public allTasksCompletedEvent = new EventEmitter<boolean>();
  @Output() public modificationsSavedEvent = new EventEmitter<boolean>();
  @Output() maintenancePlanTableUpdated = new EventEmitter<boolean>();
  @Output() camFaultsTableUpdated = new EventEmitter<boolean>();
  @ViewChildren(MaintenancePlanTableComponent) mplan: QueryList<MaintenancePlanTableComponent>;
  @ViewChildren(CamsFaultsTableComponent) cams: QueryList<CamsFaultsTableComponent>;

  /** langage du navigateur */
  lang : string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Variable for progressComponent */
  messageToProgress = 'progressComponent receive a msg';
  /** Received value from ProgressComponent*/
  receivedProgressMessage: string;
  /** CAMS et C.U.U.S */
  listCAMSDefault : CamsDefault[];
  /** MonitoringOfWork  datas*/
  monitoringOfWork : MonitoringOfWork;
  /**RefMaterial datas */
  listRefMaterial: RefMaterial[]
  /**Progression  = ‘Progression’ + Somme (NbTacheTermine+ NbCAMTermine + NbDefautTermine)  + ‘/’ +  Somme (NbTacheTotal + NbCAMTotal + NbDefautTotal)*/
  nbProgressionTaskDone: number;
  nbProgressionTaskTotal: number;
  percentageProgression: number;
  /** Somme (NbTacheAFaire+ NbCAMAFaire + NbDefautAFaire) + ‘A faire’ */
  nbTasktoDO: number;
  /** = NbTacheEnCours + ‘En cours’ */
  nbTaskInProgress: number;
  /** = NbTacheEnAtt + ‘En attente’ */
  nbTaskWaiting: number;
  /** = Somme (NbTacheTotal + NbCAMTotal + NbDefautTotal) + ‘Terminé’ */
  nbTaskRealized: number;

  /** Badge enabled */
  badgeToDoEnabled: boolean;
  badgeInProgressEnabled: boolean;
  badgeWaitingEnabled: boolean;
  badgeRealizedEnabled: boolean;

  /** Monitoring Work form group */
  monitoringWorksForm: FormGroup;
  /** submitted property */
  submitted : boolean;
  /** new Estimated Release Date form control */
  estReleaseDateCtrl: FormControl;
  /** is Estiman. ReleaseDate mineur Entry Date*/
  isNotValidEstReleaseDate: boolean;
  /** Estimation Release Date */
  estRelease: Date;
  /** Date system */
  dateSystem : Date;
  /** La liste des Trailed Material Status  */
  trailedMaterialStatusList: TrailedMaterialStatus[];
  /** TrailedMaterialStatus selected*/
  trailedMaterialStatusSelected: TrailedMaterialStatus;
  /** TrailedMaterialStatus Init*/
  trailedMaterialStatusInit: TrailedMaterialStatus;
  /** Disponibilité control */
  availabilityCtrl: FormControl;
  /** new Availability Date form control */
  availabilityDateCtrl: FormControl;
  /** Availability time form control */
  availableTimeCtrl: FormControl;
  /** Time available System */
  availableTimeDf : string;
  /** is Estiman. ReleaseDate mineur Entry Date*/
  isNotValidAvailabilityDateTime: boolean;
  /** Estimation Release Date */
  availableDate: Date;
  observations: string;
  availabilityDate_error: number;
  dateToday = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private monitoringWorkService: MonitoringWorkService,
    // private utilityService: UtilityService,
    private dialog: MatDialog,
    private overlay: Overlay,
    private referenceService: ReferenceService) {
      this.lang = this.translateService.getBrowserLang().match(/en|fr/)
                ? this.translateService.getBrowserLang() : 'en';
  }

  getMessageFromProgress(message: string) {
    this.receivedProgressMessage = message;
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.resetDatas();
    this.loadInitDatas(true);
    this.estReleaseDateCtrl = this.formBuilder.control((this.monitoringOfWork && this.monitoringOfWork.update && this.monitoringOfWork.update.exit)? this.monitoringOfWork.update.exit: null);
    this.availabilityDateCtrl = this.formBuilder.control((this.monitoringOfWork && this.monitoringOfWork.update && this.monitoringOfWork.update.dateDispo)? this.monitoringOfWork.update.dateDispo: this.dateSystem, Validators.required);
    this.availabilityCtrl = this.formBuilder.control('', Validators.required);
    this.availableTimeCtrl = this.formBuilder.control(this.availableTimeDf, Validators.required);
    this.monitoringWorksForm = this.formBuilder.group({
      estReleaseDate: this.estReleaseDateCtrl,
      availabilityDate: this.availabilityDateCtrl,
      availabilityTime: this.availableTimeCtrl,
      availability: this.availabilityCtrl,
      observations:['', ],
     } , {
        validator: [ this.releaseDateValidator('estReleaseDate','availabilityDate','availabilityTime')]
    });
  }

  private addMinutes(date: any, minutes: number): Date {
    return new Date(date + minutes*60000);
  }

  /** Get hour and minute of a date */
  private getTime(date: Date): string{
    return date.toLocaleTimeString(navigator.language, {
      hour: '2-digit',
      minute:'2-digit'
    });
  }

  /** Clean Time Available */
  cleanAvailableTime(): void {
    this.availableTimeCtrl.setValue(undefined);
    this.monitoringWorksForm.markAsDirty();
  }

  /**
   * Methode to show the new Available Time
   */
  changeAvailableTime(): void {
    const value = this.monitoringWorksForm.get('availabilityTime')
    const request = this.monitoringWorksForm.get('availabilityTime').value;
  }

  /**
   * Validator to check that ReleaseDate et estimatino ReleaseDate
   * @param estReleaseDateControlName
   * @param availabilityDateControlName
   * @param availabilityTimeControlName
   */
  releaseDateValidator(estReleaseDateControlName: string, availabilityDateControlName: string, availabilityTimeControlName: string) {
    return (formGroup: FormGroup) => {
      this.dateSystem =  new Date((new Date().getTime()));
      const estReleaseDateControl = formGroup.controls[estReleaseDateControlName];
      const availabilityDateControl = formGroup.controls[availabilityDateControlName];
      const availabilityTimeControl = formGroup.controls[availabilityTimeControlName];

      availabilityDateControl.setErrors(null);
      availabilityTimeControl.setErrors(null);

      this.isNotValidEstReleaseDate = (estReleaseDateControl.status== 'INVALID') ? true: false;
      this.isNotValidAvailabilityDateTime = (availabilityDateControl.status== 'INVALID' || availabilityTimeControl.status== 'INVALID') ? true: false;

      if (availabilityDateControl.invalid || (availabilityDateControl.valid && (availabilityDateControl.value==undefined))){
        availabilityDateControl.setErrors(Validators.required);
        this.isNotValidAvailabilityDateTime = (availabilityDateControl.status== 'INVALID' || availabilityTimeControl.status== 'INVALID') ? true: false;

      }
      try {
        this.getDateFromCtrl(availabilityDateControl);
      } catch(error) {
        availabilityDateControl.setErrors(Validators.required);
        this.isNotValidAvailabilityDateTime = true;
      }
      if (availabilityTimeControl.invalid || (availabilityTimeControl.value==undefined)){
        availabilityTimeControl.setErrors(Validators.required);
        this.isNotValidAvailabilityDateTime = (availabilityDateControl.status== 'INVALID' || availabilityTimeControl.status== 'INVALID') ? true: false;
      }
      if (availabilityDateControl.errors || availabilityTimeControl.errors){
        this.availableDate = undefined;
        this.availabilityDate_error =0;
      }
      //RG_MAJ_003_9	La date/heure de disponibilité doit être <= date/heure système
      if (!availabilityDateControl.errors && !availabilityTimeControl.errors){
        const availabilityDate =  this.getDateFromCtrl(availabilityDateControl);
        const availabilityDateTime = this.addTimeToDateCtrl(availabilityDate, availabilityTimeControl);
        if(availabilityDateTime > this.dateSystem ){
          availabilityDateControl.setErrors(Validators.required);
          availabilityTimeControl.setErrors(Validators.required);
          this.isNotValidAvailabilityDateTime = true;
          this.availabilityDate_error =1;
        }else{
          this.availableDate = availabilityDateTime;
        }
        if (this.monitoringOfWork && this.monitoringOfWork.update && this.monitoringOfWork.update.entry){
          let entryDate = this.monitoringOfWork.update.entry;
          if (typeof entryDate === 'string'){
            entryDate = new Date(this.monitoringOfWork.update.entry);
          }
          //RG_MAJ_003_8	La date/heure de disponibilité doit être >= Date/heure d’entrée
          if(availabilityDateTime < entryDate ){
            availabilityDateControl.setErrors(Validators.required);
            availabilityTimeControl.setErrors(Validators.required);
            this.isNotValidAvailabilityDateTime = true;
            this.availabilityDate_error = 2;
          }else{
            this.availableDate = availabilityDateTime;
          }
        }
      }
      // RG_MAJ_003_6 : La date/heure de sortie prévue doit être >= à la date d’entrée
      if (estReleaseDateControl.invalid || (estReleaseDateControl.valid && (estReleaseDateControl.value===undefined))){
          estReleaseDateControl.setErrors(Validators.required);
      }
      else if (!estReleaseDateControl.errors && estReleaseDateControl.value!==null){

        let estReleaseDate =  this.getDateFromCtrl(estReleaseDateControl);
        if (typeof estReleaseDate === 'string'){
          estReleaseDate = new Date(estReleaseDate);
        }
        if (this.monitoringOfWork && this.monitoringOfWork.update && this.monitoringOfWork.update.entry){
          let entryDate = this.monitoringOfWork.update.entry;
          if (typeof entryDate === 'string'){
            entryDate = new Date(this.monitoringOfWork.update.entry);
          }
          //RG_MAJ_003_6 : La date/heure de sortie prévue doit être >= à la date d’entrée
          if (entryDate.toDateString() === estReleaseDate.toDateString()){
            estReleaseDate = entryDate;            
          }
          if (entryDate.toDateString())
          if (entryDate > estReleaseDate) {
            estReleaseDateControl.setErrors(Validators.required);
            this.isNotValidEstReleaseDate = true;
          }else{
            this.estRelease = estReleaseDate;
          }
          //RG_MAJ_003_7 : La date/heure de disponibilité doit être <= Date/heure de sortie prévue (si cette dernière est renseignée)
          /*
          if (this.availableDate > this.estRelease){
            availabilityDateControl.setErrors(Validators.required);
            availabilityTimeControl.setErrors(Validators.required);
            this.isNotValidAvailabilityDateTime = true;
            this.availabilityDate_error = 3;
          }*/
        }
      } else {
        this.estRelease = null;
      }
  }
}

/**
   * Return Date with Time in string
   * @param date
   * @param ctrl
   */
    private addTimeToDateCtrl(date: string | Date, ctrl: AbstractControl): Date {
      const indexT = date.toString().indexOf('T');
      const subStr = date.toString().substring(indexT, indexT + 6);
      const dateStr = date.toString().replace(subStr, 'T' + ctrl.value);
      const targetTime = new Date(dateStr);
      const indexZ = dateStr.lastIndexOf('Z');
      if (indexZ > -1){
        const hourZoneOffset =  targetTime.getTimezoneOffset()/60;
        targetTime.setHours(targetTime.getHours() + hourZoneOffset);
      }
      //convert the offset to milliseconds, add to targetTime, and make a new Date
      //var offsetTime = new Date(targetTime.getTime() + targetTime.getTimezoneOffset());
      return targetTime;
    }

/**
   * convenience getter for easy access to form fields
   */
  get f() { return this.monitoringWorksForm.controls; }

/**
   * Methode to show the new Est Release Date
   */
  changeEstReleaseDate(): void {
    const request =  this.getDateFromCtrl(this.monitoringWorksForm.get('estReleaseDate'));
  }

  /**
   * Methode to show the new AvailabilityDate
   */
  changeAvailabilityDate(): void {
    const request =  this.getDateFromCtrl(this.monitoringWorksForm.get('availabilityDate'));
  }

  /** Clean Est. Release Date entry */
  cleanEstReleaseDate(): void {
    this.estReleaseDateCtrl.setValue(undefined);
  }

  /** Clean Availability Date entry */
  cleanAvailabilityDate(): void {
    this.availabilityDateCtrl.setValue(undefined);
  }

  /**
   * Get the date of DatePicker control
   * @param ctrl
   */
  getDateFromCtrl(ctrl: AbstractControl) : string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : new Date(ctrl.value);
  }

  onAvailabilitySelection(): void {
    if(this.availabilityCtrl.value!==undefined && (this.trailedMaterialStatusSelected == undefined || this.availabilityCtrl.value!==this.trailedMaterialStatusSelected.code)){
      const valList = this.trailedMaterialStatusList.filter(t => t.code === this.availabilityCtrl.value);
      this.trailedMaterialStatusSelected = valList[0];
    }
  }
  
  /**
   * Reset les donnes
   */
  private resetDatas(): void {
   this.nbProgressionTaskDone = 0;
   this.nbProgressionTaskTotal = 0;
   this.nbTasktoDO = 0;
   this.nbTaskInProgress = 0;
   this.nbTaskWaiting= 0;
   this.nbTaskRealized= 0;
   this.percentageProgression = 0;
   this.badgeToDoEnabled = true;
   this.badgeInProgressEnabled = true;
   this.badgeWaitingEnabled = true;
   this.badgeRealizedEnabled = true;
   this.submitted = false;
   this.isNotValidEstReleaseDate = false;
   this.isNotValidAvailabilityDateTime = false;
   this.estRelease = null;
   this.dateSystem =  new Date((new Date().getTime()));
   this.availableTimeDf = null;
   this.availableDate = null;
   this.observations = '';
   this.availabilityDate_error = 0;
  }

  /**
   * Methode pour init datas
   */
  public loadInitDatas(intitMonitoringForm?: boolean): void {
    this.subscriptionRefs.push(
      this.monitoringWorkService.loadMonitoringOfWorkPageDatas(this.wagon)
      .subscribe(monitoring => {
        this.monitoringOfWork = monitoring.data;
        if (intitMonitoringForm) {
          this.initFormCtrlValues();
        }
        if (this.trailedMaterialStatusList) {
          this.resetEvent();
        } else{
          const operationMaintenance = this.monitoringOfWork.update.maintenanceOperation.code;
          this.subscriptionRefs.push(
            this.referenceService.findTrailedMaterialStatusByMaintenanceOperation(operationMaintenance)
            .subscribe((trailedMaterialStatusList) =>{
              this.trailedMaterialStatusList = trailedMaterialStatusList.data;
              if (this.trailedMaterialStatusList) {
                this.trailedMaterialStatusList.forEach(t => t['codeLabel'] = t.code + ' - ' + t.label);
              }
              this.resetEvent();
            })
          );
        }
      })
    );
  }


  /**
   * Event reset datas
   *
   */
  private resetEvent(){
    this.trailedMaterialStatusSelected = this.monitoringOfWork.update.trailedMaterialStatus;
    this.availabilityCtrl.setValue(this.trailedMaterialStatusSelected.code);
    this.trailedMaterialStatusInit = this.monitoringOfWork.update.trailedMaterialStatus;
    this.initStatusTask();
    this.checkTasksStatus();
    this.loadingSubject.next(false);
  }


  /**
   * Init Ctrl dates of form
   * @param monitoring
   */
  initFormCtrlValues(): void {
    if (this.monitoringOfWork && this.monitoringOfWork.update && this.monitoringOfWork.update.dateDispo){
      this.availableTimeDf =  this.getTime( moment(this.monitoringOfWork.update.dateDispo).add(1,'minute').toDate());
      this.availableTimeCtrl.setValue(this.availableTimeDf);
      const dateDispo = new Date(this.monitoringOfWork.update.dateDispo);
      if (this.availableTimeDf ==='00:00' ){
        dateDispo.setDate(dateDispo.getDate() + 1);
      }
      this.availabilityDateCtrl.setValue(dateDispo);
      
    }
    if (this.monitoringOfWork && this.monitoringOfWork.update && this.monitoringOfWork.update.exit){
      this.estReleaseDateCtrl.setValue(this.monitoringOfWork.update.exit);
    } else {
      this.estReleaseDateCtrl.setValue(null);
    }
    this.observations=this.monitoringOfWork.update.observationSite;
    this.availabilityCtrl.setValue(this.monitoringOfWork.update.trailedMaterialStatus?
      this.monitoringOfWork.update.trailedMaterialStatus.code:null);
    this.monitoringWorksForm.markAsPristine();
  }


  /**
   * Methode pour mettre a jour les values des badges
   * @param monitoringOfWork
   */
  initStatusTask(){
   this.nbProgressionTaskDone = this.monitoringOfWork.nTaskEnds + this.monitoringOfWork.nCAMEnds + this.monitoringOfWork.nFaultEnds;
   this.nbProgressionTaskTotal = this.monitoringOfWork.nTotalTask + this.monitoringOfWork.nTotalCAM + this.monitoringOfWork.nTotalFault;
   this.nbTasktoDO = this.monitoringOfWork.nTaskToDo + this.monitoringOfWork.nCAMToDo + this.monitoringOfWork.nFaultToDo;
   this.nbTaskInProgress = this.monitoringOfWork.nCurrentTask;
   this.nbTaskWaiting= this.monitoringOfWork.nPendingTask;
   this.nbTaskRealized= this.monitoringOfWork.nTaskEnds + this.monitoringOfWork.nCAMEnds + this.monitoringOfWork.nFaultEnds;
   this.percentageProgression = (this.nbProgressionTaskDone/this.nbProgressionTaskTotal)*100;
  }

  checkTasksStatus() {
    // Emettre l'etat des taches pour activer/desactiver le bouton Travaux terminés
    if (this.nbProgressionTaskDone === this.nbProgressionTaskTotal) {
      // Toutes les taches Realisés
    this.allTasksCompletedEvent.emit(true);
    } else {
    this.allTasksCompletedEvent.emit(false);
    }
  }

  /**
   * Filter les plan maintenance et cams
   * @param event
   * @param state
   */
  filterTacheByStatus(event: Event, state: StateJob){
    event.preventDefault();
    event.stopPropagation();
    if (!this.mplan.first || !this.cams.first) return;

    switch(state.code) {
      case '1': {
         this.badgeToDoEnabled = !this.badgeToDoEnabled;
         this.mplan.first.filterMaintanancePlanUpdate(this.badgeToDoEnabled, this.badgeInProgressEnabled, this.badgeWaitingEnabled, this.badgeRealizedEnabled);
         this.cams.first.filterCamsDefaults(this.badgeToDoEnabled, this.badgeRealizedEnabled);
         break;
      }
      case '2': {
         //En cours;
         this.badgeInProgressEnabled = !this.badgeInProgressEnabled;
         this.mplan.first.filterMaintanancePlanUpdate(this.badgeToDoEnabled, this.badgeInProgressEnabled, this.badgeWaitingEnabled, this.badgeRealizedEnabled);
         break;
      }
      case '3': {
        //En attente;
        this.badgeWaitingEnabled = !this.badgeWaitingEnabled;
        this.mplan.first.filterMaintanancePlanUpdate(this.badgeToDoEnabled, this.badgeInProgressEnabled, this.badgeWaitingEnabled, this.badgeRealizedEnabled);
        break;
      }
      case '4': {
        //terminé;
        this.badgeRealizedEnabled = !this.badgeRealizedEnabled;
        this.mplan.first.filterMaintanancePlanUpdate(this.badgeToDoEnabled, this.badgeInProgressEnabled, this.badgeWaitingEnabled, this.badgeRealizedEnabled);
        this.cams.first.filterCamsDefaults(this.badgeToDoEnabled, this.badgeRealizedEnabled);
        break;
      }
      default: {
         break;
      }
   }
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
    this.loadingSubject.complete();
  }

  /**
   * Methode to clean alerts errors.
   */
  disableAlerts(): void {
    if (this.submitted){
      this.submitted = false;
    }
  }

  /**
   * Methode to submit the form
   */
  async onSubmit(event?: RefMaterialUpdate[]): Promise<void> {
    if (event) {
      this.monitoringOfWork.update.refMaterialUpdateList = event;
      if (!this.monitoringWorksForm.dirty) {
        return;
      }
    }
    this.submitted = true;
    if (this.monitoringWorksForm.valid){
      this.monitoringOfWork.update.trailedMaterialStatus = this.trailedMaterialStatusSelected;
      this.monitoringOfWork.update.exit = this.estRelease;
      this.monitoringOfWork.update.dateDispo = this.availableDate;
      this.monitoringOfWork.update.observationSite = this.observations;      
      if (this.monitoringOfWork && this.monitoringOfWork.update && this.trailedMaterialStatusSelected && this.trailedMaterialStatusSelected.flagAtt===1
        && (!this.monitoringOfWork.update.refMaterialUpdateList || this.monitoringOfWork.update.refMaterialUpdateList.length == 0)){
        const message = this.translateService.instant('wagon-update.monitoring-work.pending_symbols_1');
        /*this.trailedMaterialStatusSelected = this.trailedMaterialStatusInit;
        this.availabilityCtrl.setValue(this.trailedMaterialStatusSelected.code);*/
        this.showAlertMessage(message);
      } else if (this.monitoringOfWork && this.monitoringOfWork.update && (!this.trailedMaterialStatusSelected || this.trailedMaterialStatusSelected.flagAtt===0 )
        && this.monitoringOfWork.update.refMaterialUpdateList && this.monitoringOfWork.update.refMaterialUpdateList.length > 0){
        const message = this.translateService.instant('wagon-update.monitoring-work.pending_symbols_2');
        /*this.trailedMaterialStatusSelected = this.trailedMaterialStatusInit;
        this.availabilityCtrl.setValue(this.trailedMaterialStatusSelected.code);*/
        await this.showAlertMessage(message);
        this.monitoringWorkService.updateMonitoringOfWorkPageDatas(this.monitoringOfWork)
        .subscribe((errorMessage) => {
          this.monitoringWorksForm.markAsPristine();
          if (!errorMessage && !errorMessage.data){
            /*this.trailedMaterialStatusSelected = this.trailedMaterialStatusInit;
            this.availabilityCtrl.setValue(this.trailedMaterialStatusSelected.code);*/
            this.showAlertMessage(errorMessage.data.message, true);
          } else {
            this.updateDone();
          }
        });

      } else {
        this.monitoringWorkService.updateMonitoringOfWorkPageDatas(this.monitoringOfWork)
        .subscribe((msg) => {
          this.monitoringWorksForm.markAsPristine();
          if (msg && msg.data && !msg.data.message){
            /*this.trailedMaterialStatusSelected = this.trailedMaterialStatusInit;
            this.availabilityCtrl.setValue(this.trailedMaterialStatusSelected.code);*/
            this.showAlertMessage(msg.data.message, true);
          } else {
            this.updateDone();
          }
        });
      }
    }
  }

   /**
   * Le message se ferme quand 5 seconds sont pasé
   */
  private updateDone() {
    // Emettre l'action de enregistrer pour activer le bouton Travaux terminés
    this.modificationsSavedEvent.emit(true);
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    const title = this.translateService.instant('wagon-update.monitoring-work.management-expectations.update-done.msg');
    const message = this.translateService.instant('wagon-update.monitoring-work.management-expectations.update-done.msg');
    const timeout = UtilityService.UPDATE_DONE_TIMEOUT;
    dialogConfig.data = {
      namePopUp: 'update-done-alert',
      titlePopUp: title,
      msgPopUp: message
    };
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        
        dialogRef.close();
        this.dialog.closeAll();
        //this.ngOnInit();
      }, timeout)
    })
  }

  /**
   * Show Alert message
   * @param msg
   */
  private async showAlertMessage(msg: any, saveData? : boolean) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';

    dialogConfig.data = {
      namePopUp: 'alert_msg',
      msgPopUp: msg
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    const confirm = (await dialogRef.afterClosed().pipe(first()).toPromise())
    if (saveData===true) {
      this.updateDone();
    }else{
      this.dialog.closeAll();
    }
  }

  /**
   * Methode pour ajouter un ligne des travaux
   * @param wagon
   */
  addTaskRow(): void {
    const scrollStrat = this.overlay.scrollStrategies.reposition();
    const dialogRef = this.dialog.open(AddLineComponent
      , {
        data: {wagonId: this.wagon, taskDialogDatas: this.taskDialogDatas, maintenanceAids: this.maintenanceAids, gcus: this.gcus},
        disableClose: true,
        scrollStrategy: scrollStrat,
        autoFocus: false
      });
      dialogRef.componentInstance.maintenancePlanTableUpdated.subscribe(res => { 
        if(res){
          this.maintenancePlanTableUpdated.emit(true);
        }
      });
      dialogRef.componentInstance.camFaultsTableUpdated.subscribe(res => { 
        if(res){
          this.camFaultsTableUpdated.emit(true);
        }
      });
      dialogRef.componentInstance.reload.subscribe(res => { 
        if(res){
          this.loadInitDatas(false);
        }
      });
    dialogRef.afterClosed().subscribe(confirm => {
      if(confirm) {
        this.maintenancePlanTableUpdated.emit(true);
        this.camFaultsTableUpdated.emit(true);
        this.loadInitDatas(false);
      }
    });
  }


  /**
   * Return number of Management Expecations
   * return number
   */
  getManagementExpectationsNumber(): number {
    let managementExpectationsNumber = 0;
    if(this.monitoringOfWork && this.monitoringOfWork.update && this.monitoringOfWork.update.refMaterialUpdateList){
      managementExpectationsNumber = this.monitoringOfWork.update.refMaterialUpdateList.length;
    }
     return managementExpectationsNumber;
  }

  /**
 * Get value from child component to refresh or not the page
 * @param loadPage 
 */
  onMaintenancePlanTableUpdateEvent(loadPage: boolean) {
    if (loadPage){
      this.maintenancePlanTableUpdated.emit(true);
      this.loadInitDatas(false);
    }
  }
  onCamFaultTableUpdateEvent(ev: boolean) {
    if (ev){
      this.camFaultsTableUpdated.emit(true);
      this.loadInitDatas(false);
    }
  }
}
