import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { HttpParams } from '@angular/common/http';

/**
 * Représente les paramètres de pagination et de
 * trie d'une API Spring WEB
 */
export class Pageable {
  /** Number of elements */
  size?: number;
  /** Current page index */
  page?: number;
  /** Sorted column */
  sort?: string;
  /** Sorted column array */
  sortArray?: string[];
  /** Sort direction */
  order?: string;
  /** Sort direction array */
  orderArray?: string[];

  /**
   * Nouveau pageable à partir du module de filtrage et de trie material
   * @param sort the material `MatSort` instance
   * @param paginator the material `MatPaginator` instance
   * @returns the computed `Pageable` for provided sort and paginator
   */
  static from(sort: MatSort, paginator: MatPaginator): Pageable {
    const pageable = new Pageable();
    pageable.page = paginator.pageIndex;
    pageable.size = paginator.pageSize;
    pageable.sort = sort.direction && sort.active || '';
    pageable.order = sort.direction;
    return pageable;
  }

  /**
   * Construit l'objet pageable
   */
  build(): HttpParams {

    let pageable: HttpParams = new HttpParams();

    if (this.size !== undefined) {
      pageable = pageable.set('size', this.size.toString());
    }
    if (this.page !== undefined) {
      pageable = pageable.set('page', this.page.toString());
    }
    if (this.sortArray) {
      pageable = pageable.set('sort', this.buildComplexSort(this.sortArray, this.orderArray));
    }
    if (this.sort) {
      const order = this.order === 'desc' ? '-' : '';
      pageable = pageable.set('sort', order + this.sort.toString());
    }

    return pageable;
  }


  /**
   * This function return un complex sort. Example sort=-id,date
   * @param pArraySort sorts
   * @param pArrayOrder orders
   * @returns Amount of quote estimation
   */
  buildComplexSort(pArraySort: string[], pArrayOrder: string[]): string {
    let sortStr = '';
    let orderStr = '';
    for (let i = 0; i < pArraySort.length; i++) {
      if (pArrayOrder && pArrayOrder[i]) {
        orderStr = pArrayOrder[i].toString() === 'desc' ? '-' : '';
      } else {
        orderStr = '';
      }
      if (sortStr !== '') {
        sortStr = sortStr + ',' + (orderStr + pArraySort[i].toString());
      } else {
        sortStr = sortStr + (orderStr + pArraySort[i].toString());
      }
    }
    return sortStr;
  }
}
