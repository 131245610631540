import { WagonTechnicalDataDetail } from './wagon-technical-data-detail';
import { WagonUpdate } from './wagon-update';
import { WagonRegulator } from './wagon-regulator';
import { WagonBrakeCylinder } from './wagon-brake-cylinder';
import { WagonBrakeParticularity } from './wagon-brake-particularity';
import { WagonEmptyLoadedDevice } from './wagon-empty-loaded-device';
import { WagonBrakeMainOrgan } from './wagon-brake-main-organ';
import { OutputAuxiliaryTankElement } from './output-auxiliary-tank-element';

/** API model: Page MAJ_003_4_6 Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Roulements » */
export class TechSpecBrake {

  wagonId: number;

  wagonUpdate: WagonUpdate;

  wagonTechnicalDataDetail: WagonTechnicalDataDetail;

  // regulators: Regulator[];

  // brakeCylinders: BrakeCylinder[];

  // brakeParticularities: BrakeParticularity[];

  // emptyLoadedDevices: EmptyLoadedDevice[];

  // auxiliaryTanks: AuxiliaryTank[];

  // purgeValves: PurgeValve[];

  // brakeControls: BrakeControl[];

  // handbrakes: Handbrake[];

  // brakeMainOrgans: BrakeMainOrgan[];

  isModifiedBrakedMass: boolean;

  isModifiedBrakedUnit: boolean;

  wagonRegulators: WagonRegulator[];

  wagonCylinders: WagonBrakeCylinder[];

  wagonBrakeParticularities: WagonBrakeParticularity[];

  wagonDevices: WagonEmptyLoadedDevice[];

  wagonBrakeMainOrgans: WagonBrakeMainOrgan[];

  auxiliaryTankElementList: OutputAuxiliaryTankElement[];
}
