import { Box } from './box';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_BOIT */
export class WagonBox {
    /** WBOIT_WCAR_MEX_PFK	VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = WBOIT_WCAR_INV_CODE_PFK	VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** box.code = WBOIT_BOIT_CODE_PFK VARCHAR2(3 BYTE) */
    box: Box;
    /** WBOIT_NBRE NUMBER(3,0) */
    amount: number;
}
