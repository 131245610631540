import { Component } from '@angular/core';

/** Template of a session expired page */
@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent {
    /**
   * `SessionExpiredComponent` constructor
   */
     constructor() {}
}
