import { WagonTechnicalDataDetail } from './wagon-technical-data-detail';
import { WagonUpdate } from './wagon-update';
import { WagonFrameEnd } from './wagon-frame-end';
import { WagonBumpOrgan } from './wagon-bump-organ';
import { WagonDrawHook } from './wagon-draw-hook';
import { WagonDrawSpring } from './wagon-draw-spring';

/** API model: Page MAJ_003_4_4 Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Choc traction » */
export class TechSpecShockTraction {

  wagonId: number;

  wagonUpdate: WagonUpdate;

  wagonTechnicalData: WagonTechnicalDataDetail;

  wagonFrameEnd: WagonFrameEnd[];

  // listFrameEnd: FrameEnd[];

  // listWagonLocation: WagonLocation[];

  wagonDrawSpring: WagonDrawSpring[];

  // listDrawSpring: DrawSpring[];

  wagonDrawHook: WagonDrawHook[];

  // listDrawHook: DrawHook[];

  wagonBumpOrgan: WagonBumpOrgan[];

  // listBumpOrgan: BumpOrgan[];
}
