import { Wheel } from './wheel';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_ROUE */
export class WagonWheel {
    /** WROUE_WCAR_MEX_PFK	VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = WROUE_WCAR_INV_CODE_PFK	VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** wheel.code = WROUE_ROUE_CODE_PFK VARCHAR2(2 BYTE) */
    wheel: Wheel;
    /** WROUE_NBRE NUMBER(3,0) */
    amount: number;
}
