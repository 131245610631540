import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { LogicalWagon } from '../../models/logical-wagon';
import { Wagon } from '../../models/wagon';
import { WagonTechnicalData } from '../../models/wagon-technical-data';
import { WagonService } from '../../services/wagon/wagon.service';
import { BehaviorSubject, Subscription, forkJoin } from 'rxjs';
import { Portal } from '@angular/cdk/portal';
import { AgentService } from 'src/app/core/services/agent/agent.service';


/**
 * `WagonComponent` display the wagon module with wagon data & page's specifics data
 */
@Component({
  selector: 'app-wagon',
  templateUrl: './wagon.component.html',
  styleUrls: ['./wagon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WagonComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Current physical wagon */
  wagon: Wagon;
  /** Commercial contract détails */
  commercialContractDetails: string;
  /** Current inventory logical wagon */
  currentInventoryWagon: LogicalWagon;
  /** Next inventory logical wagon */
  nextInventoryWagon: LogicalWagon;
  /** Current inventory technicals datas */
  technicalDatas: WagonTechnicalData;
  /** Portal where inject additional content  */
  contentPortal: Portal<any>;
  /** Portal where inject header content  */
  headerPortal: Portal<any>;
  /** Portal where inject actions  */
  actionPortal: Portal<any>;
  /** Subject to manage loading status */
  private loadingSubject = new BehaviorSubject<boolean>(true);
  /** Observable to get loading status */
  public loading$ = this.loadingSubject.asObservable();
  /** Retain all subscription */
  private subscriptionRefs: Subscription[] = [];
  /** Wether agent has PC Service */
  hasPC: boolean;
  /**
   * WagonComponent constructor
   * @param wagonService Inject service to retrieve wagon datas
   */
  constructor(private wagonService: WagonService, public agentService: AgentService) {
    this.subscriptionRefs.push( this.agentService.getHabilitationsLoaded()
      .subscribe(
        _ => this.hasPC = this.agentService.hasService('PC')
      )
    );
   }
  /**
   * OnInit hook
   * Subscribe to listen current wagon changes for WagonComponent
   */
  ngOnInit() {
    this.subscriptionRefs.push(
      this.wagonService.getCurrentWagonId()
        .subscribe(id => {
          this.loadingSubject.next(true);
          this.wagon = null;
          this.commercialContractDetails = '';
          this.currentInventoryWagon = null;
          this.nextInventoryWagon = null;
          this.technicalDatas = null;
          if ( id !== null && id !== undefined) {
            this.loadWagon(id);
          } else {
            this.wagonService.setSharedDatas(null, null, null);
            this.loadingSubject.next(false);
          }
      })
    );
  }
  /**
   * AfterViewInit hook
   * Subscribe to listen page specifics changes for WagonComponent
   */
  ngAfterViewInit() {
    this.subscriptionRefs.push(
      this.wagonService.getCurrentPortalTemplates()
        .subscribe(templates => {
          this.contentPortal = undefined;
          this.actionPortal = undefined;
          this.headerPortal = undefined;
          if ( templates ) {
            if ( templates[0] ) {
              this.contentPortal = templates[0] ;
            }
            if ( templates[1] ) {
              this.actionPortal = templates[1];
            }
            if ( templates[2] ) {
              this.headerPortal = templates[2];
            }
          }
      })
    );
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
    this.loadingSubject.complete();
  }
  /**
   * update current wagon's datas
   * @param id current wagon's id
   */
  loadWagon(id: number) {
    this.subscriptionRefs.push(
      forkJoin([
        this.wagonService.getWagon(id),
        this.wagonService.getWagonTechnicalDatas(id),
        this.wagonService.getLogicalWagons(id)
      ])
      .subscribe(([wagon, technicalDatas, logicals]) => {
        if (technicalDatas.data.length) {
          this.setTechnicalDatas(technicalDatas.data);
        }
        this.wagon = wagon.data;
        this.wagonService.setSharedDatas(wagon.data, technicalDatas.data ,logicals.data.reduce(
            (prev, curr) => ['13', '14', 'WO'].indexOf(curr.inventory.code) === -1 ? curr : prev , null), logicals.data);
        this.loadingSubject.next(false);
        if (wagon.data && wagon.data.commercialContract) {
          this.wagonService.getCommercialContractDetails(wagon.data.commercialContract.code)
            .subscribe( (details) => {
              for (const iterator of details.data) {
                this.commercialContractDetails += iterator.title + ' : ' + iterator.label + '\n';
              }
            });
        }
      })
    );
  }

  private setTechnicalDatas(datas: WagonTechnicalData[]): void  {
    this.technicalDatas = datas.reduce(
      (prev, curr) => ['13', '14', 'WO'].indexOf(curr.inventory.code) === -1 ? curr : prev , null);
  }
}
