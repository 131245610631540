import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppApiService } from '../../services/app-api/app-api.service';

/** Display the Application's about informations */
@Component({
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  /** this Api version number */
  apiVersion: string;
  /**
   * AboutComponent constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) { }
  /** OnInit hook get the Api version number from Api */
  ngOnInit() {
    this.api.get('/version', {responseType: 'text'})
      .subscribe((res: string) => this.apiVersion = res);
  }
}
