import { LineCategory } from './line-category';

/** API model:Table: COMPO_CLIG */
export class LineCategoryComposition {
    /** COMPCLIG_ID_PK NUMBER(5,0) */
    id: number;
    /* tslint:disable:variable-name */
    /** COMPCLIG_CLIG1_CODE_FK VARCHAR2(5 BYTE) */
    lineCategory1: LineCategory;
    /** COMPCLIG_CLIG2_CODE_FK VARCHAR2(5 BYTE) */
    lineCategory2: LineCategory;
    /** COMPCLIG_CLIG3_CODE_FK VARCHAR2(5 BYTE) */
    lineCategory3: LineCategory;
    /** COMPCLIG_CLIG4_CODE_FK VARCHAR2(5 BYTE) */
    lineCategory4: LineCategory;
    /** COMPCLIG_CLIG5_CODE_FK VARCHAR2(5 BYTE) */
    lineCategory5: LineCategory;
    /** COMPCLIG_CLIG6_CODE_FK VARCHAR2(5 BYTE) */
    lineCategory6: LineCategory;
    /** COMPCLIG_CLIG7_CODE_FK VARCHAR2(5 BYTE) */
    lineCategory7: LineCategory;
    /** COMPCLIG_CLIG8_CODE_FK VARCHAR2(5 BYTE) */
    lineCategory8: LineCategory;
    /** COMPCLIG_CLIG9_CODE_FK VARCHAR2(5 BYTE) */
    lineCategory9: LineCategory;
    /* tslint:enable:variable-name */
}
