import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { debounceTime } from 'rxjs/operators';
import { AgentService } from 'src/app/core/services/agent/agent.service';
import { CamsDefault } from 'src/app/shared/models/cams-default';
import { CertificationCompleted } from 'src/app/shared/models/certification-completed';
import { Expertise } from 'src/app/shared/models/expertise';
import { Gcu } from 'src/app/shared/models/gcu';
import { MaintenanceAid } from 'src/app/shared/models/maintenance-aid';
import { ReducedUpdateCompleted } from 'src/app/shared/models/reduced-update-completed';
import { ReducedUpdateInput } from 'src/app/shared/models/reduced-update-input';
import { ReducedUpdateValidate } from 'src/app/shared/models/reduced-update-validate';
import { StateJob } from 'src/app/shared/models/state-job';
import { TrailedMaterialStatus } from 'src/app/shared/models/trailed-material-status';
import { CertificationService } from 'src/app/shared/services/certification/certification.service';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { Anomaly } from "../../../models/anomaly";
import { WagonUpdate } from "../../../models/wagon-update";
import { WagonService } from "../../../services/wagon/wagon.service";
import { ConfirmationAlertComponent } from '../../end-of-work-alert/confirmation-alert.component';
import { TemplatePopupDialogComponent } from '../../template-popup-dialog/template-popup-dialog.component';
import { AresDialogComponent } from '../ares-dialog/ares-dialog.component';

@Component({
  selector: 'app-reduced-update',
  templateUrl: './reduced-update.component.html',
  styleUrls: ['./reduced-update.component.scss']
})
export class ReducedUpdateComponent implements OnInit, OnDestroy {

  @Input() public wagon: number;
  @Input() public pMex: string;
  @Input() public pInv: string;
  @Input() public pReducedUpdateInput : ReducedUpdateInput;
  @Input() public stateJobs: StateJob[];
  @Input() public maintenanceAids: MaintenanceAid[];
  @Input() public gcus: Gcu[];
  @Output() validateCompletedEvent = new EventEmitter();
  @Output() returnEvent = new EventEmitter();
  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  filteredMaintenanceAids: MaintenanceAid[];
  filteredGcus: Gcu[];
  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);

  anomalies: Anomaly[] = [];
  wagonUpdate: WagonUpdate;
  cams: CamsDefault[];
  wagonExpertise: Expertise;
  wagonExpertiseLabel: string;
  isRevision: boolean;

  certificationForm: FormGroup;
  addCam: FormControl;
  addDefect: FormControl;
  incidents: FormArray;
  reforms: FormArray;
  availability: FormControl;
  observations: FormControl;
  option1: FormControl;
  option2: FormControl;
  returnToServiceAdvice: FormControl;
  advice: boolean;

  incidentColumns: string[] = ['incident', 'event', 'sheet', 'tandem', 'region', 'reasonType', 'endReason', 'viewed', 'amortization'];
  incidentDS: MatTableDataSource<FormGroup>;

  reformColumns: string[] = ['reform', 'event', 'model', 'type', 'station', 'reason', 'label', 'viewed',  'amortization'];
  reformDS: MatTableDataSource<FormGroup>;

  camColumns: string[] = ['cam', 'codes', 'labels', 'origin', 'status', 'action'];
  camDS: MatTableDataSource<CamsDefault>;

  listAvailabilities: TrailedMaterialStatus[];
  mAvisDispo: number;
  stateToDoColor: string;
  stateDoneColor: string;
  isEnabledValidateBt: boolean;
  incValids: boolean;
  refValids: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private wagonService: WagonService,
    private certificationService: CertificationService,
    private referenceService: ReferenceService,
    private agentService: AgentService
  ) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.addCam = this.formBuilder.control('');
    this.addDefect = this.formBuilder.control('');
    this.incidents = this.formBuilder.array([]);
    this.reforms = this.formBuilder.array([]);
    this.availability = this.formBuilder.control(null, [Validators.required]);
    this.observations = this.formBuilder.control('');
    this.option1 = this.formBuilder.control('');
    this.option2 = this.formBuilder.control('');
    this.returnToServiceAdvice = this.formBuilder.control(false);
    this.certificationForm = this.formBuilder.group({
      addCam: this.addCam,
      addDefect: this.addDefect,
      incidents: this.incidents,
      reforms: this.reforms,
      observations: this.observations,
      availability: this.availability,
      option1: this.option1,
      option2: this.option2,
      returnToServiceAdvice: this.returnToServiceAdvice
    });
    this.filteredMaintenanceAids = this.maintenanceAids;
    this.filteredGcus = this.gcus;
    this.subscriptionRefs.push(
      this.certificationForm.get('addCam').valueChanges.pipe(debounceTime(10)).subscribe(
        value => this.filteredMaintenanceAids = this.filterWithCodeLabel(value, this.maintenanceAids)
      )
    );
    this.subscriptionRefs.push(
      this.certificationForm.get('addDefect').valueChanges.pipe(debounceTime(10)).subscribe(
        value => this.filteredGcus = this.filterWithCodeLabel(value, this.gcus)
      )
    );
    this.subscriptionRefs.push(
      this.certificationForm.get('availability').valueChanges.subscribe(
        value => {
          if (value && value.code === 'XN') {
            this.certificationForm.get('observations').setValidators(Validators.required);
          } else {
            this.certificationForm.get('observations').clearValidators();
          }
          this.certificationForm.get('observations').updateValueAndValidity();
        }
      )
    );
    this.loadInitDatas();
  }

  ngOnDestroy(): void {    
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
    this.loadingSubject.complete();
  }

  /**
  * Methode pour init datas
  */
  private loadInitDatas() {
    this.subscriptionRefs.push(
      forkJoin(
        this.wagonService.getAnomalies(this.wagon),
        this.certificationService.getAvailabilitiesList(this.pReducedUpdateInput.maintenanceOperation ? this.pReducedUpdateInput.maintenanceOperation.code:null),
        this.certificationService.findReturnToServiceAdviceReduced(this.wagon, this.pMex, 
            this.pReducedUpdateInput.workshop.code, this.pReducedUpdateInput.maintenanceOperation.code),
        this.referenceService.findCamsDefaultsReducedMAJ(this.wagon, this.pMex, 
          this.pReducedUpdateInput.pattern ? this.pReducedUpdateInput.pattern.code:'0',
          this.pReducedUpdateInput.workshop.code,
          this.pReducedUpdateInput.expertise ? this.pReducedUpdateInput.expertise.id:0)
      ).subscribe(([an, av, dispo, cams]) => {
        this.anomalies = an.data.filter(e => !e.isWroteOff).map( e => {
          e.isViewed = e.isViewed && e.isWroteOff;
          return e;
        });
        this.listAvailabilities = av.data;
        this.mAvisDispo = dispo.data;
        this.cams = cams.data;

        this.resetDatas();
        this.loadingSubject.next(false);
      })
    );
  }

  valider(): void {
    this.isEnabledValidateBt = false;
    this.onSubmit();
  }
  
  onSubmit(): void {
    if (!this.checkMandatoryFields()) {
      const title = this.translateService.instant('wagon-update.reduced-update.title');
      const message = this.translateService.instant('wagon-update.reduced-update.Controle_1');
      this.showErrorPopUpDialog(title, message);
      this.isEnabledValidateBt = true;
      return;
    }
    const msgDataControl = this.checkDataControls();
    if (msgDataControl) {
      const title = this.translateService.instant('wagon-update.reduced-update.title');
      const message = msgDataControl;
      this.showErrorPopUpDialog(title, message);
      this.isEnabledValidateBt = true;
      return;
    }
    this.control6IdentifiedDefect();
  }

  doSubmit(): void {
    const reducedUpdateValidate = new ReducedUpdateValidate();
    reducedUpdateValidate.wagonId = this.wagon;
    reducedUpdateValidate.availabilityCode = this.availability.value.code;
    reducedUpdateValidate.observations = this.observations.value;
    reducedUpdateValidate.option1 = this.option1.value;
    reducedUpdateValidate.option2 = this.option2.value;
    reducedUpdateValidate.adviceARES = this.returnToServiceAdvice.value;
    reducedUpdateValidate.anomalies = this.anomalies;
    reducedUpdateValidate.cams = this.cams;
    reducedUpdateValidate.hasExpertise = this.pReducedUpdateInput.expertiseChecked;
    reducedUpdateValidate.expertise = this.pReducedUpdateInput.expertise? this.pReducedUpdateInput.expertise.id: null;
    reducedUpdateValidate.maintenanceOperation = this.pReducedUpdateInput.maintenanceOperation.code;
    reducedUpdateValidate.interventionCause = this.pReducedUpdateInput.cause? this.pReducedUpdateInput.cause.code:null;
    reducedUpdateValidate.imputation = this.pReducedUpdateInput.imputation?this.pReducedUpdateInput.imputation.code:null;
    reducedUpdateValidate.interventionReason = this.pReducedUpdateInput.pattern?this.pReducedUpdateInput.pattern.code:null;
    reducedUpdateValidate.interventionWorkshop = this.pReducedUpdateInput.workshop.code;
    reducedUpdateValidate.releaseDate = this.pReducedUpdateInput.releaseDate;
    reducedUpdateValidate.entryDate = this.pReducedUpdateInput.entryDate;
    if (this.pReducedUpdateInput.dealNumber) {
      reducedUpdateValidate.dealCode = this.pReducedUpdateInput.dealNumber.toString();
    }
    this.loadingSubject.next(true);
    this.certificationService.validateReducedUpdate(reducedUpdateValidate)
      .subscribe((val) => {
        this.loadingSubject.next(false);
        if (val && val.data && val.data.returnCode && val.data.messageError) {
          const title = this.translateService.instant('wagon-update.reduced-update.title');
          this.showCamImposeConfirmationMessage(title, val.data.messageError.message, reducedUpdateValidate);
        } else if (val && val.data && val.data.messageError) {
          const title = this.translateService.instant('wagon-update.reduced-update.title');
          this.showErrorPopUpDialog(title, val.data.messageError.message);
          this.isEnabledValidateBt = true;
        } else {
          this.showPopARES(val.data);
        }
    });
  }

  /**
   * Contrôle des informations obligatoires
   */
  private checkMandatoryFields(): boolean {
    let valid = true;
    if (!this.availability.value) {
      this.availability.setErrors(Validators.required);
      valid = false;
    }

    return valid;
  }

  /**
     * Contrôle des informations obligatoires
     */
  private checkDataControls(): string {
    const txtObservation = this.observations.value;
    const availabilityCode = this.availability.value.code;
    // RG_MAJ_005_ 7
    if (availabilityCode === "XN" && !txtObservation) {
      return this.translateService.instant('wagon-update.certification.RG_MAJ_004_20');
    }
    // RG_MAJ_005_8
    if (txtObservation && txtObservation.includes(";")) {
      return this.translateService.instant('wagon-update.certification.RG_MAJ_004_21');
    }

    return "";
  }

  private control6IdentifiedDefect(): void {
    const lDefautIdentifiesAFaire =  this.cams.filter(elem => elem.type === "C.U.U" && elem.origin === "I");
    if (lDefautIdentifiesAFaire.length > 0) {
      const title = this.translateService.instant('wagon-update.reduced-update.title-anounce');
      const msg = this.translateService.instant('wagon-update.reduced-update.Controle_6');
      this.showConfirmationMessage(title, msg);
    } else {
      this.control11IdentifiedDefect();
    }
  }

  /**
   * Show Confirmation message
   * @param msg
   */
     showConfirmationMessage(title: string, msg: string): void {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.minWidth = '20rem';
  
      dialogConfig.data = {
        titlePopUp: title,
        msgPopUp: msg,
        btnCancelLabel: this.translateService.instant('wagon-update.preparation-work.popup_cancel'),
        btnAcceptLabel: this.translateService.instant('wagon-update.preparation-work.popup_accept')
      };
  
      const dialogRef = this.dialog.open(ConfirmationAlertComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.control11IdentifiedDefect();
        } else {
          this.isEnabledValidateBt = true;
        }
      });
  }

  private control11IdentifiedDefect(): void {
    const availCode = this.availability.value.code;
    const lDefautIdentifiesAFaire =  this.cams.filter(elem => elem.type === "C.U.U" && elem.origin === "I");
    const lDefautIdentifiesCorrige =  this.cams.filter(elem => elem.type === "C.U.U" 
                                                        && (elem.origin === "IC" || elem.origin === "C"));

    if ((availCode === "FI" || availCode === "FQ")
        && lDefautIdentifiesAFaire.length > 0 && lDefautIdentifiesCorrige.length == 0) {
          const title = this.translateService.instant('wagon-update.reduced-update.title');
          const message = this.translateService.instant('wagon-update.reduced-update.RG_MAJ_005_14');
          this.showErrorPopUpDialog(title, message);
          this.isEnabledValidateBt = true;
          return;
    }

    this.doSubmit();
  }

  /**
   * Show Confirmation message
   * @param msg
   */
  showCamImposeConfirmationMessage(title: string, msg: string, reducedUpdateValidate: ReducedUpdateValidate): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';
    
    dialogConfig.data = {
      titlePopUp: title,
      msgPopUp: msg,
      btnCancelLabel: this.translateService.instant('wagon-update.preparation-work.popup_cancel'),
      btnAcceptLabel: this.translateService.instant('wagon-update.preparation-work.popup_accept')
    };
    
    const dialogRef = this.dialog.open(ConfirmationAlertComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.certificationService.validateReducedUpdateAfterCAM(reducedUpdateValidate)
            .subscribe(val => {
              if (val && val.data && val.data.messageError) {
                const title = this.translateService.instant('wagon-update.reduced-update.title');
                this.showErrorPopUpDialog(title, val.data.messageError.message);
                this.isEnabledValidateBt = true;
              } else {
                this.showPopARES(val.data);
              }
          });
        } else {
          this.isEnabledValidateBt = true;
        }
    });
  }

  /**
   * Reset les donnes
   */
  private resetDatas() {
    this.incidents.clear();
    this.reforms.clear();

    this.anomalies.forEach(anomalie => {
      if ('INCIDENT' === anomalie.type) {
        this.incidents.push(
          this.addIncident(anomalie)
        );
      } else if ('REFORME' === anomalie.type) {
        this.reforms.push(
          this.addReform(anomalie)
        );
      }
    });

    this.incValids = true;
    for (const iterator of this.incidents.value) {
      if (!iterator.amortization) {
        this.incValids = false;
        break;
      }
    }
    this.refValids = true;
    for (const iterator of this.reforms.value) {
      if (!iterator.amortization) {
        this.refValids = false;
        break;
      }
    }
    this.incidentDS = new MatTableDataSource<FormGroup>(this.incidents.controls as FormGroup[]);
    this.reformDS = new MatTableDataSource<FormGroup>(this.reforms.controls as FormGroup[]);

    this.cams.forEach(camcuu => {
      if (camcuu.type === "C.U.U") {
        camcuu.originLabel = "Identifié";
      } else if (camcuu.type === "CAM") {
        if (camcuu.flagOrigin === 1) {
          camcuu.originLabel = "Imposé SEF";
        } else {
          camcuu.originLabel = "Imposé";
        }
      }
    });
    this.camDS = new MatTableDataSource<CamsDefault>(this.cams);

    // Retrive values from the OpenWagon popup
    if (!this.wagonUpdate) {
      this.wagonUpdate = new WagonUpdate();
      this.wagonUpdate.exit = this.pReducedUpdateInput.releaseDate;
      this.wagonUpdate.entry = this.pReducedUpdateInput.entryDate;
      this.wagonUpdate.type = this.pReducedUpdateInput.updateType;
      this.wagonUpdate.interventionWorkshop = this.pReducedUpdateInput.workshop;
      this.wagonUpdate.dealId = this.pReducedUpdateInput.dealNumber;
      this.wagonUpdate.maintenanceOperation = this.pReducedUpdateInput.maintenanceOperation;
      this.wagonUpdate.interventionReason = this.pReducedUpdateInput.pattern;
      this.wagonUpdate.interventionCause = this.pReducedUpdateInput.cause;
      this.wagonUpdate.imputation = this.pReducedUpdateInput.imputation;
      this.wagonUpdate.observation = this.pReducedUpdateInput.observations;
      this.wagonUpdate.option1 = this.pReducedUpdateInput.option1;
      this.wagonUpdate.option2 = this.pReducedUpdateInput.option2;
    }
    
    this.observations.setValue(this.wagonUpdate.observation);
    this.option1.setValue(this.wagonUpdate.option1);
    this.option2.setValue(this.wagonUpdate.option2);

    // RI_MAJ_005_1
    this.wagonExpertiseLabel = null;
    if (this.pReducedUpdateInput.expertiseChecked) {
      this.wagonExpertise = this.pReducedUpdateInput.expertise;
      this.wagonExpertiseLabel = this.translateService.instant('wagon.opening.detail_expertise');
      this.wagonExpertiseLabel = this.wagonExpertiseLabel.replace("{{ id }}", this.wagonExpertise.id.toString());
      this.wagonExpertiseLabel = this.wagonExpertiseLabel.replace("{{creation}}", formatDate(this.wagonExpertise.creation, 'shortDate', this.lang, 'undefined'));
      this.wagonExpertiseLabel = this.wagonExpertiseLabel.replace("{{workshop}}", this.wagonExpertise.workshop.code);
      this.wagonExpertiseLabel = this.wagonExpertiseLabel.replace("{{opMaint}}", this.wagonExpertise.maintenanceOperation.code);
      this.wagonExpertiseLabel = this.wagonExpertiseLabel.replace("{{intReason}}", this.wagonExpertise.interventionReason.code);
    }

    // RI_MAJ_005_2
    this.isRevision = this.wagonUpdate && this.wagonUpdate.maintenanceOperation
                      && !this.wagonUpdate.maintenanceOperation.isReasonMandatory;

    this.calculateServiceAdvice();

    this.stateToDoColor = (this.stateJobs.filter(s => s.code === "1"))[0].color;
    this.stateDoneColor = "green-off";

    this.isEnabledValidateBt = true;
  }

  private addIncident(anomalie: Anomaly) {
    const amortizationValue = this.getAmortizationValue(anomalie);
    const vuValue = this.getViewValue(anomalie);

    return this.formBuilder.group({
      id: this.formBuilder.control(anomalie.id),
      incident: this.formBuilder.control(anomalie.year + ' ' + anomalie.number.toString().substr(0, 8)),
      event: this.formBuilder.control(anomalie.event),
      sheet: this.formBuilder.control(anomalie.incident ? anomalie.incident.eventSheetNumber : null),
      tandem: this.formBuilder.control(anomalie.incident ? anomalie.incident.originDomain + ' ' + anomalie.incident.originPseudoKART : null),
      region: this.formBuilder.control(anomalie.incident && anomalie.incident.region ? anomalie.incident.region.label : null),
      type: this.formBuilder.control(anomalie.incident ? anomalie.incident.originType : null),
      endReason: this.formBuilder.control(anomalie.incident ? anomalie.incident.endReason : null, [Validators.required]),
      viewed: this.formBuilder.control(vuValue),
      amortization: this.formBuilder.control(amortizationValue)
    });
  }

  private addReform(anomalie: Anomaly) {
    const amortizationValue = this.getAmortizationValue(anomalie);
    const vuValue = this.getViewValue(anomalie);

    let modelLabel = anomalie.reform ? anomalie.reform.model.code : '';
    if (anomalie.reform) {
      modelLabel = anomalie.reform.complementTagModel ? modelLabel + ' ' + anomalie.reform.complementTagModel : modelLabel;
    }

    return this.formBuilder.group({
      id: this.formBuilder.control(anomalie.id),
      reform: this.formBuilder.control(anomalie.year + ' ' + anomalie.number.toString().substr(0, 8)),
      event: this.formBuilder.control(anomalie.event),
      model: this.formBuilder.control(modelLabel),
      type: this.formBuilder.control(anomalie.reform && anomalie.reform.type ? anomalie.reform.type.code : null),
      typeLabel: this.formBuilder.control(anomalie.reform && anomalie.reform.type ? anomalie.reform.type.label : null),
      station: this.formBuilder.control(anomalie.reform && anomalie.reform.detectStation ? anomalie.reform.detectStation.code : null),
      stationLabel: this.formBuilder.control(anomalie.reform && anomalie.reform.detectStation ? anomalie.reform.detectStation.label : null),
      reason: this.formBuilder.control(anomalie.reform && anomalie.reform.reason ? anomalie.reform.reason.code + ' ' + anomalie.reform.reason.label : null),
      label: this.formBuilder.control(anomalie.reform ? anomalie.reform.label : null),
      viewed: this.formBuilder.control(vuValue),
      amortization: this.formBuilder.control(amortizationValue)
    });
  }


  /**
   * View Change
   * @param vuValue 
   * @param newAmortizationValue 
   * @param id 
   */
   viewChange(event:MatCheckboxChange, element:any): void {
    const id = element.get('id').value;
    if (event.checked){
      element.get('viewed').setValue(true);
    }else{
      element.get('viewed').setValue(false);
      // RG_MAJ_004_ 24
      element.get('amortization').setValue(false);
    }
    const newAmortizationValue = element.get('amortization').value;
    const ano = this.anomalies.find( elem => elem.id === id);
    this.setAmortizationValue(ano, newAmortizationValue, event.checked);

    this.incValids = true;
    for (const iterator of this.incidents.value) {
      if (!iterator.amortization) {
        this.incValids = false;
        break;
      }
    }
    this.refValids = true;
    for (const iterator of this.reforms.value) {
      if (!iterator.amortization) {
        this.refValids = false;
        break;
      }
    }
  }
  
  /**
   * Amortization Change
   * @param vuValue 
   * @param newAmortizationValue 
   * @param id 
   */
  amortizationChange(event:MatCheckboxChange, element: any): void {
    const vuValue = event.checked ? true : element.get('viewed').value;
    const ano = this.anomalies.find( elem => elem.id === element.get('id').value);
    if (element.get('viewed').value !== vuValue) {
      (element.get('viewed') as FormControl).setValue(vuValue, {emitEvent:false, onlySelf: true,emitModelToViewChange: false, emitViewToModelChange: false});
    }
    this.setAmortizationValue(ano, event.checked, event.checked ? true : element.get('viewed').value);
  }

  private getAmortizationValue(anomalie: Anomaly): boolean {

    let amortizationValue = false;
    if (anomalie.isViewed) {
      amortizationValue = anomalie.isWroteOff;
    }

    return amortizationValue;
  }

  /**
   * Return View value 
   * @param anomalie 
   * @returns 
   */
   private getViewValue(anomalie: Anomaly): boolean {
    return anomalie.isViewed;
  }

  private setAmortizationValue(anomalie: Anomaly, amortizationValue: boolean, vuValue: boolean): void {
    anomalie.isViewed = vuValue;
    anomalie.isWroteOff = amortizationValue;
    this.incValids = true;
    for (const iterator of this.incidents.value) {
      if (!iterator.amortization) {
        this.incValids = false;
        break;
      }
    }
    this.refValids = true;
    for (const iterator of this.reforms.value) {
      if (!iterator.amortization) {
        this.refValids = false;
        break;
      }
    }
  }

  compareWithTrailedMaterialStatus = (t1: TrailedMaterialStatus, t2: TrailedMaterialStatus):boolean =>  {
    return t1 && t2 && t1.code === t2.code;
  }

  private calculateServiceAdvice(): void {
    if (this.mAvisDispo === 0) {
      // La case Valider Avis de Mise à Disposition est inactive
      this.returnToServiceAdvice.disable();
    } else {
      // La case Valider Avis de Mise à Disposition est active non cochée
      this.returnToServiceAdvice.setValue(this.agentService.getAgentData().value.company.code === '58002' ? true : false);
    }
  }

  endReasonChange(event: string, id: number): void {
    const inc = this.anomalies.filter(i => i.id === id);
    if (inc && inc.length > 0) {
      inc[0].incident.endReason = event;
    }
  }

  removeRow(index: number, event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.cams.splice(index, 1);
    this.tables.forEach(table => table.renderRows());
  }

  addCamRow(): void {
    const selectedCam = this.addCam.value;
    const msg = this.addCamRowControls(selectedCam.code);
    if (msg) {
      const title = this.translateService.instant('wagon-update.reduced-update.title');
      this.showErrorPopUpDialog(title, msg);
    } else {
      const newCam = new CamsDefault();
      newCam.type = "CAM"
      newCam.code = selectedCam.code;
      newCam.label = selectedCam.label;
      newCam.origin = "IE";
      newCam.originLabel = "Ajouté";
      this.cams.push(newCam);
      this.tables.forEach(table => table.renderRows());
    }
    this.addCam.setValue(null);
  }

  addCamRowControls(camCode: string): string {
    const tabCamsCode = this.cams.filter(elem => elem.code === camCode);
    if (tabCamsCode.length > 0) {
      // RG_MAJ_005_11: Unicité des CAM
      return this.translateService.instant('wagon-update.reduced-update.RG_MAJ_005_11');
    }
    
    // RG_MAJ_005_12: Limite de 40 CAM réalisés
    const tabCams = this.cams.filter(elem => elem.type === "CAM" && (elem.origin === 'IE' || elem.origin === 'E'));
    if (tabCams.length >= 40) {
      return this.translateService.instant('wagon-update.reduced-update.RG_MAJ_005_12');
    }

    return null;
  }

  addDefectRow(): void {
    const selectedDefect = this.addDefect.value;
    const msg = this.addDefaultRowControls(selectedDefect.code);
    if (msg) {
      const title = this.translateService.instant('wagon-update.reduced-update.title');
      this.showErrorPopUpDialog(title, msg);
    } else {
      const newDefect = new CamsDefault();
      newDefect.type = "C.U.U"
      newDefect.code = selectedDefect.code;
      newDefect.label = selectedDefect.label;
      newDefect.origin = "IC";
      newDefect.originLabel = "Ajouté";
      this.cams.push(newDefect);
      this.tables.forEach(table => table.renderRows());
    }
    this.addDefect.setValue(null);
  }

  addDefaultRowControls(camCode: string): string {
    const tabCamsCode = this.cams.filter(elem => elem.code === camCode);
    if (tabCamsCode.length > 0) {
      // RG_MAJ_005_ 13
      return this.translateService.instant('wagon-update.reduced-update.RG_MAJ_005_13');
    }

    return null;
  }

  updateCAMDefault(event: Event, type: string, code: string, state: string): void {
    event.preventDefault();
    event.stopPropagation();

    const selectedCam = this.cams.filter(elem => elem.code === code)[0];
    // Modifier l'état d'un CAM Imposé ou d'un Défaut Identifié
    if (!(selectedCam.originLabel === 'Imposé' || selectedCam.originLabel === 'Imposé SEF'
            || selectedCam.originLabel === 'Identifié')) {
      return;
    }

    let status: string;
    switch (type) {
      case 'CAM': {
        if (state === 'I') {
          status = 'IE';
        } else if (state === 'IE' || state === 'E') {
          status = 'I';
        }
        break;
      }
      case 'C.U.U': {
        if (state === 'I') {
          status = 'IC';
        } else if (state === 'IC' || state === 'C') {
          status = 'I';
        }
      }
    }

    // RG_MAJ_005_ 12: Limite de 40 CAM réalisés
    const tabCams = this.cams.filter(elem => elem.type === "CAM" && (elem.origin === 'IE' || elem.origin === 'E'));
    if (type === 'CAM' && status === 'IE' && tabCams.length >= 40) {
      const title = this.translateService.instant('wagon-update.reduced-update.title');
      const msg = this.translateService.instant('wagon-update.reduced-update.RG_MAJ_005_12');
      this.showErrorPopUpDialog(title, msg);
      return;
    }
  

    selectedCam.origin = status;
    this.tables.forEach(table => table.renderRows());
  }


  /**
   * Method to show an error Dialog
   */
   showErrorPopUpDialog(title: string, message: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '12.5rem';
    dialogConfig.minWidth = '13.75rem';
    dialogConfig.data = {
      namePopUp: 'alert_msg',
      titlePopUp: title,
      msgPopUp: message
    };
    this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
  }

  returnToCreate(): void {
    // Prepare the object to initialize the OpenWagon popup
    const reducedUpdateInput = new ReducedUpdateInput();
    reducedUpdateInput.reducedUpdateCheckbox = true;
    reducedUpdateInput.workshop = this.wagonUpdate.interventionWorkshop;
    reducedUpdateInput.updateType = this.wagonUpdate.type;
    reducedUpdateInput.maintenanceOperation = this.wagonUpdate.maintenanceOperation;
    reducedUpdateInput.pattern = this.wagonUpdate.interventionReason;
    reducedUpdateInput.cause = this.wagonUpdate.interventionCause;
    reducedUpdateInput.imputation = this.wagonUpdate.imputation;
    reducedUpdateInput.entryDate = this.wagonUpdate.entry;
    reducedUpdateInput.releaseDate =this.wagonUpdate.exit;
    reducedUpdateInput.observations = this.wagonUpdate.observation;
    reducedUpdateInput.option1 = this.wagonUpdate.option1;
    reducedUpdateInput.option2 = this.wagonUpdate.option2;
    reducedUpdateInput.dealNumber = this.wagonUpdate.dealId;
    reducedUpdateInput.referenceWagon = this.pReducedUpdateInput.referenceWagon;
    reducedUpdateInput.expertiseChecked = this.pReducedUpdateInput.expertiseChecked;
    reducedUpdateInput.expertise = this.wagonExpertise;

    this.returnEvent.emit(reducedUpdateInput);
  }

  /**
   * Fermeture de la mise à jour
   */
  showPopARES(cert: ReducedUpdateCompleted): void {
    const certificationCompleted = new CertificationCompleted();
    certificationCompleted.mMessageBT = cert.mMessageBT;
    certificationCompleted.mMessageARES = cert.mMessageARES;
    certificationCompleted.mValidARES = cert.mValidARES;
    certificationCompleted.mBtId = cert.mBtId;
    certificationCompleted.mNoticeId = cert.mNoticeId;
    certificationCompleted.mDevisIdExp = cert.mDevisIdExp;
    certificationCompleted.mDevisIdClient = cert.mDevisIdClient;
    certificationCompleted.mEtbIdSaisi = cert.mEtbIdSaisi;
    const dialogRef = this.dialog.open(AresDialogComponent, {
      data: {
        wagonId: this.wagon,
        mex: this.wagonUpdate.mex,
        certificationCompleted: certificationCompleted
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(() => {
      this.wagonService.setWagonWorkDone(this.wagon);
      this.validateCompletedEvent.emit();
    });
  }

  private filterWithCodeLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + v.label).toUpperCase().includes(filterValue))
      ) : list;
  }
  displayCodeLabel = (obj: any) => {
    return obj ? (obj.code ? obj.code + ': ' + obj.label : obj) : '';
  }
}
