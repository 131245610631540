import { Axle } from './axle';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_ESS */
export class WagonAxle {
    /** WESS_WCAR_MEX_PFK	VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = WESS_WCAR_INV_CODE_PFK	VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** axle.code = WESS_ESS_CODE_PFK VARCHAR2(3 BYTE) */
    axle: Axle;
    /** WESS_NBRE NUMBER(3,0) */
    amount: number;
}
