import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { AboutComponent } from './components/about/about.component';
import { Error401Component } from './components/error-pages/401/error-401.component';
import { Error403Component } from './components/error-pages/403/error-403.component';
import { Error404Component } from './components/error-pages/404/error-404.component';
import { Error500Component } from './components/error-pages/500/error-500.component';
import { SessionExpiredComponent } from './components/error-pages/session-expired/session-expired.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { SiteMapComponent } from './components/site-map/site-map.component';
import { FaqComponent } from './components/faq/faq.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'dashboard', component: DashboardComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'connection', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LoginComponent },
  { path: 'unauthorized', component: LoginComponent },
  { path: 'about', component: AboutComponent },
  { path: 'sitemap', component: SiteMapComponent },
  { path: 'faq', component: FaqComponent,
    canActivate: [AuthGuardService] },
  { path: '401', component: Error401Component },
  { path: '403', component: Error403Component },
  { path: '404', component: Error404Component },
  { path: '500', component: Error500Component },
  { path: 'session-expired', component: SessionExpiredComponent },
  { path: 'consultation', loadChildren: () => import('../modules/consultation/consultation.module').then(m => m.ConsultationModule),
    canActivate: [AuthGuardService]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {
}
