import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/internal/operators/finalize';
import { Subscription } from 'rxjs/internal/Subscription';
import { PreparationWorkCamsFaults } from '../../models/preparation-work-cams-faults';
import { MaintenancePlanUpdateService } from '../../services/maintenance-plan-update/maintenance-plan-update.service';

@Component({
  selector: 'app-template-popup-dialog',
  templateUrl: './template-popup-dialog.component.html',
  styleUrls: ['./template-popup-dialog.component.scss']
})
export class TemplatePopupDialogComponent implements OnDestroy  {  
  /** langage du navigateur */
  lang : string;
  /** mex de la recherche */
  mex : string;

  bsChecked:boolean;
  defaultChecked:boolean;
  camChecked:boolean;
  pmChecked:boolean;

  /** loading status of mediaor report */
  isLoadingPreviewOrReport: boolean;

  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<TemplatePopupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private maintenancePlanUpdateService: MaintenancePlanUpdateService,
    private translateService: TranslateService) {
      this.mex = data.mex;
      this.bsChecked =false;
      this.defaultChecked =false;
      this.camChecked =false;
      this.pmChecked =false;
      this.lang = this.translateService.getBrowserLang().match(/en|fr/)
                ? this.translateService.getBrowserLang() : 'en';
  }

  /**
   * Confirm Dialog
   */
  confirm(): void {
    this.dialogRef.close(true);
  }

  onChange($event, checkbox : string): void {
    if (checkbox ==='bs'){
      this.bsChecked = $event.checked;
    }else if (checkbox ==='default'){
      this.defaultChecked = $event.checked;
    }else if (checkbox ==='cam'){
      this.camChecked = $event.checked;
    }else if (checkbox === 'pm'){
      this.pmChecked = $event.checked;
    }
  }

  /**
   * Gener le rapport de BulletinSuivi, Defaults, CAMS et plan maintenance
   * @param prepWorkCamsFaultsDatas 
   * @param flagTracking 
   * @param flagDefaults 
   * @param flagCAM 
   * @param flagMaintenance 
   * @param mex 
   */
  printBulletinSuiviPDF(prepWorkCamsFaultsDatas : PreparationWorkCamsFaults, flagTracking : boolean,flagDefaults : boolean,flagCAM : boolean,flagMaintenance: boolean, mex: string): void {

    this.isLoadingPreviewOrReport = true;
    this.subscriptionRefs.push(
      this.maintenancePlanUpdateService.downloadPDFPreparationWorks(prepWorkCamsFaultsDatas.wagon.id, prepWorkCamsFaultsDatas.wagonProduction.id,
        flagTracking,flagDefaults,flagCAM,flagMaintenance, mex)
      .pipe(
        finalize(() => this.isLoadingPreviewOrReport = false)
      )
      .subscribe(
          (report) => {
            saveAs(report.blob, report.filename);
            this.dialog.closeAll();
        })
    );
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
  }
 

}
