import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AgentService } from 'src/app/core/services/agent/agent.service';
import { PcMessageService } from 'src/app/core/services/pc-message/pc-message.service';
import { first } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  /** Whether datas are still loading */
  isLoadingResults = true;
  /** Media Query list to check against for detect medium screens */
  mobileQueryMedium: MediaQueryList;
  /** Data */
  messages: SafeHtml[] = [];
  /** browser language */
  lang: string;

  constructor(
    private translateService: TranslateService,
    public agentService: AgentService,
    private pcMessageService: PcMessageService,
    private media: MediaMatcher,
    private sanitizer: DomSanitizer) {
      this.lang = this.translateService.getBrowserLang().match(/en|fr/)
                ? this.translateService.getBrowserLang() : 'en';
      this.mobileQueryMedium = this.media.matchMedia('(max-width: 1200px)');
    }

  ngOnInit() {
    this.isLoadingResults = true;
    this.agentService.getAgentData().subscribe ( async agent => {
      if (agent) {
        const msgs = await this.pcMessageService.getPcMessages(agent.company.code).pipe(first()).toPromise();
        this.messages = msgs.data.filter(m => m.label !== null).map( e => this.sanitizer.bypassSecurityTrustHtml(e.label));
        this.isLoadingResults = false;
      }
    });
  }
}
