import { Gcu } from './gcu';

/** API model:Table: IGL_DEFAUT_CUU  */
export class IglDefautCuu {
    /** IDCUU_IWAG_ID_PFK	NUMBER(10,0) */
    id: number;
    /** IDCUU_IDEF_CODE_PFK	VARCHAR2(11 BYTE) */
    idefCode: Gcu;
    /** IDCUU_TYPE_INI_COR	VARCHAR2(2 BYTE) */
    type: string;
}
