import { EmptyLoadedDevice } from './empty-loaded-device';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_DISPOVC */
export class WagonEmptyLoadedDevice {
     /** WDISPOVC_WCAR_MEX_PFK VARCHAR2(14 BYTE) */
     mex: string;
     /** inventory.code = WDISPOVC_WCAR_INV_CODE_PFK VARCHAR2(2 BYTE) */
     inventory: Inventory;
    /** emptyLoadedDevice.code = WDISPOVC_DISPOVC_CODE_PFK VARCHAR2(2 BYTE) */
    emptyLoadedDevice: EmptyLoadedDevice;
    /** WDISPOVC_NBRE NUMBER(3,0) */
    amount: number;
}
