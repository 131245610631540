
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Holder } from 'src/app/shared/models/holder';
import { WagonHolderSheet } from 'src/app/shared/models/wagon-holder-sheet';
/**
 * Display a deletion confirmation dialog for file nammed `filename`
 */
@Component({
  selector: 'app-holder-sheet-dialog',
  templateUrl: './holder-sheet-dialog.component.html',
  styleUrls: ['./holder-sheet-dialog.component.scss']
})
export class HolderSheetDialogComponent {
  /** holder datas */
  holder: Holder;
  holderSheet: WagonHolderSheet;
  /**
   * `HolderSheetDialogComponent` constructor
   * @param dialogRef reference to this component as a popable MatDialog
   * @param data Dialog injected data
   */
  constructor(
    public dialogRef: MatDialogRef<HolderSheetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.holder = data.holder;
      this.holderSheet = data.holderSheet;
    }
}
