import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';

import { AuthService } from './auth.service';
import { AuthRoleEnum } from './auth-role.enum';
import { takeWhile } from 'rxjs/operators';
/**
 * Directive to check if the connected user has a specific role & embed view if true
 */
@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit, OnDestroy {
  /** Input roles to check for */
  @Input() appHasRole: [[AuthRoleEnum] | AuthRoleEnum] | AuthRoleEnum;

  /** Is alive until it is destroy */
  private alive = true;
  /** Is visible if has the checked roles */
  private visible = false;
  /**
   * HasRoleDirective constructor
   * @param authService Inject application's authentication service
   * @param templateRef This template reference
   * @param viewContainer View Container reference to create embedded view in
   */
  constructor(private authService: AuthService,
              private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef) {
  }
  /** OnInit hook subscribe to isLogged `BehaviourSubject<boolean>` to manage visibility updates */
  ngOnInit() {
    this.authService.isLogged()
      .pipe(
        takeWhile(() => this.alive)
      ).subscribe(() => this.updateVisiblity());
  }
  /**
   * Check if user has `appHasRole` role(s).
   * Create or clear the embedded `templateRef` view in `viewContainer` according to previous result & sets `visible` property value.
   */
  private updateVisiblity() {
    let hasRole = false;
    if (Array.isArray(this.appHasRole) && this.authService.hasRoles(this.appHasRole)) {
      hasRole = true;
    } else if (this.authService.hasRole(this.appHasRole as AuthRoleEnum)) {
      hasRole = true;
    }

    if (hasRole && !this.visible) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.visible = true;
    } else if (!hasRole) {
      this.viewContainer.clear();
      this.visible = false;
    }
  }
  /** OnDestroy hook sets alive = false */
  ngOnDestroy(): void {
    this.alive = false;
  }

}
