import { Component, OnInit , OnDestroy} from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { WorkingBill } from 'src/app/shared/models/working-bill';
import {Chart} from 'chart.js';
import { Subscription } from 'rxjs';
import { AgentService } from 'src/app/core/services/agent/agent.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-indicators',
  templateUrl: './indicators.component.html',
  styleUrls: ['./indicators.component.scss']
})
export class IndicatorsComponent implements OnInit, OnDestroy {

  /** Whether datas are still loading */
  isLoadingResults = true;
  /** Retain all subscriptions */
  subscriptionRefs: Subscription[] = [];
  /** Data */
  chart: Chart;
  billsFactured = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  billsInProgress = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  workingBills: WorkingBill[];

  constructor(
    private dashboardService: DashboardService,
    private agentSerivce: AgentService,
    private translateService: TranslateService) {
  }

  ngOnInit() {
    this.subscriptionRefs.push(
    this.agentSerivce.getAgentData().subscribe(agentData => {
      if (agentData) {
        const workShopCode = agentData.company.code;
        this.subscriptionRefs.push(
          this.dashboardService.getWorkingBills(workShopCode)
            .subscribe((workingBills) => {
              this.workingBills = workingBills.data;
              this.processWorkingBills(this.workingBills);
            }
            )
        );
      }
    })
    );


    this.chart = new Chart('canvas_indicators', {
      type: 'bar',
      data: {
        labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
        datasets: [
          {
            label: this.translateService.instant('dashboard.indicators_card.factured_bt_label') ,
            stack: 'Stack 0',
            data: this.billsFactured,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
          },
          {
            label: this.translateService.instant('dashboard.indicators_card.open_bt_label') ,
            stack: 'Stack 0',
            data: this.billsInProgress,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
          },
        ]
      },
      options: {
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            display: true,
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: this.translateService.instant('dashboard.indicators_card.month'),
            },
          }],
          yAxes: [{
            display: true,
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: this.translateService.instant('dashboard.indicators_card.nb_bt'),
            },
          }],
        }
      }
    });
  }


  processWorkingBills(workingBills: WorkingBill[]) {
    for (const workingBill of workingBills) {
      const month = new Date(workingBill.creation).getMonth();
      if (workingBill.status === '0') {
        this.billsInProgress[month] = this.billsInProgress[month] + 1;
      } else {
        this.billsFactured[month] = this.billsFactured[month] + 1;
      }
      this.chart.data.datasets[0].data = this.billsFactured;
      this.chart.data.datasets[1].data = this.billsInProgress;
      this.chart.update();
    }
    this.isLoadingResults = false;
  }


  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
  }
}
