/** API model:Table: FAQ_QUESTIONS */
export class FaqQuestion {
  /** FQUE_ID_PK	NUMBER(10,0) */
  id: number;
  /** FQUE_FTHE_ID_FK	NUMBER(10,0) */
  // themeId: number;
  /** FQUE_QUESTION	VARCHAR2(250 BYTE) */
  question: string;
  /** FQUE_REPONSE	VARCHAR2(1000 BYTE) */
  response: string;
}
