import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { LoggerService } from '../logging/logger.service';

/**
 * Gestionnaire des exception non catchées
 */
@Injectable()
export class UncaugthErrorHandler implements ErrorHandler {

  /**
   * MastHeaderComponent constructor
   * @param injector Angular injector
   * @param logger log service
   * @param zone Angular zone
   */
  constructor(private injector: Injector, private logger: LoggerService, private zone: NgZone) {
    this.logger = logger.newLogger('uncaugth_error_handler');
  }

  /**
   * Handler pour les erreurs non catchées
   * @param error - L'exception
   */
  handleError(error: any): void {

    // Dans le cas ou un groupe en console a été ouvert
    // il est fermé
    console.groupEnd();

    // S'il s'agit d'une erreur dans une Promise, on
    // récupère l'erreur racine
    if (error.promise && error.rejection) {
      error = error.rejection;
    }

    // Gestion spéciale des erreurs HTTP
    if (error instanceof HttpErrorResponse) {
      return this.handleHttpResponseError(error);
    }

    // Lance les autres execeptions
    this.logger.error('Erreur non gérée et non catchée');
    console.log(error);
    
    // En production, redirger vers la page d'erreur
    this.redirect('/500');
  }

  /**
   * Gestion d'une erreur HTTP
   * @param error - L'erreur HTTP
   */
  private handleHttpResponseError(error: HttpErrorResponse): void {
    this.logger.warn('Erreur HTTP {} reçu et non gérée - {}', error.status, error.url);

    // Si erreur 401 ou 403, redirect vers une page d'erreur
    if (error.status === 401) {
      this.redirect('/401');
    } else if (error.status === 403) {
      this.redirect('/403');
    }
  }

  /**
   * Redirige vers une autre page
   * @param url - URL source
   */
  private redirect(url: string) {
    const router: Router = this.injector.get(Router);
    this.zone.run(() => router.navigate([url]));
  }
}
