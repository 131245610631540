import { Injectable } from '@angular/core';

/**
 * Service pour le stockage de données en localstorage
 */
@Injectable()
export class StorageService {
  /** window localStorage access variable */
  private localStorage = window.localStorage;
  /** window sessionStorage access variable */
  private sessionStorage = window.sessionStorage;
  /** StorageService constructor */
  constructor() { }
  /**
   * Get item stored as `key`
   * @param key key of the item
   * @returns Item value
   */
  public get(key: string): any {
    return this.localStorage.getItem(key);
  }
  /**
   * Whether item `key` exists
   * @param key key of the item
   * @returns true if exists
   */
  public exists(key: string): boolean {
    return this.localStorage.getItem(key) !== null;
  }
  /**
   * Whether item stored as `key` is of type `number`
   * @param key key of the item
   * @returns true if is a number
   */
  public isNumber(key: string): boolean {
    const value = this.getInt(key);
    return !isNaN(value) && isFinite(value);
  }
  /**
   * Get string item stored as `key`
   * @param key key of the item
   * @returns Item value
   */
  public getString(key: string): string {
    return this.get(key);
  }
  /**
   * Get integer integer item stored as `key`
   * @param key key of the item
   * @returns Item value as integer value
   */
  public getInt(key: string): number {
    const value = this.get(key);
    return Number.parseInt(value, 10);
  }
  /**
   * Get float number item stored as `key`
   * @param key key of the item
   * @returns Item value as float number
   */
  public getFloat(key: string): number {
    const value = this.get(key);
    return Number.parseFloat(value);
  }
  /**
   * Get Json item stored as `key`
   * @param key key of the item
   * @returns Item value
   */
  public getJson(key: string): any {
    const value = this.get(key);
    return JSON.parse(value);
  }
  /**
   * Get Object<T> item stored as `key`
   * @param key key of the item
   * @returns Item value
   */
  public getObject<T>(key: string): T {
    return this.getJson(key);
  }
  /**
   * Get Array<T> item stored as `key`
   * @param key key of the item
   * @returns Item value
   */
  public getArray<T>(key: string): Array<T> {
    return this.getJson(key);
  }
  /**
   * Store `value` as `key` in localStorage
   * @param key key of the item
   * @param value item value
   */
  public put(key: string, value: any): void {
    this.localStorage.setItem(key, value);
  }
  /**
   * Convert Json `value` to Object and store as `key` in localStorage
   * @param key key of the item
   * @param value item value
   */
  public putJson(key: string, json: any): void {
    this.put(key, JSON.stringify(json));
  }

  /**
   * Whether item `key` exists
   * @param key key of the item
   * @returns true if exists
   */
  public existsSession(key: string): boolean {
    return this.sessionStorage.getItem(key) !== null;
  }

  /**
   * Get item stored as `key` in sessionStorage
   * @param key key of the item
   * @returns Item value
   */
  public getSession(key: string): any {
    return this.sessionStorage.getItem(key);
  }

  /**
   * Get Json item stored as `key` in sessionStorage
   * @param key key of the item
   * @returns Item value
   */
  public getSessionJson(key: string): any {
    const value = this.getSession(key);
    return JSON.parse(value);
  }

  /**
   * Store `value` as `key` in sessionStorage
   * @param key key of the item
   * @param value item value
   */
  public putSession(key: string, value: any): void {
      this.sessionStorage.setItem(key, value);
  }

  /**
   * Convert Json `value` to Object and store as `key` in sessionStorage
   * @param key key of the item
   * @param value item value
   */
  public putSessionJson(key: string, json: any): void {
    this.putSession(key, JSON.stringify(json));
  }


  public putStorageJson(key: string, json: any) {
    if(json && Array.isArray(json) && json.length < 100) {
      try {
        this.sessionStorage.setItem(key, JSON.stringify(json));
        
      } catch (error) {
        try {
          this.localStorage.setItem(key, JSON.stringify(json));
        } catch (error2) {
          // console.log(error2);
        }
      }
    } else {
      try {
        this.localStorage.setItem(key, JSON.stringify(json));
      } catch (error) {
        try {
          this.sessionStorage.setItem(key, JSON.stringify(json));
        } catch (error2) {
          // console.log(error2);
        }
      }
    }  
  }
  
  public getStorageJson(key: string) {
    let value = this.sessionStorage.getItem(key);
    if (value === null ) {
      value = this.localStorage.getItem(key);
    }
    return JSON.parse(value);
    
  }
  
  public existsStorage(key: string): boolean {
    return (this.sessionStorage.getItem(key) !== null && this.sessionStorage.getItem(key) !== 'null' && JSON.stringify(this.sessionStorage.getItem(key)).length > 4 ) 
          || (this.localStorage.getItem(key) !== null && this.localStorage.getItem(key) !== 'null'   && JSON.stringify(this.localStorage.getItem(key)).length > 4) ;
  }
}
