import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { WagonService } from '../../services/wagon/wagon.service';
import { Subscription } from 'rxjs';

/**
 * 'WagonToggleComponent' display toggle button for WagonComponent
 */
@Component({
  selector: 'app-wagon-toggle',
  templateUrl: './wagon-toggle.component.html',
  styleUrls: ['./wagon-toggle.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WagonToggleComponent implements OnDestroy {
  /** retain display status */
  display = false;
  sub: Subscription;
  /**
   * WagonToggleComponent constructor
   * @param wagonService Inject service to retrieve wagon datas
   */
  constructor(public wagonService: WagonService) {
    this.sub = this.wagonService.getCurrentWagonId()
      .subscribe( (val) => {
        if (val === null) {
          this.display = false;
          this.wagonService.setDisplayStatus(false);
        }
      });
  }
  ngOnDestroy(): void {
    if (this.sub && !this.sub.closed) { this.sub.unsubscribe(); }
  }
  /**
   * Toggle wagon display status
   */
  toggleWagon(): void {
    this.display = !this.display;
    this.wagonService.setDisplayStatus(this.display);
  }
}
