import { OverhaulFrequency } from './overhaul-frequency';
import { OverhaulNature } from './overhaul-nature';
import { Workshop } from './workshop';
import { PlateChange } from './plate-change';
import { PlateChangeReason } from './plate-change-reason';

/** API model:Table: CARTOUCHE_REV_MAJ  */
export class OverhaulPlateUpdate {
    /** CREVMAJ_WPHY_ID_PFK	NUMBER(10,0) */
    wagonId: number;
    /** CREVMAJ_TYPE_PK VARCHAR2(2 BYTE) */
    type: string;
    /** originFrequency.code = CREVMAJ_PERREV_ORIG_FK VARCHAR2(2 BYTE) */
    originFrequency: OverhaulFrequency;
    /** overhaulNature.code = CREVMAJ_NATREV_CODE_FK VARCHAR2(3 BYTE) */
    overhaulNature: OverhaulNature;
    /** originWorkshop.code = CREVMAJ_ETAB_ORIG_FK VARCHAR2(5 BYTE) */
    originWorkshop: Workshop;
    /** frequency.code = CREVMAJ_PERREV_ACTUEL_FK VARCHAR2(2 BYTE) */
    frequency: OverhaulFrequency;
    /** change.code = CREVMAJ_CMODIF_CODE_FK VARCHAR2(1 BYTE) */
    change: PlateChange;
    /** changeReason.code = CREVMAJ_CMTF_CODE_FK VARCHAR2(1 BYTE) */
    changeReason: PlateChangeReason;
    /** changeWorkshop.code = CREVMAJ_ETAB_MODIF_FK VARCHAR2(5 BYTE) */
    changeWorkshop: Workshop;
    /** CREVMAJ_DATE_ACTUEL DATE */
    actual: Date;
    /** CREVMAJ_RANG NUMBER(3,0) */
    rank: number;
    /** CREVMAJ_DATE_ORIG DATE */
    origin: Date;
    /** CREVMAJ_FLAG_MODIF NUMBER(1,0) */
    wasChangedOutOfRev: boolean;
    /** CREVMAJ_DATE_MODIF DATE */
    update: Date;
    /** CREVMAJ_PLS_CODE_FK	VARCHAR2(9 BYTE) */
    pls: string;
}
