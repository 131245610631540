import { LineCategoryComposition } from './line-category-composition';
import { TransitRegimeComposition } from './transit-regime-composition';
import { WagonTechnicalData } from './wagon-technical-data';
import { WagonTechnicalDataDetail } from './wagon-technical-data-detail';
import { WagonUpdateHistory } from './wagon-update-history';

/** API model: Page MAJ_003_5 Suivi des Travaux / MAJ Tare et charges */
export class TareLoads {

  wagonID: number;

  wagonTechnicalData: WagonTechnicalData;

  wagonTechnicalDataDetail: WagonTechnicalDataDetail;

  wessNumber: number;

  history: WagonUpdateHistory;

  geographyChanged: boolean;

  exceptionalLoadChanged: boolean;

  speedLimitChanged: boolean;

  maxPassengerTrainSpeedChanged: boolean;

  derogatoryRegimeChanged: boolean;

  requiredRegimes: string[];

  mNbEss: number;

  mChargeMinEssRef: number;

  lineCategoryCompositionList: LineCategoryComposition[];

  transitRegimeCompositionList: TransitRegimeComposition[];
}
