import { FaqQuestion } from './faq-question';

/** API model:Table: FAQ_THEMES */
export class FaqTheme {
  /** FTHE_ID_PK	NUMBER(10,0) */
  id: number;
  /** FTHE_LIB	VARCHAR2(100 BYTE) */
  label: string;
  /** FTHE_CONTACT	VARCHAR2(250 BYTE) */
  contact: string;
  /** FQUE_FTHE_ID_FK = FTHE_ID_PK	NUMBER(10,0) */
  questionList: FaqQuestion[];
}
