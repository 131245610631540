import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

/** Template of an error 403 page */
@Component({
  selector: 'app-error-403',
  templateUrl: './error-403.component.html',
  styleUrls: ['./error-403.component.scss']
})
export class Error403Component implements OnInit {
  /**
   * `Error403Component` constructor
   */
  data: any;
  constructor(private router: Router) {}
  /** OnInit hook  */
  ngOnInit() {
    this.data = history.state;
  }
}
