import { Component, Input } from '@angular/core';
import { InputConfig } from '../../../../../shared/components/form-input/form-input.component';
import { ActionBarAdComponent } from '../../../../../core/components/action-bar/action-bar-ad-component';

@Component({
  selector: 'app-wag-action-bar-custom',
  templateUrl: './wag-action-bar-custom.component.html',
  styleUrls: ['./wag-action-bar-custom.component.scss']
})
export class WagActionBarCustomComponent implements ActionBarAdComponent {
  /** implemented `AdComponent` data property , contain values used in action bar */
  @Input() data: any;
  searchModel = '';
  /** ngModel binded variable for search field */
  // searchModel: string;
  searchConfig: InputConfig = {
    small: true,
    icon: {
      position: 'left',
      name: 'train',
      class: 'material-icons'
    },
    append: {
      type : 'button',
      config: {
        icon_class: 'icons-search',
        callback: ($e: Event) => this.data.component.loadWagonByMex(this.searchModel)
      }
    }
  };
}
