import { ComponentGroup } from './component-group';
import { Components } from './components';
import { Defect } from './defect';
import { Job } from './job';
import { JobDefect } from './job-defect';
import { MaintenancePlanUpdate } from './maintenance-plan-update';
import { StateJob } from './state-job';
import { TTache } from './t-tache';

/** */
export class TaskParams {
    wagonId?: number;
    
    fromAddLine: boolean;

    mpu: MaintenancePlanUpdate;

    taskDialogDatas: {
        stateJobs: StateJob[],
        componentGroups: ComponentGroup[],
        components: Components[],
        jobs: Job[],
        defects: Defect[],
        jobDefects: JobDefect[],
        tTaches: TTache[]
      };
}
