import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { DialogCancelConfirmComponent } from 'src/app/shared/components/dialog-cancel-confirm/dialog-cancel-confirm.component';
import { TechSpecReferences } from 'src/app/shared/models/tech-spec-references';
import { WagonTechnicalData } from "../../../../../models/wagon-technical-data";
import { OpenWagonBearingComponent } from './open-wagon-bearing/open-wagon-bearing.component';
import { OpenWagonBrakeComponent } from './open-wagon-breake/open-wagon-brake.component';
import { OpenWagonBumpTractionComponent } from './open-wagon-bump-traction/open-wagon-bump-traction.component';
import { OpenWagonFrameSuspensionComponent } from './open-wagon-frame-suspension/open-wagon-frame-suspension.component';
import { OpenWagonParticularitiesComponent } from './open-wagon-particularities/open-wagon-particularities.component';
import { OpenWagonSuperstructureComponent } from './open-wagon-superstructure/open-wagon-superstructure.component';


@Component({
  selector: 'app-technical-data',
  templateUrl: './technical-data.component.html',
  styleUrls: ['./technical-data.component.scss']
})
export class TechnicalDataComponent implements OnInit, OnDestroy {

  @Input() public wagonTechnicalData: WagonTechnicalData;
  @Input() public wagonTechnicalDataWO: WagonTechnicalData;
  @Input() public techSpecReferences: TechSpecReferences;
  @Output() modifiedDataEvent = new EventEmitter();
  @Output() wagonTechnicalDataWOChange = new EventEmitter<WagonTechnicalData>();
  @ViewChild('superstructureTab') superstructureTab: OpenWagonSuperstructureComponent;
  @ViewChild('bearingTab') bearingTab: OpenWagonBearingComponent;
  @ViewChild('brakeTab') brakeTab: OpenWagonBrakeComponent;
  @ViewChild('tractionTab') tractionTab: OpenWagonBumpTractionComponent;
  @ViewChild('suspensionTab') suspensionTab: OpenWagonFrameSuspensionComponent;
  @ViewChild('particularitiesTab') particularitiesTab: OpenWagonParticularitiesComponent;

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);

  techDataCurrentTab: number;
  tabsDirty = [false, false, false, false];

  constructor(
    private translateService: TranslateService,
    private dialog: MatDialog) {
      this.lang = this.translateService.getBrowserLang().match(/en|fr/)
        ? this.translateService.getBrowserLang() : 'en';
  }

  async ngOnInit() {
    this.loadingSubject.next(true);
    this.resetDatas();
    this.loadInitDatas();
    this.techDataCurrentTab = 1;
  }

  /**
   * Reset les donnes
   */
  private resetDatas(): void {
    this.techDataCurrentTab = undefined;
    this.tabsDirty = [false, false, false, false];
  }

  changeTab(value: number): void {
    if (value === this.techDataCurrentTab) return;

    if (this.tabsDirty[this.techDataCurrentTab - 1]) {
      // Show confirm dialog if there's modified data
      const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
        data: {
          title: 'core.messages.not_saved_data',
          message: 'core.messages.save_question',
          cancelLabel: 'actions.quit',
          confirmLabel: 'actions.save'
        }
      });
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.saveCurrentTab();
        } else {
          this.techDataCurrentTab = value;
        }
      });
    } else {
      // Change without confirmation
      this.techDataCurrentTab = value;
    }
  }

  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
    this.loadingSubject.next(false);
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

  setTabDirty(formIsDirty: boolean, tab: number): void {
    this.tabsDirty[tab] = formIsDirty;
    this.modifiedDataEvent.emit(this.tabsDirty.reduce((p, c) => p || c, false));
  }

  saveCurrentTab(): void {
    if (this.techDataCurrentTab === 1) {
      this.superstructureTab.onSubmit();
    } else if (this.techDataCurrentTab === 2) {
      this.bearingTab.onSubmit();
    } else if (this.techDataCurrentTab === 3) {
      this.brakeTab.onSubmit();
    } else if (this.techDataCurrentTab === 4) {
      this.tractionTab.onSubmit();
    } else if (this.techDataCurrentTab === 5) {
      this.suspensionTab.onSubmit();
    } else if (this.techDataCurrentTab === 6) {
      this.particularitiesTab.onSubmit();
    }
  }
  async saveDirtyTabs() {
    this.loadingSubject.next(true);
    if (this.superstructureTab.superstructureForm.dirty) {
      await this.superstructureTab.onSubmit(false);
    } 
    if (this.bearingTab.bearingForm.dirty) {
      await this.bearingTab.onSubmit(false);
    } 
    if (this.brakeTab.breakForm.dirty) {
      await this.brakeTab.onSubmit(false);
    } 
    if (this.tractionTab.bumpTractionForm.dirty) {
      await this.tractionTab.onSubmit(false);
    } 
    if (this.suspensionTab.frameSuspensionForm.dirty) {
      await this.suspensionTab.onSubmit(false);
    } 
    if (this.particularitiesTab.particularitiesForm.dirty) {
      await this.particularitiesTab.onSubmit(false);
    }    
    this.loadingSubject.next(false);
  }

  updateWagTechWO(value: WagonTechnicalData) {
    this.wagonTechnicalDataWO = value;
    this.wagonTechnicalDataWOChange.emit(this.wagonTechnicalDataWO);
  }
}
