import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/services/app-api/api-response.model';
import { AppApiService } from 'src/app/core/services/app-api/app-api.service';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { CamTask } from '../../models/cam-task';
import { FamilyRestrictions } from '../../models/family-restrictions';
import { IglCam } from '../../models/igl-cam';
import { IglDefautCuu } from '../../models/igl-defaut-cuu';
import { MessageCodeError } from '../../models/message-code-error';
import { MessageError } from '../../models/message-error';
import { MonitoringOfWork } from '../../models/monitoring-work';
import { RefMaterial } from '../../models/ref-material';
import { RefMaterialUpdate } from '../../models/ref-material-udpate';
import { TareLoads } from '../../models/tare-loads';
import { TechSpecBrake } from '../../models/tech-spec-brake';
import { TechSpecParticularities } from '../../models/tech-spec-particularities';
import { TechSpecRolling } from '../../models/tech-spec-rolling';
import { TechSpecShockTraction } from '../../models/tech-spec-shock-traction';
import { TechSpecSuperstructure } from '../../models/tech-spec-superstructure';
import { TechSpecSuspensionChassis } from '../../models/tech-spec-suspension-chassis';
import { CartridgeATS } from '../../models/cartridge-ats';
import { TechSpecReferences } from '../../models/tech-spec-references';
import { WagonUpdate } from '../../models/wagon-update';
import { WagonTechnicalData } from '../../models/wagon-technical-data';
import { AuxiliaryTankElement } from '../../models/auxiliary-tank-element';
import { OutputAuxiliaryTankElement } from '../../models/output-auxiliary-tank-element';


@Injectable({
  providedIn: 'root'
})
export class MonitoringWorkService {
  /**
   * MaintenancePlanUpdateService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) { }

  public loadTechSpecReferences(): Observable<ApiResponse<TechSpecReferences>> { 
    return this.api.get<ApiResponse<TechSpecReferences>>(`/monitoring-work/technical/references`)
      .pipe(
        catchError(this.handleError<any>('loadTechSpecReferences', { data: null }))
      );
  }
  /**
   * Obtain the data for the screen MAJ_003_1 - Suivis des travaux
   * @param id the wagon's id 
   */
  public loadMonitoringOfWorkPageDatas(id: number): Observable<ApiResponse<MonitoringOfWork>> {
    return this.api.get<ApiResponse<MonitoringOfWork>>(`/monitoring-work/${id}`)
      .pipe(
        catchError(this.handleError<any>('loadMonitoringOfWorkPageDatas', { data: null }))
      );
  }

  /**
  * update records of the data for the screen MAJ_003_1 - Suivis des travaux
  * @param element: MonitoringOfWork datas
  */
  public updateMonitoringOfWorkPageDatas(element: MonitoringOfWork): Observable<ApiResponse<MessageError>> {
    return this.api.post<ApiResponse<MessageError>>(`/monitoring-work`, element)
      .pipe(
        catchError(this.handleError<any>('updateMonitoringOfWorkPageDatas', null))
      );
  }

  /**
   * Obtain the data for the screen MAJ_003_4_1 – Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Superstructure »
   * @param id the wagon's id
   */
  public loadTechSpecSuperstructureDatas(id: number): Observable<ApiResponse<TechSpecSuperstructure>> {
    return this.api.get<ApiResponse<TechSpecSuperstructure>>(`/monitoring-work/${id}/technical/superstructure`)
      .pipe(
        catchError(this.handleError<any>('loadTechSpecSuperstructurePageDatas', { data: null }))
      );
  }

  /**
   * Obtain the data for the screen MAJ_003_5_1 – Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Châssis et Suspension » page
   * @param id the wagon's id
   */
  public loadTechSpecSuspensionChassisPageDatas(id: number): Observable<ApiResponse<TechSpecSuspensionChassis>> {
    return this.api.get<ApiResponse<TechSpecSuspensionChassis>>(`/monitoring-work/${id}/technical/suspension-chasis`)
      .pipe(
        catchError(this.handleError<any>('loadTechSpecSuspensionChassisPageDatas', { data: null }))
      );
  }

  /**
   * update records of 'MAJ_003_5_1 – Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Châssis et Suspension » page
   * @param element: TechSpecSuspensionChassis datas
   */
  public updateTechSpecSuspensionChassis(element: TechSpecSuspensionChassis): Observable<ApiResponse<TechSpecSuspensionChassis>> {
    return this.api.post<ApiResponse<TechSpecSuspensionChassis>>(`/monitoring-work/technical/suspension-chasis`, element)
      .pipe(
        catchError(this.handleError<any>('updateTechSpecSuspensionChassis', null))
      );
  }

  /**
   * Obtain the data for the screen MAJ_003_4_6 Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Particularités  » page
   * @param id the wagon's id
   */
  public loadParticularitiesPageDatas(id: number): Observable<ApiResponse<TechSpecParticularities>> {
    return this.api.get<ApiResponse<TechSpecParticularities>>(`/monitoring-work/${id}/technical/particularities`)
      .pipe(
        catchError(this.handleError<any>('loadParticularitiesPageDatas', { data: null }))
      );
  }

  /**
   * update records of 'MAJ_003_4_6 Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Particularités  » page
   * @param element: TechSpecParticularities datas
   */
  public updateTechSpecParticularities(element: TechSpecParticularities): Observable<ApiResponse<TechSpecParticularities>> {
    return this.api.post<ApiResponse<TechSpecParticularities>>(`/monitoring-work/technical/particularities`, element)
      .pipe(
        catchError(this.handleError<any>('updateTechSpecParticularities', null))
      );
  }

  /**
   * Obtain the data for the screen MAJ_003_5 Suivi des Travaux / MAJ Tare et charges
   * @param id the wagon's id
   */
  public loadTareLoads(id: number): Observable<ApiResponse<TareLoads>> {
    return this.api.get<ApiResponse<TareLoads>>(`/monitoring-work/${id}/technical/tare-loads`)
      .pipe(
        catchError(this.handleError<any>('loadTareLoads', { data: null }))
      );
  }

  /**
   * Validate speed values for MAJ_003_5 Suivi des Travaux / MAJ Tare et charges
   * @param speedValue speed value
   * @param speedCode speed code
   */
  public controlSpeedTareLoads(speedValue: string, speedCode: string): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('speedValue', `${speedValue}`);
    parameters = parameters.set('speedCode', `${speedCode}`);
    return this.api.get<ApiResponse<boolean>>(`/monitoring-work/control/tare-loads`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('controlSpeedTareLoads', { data: null }))
      );
  }

  /**
   * update records of 'MAJ_003_5 Suivi des Travaux / MAJ Tare et charges' page
   * @param tareLoads: tareLoads datas
   */
  public updateTareLoads(tareLoads: TareLoads): Observable<Response> {
    return this.api.put<Response>(`/monitoring-work/tare-loads`, tareLoads, { observe: 'response' })
      .pipe(
        catchError(this.handleError<any>('updateTareLoads', null))
      );
  }

  public downloadTareLoadsReport(id: number, mex: string): Observable<{blob: Blob, filename: string}> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    return this.api.get<Blob>(`/monitoring-work/${id}/tare-loads/pdf`, {params: parameters, responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadTareLoadsReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'BDT_' + id + '.pdf'};
      })
    );
  }

  /**
   * update records of 'MAJ_003_4_1 – Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Superstructure »' page
   * @param element: TechSpecSuperstructure datas
   */
  public updateTechSpecSuperstructure(element: TechSpecSuperstructure): Observable<ApiResponse<TechSpecSuperstructure>> {
    return this.api.post<ApiResponse<TechSpecSuperstructure>>(`/monitoring-work/technical/superstructure`, element)
      .pipe(
        catchError(this.handleError<any>('updateTechSpecSuperstructure', { data: null }))
      );
  }

  /**
   * controls for 'MAJ_003_6 Suivi des Travaux / MAJ Famille et Restriction' page
   * @param familyRest: FamilyRestrictions datas
   */
  public controlFamilyRestrictions(id: number, element: FamilyRestrictions): Observable<ApiResponse<MessageError>> {
    return this.api.post<ApiResponse<MessageError>>(`/monitoring-work/${id}/control/family-restrictions`, element)
      .pipe(
        catchError(this.handleError<any>('controlFamilyRestrictions', null))
      );
  }

  /**
   * update records of 'MAJ_003_6 Suivi des Travaux / MAJ Famille et Restriction' page
   * @param familyRest: FamilyRestrictions datas
   */
  public updateFamilyRestrictions(id: number, element: FamilyRestrictions): Observable<ApiResponse<WagonTechnicalData>> {
    return this.api.post<ApiResponse<WagonTechnicalData>>(`/monitoring-work/${id}/family-restrictions`, element)
      .pipe(
        catchError(this.handleError<any>('updateFamilyRestrictions', null))
      );
  }

  /**
   * Obtain the data for the screen MAJ_003_4_2 Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Roulement » page
   * @param id the wagon's id
   */
  public loadRollingPageDatas(id: number): Observable<ApiResponse<TechSpecRolling>> {
    return this.api.get<ApiResponse<TechSpecRolling>>(`/monitoring-work/${id}/technical/rolling`)
      .pipe(
        catchError(this.handleError<any>('loadRollingPageDatas', { data: null }))
      );
  }

  /**
  * update records of 'MAJ_003_4_1 – Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Roulement »' page
  * @param element: TechSpecRolling datas
  */
  public updateTechSpecRolling(element: TechSpecRolling): Observable<ApiResponse<TechSpecRolling>> {
    return this.api.post<ApiResponse<TechSpecRolling>>(`/monitoring-work/technical/rolling`, element)
      .pipe(
        catchError(this.handleError<any>('updateTechSpecRolling', null))
      );
  }

  /**
  * Contrôles de cohérence globaux Caractéristiques Techniques – Onglet « Roulement» page
  * @param element: TechSpecRolling datas
  */
  public consistencyChecksRolling(element: TechSpecRolling): Observable<ApiResponse<MessageError>> {
    return this.api.post<ApiResponse<MessageError>>(`/monitoring-work/technical/consistency-check/rolling`, element)
      .pipe(
        catchError(this.handleError<any>('consistencyChecksRolling', null))
      );
  }

  /**
  * Contrôles de cohérence globaux / MAJ Caractéristiques Techniques – Onglet « Suspension Chasis» page
  * @param element: string datas
  */
  public checkCompatibilitySuspensionChassis(liaison: string, element: string[]): Observable<ApiResponse<MessageError>> {
    return this.api.post<ApiResponse<MessageError>>(`/monitoring-work/technical/consistency-check/${liaison}/suspension-chasis`, element)
      .pipe(
        catchError(this.handleError<any>('checkCompatibilitySuspensionChassis', null))
      );
  }

  /**
   * Obtain the data for the screen MAJ_003_4_3 Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Frein » page
   * @param id the wagon's id
   */
  public loadBrakePageDatas(id: number): Observable<ApiResponse<TechSpecBrake>> {
    return this.api.get<ApiResponse<TechSpecBrake>>(`/monitoring-work/${id}/technical/brake`)
      .pipe(
        catchError(this.handleError<any>('loadBrakePageDatas', { data: null }))
      );
  }

  /**
   * update records of 'MAJ_003_4_3 – Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Frein »' page
   * @param element: TechSpecBrake datas
   */
  public updateTechSpecBrake(element: TechSpecBrake): Observable<ApiResponse<TechSpecBrake>> {
    return this.api.post<ApiResponse<TechSpecBrake>>(`/monitoring-work/technical/brake`, element)
      .pipe(
        catchError(this.handleError<any>('updateTechSpecBrake', null))
      );
  }

  /**
   * Obtain the data for the screen MAJ_003_4_4 Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Choc Traction » page
   * @param id the wagon's id
   */
  public loadShockTractionPageDatas(id: number): Observable<ApiResponse<TechSpecShockTraction>> {
    return this.api.get<ApiResponse<TechSpecShockTraction>>(`/monitoring-work/${id}/technical/shockTraction`)
      .pipe(
        catchError(this.handleError<any>('loadShockTractionPageDatas', { data: null }))
      );
  }

  /**
   * update records of 'MAJ_003_4_4 – Suivi des Travaux / MAJ Caractéristiques Techniques – Onglet « Choc Traction »' page
   * @param element: TechSpecShockTraction datas
   */
  public updateTechSpecShockTraction(element: TechSpecShockTraction): Observable<ApiResponse<TechSpecShockTraction>> {
    return this.api.post<ApiResponse<TechSpecShockTraction>>(`/monitoring-work/technical/shockTraction`, element)
      .pipe(
        catchError(this.handleError<any>('updateTechSpecShockTraction', null))
      );
  }

  /**
  * Obtain the data for the screen MAJ_003_2 Monitoring of Work / Management of Expectations
  * @param id 
 */
  public getManagementExpectationsDatas(id: number, pageable?: Pageable): Observable<ApiResponse<RefMaterialUpdate[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<RefMaterialUpdate[]>>(`/monitoring-work/${id}/management-expectations`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getManagementExpectationsDatas', { data: [] }))
      );
  }

  /**
    * update records of 'MAJ_003_2 Monitoring of Work / Management of Expectations' page
    * @param RefMaterialUpdate: RefMaterialUpdate datas
    */
  public updateManagementExpectations(id: number, element: RefMaterialUpdate[]): Observable<ApiResponse<RefMaterialUpdate[]>> {
    return this.api.post<ApiResponse<RefMaterialUpdate[]>>(`/monitoring-work/${id}/management-expectations`, element)
      .pipe(
        catchError(this.handleError<any>('updateManagementExpectations', null))
      );
  }

  /**
   * Obtain all Valid RefMaterial
   * @param search 
  */
  public getAllValidRefMaterial(search?: string, pageable?: Pageable): Observable<ApiResponse<RefMaterial[]>> {
    let parameters: HttpParams = new HttpParams();
    if (search !== undefined) {
      parameters = parameters.set('code', `${search}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key));
      });
    }
    return this.api.get<ApiResponse<RefMaterial[]>>(`/monitoring-work/ref-material`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getAllValidRefMaterial', { data: [] }))
      );
  }


  /**
  * Service that controls the CAM to add. Screen MAJ_003_1_1 Work monitoring / "Add a line" pop-up
  * @param id the wagon's id 
  * @param cam the cam
  * @param operationType operationType = 0 --> Check to create CAM; operationType = 1 --> Check to update CAM
  */
  public checkCAM(id: number, cam: string, operationType: number): Observable<ApiResponse<MessageError>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('code', `${cam}`);
    parameters = parameters.set('operationType', `${operationType}`);
    return this.api.get<ApiResponse<MessageError>>(`/monitoring-work/${id}/check-cam`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('checkCAM', { data: null }))
      );
  }

  /**
   * Service that controls the Default to add. Screen MAJ_003_1_1 Work monitoring / "Add a line" pop-up
   * @param id the wagon's id 
   * @param default the cam
   */
  public checkDefault(id: number, defaut: string): Observable<ApiResponse<MessageError>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('code', `${defaut}`);
    return this.api.get<ApiResponse<MessageError>>(`/monitoring-work/${id}/check-default`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('checkDefault', { data: null }))
      );
  }

  /**
   *  Service to create a default. 
   * @param id the wagon's id
   * @param defaut the defaut code
   */
  public createDefault(idDefCuu: IglDefautCuu, defaut: string): Observable<ApiResponse<IglDefautCuu>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('code', `${defaut}`);
    return this.api.post<ApiResponse<IglDefautCuu>>(`/monitoring-work/default`, idDefCuu, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('createDefault', null))
      );
  }

  /**
   *  Service to modify CAM status. 
   * @param id the wagon's id
   * @param defaut the CAM code
   * @param status the status
   */
  public updateCAM(element: any, defaut: string, status: string): Observable<ApiResponse<IglCam>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('code', `${defaut}`);
    parameters = parameters.set('status', `${status}`);
    return this.api.post<ApiResponse<IglCam>>(`/monitoring-work/update/cam`, element, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('updateCAM', null))
      );
  }

  /**
   *  Service to modify default status. 
   * @param id the wagon's id
   * @param defaut the defaut code
   * @param status the status
   */
  public updateDefault(element: any, defaut: string, status: string): Observable<ApiResponse<IglDefautCuu>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('code', `${defaut}`);
    parameters = parameters.set('status', `${status}`);
    return this.api.post<ApiResponse<IglDefautCuu>>(`/monitoring-work/update/default`, element, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('updateDefault', null))
      );
  }

  /**
   *  Service to create a CAM. 
   * @param id the wagon's id
   * @param cam the CAM code
   */
  public createCAM(idCam: IglCam, cam: string): Observable<ApiResponse<IglCam>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('code', `${cam}`);
    return this.api.post<ApiResponse<IglCam>>(`/monitoring-work/cam`, idCam, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('createCAM', null))
      );
  }

  /**
  * Obtain task linked to CAM
  * @param cam code cam
  * @param caseType type case
 */
  public getCamTask(cam: string, caseType: number): Observable<ApiResponse<CamTask[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('caseType', `${caseType}`);
    return this.api.get<ApiResponse<CamTask[]>>(`/monitoring-work/${cam}/cam-task`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getCamTask', { data: [] }))
      );
  }

  /**
 * Service to create CAM Task
 * @param id WagonId
 * @param caseType type case
*/
  public createCamTask(id: number, element: CamTask[], caseType: number): Observable<ApiResponse<MessageError>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('caseType', `${caseType}`);
    return this.api.post<ApiResponse<MessageError>>(`/monitoring-work/${id}/save/cam-task`, element, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getCamTask', { data: null }))
      );
  }


  /**
   * Service qui controle le defaut au ajouter. Ecran MAJ_003_1 Suivi des Travaux / Bouton « Enregistrer
   * @param wagonId type case
   */
  public controlFinishedWorks(wagonId: number): Observable<ApiResponse<MessageCodeError>> {
    return this.api.get<ApiResponse<MessageCodeError>>(`/monitoring-work/${wagonId}/controlFinishedWorks`)
      .pipe(
        catchError(this.handleError<any>('controlFinishedWorks', null))
      );
  }

  /**
    * Check if the the information for a cartridge-ats is valid and returns a valid frequency
    * @param wagonId
    */
  public checkFrequencyATS(wagonId: number): Observable<ApiResponse<number>> {
    return this.api.get<ApiResponse<number>>(`/monitoring-work/cartridge-ats/${wagonId}`)
      .pipe(
        catchError(this.handleError<any>('checkFrequencyATS', { data: null }))
      );
  }

  /**
     * Check if the relation natureRev and frequencyRev is valid
     * @param natureRev
     * @param frequencyRev
     */
  public checkNatRevPerRev(natureRev: string, frequencyRev: string): Observable<ApiResponse<boolean>> {
    return this.api.get<ApiResponse<boolean>>(`/monitoring-work/natrev-perrev/${natureRev}/${frequencyRev}`)
      .pipe(
        catchError(this.handleError<any>('checkNatRevPerRev', { data: null }))
      );
  }

  

  /**
   * MAJ_003_3 Suivi des Travaux / MAJ Cartouche de Maintenance Enregistrement des données en base
   * @param element: CartridgeATS data
   */
   public updateMaintenanceCartridge(element: CartridgeATS): Observable<ApiResponse<WagonUpdate>> {
    return this.api.post<ApiResponse<WagonUpdate>>(`/monitoring-work/cartridge`, element)
    .pipe(
      catchError(this.handleError<any>('updateMaintenanceCartridge', {data: null}))
    );
  }

  /**
   * Nombre d’éléments du wagon
   * @param mex 
   */
  public getNumberOfElementsOfWagon(id: number): Observable<ApiResponse<number>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${id}`);
    return this.api.get<ApiResponse<number>>(`/monitoring-work/technical/brake/numberWagon`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getNumberOfElementsOfWagon', { data: null }))
      );
  }

  /**
   * RG_MAJ_003_4_3_7 : nombre d’essieux du wagon** 
   * @param mex 
   */
  public getNumberOfAxlesOfWagon(mex: string): Observable<ApiResponse<number>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    return this.api.get<ApiResponse<number>>(`/monitoring-work/technical/brake/numberAxles`, { params: parameters })
      .pipe(
        catchError(this.handleError<any>('getNumberOfAxlesOfWagon', { data: null }))
      );
  }

  public checkAuxTank(number: string, wagonId: number): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('number', `${number}`);
    parameters = parameters.set('wagon', `${wagonId}`);
    return this.api.get<ApiResponse<boolean>>(`/monitoring-work/check-aux-tank`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('checkAuxTank', { data: null }))
    );
  }
  getAuxTank(number: string): Observable<ApiResponse<AuxiliaryTankElement>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('number', `${number}`);
    return this.api.get<ApiResponse<boolean>>(`/monitoring-work/aux-tank`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getAuxTank', { data: null }))
    );
  }

  getOutputAuxiliaryTankElementList(wagonId: number): Observable<ApiResponse<OutputAuxiliaryTankElement[]>> {
    return this.api.get<ApiResponse<OutputAuxiliaryTankElement[]>>(`/monitoring-work/${wagonId}/output-aux-tanks`)
      .pipe(
        catchError(this.handleError<any>('getOutputAuxiliaryTankElementList', null))
      );
  }
  
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
}
