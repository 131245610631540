import { Components } from './components';
import { Job } from './job';
import { ExpertiseTaskMaintenanceAid } from './expertise-task-maintenance-aid';
import { ExpertiseTaskRefWork } from './expertise-task-ref-work';
import { ExpertiseTaskRefMaterial } from './expertise-task-ref-material';
import { Media } from './media';
/** API model:Table: R_EXP_TCH   */
export class ExpertiseTask {
  /** component.code = EXPTCH_ORG_CODE_PFK VARCHAR2(4 BYTE) */
  component: Components;
  /** job.code = EXPTCH_TRA_CODE_PFK VARCHAR2(3 BYTE) */
  job: Job;
  /** EXPTCH_NUAGECP_MAJ_FK VARCHAR2(8 BYTE) */
  updateAgentCp: string;
  /** EXPTCH_DH_MAJ DATE */
  update: Date;
  /** EXPTCH_NUAGECP_REAL_FK VARCHAR2(8 BYTE) */
  achievementAgentCp: string;
  /** EXPTCH_DH_REAL DATE */
  achievement: Date;
  /** EXPTCH_OBSERVATION VARCHAR2(30 BYTE) */
  observation: string;
  /** taskMaintenanceAidList[x].maintenanceAid.code = R_EXP_TCH_CAM.ETCHCAM_CAM_CODE_PFK */
  taskMaintenanceAidList: ExpertiseTaskMaintenanceAid [ ];
  /** taskRefMaterialList[x].refMaterial.code = R_EXP_TCH_MATREF.ETCHMATREF_MATREF_CODE_PFK */
  taskRefMaterialList: ExpertiseTaskRefMaterial [ ];
  /** taskRefWorkList[x].refWork.code = R_EXP_TCH_WREF.ETCHWREF_WREF_CODE_PFK */
  taskRefWorkList: ExpertiseTaskRefWork [ ];
  /**
   * PJ_PFO_ID = EXPTCH_EXP_ID_PFK
   * AND PJ_TRA_CODE_FK =  R_EXP_TCH.EXPTCH_TRA_CODE_PFK
   * AND PJ_ORG_CODE_FK = R_EXP_TCH.EXPTCH_ORG_CODE_PFK
   */
  mediaList: Media[];
}
