import { BrakeParticularity } from './brake-particularity';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_PARTFREIN */
export class WagonBrakeParticularity {
     /** WPARTF_WCAR_MEX_PFK VARCHAR2(14 BYTE) */
     mex: string;
     /** inventory.code = WPARTF_WCAR_INV_CODE_PFK VARCHAR2(2 BYTE) */
     inventory: Inventory;
    /** particularity.code	WPARTF_PARTFREIN_CODE_PFK VARCHAR2(2 BYTE) */
    particularity: BrakeParticularity;
    /** WPARTF_NBRE NUMBER(3,0) */
    amount: number;
}
