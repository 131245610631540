/** API model:Table: HISTO_WAG_MAJ  */
export class WagonUpdateHistory {
  /** HWMAJ_WPHY_ID_PFK		NUMBER(10,0) */
  wagonId: number;
  /** HWMAJ_PFO_CODE_PFK		VARCHAR2(6 BYTE) */
  functionalProcedureCode: string;
  /** HWMAJ_NUAGECP_PFK		VARCHAR2(8 BYTE) */
  agentCp: string;
  /** HWMAJ_DH_MAJ_AGENT		DATE */
  update: Date;
}
