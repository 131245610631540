import { Component, OnInit, OnDestroy} from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import {Chart} from 'chart.js';
import { Subscription, from, forkJoin } from 'rxjs';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AgentService } from 'src/app/core/services/agent/agent.service';
import { TranslateService } from '@ngx-translate/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';


@Component({
  selector: 'app-prod-wagon',
  templateUrl: './prod-wagon.component.html',
  styleUrls: ['./prod-wagon.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ]
})
export class ProdWagonComponent implements OnInit, OnDestroy {

  /** Whether datas are still loading */
  isLoadingResults = true;
  /** Retain all subscriptions */
  subscriptionRefs: Subscription[] = [];
  /** Data */
  chart: Chart;
  labels: string[] = [];
  nbWagonsPerOperation: number[] = [];
  nbWagonUpdate = 0 ;
  nbAres = 0 ;
  today =  moment().endOf('day').toDate();
  yesterday = moment().add(-1, 'day').toDate();
  dateFormControl = new FormControl(this.yesterday.toISOString());

  constructor(
    private dashboardService: DashboardService,
    private agentService: AgentService,
    private translateService: TranslateService,
    private adapter: DateAdapter<any> ) {
      if (this.translateService.getBrowserLang().match(/fr/)) {
        this.adapter.setLocale('fr');
      }

    }

  ngOnInit() {
  this.getWagonProd();
  this.chart = new Chart('canvas_prod_wagons', {
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: [
          {
            label: this.translateService.instant('dashboard.production_card.nb_wagons'),
            stack: 'Stack 0',
            data: this.nbWagonsPerOperation,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
          },
        ]
      },
      options: {
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            display: true,
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: this.translateService.instant('dashboard.production_card.opeMaint') ,
            },
          }],
          yAxes: [{
            display: true,
            stacked: true,
            ticks: {
              stepSize: 1
            },
            scaleLabel: {
              display: true,
              labelString: this.translateService.instant('dashboard.production_card.nb_wagons' ) ,
            },
          }],
        }
      }
    });
  }

  getWagonProd(delay?: string) {
    this.resetChart();
    if ( delay === undefined) {
      delay = '1';
    }
    this.subscriptionRefs.push(
      this.agentService.getAgentData().subscribe(agentData => {
        if (agentData) {
          const workShopCode = agentData.company.code;
          forkJoin([
            this.dashboardService.getWagonProd(workShopCode, delay),
            this.dashboardService.getWagonUpdateNumber(workShopCode),
            this.dashboardService.getWagonUpdateNumber(workShopCode, 'true')
          ]).subscribe((result => {
            const wagonProductions = result[0];
            this.nbWagonUpdate = result[1].data;
            this.nbAres = result[2].data;
            from(wagonProductions.data).subscribe(wagonProduction => {
              this.labels.push(wagonProduction.opeCode);
              this.nbWagonsPerOperation.push(wagonProduction.wagonSum);
            });
            this.isLoadingResults = false;
            this.chart.update();
          }));
        }
      }));
  }

  resetChart(): void {
    this.nbWagonsPerOperation.splice(0, this.nbWagonsPerOperation.length);
    this.labels.splice(0, this.labels.length);
  }

  updateChart(type: string, event: MatDatepickerInputEvent<Date>) {
    const delay = Math.floor(( new Date().valueOf()  - event.value.valueOf() ) / (1000 * 60 * 60 * 24) ).toString();
    this.isLoadingResults = true;
    this.getWagonProd(delay);
  }

  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
  }

}
