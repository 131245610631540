import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/core/services/app-api/app-api.service';
import { Observable, of } from 'rxjs';
import { ApiResponse } from 'src/app/core/services/app-api/api-response.model';
import { catchError, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { Deal } from 'src/app/shared/models/deal';
import { DealDetail } from 'src/app/shared/models/deal-detail';
import { DealWorkshop } from 'src/app/shared/models/deal-workshop';
import { DealWagon } from 'src/app/shared/models/deal-wagon';
import { DealMedia } from 'src/app/shared/models/deal-media';
import { DealUpdate } from 'src/app/shared/models/deal-update';
import { WorkingBill } from 'src/app/shared/models/working-bill';

@Injectable({
  providedIn: 'root'
})
export class DealService {
  /**
   * DealService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) {  }

  /**
   * Retrieve deal with given id
   * @returns encapsulated deal datas as `Observable`
   */
  public getDeal(id: number): Observable<ApiResponse<Deal>> {
    return this.api.get<ApiResponse<Deal>>(`/deals/${id}`)
    .pipe(
      catchError(this.handleError<any>('getDeal', {data: null}))
    );
  }
  /**
   * Retrieve deal list with given params
   * @returns encapsulated deal list datas as `Observable`
   */
  public getDealsByWorkshop(workshop?: string, pageable?: Pageable): Observable<ApiResponse<Deal[]>> {
    let parameters: HttpParams = new HttpParams();

    if (workshop !== undefined && workshop !== null && workshop !== '') {
      parameters = parameters.set('workshop', `${workshop}`);
    }

    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<Deal[]>>(`/deals`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getDealsByWorkshop', {data: []}))
    );
  }

  /**
     * Retrieve deal list with given params
     * @returns encapsulated deal list datas as `Observable`
     */
  public getDealInProgressByDataEntryWorkshop(workshop: string): Observable<ApiResponse<Deal[]>> {
      return this.api.get<ApiResponse<Deal[]>>(`/deals/in-progress/${workshop}`)
    .pipe(
      catchError(this.handleError<any>('getDealInProgressByDataEntryWorkshop', {data: []}))
    );
  }

  /**
   * Search the list of open or ongoing cases attached to the wagon and on which the wagon has not yet been processed
   * @param mex wagon's mex
   * @param done ?
   */
  public getDealOpenOrAttachedPage(mex: string, workshop: string, pageable?: Pageable): Observable<ApiResponse<Deal[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('workshop', `${workshop}`);

    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<Deal[]>>(`/deals/${mex}/open-or-attached-wag`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getDealOpenOrAttachedPage', {data: []}))
    );
  }

  /**
   * Search the list of open or ongoing cases not attached to the wagon and on which the wagon has not yet been processed
   * @param mex wagon's mex
   * @param done ?
   */
  public getDealOpenOrNotAttachedPage(mex: string, workshop: string, pageable?: Pageable): Observable<ApiResponse<Deal[]>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('workshop', `${workshop}`);

    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<Deal[]>>(`/deals/${mex}/open-or-not-attached-wag`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getDealOpenOrNotAttachedPage', {data: []}))
    );
  }

  /**
   * Retrieve deal list with given params
   * @returns encapsulated deal list datas as `Observable`
   */
  public getDeals({ isManual, status, date1, date2, claimant, object1, object2, object3, order, station, workshop, mex }:
    { isManual?: boolean; status?: string; date1?: Date; date2?: Date;
      claimant?: string; object1?: string; object2?: string; object3?: string;
      order?: string; station?: string; workshop?: string; mex?: string;
    } = {},       pageable?: Pageable): Observable<ApiResponse<Deal[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isManual !== undefined && isManual !== null) {
      parameters = parameters.set('isManual', `${isManual}`);
    }
    if (status !== undefined && status !== null && status !== '') {
      parameters = parameters.set('status', `${status}`);
    }
    if (date1 !== undefined && date1 !== null) {
      parameters = parameters.set('date1', `${date1}`);
    }
    if (date2 !== undefined && date2 !== null) {
      parameters = parameters.set('date2', `${date2}`);
    }
    if (claimant !== undefined && claimant !== null && claimant !== '') {
      parameters = parameters.set('claimant', `${claimant}`);
    }
    if (object1 !== undefined && object1 !== null && object1 !== '') {
      parameters = parameters.set('object1', `${object1}`);
    }
    if (object2 !== undefined && object2 !== null && object2 !== '') {
      parameters = parameters.set('object2', `${object2}`);
    }
    if (object3 !== undefined && object3 !== null && object3 !== '') {
      parameters = parameters.set('object3', `${object3}`);
    }
    if (order !== undefined && order !== null && order !== '') {
      parameters = parameters.set('order', `${order}`);
    }
    if (station !== undefined && station !== null && station !== '') {
      parameters = parameters.set('station', `${station}`);
    }
    if (workshop !== undefined && workshop !== null && workshop !== '') {
      parameters = parameters.set('workshop', `${workshop}`);
    }
    if (mex !== undefined && mex !== null && mex !== '') {
      parameters = parameters.set('mex', `${mex}`);
    }
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<Deal[]>>(`/deals`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getDeals', {data: []}))
    );
  }

  /**
   * Retrieve detail of deal with given id
   * @returns encapsulated deal datas as `Observable`
   */
  public getDealDetail(id: number): Observable<ApiResponse<DealDetail>> {
    return this.api.get<ApiResponse<Deal>>(`/deals/${id}/detail`)
    .pipe(
      catchError(this.handleError<any>('getDealDetail', {data: null}))
    );
  }

  /**
   * update deal record
   * @param id deal id
   * @param deal deal infos
   */
  public updateDeal(id: number, deal: Deal): Observable<Response>  {
    return this.api.put<Response>(`/deals/${id}`, deal, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateDeal', null))
    );
  }

  /**
   * create deal record
   * @param deal deal infos
   */
  public createDeal(deal: Deal): Observable<ApiResponse<Deal>>  {
    return this.api.post<ApiResponse<Deal>>(`/deals`, deal)
    .pipe(
      catchError(this.handleError<any>('createDeal', {data: null}))
    );
  }

  /**
   * delete deal workshops
   * @param id deal id
   */
  public deleteDealWorkshops(id: number): Observable<any>  {
    return this.api.delete<any>(`/deals/${id}/workshops`)
    .pipe(
      catchError(this.handleError<any>('deleteDealWorkshops', null))
    );
  }

  /**
   * create deal workshop record
   * @param id deal id
   * @param ws workshop infos
   */
  public createDealWorkshop(id: number, ws: DealWorkshop): Observable<ApiResponse<DealWorkshop>>  {
    return this.api.post<ApiResponse<DealWorkshop>>(`/deals/${id}/workshops`, ws)
    .pipe(
      catchError(this.handleError<any>('createDealWorkshop', {data: null}))
    );
  }

  /**
   * delete & create deal workshop records
   * @param id deal id
   * @param workshops workshop list
   */
  deleteAndCreateDealWorkshops(id: number, workshops: DealWorkshop[]): Observable<ApiResponse<DealWorkshop[]>> {
    return this.api.post<ApiResponse<DealWorkshop[]>>(`/deals/${id}/workshops/delete-and-create`, workshops)
    .pipe(
      catchError(this.handleError<any>('deleteAndCreateDealWorkshops', {data: []}))
    );
  }

  /**
   * delete deal wagon
   * @param id deal id
   */
  public deleteDealWagons(id: number): Observable<any>  {
    return this.api.delete<any>(`/deals/${id}/wagons`)
    .pipe(
      catchError(this.handleError<any>('deleteDealWagons', null))
    );
  }

  /**
   * create deal wagon record
   * @param id deal id
   * @param wag wagon infos
   */
  public createDealWagon(id: number, wag: DealWagon): Observable<ApiResponse<DealWagon>>  {
    return this.api.post<ApiResponse<DealWagon>>(`/deals/${id}/wagons`, wag)
    .pipe(
      catchError(this.handleError<any>('createDealWagon', {data: null}))
    );
  }

  /**
   * delete & create deal wagon records
   * @param id deal id
   * @param wagons wagon list
   */
   deleteAndCreateDealWagons(id: number, wagons: DealWagon[]): Observable<ApiResponse<DealWagon[]>> {
    return this.api.post<ApiResponse<DealWagon[]>>(`/deals/${id}/wagons/delete-and-create`, wagons)
    .pipe(
      catchError(this.handleError<any>('deleteAndCreateDealWagons', {data: []}))
    );
  }
  /**
   *  delete deal media record
   */
  public deleteDealMedia(id: number, filename: string): Observable<any> {
    return this.api.delete<any>(`/deals/${id}/medias/${encodeURIComponent(filename)}`)
    .pipe(
      catchError(this.handleError<any>('deleteDealMedia', null))
    );
  }

  /**
   * create deal media record & upload media
   * @param id deal id
   * @param media media infos
   * @param file media data
   */
  public createDealMedia(id: number, media: DealMedia, file: File): Observable<ApiResponse<DealMedia>> {
    const data = new FormData();
    data.append('media', new Blob([JSON.stringify(media)], { type: 'application/json' }));
    data.append('file', file, file.name);
    return this.api.post<ApiResponse<DealWagon>>(`/deals/${id}/medias`, data)
    .pipe(
      catchError(this.handleError<any>('createDealMedia', {data: null}))
    );
  }

  /**
   * update deal media record
   * @param id deal id
   * @param media media infos
   */
  public updateDealMedia(id: number, media: DealMedia): Observable<Response> {
    return this.api.put<Response>(`/deals/${id}/medias`, media, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updateDealMedia', null))
    );
  }

  /**
   * Download a media
   * @param id the deal id
   * @param filename The filename to download
   * @returns file content as an `Observable<Blob>`
   */
  public downloadMedia(id: number, filename: string): Observable<Blob> {
    return this.api.get<Blob>(`/deals/${id}/medias/${encodeURIComponent(filename)}`, {responseType: 'blob', observe: 'response'})
      .pipe(
        catchError(this.handleError<any>('downloadMedia', null)),
        map((resp) => {
          return resp.body;
        })
      );
  }

  /**
   * create deal update record
   * @param id deal id
   * @param update update infos
   */
  public createDealUpdate(id: number, update: DealUpdate): Observable<ApiResponse<DealUpdate>>  {
    return this.api.post<ApiResponse<DealUpdate>>(`/deals/${id}/updates`, update)
    .pipe(
      catchError(this.handleError<any>('createDealUpdate', {data: null}))
    );
  }

  /**
   * Download workingBill datas report
   * @param id the wb's id
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadWorkingBillReport(id: number): Observable<{blob: Blob, filename: string}> {
    return this.api.get<{blob: Blob, filename: string}>(`/deals/working-bills/${id}/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadWorkingBillReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : 'BT_' + id + '.pdf'};
      })
    );
  }

  /**
   * Download working bill datas report
   * @param ids array of wb ids
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadAllWorkingBillReport(ids: any[]) {
    return this.api.post<{blob: Blob, filename: string}>(`/deals/working-bills/pdf`, ids, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadAllWorkingBillReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1]
          : ( ids.length > 1 ? 'BT.pdf' : 'BT_' + ids[0] + '.pdf')};
      })
    );
  }

  /**
   * Download recommissioning datas report
   * @param ids array of recommissioning ids
   * @returns report content as an `Observable<[Blob, string]>`
   */
  downloadRecomReport(ids: any[]) {
    return this.api.post<{blob: Blob, filename: string}>(`/deals/recommissionings/pdf`, ids, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadRecomReport', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1]
          : ( ids.length > 1 ? 'ARES.pdf' : 'ARES_' + ids[0] + '.pdf')};
      })
    );
  }

  /**
   * check the maximum number of wagons for the deal and wagon
   * @returns encapsulated deal datas as `Observable`
   */
  public isMaxNumberWagonByDeal(id: number, wagonId: number): Observable<ApiResponse<boolean>> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('wagonId', `${wagonId}`);

    return this.api.get<ApiResponse<boolean>>(`/deals/${id}/max-number-wagon`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('isMaxNumberWagonByDeal', {data: null}))
    );
  }

  /**
   * Table feeding for the DEAL portal
   * @param element wagon update history datas
   */
  public saveDealPortalTables(element: any, idDeal?: number, pvca?: number, workshopCode?: string): Observable<ApiResponse<DealWagon[]>>  {
    let parameters: HttpParams = new HttpParams();
    if (idDeal !== undefined && idDeal !== null) {
      parameters = parameters.set('idDeal', `${idDeal}`);
    }
    if (pvca !== undefined && pvca !== null) {
      parameters = parameters.set('pvca', `${pvca}`);
    }
    if (workshopCode !== undefined && workshopCode !== null) {
      parameters = parameters.set('workshopCode', `${workshopCode}`);
    }

    return this.api.post<ApiResponse<DealWagon[]>>(`/deals/portal`, element, { params: parameters } )
    .pipe(
      catchError(this.handleError<any>('saveDealPortalTables', {data: []}))
    );
  }

  
  updateDealWorkingBillSent(dealId: number, wb: WorkingBill): Observable<Response>  {
    return this.api.put<Response>(`/deals/${dealId}/working-bills/${wb.id}`, wb.sent, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('saveDealPortalTables', {data: []}))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
