import { DeviceCategory } from './device-category';

/** API model:Table: T009_VIDE_CHARGE */
export class EmptyLoadedDevice {
    /** DISPOVC_CODE_PK VARCHAR2(2 BYTE) */
    code: string;
    /** category.code = DISPOVC_CDISPO_CODE_FK VARCHAR2(2 BYTE) */
    category: DeviceCategory;
    /** DISPOVC_LIB VARCHAR2(144 BYTE) */
    label: string;
}
