import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms';

@Directive({
  selector: '[appMinVal]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinValDirective, multi: true }]
})
export class MinValDirective implements Validator {

  @Input() min: number;

  validate(control: AbstractControl): { [key: string]: any } {
    return Validators.min(this.min)(control);
  }

}
