import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, ValidatorFn, Validator, FormControl, AbstractControl } from '@angular/forms';

export function validateAutoControl(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const val: string = control.value;
    if (!val || (val.length !== 12 && val.length !== 14)) {
      return null;
    }
    let ac = 0;
    let clef;
    const ch = '2121212121212';
    for (let i = 0; i < val.length - 1; i++) {
      let somme = parseInt(val.substr( i, 1), 10) * parseInt(ch.substr(i, 1), 10);
      if (somme >= 10) {
        somme = somme - 9;
      }
      ac = ac + somme;
    }
    if (( Math.floor(ac / 10) * 10) === ac) {
      clef = 0;
    } else {
      clef = 10 - (ac - (Math.floor(ac / 10) * 10));
    }
    if (parseInt(val.substr(val.length - 1, 1), 10) === clef) {
      return null;
    }
    return { validateAutoControl: true };
  };
}

@Directive({
  selector: '[appAutocontrol][ngModel], [appAutocontrol][formControl], [appAutocontrol][formControlName]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => AutoControlDirective), multi: true }
  ]
})
export class AutoControlDirective implements Validator {
  validator: ValidatorFn;

  constructor() {
    this.validator = validateAutoControl();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }


}

