import { WagonBrakeMainOrgan } from './wagon-brake-main-organ';
import { WagonRegulator } from './wagon-regulator';
import { BrakeCylinder } from './brake-cylinder';
import { WagonBrakeParticularity } from './wagon-brake-particularity';
import { WagonEmptyLoadedDevice } from './wagon-empty-loaded-device';
import { AuxiliaryTank } from './auxiliary-tank';
import { PurgeValve } from './purge-valve';
import { BrakeControl } from './brake-control';
import { Handbrake } from './handbrake';
import { AuxiliaryTankElement } from './auxiliary-tank-element';
import { BrakeMainOrganElement } from './brake-main-organ-element';
import { BrakeMainOrganElementUpdate } from './brake-main-organ-element-update';

/** API model:Table: WAGON_CARACT */
export class WagonBrake {
    /**     WAGON_CARACT.WCAR_WLOG_MEX_PFK = R_WCAR_OPF.WOPF_WCAR_MEX_PFK
     * AND  WAGON_CARACT.WCAR_WLOG_INV_CODE_PFK = R_WCAR_OPF.WOPF_WCAR_INV_CODE_PFK
     */
    mainOrganList: WagonBrakeMainOrgan [];
    /**     WAGON_CARACT.WCAR_WLOG_MEX_PFK = R_WCAR_REGL.WREGL_WCAR_MEX_PFK
     * AND  WAGON_CARACT.WCAR_WLOG_INV_CODE_PFK = R_WCAR_REGL.WREGL_WCAR_INV_CODE_PFK
     */
    regulatorList: WagonRegulator [];
    /**
     *      WAGON_CARACT.WCAR_WLOG_MEX_PFK = R_WCAR_CYLFREIN.WCYLF_WCAR_MEX_PFK
     * AND  WAGON_CARACT.WCAR_WLOG_INV_CODE_PFK = R_WCAR_CYLFREIN.WCYLF_WCAR_INV_CODE_PFK
     * AND  R_WCAR_CYLFREIN.WCYLF_CYLFREIN_CODE_PFK = T012_CYLINDRE_FREIN.CYLFREIN_CODE_PK
     */
    brakeCylinderList: BrakeCylinder [];
    /**
     *      WAGON_CARACT.WCAR_WLOG_MEX_PFK = R_WCAR_PARTFREIN.WPARTF_WCAR_MEX_PFK
     * AND  WAGON_CARACT.WCAR_WLOG_INV_CODE_PFK = R_WCAR_PARTFREIN.WPARTF_WCAR_INV_CODE_PFK
     */
    particularityList: WagonBrakeParticularity [];
    /**
     * WAGON_CARACT.WCAR_WLOG_MEX_PFK = R_WCAR_DISPOVC.WDISPOVC_WCAR_MEX_PFK
     * AND WAGON_CARACT.WCAR_WLOG_INV_CODE_PFK = R_WCAR_DISPOVC.WDISPOVC_WCAR_INV_CODE_PFK
     */
    emptyLoadedDeviceList: WagonEmptyLoadedDevice [];
    /** auxiliaryTank.code = WCAR_RESAUX_CODE_FK VARCHAR2(2 BYTE) */
    auxiliaryTank: AuxiliaryTank;
    /** purgeValve.code = WCAR_VALPUR_CODE_FK VARCHAR2(1 BYTE) */
    purgeValve: PurgeValve;
    /** brakeControl.code = WCAR_COMFREIN_CODE_FK VARCHAR2(2 BYTE) */
    brakeControl: BrakeControl;
    /** handbrake.code = WCAR_FMAIN_CODE_FK VARCHAR2(1 BYTE) */
    handbrake: Handbrake;
    /* tslint:disable:variable-name */
    /** WCAR_POIDS_FREIN_6 VARCHAR2(3 BYTE) */
    emptyBrakedWeightG: string;
    /** WCAR_POIDS_FREIN_7 VARCHAR2(3 BYTE) */
    loadedBrakedWeightG: string;
    /** WCAR_POIDS_FREIN_3 VARCHAR2(3 BYTE) */
    firstTransitionWeight: string;
    /** WCAR_POIDS_FREIN_8 VARCHAR2(3 BYTE) */
    halfBrakedWeightG: string;
    /** WCAR_POIDS_FREIN_5 VARCHAR2(3 BYTE) */
    secondTransitionWeight: string;
    /** WCAR_POIDS_FREIN_1 VARCHAR2(3 BYTE) */
    emptyBrakedWeightP: string;
    /** WCAR_POIDS_FREIN_2 VARCHAR2(3 BYTE) */
    loadedBrakedWeightP: string;
    /** WCAR_POIDS_FREIN_4 VARCHAR2(3 BYTE) */
    halfBrakedWeightP: string;
    /* tslint:enable:variable-name */
    /** WCAR_POIDS_FREIN_9 VARCHAR2(3 BYTE) */
    handbrakeBrakedWeight: string;
    /** ELEMENT_RESERVOIR_AUX.ELTRA_WPHY_ID_PFK = WAGON_CARACT.WCAR_WPHY_ID_FK */
    auxiliaryTankElementList: AuxiliaryTankElement [];
    /** ELEMENT_OPF.ELTOPF_WPHY_ID_PFK = WAGON_CARACT.WCAR_WPHY_ID_FK */
    brakeMainOrganElementList: BrakeMainOrganElement [];
    /** ELEMENT_OPF_MAJ.ELTOPFMAJ_WPHY_ID_PFK = WAGON_CARACT.WCAR_WPHY_ID_FK */
    brakeMainOrganElementUpdateList: BrakeMainOrganElementUpdate [];
}
