/** This file can be replaced during build by using the `fileReplacements` array.
 *  `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
 * The list of file replacements can be found in `angular.json`.
 */
export const environment = {
   /**
   * Application name
   */
   name: 'MARGO',
  /**
   * Whether it is production environnement
   */
  production: true,
  /**
   * log level of application
   * TRACE, DEBUG, INFO, WARN, ERROR, DISABLE
   */
  logger_level: 'DISABLE',
  /**
   * API URL
   */
  api_url: '/margo-wr/api',
  /** Support mail */
  support_mail: 'pc.margo-oreste@sncf.fr'

};


/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
