import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppApiService } from '../app-api/app-api.service';
import { AuthService } from '../auth/auth.service';
import { ApiResponse } from '../app-api/api-response.model';
import { Agent } from '../../../shared/models/agent';
import { Service } from '../../../shared/models/service';
import { Workshop } from '../../../shared/models/workshop';
import { UserInfos } from '../auth/user-infos';
import { WorkshopHabilitation } from 'src/app/shared/models/workshop-habilitation';
import { HttpParams } from '@angular/common/http';

/** provide info about agent */
@Injectable({
  providedIn: 'root'
})

/**
 * AgentService constructor
 * @param api Inject Api service to manage http calls
 * @param authService Inject application's authentication service
 */
export class AgentService {
  agent = new BehaviorSubject<Agent>(null);
  fPArray = new BehaviorSubject<string[]>( []);
  serviceArray = new BehaviorSubject<string[]>( []);
  habilitationsLoaded = new Subject<boolean>();
  constructor(
    private api: AppApiService,
    private authService: AuthService,
    private router: Router) {      
      this.authService.isLogged()
      .subscribe( (res) => {
        if (res) {
          this.getAgent().subscribe( (resp) => {
            if ('data' in resp) {
              this.getServices().subscribe( (servicesResp) => {
                this.agent.next(resp.data);
                this.serviceArray.next(this.makeServiceArray(servicesResp.data));
                this.fPArray.next(this.makeFuncProcArray(servicesResp.data));
                this.habilitationsLoaded.next(true);
              });
            } else {
              this.router.navigate(['/unauthorized']);
            }
          });
        } else {
          this.agent.next(null);
          this.fPArray.next([]);
          this.serviceArray.next([]);
        }
      });
    }

  /** Construct array of functional procedure from service list
   * @param services service list
   * @returns procedure codes array
   */
  makeFuncProcArray(services: Service[]): string[] {
    return Array.from(new Set(services.map(
        (serv) => serv.functionalProcedureList.map((fp) => fp.code)
      ).reduce((acc, val) => acc.concat(val), [])));
  }
  /** Construct array of services from service list
   * @param services service list
   * @returns service codes array
   */
  makeServiceArray(services: Service[]): string[] {
    return Array.from(new Set(services.map(
        (serv) => serv.code
      ).reduce((acc, val) => acc.concat(val), [])));
  }
  /**
   * Get agent datas
   * @returns Agent datas
   */
  getAgentData(): BehaviorSubject<Agent> {
    return this.agent;
  }
  /**
   * Get agent Infos from RID
   * @returns Agent datas
   */
  getUserInfos(): UserInfos {
    return this.authService.getUserInfos();
  }
  /**
   * Get functional procedure array
   * @returns procedure's name array
   */
  getProcedureArray(): BehaviorSubject<string[]> {
    return this.fPArray;
  }
  /**
   * Get functional procedure array
   * @returns procedure's name array
   */
  getHabilitationsLoaded(): Subject<boolean> {
    return this.habilitationsLoaded;
  }
  /**
   * Retrieve agent datas
   * @returns encapsulated Agent datas as `Observable`
   */
  getAgent(): Observable<ApiResponse<Agent>> {
   // if (this.authService.isLogged().getValue()) {
      return this.api.get<ApiResponse<Agent>>('/agent')
      .pipe(
        catchError(this.handleError<any>('getAgent', {data: null}))
      );
    // }
  }
  
  updateLastConnection(): Observable<ApiResponse<any>> {
    return this.api.get<ApiResponse<Agent>>('/agent/connect')
      .pipe(
        catchError(this.handleError<any>('updateLastConnection', {data: null}))
      );
  }
  /**
   * Retrieve agent workshop habilitation
   * @returns encapsulated Agent workshop habilitation datas as `Observable`
   */
  getAgentWorkshopHabilitation(): Observable<ApiResponse<WorkshopHabilitation[]>> {
      return this.api.get<ApiResponse<WorkshopHabilitation>>('/agent/workshops-habilitations')
      .pipe(
        catchError(this.handleError<any>('getAgentWorkshopHabilitation', {data: []}))
      );
  }

  isAgentEnabled(hab: string): Observable<ApiResponse<boolean>> {
    return this.api.get<ApiResponse<boolean>>(`/agent-enabled/${hab}`)
    .pipe(
      catchError(this.handleError<any>('isAgentEnabled', {data: null}))
    );
  }

  /**
   * Retrieve agent services
   * @returns encapsulated Services datas as `Observable`
   */
  getServices(): Observable<ApiResponse<Service[]>> {
   // if (this.authService.isLogged().getValue()) {
      return this.api.get<ApiResponse<Service[]>>('/agent/services')
      .pipe(
        catchError(this.handleError<any>('getServices', {data: []}))
      );
   // }
  }
  /**
   * Retrieve agent workshop
   * @returns encapsulated workshop datas as `Observable`
   */
  getWorkshop(): Observable<ApiResponse<Workshop>> {
    // if (this.authService.isLogged().getValue()) {
      return this.api.get<ApiResponse<Workshop>>('/agent/workshop')
      .pipe(
        catchError(this.handleError<any>('getWorkshop', {data: []}))
      );
    // }
  }
  
  public getCustomerFiles(): Observable<ApiResponse<any[]>> {
    return this.api.get<ApiResponse<any[]>>('/agent/files')
      .pipe(
        catchError(this.handleError<any>('getCustomerFiles', {data: []}))
      );
  }
  
  public getCustomerFilesFor(group: string): Observable<ApiResponse<any[]>> {
    return this.api.get<ApiResponse<any[]>>(`/agent/files/${group}`)
      .pipe(
        catchError(this.handleError<any>('getCustomerFiles', {data: []}))
      );
  }

  public deleteCustomerFile(fileInfo: any): Observable<Response> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('type', `${fileInfo.type}`);
    parameters = parameters.set('group', `${fileInfo.group}`);
    parameters = parameters.set('filename', `${fileInfo.filename}`);
    return this.api.delete<Response>(`/agent/files/`, {params: parameters, observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('deleteCustomerFile', null))
    );
  }

  public uploadCustomerFile(file: File, type: string, group: string): Observable<any> {
    const data = new FormData();
    data.append('file', file, file.name);
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('type', `${type}`);
    parameters = parameters.set('group', `${group}`);
    return this.api.post<Response>(`/agent/files`, data, {params: parameters, observe: 'response'})
   /* .pipe(
      catchError(this.handleError<any>('uploadCustomerFile', {data: null}))
    )*/;
  }

  public downloadCustomerFile(fileInfo: any): Observable<File> {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('type', `${fileInfo.type}`);
    parameters = parameters.set('group', `${fileInfo.group}`);
    parameters = parameters.set('filename', `${fileInfo.filename}`);
    return this.api.get<Blob>(`/agent/files/download`, {responseType: 'blob', observe: 'response', params: parameters})
    .pipe(
      catchError(this.handleError<any>('downloadCustomerFile', null)),
      map((resp) => {
        return new File([resp.body], fileInfo.fileName, {type: resp.headers.get('content-type') });
      })
    );
  }

  /**
   * Allow activation if user has required functional procedure
   * @param fp - functional procedure code conditions
   * @returns true if user has fp
   */
  hasFunctionalProcedures(fp: string | string[]): boolean {
    if (fp instanceof Array) {
      return this.hasAllFunctionalProcedures(fp);
    } else {
      return this.hasFunctionalProcedure(fp);
    }
  }
  /**
   * Assert user has all functional procedure
   * @param fpList - functional procedure codes list
   * @returns true if user has all fp
   */
  hasAllFunctionalProcedures(fpList: string[]): boolean {
      return fpList.every(
        (fp) => this.hasFunctionalProcedure(fp)
      );
  }
  /**
   * Assert user has at least one functional procedure
   * @param fpList - functional procedure codes list
   * @returns true if user has one fp
   */
  hasAtLeastOneFunctionalProcedure(fpList: string[]): boolean {
      return fpList.some(
        (fp) => this.hasFunctionalProcedure(fp)
      );
  }

  /**
   * Assert user has a functional procedure
   * @param fp - functional procedure code
   * @returns true if user has fp
   */
  hasFunctionalProcedure(fp: string): boolean {
      return this.fPArray.getValue().indexOf(fp) !== -1;
  }

  /**
   * Assert user has a service
   * @param serv service code
   * @returns true if user has fp
   */
  hasService(serv: string): boolean {
    return this.serviceArray.getValue().indexOf(serv) !== -1;
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  /**
   * Retrieve agent list habilited to DWV01
   * @returns encapsulated Services datas as `Observable`
   */
  getDWV01Agents(code: string): Observable<ApiResponse<Agent[]>> {
       return this.api.get<ApiResponse<Agent[]>>(`/agents/fp/DWV01/${code}`)
       .pipe(
         catchError(this.handleError<any>('getDWV01Agents', {data: []}))
       );
   }
  
   
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
