import { Workshop } from './workshop';
import { UpdateType } from './update-type';
import { MaintenanceOperation } from './maintenance-operation';
import { InterventionReason } from './intervention-reason';
import { InterventionCause } from './intervention-cause';
import { TrailedMaterialStatus } from './trailed-material-status';
import { Imputation } from './imputation';
import { RefMaterialUpdate } from './ref-material-udpate';
import { StageUpdate } from './stage-update';

/** API model:Table: WAGON_MAJ  */
export class WagonUpdate {
  /** WMAJ_WPHY_ID_PFK NUMBER(10,0) */
  wagonId: number;
  /** interventionWorkshop.code =	WMAJ_ETAB_INTV_FK VARCHAR2(5 BYTE) */
  interventionWorkshop: Workshop;
  /** type.code = WMAJ_TMAJ_CODE_FK VARCHAR2(2 BYTE) */
  type: UpdateType;
  /** WMAJ_WLOG_MEX_FK VARCHAR2(14 BYTE) */
  mex: string;
  /** dataEntryWorkshop.code = WMAJ_ETAB_SAISIE_FK VARCHAR2(5 BYTE) */
  dataEntryWorkshop: Workshop;
  /** maintenanceOperation.code = WMAJ_OPE_CODE_FK VARCHAR2(6 BYTE) */
  maintenanceOperation: MaintenanceOperation;
  /** interventionReason.code = WMAJ_MTFINTV_CODE_FK VARCHAR2(3 BYTE) */
  interventionReason: InterventionReason;
  /** interventionCause.code = WMAJ_KOZINTV_CODE_FK VARCHAR2(1 BYTE) */
  interventionCause: InterventionCause;
  /** imputation.code = WMAJ_IMPUTRA_CODE_FK VARCHAR2(1 BYTE) */
  imputation: Imputation;
  /** trailedMaterialStatus.code = WMAJ_EMR_CODE_FK VARCHAR2(2 BYTE) */
  trailedMaterialStatus: TrailedMaterialStatus;
  /** WMAJ_DH_ENTREE DATE */
  entry: Date;
  /** WMAJ_DH_SORTIE_SAISIE	DATE */
  exit: Date;
  /** WMAJ_AFF_ID_FK		NUMBER(10,0) */
  dealId: number;
  /** WMAJ_ETRELESS_CODE_ENT_FK		VARCHAR2(1 BYTE) */
  entranceAxleStatementStatusCode: string;
  /** WMAJ_ETRELESS_CODE_SOR_FK		VARCHAR2(1 BYTE) */
  outputAxleStatementStatusCode: string;
  /** WMAJ_FLAG_PAD_VALIDE		NUMBER(1,0) */
  isValidated: boolean;
  /** WMAJ_EXMEX VARCHAR2(14 BYTE) */
  exMex: string;
  /** WMAJ_MEX_REF VARCHAR2(14 BYTE) */
  refMex: string;
  /** WMAJ_DH_DISPO DATE */
  dateDispo: Date;
  /** WMAJ_OPTION1 VARCHAR2(5 BYTE) */
  option1: string;
  /** WMAJ_OPTION2 VARCHAR2(5 BYTE) */
  option2: string;
  /** WMAJ_OBSERVATION VARCHAR2(80 BYTE) */
  observation: string;
  /** WMAJ_OBSERVATION_SITE VARCHAR2(80 BYTE) */
  observationSite: string;
  /** WMAJ_PVCA_ID_FK NUMBER(10,0) */
  pvcaId: number;
  /** WMAJ_EXP_ID_FK NUMBER(10,0) */
  expId: number;
  /** WMAJ_DH_SORTIE_SYS	DATE */
  exitSys: Date;
  /** WMAJ_ETAPE_FK NUMBER(1,0) */
  stage: StageUpdate;
  /** R_WPHY_MATREF_MAJ.WMATRMAJ_WPHY_ID_PFK = WMAJ_WPHY_ID_PFK */
  refMaterialUpdateList: RefMaterialUpdate[];
  /** WMAJ_GEO_CREV */
  wmajGeoCrev: number;
  /** WMAJ_GEO_VSP */
  wmajGeoVsp: number;
}
