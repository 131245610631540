import { Component, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { AxleElement } from 'src/app/shared/models/axle-element';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Portal } from '@angular/cdk/portal';
import { AxleService } from 'src/app/modules/consultation/axle/services/axle.service';
import { LogicalWagon } from 'src/app/shared/models/logical-wagon';
import { AxleIntervention } from 'src/app/shared/models/axle-intervention';
import { MaskService } from 'ngx-mask';
import { AxleSideElement } from '../../models/axle-side-element';
import { AxleElementDamage } from '../../models/axle-element-damage';

/**
 * 'AxleToggleComponent' display toggle button
 */
@Component({
  selector: 'app-axle-toggle',
  templateUrl: './axle-toggle.component.html',
  styleUrls: ['./axle-toggle.component.scss'],
  providers: [MaskService],
  encapsulation: ViewEncapsulation.None
})
export class AxleToggleComponent implements OnInit, AfterViewInit, OnDestroy {
  /** retain display status */
  display = false;
  /** Current axle */
  axle: AxleElement;
  /** Side axle G */
  sideG: AxleSideElement;
  /** Side axle D */
  sideD: AxleSideElement;
  /**  Initial damage report*/
  damage: AxleElementDamage;
  /* Wagon format */
  wagonFormat: String;

  wagon: LogicalWagon;
  /** Intervention label */
  intervention: AxleIntervention;
  /** browser language */
  lang: string;
  /** Portal where inject additional content  */
  contentPortal: Portal<any>;
  /** Portal where inject header content  */
  headerPortal: Portal<any>;
  /** Portal where inject actions  */
  actionPortal: Portal<any>;
  /** Subject to manage loading status */
  private loadingSubject = new BehaviorSubject<boolean>(true);
  /** Observable to get loading status */
  public loading$ = this.loadingSubject.asObservable();
  /** Retain all subscription */
  private subscriptionRefs: Subscription[] = [];
  /**
   * AxleToggleComponent constructor
   * @param axleService Inject service to retrieve wagon datas
   */
  constructor(public axleService: AxleService,
    private maskService: MaskService,) {
    this.axleService.getCurrentAxleId()
      .subscribe( (val) => {
        if (val === null) {
          this.display = false;
          this.axleService.setDisplayStatus(false);
        }
      });
  }

  ngOnInit() {
    this.loadingSubject.next(false);
  }

  /**
     * AfterViewInit hook
     * Subscribe to listen page specifics changes for AxleComponent
     */
    ngAfterViewInit() {
      this.subscriptionRefs.push(
      this.axleService.getCurrentPortalTemplates()
          .subscribe(templates => {
          this.contentPortal = undefined;
          this.actionPortal = undefined;
          this.headerPortal = undefined;
          if ( templates ) {
              if ( templates[0] ) {
              this.contentPortal = templates[0] ;
              }
              if ( templates[1] ) {
              this.actionPortal = templates[1];
              }
              if ( templates[2] ) {
              this.headerPortal = templates[2];
              }
          }
      })
      );
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
    this.loadingSubject.complete();
  }
  
  /**
   * Toggle axle display status
   */
  toggleAxle(): void {
    this.display = !this.display;
    this.axleService.setDisplayStatus(this.display);
    if (this.display){
    
      /*Find the current axle*/ 
      this.subscriptionRefs.push(
        this.axleService.getCurrentAxle().subscribe( (AxleElement) => {
          
            this.axle = AxleElement;
        }),
        this.axleService.getCurrentSideG().subscribe( (SideAxleElement) => {
          this.sideG = SideAxleElement;
        }),
        this.axleService.getCurrentSideD().subscribe( (SideAxleElement) => {
          
          this.sideD = SideAxleElement;
        }),
        this.axleService.getCurrentWagon().subscribe( (LogicalWagon) => {
          
          this.wagon = LogicalWagon;
          if (this.wagon){
            this.wagonFormat = this.maskService.applyMask(this.wagon.mex, '00 00 0000 000 00 0');
          }
        }),
        this.axleService.getCurrentIntervention().subscribe( (AxleIntervention) => {
          
          this.intervention = AxleIntervention;
        }),
        this.axleService.getCurrentDamage().subscribe((damageAxleElement) => {
          this.damage = damageAxleElement;
        })
        );
    }
  }
}
