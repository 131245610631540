import { TransitRegime } from './transit-regime';

/** API model:Table: COMPO_RACH */
export class TransitRegimeComposition {
    /** COMPRACH_ID_PK NUMBER(5,0) */
    id: number;
    /* tslint:disable:variable-name */
    /** transitRegime1.code = COMPRACH_RACH1_CODE_FK VARCHAR2(5 BYTE) */
    transitRegime1: TransitRegime;
    /** transitRegime2.code = COMPRACH_RACH2_CODE_FK VARCHAR2(5 BYTE) */
    transitRegime2: TransitRegime;
    /** transitRegime3.code = COMPRACH_RACH3_CODE_FK VARCHAR2(5 BYTE) */
    transitRegime3: TransitRegime;
    /* tslint:enable:variable-name */
}
