import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

/**
 * Surcharge de MatPaginatorIntl pour traduire les libellés
 * et tooltips en Français
 *
 * Cette classe doit être enregistrée en tant que provider :
 * providers: [
 *   { provide: MatPaginatorIntl, useClass: MatPaginatorIntlFr }
 * ]
 */
@Injectable()
export class MatPaginatorIntlFr extends MatPaginatorIntl {
  /** Items per page french label */
  itemsPerPageLabel = 'Eléments par page';
  /** First page french label */
  firstPageLabel = 'Première page';
  /** Last page french label */
  lastPageLabel = 'Dernière page';
  /** Next page french label */
  nextPageLabel = 'Page suivante';
  /** Previous page french label */
  previousPageLabel = 'Page précédente';
  /**
   * Function to construct range label displayed for french MatPaginator
   * @param page page index
   * @param pageSize Number of items per page
   * @param length Total item count
   * @returns The french range label string to display
   */
  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 sur ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} sur ${length}`;
  }
}
