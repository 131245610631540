import { Bogie } from './bogie';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_BOG */
export class WagonBogie {
    /** WBOG_WCAR_MEX_PFK	VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = WBOG_WCAR_INV_CODE_PFK	VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** bogie.code = WBOG_BOG_CODE_PFK  VARCHAR2(3 BYTE) */
    bogie: Bogie;
    /** WBOG_NBRE NUMBER(3,0) */
    amount: number;
}
