import { Injectable } from '@angular/core';
import { AppApiService } from 'src/app/core/services/app-api/app-api.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ApiResponse } from 'src/app/core/services/app-api/api-response.model';
import { PreparationWorkCamsFaults } from '../../models/preparation-work-cams-faults';

@Injectable({
  providedIn: 'root'
})
export class PreparationWorkCamsFaultsService {

  /**
   * MaintenancePlanUpdateService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) { }

  /**
   * Obtain the data for the screen MAJ_002_1 - Preparation of work / CAMs and Faults
   * @param id the wagon's id 
   * @param mex the mex of wagon
   */
  public loadPreparationWorkCAMsAndFaultsPageDatas(id: number,mex: string): Observable<ApiResponse<PreparationWorkCamsFaults>>  {
    let parameters: HttpParams = new HttpParams();
    parameters = parameters.set('mex', `${mex}`);
    return this.api.get<ApiResponse<PreparationWorkCamsFaults>>(`/preparation-work/${id}/cams-faults`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('loadPreparationWorkCAMsAndFaultsPageDatas',  {data: null}))
    );
  }    

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
