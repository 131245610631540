/** API model:Table: T083_CAT_LIGNE */
export class LineCategory {
    /** CLIG_CODE_PK VARCHAR2(5 BYTE) */
    code: string;
    /** CLIG_LIB VARCHAR2(252 BYTE) */
    label: string;
    /** CLIG_ORDRE NUMBER(3,0) */
    order: number;
    /** refCodeList[x] = RT_CLIG_REF.CLIGREF_CLIG_REF_PFK
     * AND RT_CLIG_REF.CLIGREF_CLIG_CODE_PFK = T083_CAT_LIGNE.CLIG_CODE_PK
     */
    refCodeList: string[];
}
