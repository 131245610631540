import { RefMaterial } from './ref-material';
/** API model:Table: R_EXP_TCH_MATREF  */
export class ExpertiseTaskRefMaterial {
  /** refMaterial.code = ETCHMATREF_MATREF_CODE_PFK VARCHAR2(8 BYTE) */
  refMaterial: RefMaterial;
  /** ETCHMATREF_NBRE	NUMBER(3,0) */
  amount: number;
  /** ETCHMATREF_NBRE_INDISP NUMBER(3,0) */
  inOrderAmount: number;
  /** ETCHMATREF_NBRE_RESERV NUMBER(3,0) */
  dedicatedAmount: number;
}
