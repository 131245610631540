import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/services/app-api/api-response.model';
import { AppApiService } from 'src/app/core/services/app-api/app-api.service';
import { Anomaly } from '../../models/anomaly';
import { CertificationCompleted } from '../../models/certification-completed';
import { CertificationReasonChange } from '../../models/certification-reason-change';
import { CertificationValidate } from '../../models/certification-validate';
import { Deal } from '../../models/deal';
import { Expertise } from '../../models/expertise';
import { InterventionReason } from '../../models/intervention-reason';
import { ReducedUpdateCompleted } from '../../models/reduced-update-completed';
import { ReducedUpdateValidate } from '../../models/reduced-update-validate';
import { TrailedMaterialStatus } from '../../models/trailed-material-status';
import { CustomHttpParamEncoder } from '../custom-http-param-encoder';

@Injectable({
    providedIn: 'root'
  })
  export class CertificationService {
  
    /**
     * CertificationService constructor
     * @param api Inject Api service to manage http calls
     */
    constructor(private api: AppApiService) { }

    /**
     * Service qui controle l'affichage de la page de certification'
     * @param code type case
     */
    public controlAffichage(idWagon: number): Observable<ApiResponse<boolean>> {
        return this.api.get<ApiResponse<boolean>>(`/certification/valid-maintenance/${idWagon}`)
        .pipe(
        catchError(this.handleError<any>('controleAffichage', {data: null}))
        );
    } 

    /**
     * Service qui controle les mises à jour spécifiques sur le wagon en cours
     * @param id 
     */
    public controlOpeMaintenanceToDisplayPage(id: number): Observable<ApiResponse<string[]>> {
        return this.api.get<ApiResponse<string[]>>(`/certification/specificUpdate/${id}`)
        .pipe(
        catchError(this.handleError<any>('controlOpeMaintenanceToDisplayPage', {data: null}))
        );
    }

    /**
     * Service pour obtenir la liste des affaires
     * @param id 
     */
    public getDealsList(id: number): Observable<ApiResponse<Deal[]>> {
        return this.api.get<ApiResponse<Deal[]>>(`/certification/${id}/deals`)
        .pipe(
        catchError(this.handleError<any>('getDealsList', {data: null}))
        );
    }

    /**
     * Service pour obtenir la liste des Motifs
     * @param id 
     */
     public getInterventionReasonList(id: number, mex: string): Observable<ApiResponse<InterventionReason[]>> {
      let parameters: HttpParams = new HttpParams();
      parameters = parameters.set('mex', `${mex}`);
      return this.api.get<ApiResponse<InterventionReason[]>>(`/certification/${id}/reasons`, {params: parameters})
      .pipe(
      catchError(this.handleError<any>('getInterventionReasonList', {data: null}))
      );
    }

    /**
     * Service pour obtenir la liste des disponibilités
     * @param id 
     */
    public getAvailabilitiesList(codeMaintenance: string): Observable<ApiResponse<TrailedMaterialStatus[]>> {
      return this.api.get<ApiResponse<TrailedMaterialStatus[]>>(`/certification/${codeMaintenance}/availabilities`)
      .pipe(
      catchError(this.handleError<any>('getAvailabilitiesList', {data: null}))
      );
    }

    /**
     * Obtenir la valeur de Avis de Remise en service (mAVIS_DISPO)
     * @param id
     */
     public findReturnToServiceAdvice(id: number, mex: string): Observable<ApiResponse<number>> {
      let parameters: HttpParams = new HttpParams();
      parameters = parameters.set('mex', `${mex}`);
      return this.api.get<ApiResponse<number>>(`/certification/${id}/advice`, {params: parameters})
      .pipe(
      catchError(this.handleError<any>('findReturnToServiceAdvice', {data: null}))
      );
    }

    /**
     * Obtenir la valeur de Avis de Remise en service (mAVIS_DISPO) pour la MAJ Reduite
     * @param id
     */
     public findReturnToServiceAdviceReduced(id: number, mex: string, workshop: string, maintenanceOperation: string): Observable<ApiResponse<number>> {
      let parameters: HttpParams = new HttpParams({ encoder: new CustomHttpParamEncoder() });
      parameters = parameters.set('mex', `${mex}`);
      parameters = parameters.set('workshop', `${workshop}`);
      parameters = parameters.set('maintenanceOperation', `${maintenanceOperation}`);
      return this.api.get<ApiResponse<number>>(`/certification/${id}/advice-reduced`, {params: parameters})
      .pipe(
      catchError(this.handleError<any>('findReturnToServiceAdviceReduced', {data: null}))
      );
    }

    /**
     * Service pour obtenir l'expertise du wagon 
     */
    public getWagonExpertise(wagonId: number): Observable<ApiResponse<Expertise>> {
      return this.api.get<ApiResponse<Expertise>>(`/certification/${wagonId}/expertise`)
            .pipe(
              catchError(this.handleError<any>('getWagonExpertise', {data: null}))
          );
    }

    /**
     * Service pour enregistrer la modification du motif
     * @param idWagon
     * @param reason 
     */
     public saveReason(idWagon: number, reason: CertificationReasonChange): Observable<ApiResponse<boolean>> {
      return this.api.post<ApiResponse<boolean>>(`/certification/${idWagon}/reason`, reason);
    }

    /**
     * Service pour enregistrer la modification du amortissement d'un incident ou d'une réforme
     * @param idAnomaly
     * @param anomaly 
     */
     public saveAmortization(idAnomaly: number, anomaly: Anomaly): Observable<ApiResponse<boolean>> {
      return this.api.post<ApiResponse<boolean>>(`/certification/${idAnomaly}/amortization`, anomaly);
    }

    /**
     * Service pour generer et telecharger le rapport Certification
     * @param id
     * @returns 
     */
    public downloadCertificationReport(id: number): Observable<{blob: Blob, filename: string}> {
        return this.api.get<Blob>(`/certification/${id}/pdf`, {responseType: 'blob', observe: 'response'})
        .pipe(
          catchError(this.handleError<any>('downloadCertificationReport', null)),
          map((resp) => {
            const contentDisposition = resp.headers.get('Content-Disposition');
            const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
            return {blob: resp.body, filename: matches != null && matches[1] ? matches[1] : id + '.pdf'};
          })
        );
    }

    /**
     * Service qui retour les messages d'erreur pour l'ecran MAJ_004 – Certification
     * @param certificationValidate
     */
     public validate(certificationValidate: CertificationValidate): Observable<ApiResponse<CertificationCompleted>> {
      return this.api.post<ApiResponse<CertificationCompleted>>(`/certification/validate`, certificationValidate);
    }

    /**
     * Obtenir les infos pour l'envoi de mail ARES
     * @param id
     */
      public sendMailARES(id: number, noticeId: number): Observable<ApiResponse<boolean>> {
          return this.api.get<ApiResponse<boolean>>(`/certification/${id}/${noticeId}/mail`)
          .pipe(
          catchError(this.handleError<any>('sendMailARES', {data: null}))
          );
      }

    /**
     * Service qui retour les messages d'erreur pour l'ecran MAJ_005 Mise à jour réduite
     * @param reducedUpdateValidate
     */
    public validateReducedUpdate(reducedUpdateValidate: ReducedUpdateValidate): Observable<ApiResponse<ReducedUpdateCompleted>> {
      return this.api.post<ApiResponse<ReducedUpdateCompleted>>(`/certification/reduced-update-validate`, reducedUpdateValidate);
    }

    /**
     * Service pour enregistrer les donnees pour l'ecran MAJ_005 Mise à jour réduite
     * @param reducedUpdateValidate
     */
     public validateReducedUpdateAfterCAM(reducedUpdateValidate: ReducedUpdateValidate): Observable<ApiResponse<ReducedUpdateCompleted>> {
      return this.api.post<ApiResponse<ReducedUpdateCompleted>>(`/certification/reduced-update-validate-cam`, reducedUpdateValidate);
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation, result?: T) {
        return (error: any): Observable<T> => {
        return of(result as T);
        };
    }
}


