import { FloorNature } from './floor-nature';
import { RoofNature } from './roof-nature';
import { RoofType } from './roof-type';
import { TrancheTechnicalData } from './tranche-technical-data';
import { WagonTechnicalDataDetail } from './wagon-technical-data-detail';
import { WallNature } from './wall-nature';
import { Workshop } from './workshop';

/** API model: Superstructure onglet Datas Screen */
export class TechSpecSuperstructure {

  wagonID: number;

  mex: string;

  wagonTechnicalDataDetail: WagonTechnicalDataDetail;

  trancheTechnicalData: TrancheTechnicalData;

  workshops: Workshop[] = [];

  wallNatures: WallNature[] = [];

  roofTypes: RoofType[] = [];

  roofNatures: RoofNature[] = [];

  floorNatures: FloorNature[] = [];

  volume: number;

  usefulArea: number;

  usefulLength: number;

  usefulWidth: number;

  usefulWallHeight: number;

  usefulMiddleHeight: number;

  loadPlanHeight: number;

  unloadingOpeningCount: number;

  unloadingOpeningLength: number;

  unloadingOpeningWidth: number;

  unloadingOpeningHeight: number;

  loadingOpeningCount: number;

  loadingOpeningLength: number;

  loadingOpeningWidth: number;

  sideOpeningCount: number;

  sideOpeningHeight: number;

  sideOpeningWidth: number;

  modified: boolean;

  dateConstruction: Date;

  constructionWorkshop: Workshop;

  wallNature: WallNature;

  roofType: RoofType;

  roofNature: RoofNature;

  floorNature: FloorNature;

  showVolume: boolean;

  showSurface: boolean;

  showUsefulWallHeight: boolean;

  showUsefulMiddleHeight: boolean;

  showLoadPlanHeight: boolean;

  showLoadingOpening: boolean;

  showUnloadingOpening: boolean;

  showSideOpening: boolean;
}
