import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { tap } from 'rxjs/operators';

/**
 * Gestionnaire d'erreurs HTTP
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authSerice: AuthService) {
  }

  /**
   * Gestion d'une requête HTTP
   * @param request - La requête HTTP
   * @param next - handler suivant
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(tap(
        (ev: HttpEvent<any>) => this.handleEvent(ev),
        (err: any) => this.handleError(err)
      ));
  }

  /**
   * Gestion d'une erreur lors d'une requête HTTP
   * @param error - L'erreur HTTP
   */
  handleError(error: any) {
    // Si une erreur 401, les informations de connexion
    // existantes sont supprimées
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        this.authSerice.logout();
      }
    }
  }

  /**
   * Gestion d'un évènement HTTP (ex: réponse)
   * @param event - L'évenement HTTP
   */
  handleEvent(event: HttpEvent<any>) {
  }
}
