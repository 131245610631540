import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { MaskService } from 'ngx-mask';
import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { AgentService } from 'src/app/core/services/agent/agent.service';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { Expertise } from 'src/app/shared/models/expertise';
import { InterventionReason } from 'src/app/shared/models/intervention-reason';
import { MaintenanceOperation } from 'src/app/shared/models/maintenance-operation';
import { Workshop } from 'src/app/shared/models/workshop';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';

@Component({
  selector: 'app-init-expertise',
  templateUrl: './init-expertise.component.html',
  styleUrls: ['./init-expertise.component.scss']
})
export class InitExpertiseComponent implements OnInit {

  @Input() public pMex: string;
  @Input() public pWagonId: number;
  @Input() public pIsLevel34: boolean;
  @Output() cancelEvent = new EventEmitter();
  @Output() submitEvent = new EventEmitter<Expertise>();

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Agent workshop */
  workshop: Workshop;
  /** Physical wagon ID */
  currentWagonId: number;
  /** Wagon MEX */
  currentMex: string;
  /** Wagon MEX to display */
  currentMexMask: string;
  /** MaintenanceOperation selected */
  maintenanceOperationSelected: MaintenanceOperation;
  /** Maintenance Operation list */
  maintenanceOperationList: MaintenanceOperation[];
  /** InterventionReason selected */
  patternSelected: InterventionReason;
  /** La liste des motifs */
  patternList: InterventionReason[];
  /** Le wagon est sous contrat de maintenance  */
  isLevel34: boolean;
  /** Selected element in the maintenance operation list */
  modelOperationList: string;
  /** Selected element in the reason list */
  modelPatternList: string;
  /** Flag to control activation/desactivation of the Motif select */
  reasonEnabled: boolean;
  /** Flag to control activation/desactivation of the Validate button */
  btnValiderEnabled: boolean;
  
  constructor(
    private translateService: TranslateService,
    private agentService: AgentService,
    private maskService: MaskService,
    private referenceService: ReferenceService) {

    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.currentMex = this.pMex;
    this.currentWagonId = this.pWagonId;
    this.isLevel34 = this.pIsLevel34;

    // Rechercher l’établissement de l’opérateur de saisie
    this.subscriptionRefs.push(
      forkJoin(
        this.agentService.getWorkshop()
      ).subscribe(([workshop]) => {
        this.workshop = workshop.data;
      })
    );
    this.currentMexMask = this.maskService.applyMask(this.currentMex, '00 00 0000 000 0');

    // RI-P1-CU2-IHM01-006
    this.btnValiderEnabled = true;
    
    this.initMaintenanceOperationList();
    this.initInterventionReasonList();
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
  }

  /**
   * Maintenance Operation selection event
   */
   onMaintenanceOperationSelection(): void {
    if (this.maintenanceOperationList) {
      const opeMaintList = this.maintenanceOperationList.filter(mOp => mOp['codeLabel'] === this.modelOperationList);
      this.maintenanceOperationSelected = opeMaintList[0];
      this.patternSelected = null;
      this.modelPatternList = null;
      if (this.maintenanceOperationSelected && this.maintenanceOperationSelected.code
        && this.maintenanceOperationSelected.isReasonMandatory) {
        // RG-P1-CU2-IHM01-007
        this.reasonEnabled = true;
        // RG-P1-CU2-IHM01-009
        this.btnValiderEnabled = false;
      } else {
        // RG-P1-CU2-IHM01-006
        this.reasonEnabled = false;
        // RG-P1-CU2-IHM01-009
        this.btnValiderEnabled = !!this.maintenanceOperationSelected;
      }
    }
  }

  onPatternSelection(): void {
    if (this.patternList) {
      const patList = this.patternList.filter(mOp => mOp['codeLabel'] === this.modelPatternList);
      this.patternSelected = patList[0];
      this.btnValiderEnabled = !!this.patternSelected;
    }
  }

/**
 * Initialiser la liste des opérations de maintenance 
 */
  initMaintenanceOperationList(): void {
    const pageable = new Pageable();
    pageable.sort = 'code';
    const wagonId = this.currentWagonId !== 0 ? this.currentWagonId : undefined;
    this.subscriptionRefs.push(
      this.referenceService.getMaintenanceOperation(wagonId, undefined, undefined, pageable)
        .subscribe(mOpe => {
          this.maintenanceOperationList = mOpe.data;
          this.maintenanceOperationList.forEach(op => op['codeLabel']=op.code + ' : ' + op.label);
        })
    );
  }

/**
 * Initialiser la liste des motifs
 */
  initInterventionReasonList(): void {
    const pageable = new Pageable();
    pageable.sort = 'code';
    pageable.order = 'asc';
    if (this.isLevel34) {
      this.subscriptionRefs.push(
        this.referenceService.getInterventionsReason(this.currentMex, '10', pageable)
          .subscribe(interventionReason => {
            this.patternList = interventionReason.data;
            this.patternList.forEach(rs => rs['codeLabel']=rs.code + ' : ' + rs.label);
          })
     );
    } else {
      this.subscriptionRefs.push(
        this.referenceService.getAllInterventionsReason(pageable, true)
          .subscribe(interventionReason => {
            this.patternList = interventionReason.data;
            this.patternList.forEach(rs => rs['codeLabel']=rs.code + ' : ' + rs.label);
          })
      );
    }
  }

  cancelInitExpertise(): void {
    this.cancelEvent.emit();
  }

  /**
   * Method to submit the form
   */
   onSubmit(): void {
    const expertise = new Expertise();
    expertise.maintenanceOperation = this.maintenanceOperationSelected;
    expertise.interventionReason = this.patternSelected;
    expertise.workshop = this.workshop;
    this.submitEvent.emit(expertise);
   }
}
