import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-cancel-confirm',
  templateUrl: './dialog-cancel-confirm.component.html',
  styleUrls: ['./dialog-cancel-confirm.component.scss']
})
export class DialogCancelConfirmComponent {
  title: string;
  message: string;
  cancelLabel: string;
  confirmLabel: string;
  /**
   * `DialogCancelConfirmComponent` constructor
   * @param dialogRef reference to this component as a popable MatDialog
   * @param data Dialog injected data
   */
  constructor(
    public dialogRef: MatDialogRef<DialogCancelConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.title = data.title;
      this.message = data.message;
      this.cancelLabel = data.cancelLabel;
      this.confirmLabel = data.confirmLabel;
  }
}
