import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppApiService } from 'src/app/core/services/app-api/app-api.service';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/services/app-api/api-response.model';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { VpiPdf } from '../models/vpi-pdf';

@Injectable({
  providedIn: 'root'
})
export class VpiService {

  /**
   * VpiService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) { }

  /**
   * update vpi record
   * @param id vpi id
   * @param vpi vpi infos
   */
  public updateVpi(id: number, vpi: any): Observable<ApiResponse<any>>  {
    return this.api.put<Response>(`/vpi/${id}`, vpi, {responseType: 'json'}).pipe(
      catchError(this.handleError<any>('updateVpi', {data: null}))
    );
  }

  /**
   * create vpi record
   * @param vpi vpi infos
   */
  public createVpi(vpi: any): Observable<ApiResponse<any>>  {
    return this.api.post<ApiResponse<any>>(`/vpi`, vpi).pipe(
      catchError(this.handleError<any>('createVpi', {data: null}))
    );
  }

  /**
   * create vpi record
   * @param vpi vpi infos
   */
  public createVpi2(data: FormData): Observable<ApiResponse<any>>  {
    return this.api.post<ApiResponse<any>>(`/vpi`, data).pipe(
      catchError(this.handleError<any>('createVpi2', {data: null}))
    );
  }

  /**
   *
   * @param id vpi id
   */
  public getVpi(id: number): Observable<ApiResponse<any>>  {
    return this.api.get<ApiResponse<any>>(`/vpi/${id}`).pipe(
      catchError(this.handleError<any>('getVpi', {data: null}))
    );
  }

  /**
   *
   * @param id vpi id
   */
  public getVpiXml(id: number): Observable<string>  {
    const headers = new HttpHeaders().set('Accept', 'application/xml');
    return this.api.get<ApiResponse<any>>(`/vpi/xml/${id}`, { headers, responseType: 'text' }).pipe(
      catchError(this.handleError<any>('getVpiXml', null))
    );
  }

  /**
   *
   */
  public getVpiPdfList() {
    return this.api.get<ApiResponse<any>>(`/vpi/pdfs`).pipe(
      catchError(this.handleError<any>('getVpiPdfList', {data: []}))
    );
  }

  public getVpiPdfFile(id: number): Observable<Blob> {
    return this.api.get<Blob>(`/vpi/pdfs/${id}`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('getVpiPdfFile', null)),
      map((resp) => {
        return resp.body;
      })
    );
  }

  public updatePdf(value: VpiPdf, file: File): Observable<Response>  {
    const data = new FormData();
    data.append('vpi', new Blob([JSON.stringify(value)], { type: 'application/json' }));
    data.append('file', file, file.name);
    return this.api.put<Response>(`/vpi/pdfs/${value.id}`, data, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('updatePdf', null))
    );
  }

  public createPdf(value: VpiPdf, file: File): Observable<ApiResponse<VpiPdf>>  {
    const data = new FormData();
    data.append('vpi', new Blob([JSON.stringify(value)], { type: 'application/json' }));
    data.append('file', file);
    return this.api.post<ApiResponse<any>>(`/vpi/pdfs`, data).pipe(
      catchError(this.handleError<any>('createPdf', {data: null}))
    );
  }

  public deletePdf(id: number): Observable<any> {
    return this.api.delete<any>(`/vpi/pdfs/${id}`)
    .pipe(
      catchError(this.handleError<any>('deletePdf', null))
    );
  }

  public getVpiAttachment(attachment: any): Observable<File> {
    return this.api.get<Blob>(`/vpi/attachments/${attachment.id}`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('getVpiAttachment', null)),
      map((resp) => {
        return new File([resp.body], attachment.fileName, {type: resp.headers.get('content-type') });
      })
    );
  }

  public convertXml(file: File): Observable<ApiResponse<any>>  {
    const data = new FormData();
    data.append('file', file, file.name);
    /*const headers = new HttpHeaders().set('Accept', 'application/xml');
    return this.api.post<string>(`/vpi/xml`, data, { headers, responseType: 'text' }).pipe(
      catchError(this.handleError<any>('uploadXml', null))
    );*/
    return this.api.post<ApiResponse<any>>(`/vpi/xml`, data).pipe(
      catchError(this.handleError<any>('convertXml', {data: null}))
      );
  }

  /**
   * value: search params
   */
  public searchVpi(value: any): Observable<ApiResponse<any[]>> {
    let parameters: HttpParams = new HttpParams();
    if (value.item) {
      parameters = parameters.set('item', `${value.item}`);
    }
    if (value.order) {
      parameters = parameters.set('order', `${value.order}`);
    }
    if (value.holder) {
      parameters = parameters.set('holder', `${value.holder}`);
    }
    if (value.workshop) {
      parameters = parameters.set('workshop', `${value.workshop}`);
    }
    if (value.date1) {
      parameters = parameters.set('date1', `${value.date1}`);
    }
    if (value.date2) {
      parameters = parameters.set('date2', `${value.date2}`);
    }
    if (value.status) {
      parameters = parameters.set('status', `${value.status}`);
    }
    return this.api.get<ApiResponse<any[]>>(`/vpi`, { params: parameters }).pipe(
      catchError(this.handleError<any>('searchVpi', {data: []}))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
