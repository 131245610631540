import { Component, OnInit, Input } from '@angular/core';

import { AuthRoleEnum } from '../../services/auth/auth-role.enum';
import { AuthService } from '../../services/auth/auth.service';
import { MastNavConfig } from './mast-nav-config';
import { AgentService } from '../../services/agent/agent.service';
/**
 * `MastNavComponent` provide an application configurable `mastnav` element
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'nav.mastnav',
  templateUrl: './mast-nav.component.html',
  styleUrls: ['./mast-nav.component.scss']
})
export class MastNavComponent implements OnInit {
  /**
   * Navigation items configuration
   */
   @Input() config: MastNavConfig;
  /** index of active submenu */
  toggled: any;
  /** array of required habilitations */
  hasHab: boolean[];
  /**
   * Injection des rôles applicatifs dans la vue
   */
  AuthRoleEnum = AuthRoleEnum;
  /**
   * MastNavComponent constructor
   * @param authService Inject application's authentication service
   */
  constructor(public authService: AuthService, public agentService: AgentService) {  }
  /**
   * OnInit hook
   */
  ngOnInit() {
    this.agentService.getProcedureArray().subscribe (val => {
      if (val.length > 0) {
        this.config.top.forEach(element => {

           element.access = (!element.hasFunctionalProc || this.agentService.hasFunctionalProcedures(element.hasFunctionalProc))
                && (!element.hasOneOfFunctionalProc || this.agentService.hasAtLeastOneFunctionalProcedure(element.hasOneOfFunctionalProc))
                && (!element.hasService || this.agentService.hasService(element.hasService));
           if (element.subItems) {
            element.subItems.forEach( subElem => {
              subElem.access = (!subElem.hasFunctionalProc || this.agentService.hasFunctionalProcedures(subElem.hasFunctionalProc))
                && (!subElem.hasOneOfFunctionalProc || this.agentService.hasAtLeastOneFunctionalProcedure(subElem.hasOneOfFunctionalProc))
                && (!subElem.hasService || this.agentService.hasService(subElem.hasService));
            });
          }
        });
      } else {
        this.config.top.forEach(element => {
        element.access = !element.hasFunctionalProc && !element.hasOneOfFunctionalProc && !element.hasService;
        if (element.subItems && element.access) {
           element.subItems.forEach( subElem => {
             subElem.access = !subElem.hasFunctionalProc && !subElem.hasOneOfFunctionalProc && !subElem.hasService;
           });
         }
       });
      }
    });
  }

  toggleSubMenu(i: number): void {
    this.toggled !== i ? this.toggled = i : this.toggled = undefined;
  }
}
