import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { DialogCancelConfirmComponent } from 'src/app/shared/components/dialog-cancel-confirm/dialog-cancel-confirm.component';
import { TemplatePopupDialogComponent } from 'src/app/shared/components/template-popup-dialog/template-popup-dialog.component';
import { DerogationSystem } from 'src/app/shared/models/derogation-system';
import { DerogationSystemList } from 'src/app/shared/models/derogation-system-list';
import { Envelope } from 'src/app/shared/models/envelope';
import { Inventory } from 'src/app/shared/models/inventory';
import { LineCategoryComposition } from 'src/app/shared/models/line-category-composition';
import { TechSpecReferences } from 'src/app/shared/models/tech-spec-references';
import { TransitRegimeComposition } from 'src/app/shared/models/transit-regime-composition';
import { WagonTechnicalData } from 'src/app/shared/models/wagon-technical-data';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { LineCategory } from "../../../../../models/line-category";
import { SpecialAgreement } from "../../../../../models/special-agreement";
import { TareLoads } from "../../../../../models/tare-loads";
import { TransitRegime } from "../../../../../models/transit-regime";
import { WagonTechnicalDataDetail } from "../../../../../models/wagon-technical-data-detail";
import { MonitoringWorkService } from "../../../../../services/monitoring-work/monitoring-work.service";

@Component({
  selector: 'app-tare-loads',
  templateUrl: './tare-loads.component.html',
  styleUrls: ['./tare-loads.component.scss']
})
export class TareLoadsComponent implements OnInit, OnDestroy {

  @Input() public wagon: number;
  @Input() public wagonTechnicalData: WagonTechnicalData;
  @Input() public wagonTechnicalDataWO: WagonTechnicalData;
  @Input() public techSpecReferences: TechSpecReferences;
  @Input() public derogationSystemList: DerogationSystemList;
  @Output() modifiedDataEvent = new EventEmitter();

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  tareLoadsForm: FormGroup;
  tareCtrl: FormControl;
  normalPresenceCtrl: FormControl;
  linesCtrl: FormControl;
  regimesCtrl: FormControl;
  chargesCtrl: FormArray;
  chargesPreviewCtrl: FormArray;
  derogationsCtrl: FormArray;
  exceptionalLimitCtrl: FormControl;
  maxSpeedCtrl: FormControl;
  passengerMaxSpeedCtrl: FormControl;
  l1Ctrl: FormControl;
  l2Ctrl: FormControl;
  l3Ctrl: FormControl;
  l4Ctrl: FormControl;
  l5Ctrl: FormControl;
  l6Ctrl: FormControl;
  l7Ctrl: FormControl;
  l8Ctrl: FormControl;
  l9Ctrl: FormControl;
  showLine2: boolean;
  showLine2C: boolean;
  showLine2D: boolean;
  regimeLine2: string;
  l2CMaxiCtrl: FormControl;
  l2DMaxiCtrl: FormControl;
  showLine3: boolean;
  showLine3C: boolean;
  showLine3D: boolean;
  regimeLine3: string;
  l3CMaxiCtrl: FormControl;
  l3DMaxiCtrl: FormControl;
  isPreviewMode: boolean;

  chargesDisplayedColumns: string[] = ['regimes'];
  chargesDisplayedColumnsNames: Map<string, string> = new Map();
  chargesDS: MatTableDataSource<FormGroup>;
  chargesPreviewDS: MatTableDataSource<FormGroup>;

  derogationDisplayedColumns: string[] = ['specialAgreements', 'regime', 'line', 'maximumLoad', 'action'];
  derogationDS: MatTableDataSource<FormGroup>;

  tareLoads: TareLoads;
  technicalDataDetailWO: WagonTechnicalDataDetail;

  showTare: boolean;
  errorMessage: string;
  submitted: boolean;
  isSubmitting: boolean;
  duplicatedRow: boolean;
  validationErrorMessage: string;
  validationWarningMessage: string;
  numDisplayedColumns: number;
  lastSelectedLine: LineCategory;
  lastSelectedRegime: TransitRegime;
  currentLineCategory: LineCategory;
  currentTransitRegime: TransitRegime;

  listSeparator = " ";

  lineCategoryList: LineCategory[] = [];
  regimeList: TransitRegime[] = [];
  specialAgreementList: SpecialAgreement[] = [];
  chargesRegimeList: TransitRegime[] = [];
  chargesLineCategoryList: LineCategory[] = [];

  filteredSpecialAgreementList: SpecialAgreement[] = [];
  filteredChargesRegimeList: TransitRegime[] = [];
  filteredChargesLineCategoryList: LineCategory[] = [];

  transitRegimeFullList: TransitRegime[];
  modifSub: Subscription;
  alert: boolean;
  constructor(private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private monitoringWorkService: MonitoringWorkService,
              private dialog: MatDialog) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.tareCtrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,10}$/)]);
    this.normalPresenceCtrl = this.formBuilder.control(false);
    this.linesCtrl = this.formBuilder.control(null, Validators.required);
    this.regimesCtrl = this.formBuilder.control(null, Validators.required);
    this.chargesCtrl = this.formBuilder.array([]);
    // Charges normales pour le deuxième régime d'acheminement (deuxième ligne)
    this.l2CMaxiCtrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l2DMaxiCtrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    // Charges normales pour le troisième régime d'acheminement (troisième ligne)
    this.l3CMaxiCtrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l3DMaxiCtrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.derogationsCtrl = this.formBuilder.array([]);
    this.exceptionalLimitCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,3}(\.\d)?$/));
    this.maxSpeedCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,5}$/));
    this.passengerMaxSpeedCtrl = this.formBuilder.control(null, Validators.pattern(/^\d{1,5}$/));
    this.tareLoadsForm = this.formBuilder.group({
      tare: this.tareCtrl,
      normalPresence: this.normalPresenceCtrl,
      lines: this.linesCtrl,
      regimes: this.regimesCtrl,
      charges: this.chargesCtrl,
      l2CMaxi: this.l2CMaxiCtrl,
      l2DMaxi: this.l2DMaxiCtrl,
      l3CMaxi: this.l3CMaxiCtrl,
      l3DMaxi: this.l3DMaxiCtrl,
      derogations: this.derogationsCtrl,
      exceptionalLimit: this.exceptionalLimitCtrl,
      maxSpeed: this.maxSpeedCtrl,
      passengerMaxSpeed: this.passengerMaxSpeedCtrl
    });
    
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.modifSub = this.tareLoadsForm.valueChanges.subscribe(
      _ => this.modifiedDataEvent.emit(this.tareLoadsForm.dirty));

    this.loadInitDatas();
  }

  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
      this.subscriptionRefs.push(
        this.monitoringWorkService.loadTareLoads(this.wagon)
          .subscribe((tare) => {
            this.tareLoads = tare.data;
            Object.entries(tare.data.wagonTechnicalData).forEach( pair => {
              this.wagonTechnicalDataWO[pair[0]] = pair[1];
            });
            this.transitRegimeFullList = this.techSpecReferences.transitRegimeList;
            this.specialAgreementList = this.derogationSystemList.specialAgreement;
            this.chargesRegimeList = this.derogationSystemList.transitRegime;
            this.chargesLineCategoryList = this.derogationSystemList.lineCategory;
            this.filteredSpecialAgreementList = this.specialAgreementList;
            this.filteredChargesRegimeList = this.chargesRegimeList;
            this.filteredChargesLineCategoryList = this.chargesLineCategoryList;
            this.resetDatas();
            this.loadingSubject.next(false);
          })
        );
  }

  /**
   * Reset les donnes
   */
  resetDatas(): void {
    if (!this.tareLoads) {
      return;
    }
    this.technicalDataDetailWO = this.tareLoads.wagonTechnicalDataDetail;

    this.initialControl();

    this.tareCtrl.setValue(this.technicalDataDetailWO.tare);

    this.createLineCategoryList();
    this.createTransitRegimeList();
    // Charges normales pour le premier régime d'acheminement (première ligne)
    this.l1Ctrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l2Ctrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l3Ctrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l4Ctrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l5Ctrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l6Ctrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l7Ctrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l8Ctrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    this.l9Ctrl = this.formBuilder.control(null, [Validators.required, Validators.pattern(/^\d{1,3}(\.\d)?$/)]);
    
    if (this.technicalDataDetailWO.envelope) {
      this.normalPresenceCtrl.setValue(true);

      // Lines
      const lc = new LineCategory();
      lc.code = this.getLabelLineCategory(this.technicalDataDetailWO.envelope.lineCategoryComposition);
      lc.label = lc.code;
      lc["id"] = this.technicalDataDetailWO.envelope.lineCategoryComposition.id;
      this.currentLineCategory = lc;

      const tr = new TransitRegime();
      tr.code = this.getLabelTransitRegime(this.technicalDataDetailWO.envelope.transitRegimeComposition);
      tr.label = tr.code;
      tr["id"] = this.technicalDataDetailWO.envelope.transitRegimeComposition.id;
      this.currentTransitRegime = tr;
      
      this.resetLinesList();

      this.resetTableColumns();

      this.resetRegimesList();

      this.resetTableRows();

      this.setDefaultValuesCharges();
    }

    this.derogationsCtrl.clear();
    const derogationSystemList = this.technicalDataDetailWO.derogationSystemList;
    derogationSystemList.forEach(elem => {
      this.derogationsCtrl.push(
        this.addFormGroup(false, elem.specialAgreement, elem.transitRegime, elem.lineCategory, elem.maximumLoad)
      );
    });
    this.derogationsCtrl.push(this.addFormGroup());
    this.derogationDS = new MatTableDataSource<FormGroup>(this.derogationsCtrl.controls as FormGroup[]);

    this.exceptionalLimitCtrl.setValue(this.technicalDataDetailWO.exceptionalLoadingLimit);
    this.maxSpeedCtrl.setValue(this.technicalDataDetailWO.maximumSpeed);
    this.passengerMaxSpeedCtrl.setValue(this.technicalDataDetailWO.passengerTrainMaximumSpeed);

    this.preparePreviewModeDS();

    this.errorMessage = '';
    this.validationErrorMessage = '';
    this.validationWarningMessage = '';

    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
  
    this.tareLoadsForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
    this.tables.forEach(table => table.renderRows());
  }

  createLineCategoryList(): void {
    this.lineCategoryList = [];
    this.tareLoads.lineCategoryCompositionList.forEach(
      element => {
        const lc = new LineCategory();
        lc.code = this.getLabelLineCategory(element);
        lc.label = lc.code;
        lc["id"] = element.id;
        this.lineCategoryList.push(lc);
    });
  }

  createTransitRegimeList(): void {
    this.regimeList = [];
    this.tareLoads.transitRegimeCompositionList.forEach(
      element => {
        const tr = new TransitRegime();
        tr.code = this.getLabelTransitRegime(element);
        tr.label = tr.code;
        tr["id"] = element.id;
        this.regimeList.push(tr);
    });
  }

  getLabelLineCategory(element: LineCategoryComposition): string {
    let code = "";
    if (element.lineCategory1) {
      code += element.lineCategory1.code;
    }
    if (element.lineCategory2) {
      code += this.listSeparator + element.lineCategory2.code;
    }
    if (element.lineCategory3) {
      code += this.listSeparator + element.lineCategory3.code;
    }
    if (element.lineCategory4) {
      code += this.listSeparator + element.lineCategory4.code;
    }
    if (element.lineCategory5) {
      code += this.listSeparator + element.lineCategory5.code;
    }
    if (element.lineCategory6) {
      code += this.listSeparator + element.lineCategory6.code;
    }
    if (element.lineCategory7) {
      code += this.listSeparator + element.lineCategory7.code;
    }
    if (element.lineCategory8) {
      code += this.listSeparator + element.lineCategory8.code;
    }
    if (element.lineCategory9) {
      code += this.listSeparator + element.lineCategory9.code;
    }

    return code;
  }

  getLabelTransitRegime(element: TransitRegimeComposition): string {
    let code = "";

    if (element.transitRegime1) {
      code += element.transitRegime1.code;
    }
    if (element.transitRegime2) {
      code += this.listSeparator + element.transitRegime2.code;
    }
    if (element.transitRegime3) {
      code += this.listSeparator + element.transitRegime3.code;
    }
     
    return code;
  }

  /**
   * Methode to submit the form
   */
  async onSubmit(alert?: boolean): Promise<void> {
    this.alert = alert === undefined ? true : alert;
    if (this.duplicatedRow) {
      this.duplicatedRow = false;
      return;
    }
    
    this.submitted = true;
    this.validationErrorMessage = await this.checkValidationRules();
  }

  async doSubmit(): Promise<void> {
    
    this.loadingSubject.next(true);
    this.isSubmitting = true;

    const updateTareLoads = new TareLoads();
    updateTareLoads.wagonID = this.wagon;
    updateTareLoads.wagonTechnicalDataDetail = new WagonTechnicalDataDetail();
    updateTareLoads.wagonTechnicalDataDetail.mex = this.tareLoads.wagonTechnicalData.mex;
    updateTareLoads.wagonTechnicalDataDetail.inventory = new Inventory();
    updateTareLoads.wagonTechnicalDataDetail.inventory.code = this.tareLoads.wagonTechnicalData.inventory.code;
    updateTareLoads.wagonTechnicalDataDetail.tare = this.tareCtrl.value;
    if (this.normalPresenceCtrl.value) {
      updateTareLoads.wagonTechnicalDataDetail.envelope = new Envelope();
      updateTareLoads.wagonTechnicalDataDetail.envelope.lineCategoryComposition = new LineCategoryComposition();
      updateTareLoads.wagonTechnicalDataDetail.envelope.lineCategoryComposition.id = this.lineCategoryList.filter(l => l.code === this.linesCtrl.value)[0]['id'];
      updateTareLoads.wagonTechnicalDataDetail.envelope.transitRegimeComposition = new TransitRegimeComposition();
      updateTareLoads.wagonTechnicalDataDetail.envelope.transitRegimeComposition.id = this.regimeList.filter(r => r.code === this.regimesCtrl.value)[0]['id'];
      this.setLinesValues(updateTareLoads.wagonTechnicalDataDetail);
      updateTareLoads.wagonTechnicalDataDetail.lineValue2C = this.l2CMaxiCtrl.value;
      updateTareLoads.wagonTechnicalDataDetail.lineValue2D = this.l2DMaxiCtrl.value;
      updateTareLoads.wagonTechnicalDataDetail.lineValue3C = this.l3CMaxiCtrl.value;
      updateTareLoads.wagonTechnicalDataDetail.lineValue3D = this.l3DMaxiCtrl.value;
    } else {
      updateTareLoads.wagonTechnicalDataDetail.envelope = null;
    }
    updateTareLoads.wagonTechnicalDataDetail.exceptionalLoadingLimit = this.exceptionalLimitCtrl.value;
    updateTareLoads.wagonTechnicalDataDetail.maximumSpeed = this.maxSpeedCtrl.value;
    updateTareLoads.wagonTechnicalDataDetail.passengerTrainMaximumSpeed = this.passengerMaxSpeedCtrl.value;

    const updateDerogationList = [];
    const derogList = this.derogationsCtrl.value;
    derogList.forEach(element => {
      if (element.specialAgreement) {
        const newDerogation = new DerogationSystem();
        newDerogation.specialAgreement = element.specialAgreement;
        newDerogation.lineCategory = element.line;
        newDerogation.transitRegime = element.regime;
        newDerogation.mex = this.wagonTechnicalData.mex;
        newDerogation.inventory = new Inventory();
        newDerogation.inventory.code = this.tareLoads.wagonTechnicalData.inventory.code;
        newDerogation.maximumLoad = element.maximumLoad;
        updateDerogationList.push(newDerogation);
      }
    });
    updateTareLoads.wagonTechnicalDataDetail.derogationSystemList = updateDerogationList;

    //this.subscriptionRefs.push(
    await this.monitoringWorkService.updateTareLoads(updateTareLoads).pipe(first()).toPromise();
    this.tareLoadsForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
    this.submitted = false;
    this.isSubmitting = false;
    this.isPreviewMode = false;
    this.loadingSubject.next(false);
    this.updateDone();
  }

  setLinesValues(wagonTechnicalDataDetail: WagonTechnicalDataDetail): void {
    const selectedLine = this.linesCtrl.value;
      const columns = selectedLine.split(this.listSeparator);
      let index = 0;
      if (index < this.numDisplayedColumns) {
        this.setLineColumnValue(wagonTechnicalDataDetail, columns[index], this.l1Ctrl.value);
      }
      index++;
      if (index < this.numDisplayedColumns) {
        this.setLineColumnValue(wagonTechnicalDataDetail, columns[index], this.l2Ctrl.value);
      }
      index++;
      if (index < this.numDisplayedColumns) {
        this.setLineColumnValue(wagonTechnicalDataDetail, columns[index], this.l3Ctrl.value);
      }
      index++;
      if (index < this.numDisplayedColumns) {
        this.setLineColumnValue(wagonTechnicalDataDetail, columns[index], this.l4Ctrl.value);
      }
      index++;
      if (index < this.numDisplayedColumns) {
        this.setLineColumnValue(wagonTechnicalDataDetail, columns[index], this.l5Ctrl.value);
      }
      index++;
      if (index < this.numDisplayedColumns) {
        this.setLineColumnValue(wagonTechnicalDataDetail, columns[index], this.l6Ctrl.value);
      }
      index++;
      if (index < this.numDisplayedColumns) {
        this.setLineColumnValue(wagonTechnicalDataDetail, columns[index], this.l7Ctrl.value);
      }
      index++;
      if (index < this.numDisplayedColumns) {
        this.setLineColumnValue(wagonTechnicalDataDetail, columns[index], this.l8Ctrl.value);
      }
      index++;
      if (index < this.numDisplayedColumns) {
        this.setLineColumnValue(wagonTechnicalDataDetail, columns[index], this.l9Ctrl.value);
      }
  }

  async checkValidationRules(): Promise<string> {

    if (this.normalPresenceCtrl.value && (!this.linesCtrl.value || !this.regimesCtrl.value)) {
      return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_4");
    }

    return await this.checkWarningMass();
  }

  async checkWarningMass(): Promise<string> {
    if ((this.tareLoads.mNbEss > 0) && (this.tareCtrl.value)) {

      const vc4 = (this.wagonTechnicalData.lineValue1C4?this.wagonTechnicalData.lineValue1C4:0);
      const vd4 = (this.wagonTechnicalData.lineValue1C4?this.wagonTechnicalData.lineValue1D4:0);
      const mChargeMaxWag = (Math.max(+vc4, +vd4)) * 100;
      const mChargeMaxEss = (mChargeMaxWag + this.tareCtrl.value) / this.tareLoads.mNbEss;

      if ((this.tareLoads.mChargeMinEssRef > 0) && (mChargeMaxEss > 0)) {
        const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
          data: {
            title: 'wagon.technical.loads.msg_warning_title',
            message: 'wagon.technical.loads.Controle2',
            cancelLabel: 'actions.quit',
            confirmLabel: 'actions.valid',
          }
        });
        const result = await dialogRef.afterClosed().pipe(first()).toPromise();
        if (result) {
            // Si l'utilisateur clique sur " Valider " alors passer au contrôle suivant
          this.validationErrorMessage = await this.checkValidationRulesPostWarning();
        }
        return this.validationErrorMessage;
      }
    }

    // Sinon (pas de message d'avertissement), passer directement au contrôle suivant
    return await this.checkValidationRulesPostWarning();
  }

  async checkValidationRulesPostWarning(): Promise<string> {

    // Adaptation de la tare en cours
    let roundedTare = 0;
    if (this.tareCtrl.value) {
      roundedTare =  (this.tareCtrl.value / 10) * 100;
      roundedTare = roundedTare / 100;
    }

    this.validationWarningMessage = "";
    const wagonValidation = new WagonTechnicalDataDetail();

    if (this.normalPresenceCtrl.value) {
      
      this.setLinesValues(wagonValidation);

      if (wagonValidation.lineValue1A
        && wagonValidation.lineValue1A > ((16*this.tareLoads.wessNumber) - roundedTare)) {
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1A) + "<br>";
      }
      if (wagonValidation.lineValue1B2
        && wagonValidation.lineValue1B2 > ((18*this.tareLoads.wessNumber) - roundedTare)) {
        // RG_MAJ_003_5_32
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1B2) + "<br>";
      }
      if (wagonValidation.lineValue1C4
        && wagonValidation.lineValue1C4 > ((20.5*this.tareLoads.wessNumber) - roundedTare)) {
        // RG_MAJ_003_5_33
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1C4) + "<br>";
      }
      if (((wagonValidation.lineValue1D2 && wagonValidation.lineValue1D2 != 0)
          || (wagonValidation.lineValue1D3 && wagonValidation.lineValue1D3 != 0))
          && wagonValidation.lineValue1D4 && wagonValidation.lineValue1D2 != 0
          && wagonValidation.lineValue1D4 > ((22.5*this.tareLoads.wessNumber) - roundedTare)) {
        // RG_MAJ_003_5_34
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1D4) + "<br>";
      }
      if (wagonValidation.lineValue1B1
          && wagonValidation.lineValue1B1 > ((5*this.wagonTechnicalData.overallLength) - roundedTare)) {
        // RG_MAJ_003_5_35
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1B1) + "<br>";
      }
      if (wagonValidation.lineValue1C2
        && wagonValidation.lineValue1C2 > ((6.4*this.wagonTechnicalData.overallLength) - roundedTare)) {
        // RG_MAJ_003_5_36
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1C2) + "<br>";
      }
      if (wagonValidation.lineValue1C3
        && wagonValidation.lineValue1C3 > ((7.2*this.wagonTechnicalData.overallLength) - roundedTare)) {
        // RG_MAJ_003_5_37
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1C3) + "<br>";
      }
      if (wagonValidation.lineValue1C4
        && wagonValidation.lineValue1C4 > ((8*this.wagonTechnicalData.overallLength) - roundedTare)) {
        // RG_MAJ_003_5_38
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1C4) + "<br>";
      }
      if (wagonValidation.lineValue1D2
        && wagonValidation.lineValue1D2 > ((6.4*this.wagonTechnicalData.overallLength) - roundedTare)) {
        // RG_MAJ_003_5_39
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1D2) + "<br>";
      }
      if (wagonValidation.lineValue1D3
        && wagonValidation.lineValue1D3 > ((7.2*this.wagonTechnicalData.overallLength) - roundedTare)) {
        // RG_MAJ_003_5_40
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1D3) + "<br>";
      }
      if (wagonValidation.lineValue1D4
        && wagonValidation.lineValue1D4 > ((8*this.wagonTechnicalData.overallLength) - roundedTare)) {
        // RG_MAJ_003_5_41
        this.validationWarningMessage += this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31").replace("$1", wagonValidation.lineValue1D4) + "<br>";
      }
    }

    if (this.validationWarningMessage) {
      // MARGO-158
      await this.showWarningMessages(wagonValidation, roundedTare);
      return this.validationErrorMessage;
    }
    const res = await this.controlsPostWarning(wagonValidation, roundedTare);
    return res;
  }

  async controlsPostWarning(wagonValidation: WagonTechnicalDataDetail, roundedTare: number): Promise<string> {
    if (!this.tareCtrl.value) {
      return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_8");
    }
    const trancheTechnicalData = this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData;
    if (trancheTechnicalData.bogieCount === "0" && Number(this.tareCtrl.value) < 8000 ) {
      return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_5");
    }
    if (trancheTechnicalData.bogieCount !== "0" && Number(this.tareCtrl.value) < 14000 ) {
      return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_6");
    }
    if (Number(this.tareCtrl.value) > (trancheTechnicalData.trpTareMax*1000)) {
      return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_7");
    }
    if (this.normalPresenceCtrl.value) {
      const columnErr = this.validateEmptyChargesTable();
      if (columnErr > 0) {
         return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_9")
          .replace("$1", this.chargesDisplayedColumnsNames.get('l'+columnErr))
          .replace("$2", this.chargesCtrl.controls[0].value.regime);
      }
      if (this.checkInvalidValuesABCChargesTable(wagonValidation)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_10");
      }
      if (this.checkInvalidValuesDChargesTable(wagonValidation)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_11");
      }
      if (this.checkInvalidValuesCDChargesTable(wagonValidation)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_12");
      }
      if ((this.chargesCtrl.controls.length > 1) && (!this.l2CMaxiCtrl.value)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_13");
      }
      if ((this.chargesCtrl.controls.length > 2) && (!this.l3CMaxiCtrl.value)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_13");
      }
      if ((this.chargesCtrl.controls.length > 1) && (this.containsCodeLine("D")) && (!this.l2DMaxiCtrl.value)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_14");
      }
      if ((this.chargesCtrl.controls.length > 2) && (this.containsCodeLine("D")) && (!this.l3DMaxiCtrl.value)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_14");
      }
      if (!this.validateRegimeChargeMaxi()) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_15");
      }
      if ((this.chargesCtrl.controls.length > 2) 
        && (this.l3CMaxiCtrl.value && this.l3CMaxiCtrl.value >= this.l2CMaxiCtrl.value)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_16");
      }
      if ((this.chargesCtrl.controls.length > 2) 
        && (this.l3DMaxiCtrl.value && this.l3DMaxiCtrl.value >= this.l2DMaxiCtrl.value)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_17");
      }
      if ((this.chargesCtrl.controls.length > 1) 
        && (this.l2DMaxiCtrl.value && this.l2DMaxiCtrl.value < this.l2CMaxiCtrl.value)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_18").replace("$1", "2");
      }
      if ((this.chargesCtrl.controls.length > 2) 
        && (this.l3DMaxiCtrl.value && this.l3DMaxiCtrl.value < this.l3CMaxiCtrl.value)) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_18").replace("$1", "3");
      }
      if (trancheTechnicalData.trpRegimeS === "0" && this.getTableContainsRegime(["S", "S**", "100"])) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_19");
      }
      if (trancheTechnicalData.trpRegimeS === "1" && !this.getTableContainsRegime(["S", "S**", "SS"])) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_20");
      }
      if (trancheTechnicalData.trpRegimeS === "9" && this.getTableContainsRegime(["S", "S**", "SS"])) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_21");
      }
      if (trancheTechnicalData.trpRegimeSS === "0" && this.getTableContainsRegime(["SS", "120"])) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_22");
      }
      if (trancheTechnicalData.trpRegimeSS === "9" && this.getTableContainsRegime(["SS"])) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_23");
      }
      if (trancheTechnicalData.trpRegimeSS === "1" && !this.getTableContainsRegime(["SS"])) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_24");
      }
      if (trancheTechnicalData.trpRegimeSS === "2" && !this.getTableContainsRegime(["120"])) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_25");
      }
      const tValC4 = this.getValueFromTable("C4");
      if (tValC4 && ((tValC4 + roundedTare)/this.tareLoads.wessNumber) > 22000) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_26");
      }
      const tValD4 = this.getValueFromTable("D4");
      if (tValD4 && ((tValD4 + roundedTare)/this.tareLoads.wessNumber) > 25000) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_27");
      }
      const limitValue = this.validateLimitValues();
      if (limitValue !== 0) {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_28").replace("$1", limitValue.toString());
      }
      const formatError = this.validateNumberFormat();
      if (formatError !== "") {
        return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_29").replace("$1", formatError);
      }
      // RG_MAJ_003_24: Vérification des régimes dérogatoires par rapport aux propriétés de la tranche
      if ((this.derogationsCtrl.controls.length === 1) &&
        ((this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData.trpDeroInf100 === '1' )
        || (this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData.trpDero100 === '1' )
        || (this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData.trpDero120 === '1' )
        || (this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData.trpDeroAutre === '1' ))) {
          return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_24");
      } else if ((this.derogationsCtrl.controls.length > 1) &&
                ((this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData.trpDeroInf100 === '0' )
                && (this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData.trpDero100 === '0' )
                && (this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData.trpDero120 === '0' )
                && (this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData.trpDeroAutre === '0' ))) {
          return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_24");
      }
      // RG_MAJ_003_25
      if (this.derogationsCtrl.controls.length > 1) {
        for (let index = 0; index < this.derogationsCtrl.controls.length -1; index++) {
          if (this.derogationsCtrl.controls[index].value.regime) {
            
            const codeRegime = this.derogationsCtrl.controls[index].value.regime.code;
            const regimeFound = this.transitRegimeFullList.filter(reg => reg.code === codeRegime);
            if (regimeFound.length === 0) {            
              return this.translateService.instant("wagon.technical.loads.RG_MAJ_003_25");
            }
          }
        }
      }
      const requiredRegime = this.validateRequiredRegimes();
      if (requiredRegime !== "") {
        return this.translateService.instant("wagon.technical.loads.Controle41").replace("$1", requiredRegime);
      }
    }
    await this.validateMaxSpeed();

    return this.validationErrorMessage;
  }

  validateEmptyChargesTable(): number {
    const columnNbr = this.numDisplayedColumns;
    
    if (!this.l1Ctrl.value) {
      return 1;
    } else if(!this.l2Ctrl.value) {
      if (columnNbr > 1) { 
        return 2;
      }
    } else if(!this.l3Ctrl.value) {
      if (columnNbr > 2) { 
        return 3;
      }
    } else if(!this.l4Ctrl.value) {
      if (columnNbr > 3) { 
        return 4;
      }
    } else if(!this.l5Ctrl.value) {
      if (columnNbr > 4) { 
        return 5;
      }
    } else if(!this.l6Ctrl.value) {
      if (columnNbr > 5) { 
        return 6;
      }
    } else if(!this.l7Ctrl.value) {
      if (columnNbr > 6) { 
        return 7;
      }
    } else if(!this.l8Ctrl.value) {
      if (columnNbr > 7) { 
        return 8;
      }
    } else if(!this.l9Ctrl.value) {
      if (columnNbr > 8) { 
        return 9;
      }
    }

    return 0;
  }

  checkInvalidValuesABCChargesTable(wagonValidation: WagonTechnicalDataDetail): boolean {
    return (wagonValidation.lineValue1B1 && wagonValidation.lineValue1B1 < wagonValidation.lineValue1A)
        || (wagonValidation.lineValue1B1 && wagonValidation.lineValue1C2 && wagonValidation.lineValue1C2 < wagonValidation.lineValue1B1)
        || (wagonValidation.lineValue1B2 && wagonValidation.lineValue1B2 < wagonValidation.lineValue1B1)
        || (wagonValidation.lineValue1C2 && wagonValidation.lineValue1C2 < wagonValidation.lineValue1B2)
        || (wagonValidation.lineValue1C3 && wagonValidation.lineValue1C3 < wagonValidation.lineValue1C2)
        || (wagonValidation.lineValue1C4 && wagonValidation.lineValue1C4 < wagonValidation.lineValue1C3);
  }

  checkInvalidValuesDChargesTable(wagonValidation: WagonTechnicalDataDetail): boolean {
    return (wagonValidation.lineValue1D2 && wagonValidation.lineValue1D2 == 0)
        || (wagonValidation.lineValue1D3 && (wagonValidation.lineValue1D3 == 0 || wagonValidation.lineValue1D3 < wagonValidation.lineValue1D2))
        || (wagonValidation.lineValue1D4 && (wagonValidation.lineValue1D4 == 0 || wagonValidation.lineValue1D4 < wagonValidation.lineValue1D3));
  }

  checkInvalidValuesCDChargesTable(wagonValidation: WagonTechnicalDataDetail): boolean {
    return wagonValidation.lineValue1C4 && wagonValidation.lineValue1D4
          && wagonValidation.lineValue1D4 < wagonValidation.lineValue1C4;
  }

  validateRegimeChargeMaxi(): boolean {
    let ret = true;
    if (this.chargesCtrl.controls.length > 1) {
      const regimeCode = this.chargesCtrl.controls[1].value.regime;
      if (regimeCode === "100" || regimeCode === "120") {
        if (this.l2CMaxiCtrl.value && this.l2CMaxiCtrl.value != 0) {
          ret = false;
        }
        if (this.l2DMaxiCtrl.value && this.l2DMaxiCtrl.value != 0) {
          ret = false;
        }
      }
    }
    if (this.chargesCtrl.controls.length > 2) {
      const regimeCode = this.chargesCtrl.controls[2].value.regime;
      if (regimeCode === "100" || regimeCode === "120") {
        if (this.l3CMaxiCtrl.value && this.l3CMaxiCtrl.value != 0) {
          ret = false;
        }
        if (this.l3DMaxiCtrl.value && this.l3DMaxiCtrl.value != 0) {
          ret = false;
        }
      }
    }
    return ret;
  }

  getTableContainsRegime(codesToValidate: string[]): boolean {
    for (let i=0; i<this.chargesCtrl.controls.length; i++) {
      if (codesToValidate.indexOf(this.chargesCtrl.controls[i].value.regime) >= 0) {
        return true;
      }
    }
    if (this.regimeLine2 && codesToValidate.indexOf(this.regimeLine2) >= 0) {
      return true;
    }
    if (this.regimeLine3 && codesToValidate.indexOf(this.regimeLine3) >= 0) {
      return true;
    }
    return false;
  }

  validateLimitValues(): number {
    if (this.l1Ctrl.value && (this.l1Ctrl.value < 0 || Number(this.l1Ctrl.value) > 699.9)) {
      return this.l1Ctrl.value;
    }
    if (this.l2Ctrl.value && (this.l2Ctrl.value < 0 || Number(this.l2Ctrl.value) > 699.9)) {
      return this.l2Ctrl.value;
    }
    if (this.l3Ctrl.value && (this.l3Ctrl.value < 0 || Number(this.l3Ctrl.value) > 699.9)) {
      return this.l3Ctrl.value;
    }
    if (this.l4Ctrl.value && (this.l4Ctrl.value < 0 || Number(this.l4Ctrl.value) > 699.9)) {
      return this.l4Ctrl.value;
    }
    if (this.l5Ctrl.value && (this.l5Ctrl.value < 0 || Number(this.l5Ctrl.value) > 699.9)) {
      return this.l5Ctrl.value;
    }
    if (this.l6Ctrl.value && (this.l6Ctrl.value < 0 || Number(this.l6Ctrl.value) > 699.9)) {
      return this.l6Ctrl.value;
    }
    if (this.l7Ctrl.value && (this.l7Ctrl.value < 0 || Number(this.l7Ctrl.value) > 699.9)) {
      return this.l7Ctrl.value;
    }
    if (this.l8Ctrl.value && (this.l8Ctrl.value < 0 || Number(this.l8Ctrl.value) > 699.9)) {
      return this.l8Ctrl.value;
    }
    if (this.l9Ctrl.value && (this.l9Ctrl.value < 0 || Number(this.l9Ctrl.value) > 699.9)) {
      return this.l9Ctrl.value;
    }
    if ((this.chargesCtrl.controls.length > 1) && this.l2CMaxiCtrl.value && (this.l2CMaxiCtrl.value < 0 || Number(this.l2CMaxiCtrl.value) > 699.9)) {
      return this.l2CMaxiCtrl.value;
    }
    if ((this.chargesCtrl.controls.length > 1) && this.l2DMaxiCtrl.value && (this.l2DMaxiCtrl.value < 0 || Number(this.l2DMaxiCtrl.value) > 699.9)) {
      return this.l2DMaxiCtrl.value;
    }
    if ((this.chargesCtrl.controls.length > 2) && this.l3CMaxiCtrl.value && (this.l3CMaxiCtrl.value < 0 || Number(this.l3CMaxiCtrl.value) > 699.9)) {
      return this.l3CMaxiCtrl.value;
    }
    if ((this.chargesCtrl.controls.length > 2) && this.l3DMaxiCtrl.value && (this.l3DMaxiCtrl.value < 0 || Number(this.l3DMaxiCtrl.value) > 699.9)) {
      return this.l3DMaxiCtrl.value;
    }
    return 0;
  }

  validateNumberFormat(): string {
    if ((this.l1Ctrl.value && !this.l1Ctrl.valid)
      || (this.l2Ctrl.value && !this.l2Ctrl.valid)
      || (this.l3Ctrl.value && !this.l3Ctrl.valid)
      || (this.l4Ctrl.value && !this.l4Ctrl.valid)
      || (this.l5Ctrl.value && !this.l5Ctrl.valid)
      || (this.l6Ctrl.value && !this.l6Ctrl.valid)
      || (this.l7Ctrl.value && !this.l7Ctrl.valid)
      || (this.l8Ctrl.value && !this.l8Ctrl.valid)
      || (this.l9Ctrl.value && !this.l9Ctrl.valid)) {
      return this.translateService.instant("wagon.technical.loads.normal");
    }
    if (((this.chargesCtrl.controls.length > 1) && this.l2CMaxiCtrl.value && !this.l2CMaxiCtrl.valid)
      || ((this.chargesCtrl.controls.length > 2) && this.l3CMaxiCtrl.value && !this.l3CMaxiCtrl.valid)) {
      return this.translateService.instant("wagon.technical.loads.load_c_max");
    }
    if (((this.chargesCtrl.controls.length > 1) && this.l2DMaxiCtrl.value && !this.l2DMaxiCtrl.valid)
      || ((this.chargesCtrl.controls.length > 2) && this.l3DMaxiCtrl.value && !this.l3DMaxiCtrl.valid)) {
      return this.translateService.instant("wagon.technical.loads.load_d_max");
    }
    if (this.exceptionalLimitCtrl.value && !this.exceptionalLimitCtrl.valid) {
      return this.translateService.instant("wagon.technical.loads.exceptional_limit");
    }
    return "";
  }

  validateRequiredRegimes(): string {
    const apt = [];
    for (const iterator of this.derogationSystemList.requiredRegimes) {
      const elem = apt.find(e => iterator.aptregAptCodePfk === e.apt);
      if (!elem) {
        apt.push({apt: iterator.aptregAptCodePfk, list: [iterator.aptregRachCodePfk]});
      } else {
        elem.list.push(iterator.aptregRachCodePfk);
      }
    }
    for (const iterator of apt) {
      let present = false;
      for (const ctrl of this.derogationsCtrl.controls) {
        if (ctrl.value.regime && iterator.list.includes(ctrl.value.regime.code)) {
          present = true;
        }
      }
      if (!present) {
        return iterator.apt;
      }
    }
    return "";
  }

  async validateMaxSpeed(): Promise<void> {
    if (this.maxSpeedCtrl.value) {
        const res = await this.monitoringWorkService.controlSpeedTareLoads(this.maxSpeedCtrl.value, "VM").pipe(first()).toPromise();
          //.subscribe(res => {
            if (res && res.data) {
              await this.validatePassengersTrainMaxSpeed();
            } else {
              this.validationErrorMessage = this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_30");
            }
          //})
    } else {
      await this.validatePassengersTrainMaxSpeed();
    }
  }

  async validatePassengersTrainMaxSpeed(): Promise<void> {
    if (this.passengerMaxSpeedCtrl.value) {
        const res = await this.monitoringWorkService.controlSpeedTareLoads(this.passengerMaxSpeedCtrl.value, "VL").pipe(first()).toPromise();
          //.subscribe(res => {
            if (res && res.data) {
              await this.doSubmit();
            } else {
              this.validationErrorMessage = this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_31b");
            }
          //})
    } else {
      await this.doSubmit();
    }
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.loadingSubject.complete();
  }

  addFormGroup(isNew = true, specialAgreement?: SpecialAgreement, transitRegime?: TransitRegime,
               lineCategory?: LineCategory, maximumLoad?: number): FormGroup {
    if (isNew) {
      return this.formBuilder.group({
        isNew: this.formBuilder.control(isNew, Validators.required),
        specialAgreement: this.formBuilder.control(specialAgreement, [this.validateInList(this.specialAgreementList)]),
        regime: this.formBuilder.control(transitRegime,[this.validateInList(this.chargesRegimeList)]),
        line: this.formBuilder.control(lineCategory, [this.validateInList(this.chargesLineCategoryList)]),
        maximumLoad: this.formBuilder.control(maximumLoad, [Validators.pattern('[0-9]{1,3}([.][0-9])?')]),
      });
    } else {
      return this.formBuilder.group({
        isNew: this.formBuilder.control(isNew, Validators.required),
        specialAgreement: this.formBuilder.control(specialAgreement, [Validators.required, this.validateInList(this.specialAgreementList)]),
        regime: this.formBuilder.control(transitRegime,[ Validators.required, this.validateInList(this.chargesRegimeList)]),
        line: this.formBuilder.control(lineCategory, [Validators.required, this.validateInList(this.chargesLineCategoryList)]),
        maximumLoad: this.formBuilder.control(maximumLoad, [Validators.required, Validators.pattern('[0-9]{1,3}([.][0-9])?')]),
      });
    }
  }

  addRow(array: FormArray, index: number): void {
    // RG_MAJ_003_5_2
    const lastInsert =  array.controls[array.controls.length - 1];
    this.duplicatedRow = false;
    for (let index=0; index < array.controls.length - 1; index++) {
      const spagree = array.controls[index].value.specialAgreement;
      const reg = array.controls[index].value.regime;
      const line = array.controls[index].value.line;
      if (spagree.code === lastInsert.value.specialAgreement.code
        && reg.code === lastInsert.value.regime.code
        && line.code === lastInsert.value.line.code) {
        this.errorMessage = this.translateService.instant("wagon.technical.loads.RG_MAJ_003_5_2");
        this.duplicatedRow = true;
        return;
      }
    }
    if (!this.duplicatedRow) {
      lastInsert.get('specialAgreement').setValidators([Validators.required, this.validateInList(this.specialAgreementList)]);
      lastInsert.get('regime').setValidators([Validators.required, this.validateInList(this.chargesRegimeList)]);
      lastInsert.get('line').setValidators([Validators.required, this.validateInList(this.chargesLineCategoryList)]);
      lastInsert.get('maximumLoad').setValidators([Validators.required, Validators.pattern('[0-9]{1,3}([.][0-9])?')]);
      this.errorMessage = "";
      array.push(this.addFormGroup());
      this.tables.forEach(table => table.renderRows());
    }
  }

  removeRow(array: FormArray, index: number): void {
    array.removeAt(index);
    array.markAsDirty();
    this.modifiedDataEvent.emit(true);
    this.tables.forEach(table => table.renderRows());
  }

  previewMode(): void {
    this.isPreviewMode = true;
    this.tareCtrl.disable();
    this.normalPresenceCtrl.disable();
    this.fillPreviewModeDS();
  }

  editMode(): void {
    this.isPreviewMode = false;
    this.tareCtrl.enable();
    this.normalPresenceCtrl.enable();
  }

  /**
   * Contrôle préalable
   * RG_MAJ_003_5_1
   */
  initialControl(): void {
    this.showTare = this.tareLoads.wessNumber !== 0 && !!this.tareLoads.wagonTechnicalData.overallLength;
  }

  /**
   * Génération du rapport " Tableau des Charges Normales & Dérogatoires " au format PDF
   */
  printTareLoads(): void {
    this.subscriptionRefs.push(
      this.monitoringWorkService.downloadTareLoadsReport(this.wagon, this.wagonTechnicalData.mex)
        .subscribe(
          (report) => {
            saveAs(report.blob, report.filename);
        })
    );
  }

  containsCodeLine(code: string): boolean {
    return this.linesCtrl.value && this.linesCtrl.value.indexOf(code) >= 0;
  }

  formatCharge(charge: string): string {
    const ret = new Number(charge).valueOf()/10;
    return ret.toFixed(1);
  }

  normalPresenceChange(event: MatCheckboxChange): void {
    if (event.checked) {
      if (!this.technicalDataDetailWO.envelope) {
        this.resetTableColumns();
        this.resetTableRows();
        this.resetCharges();

        this.currentLineCategory = this.lineCategoryList[0];
        this.currentTransitRegime = this.regimeList[0];
      }

      this.resetLinesList();
      this.resetTableColumns();
      this.setDefaultValuesCharges();
    } else {
      // RG_MAJ_003_5_3
      if (this.technicalDataDetailWO.envelope
        && this.tareLoads.wagonTechnicalDataDetail.tranche.technicalData.trpRegimeS === "1") {
        // Revert action without additional actions
        this.normalPresenceCtrl.setValue(!this.normalPresenceCtrl.value);
        this.showAlertCheckboxChange('wagon.technical.loads.RG_MAJ_003_5_3');
        return;
      }
      this.showConfirmCheckboxChange();
    }
  }

  async showConfirmCheckboxChange(): Promise<void> {
    const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
      data: {
        title: 'wagon.technical.loads.msg_confirm_title',
        message: 'wagon.technical.loads.msg_confirm_load_table',
        cancelLabel: 'actions.quit',
        confirmLabel: 'actions.valid'
      }
    });
    const result = await dialogRef.afterClosed().pipe(first()).toPromise();
    if (!result) {
      // Revert action without additional actions
      this.normalPresenceCtrl.setValue(!this.normalPresenceCtrl.value);
    }
  }

  showAlertCheckboxChange(messageCode: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;    
    const title = this.translateService.instant('wagon.technical.loads.normal_presence');
    const message = this.translateService.instant(messageCode);
    dialogConfig.data = {
      namePopUp: 'alert_msg',
      titlePopUp: title,
      msgPopUp: message
    };    
    this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
  }

  async showWarningMessages(wagonValidation: WagonTechnicalDataDetail, roundedTare: number): Promise<void> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    
    const title = this.translateService.instant('wagon-update.monitoring-work.tabs.tare_loads.title');
    const message = this.translateService.instant(this.validationWarningMessage);

    dialogConfig.data = {
      namePopUp: 'alert_msg',
      titlePopUp: title,
      msgHtmlPopUp: message
    };
    
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    await dialogRef.afterClosed().pipe(first()).toPromise();
    this.validationErrorMessage = await this.controlsPostWarning(wagonValidation, roundedTare);
  }

  linesChange(option: MatSelectChange): void {
    if (option) {
      this.showConfirmListChange(option, 'wagon.technical.loads.msg_confirm_change_line', 'line');
    }
  }

  regimesChange(option: MatSelectChange): void {
    if (option) {
      this.showConfirmListChange(option, 'wagon.technical.loads.msg_confirm_change_regime', 'regime');
    }
  }

  showConfirmListChange(option: MatSelectChange, message: string, type: string): void {
    const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
      data: {
        title: 'wagon.technical.loads.msg_confirm_title',
        message: message,
        cancelLabel: 'actions.quit',
        confirmLabel: 'actions.valid'
      }
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (type === 'line') {
          this.resetTableColumns();
          const selLastSelectedLine = this.lineCategoryList.filter(w => w.code === this.linesCtrl.value);
          this.lastSelectedLine = (selLastSelectedLine.length > 0)?selLastSelectedLine[0]:null;
        } else if (type === 'regime') {
          this.resetTableRows();
          const selLastSelectedRegime = this.regimeList.filter(w => w.code === this.regimesCtrl.value);
          this.lastSelectedRegime = (selLastSelectedRegime.length > 0)?selLastSelectedRegime[0]:null;
        }
        this.resetCharges();
      } else {
          if (type === 'line') {
            this.linesCtrl.setValue(this.lastSelectedLine?this.lastSelectedLine.code:null);
          } else if (type === 'regime') {
            this.regimesCtrl.setValue(this.lastSelectedRegime?this.lastSelectedRegime.code:null);
          }
      }
    });
  }

  resetLinesList(): void {
    this.linesCtrl.setValue(this.currentLineCategory?this.currentLineCategory.code:null);
    this.lastSelectedLine = this.lineCategoryList.filter(w => w.code === this.linesCtrl.value)[0];
  }

  resetRegimesList(): void {
    this.regimesCtrl.setValue(this.currentTransitRegime?this.currentTransitRegime.code:null);
    this.lastSelectedRegime = this.regimeList.filter(w => w.code === this.regimesCtrl.value)[0];
  }

  resetTableColumns(): void {
    if (!this.linesCtrl.value) {
      this.linesCtrl.setValue(this.lineCategoryList[0]?this.lineCategoryList[0].code:null);
    }
    const selectedCode = this.linesCtrl.value;
    const categories = selectedCode.split(this.listSeparator);

    this.numDisplayedColumns = 0;
    this.chargesDisplayedColumns = ['regimes'];
    this.chargesDisplayedColumnsNames.clear();

    for (let index=1; index <= categories.length; index++) {
      this.chargesDisplayedColumns.push('l'+index);
      this.chargesDisplayedColumnsNames.set('l'+index, categories[index - 1]);
      this.numDisplayedColumns++;
    }
    for (let index=categories.length+1; index < 10; index++) {
      this.chargesDisplayedColumns.push('l'+index);
      this.chargesDisplayedColumnsNames.set('l'+index, "");
    }
  }

  resetTableRows(): void {
    if (!this.regimesCtrl.value) {
      this.regimesCtrl.setValue(this.regimeList[0]?this.regimeList[0].code:null);
    }
    const selectedCode = this.regimesCtrl.value;
    
    const rows = selectedCode.split(this.listSeparator);

    this.chargesCtrl.clear();
    this.chargesCtrl.push(this.formBuilder.group({
      regime: this.formBuilder.control(rows[0]),
      l1: this.l1Ctrl,
      l2: this.l2Ctrl,
      l3: this.l3Ctrl,
      l4: this.l4Ctrl,
      l5: this.l5Ctrl,
      l6: this.l6Ctrl,
      l7: this.l7Ctrl,
      l8: this.l8Ctrl,
      l9: this.l9Ctrl
    }));

    this.showLine2 = false;
    this.regimeLine2 = null;
    this.showLine3 = false;
    this.regimeLine3 = null;

    if (rows.length > 1) {
      this.showLine2 = true;
      this.regimeLine2 = rows[1];
    }

    if (rows.length > 2) {
      this.showLine3 = true;
      this.regimeLine3 = rows[2];
    }

    this.chargesDS = new MatTableDataSource<FormGroup>(this.chargesCtrl.controls as FormGroup[]);
  }

  resetCharges(): void {
   
    this.l1Ctrl.setValue(null);
    this.l2Ctrl.setValue(null);
    this.l3Ctrl.setValue(null);
    this.l4Ctrl.setValue(null);
    this.l5Ctrl.setValue(null);
    this.l6Ctrl.setValue(null);
    this.l7Ctrl.setValue(null);
    this.l8Ctrl.setValue(null);
    this.l9Ctrl.setValue(null);

    let index = 0;
    if (index < this.numDisplayedColumns) {
      this.l1Ctrl.enable();
    } else {
      this.l1Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l2Ctrl.enable();
    } else {
      this.l2Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l3Ctrl.enable();
    } else {
      this.l3Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l4Ctrl.enable();
    } else {
      this.l4Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l5Ctrl.enable();
    } else {
      this.l5Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l6Ctrl.enable();
    } else {
      this.l6Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l7Ctrl.enable();
    } else {
      this.l7Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l8Ctrl.enable();
    } else {
      this.l8Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l9Ctrl.enable();
    } else {
      this.l9Ctrl.disable();
    }

    this.resetChargeMaxi();
  }

  resetChargeMaxi(): void {
    const selectedRegimeCode = this.regimesCtrl.value;
    const rows = selectedRegimeCode.split(this.listSeparator);
    this.l2CMaxiCtrl.disable();
    this.l2DMaxiCtrl.disable();
    this.l3CMaxiCtrl.disable();
    this.l3DMaxiCtrl.disable();
    if (rows.length > 1) {
      if (this.containsCodeLine("C")) {
        this.showLine2C = true;
        this.l2CMaxiCtrl.setValue(null);
        this.l2CMaxiCtrl.enable();
        
      } else {
        this.showLine2C = false;
        this.l2CMaxiCtrl.disable();
        this.l2CMaxiCtrl.setValue(null);
      }
      if (this.containsCodeLine("D")) {
        this.showLine2D = true;
        this.l2DMaxiCtrl.setValue(null);
        this.l2DMaxiCtrl.enable();
      } else {
        this.showLine2D = false;
        this.l2DMaxiCtrl.disable();
        this.l2DMaxiCtrl.setValue(null);
      }
    }

    if (rows.length > 2) {
      if (this.containsCodeLine("C")) {
        this.showLine3C = true;
        this.l3CMaxiCtrl.setValue(null);
        this.l3CMaxiCtrl.enable();
      } else {
        this.showLine3C = false;
        this.l3CMaxiCtrl.disable();
        this.l3CMaxiCtrl.setValue(null);
      }
      if (this.containsCodeLine("D")) {
        this.showLine3D = true;
        this.l3DMaxiCtrl.setValue(null);
        this.l3DMaxiCtrl.enable();
      } else {
        this.showLine3D = false;
        this.l3DMaxiCtrl.disable();
        this.l3DMaxiCtrl.setValue(null);
      }
    }
  }

  setDefaultValuesCharges(): void {
    
    this.linesCtrl.setValue(this.currentLineCategory?this.currentLineCategory.code:null);
    const selectedLine = this.linesCtrl.value;
    const columns = selectedLine.split(this.listSeparator);
    
    let index = 0;
    if (index < this.numDisplayedColumns) {
      this.l1Ctrl.setValue(this.formatCharge(this.getLineColumnValue(columns[index])));
    } else {
      this.l1Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l2Ctrl.setValue(this.formatCharge(this.getLineColumnValue(columns[index])));
    } else {
      this.l2Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l3Ctrl.setValue(this.formatCharge(this.getLineColumnValue(columns[index])));
    } else {
      this.l3Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l4Ctrl.setValue(this.formatCharge(this.getLineColumnValue(columns[index])));
    } else {
      this.l4Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l5Ctrl.setValue(this.formatCharge(this.getLineColumnValue(columns[index])));
    } else {
      this.l5Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l6Ctrl.setValue(this.formatCharge(this.getLineColumnValue(columns[index])));
    } else {
      this.l6Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l7Ctrl.setValue(this.formatCharge(this.getLineColumnValue(columns[index])));
    } else {
      this.l7Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l8Ctrl.setValue(this.formatCharge(this.getLineColumnValue(columns[index])));
    } else {
      this.l8Ctrl.disable();
    }
    index++;
    if (index < this.numDisplayedColumns) {
      this.l9Ctrl.setValue(this.formatCharge(this.getLineColumnValue(columns[index])));
    } else {
      this.l9Ctrl.disable();
    }

    const selectedCode = this.regimesCtrl.value;
    const rows = selectedCode.split(this.listSeparator);
   
    this.l2CMaxiCtrl.disable();
    this.l2DMaxiCtrl.disable();
    this.l3CMaxiCtrl.disable();
    this.l3DMaxiCtrl.disable();
    if (rows.length > 1) {
      if (this.containsCodeLine("C")) {
        this.showLine2C = true;
        this.l2CMaxiCtrl.enable();
        this.l2CMaxiCtrl.setValue(this.formatCharge(this.tareLoads.wagonTechnicalData.lineValue2C));
      } else {
        this.showLine2C = false;
        this.l2CMaxiCtrl.disable();
        this.l2CMaxiCtrl.setValue(null);
      }
      if (this.containsCodeLine("D")) {
        this.showLine2D = true;
        this.l2DMaxiCtrl.enable();
        this.l2DMaxiCtrl.setValue(this.formatCharge(this.tareLoads.wagonTechnicalData.lineValue2D));
      } else {
        this.showLine2D = false;
        this.l2DMaxiCtrl.disable();
        this.l2DMaxiCtrl.setValue(null);
      }
    } 
    if (rows.length > 2) {
      if (this.containsCodeLine("C")) {
        this.showLine3C = true;
        this.l3CMaxiCtrl.enable();
        this.l3CMaxiCtrl.setValue(this.formatCharge(this.tareLoads.wagonTechnicalData.lineValue3C));
      } else {
        this.showLine3C = false;
          this.l3CMaxiCtrl.disable();
          this.l3CMaxiCtrl.setValue(null);
      }
      if (this.containsCodeLine("D")) {
        this.showLine3D = true;
        this.l3DMaxiCtrl.enable();
        this.l3DMaxiCtrl.setValue(this.formatCharge(this.tareLoads.wagonTechnicalData.lineValue3D));
      } else {
        this.showLine3D = false;
        this.l3DMaxiCtrl.disable();
        this.l3DMaxiCtrl.setValue(null);
      }
    }
  }

  /**
   * Calculer la valeur a montrer en chaque colonne
   * @param columnLabel
   * @returns 
   */
  private getLineColumnValue(columnLabel: string): string {
    if (columnLabel.indexOf("A") >= 0) {
      return this.tareLoads.wagonTechnicalData.lineValue1A;
    }
    if ((columnLabel.indexOf("B1") >= 0) || (columnLabel.indexOf("B-") >= 0) 
      || ((columnLabel.indexOf("B") >= 0) && columnLabel.length === 1)) {
      return this.tareLoads.wagonTechnicalData.lineValue1B1;
    }
    if (columnLabel.indexOf("B2") >= 0) {
      return this.tareLoads.wagonTechnicalData.lineValue1B2;
    }
    if ((columnLabel.indexOf("C2") >= 0)
      || ((columnLabel.indexOf("C") >= 0) && columnLabel.length === 1)) {
      return this.tareLoads.wagonTechnicalData.lineValue1C2;
    }
    if (columnLabel.indexOf("C3") >= 0) {
      return this.tareLoads.wagonTechnicalData.lineValue1C3;
    }
    if (columnLabel.indexOf("C4") >= 0) {
      return this.tareLoads.wagonTechnicalData.lineValue1C4;
    }
    if ((columnLabel.indexOf("D2") >= 0)
      || ((columnLabel.indexOf("D") >= 0) && columnLabel.length === 1)) {
      return this.tareLoads.wagonTechnicalData.lineValue1D2;
    }
    if (columnLabel.indexOf("D3") >= 0) {
      return this.tareLoads.wagonTechnicalData.lineValue1D3;
    }
    if (columnLabel.indexOf("D4") >= 0) {
      return this.tareLoads.wagonTechnicalData.lineValue1D4;
    }
  }

   private setLineColumnValue(wagonTech: WagonTechnicalDataDetail, columnLabel: string, valueToSet: string) {
      if (columnLabel.indexOf("A") >= 0) {
        wagonTech.lineValue1A = Number(valueToSet);
      }
      if ((columnLabel.indexOf("B1") >= 0) || (columnLabel.indexOf("B-") >= 0) 
        || ((columnLabel.indexOf("B") >= 0) && columnLabel.length === 1)) {
        wagonTech.lineValue1B1 = Number(valueToSet);
      }
      if (columnLabel.indexOf("B2") >= 0) {
        wagonTech.lineValue1B2 = Number(valueToSet);
      }
      if ((columnLabel.indexOf("C2") >= 0)
        || ((columnLabel.indexOf("C") >= 0) && columnLabel.length === 1)) {
        wagonTech.lineValue1C2 = Number(valueToSet);
      }
      if (columnLabel.indexOf("C3") >= 0) {
        wagonTech.lineValue1C3 = Number(valueToSet);
      }
      if (columnLabel.indexOf("C4") >= 0) {
        wagonTech.lineValue1C4 = Number(valueToSet);
      }
      if ((columnLabel.indexOf("D2") >= 0)
        || ((columnLabel.indexOf("D") >= 0) && columnLabel.length === 1)) {
        wagonTech.lineValue1D2 = Number(valueToSet);
      }
      if (columnLabel.indexOf("D3") >= 0) {
        wagonTech.lineValue1D3 = Number(valueToSet);
      }
      if (columnLabel.indexOf("D4") >= 0) {
        wagonTech.lineValue1D4 = Number(valueToSet);
      }
    }

  getValueFromTable(columnName: string): number {
    let index = 1;
    let value = null;
    while (index <= this.numDisplayedColumns) {
      const columnHeader = this.chargesDisplayedColumnsNames.get('l' + index);
      if (columnHeader.indexOf(columnName) >= 0) {
        if (index === 1) value = this.l1Ctrl.value;
        if (index === 2) value = this.l2Ctrl.value;
        if (index === 3) value = this.l3Ctrl.value;
        if (index === 4) value = this.l4Ctrl.value;
        if (index === 5) value = this.l5Ctrl.value;
        if (index === 6) value = this.l6Ctrl.value;
        if (index === 7) value = this.l7Ctrl.value;
        if (index === 8) value = this.l8Ctrl.value;
        if (index === 9) value = this.l9Ctrl.value;
      }
      index++;
    }
    if (value) {
      return Number(value);
    }
    return value;
  }

 /**
  * Popup message. Closed after 5 seconds.
  */
  private updateDone() {
    this.ngOnInit();
    if (this.alert) {
      const dialogConfig = new MatDialogConfig();      
      dialogConfig.disableClose = true;
      const message = this.translateService.instant('wagon.technical.update-done.msg');      
      const timeout = UtilityService.UPDATE_DONE_TIMEOUT;      
      dialogConfig.data = {
        namePopUp: 'update-done-alert',
        titlePopUp: message,
        msgPopUp: message
      };      
      const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
      dialogRef.afterOpened().subscribe(_ => {
          setTimeout(() => {
          dialogRef.close();
          this.dialog.closeAll();
          }, timeout)
      });
    }
  }

  preparePreviewModeDS(): void {
    this.chargesPreviewCtrl = this.formBuilder.array([]);
    this.chargesPreviewDS = new MatTableDataSource<FormGroup>(this.chargesPreviewCtrl.controls as FormGroup[]);
  }

  fillPreviewModeDS(): void {
    const selectedCode = this.regimesCtrl.value;
    const rows = selectedCode.split(this.listSeparator);

    this.chargesPreviewCtrl.clear();
    const valuesArray = [];
    valuesArray[0] = [];
    valuesArray[0][0] = (this.l1Ctrl.value ? this.l1Ctrl.value + " t" : null);
    valuesArray[0][1] = (this.l2Ctrl.value ? this.l2Ctrl.value + " t" : null);
    valuesArray[0][2] = (this.l3Ctrl.value ? this.l3Ctrl.value + " t" : null);
    valuesArray[0][3] = (this.l4Ctrl.value ? this.l4Ctrl.value + " t" : null);
    valuesArray[0][4] = (this.l5Ctrl.value ? this.l5Ctrl.value + " t" : null);
    valuesArray[0][5] = (this.l6Ctrl.value ? this.l6Ctrl.value + " t" : null);
    valuesArray[0][6] = (this.l7Ctrl.value ? this.l7Ctrl.value + " t" : null);
    valuesArray[0][7] = (this.l8Ctrl.value ? this.l8Ctrl.value + " t" : null);
    valuesArray[0][8] = (this.l9Ctrl.value ? this.l9Ctrl.value + " t" : null);

    this.chargesPreviewCtrl.push(this.formBuilder.group({
      regime: this.formBuilder.control(rows[0]),
      l1: valuesArray[0][0],
      l2: valuesArray[0][1],
      l3: valuesArray[0][2],
      l4: valuesArray[0][3],
      l5: valuesArray[0][4],
      l6: valuesArray[0][5],
      l7: valuesArray[0][6],
      l8: valuesArray[0][7],
      l9: valuesArray[0][8]
    }));

    if (rows.length > 1) {
      valuesArray[1] = [];
      valuesArray[1][0] = this.getValueMaxiPreviewMode(this.l1Ctrl.value, this.l2CMaxiCtrl.value, this.l2DMaxiCtrl.value);
      valuesArray[1][1] = this.getValueMaxiPreviewMode(this.l2Ctrl.value, this.l2CMaxiCtrl.value, this.l2DMaxiCtrl.value);
      valuesArray[1][2] = this.getValueMaxiPreviewMode(this.l3Ctrl.value, this.l2CMaxiCtrl.value, this.l2DMaxiCtrl.value);
      valuesArray[1][3] = this.getValueMaxiPreviewMode(this.l4Ctrl.value, this.l2CMaxiCtrl.value, this.l2DMaxiCtrl.value);
      valuesArray[1][4] = this.getValueMaxiPreviewMode(this.l5Ctrl.value, this.l2CMaxiCtrl.value, this.l2DMaxiCtrl.value);
      valuesArray[1][5] = this.getValueMaxiPreviewMode(this.l6Ctrl.value, this.l2CMaxiCtrl.value, this.l2DMaxiCtrl.value);
      valuesArray[1][6] = this.getValueMaxiPreviewMode(this.l7Ctrl.value, this.l2CMaxiCtrl.value, this.l2DMaxiCtrl.value);
      valuesArray[1][7] = this.getValueMaxiPreviewMode(this.l8Ctrl.value, this.l2CMaxiCtrl.value, this.l2DMaxiCtrl.value);
      valuesArray[1][8] = this.getValueMaxiPreviewMode(this.l9Ctrl.value, this.l2CMaxiCtrl.value, this.l2DMaxiCtrl.value);
      this.chargesPreviewCtrl.push(this.formBuilder.group({
        regime: this.formBuilder.control(rows[1]),
        l1: valuesArray[1][0],
        l2: valuesArray[1][1],
        l3: valuesArray[1][2],
        l4: valuesArray[1][3],
        l5: valuesArray[1][4],
        l6: valuesArray[1][5],
        l7: valuesArray[1][6],
        l8: valuesArray[1][7],
        l9: valuesArray[1][8]
      }));
    }

    if (rows.length > 2) {
      valuesArray[2] = [];
      valuesArray[2][0] = this.getValueMaxiPreviewMode(this.l1Ctrl.value, this.l3CMaxiCtrl.value, this.l3DMaxiCtrl.value);
      valuesArray[2][1] = this.getValueMaxiPreviewMode(this.l2Ctrl.value, this.l3CMaxiCtrl.value, this.l3DMaxiCtrl.value);
      valuesArray[2][2] = this.getValueMaxiPreviewMode(this.l3Ctrl.value, this.l3CMaxiCtrl.value, this.l3DMaxiCtrl.value);
      valuesArray[2][3] = this.getValueMaxiPreviewMode(this.l4Ctrl.value, this.l3CMaxiCtrl.value, this.l3DMaxiCtrl.value);
      valuesArray[2][4] = this.getValueMaxiPreviewMode(this.l5Ctrl.value, this.l3CMaxiCtrl.value, this.l3DMaxiCtrl.value);
      valuesArray[2][5] = this.getValueMaxiPreviewMode(this.l6Ctrl.value, this.l3CMaxiCtrl.value, this.l3DMaxiCtrl.value);
      valuesArray[2][6] = this.getValueMaxiPreviewMode(this.l7Ctrl.value, this.l3CMaxiCtrl.value, this.l3DMaxiCtrl.value);
      valuesArray[2][7] = this.getValueMaxiPreviewMode(this.l8Ctrl.value, this.l3CMaxiCtrl.value, this.l3DMaxiCtrl.value);
      valuesArray[2][8] = this.getValueMaxiPreviewMode(this.l9Ctrl.value, this.l3CMaxiCtrl.value, this.l3DMaxiCtrl.value);
      this.chargesPreviewCtrl.push(this.formBuilder.group({
        regime: this.formBuilder.control(rows[2]),
        l1: valuesArray[2][0],
        l2: valuesArray[2][1],
        l3: valuesArray[2][2],
        l4: valuesArray[2][3],
        l5: valuesArray[2][4],
        l6: valuesArray[2][5],
        l7: valuesArray[2][6],
        l8: valuesArray[2][7],
        l9: valuesArray[2][8]
      }));
    }

    this.chargesPreviewCtrl.disable();

    this.tables.forEach(table => table.renderRows());
  }

  getValueMaxiPreviewMode(valueHead: number, valueCMaxi: number, valueDMaxi: number): string {
    if (!valueHead) {
      return null;
    }
    if (this.containsCodeLine("C")) {
      if (valueCMaxi.toString() === "") {
        return null;
      } else if (valueCMaxi === 0) {
        return valueCMaxi + " t";
      } else if (valueHead < valueCMaxi) {
        return valueHead + " t";
      } else {
        return valueCMaxi + " t";
      }
    } else if (this.containsCodeLine("D")) {
      if (valueDMaxi.toString() === "") {
        return null;
      } else if (valueDMaxi === 0) {
        return valueDMaxi + " t";
      } else if (valueHead < valueDMaxi) {
        return valueHead + " t";
      } else {
        return valueDMaxi + " t";
      }
    }
  }

  private filterLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.label.toUpperCase() : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => v.label.toUpperCase().includes(filterValue)
      ) : list;
  }
  filterCode(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => v.code.toUpperCase().includes(filterValue)
      ) : list;
  }
  
  displayCode = (obj: any) => {
    return obj ? (obj.code ? obj.code : obj) : '';
  }

  
  validateInList(list: {code: string}[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let val = control.value;
      if (!val) {
        if (val === '') {
          control.setValue(null);
        }
        return null;
      } else if (typeof val === 'object') {
        if (list.filter( elem => elem.code === val.code).length) {
          return null;
        }
      }
      return { validateInList: true };
    };
  }

  canAddRow(element: FormGroup) {
    return element.get('maximumLoad').value && element.get('maximumLoad').value.match('[0-9]{1,3}([.][0-9])?')
        && element.get('line').value && typeof element.get('line').value  === 'object'
        && element.get('regime').value && typeof element.get('regime').value  === 'object'
        && element.get('specialAgreement').value && typeof element.get('specialAgreement').value  === 'object';
  }
}
