/** API model:Table: ELEMENT_OPF_MAJ */
export class BrakeMainOrganElementUpdate {
  /** ELTOPFMAJ_WPHY_ID_PFK	NUMBER(10,0) */
  wagonId: number;
  /** ELTOPFMAJ_CPT_PK	NUMBER(3,0) */
  counter: number;
  /** ELTOPFMAJ_DH_DERN_REV_OPF	DATE */
  lastOverhaul: Date;
  /** ELTOPFMAJ_NUM_OPF	VARCHAR2(10 BYTE) */
  number: string;
  /** ELTOPFMAJ_PLS_CODE_FK	VARCHAR2(9 BYTE) */
  wagonSerialCode: string;
  /** ELTOPFMAJ_TYPE_ARES	VARCHAR2(15 BYTE) */
  aresType: string;
}
