import { Component, OnInit } from '@angular/core';

/** Template of an error 500 page */
@Component({
  selector: 'app-error-500',
  templateUrl: './error-500.component.html',
  styleUrls: ['./error-500.component.scss']
})
export class Error500Component implements OnInit {
  /**
   * `Error500Component` constructor
   */
  constructor() {}
  /** OnInit hook  */
  ngOnInit() {
  }
}
