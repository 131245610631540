import { SelectionModel } from '@angular/cdk/collections';
import { MediaMatcher } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { TemplatePopupDialogComponent } from 'src/app/shared/components/template-popup-dialog/template-popup-dialog.component';
import { AuxiliaryTank } from 'src/app/shared/models/auxiliary-tank';
import { BrakeControl } from 'src/app/shared/models/brake-control';
import { BrakeCylinder } from 'src/app/shared/models/brake-cylinder';
import { BrakeMainOrgan } from 'src/app/shared/models/brake-main-organ';
import { BrakeMainOrganElement } from 'src/app/shared/models/brake-main-organ-element';
import { BrakeMainOrganElementUpdate } from 'src/app/shared/models/brake-main-organ-element-update';
import { BrakeParticularity } from 'src/app/shared/models/brake-particularity';
import { ConstructiveRule } from 'src/app/shared/models/constructive-rule';
import { EmptyLoadedDevice } from 'src/app/shared/models/empty-loaded-device';
import { Handbrake } from 'src/app/shared/models/handbrake';
import { OutputAuxiliaryTankElement } from 'src/app/shared/models/output-auxiliary-tank-element';
import { PurgeValve } from 'src/app/shared/models/purge-valve';
import { RecomAuxiliaryTank } from 'src/app/shared/models/recom-auxiliary-tank';
import { Regulator } from 'src/app/shared/models/regulator';
import { TankMaker } from 'src/app/shared/models/tank-maker';
import { TechSpecReferences } from 'src/app/shared/models/tech-spec-references';
import { WagonBrake } from 'src/app/shared/models/wagon-brake';
import { WagonBrakeParticularity } from 'src/app/shared/models/wagon-brake-particularity';
import { WagonEmptyLoadedDevice } from 'src/app/shared/models/wagon-empty-loaded-device';
import { WagonRegulator } from 'src/app/shared/models/wagon-regulator';
import { WagonTechnicalDataDetail } from 'src/app/shared/models/wagon-technical-data-detail';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TechSpecBrake } from "../../../../../../models/tech-spec-brake";
import { WagonTechnicalData } from "../../../../../../models/wagon-technical-data";
import { MonitoringWorkService } from "../../../../../../services/monitoring-work/monitoring-work.service";

@Component({
  selector: 'app-open-wagon-brake',
  templateUrl: './open-wagon-brake.component.html',
  styleUrls: ['./open-wagon-brake.component.scss']
})
export class OpenWagonBrakeComponent implements OnInit, OnDestroy {

  @Input() public wagonTechnicalData: WagonTechnicalData;
  @Input() public techSpecReferences: TechSpecReferences;
  @Output() modifiedDataEvent = new EventEmitter();
  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  breakForm: FormGroup;

  commonColumns: string[] = ['type', 'number', 'nature', 'action'];
  brakeMainOrganDS: MatTableDataSource<FormGroup>;
  regulatorDS: MatTableDataSource<FormGroup>;
  brakeCylinderDS: MatTableDataSource<FormGroup>;
  brakeParticularitieDS: MatTableDataSource<FormGroup>;
  emptyLoadedDeviceDS: MatTableDataSource<FormGroup>;

  brakeMainOrganColumns: string[] = ['type', 'numero', 'review', 'action'];
  brakeMainOrganElementDS: MatTableDataSource<FormGroup>;
  techSpecBrake: TechSpecBrake;
  techSpecBrakeToSend: TechSpecBrake;
  /** Error list*/
  errorAddList: string[] = [];
  errorSubmitList: string[] = [];
  flagActivateAddList  = false;
  flagActivateSubmitList  = false;
  /** message error */
  messageAddError = '';
  msgSubmitError = '';

  controlNumber1 = false;
  controlNumber2 = false;
  controlNumber3 = false;
  controlNumber4 = false;
  control_4 = false;
  control_5 = false;
  control_3 = false;
  control_2 = false;
  numberAxles = 0;
  control_coh_16 = 0;
  control_coh_17 = false;
  msg_error_displayed: string = null;
  controlRegPassed = true;
  controlCylPassed = true;
  controlPartPassed = true;
  controlDevPassed = true;
  modifSub: Subscription;
  alert: boolean;
  constructiveRuleList: ConstructiveRule[];
  recomAuxTankList: RecomAuxiliaryTank[];
  tankMakerList: TankMaker[];
  filteredConstructiveRuleList: ConstructiveRule[];
  filteredRecomAuxTankList: RecomAuxiliaryTank[];
  filteredTankMakerList: TankMaker[];
  filteredBrakeMainOrganList: BrakeMainOrgan[];
  filteredRegulatorList: Regulator[];
  filteredBrakeCylinderList: BrakeCylinder[];
  filteredBrakeParticularitieList: BrakeParticularity[];
  filteredEmptyLoadedDeviceList: EmptyLoadedDevice[];
  filteredAuxiliaryTankList: AuxiliaryTank[]
  filteredPurgeValveList: PurgeValve[];
  filteredBrakeControlList: BrakeControl[];
  filteredHandbrakeList: Handbrake[];
  uiQuery: MediaQueryList;
  positionList: string[] = [];
  detailSelection = new SelectionModel(false, [], true);
  numberWagon = -1;
  today: Date;
  constructor(private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private monitoringWorkService: MonitoringWorkService,
              private referenceService: ReferenceService,
              private media: MediaMatcher,
              private dialog: MatDialog) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
    this.uiQuery = this.media.matchMedia('(max-width: 1279px)');
    for (let index = 1; index < 31; index++) {
      this.positionList.push( (index + '').padStart(2, '0'))
    }
    this.today = new Date();
  }

  get auxiliaryTankElementList(): FormArray {
    return this.breakForm.get('auxiliaryTankElementList') as FormArray;
  }
  
  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.breakForm = this.formBuilder.group({
      brakeMainOrgans: this.formBuilder.array([]),
      regulators: this.formBuilder.array([]),
      brakeCylinders: this.formBuilder.array([]),
      brakeParticularities: this.formBuilder.array([]),
      emptyLoadedDevices: this.formBuilder.array([]),
      auxiliaryTank: this.formBuilder.control(null),/*, Validators.required*/
      purgeValve: this.formBuilder.control(null, [Validators.required, this.validateCodeInList(this.techSpecReferences.purgeValves)]),
      brakeControl: this.formBuilder.control(null, [this.validateCodeInList(this.techSpecReferences.brakeControls)]),
      handBrake: this.formBuilder.control(null, [Validators.required, this.validateCodeInList(this.techSpecReferences.handbrakes)]),
      brakeMainOrganElements: this.formBuilder.array([]),
      emptyBrakedWeightG: this.formBuilder.control(null, [Validators.pattern(/^\d{1,3}$/)]),
      loadedBrakedWeightG: this.formBuilder.control(null, [Validators.pattern(/^\d{1,3}$/)]),
      halfBrakedWeightG: this.formBuilder.control(null, [Validators.pattern(/^\d{1,3}$/)]),
      emptyBrakedWeightP: this.formBuilder.control(null, [Validators.pattern(/^\d{1,3}$/)]),
      loadedBrakedWeightP: this.formBuilder.control(null, [Validators.pattern(/^\d{1,3}$/)]),
      halfBrakedWeightP: this.formBuilder.control(null, [Validators.pattern(/^\d{1,3}$/)]),
      firstTransitionWeight: this.formBuilder.control(null, [Validators.pattern(/^\d{1,3}$/)]),
      secondTransitionWeight: this.formBuilder.control(null, [Validators.pattern(/^\d{1,3}$/)]),
      handbrakeBrakedWeight: this.formBuilder.control(null, [Validators.pattern(/^\d{1,3}$/)]),
      auxiliaryTankElementList: this.formBuilder.array([])
    });
    
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.modifSub = this.breakForm.valueChanges.subscribe(
      _ => this.modifiedDataEvent.emit(this.breakForm.dirty));

    this.loadInitDatas();
  }

  /**
   * Reset les donnes
   */
  resetDatas(): void {
    this.brakeMainOrgans.clear();
    this.regulators.clear();
    this.brakeCylinders.clear();
    this.brakeParticularities.clear();
    this.emptyLoadedDevices.clear();
    this.brakeMainOrganElements.clear();

    this.errorAddList = [];
    this.errorSubmitList = [];
    this.flagActivateAddList = false;
    this.flagActivateSubmitList = false;
    
    const mainOrganList = this.techSpecBrake.wagonTechnicalDataDetail.brake.mainOrganList;
    mainOrganList.forEach(elem => this.brakeMainOrgans.push(this.addFormGroup(null, false, elem.amount, elem.brakeMainOrgan.label)));
    this.brakeMainOrganDS = new MatTableDataSource<FormGroup>(this.brakeMainOrgans.controls as FormGroup[]);

    const regulatorList = this.techSpecBrake.wagonTechnicalDataDetail.brake.regulatorList;
    regulatorList.forEach(elem => this.regulators.push(this.addFormGroup( this.techSpecReferences.regulators, false, elem.amount, elem.regulator.code)));
    this.regulators.push(this.addFormGroup(this.techSpecReferences.regulators));
    this.regulatorDS = new MatTableDataSource<FormGroup>(this.regulators.controls as FormGroup[]);

    const brakeCylinderList = this.techSpecBrake.wagonTechnicalDataDetail.brake.brakeCylinderList;
    brakeCylinderList.forEach(elem => this.brakeCylinders.push(this.addFormGroupWithoutAmount(this.techSpecReferences.brakeCylinders, false, elem.code)));
    this.brakeCylinders.push(this.addFormGroupWithoutAmount(this.techSpecReferences.brakeCylinders));
    this.brakeCylinderDS = new MatTableDataSource<FormGroup>(this.brakeCylinders.controls as FormGroup[]);

    const particularityList = this.techSpecBrake.wagonTechnicalDataDetail.brake.particularityList;
    particularityList.forEach(elem => this.brakeParticularities.push(this.addFormGroup(this.techSpecReferences.brakeParticularities ,false, elem.amount, elem.particularity.code)));
    this.brakeParticularities.push(this.addFormGroup(this.techSpecReferences.brakeParticularities));
    this.brakeParticularitieDS = new MatTableDataSource<FormGroup>(this.brakeParticularities.controls as FormGroup[]);

    const emptyLoadedDeviceList = this.techSpecBrake.wagonTechnicalDataDetail.brake.emptyLoadedDeviceList;
    emptyLoadedDeviceList.forEach(elem => this.emptyLoadedDevices.push(this.addFormGroup(this.techSpecReferences.emptyLoadedDevices, false, elem.amount, elem.emptyLoadedDevice.code)));
    this.emptyLoadedDevices.push(this.addFormGroup(this.techSpecReferences.emptyLoadedDevices));
    this.emptyLoadedDeviceDS = new MatTableDataSource<FormGroup>(this.emptyLoadedDevices.controls as FormGroup[]);

    this.breakForm.get('auxiliaryTank').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.auxiliaryTank?this.techSpecBrake.wagonTechnicalDataDetail.brake.auxiliaryTank.code:null);
    this.breakForm.get('purgeValve').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.purgeValve?this.techSpecBrake.wagonTechnicalDataDetail.brake.purgeValve.code:null);
    this.breakForm.get('brakeControl').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.brakeControl?this.techSpecBrake.wagonTechnicalDataDetail.brake.brakeControl.code:null);
    this.breakForm.get('handBrake').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.handbrake?this.techSpecBrake.wagonTechnicalDataDetail.brake.handbrake.code:null);

    let brakeMainOrganElementList = this.techSpecBrake.wagonTechnicalDataDetail.brake.brakeMainOrganElementList;
    // Si existe brakeMainOrganElementUpdateList, on utilise cettes valeurs a lieu de brakeMainOrganElementList
    if (this.techSpecBrake.wagonTechnicalDataDetail.brake.brakeMainOrganElementUpdateList
        && this.techSpecBrake.wagonTechnicalDataDetail.brake.brakeMainOrganElementUpdateList.length > 0) {
      brakeMainOrganElementList = this.techSpecBrake.wagonTechnicalDataDetail.brake.brakeMainOrganElementUpdateList
                                  .map(elem => {
                                    const newElem = new BrakeMainOrganElement();
                                    newElem.aresType = elem.aresType;
                                    newElem.number = elem.number;
                                    newElem.review = elem.lastOverhaul;
                                    return newElem;
                                  } );
    }
    brakeMainOrganElementList.forEach(elem => this.brakeMainOrganElements.push(this.addBrakeMainOrganElement(false, elem.aresType, elem.number, elem.review)));
    this.brakeMainOrganElements.push(this.addBrakeMainOrganElement());
    this.brakeMainOrganElementDS = new MatTableDataSource<FormGroup>(this.brakeMainOrganElements.controls as FormGroup[]);

    this.breakForm.get('emptyBrakedWeightG').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.emptyBrakedWeightG);
    this.breakForm.get('loadedBrakedWeightG').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.loadedBrakedWeightG);
    this.breakForm.get('halfBrakedWeightG').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.halfBrakedWeightG);
    this.breakForm.get('emptyBrakedWeightP').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.emptyBrakedWeightP);
    this.breakForm.get('loadedBrakedWeightP').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.loadedBrakedWeightP);
    this.breakForm.get('halfBrakedWeightP').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.halfBrakedWeightP);
    this.breakForm.get('firstTransitionWeight').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.firstTransitionWeight);
    this.breakForm.get('secondTransitionWeight').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.secondTransitionWeight);
    this.breakForm.get('handbrakeBrakedWeight').setValue(this.techSpecBrake.wagonTechnicalDataDetail.brake.handbrakeBrakedWeight);
    (this.breakForm.get('auxiliaryTankElementList') as FormArray).clear();
    this.techSpecBrake.auxiliaryTankElementList.sort((a , b) => a.position > b.position ? 1 : -1 ).forEach(
      elem => 
        (this.breakForm.get('auxiliaryTankElementList') as FormArray).push(this.getAuxTankElemFormGroup(elem))
    );
    (this.breakForm.get('auxiliaryTankElementList') as FormArray).push(this.getAuxTankElemFormGroup());
    this.tables.forEach(table => table.renderRows());
  
    this.breakForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }

  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
    
    this.subscriptionRefs.push(
      forkJoin(
        [this.monitoringWorkService.loadBrakePageDatas(this.wagonTechnicalData.wagon.id),
        this.referenceService.getConstructiveRules(),
        this.referenceService.getRecomAuxTanks(),
        this.referenceService.getTankMakers()]
      )
        .subscribe(([update, constRules, recomAuxTanks, tankMakers]) => {
          this.constructiveRuleList = constRules.data;
          this.recomAuxTankList = recomAuxTanks.data.sort( (a, b) => {            
            return parseInt(a.code.replace(/[A-Za-z]*/g,''), 10) < parseInt(b.code.replace(/[A-Za-z]*/g,''), 10) ? -1 : 1});
          this.tankMakerList = tankMakers.data;
          this.filteredConstructiveRuleList = constRules.data;
          this.filteredRecomAuxTankList = recomAuxTanks.data;
          this.filteredTankMakerList = tankMakers.data;
          this.filteredBrakeMainOrganList = this.techSpecReferences.brakeMainOrgans;
          this.filteredRegulatorList = this.techSpecReferences.regulators;
          this.filteredBrakeCylinderList = this.techSpecReferences.brakeCylinders;
          this.filteredBrakeParticularitieList = this.techSpecReferences.brakeParticularities;
          this.filteredEmptyLoadedDeviceList = this.techSpecReferences.emptyLoadedDevices;
          this.filteredAuxiliaryTankList = this.techSpecReferences.auxiliaryTanks;
          this.filteredPurgeValveList = this.techSpecReferences.purgeValves;
          this.filteredBrakeControlList = this.techSpecReferences.brakeControls;
          this.filteredHandbrakeList = this.techSpecReferences.handbrakes;
          this.techSpecBrake = update.data;

          this.resetDatas();
          this.loadingSubject.next(false);
          
        })
    );
  }

  /**
   * Methode to submit the form
   */
  async onSubmit(alert?: boolean): Promise<void> {
    this.numberWagon = -1;
    this.alert = alert === undefined ? true : alert;
    this.msgSubmitError = '';
    this.errorSubmitList = [];
    await this.checkControls(); 
  }

  async doSubmit(): Promise<void> {
    let techSpecBrakeTosend = new TechSpecBrake();
    techSpecBrakeTosend = this.initializeDataToSend();
    
    this.loadingSubject.next(true);
    const techSpecBrake = (await this.monitoringWorkService.updateTechSpecBrake(techSpecBrakeTosend).pipe(first()).toPromise()).data
    this.loadingSubject.next(false);
    this.updateDone();
    
    this.breakForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }


  initializeDataToSend (): TechSpecBrake {
    const techSpecBrakeTosend = new TechSpecBrake();
    const wagontechnicalDet = new WagonTechnicalDataDetail();
    const brake = new WagonBrake();
    techSpecBrakeTosend.wagonId = this.techSpecBrake.wagonUpdate.wagonId;
    techSpecBrakeTosend.wagonUpdate = this.techSpecBrake.wagonUpdate;
    const regulatorList: WagonRegulator [] = [];
    let index = 1;
    this.breakForm.value.regulators.forEach(reg => {
      if (reg.nature && index < this.breakForm.value.regulators.length) {
        const regulatorTosend = new WagonRegulator();
        const regulador = new Regulator();
        regulador.code = reg.nature;
        regulatorTosend.regulator = regulador;
        regulatorTosend.amount = reg.amount;
        regulatorList.push(regulatorTosend);
      }
      index++;
    });
    brake.regulatorList = regulatorList;

    const cylinderList: BrakeCylinder [] = [];
    index = 1;
    this.breakForm.value.brakeCylinders.forEach(cyl => {
      if (cyl.nature && index < this.breakForm.value.brakeCylinders.length) {
        const cylinderToSend = new BrakeCylinder();
        cylinderToSend.code = cyl.nature;
        cylinderList.push(cylinderToSend);
      }
      index++;
    });
    brake.brakeCylinderList = cylinderList;

    const particularityListToSend: WagonBrakeParticularity[] = [];
    index = 1;
    this.breakForm.value.brakeParticularities.forEach(part => {
      if (part.nature && index < this.breakForm.value.brakeParticularities.length) {
        const particularityWagon = new WagonBrakeParticularity();
        particularityWagon.amount = part.amount;
        const particularityTosend = new BrakeParticularity();
        particularityTosend.code = part.nature;
        particularityWagon.particularity = particularityTosend;
        particularityListToSend.push(particularityWagon);
      }
      index++;
    });
    brake.particularityList= particularityListToSend;

    const emptyLoadedDeviceListToSend : WagonEmptyLoadedDevice[] = [];
    index = 1;
    this.breakForm.value.emptyLoadedDevices.forEach(device => {
      if (device.nature && index < this.breakForm.value.emptyLoadedDevices.length) {
        const emptyDeviceToSend = new WagonEmptyLoadedDevice();
        emptyDeviceToSend.amount = device.amount;

        const emptyLoadesDev = new EmptyLoadedDevice();
        emptyLoadesDev.code = device.nature;
        emptyDeviceToSend.emptyLoadedDevice = emptyLoadesDev;
        emptyLoadedDeviceListToSend.push(emptyDeviceToSend);
      }
      index++;
    });
    brake.emptyLoadedDeviceList = emptyLoadedDeviceListToSend;

    const brakeMainOrganElementUpdateListToSend : BrakeMainOrganElementUpdate[] = [];
    index = 1;
    this.breakForm.value.brakeMainOrganElements.forEach(organ => {
      if (organ.numero && organ.review && index < this.breakForm.value.brakeMainOrganElements.length) {
        const brakeMainOrganToSend = new BrakeMainOrganElementUpdate();
        const valList = this.techSpecReferences.brakeMainOrgans.filter(t => t.aresType === organ.type)[0];
        brakeMainOrganToSend.aresType = valList?valList.aresType?valList.aresType:organ.type:'INCONNU';
        brakeMainOrganToSend.number = organ.numero;
        brakeMainOrganToSend.lastOverhaul = new Date(organ.review);
        brakeMainOrganElementUpdateListToSend.push(brakeMainOrganToSend);
      }
      index++;
    });
    brake.brakeMainOrganElementUpdateList = brakeMainOrganElementUpdateListToSend;

    const mainOrganListToSend = this.techSpecBrake.wagonTechnicalDataDetail.brake.mainOrganList;
    index = 0;
    this.breakForm.value.brakeMainOrgans.forEach(organ => {
      mainOrganListToSend[index].amount = organ.amount;
      index++;
    });
    brake.mainOrganList = mainOrganListToSend;

    brake.auxiliaryTank = new AuxiliaryTank();
    if (this.breakForm.value.auxiliaryTank) {
      brake.auxiliaryTank.code = this.breakForm.value.auxiliaryTank;
    }

    brake.purgeValve = new PurgeValve();
    if (this.breakForm.value.purgeValve) {
      brake.purgeValve.code = this.breakForm.value.purgeValve;
    }

    if (this.breakForm.value.brakeControl) {
      brake.brakeControl = new BrakeControl();
      brake.brakeControl.code = this.breakForm.value.brakeControl;
    }

    brake.handbrake = new Handbrake();
    if (this.breakForm.value.handBrake) {
      brake.handbrake.code = this.breakForm.value.handBrake;
    }

    brake.emptyBrakedWeightG = this.breakForm.value.emptyBrakedWeightG;
    brake.loadedBrakedWeightG = this.breakForm.value.loadedBrakedWeightG;
    brake.firstTransitionWeight = this.breakForm.value.firstTransitionWeight;
    brake.halfBrakedWeightG = this.breakForm.value.halfBrakedWeightG;
    brake.secondTransitionWeight = this.breakForm.value.secondTransitionWeight;
    brake.emptyBrakedWeightP = this.breakForm.value.emptyBrakedWeightP;
    brake.loadedBrakedWeightP = this.breakForm.value.loadedBrakedWeightP;
    brake.halfBrakedWeightP = this.breakForm.value.halfBrakedWeightP;
    brake.handbrakeBrakedWeight = this.breakForm.value.handbrakeBrakedWeight;

    wagontechnicalDet.brake = brake;
    techSpecBrakeTosend.wagonTechnicalDataDetail = wagontechnicalDet;
    techSpecBrakeTosend.wagonTechnicalDataDetail.mex = this.techSpecBrake.wagonTechnicalDataDetail.mex;
    techSpecBrakeTosend.isModifiedBrakedMass = this.breakForm.value.isModifiedBrakedMass;
    techSpecBrakeTosend.isModifiedBrakedUnit = this.breakForm.value.isModifiedBrakedUnit;

    techSpecBrakeTosend.auxiliaryTankElementList = this.auxiliaryTankElementList.value.filter( (e, i) => i < this.auxiliaryTankElementList.value.length - 1);
    
    return techSpecBrakeTosend;
  }
  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

  addFormGroup(list: any[], isNew = true, amount?: number, type?: string): FormGroup {
    return this.formBuilder.group({
      isNew: this.formBuilder.control(isNew, Validators.required),
      amount: this.formBuilder.control(amount, Validators.required),
      nature: this.formBuilder.control(type, list ? [Validators.required, this.validateCodeInList(list)] : Validators.required)
    });
  }

  addFormGroupWithoutAmount(list: any[], isNew = true, type?: string): FormGroup {
    return this.formBuilder.group({
      isNew: this.formBuilder.control(isNew, Validators.required),
      nature: this.formBuilder.control(type, [Validators.required, this.validateCodeInList(list)])
    });
  }

  async addRow(array: FormArray, index: number, typ : string): Promise<void> {
    this.flagActivateAddList = true;
    this.errorAddList = [];
    this.messageAddError= '';

    
    this.errorAddList = [];
    this.messageAddError= '';
    this.controlRegPassed = true;
    this.controlCylPassed = true;
    this.controlPartPassed = true;
    this.controlDevPassed = true;
    
    if(typ == 'reg') {
      
      if(this.breakForm.value.regulators.length >= 1){
        if (!this.breakForm.value.regulators[this.breakForm.value.regulators.length-1].nature) {
          this.regulatorDS.data[this.breakForm.value.regulators.length -1].controls.nature.setErrors(Validators.required);
          this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_regulator');
          this.controlDevPassed = false;
          if(this.errorAddList.indexOf(this.messageAddError) === -1) {
            this.errorAddList.push(this.messageAddError);
          } 
        }
        // control 1 RG_MAJ_003_4_3_4
        this.breakForm.value.regulators.slice(0,this.breakForm.value.regulators.length-1).forEach(regulator => {          
          if(regulator.nature == this.breakForm.value.regulators[this.breakForm.value.regulators.length -1].nature){        
            this.regulatorDS.data[this.breakForm.value.regulators.length -1].controls.nature.setErrors(Validators.required);
            this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control1_add_regulator');
            this.controlRegPassed = false;
            if(this.errorAddList.indexOf(this.messageAddError) === -1) {
              this.errorAddList.push(this.messageAddError);
            }  
          }
        });
        // control 2
        if(this.controlRegPassed && +(this.breakForm.value.regulators[this.breakForm.value.regulators.length -1].amount) < 0){
          this.regulatorDS.data[this.breakForm.value.regulators.length -1].controls.amount.setErrors(Validators.required);
          this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.invalid_number');
          this.controlRegPassed = false;
          if(this.errorAddList.indexOf(this.messageAddError) === -1) {
            this.errorAddList.push(this.messageAddError);
          } 
        }
        // nombre de régleurs de la tranche du wagon
        const numberRegulators = parseInt(this.wagonTechnicalData.tranche.technicalData.trpNbreRegleur, 10);
        let sumNumber = 0;
        let index = 1;
        this.breakForm.value.regulators.forEach(regulator =>{
          if (index <= this.breakForm.value.regulators.length) {
            sumNumber += +(regulator.amount);
            if (this.controlRegPassed && sumNumber > numberRegulators) {
              this.regulatorDS.data[this.breakForm.value.regulators.length -1].controls.amount.setErrors(Validators.required);
              this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.incompatible_regulator_number');
              this.controlRegPassed = false;
              if(this.errorAddList.indexOf(this.messageAddError) === -1) {
                this.errorAddList.push(this.messageAddError);
              } 
            }
          }
          index++;
        });                     
      }
      if(this.controlRegPassed){
        array.push(this.addFormGroup(this.techSpecReferences.regulators));
      }
    }
       
    if(typ == 'cyl'){
      
      if(this.breakForm.value.brakeCylinders.length >= 1){
        if (!this.breakForm.value.brakeCylinders[this.breakForm.value.brakeCylinders.length-1].nature) {
          this.brakeCylinderDS.data[this.breakForm.value.brakeCylinders.length -1].controls.nature.setErrors(Validators.required);
          this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_cylinder');
          this.controlCylPassed = false;
          if(this.errorAddList.indexOf(this.messageAddError) === -1) {
            this.errorAddList.push(this.messageAddError);
          } 
        }
        this.breakForm.value.brakeCylinders.slice(0,this.breakForm.value.brakeCylinders.length-1).forEach(cylinder => {
          if(cylinder.nature == this.breakForm.value.brakeCylinders[this.breakForm.value.brakeCylinders.length -1].nature){
            this.brakeCylinderDS.data[this.breakForm.value.brakeCylinders.length -1].controls.nature.setErrors(Validators.required);
            this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control1_add_cylinder');
            this.controlCylPassed = false;
            if(this.errorAddList.indexOf(this.messageAddError) === -1) {
              this.errorAddList.push(this.messageAddError);
            }  
          }
        });
      }
      
      if(this.controlCylPassed) {
        array.push(this.addFormGroupWithoutAmount(this.techSpecReferences.brakeCylinders));
      }
           
    }
    if(typ == 'part'){
      
      if(this.breakForm.value.brakeParticularities.length >= 1){
        if (!this.breakForm.value.brakeParticularities[this.breakForm.value.brakeParticularities.length-1].nature) {
          this.brakeParticularitieDS.data[this.breakForm.value.brakeParticularities.length -1].controls.nature.setErrors(Validators.required);
          this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_discs');
          this.controlPartPassed = false;
          if(this.errorAddList.indexOf(this.messageAddError) === -1) {
            this.errorAddList.push(this.messageAddError);
          } 
        }
        // control 1 
        this.breakForm.value.brakeParticularities.slice(0,this.breakForm.value.brakeParticularities.length-1).forEach(part => {          
          if(part.nature == this.breakForm.value.brakeParticularities[this.breakForm.value.brakeParticularities.length -1].nature){        
            this.brakeParticularitieDS.data[this.breakForm.value.brakeParticularities.length -1].controls.nature.setErrors(Validators.required);
            this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control1_add_part');
            this.controlPartPassed = false;
            if(this.errorAddList.indexOf(this.messageAddError) === -1) {
              this.errorAddList.push(this.messageAddError);
            }  
          }
        });
          // control 2
          if(this.controlPartPassed && +(this.breakForm.value.brakeParticularities[this.breakForm.value.brakeParticularities.length -1].amount) < 0){
            this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.invalid_number');
            this.brakeParticularitieDS.data[this.breakForm.value.brakeParticularities.length -1].controls.amount.setErrors(Validators.required);
            this.controlPartPassed = false;
            if(this.errorAddList.indexOf(this.messageAddError) === -1) {
              this.errorAddList.push(this.messageAddError);
            } 
          }
          // control 3
          let sumNumber = 0;
          const numberAxles = (await this.monitoringWorkService.getNumberOfAxlesOfWagon(this.techSpecBrake.wagonTechnicalDataDetail.mex).pipe(first()).toPromise()).data;
          
          let index = 1;
          this.breakForm.value.brakeParticularities.forEach(part =>{
            if (index < this.breakForm.value.brakeParticularities.length) {
              sumNumber += +(part.amount);
              if (this.controlPartPassed && sumNumber > (numberAxles * 8)) {
                this.brakeParticularitieDS.data[this.breakForm.value.brakeParticularities.length -1].controls.amount.setErrors(Validators.required);
                this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.incompatible_discs_number');
                this.controlPartPassed = false;
                if(this.errorAddList.indexOf(this.messageAddError) === -1) {
                  this.errorAddList.push(this.messageAddError);
                } 
              } 
            }
            index++;
          });
      }
      if(this.controlPartPassed){
        array.push(this.addFormGroup(this.techSpecReferences.brakeParticularities));
      }
    }
    if(typ == 'dev'){
      
      if(this.breakForm.value.emptyLoadedDevices.length >= 1){
        if (!this.breakForm.value.emptyLoadedDevices[this.breakForm.value.emptyLoadedDevices.length-1].nature) {
          this.emptyLoadedDeviceDS.data[this.breakForm.value.emptyLoadedDevices.length -1].controls.nature.setErrors(Validators.required);
          this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_emptyDevice');
          this.controlDevPassed = false;
          if(this.errorAddList.indexOf(this.messageAddError) === -1) {
            this.errorAddList.push(this.messageAddError);
          } 
        }
        // control 1 
        this.breakForm.value.emptyLoadedDevices.slice(0,this.breakForm.value.emptyLoadedDevices.length-1).forEach(dev => {          
          if(dev.nature == this.breakForm.value.emptyLoadedDevices[this.breakForm.value.emptyLoadedDevices.length -1].nature){
            this.emptyLoadedDeviceDS.data[this.breakForm.value.emptyLoadedDevices.length -1].controls.nature.setErrors(Validators.required);
            this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control1_add_emptydevice');
            this.controlDevPassed = false;
            if(this.errorAddList.indexOf(this.messageAddError) === -1) {
              this.errorAddList.push(this.messageAddError);
            }  
          }
        });
        // control 2 
        if(this.controlDevPassed){
          this.techSpecBrake.wagonTechnicalDataDetail.brake.emptyLoadedDeviceList.forEach(element =>{
            if(element.emptyLoadedDevice.category.code != this.techSpecReferences.emptyLoadedDevices.find(e=> e.code === this.breakForm.value.emptyLoadedDevices[this.breakForm.value.emptyLoadedDevices.length -1].nature).category.code) {
              this.emptyLoadedDeviceDS.data[this.breakForm.value.emptyLoadedDevices.length -1].controls.nature.setErrors(Validators.required);
              this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control2_add_emptydevice');
              this.controlDevPassed = false;
              if(this.errorAddList.indexOf(this.messageAddError) === -1) {
                this.errorAddList.push(this.messageAddError);
              } 
            }
          });
        }
          // control 3
          if(this.controlDevPassed && +(this.breakForm.value.emptyLoadedDevices[this.breakForm.value.emptyLoadedDevices.length -1].amount) < 0){
            this.emptyLoadedDeviceDS.data[this.breakForm.value.emptyLoadedDevices.length -1].controls.amount.setErrors(Validators.required);
            this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.invalid_number');
            this.controlDevPassed = false;
            if(this.errorAddList.indexOf(this.messageAddError) === -1) {
              this.errorAddList.push(this.messageAddError);
            } 
          }
          // control 4
          let sumNumber = 0;
          this.numberWagon = this.numberWagon > -1 ? this.numberWagon : (await this.monitoringWorkService.getNumberOfElementsOfWagon(this.wagonTechnicalData.wagon.id).pipe(first()).toPromise()).data;
          let index = 1;
          this.breakForm.value.emptyLoadedDevices.forEach(dev =>{
            if (index < this.breakForm.value.emptyLoadedDevices.length) {
              sumNumber += +(dev.amount);
              if (this.controlDevPassed && sumNumber > (this.numberWagon * 2)) {
                this.emptyLoadedDeviceDS.data[this.breakForm.value.emptyLoadedDevices.length -1].controls.amount.setErrors(Validators.required);
                this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.incompatible_wagon_number');
                this.controlDevPassed = false;
                if(this.errorAddList.indexOf(this.messageAddError) === -1) {
                  this.errorAddList.push(this.messageAddError);
                } 
              }
            }
          });
          index++;
      }
      if(this.controlDevPassed){
        array.push(this.addFormGroup(this.techSpecReferences.emptyLoadedDevices));
      }
    }
    this.tables.forEach(table => table.renderRows());
  }

  getAuxTankElemFormGroup(data?: OutputAuxiliaryTankElement): FormGroup {
    return this.formBuilder.group({
      position: [data ? data.position : null, Validators.required],
      type: [data ? data.type : null,[ Validators.required, this.validateInList(this.recomAuxTankList)]],
      number: [data ? data.number : null, Validators.required],
      maker: [data ? data.maker : null, this.validateInList(this.tankMakerList)],
      installation: [data ? data.installation : null, Validators.required],
      production: data ? data.production : null,
      internalReview: data ? data.internalReview : null,
      externalReview: data ? data.externalReview : null,
      constructiveRule: [data ? data.constructiveRule : null, this.validateInList(this.constructiveRuleList)],
      dismantling: data ? data.dismantling : null,
      isDerogatory: data ? data.isDerogatory : true,
      tankElementId: data ? data.tankElementId : null,
      wagonId: data ? data.wagonId : this.wagonTechnicalData.wagon.id
    });
  }

  removeRow(array: FormArray, index: number): void {
    array.removeAt(index);
    array.markAsDirty();
    this.modifiedDataEvent.emit(true);
    this.tables.forEach(table => table.renderRows());
  }

  addBrakeMainOrganElement(isNew = true, type?: string, numero?: string, review?: Date): FormGroup {
    return this.formBuilder.group({
      isNew: this.formBuilder.control(isNew, Validators.required),
      type: this.formBuilder.control(type, Validators.required),
      numero: this.formBuilder.control(numero, [Validators.required, Validators.maxLength(10)]), //JIRA-241
      review: this.formBuilder.control(review, Validators.required)
    });
   
  }

  addBrakeMainOrganRow(): void {
    //JIRA-241
    this.flagActivateAddList = true;
    this.errorAddList = [];
    this.messageAddError= '';
    let duplicateNumberControl = true;

    if(this.breakForm.value.brakeMainOrganElements.length >= 1){
      
      this.breakForm.value.brakeMainOrganElements.slice(0,this.breakForm.value.brakeMainOrganElements.length-1).forEach(distributor => {          
        // Contrôle 1 : RG_MAJ_003_4_3_1
        if(distributor.numero == this.breakForm.value.brakeMainOrganElements[this.breakForm.value.brakeMainOrganElements.length -1].numero){        
          this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control1_add_distributor');
          duplicateNumberControl = false;
          this.brakeMainOrganElementDS.data[this.breakForm.value.brakeMainOrganElements.length -1].controls.numero.setErrors(Validators.required);
          if(this.errorAddList.indexOf(this.messageAddError) === -1) {
            this.errorAddList.push(this.messageAddError);
          }  
        }
        //Contrôle 2 : RG_MAJ_003_4_3_2
        const dateSystem = new Date((new Date().getTime()));
        const lastReview = this.breakForm.value.brakeMainOrganElements[this.breakForm.value.brakeMainOrganElements.length -1].review;
        if(lastReview >=  dateSystem){        
          this.messageAddError = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control2_add_distributor');
          duplicateNumberControl = false;
          this.brakeMainOrganElementDS.data[this.breakForm.value.brakeMainOrganElements.length -1].controls.review.setErrors(Validators.required);
          if(this.errorAddList.indexOf(this.messageAddError) === -1) {
            this.errorAddList.push(this.messageAddError);
          }  
        }
      });
    }
    if(duplicateNumberControl){
      this.brakeMainOrganElements.push(this.addBrakeMainOrganElement());
      this.tables.forEach(table => table.renderRows());
    }

    
  }

  /**
   * Get the date of DatePicker control
   * @param ctrl
   */
  getDateFromCtrl(ctrl: AbstractControl) : string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : new Date(ctrl.value);
  }

  removeBrakeMainOrganRow(index: number): void {
    this.brakeMainOrganElements.removeAt(index);
    this.brakeMainOrganElements.markAsDirty();
    this.modifiedDataEvent.emit(true);
    this.tables.forEach(table => table.renderRows());
  }

  get brakeMainOrgans(): FormArray {
    return this.breakForm.get('brakeMainOrgans') as FormArray;
  }

  get regulators(): FormArray {
    return this.breakForm.get('regulators') as FormArray;
  }

  get brakeCylinders(): FormArray {
    return this.breakForm.get('brakeCylinders') as FormArray;
  }

  get brakeParticularities(): FormArray {
    return this.breakForm.get('brakeParticularities') as FormArray;
  }

  get emptyLoadedDevices(): FormArray {
    return this.breakForm.get('emptyLoadedDevices') as FormArray;
  }

  get brakeMainOrganElements(): FormArray {
    return this.breakForm.get('brakeMainOrganElements') as FormArray;
  }

  private checkControls(): void {
    this.msgSubmitError = '';
    this.flagActivateSubmitList = true;
    let checkControlsFailed = false;

    checkControlsFailed = this.control1();
    if (checkControlsFailed) {
      if (this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
        this.errorSubmitList.push(this.msg_error_displayed);
      } 
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      }
    } else {
      this.control2();
    }
  }

  //Contrôle 1 : RI_MAJ_003_4_3_4
  private control1(): boolean {
    this.controlNumber1 = this.breakForm.value.regulators.length <= 1;
    if (this.controlNumber1) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_regulator');
    }
    this.controlNumber2 = false;
    this.controlNumber3 = this.breakForm.value.brakeCylinders.length <= 1;
    if (this.controlNumber3) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_cylinder');
    }
    this.controlNumber4 = this.breakForm.value.emptyLoadedDevices.length <= 1;
    if (this.controlNumber4) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_emptyDevice');
    }
    let index = 1;
    this.breakForm.value.regulators.forEach(element => {
      if (index < this.breakForm.value.regulators.length) {
        if (element.nature && element.amount!==undefined && element.amount!==null && element.amount >= 0) {
          this.controlNumber1 = false;
        } else if (!element.nature) {
          this.controlNumber1 = true;
          this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_regulator');
          this.regulatorDS.data[index-1].controls.nature.setErrors(Validators.required);
        } else if (!element.amount || element.amount < 0) {
          this.controlNumber1 = true;
          this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.invalid_number');
          this.regulatorDS.data[index-1].controls.amount.setErrors(Validators.required);
        }
      }
      index++;
    });

    index = 1;
    this.breakForm.value.brakeMainOrgans.forEach(element => {
      if (index < this.breakForm.value.brakeMainOrgans.length) {
        if (element.nature && element.amount!==undefined && element.amount!==null && element.amount >= 0) {
          this.controlNumber2 = false;
        } else if (!element.nature) {
          this.controlNumber2 = true;
          this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_cylinder');
          this.brakeMainOrganDS.data[index-1].controls.nature.setErrors(Validators.required);
        } else if (!element.amount || element.amount < 0) {
          this.controlNumber2 = true;
          this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.invalid_number');
          this.brakeMainOrganDS.data[index-1].controls.amount.setErrors(Validators.required);
        }
      }
      index++;
    });

    index = 1;
    this.breakForm.value.brakeParticularities.forEach(element => {
      if (index < this.breakForm.value.brakeParticularities.length) {
        if (element.nature && element.amount!==undefined && element.amount!==null && element.amount >= 0) {
          this.controlNumber3 = false;
        } else if (!element.nature) {
          this.controlNumber3 = true;
          this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_discs');
          this.brakeParticularitieDS.data[index-1].controls.nature.setErrors(Validators.required);
        } else if (!element.amount || element.amount < 0) {
          this.controlNumber3 = true;
          this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.invalid_number');
          this.brakeParticularitieDS.data[index-1].controls.amount.setErrors(Validators.required);
        }
      }
      index++;
    });

    index = 1;
    this.breakForm.value.emptyLoadedDevices.forEach(element => {
      if (index < this.breakForm.value.emptyLoadedDevices.length) {
        if (element.nature && element.amount!==undefined && element.amount!==null && element.amount >= 0) {
          this.controlNumber4 = false;
        } else if (!element.nature) {
          this.controlNumber4 = true;
          this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_emptyDevice');
          this.emptyLoadedDeviceDS.data[index-1].controls.nature.setErrors(Validators.required);
        } else if (!element.amount || element.amount < 0) {
          this.controlNumber4 = true;
          this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.invalid_number');
          this.emptyLoadedDeviceDS.data[index-1].controls.amount.setErrors(Validators.required);
        }
      }
      index++;
    });
    return (this.controlNumber1 || this.controlNumber2 || this.controlNumber3 || this.controlNumber4)
    
  }
  
  // Contrôle 2: RG_MAJ_003_4_3_3  Si la somme des nombres de la zone de liste régleur > au nombre de régleurs de la tranche du wagon
  private control2 (): void {
    let sumNumber = 0;
    let index = 1;
    this.breakForm.value.regulators.forEach(element => {
      if (element.amount && index < this.breakForm.value.regulators.length) {
        sumNumber = sumNumber + Number(element.amount);     
      }
      index++;
    });
    // nombre de régleurs de la tranche du wagon
    const numberRegulators = parseInt(this.wagonTechnicalData.tranche.technicalData.trpNbreRegleur, 10);
    
    this.control_2 = false;
    
    if (sumNumber > numberRegulators) {
      this.control_2 = true;
    }
    if (this.control_2) {
      this.regulatorDS.data[this.breakForm.value.regulators.length-2].controls.amount.setErrors(Validators.required);
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.incompatible_regulator_number');
      if (this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
        this.errorSubmitList.push(this.msg_error_displayed);
      } 
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else {
      this.control3();
    }
  }

  // Contrôle 3: RG_MAJ_003_4_3_7 : Si la somme des nombres de la zone de liste Semelles / disques > nombre d’essieux du wagon** x 8
  private async control3 (): Promise<void> {
    let sumNumber = 0;
    let index = 1;
    this.breakForm.value.brakeParticularities.forEach(element => {
      if (element.amount && index < this.breakForm.value.brakeParticularities.length) {
        sumNumber = sumNumber + Number(element.amount);
      }
      index++;
    });
    ///nombre d’essieux du wagon* x 8: number of axles of the wagon
    this.numberAxles = (await this.monitoringWorkService.getNumberOfAxlesOfWagon(this.techSpecBrake.wagonTechnicalDataDetail.mex).pipe(first()).toPromise()).data;
    this.control_3 = false;
    if (sumNumber > (this.numberAxles * 8)) {
      this.control_3 = true;
    }
    if(this.control_3){
      this.brakeParticularitieDS.data[this.breakForm.value.brakeParticularities.length-2].controls.amount.setErrors(Validators.required);
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.incompatible_discs_number');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else {
      this.control4();
    }
  }

  // RG_MAJ_003_4_3_8 : Si la somme des nombres de la zone de liste Dispositif vide charge > nombre d’élements du wagon** x 2
  private async control4 (): Promise<void> {
    let sumNumber = 0;
    let index = 1;
    this.breakForm.value.emptyLoadedDevices.forEach(element => {
      if (element.amount && index < this.breakForm.value.emptyLoadedDevices.length) {
        sumNumber = sumNumber + Number(element.amount);
      }
      index++;
    });
    ///nombre d’éléments du wagon* 2: Number of elements of the wagon
    this.numberWagon = this.numberWagon > -1 ? this.numberWagon : (await this.monitoringWorkService.getNumberOfElementsOfWagon(this.wagonTechnicalData.wagon.id).pipe(first()).toPromise()).data;
    this.control_4 = false;
    if (sumNumber > (this.numberWagon * 2)) {
      this.control_4 = true;
    }
    if(this.control_4) {
      this.emptyLoadedDeviceDS.data[this.breakForm.value.emptyLoadedDevices.length-2].controls.amount.setErrors(Validators.required);
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.incompatible_wagon_number');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else{
      this.control5();
    }
  }

  // RG_MAJ_003_4_11 : Si la somme des nombres de la zone de liste Organe principal > nombre d’éléments du wagon* x 2
  private async control5 (): Promise<void> {
    let sumNumber = 0;
    let index = 1;
    this.breakForm.value.brakeMainOrgans.forEach(element => {
      if (element.amount && index <= this.breakForm.value.brakeMainOrgans.length) {
        sumNumber = sumNumber + Number(element.amount);     
      }
      index++;
    });
    ///nombre d’éléments du wagon* x 2: Number of elements of the wagon
    this.numberWagon = this.numberWagon > -1 ? this.numberWagon : (await this.monitoringWorkService.getNumberOfElementsOfWagon(this.wagonTechnicalData.wagon.id).pipe(first()).toPromise()).data;
    this.control_5 = false;
    if (sumNumber > (this.numberWagon * 2)) {
      this.control_5 = true;
    }
    if (this.control_5) {
      this.brakeMainOrganDS.data[this.breakForm.value.brakeMainOrgans.length-1].controls.amount.setErrors(Validators.required);
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.incompatible_wagon_number');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh1()) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_regulator');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
    } else if(this.controlCoh2()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_cylinder');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh3()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_emptyDevice');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh5()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_purgeValve');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh6()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_handBrake');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh7()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_ifNoBraking');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh8()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_ifBraking');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh9()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_PresenceOfDiscs');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh10()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_command');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh11()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.required_minDistributor');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh12()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.inconsistent_number');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh13()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.inconsistent_number');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh14()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.inconsistent_number');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else if(this.controlCoh15()) {
      this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.inconsistent_number');
      if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
        this.errorSubmitList.push(this.msg_error_displayed);
      }
      if(this.errorSubmitList.length >= 1){
        this.errorSubmitList.forEach(element => {
          this.msgSubmitError = this.msgSubmitError + element + '\n';
        });
      } 
    } else {
      this.controlCoh16();
    }
  }
  
  // RI_MAJ_003_4_3_5 : Au moins un code régleur doit-être présent
  private controlCoh1(): boolean {
    return this.breakForm.value.regulators.length <= 1;
  }

  // RI_MAJ_003_4_3_6 : Au moins un code cylindre de frein doit-être présent
  private controlCoh2(): boolean {
    return this.breakForm.value.brakeCylinders.length <= 1
  }

  // RI_MAJ_003_4_3_7 : Au moins un code dispositif vide charge doit-être présent
  private controlCoh3(): boolean {
    return this.breakForm.value.emptyLoadedDevices.length <= 1
  }

  // RI_MAJ_003_4_3_9 : Le code valve de purge doit être renseigné
  private controlCoh5(): boolean {
      return !this.breakForm.value.purgeValve || this.breakForm.value.purgeValve == null; 
  }

  // RI_MAJ_003_4_3_10 : Le code frein à main doit être renseigné
  private controlCoh6(): boolean {
    return !this.breakForm.value.handBrake || this.breakForm.value.handBrake == null;
  }

  // RG_MAJ_003_4_12  :  Absence de freinage, organe principal = ‘000’ – 
  //cylindre de frein = ‘00’ – réservoir auxiliaire = ‘00’ – régleur = ‘00’ – 
  // dispositif vide charge = ‘00’ – valve de purge = ‘0’ ou ‘1’ ou ‘2’
  private controlCoh7(): boolean {
    if (this.techSpecBrake['wagonBrakeMainOrganList'] && this.techSpecBrake['wagonBrakeMainOrganList'].length > 0) {
      const natureCode = this.techSpecBrake['wagonBrakeMainOrganList'][0].brakeMainOrgan;
      if(natureCode == '000' && 
          (this.breakForm.value.brakeCylinders[0].nature != '00' ||
          this.breakForm.value.regulators[0].nature != '00' ||
          this.breakForm.value.emptyLoadedDevices[0].nature != '00' ||
          (this.breakForm.value.PurgeValve != '0' && this.breakForm.value.purgeValve != '1' &&
          this.breakForm.value.purgeValve != '2' ))){
            return true;
      }
    }
    return false; 
  }

  // RG_MAJ_003_4_13 : Si présence de freinage (code organe principal <> ‘000’) on doit avoir :
	//- un code cylindre de frein <> ’00’ et
	// - un code réservoir auxiliaire <> ‘00’
  private controlCoh8(): boolean {
    if (this.breakForm.value.brakeMainOrgans != null ){
      this.breakForm.value.brakeMainOrgans.forEach(element => {
        if(element.nature != '000' && 
        (this.breakForm.value.brakeCylinders[0].nature == '00')){
          return true;
        }
      });       
    }  
    return false;
  }

  // RG_MAJ_003_4_14 : Si présence d’un code semelles ou frein à disques <> ‘00’, 
  //on doit avoir un code organe principal <> ‘000’.
  private controlCoh9(): boolean {
    if (this.breakForm.value.brakeParticularities){
      this.breakForm.value.brakeParticularities.forEach(element => {
        if(element.nature != '00'){
          this.breakForm.value.brakeMainOrgans.forEach(e => {
            if(e.nature == '000')
              return true;
          });
        }
      });       
    }  
    return false;
  }

  //RG_MAJ_003_4_15 : Si présence d’un code commande de frein <> ‘00’
  // on doit avoir un code organe principal <> ‘000’
  private controlCoh10 (): boolean {
    if (this.breakForm.value.brakeControl && this.breakForm.value.brakeControl.code != '00'){
      this.breakForm.value.brakeMainOrgans.forEach(e => {
            if(e.nature == '000')
              return true;
          });           
    }  
    return false;
  }

  // RG_MAJ_003_4_16 : Le wagon doit avoir au moins un distributeur renseigné
  private controlCoh11 (): boolean {
    if (this.breakForm.value.brakeMainOrganElements) {
      let invalidCtrl = true;
      this.breakForm.value.brakeMainOrganElements.forEach(e => {
            if (e.numero && e.review) {
              invalidCtrl = false;
            }
          });
      return invalidCtrl;
    }
    return false;
  }

  // RG_MAJ_003_4_17 : Si le code régleur = ‘00’ alors le nombre correspondant doit être = 0.
  private controlCoh12 () { 
    if (this.breakForm.value.regulators[0].nature == '00' && this.breakForm.value.regulators[0].amount != 0){    
       return true;
    }  
    return false;
  }

  // RG_MAJ_003_4_18 : Si le code dispositif vide charge = ‘00’ alors le nombre correspondant doit être = 0.
  private controlCoh13 () { 
    if (this.breakForm.value.emptyLoadedDevices[0].nature == '00' && this.breakForm.value.emptyLoadedDevices[0].amount != 0){    
      return true;          
    } 
    return false; 
  }

  // RG_MAJ_003_4_20 : Si le code régleur <> ‘00’ alors le nombre correspondant doit être > 0
  private controlCoh14 (): boolean {
    let control_coh_14 = false;
    let index = 1;
    this.breakForm.value.regulators.forEach(element => {
      if(element.nature != '00' && Number(element.amount) <= 0
         && index < this.breakForm.value.regulators.length) {
        control_coh_14 = control_coh_14 || true;
      }
      index++;
    })
    return control_coh_14;
  }
   
  // RG_MAJ_003_4_21 : Si le code dispositif vide charge <> ‘00’ alors le nombre correspondant doit être > 0
  private controlCoh15 (): boolean {
    let control_coh_15 = false;
    let index = 1;
    this.breakForm.value.emptyLoadedDevices.forEach(element => {
      if(element.nature != '00' && Number(element.amount) <= 0
        && index < this.breakForm.value.emptyLoadedDevices.length) {
        control_coh_15 = control_coh_15 || true;
      }
      index++;
    })
    return control_coh_15;
  }

  // RG_MAJ_003_4_22 : Si une masse freinée a été saisie mais que le nombre d’essieux** du wagon ‘WO’ (somme de WESS_NBRE) = 0 
  private async controlCoh16 (): Promise<void> { 
    this.control_coh_16 = 0;
    if( this.numberAxles == 0){
      const resultGet = (await this.monitoringWorkService.getNumberOfAxlesOfWagon(this.techSpecBrake.wagonTechnicalDataDetail.mex).pipe(first()).toPromise()).data;

      if(resultGet == 0 && this.breakForm.value.emptyBrakedWeightG) {
        this.control_coh_16 = 1;
      }
      if(resultGet == 0 && this.breakForm.value.loadedBrakedWeightG) {
        this.control_coh_16 = 2;
      }
      if(resultGet == 0 && this.breakForm.value.halfBrakedWeightG) {
        this.control_coh_16 = 3;
      }
      if(resultGet == 0 && this.breakForm.value.emptyBrakedWeightP) {
        this.control_coh_16 = 4;
      }
      if(resultGet == 0 && this.breakForm.value.loadedBrakedWeightP) {
        this.control_coh_16 = 5;
      }
      if(resultGet == 0 && this.breakForm.value.halfBrakedWeightP) {
        this.control_coh_16 = 6;
      }
      if(resultGet == 0 && this.breakForm.value.firstTransitionWeight) {
        this.control_coh_16 = 7;
      }
      if(resultGet == 0 && this.breakForm.value.secondTransitionWeight) {
        this.control_coh_16 = 8;
      }
      if(resultGet == 0 && this.breakForm.value.handbrakeBrakedWeight) {
        this.control_coh_16 = 9;
      }
      const resp = this.control_coh_16;
      if(resp == 1) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if (resp == 2){
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if (resp == 3){
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if (resp == 4){
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if (resp == 5){
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if (resp == 6){
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if (resp == 7){
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if (resp == 8){
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if (resp == 9){
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else {
        this.controlCoh17();
      }
    } else {
      this.controlCoh17();
    }
  }  

  // RG_MAJ_003_4_23 : Si une masse freinée est saisie, sa valeur doit être strictement 
  //supérieure à 0 et strictement inférieure à au nombre d’essieux du wagon** (somme WESS_NBRE) x 30. 
  private async controlCoh17 (): Promise<void> { 
    this.control_coh_17 = false;
    if( this.numberAxles == 0){
      let resultGet = (await this.monitoringWorkService.getNumberOfAxlesOfWagon(this.techSpecBrake.wagonTechnicalDataDetail.mex).pipe(first()).toPromise()).data;

      resultGet = resultGet * 30;

      if(this.breakForm.value.emptyBrakedWeightG && 
        (+this.breakForm.value.emptyBrakedWeightG <= 0 || +this.breakForm.value.emptyBrakedWeightG >= +resultGet)) {
        this.control_coh_17 = true;
      }
      if(this.breakForm.value.loadedBrakedWeightG && 
        (+this.breakForm.value.loadedBrakedWeightG <= 0 || +this.breakForm.value.loadedBrakedWeightG >= +resultGet)) {
        this.control_coh_17 = true;
      }
      if(this.breakForm.value.halfBrakedWeightG && 
        (+this.breakForm.value.halfBrakedWeightG <= 0 || +this.breakForm.value.halfBrakedWeightG >= +resultGet)) {
        this.control_coh_17 = true;
      }
      if(this.breakForm.value.emptyBrakedWeightP && 
        (+this.breakForm.value.emptyBrakedWeightP <= 0 || +this.breakForm.value.emptyBrakedWeightP >= +resultGet)) {
        this.control_coh_17 = true;
      }
      if(this.breakForm.value.loadedBrakedWeightP && 
        (+this.breakForm.value.loadedBrakedWeightP <= 0 || +this.breakForm.value.loadedBrakedWeightP >= +resultGet)) {
        this.control_coh_17 = true;
      }
      if(this.breakForm.value.halfBrakedWeightP && 
        (+this.breakForm.value.halfBrakedWeightP <= 0 || +this.breakForm.value.halfBrakedWeightP >= +resultGet)) {
        this.control_coh_17 = true;
      }
      if(this.breakForm.value.firstTransitionWeight && 
        (+this.breakForm.value.firstTransitionWeight <= 0 || +this.breakForm.value.firstTransitionWeight >= +resultGet)) {
        this.control_coh_17 = true;
      }
      if(this.breakForm.value.secondTransitionWeight && 
        (+this.breakForm.value.secondTransitionWeight <= 0 || +this.breakForm.value.secondTransitionWeight >= +resultGet)) {
        this.control_coh_17 = true;
      }
      if(this.breakForm.value.handbrakeBrakedWeight && 
        (+this.breakForm.value.handbrakeBrakedWeight <= 0 || +this.breakForm.value.handbrakeBrakedWeight >= +resultGet)) {
        this.control_coh_17 = true;
      }

      if(this.control_coh_17){
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakedMasses_value');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if(this.controlCoh18()) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakeCode');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if(this.controlCoh19()) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.inconsistent_massValue');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if(this.controlCoh20()) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_distributorNumber');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if(this.controlCoh21()) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_combination');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if(this.controlAuxiliaryTankList()){

      } else if(this.errorSubmitList.length === 0) {
        this.doSubmit();
      }
    } else {
      if(this.controlCoh18()) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_brakeCode');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1) {
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if(this.controlCoh19()) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.inconsistent_massValue');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if(this.controlCoh20()) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_distributorNumber');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if(this.controlCoh21()) {
        this.msg_error_displayed = this.translateService.instant('wagon-update.monitoring-work.tabs.technical_data.brake.msg_control.control_combination');
        if(this.errorSubmitList.indexOf(this.msg_error_displayed) === -1){
          this.errorSubmitList.push(this.msg_error_displayed);
        }
        if(this.errorSubmitList.length >= 1){
          this.errorSubmitList.forEach(element => {
            this.msgSubmitError = this.msgSubmitError + element + '\n';
          });
        } 
      } else if(this.controlAuxiliaryTankList()){

      } else if(this.errorSubmitList.length === 0) {
        this.doSubmit();
      }
    }
  } 

  // RG_MAJ_003_4_24 : Si une masse freinée de frein à main est renseignée, le code Frein à main doit être présent et <> ‘0’
  private controlCoh18 (): boolean { 
    if( this.breakForm.value.handbrakeBrakedWeight
      && (this.breakForm.value.handBrake == null || this.breakForm.value.handBrake == '0') ) {
        return true;
    }
    
    return false;
  }

  // RG_MAJ_003_4_25 : Si elles sont renseignées, les masses freinées doivent répondre aux critères suivants :
	// - 1ère transition < ou = 2ème transition
  // Pour un même régime G ou P
  //  - masse freinée à vide < ou = mi charge
  //  - masse freinée à vide < ou = charge
  //  - masse freinée à mi charge < ou = charge
  private controlCoh19(): boolean {
    let control_coh_19 = false;

    if(this.breakForm.value.emptyBrakedWeightG && this.breakForm.value.halfBrakedWeightG 
      && +this.breakForm.value.emptyBrakedWeightG > +this.breakForm.value.halfBrakedWeightG){
        control_coh_19 = true;
    }

    if(this.breakForm.value.emptyBrakedWeightP && this.breakForm.value.halfBrakedWeightP
      && +this.breakForm.value.emptyBrakedWeightP > +this.breakForm.value.halfBrakedWeightP) {
        control_coh_19 = true;
    }

    if(this.breakForm.value.emptyBrakedWeightG &&  this.breakForm.value.loadedBrakedWeightG
      && +this.breakForm.value.emptyBrakedWeightG > +this.breakForm.value.loadedBrakedWeightP){
        control_coh_19 = true;
    }

    if(this.breakForm.value.emptyBrakedWeightP && this.breakForm.value.loadedBrakedWeightG
      && +this.breakForm.value.emptyBrakedWeightP > +this.breakForm.value.loadedBrakedWeightP) {
        control_coh_19 = true;
    }

    if (this.breakForm.value.halfBrakedWeightG && this.breakForm.value.loadedBrakedWeightG 
      && +this.breakForm.value.halfBrakedWeightG > +this.breakForm.value.loadedBrakedWeightG) {
        control_coh_19 = true;
    }

    if (this.breakForm.value.loadedBrakedWeightP && this.breakForm.value.halfBrakedWeightP 
      && +this.breakForm.value.halfBrakedWeightP > +this.breakForm.value.loadedBrakedWeightP ) {
        control_coh_19 = true;
    }
    if(this.breakForm.value.firstTransitionWeight && this.breakForm.value.secondTransitionWeight
      && +this.breakForm.value.firstTransitionWeight > +this.breakForm.value.secondTransitionWeight) {
        control_coh_19 = true;
    }
    
    return control_coh_19;
  }

  // RG_MAJ_003_4_26 : Si le nombre de lignes de la liste des distributeurs est > 0 alors le nombre de lignes de la liste des 
  // distributeurs doit être égal à la somme des nombres du groupe ‘Organe principal’
  private controlCoh20(): boolean {
    const nombreLignesdistributeurs = this.brakeMainOrganElementDS.data.length - 1;
    let nombreMainOrgan = 0;
    let index = 1;
    this.breakForm.value.brakeMainOrgans.forEach(element => {
      if (element.amount && index <= this.breakForm.value.brakeMainOrgans.length) {
        nombreMainOrgan = nombreMainOrgan + Number(element.amount);     
      }
      index++;
    });
    if (nombreLignesdistributeurs > 0) {
      return nombreLignesdistributeurs !== nombreMainOrgan;
    }
    return false;
  }
  
  // RG_MAJ_003_4_27 : Selon la combinaison des propriétés d’organe principal et de dispositif vide charge, les masses freinées 
  // saisies doivent être conformes au tableau de la page suivante
  private controlCoh21(): boolean {
    let isValidControlCoh21 = true;
    let currentControl = true;
    this.techSpecBrake.wagonTechnicalDataDetail.brake.mainOrganList.forEach(main => {
      currentControl = true;

      // For OPF G 1
      if (main.brakeMainOrgan.speedRegime.code == '1') {
        this.emptyLoadedDevices.value.forEach( (e, i, a) => {
          if (i < a.length -1) {
            const emptyLoadedDevice = this.techSpecReferences.emptyLoadedDevices.find( eld => eld.code === e.nature);
            if(emptyLoadedDevice.category.code == '00'){
              currentControl = this.case_2();
            } else if(emptyLoadedDevice.category.code == '01'){
              currentControl = this.case_5();
            } else if(emptyLoadedDevice.category.code == '18' || emptyLoadedDevice.category.code == '19'){
              currentControl = this.case_8();
            } else if(emptyLoadedDevice.category.code == '09'){
              currentControl = this.case_11();
            }
          }
        });
      }
      
      // For OPF P 2
      if (main.brakeMainOrgan.speedRegime.code == '2') {
        this.emptyLoadedDevices.value.forEach( (e, i, a) => {
          if (i < a.length -1) {
            const emptyLoadedDevice = this.techSpecReferences.emptyLoadedDevices.find( eld => eld.code === e.nature);
            if(emptyLoadedDevice.category.code == '00'){
              currentControl = this.case_3();
            } else if(emptyLoadedDevice.category.code == '01'){
              currentControl = this.case_6();
            } else if(emptyLoadedDevice.category.code == '18' || emptyLoadedDevice.category.code == '19'){
              currentControl = this.case_9();
            } else if(emptyLoadedDevice.category.code == '09'){
              currentControl = this.case_12();
            }
          }
        });
      }

      // For OPF GP 3
      if (main.brakeMainOrgan.speedRegime.code == '3') {
        this.emptyLoadedDevices.value.forEach( (e, i, a) => {
          if (i < a.length -1) {
            const emptyLoadedDevice = this.techSpecReferences.emptyLoadedDevices.find( eld => eld.code === e.nature);
            if(emptyLoadedDevice.category.code == '00'){
              currentControl = this.case_4();
            } else if(emptyLoadedDevice.category.code == '01'){
              currentControl = this.case_7();
            } else if(emptyLoadedDevice.category.code == '18' || emptyLoadedDevice.category.code == '19'){
              currentControl = this.case_10();
            } else if(emptyLoadedDevice.category.code == '09'){
              currentControl = this.case_13();
            }
          }
        });
      }

    // For OPF Indéfini 9
    if (main.brakeMainOrgan.speedRegime.code == '9') {
      this.emptyLoadedDevices.value.forEach( (e, i, a) => {
        if (i < a.length -1) {
          const emptyLoadedDevice = this.techSpecReferences.emptyLoadedDevices.find( eld => eld.code === e.nature);
          if(emptyLoadedDevice.category.code == '00'){
            currentControl = this.case_14();
          } else if(emptyLoadedDevice.category.code == '01'){
            currentControl = this.case_15();
          } else if(emptyLoadedDevice.category.code == '18' || emptyLoadedDevice.category.code == '19'){
            currentControl = this.case_16();
          } else if(emptyLoadedDevice.category.code == '09'){
            currentControl = this.case_17();
          }
       }
      });
    }
    isValidControlCoh21 = isValidControlCoh21 && currentControl;
  });
  return !isValidControlCoh21;
  }

  private case_2(): boolean {
    if(+this.breakForm.value.emptyBrakedWeightG > 0 &&
    +this.breakForm.value.emptyBrakedWeightG == +this.breakForm.value.loadedBrakedWeightG){
      return true;
    } else {
      return false;
    }
  }
  private case_3(): boolean {
    if(+this.breakForm.value.emptyBrakedWeightP > 0 &&
    +this.breakForm.value.emptyBrakedWeightP == +this.breakForm.value.loadedBrakedWeightP){
      return true;
    } else {
      return false;
    }
  }
  private case_4(): boolean {
    if(+this.breakForm.value.emptyBrakedWeightP > 0 &&
    +this.breakForm.value.emptyBrakedWeightP == +this.breakForm.value.loadedBrakedWeightP && 
    +this.breakForm.value.emptyBrakedWeightG >= +this.breakForm.value.emptyBrakedWeightP &&
    +this.breakForm.value.loadedBrakedWeightG == +this.breakForm.value.emptyBrakedWeightG){
      return true;
    } else {
      return false;
    }
  }
  private case_5(): boolean {
    if(+this.breakForm.value.firstTransitionWeight > 0 &&
    +this.breakForm.value.emptyBrakedWeightG > 0 && 
    +this.breakForm.value.loadedBrakedWeightG > +this.breakForm.value.emptyBrakedWeightG ){
      return true;
    } else {
      return false;
    }
  }
  private case_6(): boolean {
    if(+this.breakForm.value.emptyBrakedWeightP > 0 &&
    +this.breakForm.value.loadedBrakedWeightP > +this.breakForm.value.emptyBrakedWeightP && 
    +this.breakForm.value.firstTransitionWeight > 0 ){
      return true;
    } else {
      return false;
    }
  }
  private case_7(): boolean {
    if(+this.breakForm.value.emptyBrakedWeightP > 0 &&
      +this.breakForm.value.loadedBrakedWeightP > +this.breakForm.value.emptyBrakedWeightP && 
      +this.breakForm.value.firstTransitionWeight > 0 && +this.breakForm.value.emptyBrakedWeightG >= +this.breakForm.value.emptyBrakedWeightP &&
      +this.breakForm.value.loadedBrakedWeightG > +this.breakForm.value.emptyBrakedWeightG){
        return true;
      } else {
        return false;
      }
  }
  private case_8(): boolean {
    if(+this.breakForm.value.loadedBrakedWeightG > 0){
      return true;
    } else {
      return false;
    }
  }
  private case_9(): boolean {
    if(+this.breakForm.value.loadedBrakedWeightP > 0){
      return true;
    } else {
      return false;
    }
  }
  private case_10(): boolean {
    if(+this.breakForm.value.loadedBrakedWeightP > 0 && 
      +this.breakForm.value.loadedBrakedWeightG >= +this.breakForm.value.loadedBrakedWeightP){
      return true;
    } else {
      return false;
    }
  }
  private case_11(): boolean {
    if((+this.breakForm.value.firstTransitionWeight > 0 && +this.breakForm.value.emptyBrakedWeightG > 0 &&
      +this.breakForm.value.loadedBrakedWeightG > +this.breakForm.value.emptyBrakedWeightG) 
    || (+this.breakForm.value.firstTransitionWeight > 0 &&
      +this.breakForm.value.secondTransitionWeight > +this.breakForm.value.firstTransitionWeight &&
      +this.breakForm.value.emptyBrakedWeightG > 0 && 
      +this.breakForm.value.loadedBrakedWeightG > +this.breakForm.value.halfBrakedWeightG &&
      +this.breakForm.value.halfBrakedWeightG > +this.breakForm.value.emptyBrakedWeightG)){
      return true;
    } else {
      return false;
    }
  }
  private case_12(): boolean { 
    if ((+this.breakForm.value.emptyBrakedWeightP > 0 && +this.breakForm.value.loadedBrakedWeightP > +this.breakForm.value.emptyBrakedWeightP &&
      +this.breakForm.value.firstTransitionWeight > 0) || 
      (+this.breakForm.value.emptyBrakedWeightP > 0 && +this.breakForm.value.loadedBrakedWeightP > +this.breakForm.value.halfBrakedWeightP &&
        +this.breakForm.value.firstTransitionWeight > 0 && 
        +this.breakForm.value.halfBrakedWeightP > +this.breakForm.value.emptyBrakedWeightP &&
        +this.breakForm.value.secondTransitionWeight > +this.breakForm.value.firstTransitionWeight)){
          return true;
    } else {
      return false;
    }
  }
  private case_13(): boolean {
    if ((+this.breakForm.value.emptyBrakedWeightP > 0 && +this.breakForm.value.loadedBrakedWeightP > +this.breakForm.value.emptyBrakedWeightP &&
      +this.breakForm.value.firstTransitionWeight > 0 && +this.breakForm.value.emptyBrakedWeightG >=  +this.breakForm.value.emptyBrakedWeightP &&
        +this.breakForm.value.loadedBrakedWeightG > +this.breakForm.value.emptyBrakedWeightG) || 
      (+this.breakForm.value.emptyBrakedWeightP > 0 && +this.breakForm.value.loadedBrakedWeightP > +this.breakForm.value.halfBrakedWeightP &&
        +this.breakForm.value.firstTransitionWeight > 0 && 
        +this.breakForm.value.halfBrakedWeightP > +this.breakForm.value.emptyBrakedWeightP &&
        +this.breakForm.value.secondTransitionWeight > +this.breakForm.value.firstTransitionWeight && 
        +this.breakForm.value.emptyBrakedWeightG >= +this.breakForm.value.emptyBrakedWeightP && 
        +this.breakForm.value.loadedBrakedWeightG > +this.breakForm.value.halfBrakedWeightG && 
        +this.breakForm.value.halfBrakedWeightG > +this.breakForm.value.emptyBrakedWeightG)){
          return true;
    } else {
      return false;
    }
  }
  private case_14(){
    return (this.case_2() || this.case_3() || this.case_4());
  }

  private case_15(){
    return (this.case_5() || this.case_6() || this.case_7());
  }
  private case_16(){
    return (this.case_8() || this.case_9() || this.case_10());
  }
  private case_17(){
    return (this.case_11() || this.case_12() || this.case_13());
  }

  /**
   * Popup message. Closed after 5 seconds.
   */
  private updateDone() {
    this.ngOnInit();
    if (this.alert) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      const message = this.translateService.instant('wagon.technical.update-done.msg');
      const timeout = UtilityService.UPDATE_DONE_TIMEOUT;
      dialogConfig.data = {
        namePopUp: 'update-done-alert',
        titlePopUp: message,
        msgPopUp: message
      };
      const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
      dialogRef.afterOpened().subscribe(_ => {
          setTimeout(() => {
          dialogRef.close();
          this.dialog.closeAll();
          }, timeout)
      });
    }
  }

  displayCodeLabel = (obj: any) => {
    return obj ? (obj.code ? obj.code + ' - ' + obj.label : obj) : '';
  }

  displayCodeLabelFromRegList = (obj: any) => {
    const f = this.techSpecReferences.regulators.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromBrakeCylList = (obj: any) => {
    const f = this.techSpecReferences.brakeCylinders.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromPartList = (obj: any) => {
    const f = this.techSpecReferences.brakeParticularities.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromEmptyLoadList = (obj: any) => {
    const f = this.techSpecReferences.emptyLoadedDevices.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromAuxTankList = (obj: any) => {
    const f = this.techSpecReferences.auxiliaryTanks.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromPurgeValveList = (obj: any) => {
    const f = this.techSpecReferences.purgeValves.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromBrakeControlList = (obj: any) => {
    const f = this.techSpecReferences.brakeControls.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromHandbrakeList = (obj: any) => {
    const f = this.techSpecReferences.handbrakes.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCode = (obj: any) => {
    return obj ? (obj.code ? obj.code : obj) : '';
  }

  filterCode(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => v.code.toUpperCase().includes(filterValue)
      ) : list;
  }
  filterWithCodeLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + ' - ' + v.label).toUpperCase().includes(filterValue))
      ) : list;
  }
  filterAresType(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.aresType : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => v.aresType.toUpperCase().includes(filterValue)
      ) : list;
  }
  autoUcase(control: AbstractControl) {
    if (control.value) {
      control.setValue((control.value as string).toUpperCase(), {onlySelf: true, emitEvent: false, emitModelToViewChange: true,
        emitViewToModelChange: false});
    }
  }

  
  validateCodeInList(list: {code: string}[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let val = control.value;
      if (!val) {
        if (val === '') {
          control.setValue(null);
        }
        return null;
      } else {
        
        val = val.toUpperCase();
        const type = list.filter( (elem) => elem.code === val);
        if (type.length) {
          return null;
        }
      }
      return { validateCodeInList: true };
    };
  }

  validateInList(list: {code: string}[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let val = control.value;      
      if (!val) {
        if (val === '') {
          control.setValue(null);
        }
        return null;
      } else if (typeof val === 'object') {
        if (list.filter( elem => elem.code === val.code).length) {
          return null;
        }
      } else {
        val = val.toUpperCase();
        const type = list.filter( (elem) => elem.code.includes(val));
        if (type.length === 1) {
          control.setValue(type[0]);
          return null;
        }
      }
      return { validateInList: true };
    };
  }
  selectDetail(index, table: MatTable<any>) {
    this.detailSelection.toggle(index);
    table.renderRows();
  }
  async addAuxTankRow(table: MatTable<any>) {
    const elem = this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1);
    for (let index = 0; index < this.auxiliaryTankElementList.length - 1; index++) {
      const element = this.auxiliaryTankElementList.at(index);
      // date pose > sysdate
      // Controle position identiques
      if(element.get('position').value === elem.get('position').value && (!element.get('dismantling').value || element.get('dismantling').invalid)) {
        this.popErrorMessage('wagon.technical.brake.rgmaj003_4_3_30');
        return;
      }
      // numéro déjà présent
      if(element.get('number').value === elem.get('number').value) {
        this.popErrorMessage('wagon.technical.brake.rgmaj003_4_3_3x');
        return;
      }
    }
    // Réservoir déjà posé ailleur -> comp numéro
    const res = (await this.monitoringWorkService.checkAuxTank(elem.get('number').value, elem.get('wagonId').value).pipe(first()).toPromise()).data;
    if(res) {
      this.popErrorMessage('wagon.technical.brake.rgmaj003_4_3_3y');
      return;
    }
    const tank = (await this.monitoringWorkService.getAuxTank(elem.get('number').value).pipe(first()).toPromise()).data;
    if (tank) {
      this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('production').setValue(tank.production);
      this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('maker').setValue(this.tankMakerList.find(e => e.code === tank.manufacturer));
      //this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('internalReview').setValue(tank.internalReview);
      //this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('externalReview').setValue(tank.externalReview);
      this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('isDerogatory').setValue(tank.isDerogatory);
      this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('constructiveRule').setValue(tank.constructiveRule);
    } else if (this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('production').invalid 
            || !this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('production').value) {
        this.popErrorMessage('wagon.technical.brake.rgmaj003_4_3_3z');
      return;
    } 
    
    if (!this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('internalReview').value) {
      this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('internalReview').setValue(
        this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('installation').value
      );
    }
    if (!this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('externalReview').value) {
      this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('externalReview').setValue(
        this.auxiliaryTankElementList.at(this.auxiliaryTankElementList.length - 1).get('installation').value
      );
    }
    
    (this.breakForm.get('auxiliaryTankElementList') as FormArray).push(this.getAuxTankElemFormGroup());
    this.tables.forEach(table => table.renderRows());
    table.renderRows();
  }
  
  private controlAuxiliaryTankList(): boolean {

    //const list: OutputAuxiliaryTankElement[] = this.auxiliaryTankElementList.value;
    let res = false;
    for (let index = 0; index < this.auxiliaryTankElementList.length - 2; index++) {
      const element = this.auxiliaryTankElementList.at(index);
      for (let index2 = index + 1; index2 < this.auxiliaryTankElementList.length - 1; index2++) {
        const element2 = this.auxiliaryTankElementList.at(index2);
        // date pose > sysdate
        // Controle position identiques
        if(element.get('position').value === element2.get('position').value 
        && (!element.get('dismantling').value || element.get('dismantling').invalid) 
        && (!element2.get('dismantling').value  || element2.get('dismantling').invalid)) {
          res = true;
          const msg = this.translateService.instant('wagon.technical.brake.rgmaj003_4_3_30b');
          if(this.errorSubmitList.indexOf(msg) === -1) {
            this.errorSubmitList.push(msg);
          }
        }
        // numéro déjà présent
        if(element.get('number').value === element2.get('number').value) {
          res = true;
          const msg = this.translateService.instant('wagon.technical.brake.rgmaj003_4_3_3xb');
          if(this.errorSubmitList.indexOf(msg) === -1) {
            this.errorSubmitList.push(msg);
          }
        }

      }
    }
    for (let index = 0; index < this.auxiliaryTankElementList.length - 1; index++) {
      const element = this.auxiliaryTankElementList.at(index);
      if(!element.get('production').value || element.get('production').invalid) {
        res = true;
        const msg = this.translateService.instant('wagon.technical.brake.rgmaj003_4_3_3zb');
        if(this.errorSubmitList.indexOf(msg) === -1) {
          this.errorSubmitList.push(msg);
        }
      }
    }
    
    if(this.errorSubmitList.length > 0){
      this.errorSubmitList.forEach(element => {
        this.msgSubmitError = this.msgSubmitError + element + '\n';
      });
    } 
    return res;
  }
  
  popErrorMessage(msg: string) {
    this.dialog.open(DialogComponent, {
      data: {
        title: this.translateService.instant('axle.error'),
        message: msg
      }
    });
  }

  isValid(): boolean {
     return (this.breakForm.get('regulators') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
      (this.breakForm.get('brakeCylinders') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
      (this.breakForm.get('brakeParticularities') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
      (this.breakForm.get('emptyLoadedDevices') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
      (this.breakForm.get('brakeMainOrganElements') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
      (this.breakForm.get('auxiliaryTankElementList') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
      this.breakForm.get('brakeMainOrgans').valid && this.breakForm.get('auxiliaryTank').valid && this.breakForm.get('purgeValve').valid && this.breakForm.get('brakeControl').valid && 
      this.breakForm.get('handBrake').valid && this.breakForm.get('emptyBrakedWeightG').valid && this.breakForm.get('loadedBrakedWeightG').valid && this.breakForm.get('halfBrakedWeightG').valid && 
      this.breakForm.get('emptyBrakedWeightP').valid && this.breakForm.get('loadedBrakedWeightP').valid && this.breakForm.get('halfBrakedWeightP').valid && 
      this.breakForm.get('firstTransitionWeight').valid && this.breakForm.get('secondTransitionWeight').valid && this.breakForm.get('handbrakeBrakedWeight').valid;
  }
}
