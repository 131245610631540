import { AppApiService } from '../../../services/app-api/app-api.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiResponse } from '../../../services/app-api/api-response.model';
import { WorkingBill } from 'src/app/shared/models/working-bill';
import { catchError } from 'rxjs/operators';
import { MissingMaterialProd } from 'src/app/shared/models/missing-material-prod';
import { ReferentialUpdate } from 'src/app/shared/models/referential-update';
import { WagonProduction } from 'src/app/shared/models/wagon-production';
import { HttpParams } from '@angular/common/http';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';

/**
 * `DashboardService` is responsible for retrieve dashboard related datas through API calls
 */

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
  /**
   * DashboardService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) {  }
  /** Retrieve workingBills with given coetb
   * @param coetb the etab id
   * @returns encapsulated workingBill datas as `Observable`
   */
  public getWorkingBills(workshopCode: string): Observable<ApiResponse<WorkingBill[]>> {
    return this.api.get<ApiResponse<WorkingBill[]>>(`/dashboard/${workshopCode}/working-bills/overview`)
    .pipe(
        catchError(this.handleError<any>('get', {data: null}))
    );
  }

  public getMissingMaterials(workshopCode: string): Observable<ApiResponse<MissingMaterialProd[]>> {
    return this.api.get<ApiResponse<MissingMaterialProd[]>>(`/dashboard/${workshopCode}/missing-materials-prod`)
    .pipe(
      catchError(this.handleError<any>('get', {data: null}))
    );
  }

  public getReferentialUpdate( pageable?: Pageable): Observable<ApiResponse<ReferentialUpdate[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    return this.api.get<ApiResponse<ReferentialUpdate[]>>(`/dashboard/referential-updates`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('get', {data: null}))
    );
  }



  public getWagonProd(workshopCode: string, delay?: string): Observable<ApiResponse<WagonProduction[]>> {
    let parameters: HttpParams = new HttpParams();
    if (delay !== undefined) {
      parameters = parameters.set('delay', delay);
    }
    return this.api.get<ApiResponse<WagonProduction[]>>(`/dashboard/${workshopCode}/wagon-prod`, {params: parameters})
    .pipe(
      catchError(this.handleError<any>('get', {data: null}))
    );
  }

  public getWagonUpdateNumber(etabId: string, isClose?: string): Observable<ApiResponse<number>> {
    let parameters: HttpParams = new HttpParams();
    if (isClose !== undefined) {
      parameters = parameters.set('isClose', isClose);
    }
    return this.api.get<ApiResponse<number>>(`/dashboard/${etabId}/wagons/update/size`, {params: parameters})
    .pipe(
      catchError(this.handleError<any>('get', {data: null}))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
