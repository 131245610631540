import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, QueryList, ViewChildren, Output, EventEmitter, Optional } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { BehaviorSubject, of, Subject, Subscription } from 'rxjs';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { debounceTime } from 'rxjs/operators';
import { RefMaterial } from 'src/app/shared/models/ref-material';
import { RefMaterialUpdate } from 'src/app/shared/models/ref-material-udpate';
import { MonitoringWorkService } from 'src/app/shared/services/monitoring-work/monitoring-work.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TemplatePopupDialogComponent } from '../../../template-popup-dialog/template-popup-dialog.component';
import { StorageService } from 'src/app/core/services/storage/storage.service';

const MY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-management-expectations',
  templateUrl: './management-expectations.component.html',
  styleUrls: ['./management-expectations.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT }
    , DatePipe]
})

export class ManagementExpectationsComponent implements OnInit, OnDestroy {

  /** physical wagon */
  @Input() wagon: number;
  @Input() receivedMonitoringWorksMsg: string;
  @Input() isMonitoringWorksFormDirty: boolean;
  @Output() saved = new EventEmitter<RefMaterialUpdate[]>();
  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  managementExpectationsForm: FormGroup;
  /** RefMaterialUpdate datas*/
  refMaterialUpdateList: RefMaterialUpdate[];
  /**RefMaterial datas */
  refMaterialList: RefMaterial[];
  displayedColumns: string[] = ['edition', 'symbol', 'numberPieces', 'hhrModelNumber', 'sendingDate', 'expectedDate', 'receiptDate'];
  refMaterialUpdateDS: MatTableDataSource<FormGroup>;
  /** Is modied datas ? */
  isModifiedData: boolean;
  /** filtered intervention Reason list */
  filteredPatternList:RefMaterial[] = [];
  /** Error list*/
  errorList: string[];
  /** submitted property */
  submitted: boolean;
  /** message error */
  messageError: string;
  dateToday = new Date();

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private monitoringWorkService: MonitoringWorkService,
    private storageService: StorageService,
    private translateService: TranslateService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      if ( data && data.wagonId ) {
        this.wagon = data.wagonId
      } 
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.managementExpectationsForm = this.formBuilder.group({});
    this.managementExpectationsForm.addControl('refMaterials', this.formBuilder.array([]));
    this.isModifiedData = false;
    this.loadInitDatas();
  }

/**
 * RG_MAJ_003_2_1: La date prévisionnelle de réception doit être supérieure à la date d’envoi
 * Validator to check that SendingDate and estimation ReleaseDate
 */
  expectedAndSendingDateValidator: ValidatorFn = (group: FormGroup): ValidationErrors | null => {
    const sendingDateCtrl = group.get('sendingDate');
    const expectedDateCtrl = group.get('expectedDate');

    if (!expectedDateCtrl.errors && expectedDateCtrl.value && !sendingDateCtrl.errors && sendingDateCtrl.value) {
      if (this.castDateToString(sendingDateCtrl) >= this.castDateToString(expectedDateCtrl)) {
        return { forbiddenExpectedDate: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

 /**
  * RG_MAJ_003_2_2: La date d'envoi doit être inférieure à la date réelle de réception 
  * Validator to check that SendingDate et estimatino ReceiptDate
  */
  sendingAndReceiptDateValidator: ValidatorFn = (group: FormGroup): ValidationErrors | null => {
    const sendingDateCtrl = group.get('sendingDate');
    const receiptDateCtrl = group.get('receiptDate');
    if (!receiptDateCtrl.errors && receiptDateCtrl.value && !sendingDateCtrl.errors && sendingDateCtrl.value) {
      if (this.castDateToString(sendingDateCtrl) > this.castDateToString(receiptDateCtrl)) {
        return { forbiddenReceiptDate: true };
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  /**
   * RG_MAJ_003_2_3
   * Validator to check if there are a Symbol duplicate
   */
  duplicateSymbolValidator: ValidatorFn = (group: FormGroup): ValidationErrors | null => {
    const symbol = group.get('symbol');
    let countSymbol = 0;
    if (this.refMaterialUpdateDS) {
      this.refMaterialUpdateDS.data.forEach(element => {
        if (element.value.symbol === symbol.value) {
          countSymbol++;
        } else if (symbol && symbol.value && symbol.value.code && element.value.symbol) {
          if (element.value.symbol.code === symbol.value.code) {
            countSymbol++;
          }
        }
      });
    }
    if (countSymbol > 1) {
      return { forbiddenDuplicateSymbol: true };
    } else {
      return null;
    }
  }

  /**
   * Contrôle 1 : Le système vérifie la présence des données obligatoires
   * Validator to check if there are a value empty
   */
  requiredValuesValidator: ValidatorFn = (group: FormGroup): ValidationErrors | null => {
    const symbol = group.get('symbol');
    const numberPieces = group.get('numberPieces');

    if (symbol.errors || !symbol.value || numberPieces.errors || !numberPieces.value) {

      return { forbiddenValueRequired: true };

    } else {
      return null;
    }
  }

  validateInList(list: any[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let val = control.value;
      if (!val) {
        if (val === '') {
          control.setValue(null);
        }
        return null;
      } else if (typeof val === 'object') {
        if (list.filter( elem => elem.code === val.code).length) {
          return null;
        }
      } else {
        val = val.toUpperCase();
        const type = list.filter( (elem) => elem.code.includes(val));
        if (type.length === 1) {
          control.setValue(type[0]);
          return null;
        }
      }
      return { validateInList: true };
    };
  }

  private filterWithCodeLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + v.label).toUpperCase().includes(filterValue))
      ) : list;
  }

  /**
   * Add FormGroup 
   * @param isNew 
   * @param ref 
   */
  addFormGroup(isNew: boolean, ref?: RefMaterialUpdate): FormGroup {

    return this.formBuilder.group({
      symbol: this.formBuilder.control(ref ? ref.refMaterial : null, [Validators.required, this.validateInList(this.refMaterialList)]),
      numberPieces: this.formBuilder.control(ref && ref.number ? ref.number : null, Validators.required),
      hhrModelNumber: this.formBuilder.control(ref && ref.hhrModelNumber ? ref.hhrModelNumber : null),
      sendingDate: this.formBuilder.control(ref && ref.sendingDate ? ref.sendingDate : null),
      expectedDate: this.formBuilder.control(ref && ref.expectedDate ? ref.expectedDate : null),
      receiptDate: this.formBuilder.control(ref && ref.receiptDate ? ref.receiptDate : null),
      added: this.formBuilder.control(isNew, Validators.required),
    }, { validators: [this.expectedAndSendingDateValidator, this.sendingAndReceiptDateValidator, this.duplicateSymbolValidator, this.requiredValuesValidator] }
    );
  }

  addRow(array: FormArray, index: number, added: boolean): void {
    const fg = this.addFormGroup(added);
    fg.get('symbol').valueChanges.pipe(debounceTime(10)).subscribe(
      value => this.filteredPatternList = this.filterWithCodeLabel(value, this.refMaterialList)
    );
    array.push(fg);
    this.tables.forEach(table => table.renderRows());
    this.isModifiedData = true;
  }

  removeRow(array: FormArray, index: number): void {
    array.removeAt(index);
    this.tables.forEach(table => table.renderRows());
    this.isModifiedData = true;
  }

  /**
   * Create a RefMaterialUpdate Object
   */
  createRefMaterialUpdate(nbre: number, hhrModelNmbr: string, sendingDate: string | Date, expectedDate: string | Date, receiptDate: string | Date, symbol: RefMaterial): RefMaterialUpdate {
    const refMaterialUpdate = new RefMaterialUpdate();
    refMaterialUpdate.number = nbre;
    refMaterialUpdate.hhrModelNumber = hhrModelNmbr;
    refMaterialUpdate.sendingDate = sendingDate !== null ? typeof sendingDate === 'string' ? new Date(sendingDate) : sendingDate : null;
    refMaterialUpdate.expectedDate = expectedDate !== null ? typeof expectedDate === 'string' ? new Date(expectedDate) : expectedDate : null;
    refMaterialUpdate.receiptDate = receiptDate !== null ? typeof receiptDate === 'string' ? new Date(receiptDate) : receiptDate : null;
    refMaterialUpdate.refMaterial = symbol;
    return refMaterialUpdate;
  }

  /**
   * Put value modified
   * RI_MAJ_003_2_2 : Le bouton " Enregistrer " devient actif (couleur bleu, cliquable)
   * dès qu'au moins un champ a été modifié.
   */
  changeValue(last?:boolean): void {
    if (last){
      return;
    }
    this.isModifiedData = this.refMaterials.dirty;
    this.submitted = false;
  }

  /**
   * Get the date of DatePicker control
   * @param ctrl 
   */
  getDateFromCtrl(ctrl: AbstractControl): string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : new Date(ctrl.value);
  }

  /**
   * Cast type Date to String
   * @param ctrl 
   */
  castDateToString(ctrl: AbstractControl): string {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : ctrl.value;
  }

  displayByCode(data: any): void {
    return data ? (data.code ? data.code : '') : '';
  }

  displayCodeLabel(data: any): void {
    return data ? (data.code ? data.code + ' - ' + (data.label as string).replace(/ {2} */, ' ') : data) : '';
  }

  /**
   * Methode pour init datas 
   */
  loadInitDatas(): void {
    this.subscriptionRefs.push(
      forkJoin(
        this.monitoringWorkService.getManagementExpectationsDatas(this.wagon),
        this.storageService.existsStorage('ref-materials') ? of({data: this.storageService.getStorageJson('ref-materials')}) : this.monitoringWorkService.getAllValidRefMaterial()
      ).subscribe(([refMatUpdate, refMatList]) => {
        if (!this.storageService.existsStorage('ref-materials')) {
          this.storageService.putStorageJson('ref-materials', refMatList.data);
        }
        this.refMaterialUpdateList = refMatUpdate.data;
        this.refMaterialList = refMatList.data;
        this.filteredPatternList = this.refMaterialList;
        this.resetDatas();
        this.loadingSubject.next(false);
      })
    );
  }

  /**
   * Reset les donnes 
   */
  resetDatas(): void {
    this.submitted = false;
    this.refMaterials.clear();
    this.messageError = '';

    const mainOrganList = this.refMaterialUpdateList;
    mainOrganList.forEach(elem => this.refMaterials.push(this.addFormGroup(true, elem)));
    this.refMaterials.push(this.addFormGroup(false));
    this.refMaterialUpdateDS = new MatTableDataSource<FormGroup>(this.refMaterials.controls as FormGroup[]);

    this.refMaterials.controls.forEach(element => {
      element.get('symbol').valueChanges.pipe(debounceTime(10)).subscribe(
        value => this.filteredPatternList = this.filterWithCodeLabel(value, this.refMaterialList)
      );
    });

  }

  get refMaterials(): FormArray {
    return (this.managementExpectationsForm.get('refMaterials') as FormArray)
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
    this.loadingSubject.complete();
  }

  /**
   * Method to submit the form
   */
  onSubmit(): void {
    this.submitted = true;

    this.tables.forEach(t => t.renderRows());
    
    if (!this.checkIfExistControlError()) {
      this.loadingSubject.next(true);
      const pendingSymbols: RefMaterialUpdate[] = [];
      this.refMaterialUpdateDS.data.map( e => e.getRawValue()).filter((v, idx, arr) => idx < arr.length - 1).forEach(e => {
        const symbolToSave = this.createRefMaterialUpdate(e.numberPieces, e.hhrModelNumber, e.sendingDate, e.expectedDate, e.receiptDate, e.symbol);
        pendingSymbols.push(symbolToSave);
      });

      this.subscriptionRefs.push(this.monitoringWorkService.updateManagementExpectations(this.wagon, pendingSymbols)
        .subscribe(refMatUpdates => {
          this.updateDone(refMatUpdates.data);
        })
      );
    }

  }

  /**
   * Methode to check errors and put in a List
   */
  checkIfExistControlError(): boolean {
    let message = null;
    this.messageError = '';
    this.errorList = [];
    let errors = false;
    this.refMaterialUpdateDS.data.forEach(element => {
      if (element.hasError('forbiddenValueRequired') && element.controls.added.value == true) {
        message = this.translateService.instant('wagon-update.monitoring-work.management-expectations.control_1');
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }
      if (element.hasError('forbiddenDuplicateSymbol')) {
        message = this.translateService.instant('wagon-update.monitoring-work.management-expectations.RG_MAJ_003_2_3');
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }
      if (element.hasError('forbiddenExpectedDate')) {
        message = this.translateService.instant('wagon-update.monitoring-work.management-expectations.RG_MAJ_003_2_1');
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }
      if (element.hasError('forbiddenReceiptDate')) {
        message = this.translateService.instant('wagon-update.monitoring-work.management-expectations.RG_MAJ_003_2_2');
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }
    });
    this.errorList.forEach(element => {
      this.messageError = this.messageError + element + '\n'
    })
    return errors;
  }

  /**
   * Le message se ferme quand 5 seconds sont pasé
   */
  updateDone(refMatUpdates: RefMaterialUpdate[]): void {
    this.loadingSubject.next(false);    
    this.isModifiedData = false;
    this.saved.emit(refMatUpdates);
    if( this.isMonitoringWorksFormDirty) {
      return;
    }
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    const title = this.translateService.instant('wagon-update.monitoring-work.management-expectations.update-done.msg');
    const message = this.translateService.instant('wagon-update.monitoring-work.management-expectations.update-done.msg');
    const timeout = UtilityService.UPDATE_DONE_TIMEOUT;
    dialogConfig.data = {
      namePopUp: 'update-done-alert',
      titlePopUp: title,
      msgPopUp: message
    };
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(x => {
      setTimeout(() => {
        // this.utilityService.setTaskUpdated(true);
        dialogRef.close();
        this.dialog.closeAll();
      }, timeout)
    })

  }

  initFilter(symbol: RefMaterial): void {
    this.filteredPatternList = this.filterWithCodeLabel(symbol, this.refMaterialList);
  }

  whenFn = (index, rowData: any): boolean => {
    return index < this.refMaterialUpdateDS.data.length - 1;
  }
  whenAddFn = (index, rowData: any): boolean => {
    return index === this.refMaterialUpdateDS.data.length - 1;
  }
}
