import { Component, forwardRef, HostListener, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { VpiService } from '../services/vpi.service';
import { MatDialog } from '@angular/material/dialog';
import { RemarkDialogComponent } from './remark-dialog/remark-dialog.component';
import { first } from 'rxjs/operators';
import { FilePreviewOverlayService } from 'src/app/shared/services/file-preview-overlay/file-preview-overlay.service';
import { Media } from 'src/app/shared/models/media';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { TrancheService } from '../../tranche/services/tranche.service';

@Component({
  selector: 'app-input-file2',
  templateUrl: './input-file2.component.html',
  styleUrls: ['./input-file2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFile2Component),
      multi: true
    }]
})
export class InputFile2Component implements ControlValueAccessor, OnInit {
  @Input() formControlName: string;
  @Input() parentForm: FormGroup;
  @Input() readonly: boolean;
  @Input() multiple = true;
  @Input() hasRemark = true;
  formArray: FormArray;
  isLoading = 0;
  
  private control: AbstractControl;
  datas: any;
  errors: string[];
  maxFileSize: number;
  maxRequestSize: number;

  constructor(
    private sanitizer: DomSanitizer,
    private vpiService: VpiService,
    private trancheService: TrancheService,
    private referenceService: ReferenceService,
    private dialog: MatDialog,
    private previewService: FilePreviewOverlayService) { }

  private propagateChange = (_: any) => { };
  private propagateTouch = (_: any) => { };

  ngOnInit(): void {
    if (!this.parentForm) {
      throw new Error('Form Array component must be a part of a form group');
    }
    // It ain't pretty but here we get access to the control and all of it's errors
    this.control = this.parentForm.get(this.formControlName);
    if (!this.control) {
      throw new Error('Form Array component must be a part of a form group');
    }
    this.referenceService.getServerUploadLimits().subscribe(r => {
      const maxFileSize = r.data.maxFileSize.toUpperCase();
      this.maxFileSize = maxFileSize.indexOf('KB') > -1 ? parseInt(maxFileSize.replace('KB', ''), 10) * 1024
        : parseInt(maxFileSize.replace('MB', ''), 10) * 1024 * 1024;
      const maxRequestSize = r.data.maxRequestSize.toUpperCase();
      this.maxRequestSize = maxRequestSize.indexOf('KB') > -1 ? parseInt(maxRequestSize.replace('KB', ''), 10) * 1024
        : parseInt(maxRequestSize.replace('MB', ''), 10) * 1024 * 1024;
      this.control.setValidators([this.validateSizes()]);
    });
  }

  validateSizes(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      let totalSize = 0;
      const exceedSizes = [];
      this.datas.forEach(d => {
        totalSize += d.file.size;
        if (d.file.size > this.maxFileSize) {
          exceedSizes.push(d.file);
        }
      });
      if (!exceedSizes.length && totalSize < this.maxRequestSize) {
        return null;
      }
      return exceedSizes.length ? { validateFileSize: exceedSizes } : { validateRequestSize: totalSize };
    };
  }

  writeValue(value: any): void {
    this.isLoading += value ? value.length : 0;
    if (value && value.length && !value[0].id) {
      const fl = [];
      value.map((x, index) => {
        const header = new Uint8Array(atob(x.data).split('').slice(0, 14).map(char => char.charCodeAt(0)));
        let signature = '';
        header.forEach(h => signature += h.toString(16));
        const byteArray = new Uint8Array(atob(x.data).split('').map(char => char.charCodeAt(0)));
        const f = new File([byteArray], x.fileName, { type: this.getMimetype(signature.toUpperCase()) });
        fl.push(f);
      });
      this.handleFiles(fl);
      return;
    }

    this.datas = value ? new Array(value.length) : [];
    const v = value ? value.map((x, index) => {
      if (x.trancheId) {
        this.trancheService.getPhoto(x.trancheId).subscribe(res => {
          if(res['error']) {
            this.datas = [];
            this.formArray.clear();
            this.errors = [...(this.errors || []) , ...[res['error']]];
            this.isLoading--;
          } else {
            this.datas[index] = {file: res};
            this.previewFile(res, index);
          }
        });
      } else {
        this.vpiService.getVpiAttachment(x).subscribe(res => {
          this.datas[index] = {file: res};
          this.previewFile(res, index);
        });
      }
      return new FormGroup({
        id: new FormControl(x.id),
        vpiId: new FormControl(x.vpiId),
        remark: new FormControl(x.remark),
        fileName: new FormControl(x.fileName)
      });
    }) : [];
    this.formArray = new FormArray(v);
    this.formArray.valueChanges.subscribe(res => {
      this.onChange(res);
    });
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /*setDisabledState?(isDisabled: boolean): void {
      throw new Error('Method not implemented.');
  }*/

  onChange(event?) {
    this.propagateChange(this.formArray.value);
    this.propagateTouch(this.formArray.value);
    this.setError();
  }

  private setError() {
    if (this.control.errors) {
      const errorKeys = Object.keys(this.control.errors).filter(x => !!x);
      const indexOfFileSizeError = errorKeys.indexOf('validateFileSize');
      if (indexOfFileSizeError > -1) {
        this.errors = this.control.errors[errorKeys[indexOfFileSizeError]]
          .map((e) => e.name + ' > ' + this.maxFileSize / 1024 / 1024 + ' Mo');
        return;
      }
      const indexOfRequestSizeError = errorKeys.indexOf('validateRequestSize');
      if (indexOfRequestSizeError > -1) {
        this.errors = ['Total = ' + (this.control.errors[errorKeys[indexOfRequestSizeError]] / 1024 / 1024).toFixed(2) + ' Mo > '
          + this.maxRequestSize / 1024 / 1024 + ' Mo'];
        return;
      }
    }
    this.errors = null;
  }

  @HostListener('dragenter', ['$event'])
  @HostListener('dragleave', ['$event'])
  @HostListener('dragover', ['$event'])
  onDrag(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  onDrop(e: DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    const dt = e.dataTransfer;
    const files = dt.files;
    this.handleFiles(files);
  }

  handleInput(e) {
    this.handleFiles(e.target.files);
  }

  handleFiles(files) {
    if (this.multiple || this.datas.length === 0) {
      this.isLoading += [...files].length;
      [...files].forEach((f: File) => {
        this.datas.push({ file: f });
        this.previewFile(f, this.datas.length - 1);
        this.formArray.push(new FormGroup({
          id: new FormControl(null),
          vpiId: new FormControl(null),
          remark: new FormControl(''),
          fileName: new FormControl(f.name),
          file: new FormControl(f)
        }));
      });
    }
  }

  async previewFile(file: File, index: number) {
    // if (await this.supports(file)) {
    const reader = new FileReader();
    reader.onloadend = _ => {
      this.datas[index].src = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result as string);
      this.isLoading--;
    };
    reader.readAsDataURL(file);
    /*} else {
        this.datas[index].src = null;
        this.isLoading--;
    }*/
  }

  removeFileAtIndex(index: number) {
    this.datas.splice(index, 1);
    this.formArray.removeAt(index);
  }
  /*
      public async supports(file: File): Promise<boolean> {
          if (file.type.indexOf('image') > -1 ) {
              return true;
          }
          let m: HTMLMediaElement;
          if (file.type.indexOf('audio') > -1 ) {
              m = document.createElement('audio');
          } else if (file.type.indexOf('video') > -1 ) {
              m = document.createElement('video');
          }
          if (!m || !m.play) { return false; }
          m.src  = URL.createObjectURL(file);
          return await m.play().then(() => { m.pause(); return true; }).catch((error) => false);
      }
  */
  setRemarkEnable(enable: boolean) {
    this.hasRemark = enable;
  }

  async openRemark(index: number) {
    const dialogRef = this.dialog.open(RemarkDialogComponent, {
      data: {
        remark: this.formArray.at(index).get('remark').value
      }
    });
    const remark = await dialogRef.afterClosed().pipe(first()).toPromise();
    if (remark !== false) {
      this.formArray.at(index).get('remark').setValue(remark);
    }
  }

  preview(index: number, embed: boolean) {
    const media = new Media();
    media.filename = this.datas[index].file.name;
    this.previewService.open({
      blobMediaList: [
        {
          blob: this.datas[index].file,
          media,
          src: embed ? this.datas[index].src : undefined
        }
      ]
    });
  }

  getMimetype(signature: string) {
    switch (signature.slice(0, 16)) {
      case '4D54686400000006':
        return 'audio/midi';
      default:
        switch (signature.slice(0, 14)) {
          case '526172201A0700':
            return 'application/x-rar-compressed';
          default:
            switch (signature.slice(0, 12)) {
              case '474946383761':
              case '474946383961':
                return 'image/gif';
              default:
                switch (signature.slice(0, 10)) {
                  case '3C3F786D6C':
                    return 'text/xml';
                  case '4F67675300':
                    return 'application/ogg';
                  case '255044462D':
                    return 'application/pdf';
                  default:
                    switch (signature.slice(0, 8)) {
                      case '89504E47':
                        return 'image/png';
                      case '52494646':
                        switch (signature.slice(16, 12)) {
                          case '574542505650':
                            return 'image/webp';
                          default:
                            switch (signature.slice(16, 8)) {
                              case '41564920':
                                return 'video/avi';
                              case '57415645':
                                return 'audio/wave';
                            }
                        }
                        break;
                      case '00000100':
                      case '00000200':
                        return 'image/x-icon';
                      case '464F524D':
                        switch (signature.slice(16, 8)) {
                          case '41494646':
                            return 'audio/aiff';
                        }
                        break;
                      default:
                        switch (signature.slice(0, 6)) {
                          case 'FFD8FF':
                            return 'image/jpeg';
                          case '494433':
                            return 'audio/mpeg';
                          case '1F8B08':
                            return 'application/x-gzip';
                          default:
                            switch (signature.slice(0, 4)) {
                              case '504B':
                                return 'application/zip';
                              case '424D':
                                return 'image/bmp';
                              default:
                                return 'application/octet-stream';
                            }
                        }
                    }
                }
            }
        }
    }
  }
}
