import { LineCategoryComposition } from './line-category-composition';
import { TransitRegimeComposition } from './transit-regime-composition';

/** API model:Table: ENVELOPPE_TC */
export class Envelope {
    /** ENVTC_ID_PK NUMBER(5,0) */
    id: number;
    /** lineCategoryComposition.id = ENVTC_COMPCLIG_ID_FK NUMBER(5,0) */
    lineCategoryComposition: LineCategoryComposition;
    /** transitRegimeComposition.id = ENVTC_COMPRACH_ID_FK NUMBER(5,0) */
    transitRegimeComposition: TransitRegimeComposition;
}
