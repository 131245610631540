import { MessageError } from './message-error';

export class CertificationCompleted {

  messageError: MessageError;

  mMessageBT: number;

  mMessageARES: number;

  mValidARES: number;

  mBtId: number;

  mNoticeId: number;

  mDevisIdExp: number;

  mDevisIdClient: number;

  mEtbIdSaisi: number;
}
