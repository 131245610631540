import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/services/app-api/api-response.model';
import { AppApiService } from 'src/app/core/services/app-api/app-api.service';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { ScaleComparator } from 'src/app/shared/models/scale-comparator';
import { ScaleMeta } from 'src/app/shared/models/scale-meta';
import { ScaleMetaComparator } from 'src/app/shared/models/scale-meta-comparator';
import { ScaleMetaType } from 'src/app/shared/models/scale-meta-type';
import { ScaleRule } from 'src/app/shared/models/scale-rule';
import { Tranche } from 'src/app/shared/models/tranche';

@Injectable({
  providedIn: 'root'
})
export class TrancheService {

  constructor(private api: AppApiService) { }

  getTranches(tranche: any, pageable?: Pageable): Observable<ApiResponse<Tranche[]>> {
    let parameters: HttpParams = new HttpParams();
    if (pageable !== undefined) {
      const pageParameters: HttpParams = pageable.build();
      pageParameters.keys().forEach(key => {
        parameters = parameters.set(key, pageParameters.get(key) );
      });
    }
    
    return this.api.post<ApiResponse<Tranche[]>>(`/tranches/search`, tranche, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getTranches', {data: []}))
    );
  }

  getScaleRulesByTranche(trancheId: number): Observable<ApiResponse<ScaleRule[]>> {
    return this.api.get<ApiResponse<Tranche[]>>(`/tranches/${trancheId}/scale-rules`)
    .pipe(
      catchError(this.handleError<any>('getScaleRulesByTranche', {data: []}))
    );
  }

  getScaleComparators(): Observable<ApiResponse<ScaleComparator[]>> {
    return this.api.get<ApiResponse<ScaleComparator[]>>(`/tranches/comparators`)
    .pipe(
      catchError(this.handleError<any>('getScaleComparators', {data: []}))
    );
  }

  getScaleMetas(): Observable<ApiResponse<ScaleMeta[]>> {
    return this.api.get<ApiResponse<ScaleMeta[]>>(`/tranches/metas`)
    .pipe(
      catchError(this.handleError<any>('getScaleMetas', {data: []}))
    );
  }

  getMetaComparatorRelations(): Observable<ApiResponse<ScaleMetaComparator[]>> {
    return this.api.get<ApiResponse<ScaleMetaComparator[]>>(`/tranches/comparators-metas`)
    .pipe(
      catchError(this.handleError<any>('getScaleMetaComparatorRelations', {data: []}))
    );
  }

  getMetaTypeRelations(isValid?: boolean): Observable<ApiResponse<ScaleMetaType[]>> {
    let parameters: HttpParams = new HttpParams();
    if (isValid !== undefined) {
      parameters = parameters.set('isValid', `${isValid}`);
    }
    return this.api.get<ApiResponse<ScaleMetaType[]>>(`/tranches/types-metas`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getScaleMetaTypeRelations', {data: []}))
    );
  }

  saveTranche(tranche: FormData, ungroup?: number): Observable<ApiResponse<Tranche>> {
    let parameters: HttpParams = new HttpParams();
    if (ungroup !== undefined) {
      parameters = parameters.set('ungroup', `${ungroup}`);
    }
    return this.api.post<ApiResponse<Tranche>>(`/tranches`, tranche, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('saveTranche', {data: null}))
    );
  }

  deleteTranche(id: number): Observable<Response> {
    return this.api.delete<Response>(`/tranches/${id}`, {observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('deleteTranche', {data: null}))
    );
  }

  updateTranche(id: number, tranche: FormData, group?: number): Observable<ApiResponse<Tranche>> {
    let parameters: HttpParams = new HttpParams();
    if (group !== undefined) {
      parameters = parameters.set('group', `${group}`);
    }
    return this.api.put<ApiResponse<Tranche>>(`/tranches/${id}`, tranche, {observe: 'body', params: parameters})
    .pipe(
      catchError(this.handleError<any>('updateTranche', {data: null}))
    );
  }

  saveTrancheRelations(trId: number, trancheComplete: Tranche): Observable<ApiResponse<Tranche>> {
    return this.api.post<ApiResponse<Tranche>>(`/tranches/${trId}/relations`, trancheComplete)
    .pipe(
      catchError(this.handleError<any>('saveTrancheRelations', {data: []}))
    );
  }

  saveRules(trId: number,rules: ScaleRule[]): Observable<ApiResponse<ScaleRule[]>> {
    return this.api.post<ApiResponse<ScaleRule[]>>(`/tranches/${trId}/rules`, rules)
    .pipe(
      catchError(this.handleError<any>('saveRules', {data: []}))
    );
  }

  downloadTrancheListPDF(tranche: Tranche) {
    return this.api.post<{blob: Blob, filename: string}>(`/tranches/pdf`, tranche, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadTrancheListPDF', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1]
          : 'Tranches.pdf'};
      })
    );
  }
  
  downloadTranchePDF(id: number) {
    return this.api.get<{blob: Blob, filename: string}>(`/tranches/${id}/pdf`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('downloadTranchePDF', null)),
      map((resp) => {
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return {blob: resp.body, filename: matches != null && matches[1] ? matches[1]
          : 'Tranche_'+ id + '.pdf'};
      })
    );
  }
  
  getPhoto(id: number): Observable<File> {
    return this.api.get<Blob>(`/tranches/${id}/photo`, {responseType: 'blob', observe: 'response'})
    .pipe(
      catchError(this.handleError<any>('getPhoto', {error: 'Erreur de téléchargement de l\'image de la tranche'})),
      map((resp) => {
        if (resp.error) {
          return resp;
        }
        const contentDisposition = resp.headers.get('Content-Disposition');
        const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
        return new File([resp.body], matches != null && matches[1] ? matches[1] : null, {type: resp.headers.get('content-type') });
      })
    );
  }
  
  getWagonCountByTranche(id: number, min?: string, max?: string): Observable<ApiResponse<number>> {
    let parameters: HttpParams = new HttpParams();
    if (min !== undefined) {
      parameters = parameters.set('min', `${min}`);
    }
    if (max !== undefined) {
      parameters = parameters.set('max', `${max}`);
    }
    return this.api.get<ApiResponse<ScaleMetaType[]>>(`/tranches/${id}/wagon-count`, { params: parameters })
    .pipe(
      catchError(this.handleError<any>('getWagonCountByTranche', {data: null}))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      return of(result as T);
    };
  }
}
