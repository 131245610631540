/** API model:Table:  PIECE_JOINTE */
export class Media {
  /** PJ_ID_PK	NUMBER(10,0) */
  id: number;
  /** PJ_NOM_FIC VARCHAR2(60 BYTE) */
  filename: string;
  /** PJ_LOC_FIC VARCHAR2(256 BYTE) */
  location: string;
  /** PJ_TAILLE	NUMBER(5,3) */
  size: number;
  /** PJ_DATE DATE */
  date: Date;
  /** Media content */
  content: File;
  /** Encoded media content */
  contentAsBase64: string;
  contentAsBlob: Blob;
}
