import { Anomaly } from './anomaly';
import { CamsDefault } from './cams-default';

export class ReducedUpdateValidate {

  wagonId: number;

  availabilityCode: string;

  observations: string;

  option1: string;

  option2: string;

  adviceARES: boolean;

  anomalies: Anomaly[];

  cams: CamsDefault[];

  hasExpertise: boolean;

  expertise: number;

  maintenanceOperation: string;

  interventionCause: string;

  imputation: string;

  interventionReason: string;

  interventionWorkshop: string;

  releaseDate: Date;

  entryDate: Date;

  dealCode: string;
}
