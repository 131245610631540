import { Component, Inject } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { Media } from '../../models/media';
import { saveAs } from 'file-saver';

export const FILE_PREVIEW_DIALOG_DATA = new InjectionToken<Blob>('FILE_PREVIEW_DIALOG_DATA');
export class FilePreviewOverlayRef {

  constructor(private overlayRef: OverlayRef) { }

  close(): void {
    this.overlayRef.dispose();
  }

  setEmbed(embed: boolean) {
    embed ? this.overlayRef.addPanelClass('embed') : this.overlayRef.removePanelClass('embed');
  }
}

@Component({
  selector: 'app-file-preview-overlay',
  templateUrl: './file-preview-overlay.component.html',
  styleUrls: ['./file-preview-overlay.component.scss']
})
export class FilePreviewOverlayComponent {
  showIndex = 0;
  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: FilePreviewOverlayRef,
    @Inject(FILE_PREVIEW_DIALOG_DATA) public blobMediaList:
      {blob: Blob, media: Media, title?: string, data?: SafeUrl, src?: SafeResourceUrl}[],
    /*@Inject(FILE_PREVIEW_DIALOG_INFO) public media: Media*/) {
      dialogRef.setEmbed(blobMediaList[0].src ? true : false);
      blobMediaList.forEach( (blobMedia) =>
        blobMedia.data = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(blobMedia.blob)));
  }

  downloadMedia(blobMedia: any) {
    saveAs(blobMedia.blob, blobMedia.media.filename);
  }
}
