import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-remark-dialog',
  templateUrl: './remark-dialog.component.html',
  styleUrls: ['./remark-dialog.component.scss']
})
export class RemarkDialogComponent {
  remark: string;
  /**
   * `DialogComponent` constructor
   * @param dialogRef reference to this component as a popable MatDialog
   * @param data Dialog injected data
   */
  constructor(
    public dialogRef: MatDialogRef<RemarkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.remark = data.remark;
  }
}
