import { Overlay } from '@angular/cdk/overlay';
import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { OpenWagonDialogComponent } from '../open-wagon/open-wagon-dialog/open-wagon-dialog.component';

@Component({
  selector: 'app-not-exist-wagon-alert',
  template: `
  <h5 mat-dialog-title>{{ data.titlePopUp }}</h5>
  <div mat-dialog-content>
    <div mat-dialog-body>
      <p>{{ data.msgPopUp }}</p>
    </div>

    <div mat-dialog-footer class="wrapper-right">
      <button type="button" class="btn btn-secondary button-center" mat-dialog-close>{{ 'wagon.button_dialog.cancel' | translate }}</button>
      <button type="button" class="btn btn-primary button-center" data-dismiss="modal" (click)="openWagonDialog();" >{{ 'wagon.button_dialog.continue' | translate }}</button>
    </div>
  </div>`,
  styles: [`
      .button-center {
        margin-left:  2%;
        margin-right: 2%;
        margin-top: 3%;
        margin-bottom: 3%;
    }
    .wrapper-right {
        text-align: right;
    }
  `],
  encapsulation: ViewEncapsulation.None
})
export class NotExistWagonAlertComponent {  
  /** langage du navigateur */
  lang : string;
  /** mex de la recherche */
  mex : string;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private overlay: Overlay,
    private translateService: TranslateService) {
      this.mex = data.mex;
      this.lang = this.translateService.getBrowserLang().match(/en|fr/)
                ? this.translateService.getBrowserLang() : 'en';
  }

  /** ConfirmCancellation OpeningWagon Dialog */
  confirmCancellation(): void {
    this.dialog.closeAll();
  }

 /**
 * Methode to show oenWagonWagon Dialog
 */
  openWagonDialog(): void {
    const scrollStrat = this.overlay.scrollStrategies.reposition();
    const dialogRef = this.dialog.open(OpenWagonDialogComponent
      , {
        panelClass: 'open-wagon-dialog',
      autoFocus : true,
      data : { mex: this.mex },
      disableClose : true,
      minWidth: '38rem',
      width: '50rem',
      maxWidth: '50rem',
      scrollStrategy : scrollStrat,
    });
  }

}
