import { ManagementType } from './management-type';
import { RU } from './ru';
import { Incident } from './incident';
import { Reform } from './reform';
import { GcuMedia } from './gcu-media';

/** API model:Table: ANOMALIE */
export class Anomaly {
    /** ANO_ID_PK NUMBER(10,0) */
    id: number;
    /** ANO_TYPE VARCHAR2(9 BYTE) */
    type: string;
    /** ANO_ANNEE_REF VARCHAR2(4 BYTE) */
    year: string;
    /** ANO_NUM NUMBER(10,0) */
    number: number;
    /** managementType.code = ANO_TYPGEST_CODE_FK VARCHAR2(2 BYTE) */
    managementType: ManagementType;
    /** ANO_TRAIN_NUM VARCHAR2(12 BYTE) */
    trainNumber: string;
    /** ANO_DH_EVT DATE */
    event: Date;
    /** ANO_DH_CREATION DATE */
    creation: Date;
    /** ANO_ETAT_WAG VARCHAR2(1 BYTE) */
    status: string;
    /** ANO_FLAG_AMORTISSEMENT NUMBER(1,0) */
    isWroteOff: boolean;
    /** ANO_FLAG_VU NUMBER(1,0) */
    isViewed: boolean;
    /** ANO_FLAG_CERTIF NUMBER(1,0) */
    isCertified: boolean;
    /** ANO_PERP_NIV2_KMF NUMBER(10,0) */
    kmsFrance: number;
    /** ANO_PERP_NIV2_NTF NUMBER(10,0) */
    transportsFrance: number;
    /** ANO_PERP_NIV2_NTVF NUMBER(10,0) */
    emptyTransportsFrance: number;
    /** ANO_FLAG_PRES_FRANCE NUMBER(1,0) */
    isInFrance: boolean;
    /** ANO_DH_SORTIE_FRANCE DATE */
    franceExit: Date;
    /** ANO_DH_DERN_MAJ DATE */
    lastUpdate: Date;
    /** ru.code = ANO_EFU_CODE_FK VARCHAR2(4 BYTE) */
    ru: RU;
    /** ANO_CODE_AMORTISSEMENT VARCHAR2(6 BYTE) */
    wroteOffCode: string;
    /** ANO_MOTIVATION VARCHAR2(50 BYTE) */
    motivation: string;
    /** ANO_FLAG_MAJ NUMBER(1,0) */
    isUpdated: boolean;
    /** ANOMALIE.ANO_ID_PK = INCIDENT.INC_ANO_ID_PFK */
    incident: Incident;
    /** ANOMALIE.ANO_ID_PK = REFORME.RFM_ANO_ID_PFK */
    reform: Reform;
    /** T_DEFAUT.IDEF_CODE_PK= R_ANO_IDEF.ANOIDEF_IDEF_CODE_PFK (gcuList[x].code)
     * AND R_ANO_IDEF.ANOIDEF_ANO_ID_PFK = ANOMALIE.ANO_ID_PK
     */
    gcuMediaList: GcuMedia [];
    /** ANO_MEX VARCHAR2(14 BYTE) */
    mex: string;
    /** ANO_WPHY_ID_FK NUMBER(10) */
    wagonId: number;
}
