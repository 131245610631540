import { Accelerator } from './accelerator';

/** API model:Table: T010_VALVE_PURGE */
export class PurgeValve {
    /** VALPUR_CODE_PK VARCHAR2(1 BYTE) */
    code: string;
    /** VALPUR_LIB VARCHAR2(36 BYTE) */
    label: string;
    /** accelerator.code = VALPUR_ACCEL_CODE_FK VARCHAR2(1 BYTE) */
    accelerator: Accelerator;
}
