
export class CertificationValidate {

  wagonId: number;

  exitDate: Date;

  exitTime: string;

  dealCode: string;

  availabilityCode: string;

  reasonCode: string;

  observations: string;

  option1: string;

  option2: string;

  adviceARES: boolean;

  mexToCertify: string;
}
