/** API model:Table: T084_REG_ACHEMINEMENT */
export class TransitRegime {
    /** RACH_CODE_PK VARCHAR2(5 BYTE) */
    code: string;
    /** RACH_LIB VARCHAR2(360 BYTE) */
    label: string;
    /** RACH_ORDRE NUMBER(3,0) */
    order: number;
    /** RACH_FLAG_1ERE_LIGNE NUMBER(1,0) */
    isFirstLineAuthorized: boolean;
    /** RACH_FLAG_NORMAL NUMBER(1,0) */
    hasNormalArray: boolean;
    /** RACH_FLAG_DEROG NUMBER(1,0) */
    hasDerogatoryArray: boolean;
    /** RACH_VIT_REF NUMBER(3,0) */
    referenceSpeed: number;
}
