import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BarConfigService } from './services/bar-config/bar-config.service';
import { APP_HEADER_CONFIG, APP_NAV_ITEMS } from './index';
import { MastHeaderConfig } from './components/mast-header/mast-header-config';
import { MastNavConfig } from './components/mast-nav/mast-nav-config';
import { ControlBarConfig } from './components/control-bar/control-bar-config';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router, ResolveEnd, NavigationStart, ResolveStart, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import { WagonService } from '../shared/services/wagon/wagon.service';
import { TagReadService } from '../shared/services/tag-read/tag-read.service';
import { MatDialog } from '@angular/material/dialog';
import { AgentService } from './services/agent/agent.service';
import { ProfilDialogComponent } from './components/mast-header/profil-dialog/profil-dialog.component';

/** `CoreComponent` create the application layout */
@Component({
  selector: 'app-core',
  styleUrls: ['./core.component.scss'],
  templateUrl: './core.component.html',
  encapsulation : ViewEncapsulation.None
})
export class CoreComponent implements OnInit, OnDestroy {
  /** Mastheader configuration */
  headerConfig: MastHeaderConfig = APP_HEADER_CONFIG;
  /** Mastnav configuration */
  navConfig: MastNavConfig = APP_NAV_ITEMS;
  /** Whether page has an Actionbar */
  hasActionBar: boolean;
  /** Whether Actionbar has tabs */
  actionBarHasTabs: boolean;
  /** Controlbar configuration */
  controlBarConfig: ControlBarConfig;
  /** Retain all subscription */
  subscriptionRefs: Subscription[] = [];
  /** Whether wagon module is shown */
  showWagon = false;
  /** loading status */
  isLoading: boolean;
  /**  class to apply to actionbar */
  actionBarClass: string;
  /** Whether app is in fullscreen mode */
  fullscreen = false;
  flags = new BehaviorSubject<Map<String, boolean>>(new Map<String, boolean>());
  mhClass = '';
  /**
   * CoreComponent constructor
   * Set the subscription on actionBarConfig & controlBarConfig
   * @param barConfigService Inject service to resolve App's Bars configuration update
   * @param wagonService Inject service to retrieve wagon datas
   * @param translate Inject application's translate service
   * @param titleService Title service to set header title tag
   * @param router Angular router
   */
  constructor(
    private barConfigService: BarConfigService,
    private wagonService: WagonService,
    private tagReadService: TagReadService,
    private translate: TranslateService,
    private titleService: Title,
    private dialog: MatDialog,
    private agentService: AgentService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    this.headerConfig.actionList.push({ label: 'core.menu.profile.title', callback: () => this.showProfil()});

    
    this.subscriptionRefs.push(
      barConfigService.getControlBarConfig().subscribe(x => this.controlBarConfig = x)
    );
  }
  /**
   * OnInit hook set page title globally
   */
  ngOnInit() {
    if (window.location.hostname.includes('developpement')) {
      this.mhClass = 'dev';
    } else if(window.location.hostname.includes('recette')) {
      this.mhClass = 'rec';
    } else if(window.location.hostname.includes('integration')) {
      this.mhClass = 'int';
    } else if(window.location.hostname.includes('localhost')) {
      this.mhClass = 'loc';
    }
    this.subscriptionRefs.push(
      this.barConfigService.getActionBarConfig().subscribe(x => {
        this.hasActionBar = x !== undefined;
        this.actionBarClass = x !== undefined && x.class !== undefined ? x.class : undefined;
        this.actionBarHasTabs = x !== undefined && x.tabs !== undefined;
        this.cdRef.detectChanges();
      })
    );
    this.subscriptionRefs.push(
      this.router.events
      .pipe(
        filter(event => event instanceof ResolveEnd ||  event instanceof NavigationStart
          || event instanceof ResolveStart || event instanceof NavigationCancel)
      )
      .subscribe((event: ResolveEnd | NavigationStart | ResolveStart | NavigationCancel) => {
        if (event instanceof ResolveEnd) {
          this.isLoading = false;
          let child = event.state.root;
          let path = '';
          while (child !== null) {
            path += child.routeConfig ? '.' + child.routeConfig.path : '';
            child = child.firstChild;
          }
          const title = this.translate.instant('title' + path );
          this.titleService.setTitle(title === 'title' + path ? 'MARGO' : title);
          /*this.subscriptionRefs.push(this.translate.onDefaultLangChange
            .subscribe(() => this.titleService.setTitle(this.translate.instant('title' + path ))));*/
        }
        if (event instanceof NavigationStart) {
          this.isLoading = true;
        }
        if (event instanceof NavigationCancel) {
          this.isLoading = false;
        }
        if (event instanceof ResolveStart) {
          this.wagonService.setDisplayStatus(false);
          this.wagonService.setCurrentPortalTemplates(null);
          this.barConfigService.clearConfig();
        }
      })
    );

    this.subscriptionRefs.push(
      this.wagonService.getDisplayStatus()
      .subscribe(status => {
        this.showWagon = status;
      })
    );
  }

  /**
   * OnDestroy hook
   * unsubscribe subscriptions
   */
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscriptionRefs.forEach((s) => /* {if (s && !s.closed) {*/ s.unsubscribe() /*}}*/);
  }

  showProfil() {
    const dialogRef  = this.dialog.open(ProfilDialogComponent, {
      data: { agent: this.agentService.getAgentData().value}
    });
    
    dialogRef.componentInstance.instr.subscribe(instr => {
      this.flags.next(this.flags.getValue().set(instr, !this.flags.getValue().get(instr)));
    });
  }
}
