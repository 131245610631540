import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Title} from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreComponent } from './core.component';
import { CoreRoutingModule } from './core-routing.module';
import { SharedModule } from '../shared/shared.module';
// import { DemoModule } from '../modules/demo/demo.module';
import { HasRoleDirective } from './services/auth/has-role.directive';
import { AppApiService } from './services/app-api/app-api.service';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { AuthService } from './services/auth/auth.service';
import { LoggerService } from './services/logging/logger.service';
import { StorageService } from './services/storage/storage.service';
import { httpInterceptorProviders } from './services/http-interceptors';
import { errorHandlerProviders } from './services/error-handlers';

import { MastNavComponent } from './components/mast-nav/mast-nav.component';
import { MastHeaderComponent } from './components/mast-header/mast-header.component';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { ControlBarComponent } from './components/control-bar/control-bar.component';
import { LoginComponent } from './components/login/login.component';
import { Error401Component } from './components/error-pages/401/error-401.component';
import { Error403Component } from './components/error-pages/403/error-403.component';
import { Error404Component } from './components/error-pages/404/error-404.component';
import { Error500Component } from './components/error-pages/500/error-500.component';
import { SessionExpiredComponent } from './components/error-pages/session-expired/session-expired.component';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { FooterComponent } from './components/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AgentService } from './services/agent/agent.service';
import { SiteMapComponent } from './components/site-map/site-map.component';
import { FaqComponent } from './components/faq/faq.component';
import { ExpertisesComponent } from './components/dashboard/expertises/expertises.component';
import { IndicatorsComponent } from './components/dashboard/indicators/indicators.component';
import { ReferencialComponent } from './components/dashboard/referencial/referencial.component';
import { ProdWagonComponent } from './components/dashboard/prod-wagon/prod-wagon.component';
import { MissingMaterialsComponent } from './components/dashboard/missing-materials/missing-materials.component';
import { PortalModule} from '@angular/cdk/portal';
import { NgxMaskModule } from 'ngx-mask';
import { MessagesComponent } from './components/dashboard/messages/messages.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { WebBluetoothModule } from '@manekinekko/angular-web-bluetooth';
import { ProfilDialogComponent } from './components/mast-header/profil-dialog/profil-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    WebBluetoothModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule.forChild(),
    NgxMaskModule.forChild(),
    PortalModule,
    AngularEditorModule,
    CoreRoutingModule
  ],
  declarations: [
    MastNavComponent,
    MastHeaderComponent,
    ActionBarComponent,
    ControlBarComponent,
    LoginComponent,
    Error401Component,
    Error403Component,
    Error404Component,
    Error500Component,
    SessionExpiredComponent,
    HomeComponent,
    AboutComponent,
    FooterComponent,
    DashboardComponent,
    HasRoleDirective,
    CoreComponent,
    SiteMapComponent,
    FaqComponent,
    ExpertisesComponent,
    IndicatorsComponent,
    ReferencialComponent,
    ProdWagonComponent,
    MissingMaterialsComponent,
    MessagesComponent,
    ProfilDialogComponent
  ],
  providers: [
    AuthGuardService,
    AppApiService,
    AuthService,
    LoggerService,
    Title,
    StorageService,
    httpInterceptorProviders,
    errorHandlerProviders,
    AgentService
  ],
  exports: [
    CoreComponent,
    HasRoleDirective
  ]
})
export class CoreModule {
}
