import { Region } from './region';
import { Workshop } from './workshop';

/** API model:Table: INCIDENT */
export class Incident {
    /** INC_HINTV_ID_FK NUMBER(10,0) */
    interventionId: number;
    /** region.code = INC_REGION_CODE_FK VARCHAR2(2 BYTE) */
    region: Region;
    /** INC_FICHE_EVT_NUM VARCHAR2(13 BYTE) */
    eventSheetNumber: string;
    /** INC_LIEU VARCHAR2(30 BYTE) */
    location: string;
    /** INC_RETARD_TRAIN VARCHAR2(4 BYTE) */
    trainDelay: string;
    /** INC_PSEUDO_KART_ORIG VARCHAR2(2 BYTE) */
    originPseudoKART: string;
    /** INC_TYPE_ORIG VARCHAR2(70 BYTE) */
    originType: string;
    /** INC_FLAG_EVT_CRITIQUE_ORIG NUMBER(1,0) */
    hasOriginCriticalEvent: boolean;
    /** INC_DH_RECEPTION_FIN DATE */
    endReceipt: Date;
    /** INC_DOMAINE_ORIG VARCHAR2(1 BYTE) */
    originDomain: string;
    /** INC_TYPE_FIN VARCHAR2(70 BYTE) */
    endType: string;
    /** INC_FLAG_EVT_CRITIQUE_FIN NUMBER(1,0) */
    hasEndCriticalEvent: boolean;
    /** INC_DH_FIN DATE */
    end: Date;
    /** INC_PSEUDO_KART_FIN VARCHAR2(2 BYTE) */
    endPseudoKART: string;
    /** INC_ORGANE_FAMILLE_FIN VARCHAR2(1 BYTE) */
    endComponentFamily: string;
    /** INC_ORGANE_MATERIEL_FIN VARCHAR2(3 BYTE) */
    endComponentMaterial: string;
    /** INC_ANO_CODE_FIN VARCHAR2(1 BYTE) */
    endAnomalyCode: string;
    /** INC_RESPONS_FIN VARCHAR2(1 BYTE) */
    endCode: string;
    /** INC_DH_CREATION_ORIG DATE */
    origin: Date;
    /** INC_CAUSE_FIN VARCHAR2(66 BYTE) */
    endReason: string;
    /** interventionWorkshop.code = HINTV_ETAB_CODE_UG_FK where HISTO_INTERV.HINTV_ID_PK = INC_HINTV_ID_FK */
    interventionWorkshop: Workshop;
}
