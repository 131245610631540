/**
 * Liste des niveaux de log possible
 */
export enum LoggerLevel {
  TRACE = 0,
  DEBUG = 1,
  INFO = 2,
  WARN = 3,
  ERROR = 4,
  DISABLE = 5
}
