import { Component, OnInit } from '@angular/core';

/** Template of an error 404 page */
@Component({
  selector: 'app-error-404',
  templateUrl: './error-404.component.html',
  styleUrls: ['./error-404.component.scss']
})
export class Error404Component implements OnInit {
  /**
   * `Error404Component` constructor
   */
  constructor() {}
  /** OnInit hook  */
  ngOnInit() {
  }
}
