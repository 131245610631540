import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from '../services/dashboard.service';
import { ReferentialUpdate } from 'src/app/shared/models/referential-update';
import { Subscription } from 'rxjs/internal/Subscription';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { TranslateService } from '@ngx-translate/core';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-referencial',
  templateUrl: './referencial.component.html',
  styleUrls: ['./referencial.component.scss']
})
export class ReferencialComponent implements OnInit , OnDestroy {

    /** Whether datas are still loading */
    isLoadingResults = true;
    /** Media Query list to check against for detect medium screens */
    mobileQueryMedium: MediaQueryList;
    /** Retain all subscriptions */
    subscriptionRefs: Subscription[] = [];
    /** Data */
    referentialsUpdate: ReferentialUpdate[];
    hasData: boolean;
    /** browser language */
    lang: string;

  constructor(
    private dashboardService: DashboardService,
    private translateService: TranslateService,
    private media: MediaMatcher) {
      this.lang = this.translateService.getBrowserLang().match(/en|fr/)
                ? this.translateService.getBrowserLang() : 'en';
      this.mobileQueryMedium = this.media.matchMedia('(max-width: 1200px)');
    }

  ngOnInit() {
    const pageable = new Pageable();
    pageable.sort = 'date';
    pageable.order = 'desc';
    this.subscriptionRefs.push(
      this.dashboardService.getReferentialUpdate(pageable)
      .subscribe(referentialsUpdates => {
        this.referentialsUpdate = referentialsUpdates.data;
        this.hasData = this.referentialsUpdate.length !== 0;
        this.isLoadingResults = false;
      }));
  }

/** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
  }

}
