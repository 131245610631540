import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AppApiService } from '../app-api/app-api.service';
import { catchError } from 'rxjs/operators';
import { ApiResponse } from '../app-api/api-response.model';
import { PcMessage } from 'src/app/shared/models/pc-message';

@Injectable({
  providedIn: 'root'
})
export class PcMessageService {
  /**
   * PcMessageService constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) {  }

  public getUserMessages(): Observable<ApiResponse<PcMessage[]>> {
    return this.api.get<ApiResponse<PcMessage[]>>(`/user-messages`)
    .pipe(
      catchError(this.handleError<any>('getUserMessages', {data: []}))
    );
  }

  public getUserMessagesBanner(): Observable<ApiResponse<PcMessage[]>> {
    return this.api.get<ApiResponse<PcMessage[]>>(`/user-messages-banner`)
    .pipe(
      catchError(this.handleError<any>('getUserMessagesBanner', {data: []}))
    );
  }

  public getPcMessages(workshopCode: string): Observable<ApiResponse<PcMessage[]>> {
    return this.api.get<ApiResponse<PcMessage[]>>(`/dashboard/${workshopCode}/pc-messages`)
    .pipe(
      catchError(this.handleError<any>('getPcMessage', {data: []}))
    );
  }

  public getPcMessagesByGroup(workshopGroupCode: string): Observable<ApiResponse<PcMessage[]>> {
    return this.api.get<ApiResponse<PcMessage[]>>(`/dashboard/group/${workshopGroupCode}/pc-messages`)
    .pipe(
      catchError(this.handleError<any>('getPcMessage', {data: []}))
    );
  }

  public savePcMessage(message: PcMessage): Observable<ApiResponse<PcMessage>> {
    return this.api.post<ApiResponse<PcMessage>>(`/dashboard/pc-messages`, message)
    .pipe(
      catchError(this.handleError<any>('savePcMessage', {data: null}))
    );
  }
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      // console.error(operation, error); // log to console instead
      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
