import { Component, OnInit, Input, ViewEncapsulation, OnDestroy} from '@angular/core';
import { InputConfig } from '../../../shared/components/form-input/form-input.component';
import { MastHeaderConfig } from './mast-header-config';
import { AuthService } from '../../services/auth/auth.service';
import { AgentService } from '../../services/agent/agent.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Agent } from 'src/app/shared/models/agent';
import { WagonService } from 'src/app/shared/services/wagon/wagon.service';
import { HttpClient } from '@angular/common/http';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PcMessageService } from '../../services/pc-message/pc-message.service';
import { first } from 'rxjs/operators';

/**
 * `MastHeaderComponent` provide an application configurable `mastheader` element
 *
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'header.mastheader',
  templateUrl: './mast-header.component.html',
  styleUrls: ['./mast-header.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations: [
    trigger('scroll', [
      state('on', style({ transform: 'translate3d(100%,0,0)' })),
      transition('* => *', [
        style({ transform: 'translate3d(100%,0,0)' }),
        animate(20000, style({transform: 'translate3d(-100%,0,0)'}))
      ])
    ]),
    
    trigger('scrollV', [
      state('on', style({ transform: 'translate3d(0,100%,0)' })),
      transition('* => *', [
        style({ transform: 'translate3d(0,100%,0)' }),
        animate(20000, style({transform: 'translate3d(0,-100%,0)'}))
      ])
    ])
  ]
})
export class MastHeaderComponent  implements  OnInit, OnDestroy {
  
  userMessages: SafeHtml[] = [];
  /** Input configuration  */
  @Input() config: MastHeaderConfig;
  @Input() flags: BehaviorSubject<Map<String, boolean>>;
  /** Model to submit to search service */
  searchModel: string;
  /** Whether the searchbar is active. */
  searchBarActive = false;
  /** Search element placeholder. */
  searchPlaceholder = '';
  /** Retain all subscriptions */
  subscriptionRefs: Subscription[] = [];
  /** Provided InputConfig to search FormInputComponent. */
  searchConfig: InputConfig = {
    // labelExtraClass: 'text-white pr-3 mb-0',
    append: {
      type: 'button',
      config: {
        icon_class: 'icons-search',
        callback: this.search
      }
    }
  };
  notificationList: any[] = [];
  agent: Agent;
// TODO SearchService, NotificationServcie
  currentId;
  /**
   * MastHeaderComponent constructor
   * @param authService Inject application's authentication service
   * @param agentService Inject agent datas service
   */
  values = [];
  keys = [];
  message: string;
  msgSub: Subscription;
  handler;
  state = 0;
  suffix = '';
  constructor(private http: HttpClient,
    public authService: AuthService,
    public agentService: AgentService,
    private wagonService: WagonService,
    private pcMessageService: PcMessageService,
    private sanitizer: DomSanitizer) { }

  /**
   * OnInit hook
   */
  ngOnInit() {
    if (window.location.hostname.includes('developpement')) {
      this.suffix = 'DEV';
    } else if(window.location.hostname.includes('recette')) {
      this.suffix = 'REC';
    } else if(window.location.hostname.includes('integration')) {
      this.suffix = 'INT';
    } else if(window.location.hostname.includes('localhost')) {
      this.suffix = 'LOCAL';
    }
    
    this.getNotifications();
    this.wagonService.getSharedDatas().subscribe( datas => {
      this.currentId = datas?.wagon?.id;
      if (this.currentId && !this.keys.includes(this.currentId)) {
        this.values = [...this.values, datas.logicalWagon?.mex];
        this.keys = [...this.keys, this.currentId];
      }
    });
    this.flags.subscribe( flags => {
      clearTimeout(this.handler);
      if (this.msgSub && !this.msgSub.closed) {this.msgSub.unsubscribe();}
      if( flags.get("KAAM")) {
        this.kaam ();
      } else if( flags.get("OSS")) {
        this.oss ();
      } else if( flags.get("CHUCK")) {
        this.chuckn ();
      }
    })
  }
  kaam() {
    this.message = "";
    this.msgSub = this.http.get<any>('/api/random', {responseType: 'json'}).subscribe( res => {
      if(res?.status) {
        this.message = res.citation.citation;
        this.handler = setTimeout(_=> this.kaam(), 20000);
      }
    });
  }
  oss() {
    this.message = "";
    this.msgSub = this.http.get<any>('/v1/random', {responseType: 'json'}).subscribe( res => {
      if(res) {
        this.message = res.sentence;
        this.handler = setTimeout(_=> this.oss(), 20000);
      }
    });
  }
  chuckn() {
    this.message = "";
    this.msgSub = this.http.get<any>('/api/rand', {responseType: 'json'}).subscribe( res => {
      if(res) {
        this.message = res.joke;
        this.handler = setTimeout(_=> this.chuckn(), 20000);
      }
    });
  }
  userMsg(msg) {
   //setTimeout(_=> {
    this.message = msg;
  //} , 0);
    this.handler = setTimeout(_=> {this.message ='';this.userMsg(msg);} , 20000);
  }
  /** search function
   * @param e click event
   */
  search() {
    this.config.searchService.search(this.searchModel);
  }
  /** string capitalization
   * @param s the string to capitalize
   * @returns the capitalized string
   */
  capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }

  getNotifications() {
    this.subscriptionRefs.push(
      this.agentService.getAgentData().subscribe(async agent => {
        this.agent = agent;
        if (agent) {
          const msgs = await this.pcMessageService.getUserMessagesBanner().pipe(first()).toPromise();
          this.userMessages = msgs.data.filter(m => m.label !== null).map( e => this.sanitizer.bypassSecurityTrustHtml(e.label));
          const msg = msgs.data.reduce( (p, c) => (p.length ? p + ', ' : '') + c.label, '');
          if (msg) {
            this.userMsg(msg);
          }
          /*const msgs = await forkJoin(
            this.notificationService.getNotifications('99999'),
            this.notificationService.getNotifications(agent.company.code)
          ).pipe(first()).toPromise();*/
         //this.notificationList = this.userMessages;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
  } 
  remove(index) {
    this.values.splice(index,1);
    this.keys.splice(index,1);
    this.wagonService.setCurrentWagonId(null);
  }
  selectWagon(id) {
    if (this.currentId !== id) {
      this.wagonService.setCurrentWagonId(id);
    } else {
      this.wagonService.setCurrentWagonId(null);
    }
  }

  scrollDone() {
    this.message = '';
    this.state++;
  }
}


