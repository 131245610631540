import { DrawHook } from './draw-hook';
import { WagonLocation } from './wagon-location';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_CROCH */
export class WagonDrawHook {
    /** WCROCH_WCAR_MEX_PFK VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = WCROCH_WCAR_INV_CODE_PFK VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** drawHook.code = WCROCH_CROCH_CODE_PFK VARCHAR2(2 BYTE) */
    drawHook: DrawHook;
    /** location.code = WCROCH_EMPL_CODE_FK VARCHAR2(3 BYTE) */
    location: WagonLocation;
    /** WCROCH_NBRE NUMBER(3,0) */
    amount: number;
}
