import { Component, OnInit, ViewChild,
  ViewContainerRef, OnDestroy, ComponentRef, Injector, ViewChildren, ElementRef, QueryList, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { ActionBarConfig } from '../action-bar/action-bar-config';
import { BreadCrumb } from '../action-bar/breadcrumb';
import { BarConfigService } from '../../services/bar-config/bar-config.service';
import { Subscription } from 'rxjs';
import { ActionBarAdComponent } from './action-bar-ad-component';
import { ActionBarAdItem } from './action-bar-ad-item.model';
import { first } from 'rxjs/operators';
import { ThemePalette } from '@angular/material/core';

/** Array of breadcrumbs without link */
const noLinkBreadcrumbList: Array<string> = ['consultation', 'update', 'administration'];
/**
 * `ActionBarComponent` provide an application configurable `actionbar` element
 *
 */
@Component({
  selector: 'app-actionbar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss']
})
export class ActionBarComponent implements OnInit, AfterViewInit, OnDestroy {
  /** ActionBar Configuration  */
  config: ActionBarConfig;
  /** View reference to subtitle ad */
  @ViewChild('subtitle', {read: ViewContainerRef, static: true}) subtitle: ViewContainerRef;
  /** View reference to custom ad  */
  @ViewChild('custom', {read: ViewContainerRef, static: true}) custom: ViewContainerRef;
  /** View reference to buttons  */
  @ViewChildren('actionButton') buttons: QueryList<ElementRef<HTMLButtonElement>>;
  /** Reference for injected subtitle component which  implements ActionBarAdComponent */
  subtitleComponentRef: ComponentRef<any>;
  /** Reference for injected custom component which  implements ActionBarAdComponent */
  customComponentRef: ComponentRef<any>;
  /** Array of breadcrumb parts */
  breadcrumbs: BreadCrumb[];
  /** Retain actionbar subscription */
  barServiceSubscription: Subscription;
  /** Retain tab change subscription */
  tabChangeSubscription: Subscription;
  /** Retain timeout descriptor */
  loadingComponentsTimeout;
  /** Injector for ActionBarAdItem component  */
  injector: Injector;
  /** active tab index */
  activeTab = 0;
  /**
   * ActionBarComponent constructor
   * @param activatedRoute Angular router component's activated route to construct breadcrumb
   * @param translate Inject application's translate service
   * @param location Angular location used to navigate back
   * @param barConfigService Inject service to resolve App's Bars configuration update
   */
  constructor(
    private translate: TranslateService,
    private router: Router,
    public location: Location,
    private barConfigService: BarConfigService) { }

  /**
   * OnInit hook
   * Initialize breadcrumbs
   */
  ngOnInit() {
    this.barServiceSubscription = this.barConfigService.getActionBarConfig()
      .subscribe(conf => {
        if ( conf !== undefined) {
          setTimeout(() => {
            this.buildBreadCrumb();
            }, 100);
          this.config = conf;
          this.loadComponents();
        }
    });
    this.tabChangeSubscription = this.barConfigService.selectedTabChanged.subscribe( (idx) => this.activeTab = idx );
  }
  /**
   * AfterViewInit hook
   */
  ngAfterViewInit() {
    this.barConfigService.setActionsRef(this.buttons.toArray());
  }
  /**
   * Function to build the breadcrumb array from activated route
   * Each breadcrumb part must be registered by i18n file as 'controls.breadcrumb.{path for the child}'
   * @param route Angular router component's activated route
   * @returns The actual breadcrumb array to display for this component route
   */
  async buildBreadCrumb() {
    let newBreadcrumbs: Array<BreadCrumb> = [];
    let nextUrl = '';
    let child = this.router.routerState.root;
    while (child) {
      if (child.routeConfig && this.config.breadcrumbParams ) {
        for(let key of Object.keys(this.config.breadcrumbParams)) {
          if (child.routeConfig.path.includes(':' + key)) {
            const label = this.config.breadcrumbParams[key];
            const params = await child.params.pipe(first()).toPromise();
            let path = params[key];
            const breadcrumb = {
              label,
              url: `${nextUrl}${path}/`
            };
            newBreadcrumbs = [ ...newBreadcrumbs, breadcrumb ];
          }
        }
      }
      const label = await (child.routeConfig ?
            this.translate.get('controls.breadcrumb.' + child.routeConfig.path)
            : this.translate.get('controls.breadcrumb.home')).pipe(first()).toPromise();
      let path = child.routeConfig ? child.routeConfig.path : '';
      const params = await child.params.pipe(first()).toPromise();
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          path = path.replace(`:${key}`, params[key]);
        }
      }
      if (path === '' && child.firstChild === null) {
        this.breadcrumbs =  newBreadcrumbs;
        return;
      }
      nextUrl = `${nextUrl}${path}/`;
      const breadcrumb = {
        label,
        url: (noLinkBreadcrumbList.indexOf(path) > -1 ) ? '' : nextUrl
      };
      newBreadcrumbs = [ ...newBreadcrumbs, breadcrumb ];
      child = child.firstChild;
    }
    this.breadcrumbs =  newBreadcrumbs;
  }

  /**
   * inject dynamic components in actionbar according to actionBarConfig subtitle & custom properties
   */
  loadComponents() {
    this.clearSubtitleComponent();
    this.clearCustomComponent();
    if (this.config.ads) {
      // Inject subtitle Component
      if (this.subtitle && this.config.ads.subtitle ) {
        const adItem: ActionBarAdItem = this.config.ads.subtitle;
        // create component & bind new datas;
        const childComponent  = this.config.ads.resolver.resolveComponentFactory(adItem.component);
        this.injector = Injector.create(
          {providers: [{provide: adItem.component, useValue: adItem.component}], parent: this.config.ads.injector}
        );
        this.subtitleComponentRef = this.subtitle.createComponent(childComponent , 0, this.injector);
        (this.subtitleComponentRef.instance as ActionBarAdComponent).data = adItem.data;
      }
      // Inject custom Component
      if ( this.custom && this.config.ads.custom ) {
        const adItem: ActionBarAdItem = this.config.ads.custom;
        const childComponent  = this.config.ads.resolver.resolveComponentFactory(adItem.component);
        this.injector = Injector.create(
          {providers: [{provide: adItem.component, useValue: adItem.component}], parent: this.config.ads.injector}
        );
        this.customComponentRef = this.custom.createComponent(childComponent , 0, this.injector );
        (this.customComponentRef.instance as ActionBarAdComponent).data = adItem.data;
      }
    }
  }
  /** clear subtitle ViewContainerRef & destroy ComponentRef */
  clearSubtitleComponent() {
    this.subtitle.clear();
    if (this.subtitleComponentRef) { this.subtitleComponentRef.destroy();
    }
  }
  /** clear custom ViewContainerRef & destroy ComponentRef */
  clearCustomComponent() {
    this.custom.clear();
    if (this.customComponentRef) { this.customComponentRef.destroy(); }
  }
  /**
   * Emit selectedTabChanged event when the selected tab changes
   */

  OnTabClick(index: number) {
    this.barConfigService.selectedTabChanged.emit(index);
  }
  /**
   * OnDestroy hook
   * unsubscribe subscriptions, clear timeout & components
   */
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.barServiceSubscription && !this.barServiceSubscription.closed) {
      this.barServiceSubscription.unsubscribe();
    }
    if (this.tabChangeSubscription && !this.tabChangeSubscription.closed) {
      this.tabChangeSubscription.unsubscribe();
    }
    clearTimeout(this.loadingComponentsTimeout);
    this.clearSubtitleComponent();
    this.clearCustomComponent();
  }
  /**
   * Navigate to previous browser history location
   */
  goBack(): void {
    if (this.config.goBack !== undefined) {
      this.config.goBack();
      return;
    }
    this.location.back();
  }

  getColor(color: string): ThemePalette {
    return (['primary', 'accent', 'warn'].includes(color) ? color : undefined) as ThemePalette;
  }
}
