import { Injectable } from '@angular/core';
import { Components } from '../../models/components';
import { ComponentGroup } from '../../models/component-group';
import { RefMaterial } from '../../models/ref-material';
import { MaintenancePlanUpdate } from '../../models/maintenance-plan-update';
import { ReducedUpdateInput } from '../../models/reduced-update-input';
import { Expertise } from '../../models/expertise';
import { ExpertiseTask } from '../../models/expertise-task';
@Injectable()
export class UtilityService {
  
  constructor() {  }

  public static UPDATE_DONE_TIMEOUT = 1000;

  public variable: boolean;
  public reducedUpdateInput: ReducedUpdateInput;
  public modifiedExpertise: Expertise;
  public addedExpertiseTask: ExpertiseTask;

  public setValue(val: boolean) {
    this.variable = val;
  }

  public getValue(): boolean {
    return this.variable;
  }
  public setReducedUpdateInput(val: ReducedUpdateInput){
    this.reducedUpdateInput = val;
  }

  public getReducedUpdateInput(): ReducedUpdateInput {
    return this.reducedUpdateInput;
  }

  public setModifiedExpertise(val: Expertise){
    this.modifiedExpertise = val;
  }

  public getModifiedExpertise(): Expertise {
    return this.modifiedExpertise;
  }

  public setAddedExpertiseTask(val: ExpertiseTask){
    this.addedExpertiseTask = val;
  }

  public getAddedExpertiseTask(): ExpertiseTask {
    return this.addedExpertiseTask;
  }
}