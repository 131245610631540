import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AppApiService } from '../../services/app-api/app-api.service';

/**
 * `FooterComponent` display a footer element displaying application version number and Api version number
 */
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /** this application version number from `environment` */
  /** this Api version number */
  apiVersion: string;
  /**
   * FooterComponent constructor
   * @param api Inject Api service to manage http calls
   */
  constructor(private api: AppApiService) { }
  /** OnInit hook get the Api version number from Api */
  ngOnInit() {
    this.api.get('/version', {responseType: 'text'})
      .subscribe((res: string) => this.apiVersion = res);
  }
}
