import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../../environments/environment';
/** Template of an error 401 page */
@Component({
  selector: 'app-error-401',
  templateUrl: './error-401.component.html',
  styleUrls: ['./error-401.component.scss']
})
export class Error401Component implements OnInit {
  /** Mail adress for support contact */
  support_mail = environment.support_mail;
  /**
   * `Error401Component` constructor
   */
  constructor() {}
  /** OnInit hook  */
  ngOnInit() {
  }
}
