import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TemplatePopupDialogComponent } from 'src/app/shared/components/template-popup-dialog/template-popup-dialog.component';
import { Axle } from 'src/app/shared/models/axle';
import { Bogie } from 'src/app/shared/models/bogie';
import { Box } from 'src/app/shared/models/box';
import { TechSpecReferences } from 'src/app/shared/models/tech-spec-references';
import { WagonAxle } from 'src/app/shared/models/wagon-axle';
import { WagonBogie } from 'src/app/shared/models/wagon-bogie';
import { WagonBox } from 'src/app/shared/models/wagon-box';
import { WagonWheel } from 'src/app/shared/models/wagon-wheel';
import { Wheel } from 'src/app/shared/models/wheel';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TechSpecRolling } from "../../../../../../models/tech-spec-rolling";
import { WagonTechnicalData } from "../../../../../../models/wagon-technical-data";
import { MonitoringWorkService } from "../../../../../../services/monitoring-work/monitoring-work.service";

@Component({
  selector: 'app-open-wagon-bearing',
  templateUrl: './open-wagon-bearing.component.html',
  styleUrls: ['./open-wagon-bearing.component.scss']
})
export class OpenWagonBearingComponent implements OnInit, OnDestroy {

  @Input() public wagonTechnicalData: WagonTechnicalData;
  @Input() public techSpecReferences: TechSpecReferences;
  @Output() modifiedDataEvent = new EventEmitter();

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  bearingForm: FormGroup;

  displayedColumns: string[] = ['type', 'number', 'nature', 'action'];
  bogieDS: MatTableDataSource<FormGroup>;
  axleDS: MatTableDataSource<FormGroup>;
  boxDS: MatTableDataSource<FormGroup>;
  wheelDS: MatTableDataSource<FormGroup>;

  filteredWheelList: Wheel[];
  filteredBoxList: Box[];
  filteredAxleList: Axle[];
  filteredBogieList: Bogie[];
  
  techSpecRolling: TechSpecRolling;

  /** Error list*/
  errorList: string[];
  /** submitted property */
  submitted: boolean;
  /** message error */
  messageError: string;
  /**Init BogieList */
  initBogieList: WagonBogie[];
  /**Init AxleList */
  initAxleList: WagonAxle[];
  /**Init BoxList */
  initBoxList: WagonBox[];
  /**Init WheelList */
  initWheelList: WagonWheel[];
  /** Field labels */
  lblBogies: string;
  lblAxles: string;
  lblBox: string;
  lblWheels: string;
  modifSub: Subscription;
  alert: boolean;
  constructor(private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private monitoringWorkService: MonitoringWorkService) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.bearingForm = this.formBuilder.group({
      bogies: this.formBuilder.array([]),
      axles: this.formBuilder.array([]),
      boxes: this.formBuilder.array([]),
      wheels: this.formBuilder.array([])
    });

    
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.modifSub = this.bearingForm.valueChanges.subscribe(
      _ => this.modifiedDataEvent.emit(this.bearingForm.dirty));

    this.loadInitDatas();
  }

  /**
   * Reset les donnes
   */
  resetDatas(): void {
    this.submitted = false;
    this.messageError = '';
    this.bogies.clear();
    this.axles.clear();
    this.boxes.clear();
    this.wheels.clear();

    this.lblBogies = this.translateService.instant('wagon.technical.bearing.bogie_type') + " 1";
    this.lblAxles = this.translateService.instant('wagon.technical.bearing.axle_type') + " 1";
    this.lblBox = this.translateService.instant('wagon.technical.bearing.box_type') + " 1";
    this.lblWheels = this.translateService.instant('wagon.technical.bearing.wheel_type') + " 1";

    const bogieList = this.initBogieList;
    bogieList.forEach(b => this.bogies.push(this.addFormGroup(true, this.techSpecReferences.bogieList, b.amount, b.bogie)));
    if (this.techSpecRolling.displayBogies && !this.techSpecRolling.axleWagon) {
      this.bogies.push(this.addFormGroup(false, this.techSpecReferences.bogieList));
    }
    this.bogieDS = new MatTableDataSource<FormGroup>(this.bogies.controls as FormGroup[]);

    const axleList = this.initAxleList;
    axleList.forEach(a => this.axles.push(this.addFormGroup(true, this.techSpecReferences.axleList, a.amount, a.axle)));
    this.axles.push(this.addFormGroup(false, this.techSpecReferences.axleList));
    this.axleDS = new MatTableDataSource<FormGroup>(this.axles.controls as FormGroup[]);

    const boxList = this.initBoxList;
    boxList.forEach(b => this.boxes.push(this.addFormGroup(true, this.techSpecReferences.boxList, b.amount, b.box)));
    this.boxes.push(this.addFormGroup(false, this.techSpecReferences.boxList));
    this.boxDS = new MatTableDataSource<FormGroup>(this.boxes.controls as FormGroup[]);

    const wheelList = this.initWheelList;
    wheelList.forEach(w => this.wheels.push(this.addFormGroup(true, this.techSpecReferences.wheelList, w.amount, w.wheel)));
    this.wheels.push(this.addFormGroup(false, this.techSpecReferences.wheelList));
    this.wheelDS = new MatTableDataSource<FormGroup>(this.wheels.controls as FormGroup[]);
    
    this.bearingForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }

  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
    this.subscriptionRefs.push(
      this.monitoringWorkService.loadRollingPageDatas(this.wagonTechnicalData.wagon.id)
        .subscribe(update => {
          this.techSpecRolling = update.data;
          this.initBearingLists();
          this.resetDatas();
          this.filteredWheelList = this.techSpecReferences.wheelList;
          this.filteredBoxList = this.techSpecReferences.boxList;
          this.filteredAxleList = this.techSpecReferences.axleList;
          this.filteredBogieList = this.techSpecReferences.bogieList;
  
          this.loadingSubject.next(false);
        })
    );
  }

  /**
   * Put value modified
   * RI_MAJ_003_2_2 : Le bouton " Enregistrer " devient actif (couleur bleu, cliquable)
   * dès qu'au moins un champ a été modifié.
   */
  changeValue(): void {
    this.errorList = [];
    this.messageError = '';
    //Bogies
    for (const index in this.bogies.controls) {
      if (this.bogies.controls[index].touched) {
        this.bogies.value[index].isAdded = true;
      }
    }
    //Axles
    for (const index in this.axles.controls) {
      if (this.axles.controls[index].touched) {
        this.axles.value[index].isAdded = true;
      }
    }
    //Boxes
    for (const index in this.boxes.controls) {
      if (this.boxes.controls[index].touched) {
        this.boxes.value[index].isAdded = true;
      }
    }
    //Wheels
    for (const index in this.wheels.controls) {
      if (this.wheels.controls[index].touched) {
        this.wheels.value[index].isAdded = true;
      }
    }
  }

  /**
   * Methode to submit the form
   */
  async onSubmit(alert?: boolean): Promise<void> {
    this.alert = alert === undefined ? true : alert;
    this.submitted = true;
    if (this.checkModifyInputControl()
      || this.bogies.controls.find(fg => fg.dirty && fg.invalid && (fg.get('isAdded').value===true) )
      || this.axles.controls.find(fg => fg.dirty && fg.invalid && (fg.get('isAdded').value===true) )
      || this.boxes.controls.find(fg => fg.dirty && fg.invalid && (fg.get('isAdded').value===true) )
      || this.wheels.controls.find(fg => fg.dirty && fg.invalid && (fg.get('isAdded').value===true) )) {
      return;
    } else {
      this.setBearingLists();
      this.loadingSubject.next(true);
      const errorMessage = (await this.monitoringWorkService.consistencyChecksRolling(this.techSpecRolling).pipe(first()).toPromise());
      if (errorMessage && errorMessage.data && errorMessage.data.message) {
        this.loadingSubject.next(false);
        if ('MASS_INCOMPATIBILITY' === errorMessage.data.message) {
          await this.wagonMassCompatibilityCheckPopUpDialog();
        } else {
          const splitted = errorMessage.data.message.split("-", 3);
          const message = splitted[0];
          if (message === 'RG_MAJ_003_4_2_13' || message === 'RG_MAJ_003_4_2_14' || message === 'RG_MAJ_003_4_2_15' || message === 'RG_MAJ_003_4_2_16'
            || message === 'RG_MAJ_003_4_2_17' || message === 'RG_MAJ_003_4_2_19') {
            this.messageError = this.translateService.instant('wagon.technical.bearing.' + message, { code: splitted[1] });
          } else if (message === 'RG_MAJ_003_4_2_18') {
            this.messageError = this.translateService.instant('wagon.technical.bearing.' + message, { code1: splitted[1], code2: splitted[2] });
          } else {
            this.messageError = this.translateService.instant('wagon.technical.bearing.' + message);
          }
          this.errorList.push(this.messageError);
        }
      } else {
        this.loadingSubject.next(true);
        const techSpecRolling = (await this.monitoringWorkService.updateTechSpecRolling(this.techSpecRolling).pipe(first()).toPromise()).data;
        this.loadingSubject.next(false); 
        if (techSpecRolling) {
          this.techSpecRolling = techSpecRolling;
          this.updateDone();
          this.bearingForm.markAsPristine();
          this.modifiedDataEvent.emit(false);
        }
      }
    }
  }

  /**
   * Methode to show wagonMassCompatibilityCheckPopUpDialog Dialog
   */
 async  wagonMassCompatibilityCheckPopUpDialog(): Promise<void> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '12.5rem';
    dialogConfig.minWidth = '13.75rem';
    const title = this.translateService.instant('wagon.technical.bearing.dialog.title');
    const message = this.translateService.instant('wagon.technical.bearing.dialog.msg');
    dialogConfig.data = {
      namePopUp: 'wagonMassCompatibility',
      titlePopUp: title,
      msgPopUp: message
    };
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    const confirm = (await dialogRef.afterClosed().pipe(first()).toPromise())
    if (confirm) {
      this.loadingSubject.next(true);
      const techSpecRolling = (await this.monitoringWorkService.updateTechSpecRolling(this.techSpecRolling).pipe(first()).toPromise()).data
      this.loadingSubject.next(false);
      if (techSpecRolling) {
        this.updateDone();
        this.bearingForm.markAsPristine();
        this.modifiedDataEvent.emit(false);
      }
    }
    dialogRef.close();
    this.dialog.closeAll();
  }

  /**
   * Init the differents list of Bearing
   */
  initBearingLists(): void {
    this.initBogieList = this.techSpecRolling.wagonTechnicalDataDetail.bearing.bogieList;
    this.initAxleList = this.techSpecRolling.wagonTechnicalDataDetail.bearing.axleList;
    this.initBoxList = this.techSpecRolling.wagonTechnicalDataDetail.bearing.boxList;
    this.initWheelList = this.techSpecRolling.wagonTechnicalDataDetail.bearing.wheelList;

    if (this.initBogieList) {
      this.initBogieList.forEach(b => b['code'] = b.bogie.code);
    }
    if (this.initAxleList) {
      this.initAxleList.forEach(b => b['code'] = b.axle.code);
    }
    if (this.initBoxList) {
      this.initBoxList.forEach(b => b['code'] = b.box.code);
    }
    if (this.initWheelList) {
      this.initWheelList.forEach(b => b['code'] = b.wheel.code);
    }
  }

  /**
  * Le message se ferme quand 5 seconds sont pasé
  */
  updateDone(): void {
    this.initBearingLists();
    this.resetDatas();
    if (this.alert) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      const title = this.translateService.instant('wagon-update.task.update-done.msg');
      const message = this.translateService.instant('wagon-update.task.update-done.msg');
      const timeout = UtilityService.UPDATE_DONE_TIMEOUT;
      dialogConfig.data = {
        namePopUp: 'update-done-alert',
        titlePopUp: title,
        msgPopUp: message
      };
      const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
      dialogRef.afterOpened().subscribe(_ => {
        setTimeout(() => {
          dialogRef.close();
          this.dialog.closeAll();
        }, timeout)
      });
    }
  }

  /**
   * Set all modifications of differents lists (Bogies, Axles, Wheels and Boxes)
   */
  setBearingLists(): void {
    this.techSpecRolling.wagonTechnicalDataDetail.bearing.bogieList = [];
    this.techSpecRolling.wagonTechnicalDataDetail.bearing.axleList = [];
    this.techSpecRolling.wagonTechnicalDataDetail.bearing.boxList = [];
    this.techSpecRolling.wagonTechnicalDataDetail.bearing.wheelList = [];
    //Bogies
    for (const index in this.bogies.controls) {
      if (this.bogies.controls[index].get('isAdded').value) {
        const wagonBogie = new WagonBogie();
        wagonBogie.amount = this.bogies.value[index].amount;
        wagonBogie.bogie =  this.techSpecReferences.bogieList.filter(t => t.code === this.bogies.value[index].nature)[0];
        this.techSpecRolling.wagonTechnicalDataDetail.bearing.bogieList.push(wagonBogie);
      }
    }
    //Axles
    for (const index in this.axles.controls) {
      if (this.axles.controls[index].get('isAdded').value) {
        const wagonAxle = new WagonAxle();
        wagonAxle.amount = this.axles.value[index].amount;
        wagonAxle.axle = this.techSpecReferences.axleList.filter(t => t.code === this.axles.value[index].nature)[0];
        this.techSpecRolling.wagonTechnicalDataDetail.bearing.axleList.push(wagonAxle);
      }
    }
    //Boxes
    for (const index in this.boxes.controls) {
      if (this.boxes.controls[index].get('isAdded').value) {
        const wagonBox = new WagonBox();
        wagonBox.amount = this.boxes.value[index].amount;
        wagonBox.box = this.techSpecReferences.boxList.filter(t => t.code === this.boxes.value[index].nature)[0];
        this.techSpecRolling.wagonTechnicalDataDetail.bearing.boxList.push(wagonBox);
      }
    }
    //Wheels
    for (const index in this.wheels.controls) {
      if (this.wheels.controls[index].get('isAdded').value) {
        const wagonWheel = new WagonWheel();
        wagonWheel.amount = this.wheels.value[index].amount;
        wagonWheel.wheel = this.techSpecReferences.wheelList.filter(t => t.code === this.wheels.value[index].nature)[0];
        this.techSpecRolling.wagonTechnicalDataDetail.bearing.wheelList.push(wagonWheel);
      }
    }
  }

  /**
   * Show Alert message
   * @param msg
   */
  showAlertMessage(msg: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';

    dialogConfig.data = {
      namePopUp: 'alert_msg',
      msgPopUp: msg
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      this.dialog.closeAll();
    });
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

  addFormGroup(add: boolean, list: any[], amount?: number, nature?: any): FormGroup {
    return this.formBuilder.group({
      isAdded: this.formBuilder.control(add),
      amount: this.formBuilder.control(amount, [Validators.required, Validators.pattern(/^\d{1,3}$/)]),
      nature: this.formBuilder.control(nature?nature.code:null, [Validators.required, this.validateCodeInList(list)])
    }
    );
  }

  addRow(array: FormArray, index: number, add: boolean, list: any[]): void {
    if (!this.checkIfExistControlError(index)) {
      array.controls[index].value.isAdded = !add;
      array.controls[index].get('isAdded').setValue(!add);
      array.push(this.addFormGroup(add, list));
      this.tables.forEach(table => table.renderRows());
      array.markAsDirty();
    }
  }

  removeRow(array: FormArray, index: number): void {
    array.removeAt(index);
    this.tables.forEach(table => table.renderRows());
    array.markAsDirty();
    this.modifiedDataEvent.emit(true);
  }

  get bogies(): FormArray {
    return this.bearingForm.get('bogies') as FormArray;
  }

  get axles(): FormArray {
    return this.bearingForm.get('axles') as FormArray;
  }

  get boxes(): FormArray {
    return this.bearingForm.get('boxes') as FormArray;
  }

  get wheels(): FormArray {
    return this.bearingForm.get('wheels') as FormArray;
  }

  /**
  * Methode to check errors and put in a List
  */
  checkIfExistControlError(index: number): boolean {
    let message = null;
    this.messageError = '';
    this.errorList = [];
    let errors = false;
    const bogieAdded = this.bogieDS.data[index];
    let natureBogieAddedCtrl;
    let amountBogieAddedCtrl;
    if (bogieAdded) {
      natureBogieAddedCtrl = bogieAdded.get('nature');
      amountBogieAddedCtrl = bogieAdded.get('amount');
    }
    let countBogie = 0;
    let countBogieTranche = 0;
    const axleAdded = this.axleDS.data[index];
    let natureAxleAddedCtrl;
    let amountAxleAddedCtrl;
    if (axleAdded) {
      natureAxleAddedCtrl = axleAdded.get('nature');
      amountAxleAddedCtrl = axleAdded.get('amount');
    }
    let countAxle = 0;
    let countAxleTranche = 0;
    const boxAdded = this.boxDS.data[index];
    let natureBoxAddedCtrl;
    let amountBoxAddedCtrl;
    if (boxAdded) {
      natureBoxAddedCtrl = boxAdded.get('nature');
      amountBoxAddedCtrl = boxAdded.get('amount');
    }
    let countBox = 0;
    let countBoxTranche = 0;
    const wheelAdded = this.wheelDS.data[index];
    let natureWheelAddedCtrl;
    let amountWheelAddedCtrl;
    if (wheelAdded) {
      natureWheelAddedCtrl = wheelAdded.get('nature');
      amountWheelAddedCtrl = wheelAdded.get('amount');
    }
    let countWheel = 0;
    let countWheelTranche = 0;
    this.bogieDS.data.forEach(element => {
      const amountCtrl = element.get('amount');
      countBogieTranche = countBogieTranche + Number(amountCtrl.value);
      if (amountCtrl.value && amountCtrl.value <= 0) {
        message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_5');
        element.get('amount').setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }

      if (natureBogieAddedCtrl && natureBogieAddedCtrl.value && element.value && element.value.nature && (element.value.nature === natureBogieAddedCtrl.value.code)) {
        countBogie++;
      }

      if (countBogie > 1) {
        message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_6');
        element.get('nature').setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }
    });
    //Cas 1 : Bogies : RG_MAJ_003_4_2_4 
    if (this.techSpecRolling.bogieWagon && bogieAdded && (countBogieTranche > this.techSpecRolling.bogieCount)) {
      message = this.translateService.instant('wagon.technical.bearing.RG_MAJ_003_4_2_4');
      amountBogieAddedCtrl.setErrors(Validators.required);
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        errors = true;
      }
    }

    this.axleDS.data.forEach(element => {
      const amountCtrl = element.get('amount');
      countAxleTranche = countAxleTranche + Number(amountCtrl.value);
      if (amountCtrl.value && amountCtrl.value <= 0) {
        message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_5');
        element.get('amount').setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }

      if (natureAxleAddedCtrl && natureAxleAddedCtrl.value && element.value && element.value.nature && (element.value.nature === natureAxleAddedCtrl.value.code)) {
        countAxle++;
      }

      if (countAxle > 1) {
        message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_6');
        element.get('nature').setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }

    });

    //Cas 2 : Bogies : RG_MAJ_003_4_2_8 
    if (axleAdded && (countAxleTranche > this.techSpecRolling.axleCount)) {
      message = this.translateService.instant('wagon.technical.bearing.RG_MAJ_003_4_2_8');
      amountAxleAddedCtrl.setErrors(Validators.required);
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        errors = true;
      }
    }

    this.boxDS.data.forEach(element => {
      const amountCtrl = element.get('amount');
      countBoxTranche = countBoxTranche + Number(amountCtrl.value);
      if (amountCtrl.value && amountCtrl.value <= 0) {
        message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_5');
        element.get('amount').setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }

      if (natureBoxAddedCtrl && natureBoxAddedCtrl.value && element.value && element.value.nature && (element.value.nature === natureBoxAddedCtrl.value.code)) {
        countBox++;
      }

      if (countBox > 1) {
        message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_6');
        element.get('nature').setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }

    });

    //Cas 3 : Bogies : RG_MAJ_003_4_2_9 
    const doubleAxleCount = this.techSpecRolling.axleCount * 2;
    if (boxAdded && (countBoxTranche > doubleAxleCount)) {
      message = this.translateService.instant('wagon.technical.bearing.RG_MAJ_003_4_2_9');
      amountBoxAddedCtrl.setErrors(Validators.required);
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        errors = true;
      }
    }

    this.wheelDS.data.forEach(element => {
      const amountCtrl = element.get('amount');
      countWheelTranche = countWheelTranche + Number(amountCtrl.value);
      if (amountCtrl.value && amountCtrl.value <= 0) {
        message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_5');
        element.get('amount').setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }

      if (natureWheelAddedCtrl && natureWheelAddedCtrl.value && element.value && element.value.nature && (element.value.nature === natureWheelAddedCtrl.value.code)) {
        countWheel++;
      }

      if (countWheel > 1) {
        message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_6');
        element.get('nature').setErrors(Validators.required);
        if (!this.errorList || this.errorList.indexOf(message) === -1) {
          this.errorList.push(message);
          errors = true;
        }
      }

    });

    //Cas 4 : Bogies : RG_MAJ_003_4_2_10 
    if (wheelAdded && (countWheelTranche > (this.techSpecRolling.axleCount * 2))) {
      message = this.translateService.instant('wagon.technical.bearing.RG_MAJ_003_4_2_10');
      amountWheelAddedCtrl.setErrors(Validators.required);
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        errors = true;
      }
    }
    this.errorList.forEach(element => {
      this.messageError = this.messageError + element + '\n'
    })

    return errors;
  }



  /**
  * Methode to check Input controls for changed numbers
  */
  checkModifyInputControl(): boolean {
    let message = null;
    this.messageError = '';
    this.errorList = [];
    let errors = false;
    let countBogieTranche = 0;

    //Bogies : RG_MAJ_003_4_2_1
    if (this.techSpecRolling.bogieCount === 0 && this.bogieDS.data && this.bogieDS.data.length > 0) {
      message = this.translateService.instant('wagon.technical.bearing.RG_MAJ_003_4_2_1');
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        errors = true;
      }
    }
    this.bogieDS.data.forEach(element => {
      const amountCtrl = element.get('amount');
      if (element.get('isAdded').value){
        countBogieTranche = countBogieTranche + Number(amountCtrl.value);
        if (amountCtrl.value && amountCtrl.value <= 0) {
          message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_5');
          element.get('amount').setErrors(Validators.required);
          if (!this.errorList || this.errorList.indexOf(message) === -1) {
            this.errorList.push(message);
            errors = true;
          }
        }
      }
    });
    //Cas 1 : Bogies : RG_MAJ_003_4_2_4 
    if (this.techSpecRolling.bogieWagon && (countBogieTranche > this.techSpecRolling.bogieCount)) {
      message = this.translateService.instant('wagon.technical.bearing.RG_MAJ_003_4_2_4');
      if (!this.errorList || this.errorList.indexOf(message) === -1) {
        this.errorList.push(message);
        errors = true;
      }
    }

    this.axleDS.data.forEach(element => {
      const amountCtrl = element.get('amount');
      if (element.get('isAdded').value){
        if (amountCtrl.value && amountCtrl.value <= 0) {
          message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_5');
          element.get('amount').setErrors(Validators.required);
          if (!this.errorList || this.errorList.indexOf(message) === -1) {
            this.errorList.push(message);
            errors = true;
          }
        }
      }
    });

    this.boxDS.data.forEach(element => {
        const amountCtrl = element.get('amount');
        if (element.get('isAdded').value){
          if (amountCtrl.value && amountCtrl.value <= 0) {
            message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_5');
            element.get('amount').setErrors(Validators.required);
            if (!this.errorList || this.errorList.indexOf(message) === -1) {
              this.errorList.push(message);
              errors = true;
            }
          }
        }
    });

    this.wheelDS.data.forEach(element => {
      const amountCtrl = element.get('amount');
      if (element.get('isAdded').value){
        if (amountCtrl.value && amountCtrl.value <= 0) {
          message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_5');
          element.get('amount').setErrors(Validators.required);
          if (!this.errorList || this.errorList.indexOf(message) === -1) {
            this.errorList.push(message);
            errors = true;
          }
        }
      }
    });


    // controle si existe le code saisi est déjà présent pour les bogies
    this.bogieDS.data.forEach(element => {
      const natureBogieAddedCtrl = this.techSpecReferences.bogieList.filter(t => t.code === element.get('nature').value)[0];
      let countBogie = 0;
      if (element.get('isAdded').value){
        this.bogieDS.data.forEach(element2 => {
          if (element2.get('isAdded').value){
            const natureBogie2AddedCtrl = this.techSpecReferences.bogieList.filter(t => t.code === element2.get('nature').value)[0];
            if (natureBogieAddedCtrl && natureBogie2AddedCtrl && (natureBogie2AddedCtrl.code === natureBogieAddedCtrl.code)) {
              countBogie++;
            }
            if (countBogie > 1) {
              message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_6');

              if (!this.errorList || this.errorList.indexOf(message) === -1) {
                element2.get('nature').setErrors(Validators.required);
                this.errorList.push(message);
                errors = true;
              }
            }
          }
        });
      }
    });

    // controle si existe le code saisi est déjà présent pour les essies
    this.axleDS.data.forEach(element => {
      const natureAxleAddedCtrl = this.techSpecReferences.axleList.filter(t => t.code === element.get('nature').value)[0];
      let countAxle = 0;
      if (element.get('isAdded').value){
        this.axleDS.data.forEach(element2 => {
          const natureAxle2AddedCtrl = this.techSpecReferences.axleList.filter(t => t.code === element2.get('nature').value)[0];
          if (element2.get('isAdded').value){
            if (natureAxleAddedCtrl && natureAxle2AddedCtrl && (natureAxle2AddedCtrl.code === natureAxleAddedCtrl.code)) {
              countAxle++;
            }
            if (countAxle > 1) {
              message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_6');

              if (!this.errorList || this.errorList.indexOf(message) === -1) {
                element2.get('nature').setErrors(Validators.required);
                this.errorList.push(message);
                errors = true;
              }
            }
          }
          });
      }
    });

    // controle si existe le code saisi est déjà présent pour les boxes
    this.boxDS.data.forEach(element => {
      const natureBoxAddedCtrl = this.techSpecReferences.boxList.filter(t => t.code === element.get('nature').value)[0];
      let countBox = 0;
      if (element.get('isAdded').value){
        this.boxDS.data.forEach(element2 => {
          const natureBox2AddedCtrl = this.techSpecReferences.boxList.filter(t => t.code === element2.get('nature').value)[0];
          if (element2.get('isAdded').value){
            if (natureBoxAddedCtrl && natureBox2AddedCtrl && (natureBox2AddedCtrl.code === natureBoxAddedCtrl.code)) {
              countBox++;
            }
            if (countBox > 1) {
              message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_6');

              if (!this.errorList || this.errorList.indexOf(message) === -1) {
                element2.get('nature').setErrors(Validators.required);
                this.errorList.push(message);
                errors = true;
              }
            }
          }
        });
      }
    });

    // controle si existe le code saisi est déjà présent pour les wheels
    this.wheelDS.data.forEach(element => {
      const natureWheelAddedCtrl = this.techSpecReferences.wheelList.filter(t => t.code === element.get('nature').value)[0];
      let countWheel = 0;
      if (element.get('isAdded').value){
        this.wheelDS.data.forEach(element2 => {
          const natureWheel2AddedCtrl = this.techSpecReferences.wheelList.filter(t => t.code === element2.get('nature').value)[0];
          if (element2.get('isAdded').value){
            if (natureWheelAddedCtrl && natureWheel2AddedCtrl && (natureWheel2AddedCtrl.code === natureWheelAddedCtrl.code)) {
              countWheel++;
            }
            if (countWheel > 1) {
              message = this.translateService.instant('wagon.technical.bearing.RI_MAJ_003_4_2_6');

              if (!this.errorList || this.errorList.indexOf(message) === -1) {
                element2.get('nature').setErrors(Validators.required);
                this.errorList.push(message);
                errors = true;
              }
            }
          }
        });
      }
    });

    this.errorList.forEach(element => {
      this.messageError = this.messageError + element + '\n'
    })
    return errors;
  }

  filterWithCodeLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + ' - ' + v.label).toUpperCase().includes(filterValue))
      ) : list;
  }
  validateCodeInList(list: {code: string}[]): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let val = control.value;
      if (!val) {
        if (val === '') {
          control.setValue(null);
        }
        return null;
      } else {
        
        val = val.toUpperCase();
        const type = list.filter( (elem) => elem.code === val);
        if (type.length) {
          return null;
        }
      }
      return { validateCodeInList: true };
    };
  }
  displayCodeLabelFromBogieList = (obj: any) => {
    const f = this.techSpecReferences.bogieList.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromBoxList = (obj: any) => {
    const f = this.techSpecReferences.boxList.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromWheelList = (obj: any) => {
    const f = this.techSpecReferences.wheelList.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  displayCodeLabelFromAxleList = (obj: any) => {
    const f = this.techSpecReferences.axleList.find(e => e.code === (obj ? (obj.code ? obj.code : obj) : null));
    return f ? f.code + ' - ' + f.label  : '';
  }
  
  isValid(): boolean {
    return (this.bearingForm.get('bogies') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
     (this.bearingForm.get('axles') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
     (this.bearingForm.get('boxes') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true) &&
     (this.bearingForm.get('wheels') as FormArray).controls.map((e, idx, arr) => (idx === arr.length - 1) || e.valid).reduce((p, c) => p && c , true);
 }
}
