import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.scss']
})
export class ToggleSwitchComponent implements OnInit {

  @Input() public badge = '';
  @Input() public labelOn = '';
  @Input() public labelOff = '';
  @Input() public colorOn = '';
  @Input() public colorOff = '';
  @Input() public checked = false;

  constructor() { }

  ngOnInit() {
    if (!this.labelOff) {
      this.labelOff = this.labelOn;
    }
  }

  onInputClick() {
    this.checked = !this.checked;
  }
}
