import { Imputation } from './imputation';
import { InterventionCause } from './intervention-cause';
import { InterventionReason } from './intervention-reason';
import { LogicalWagon } from './logical-wagon';
import { MaintenanceOperation } from './maintenance-operation';
import { TrailedMaterialStatus } from './trailed-material-status';
import { UpdateType } from './update-type';
import { Workshop } from './workshop';


export class InitUpdateSpace {

  wagonId: number;

  currentLogicalWagon: LogicalWagon;

  logicalWagons: LogicalWagon[];

  workshop: Workshop;

  updateType: UpdateType;

  currentMex: string;

  refWagon: string;

  dataEntryWorkshop: Workshop;

  maintenanceOperation: MaintenanceOperation;

  interventionReason: InterventionReason;

  interventionCause: InterventionCause;

  imputation: Imputation;

  trailedMaterialStatus: TrailedMaterialStatus;

  observation: string;

  option1: string;

  option2: string;

  dealId: number;

  pvcaId: number;

  expId: number;

  exit: Date;

  exitSys: Date;

  entry: Date;

  hasExpertise: boolean;

  agentCp: string;

  wagonKnown: boolean;

  reduceUpdate: boolean;

  razPlm: number;
}
