/** API model:Table:  T106_MATIERE_REF */
export class RefMaterial {
  /** MATREF_CODE_PK VARCHAR2(8 BYTE) */
  code: string;
  /** MATREF_LIB VARCHAR2(216 BYTE) */
  label: string;
  /** MATREF_FLAG_VM NUMBER(1,0) */
  isOldMaterial: boolean;
  /** MATREF_PRIX_UNIT NUMBER(7,2) */
  unitPrice: number;
  /** MATREF_UNITE_STOCK VARCHAR2(10 BYTE) */
  unit: string;
  /** MATREF_MASSE VARCHAR2(20 BYTE) */
  mass: string;
  /** MATREF_TYPE VARCHAR2(30 BYTE) */
  type: string;
  /** MATREF_TABLEAU tableau VARCHAR2(50 BYTE) */
  array: string;
  /** MATREF_CATEGORIE VARCHAR2(50 BYTE) */
  category: string;
  /** MATREF_FLAG_ESS NUMBER(1,0) */
  isAxleMaterial: boolean;
  /** MATREF_FLAG_WAG NUMBER(1,0) */
  isWagonMaterial: boolean;
  /** MATREF_FLAG_FORF NUMBER(1,0) */
  isPackage: boolean;
}
