import { Injectable } from '@angular/core';

import { LoggerLevel } from './logger-level.enum';
import { environment } from '../../../../environments/environment';

/**
 * Service de gestion des logs
 */
@Injectable()
export class LoggerService {

  /**
   * Prefix utilisé pour chaque log
   */
  private logPrefix: string;
  /**
   * Niveau de log courant
   */
  private currentLoggerLevel: LoggerLevel;
  /**
   * Détermine s'il faut écrire les logs au sein d'un groupe
   */
  private logGrouping = false;

  /**
   * LoggerService constructor sets the log level according to `environment`
   */
  constructor() {
    // Définition du niveau de log courant
    this.currentLoggerLevel = LoggerLevel[environment.logger_level];
  }

  /**
   * Méthode générique de gestion des logs
   * @param level - Niveau de log du message
   * @param format - Message du log avec gestion des variables
   * @param args - Objets supplémentaires à logger
   */
  private log(level: LoggerLevel, format: string, args: any[]) {

    // Si niveau de log moins verbeux effectif => on ne log pas
    if (level < this.currentLoggerLevel) {
      return;
    }

    // Elements du log
    const [output, css] = this.getOutput(level);
    const [message, arr] = this.buildMessage(format, args);
    const time: string = this.getFormattedTime();
    let prefix = this.logPrefix ? ` - ${this.logPrefix}` : '';

    if (this.logGrouping === true) {
      prefix = '';
      console.group(this.logPrefix);
    }

    // Affiche le log
    output.bind(console)(`%c ${time} - ${LoggerLevel[level]}${prefix} - ${message}`, css);

    // Affiche tous les autres paramètres
    for (const obj of arr) {
      output.bind(console)(obj);
    }

    if (this.logGrouping === true) {
      console.groupEnd();
    }
  }

  /**
   * Sélectionne la bonne méthode de sortie ainsi
   * que le css associé
   * @param level - Niveau de log choisis
   */
  private getOutput(level: LoggerLevel): [(message?: any, ...optionalParams: any[]) => void, string] {
    switch (level) {
      case LoggerLevel.ERROR:
        return [console.error, 'font-size: 1.1em; padding: 3px'];
      case LoggerLevel.WARN:
        return [console.warn, 'color: orange'];
      case LoggerLevel.TRACE:
        return [console.log, 'color: #ccc'];
      case LoggerLevel.INFO:
        return [console.log, 'color: blue'];
      case LoggerLevel.DEBUG:
        return [console.log, 'color: grey'];
    }
  }

  /**
   * Génération du message à partir du format et des
   * arguments d'un log
   * @param format - Message du log
   * @param args - Arguments du logs
   */
  private buildMessage(format: any, args: any[]): [string, Array<any>] {
    // Copie du tableau
    args = args.slice();

    // Si le premier argument n'est pas un string
    if (!(typeof format === 'string' || format instanceof String)) {
      args.push(format);
      return ['', args];
    }

    // Alimentation des variables, avec les arguments dans l'ordre
    const varCount: number = (format.match(/{}/g) || []).length;
    for (let i = 0; i < varCount; ++i) {
      format = format.replace('{}', args.shift());
    }

    return [format, args];
  }

  /**
   * Génération d'une date formattée
   */
  private getFormattedTime(): string {
    const now: Date = new Date();
    return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}:${now.getMilliseconds()}`;
  }
  /** log trace level */
  public trace(format: string, ...args) {
    this.log(LoggerLevel.TRACE, format, args);
  }
  /** log debug level */
  public debug(format: string, ...args) {
    this.log(LoggerLevel.DEBUG, format, args);
  }
  /** log info level */
  public info(format: string, ...args) {
    this.log(LoggerLevel.INFO, format, args);
  }
  /** log warn level */
  public warn(format: string, ...args) {
    this.log(LoggerLevel.WARN, format, args);
  }
  /** log error level */
  public error(format: string, ...args) {
    this.log(LoggerLevel.ERROR, format, args);
  }

  /**
   * Change l'affichage des groupes
   * @param useGroup - True pour activer les groupes
   */
  public useGroup(useGroup: boolean) {
    this.logGrouping = useGroup;
  }

  /**
   * Création d'un nouveau logger
   * @param prefix - Préfix des message
   * @param useGroup - Afficher les messages au sein d'un groupe
   */
  public newLogger(prefix: string, useGroup?: boolean) {
    const logger = new LoggerService();
    logger.logPrefix = prefix;
    logger.logGrouping = useGroup || this.logGrouping === true;
    return logger;
  }
}
