import { Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Gcu } from 'src/app/shared/models/gcu';
import { IglCam } from 'src/app/shared/models/igl-cam';
import { IglDefautCuu } from 'src/app/shared/models/igl-defaut-cuu';
import { MaintenanceAid } from 'src/app/shared/models/maintenance-aid';
import { MonitoringWorkService } from 'src/app/shared/services/monitoring-work/monitoring-work.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TemplatePopupDialogComponent } from '../../../template-popup-dialog/template-popup-dialog.component';
import { CamLinkedTaskDialogComponent } from '../cam-linked-task-dialog/cam-linked-task-dialog.component';

@Component({
  selector: 'app-monitoring-works-task',
  templateUrl: './monitoring-works-task.component.html',
  styleUrls: ['./monitoring-works-task.component.scss']
})
export class MonitoringWorksTaskComponent implements OnInit, OnDestroy {

  @Input() public maintenanceAids: MaintenanceAid[];
  @Input() public gcus: Gcu[];
  @Output() onAddTask = new EventEmitter<boolean>();
  @Output() onCamAdded = new EventEmitter<boolean>();
  @Output() onTaskAdded = new EventEmitter<boolean>();
  /** Open form group */
  taskForm: FormGroup;
  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** submitted property */
  submitted: boolean;
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Current wagon */
  currentWagon: number;
  /** new cam form control */
  camCtrl: FormControl;
  camSelected: MaintenanceAid;
  camLabel: string;
  /** filtered CAMs list */
  filteredCAMList: MaintenanceAid[];

  /** new cam form control */
  gcuCtrl: FormControl;
  gcuSelected: Gcu;
  gcuLabel: string;
  /** filtered CAMs list */
  filteredGcuList: Gcu[];

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<MonitoringWorksTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {wagonId: number, maintenanceAids: MaintenanceAid[], gcus: Gcu[]},
    private overlay: Overlay,
    private translateService: TranslateService,
    private monitoringWorkService: MonitoringWorkService) {
      
    this.currentWagon = data.wagonId;
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.resetDatas();
    this.loadInitDatas();
    this.camCtrl = this.formBuilder.control('', Validators.required);
    this.gcuCtrl = this.formBuilder.control('', Validators.required);
    

    this.taskForm = this.formBuilder.group({
      cam: this.camCtrl,
      gcu: this.gcuCtrl
    }, {
      validator: []
    });
    
    this.filteredCAMList =  this.maintenanceAids;
    this.filteredGcuList = this.gcus;
    this.subscriptionRefs.push(this.camCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => {
          if(!value && this.gcuCtrl.disabled) {
            this.gcuCtrl.enable();
          } else if (value && this.gcuCtrl.enable) {
            this.gcuCtrl.disable();
          }
          this.filteredCAMList = this.filterWithCodeLabel(value, this.maintenanceAids);
        }
      ));

    this.subscriptionRefs.push(this.gcuCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe( value => {
        if(!value && this.camCtrl.disabled) {
          this.camCtrl.enable();
        } else if (value && this.camCtrl.enable) {
          this.camCtrl.disable();
        }
        this.filteredGcuList = this.filterWithCodeLabel(value, this.gcus);
      }
      ));
  }

  /**
   * Reset les donnes
   */
  private resetDatas(): void {
    this.submitted = false;
    this.camLabel = '';
    this.gcuLabel = '';
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
    this.loadingSubject.complete();
  }

  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
    this.loadingSubject.next(false);
  }

  /**
   * convenience getter for easy access to form fields
   */
  get f() { return this.taskForm.controls; }

  private filterWithCodeLabel(value: string | any, list: any[]): any[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return filterValue ? list
      .filter(
        v => ((v.code + v.label).toUpperCase().includes(filterValue))
      ) : list;
  }

  /**
  * On click ComponentGroup Selection
  */
  onCAMSelection() {
    if (this.camCtrl && this.camCtrl.value) {
      this.camSelected = this.camCtrl.value;
      this.camLabel = this.camSelected.label
    } else {
      this.camLabel = '';
    }
  }

  /**
  * On click ComponentGroup Selection
  */
  onGcuSelection() {
    if (this.gcuCtrl && this.gcuCtrl.value) {
      this.gcuSelected = this.gcuCtrl.value;
      this.gcuLabel = this.gcuSelected.label
    } else {
      this.gcuLabel = '';
    }
  }

  displayByCode(data: any) {
    return data ? (data.code ? data.code : '') : '';
  }

  displayCodeLabel(data: any) {
    return data ? (data.code ? data.code + ': ' + data.label : data) : '';
  }

  /**Clean CAM */
  cleanCAM() {
    this.camCtrl.setValue(undefined);
  }

  /**Clean Gcu */
  cleanGcu() {
    this.gcuCtrl.setValue(undefined);
  }

  /**
  * Methode to clean alerts errors.
  */
  disableAlerts() {
    if (this.submitted) {
      this.submitted = false;
    }
  }

  /**
   * Methode to cancel operation
  */
  cancel() {
    if (this.gcuCtrl.value || this.camCtrl.value) {
      this.unsavedChangesPopUpDialog();
    } else {
      this.dialog.closeAll();
    }
  }

  /**
   * Le message se ferme quand 5 seconds sont pasé
   */
  updateDone(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    const title = this.translateService.instant('wagon-update.task.update-done.msg');
    const message = this.translateService.instant('wagon-update.task.update-done.msg');

    const timeout = UtilityService.UPDATE_DONE_TIMEOUT;

    dialogConfig.data = {
      namePopUp: 'update-done-alert',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
        this.onCamAdded.emit(true);
        this.cleanCAM();
        this.cleanGcu();
      }, timeout)
    })

  }


  /**
   * Methode to show Unsaved Changes Dialog
   */
  unsavedChangesPopUpDialog(): void {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '18.75rem';
    dialogConfig.width = '34.375rem';
    const title = this.translateService.instant('wagon-update.task.unsaved_changes.title');
    const message = this.translateService.instant('wagon-update.task.unsaved_changes.msg');

    dialogConfig.data = {
      namePopUp: 'unsaved_changes_task',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.dialog.closeAll()
      }
    });
  }

  /**
   * Methode to create a task
   */
  createTask() {
    this.onAddTask.emit(true);
  }


  /**
  * Methode to submit the form
  */
  onSubmit(): void {
    this.submitted = true;

    if (this.camCtrl.value) {
      const cam = this.camCtrl.value.code;
      /* operationType = 0 --> Check to create CAM
        operationType = 1 --> Check to update CAM*/
      const operationType = 0;
      this.subscriptionRefs.push(
        this.monitoringWorkService.checkCAM(this.currentWagon, cam, operationType)
          .subscribe((msg => {
            let message;
            if (msg.data.message === null) {
              const iglCam = new IglCam();
              iglCam.id = this.currentWagon;
              this.subscriptionRefs.push(
                this.monitoringWorkService.createCAM(iglCam, cam)
                  .subscribe((cam => {
                    const iglCam = cam.data;
                    if (!iglCam) {
                      message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.add_line.msg_error_unknown_wagon');
                      this.showAlertMessage(message);
                    } else {
                      this.updateDone();
                    }
                  }))
              );
            } else if (msg.data.message === 'CAM_ERROR_1') {
              message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.add_line.msg_error_cam_1');
              this.showAlertMessage(message);
            } else if (msg.data.message === 'CAM_ERROR_2') {
              message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.add_line.msg_error_cam_2');
              this.showAlertMessage(message);
            } else {
              //ajouter une tache
              this.showAddTaskDialog(cam, msg.data.message, 'ADD_CAM')
            }

          }))
      );

    } else if (this.gcuCtrl.value) {
      const gcu = this.gcuCtrl.value.code;
      this.subscriptionRefs.push(
        this.monitoringWorkService.checkDefault(this.currentWagon, gcu)
          .subscribe((msg => {
            let message;
            if (msg.data.message === null) {
              const iglDefautCuu = new IglDefautCuu();
              iglDefautCuu.id = this.currentWagon;
              this.subscriptionRefs.push(
                this.monitoringWorkService.createDefault(iglDefautCuu, gcu)
                  .subscribe((cam => {
                    const iglCam = cam.data;
                    if (!iglCam) {
                      message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.add_line.msg_error_unknown_wagon');
                      this.showAlertMessage(message);
                    } else {
                      this.updateDone();
                    }
                  }))
              );
            } else if (msg.data.message === 'DEFAULT_ERROR_1') {
              message = this.translateService.instant('wagon-update.preparation-work.monitoring-work.add_line.msg_error_default_1');
              this.showAlertMessage(message);
            }
          }))
      );
    }
  }

  /**
   * Show Alert message
   * @param msg
   */
  showAlertMessage(msg: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';

    dialogConfig.data = {
      namePopUp: 'alert_msg',
      msgPopUp: msg
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
  }

  /**
   * Show Add task dialog.
   * @param cam code
   * @param typeCase if (CASE 1 or CASE 2) the type is 'add CAM' (CASE 3 or CASE 4) the type is 'modify CAM'
   */
  showAddTaskDialog(cams: string, case_number: string, typeCase: string) {    
    const scrollStrat = this.overlay.scrollStrategies.reposition();
    const dialogRef = this.dialog.open(CamLinkedTaskDialogComponent
      , {
        data: { wagon: this.currentWagon, cam: cams, caseNumber: case_number, caseType: typeCase },
        disableClose: true,
        scrollStrategy: scrollStrat
      });
    dialogRef.afterClosed().subscribe(confirm => {
      this.onTaskAdded.emit(true);
      this.onCamAdded.emit(true);
      this.cleanCAM();
      this.cleanGcu();
    });

  }

}
