import { Injectable, Injector } from '@angular/core';
import { BluetoothCore } from '@manekinekko/angular-web-bluetooth';
import { Observable, EMPTY, of } from 'rxjs';
import { mergeMap, catchError, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TagReadService {
  static GATT_CHARACTERISTIC = 0xffe1;
  static GATT_PRIMARY_SERVICE = 0xffe0;
  ble: BluetoothCore;
  server: BluetoothRemoteGATTServer;

  constructor(injector: Injector) {
    if (navigator.bluetooth) {
      this.ble = injector.get(BluetoothCore);
      this.ble.getGATT$().subscribe ( s => {
        this.server = s;
      });
    }
  }

  getConnectedDevice(): Observable<void|BluetoothRemoteGATTCharacteristic> {
    return this.server ?
        this.ble.getPrimaryService$(this.server, TagReadService.GATT_PRIMARY_SERVICE)
      .pipe(
        mergeMap( (primaryService: BluetoothRemoteGATTService) =>
          this.ble.getCharacteristic$(primaryService, TagReadService.GATT_CHARACTERISTIC),
        ),
        catchError( _ => EMPTY)
      ) : EMPTY;
  }

  getTagReaderCharacteristic(): Observable<void|BluetoothRemoteGATTCharacteristic> {
    return this.ble ?
      this.ble.discover$({
        acceptAllDevices: true,
        optionalServices: [TagReadService.GATT_PRIMARY_SERVICE]
      })
      .pipe(
        mergeMap( (gatt: BluetoothRemoteGATTServer)  =>
          this.ble.getPrimaryService$(gatt, TagReadService.GATT_PRIMARY_SERVICE)
        ),
        mergeMap( (primaryService: BluetoothRemoteGATTService) =>
          this.ble.getCharacteristic$(primaryService, TagReadService.GATT_CHARACTERISTIC),
        ),
        catchError( _ => EMPTY )
      ) : EMPTY;
  }
}
