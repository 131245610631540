import { Component, Input, HostListener, ElementRef, ContentChild,
  AfterContentInit, OnDestroy, Renderer2, ViewEncapsulation, OnChanges,
  SimpleChanges, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { SelectDirective } from './select.directive';
import { Subscription } from 'rxjs';

export interface SelectOptionConfig {
  label: string;
  value: string;
  index?: number;
}
export interface SelectConfig {
  search?: boolean;
  small?: boolean;
  toolbar?: {};
  left_icon?: string;
  right_icon?: string;
  clearOption?: boolean;
  placeholder?: string;
  top?: boolean;
  options: SelectOptionConfig [];
  groups?: {
    label: string;
    collapsible: boolean;
    optionIndexes: number[];
  }[];
}
// ?? ControlValueAccessor https://alligator.io/angular/custom-form-control/
@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormSelectComponent implements AfterContentInit, OnDestroy, OnChanges, AfterViewInit {

  @Input() config: SelectConfig;
  @ContentChild(SelectDirective) controller: SelectDirective;

  isActive: boolean;
  readonly: boolean;
  hasClearOption: boolean;
  selectedIndex: number[] = [];
  selectedGroupOptionIndex: number[][] = [];
  filteredOptions: SelectOptionConfig[];
  subscriptionRefs: Subscription[] = [];
  constructor(private elRef: ElementRef, private renderer: Renderer2, private cdRef: ChangeDetectorRef) { }

  @HostListener('document:click', ['$event.target']) onClick(el) {
    // detect click outside to close select menu
    if (!this.elRef.nativeElement.contains(el)) {
      this.isActive = false;
      this.controller.setTouched();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    (changes.config.currentValue.options as SelectOptionConfig[]).forEach( (opt, idx) => opt.index = idx);
    this.filteredOptions = changes.config.currentValue.options ;

    if (!changes.config.firstChange) {
      const newSelectedIndex: number[] = [];
      this.selectedIndex.forEach(idx => {
        newSelectedIndex.push( (changes.config.currentValue.options as SelectOptionConfig [])
        .findIndex( val => (val && changes.config.previousValue.options[idx])
                        && (val.value === (changes.config.previousValue.options[idx] as SelectOptionConfig).value)));
      });
      this.selectedIndex = newSelectedIndex;
    }
    // TODO changes on groups & multiple
  }

  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) {s.unsubscribe(); }});
  }

  ngAfterViewInit() {
    const select = ((this.elRef.nativeElement).querySelector('select') as HTMLSelectElement);
    if (select) {
      this.readonly = select.attributes.getNamedItem('readonly') ? true : false;
    }
   /* const clearbtn = ((this.elRef.nativeElement).querySelector('.btn-clear') as HTMLButtonElement);
    if (clearbtn) {
      if (this.readonly) {
        this.renderer.addClass(clearbtn, 'hide');
      } else {
        this.hasClearOption = true;
      }
    }*/
    this.cdRef.detectChanges();
  }

  ngAfterContentInit() {
    const label = ((this.elRef.nativeElement).querySelector('label') as HTMLElement);
    if (label) {
      if (this.controller.required !== undefined) {
        this.renderer.addClass(label, 'required');
      }
      this.renderer.setAttribute(label, 'for', this.controller.id);
    }
    const clearbtn = ((this.elRef.nativeElement).querySelector('.btn-clear') as HTMLButtonElement);
    if (clearbtn) {
      this.hasClearOption = true;
      this.renderer.addClass(clearbtn, 'btn-primary');
      const span = this.renderer.createElement('span');
      this.renderer.addClass(span, 'sr-only');
      const text = this.renderer.createText('Clear text');
      this.renderer.appendChild(span, text);
      const i = this.renderer.createElement('i');
      this.renderer.addClass(i, 'icons-close');
      this.renderer.appendChild(clearbtn, span);
      this.renderer.appendChild(clearbtn, i);
      this.controller.addClass('clear-option');
      clearbtn.addEventListener('click', ($event) => {
        $event.stopPropagation();
        this.selectedIndex = [];
        if (this.controller.multiple) {
          this.buildSelectedGroupOption();
        }
        this.controller.setTouched();
        this.controller.setValue(this.controller.multiple ? [] : null, -1);
      });
    }
    const helper = ((this.elRef.nativeElement ).querySelector('.helper-group') as HTMLElement);
    if (helper) {
      if (helper.id) {
        this.controller.addAttribute('ariaDescribedby', helper.id);
      }
      this.renderer.addClass(helper, 'form-text');
      this.renderer.addClass(helper, 'text-muted');
    }
    const counter = ((this.elRef.nativeElement).querySelector('.counter-group') as HTMLElement);
    if (counter) {
      this.renderer.addClass(counter, 'mt-2');
      this.renderer.addClass(counter, 'font-weight-medium');
      this.renderer.setAttribute(counter, 'data-role', 'counter');
    }
    if (this.controller.ngControl) {
      // Manage case where selected index is not updated cause changes doesn't comes from interface
      this.subscriptionRefs.push(this.controller.ngControl.valueChanges.subscribe((val) => {
        if (!val || val.length !== this.selectedIndex.length
          || !this.selectedIndex.every((index) => val.indexOf(this.config.options[index].value) !== -1)) {
            this.selectedIndex = [];
            if (val === null ||  val === undefined) {
              this.buildSelectedGroupOption();
              return;
            }
            if (val instanceof Array) { // Multiple
              for (const iterator of val) {
                this.selectedIndex.push(this.config.options.findIndex((option) => option.value === iterator));
              }
              this.buildSelectedGroupOption();
            } else { // Single
              this.selectedIndex.push(this.config.options.findIndex((option) => option.value === val));
            }
        }
      }));
    }
  }

  buildSelectedGroupOption() {
    // if there's groups
    if (this.config.groups) {
      // init groups array
      this.selectedGroupOptionIndex = [];
      // for each group
      for (let i = 0; i < this.config.groups.length; i++ ) {
        // init group's selected index array
        this.selectedGroupOptionIndex[i] = [];
        // for each index possibly in the group
        for (let j = 0; this.selectedIndex && j < this.config.groups[i].optionIndexes.length ; j++ ) {
          // if it is selected
          if (this.selectedIndex.indexOf(this.config.groups[i].optionIndexes[j] ) !== -1) {
            // store in group's selected index array
            this.selectedGroupOptionIndex[i].push(this.config.groups[i].optionIndexes[j]);
          }
        }
      }
    }
  }

  /*
  clear($event) {
    $event.stopPropagation();
    this.selectedIndex = [];
    if (this.appSelect.multiple) {
      this.buildSelectedGroupOption();
    }
    this.appSelect.setTouched();
    this.appSelect.setValue([], -1);
  }
  */
  toggleAll($event: Event) {
    $event.stopPropagation();
    if (this.selectedIndex.length === this.config.options.length ) {
      this.selectedIndex = [];
    } else {
      this.selectedIndex = [];
      for (let i = 0; i < this.config.options.length; i++ ) {
        this.selectedIndex.push(i);
      }
    }
    this.setMultipleOptions();
  }

  toggleGroup(index: number) {
    const groupOptions = this.config.groups[index].optionIndexes;
    if (!this.selectedGroupOptionIndex[index] || this.selectedGroupOptionIndex[index].length < groupOptions.length) {
      for (const iterator of groupOptions) {
        if (!(this.selectedIndex.indexOf(iterator) !== -1)) {
          this.selectedIndex.push(iterator);
        }
      }
    } else {
      for (const iterator of groupOptions) {
        if (this.selectedIndex.indexOf(iterator) !== -1) {
          this.selectedIndex = this.selectedIndex.filter((val) => val !==  iterator );
        }
      }
    }
    this.setMultipleOptions();
  }

  toggleOption(index: number) {
    if (this.selectedIndex.indexOf(index) !== -1 ) {
      this.selectedIndex = this.selectedIndex.filter((val) => val !==  index );
    } else {
      this.selectedIndex.push(index);
    }
    this.setMultipleOptions();
  }

  setMultipleOptions() {
    const values = [];
    for (const iterator of this.selectedIndex) {
      values.push(this.config.options[iterator].value);
    }
    this.buildSelectedGroupOption();
    this.controller.setValue(values, this.selectedIndex);
  }

  onOptionSelected(index: number) {
    if (!this.controller.multiple) {
      this.isActive = false;
    }
    if (this.selectedIndex[0] ===  index) {
      return;
    }
    this.selectedIndex = [index];
    this.controller.setValue(this.config.options[index].value, index);
  }

  applyFilter(elem: EventTarget) {
    const filter = (elem as HTMLInputElement).value.toUpperCase();
    this.filteredOptions = this.config.options.filter( opt => opt.label.toUpperCase().indexOf(filter) > -1);
  }
}
