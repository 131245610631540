import { Directive, HostBinding, ElementRef, Self, Optional, Input, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appSelect]'
})
export class SelectDirective implements OnInit {

  @HostBinding('class.form-control') formcontrolClass = true;
  @HostBinding('attr.aria-hidden') 'true';
  @HostBinding('attr.tabindex') '-1';
  @HostBinding('class.sr-only') srOnlyClass = true;

  @Output() valueChange = new EventEmitter();
  @Input() required;
  @Input() multiple;
  @Input() id: string;
  identifier: string;

  nativeDirty = false;
  nativeTouched = false;

  constructor(
    private elRef: ElementRef, private renderer: Renderer2,
    /*@Optional() parentForm: NgForm,
    @Optional() parentFormGroup: FormGroupDirective,*/
    @Self() @Optional() public ngControl: NgControl
  ) { }

  ngOnInit() {
    this.required = this.required !== undefined ;
    this.multiple = this.multiple !== undefined ;
    this.identifier = (this.id || '') + Math.floor(Math.random() * 10000);
    this.elRef.nativeElement.selectedIndex = -1;
  }

  isValid(): boolean {
    return this.ngControl ? this.ngControl.status === 'VALID'
                          : this.elRef.nativeElement.validity.valid;
  }

  isDirty(): boolean {
    return this.ngControl ? this.ngControl.dirty
                          : this.nativeDirty;
  }

  isTouched(): boolean {
    return this.ngControl ? this.ngControl.touched
                          : this.nativeTouched;
  }

  setTouched(): void {
    if ( this.ngControl && this.ngControl.dirty  && !this.ngControl.touched) {
      this.ngControl.control.markAsTouched();
    } else if (!this.ngControl && this.nativeDirty) {
      this.nativeTouched = true;
    }
  }

  addClass(newClass: string) {
    this.renderer.addClass(this.elRef.nativeElement, newClass);
  }

  addAttribute(newAttr: string, val: string) {
    this.renderer.setAttribute(this.elRef.nativeElement, newAttr, val);
  }

  setValue(value: any, index: number | number[]): void {
    if (this.ngControl) {
      this.ngControl.control.setValue(value);
      if (this.ngControl.pristine) {
        this.ngControl.control.markAsDirty();
      }
    } else {
      this.elRef.nativeElement.value = value;
      this.nativeDirty =  true ;
      if (index instanceof Array) {
        for (let i = 0; i < this.elRef.nativeElement.options.length; i++) {
          this.elRef.nativeElement[i].selected = (index.indexOf(i) !== -1);
        }
        if (index.length === 0) {
          this.elRef.nativeElement.selectedIndex = -1;
        }
      } else {
        this.elRef.nativeElement.selectedIndex = index;
      }
    }
    this.valueChange.emit({value, index});
  }
}
