import { Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateChild, CanDeactivate} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AuthRoleEnum } from './auth-role.enum';
import { LoggerService } from '../logging/logger.service';
import { AgentService } from '../agent/agent.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

/**
 * `AuthGuardService` implements `CanActivate` & `CanActivateChild` to manage guards on route activation
 */
@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild, CanDeactivate<CanComponentDeactivate> {
  /**
   * AuthGuardService constructor is responsible for setting logger for auth-service
   * @param authService Inject application's authentication service
   * @param router Angular router
   * @param logger Inject log service
   * @param agentService Inject agent datas service
   */
  constructor(private authService: AuthService, private router: Router,
              private logger: LoggerService, private agentService: AgentService) {
    this.logger = logger.newLogger('auth-guard-service');
  }
  /**
   * Whether route can be activated
   * @param route Angular component's activated route
   * @param state Angular router state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.logger.trace('{} : vérification de l\'authentification avant activation', route.url, route.data);

    // Vérifie que l'utilisateur est connecté
    if (!this.authService.isLogged().getValue()) {
      this.logger.trace('Activation refusé. L\'utilisateur n\'est pas connecté');
      this.redirectUnauthorized();
      return false;
    }

    // Si l'activation requière que l'utilisateur possède un ou
    // plusieurs rôles
    if (route.data && Array.isArray(route.data.roles)) {
      if (!this.authService.hasRoles((route.data.roles as [[AuthRoleEnum] | AuthRoleEnum]))) {
        this.logger.trace('Activation refusé. L\'utilisateur ne possède pas les rôles : ', route.data.roles);
        this.redirectForbiden(route.data);
        return false;
      }
    }
    // Si l'activation requière que l'utilisateur possède une ou
    // plusieures PFO
    if (route.data && route.data.fp) {
      if (this.agentService.getProcedureArray().getValue().length) {
        if (!this.agentService.hasFunctionalProcedures((route.data.fp as string | string[]))) {
          this.logger.trace('Activation refusé. L\'utilisateur ne possède pas les PFO : ', route.data.fp);
          this.redirectForbiden(route.data);
          return false;
        }
      } else {
        return this.agentService.getHabilitationsLoaded()
        .pipe(
          map( _ => {
            if (!this.agentService.hasFunctionalProcedures((route.data.fp as string | string[]))) {
                this.logger.trace('Activation refusé. L\'utilisateur ne possède pas les PFO : ', route.data.fp);
                this.redirectForbiden(route.data);
                return false;
              }
            return true;
            }
          )
        );
      }
    }

    // Si l'activation requière que l'utilisateur possède au moins une ou PFO parmis une liste
    if (route.data && Array.isArray(route.data.oneFpIn)) {
      if (this.agentService.getProcedureArray().getValue().length) {
        if (!this.agentService.hasAtLeastOneFunctionalProcedure(route.data.oneFpIn)) {
          this.logger.trace('Activation refusé. L\'utilisateur ne possède aucune des PFO : ', route.data.oneFpIn);
          this.redirectForbiden(route.data);
          return false;
        }
      } else {
        return this.agentService.getHabilitationsLoaded()
        .pipe(
          map( _ => {
              if (!this.agentService.hasAtLeastOneFunctionalProcedure(route.data.oneFpIn)) {
                this.logger.trace('Activation refusé. L\'utilisateur ne possède aucune des PFO : ', route.data.oneFpIn);
                this.redirectForbiden(route.data);
                return false;
              }
              return true;
            }
          )
        );
      }
    }

    this.logger.trace('Activation autorisée. L\'utilisateur est connecté');
    return true;
  }
  /**
   * Whether child route can be activated
   * @param childRoute Angular child component's activated route
   * @param state Angular router state
   */
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.canActivate(childRoute, state);
  }


  canDeactivate(component: CanComponentDeactivate): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate ? component.canDeactivate() : true;
  }

  /**
   * Action l'orsque l'utilisateur n'est pas connecté
   */
  private redirectUnauthorized(): void {
    this.logger.trace('Redirection vers la page /401');
    this.router.navigate(['/401']);
  }

  /**
   * Action l'orsque l'utilisateur n'est pas habilité
   */
  private redirectForbiden(data) {
    this.logger.trace('Redirection vers la page /403');
    this.router.navigate(['/403'], {state: data});
  }

}
