import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { finalize } from 'rxjs/operators';
import { ExpertiseService } from 'src/app/modules/consultation/expertise/services/expertise.service';
import { Cam4 } from 'src/app/shared/models/cam4';
import { ExpertiseDetail } from 'src/app/shared/models/expertise-detail';
import { ExpertiseTask } from 'src/app/shared/models/expertise-task';
import { ExpertiseTaskMaintenanceAid } from 'src/app/shared/models/expertise-task-maintenance-aid';
import { ExpertiseTaskRefMaterial } from 'src/app/shared/models/expertise-task-ref-material';
import { ExpertiseTaskRefWork } from 'src/app/shared/models/expertise-task-ref-work';
import { MaintenanceAid } from 'src/app/shared/models/maintenance-aid';
import { RefMaterial } from 'src/app/shared/models/ref-material';
import { RefWork } from 'src/app/shared/models/ref-work';
import { FilePreviewOverlayService } from 'src/app/shared/services/file-preview-overlay/file-preview-overlay.service';
import { ReferenceService } from 'src/app/shared/services/reference/reference.service';
import { ConfirmationAlertComponent } from '../../end-of-work-alert/confirmation-alert.component';
import { TemplatePopupDialogComponent } from '../../template-popup-dialog/template-popup-dialog.component';
import { WagonService } from 'src/app/shared/services/wagon/wagon.service';

@Component({
  selector: 'app-expertise-tasks',
  templateUrl: './expertise-tasks.component.html',
  styleUrls: ['./expertise-tasks.component.scss']
})
export class ExpertiseTasksComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public pExpertise: ExpertiseDetail;
  @Input() public activeTab: number;
  @Input() public numTasks: number;
  @Input() public numPhotos: number;
  @Output() cancelEvent = new EventEmitter();
  @Output() saveEvent = new EventEmitter();

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Form controls */
  expertiseTasksForm: FormGroup;
  addCamSelected: string;
  camNbPosSelected: string;
  addMaterialSelected: string;
  addMaterialQty: FormControl;
  addWorkSelected: string;
  addWorkQty: FormControl;
  addWorkTpsExp: FormControl;
  addWorkTpsReal: FormControl;
  observation: FormControl;

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  camColumns: string[] = ['codesCam', 'labelsCam', 'statusCam', 'actionCam'];
  camDS: MatTableDataSource<ExpertiseTaskMaintenanceAid>[] = [];
  stateToDoColor: string;
  stateDoneColor: string;
  devisFixedPrice: number;
  /** Liste « CAM à réaliser » */
  camList: Cam4[] = [];
  /** Liste « Nb/Pos » */
  nbPosList: string[];
  camToDoSelected: Cam4;

  materialColumns: string[] = ['codesMat', 'labelsMat', 'qtyReservedMat', 'qtyOrderedMat', 'qtyMat', 'actionMat'];
  materialDS: MatTableDataSource<FormGroup>[] = [];
  materialList: RefMaterial[] = [];
  materialSelected: RefMaterial;

  workColumns: string[] = ['codesWork', 'labelsWork', 'qtyWork', 'tpsExpWork', 'tpsRealWork', 'actionWork'];
  workDS: MatTableDataSource<FormGroup>[] = [];
  workList: RefWork[] = [];
  workSelected: RefWork;

  camListStatic: Cam4[][] = [];
  materialListStatic: RefMaterial[][] = [];
  workListStatic: RefWork[][] = [];

  /** Taux horaire national pour les BT de type INTERVENTION */
  hourlyCost: number;
  mTemp: string;
  mMaT: number;
  mTotEst: number;

  camErrorMessage: string;
  materialErrorMessage: string;
  workErrorMessage: string;
  validationErrorMessages: string[];

  expertiseModified: boolean;

  taskPhotoUrlList: any[];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private expertiseService: ExpertiseService,
    private referenceService: ReferenceService,
    private previewService: FilePreviewOverlayService,
    private domSanitizer: DomSanitizer,
    private wagonService: WagonService
  ) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.camList = [];
    this.materialList = [];
    this.workList = [];
    this.addCamSelected = '';
    this.camNbPosSelected = null;
    this.addMaterialSelected = '';
    this.addMaterialQty = this.formBuilder.control('');
    this.addWorkSelected = '';
    this.addWorkQty = this.formBuilder.control('');
    this.addWorkTpsExp = this.formBuilder.control('');
    this.addWorkTpsReal = this.formBuilder.control('');
    this.observation = this.formBuilder.control('');
    this.expertiseTasksForm = this.formBuilder.group({
      addMaterialQty: this.addMaterialQty,
      addWorkQty: this.addWorkQty,
      addWorkTpsExp: this.addWorkTpsExp,
      addWorkTpsReal: this.addWorkTpsReal,
      observation: this.observation
    });
    this.camErrorMessage = null;
    this.materialErrorMessage = null;
    this.workErrorMessage = null;
    this.expertiseModified = false;
    this.loadInitDatas();
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
    this.loadingSubject.complete();
  }

  /** Initialize the ErrorMessage */
  initErrorMessage(): void {
    this.camErrorMessage = null;
    this.materialErrorMessage = null;
    this.workErrorMessage = null;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeTab) {
      this.initErrorMessage();
      this.refreshLists(changes.activeTab.currentValue);
      this.refreshPhotos();
    } else if (changes.numTasks) {
      this.checkNewTask();
      if (changes.numTasks.currentValue === 1) {
        this.refreshLists(this.activeTab);
      }
    } else if (changes.numPhotos) {
      this.refreshPhotos();
    }
  }

  loadInitDatas(): void {
    this.subscriptionRefs.push(
      forkJoin(
        this.expertiseService.getHourlyCosts('1'),
        this.referenceService.getStateJobs(),
        this.referenceService.getTechVariable('PRIX_FIXE_DEVIS')
      ).subscribe(([hourCost, sj, tv]) => {
        this.hourlyCost =  hourCost.data.length && hourCost.data[0].value || 0;
        this.stateToDoColor = (sj.data.filter(s => s.code === "1"))[0].color;
        this.stateDoneColor = "green-off";
        this.devisFixedPrice = (new Number(tv.data.varValeur)).valueOf();

        this.loadLists();
        this.calculateTotals();
      })
    );
  }

  loadLists(): void {
    this.camDS = [];
    // Order CAM list
    this.pExpertise.expertiseTaskList.forEach(t => {
      t.taskMaintenanceAidList.sort((a,b) => a.maintenanceAid.code.localeCompare(b.maintenanceAid.code));
    });
    this.pExpertise.expertiseTaskList.forEach(expTask => {
      this.camDS.push(new MatTableDataSource<ExpertiseTaskMaintenanceAid>(expTask.taskMaintenanceAidList));
    });

    this.materialDS = [];
    // Order Material list
    this.pExpertise.expertiseTaskList.forEach(t => {
      t.taskRefMaterialList.sort((a,b) => a.refMaterial.code.localeCompare(b.refMaterial.code));
    });

    this.pExpertise.expertiseTaskList.forEach(expTask => {
      const materials = this.formBuilder.array([]);
      materials.clear(); 
      expTask.taskRefMaterialList.forEach(refm => {
        materials.push(this.addMaterialControl(refm));
      })
      this.materialDS.push(new MatTableDataSource<FormGroup>(materials.controls as FormGroup[]));
    });

    this.workDS = [];
    // Order Works list
    this.pExpertise.expertiseTaskList.forEach(t => {
      t.taskRefWorkList.sort((a,b) => a.refWork.code.localeCompare(b.refWork.code));
    });

    this.pExpertise.expertiseTaskList.forEach(expTask => {
      const works = this.formBuilder.array([]);
      works.clear(); 
      expTask.taskRefWorkList.forEach(refw => {
        works.push(this.addWorkControl(refw));
      })
      this.workDS.push(new MatTableDataSource<FormGroup>(works.controls as FormGroup[]));
    });
  }

  refreshLists(active: number): void {

    if (this.pExpertise.expertiseTaskList.length === 0) {
      return;
    }

    this.initStaticLists();

    if (this.camListStatic[active] && this.camListStatic[active].length === 0) {
      // Get the lists from DB and store in the static lists
      this.loadingSubject.next(true);

      // Liste « CAM à réaliser »
      this.camList = [];
      // Liste Matieres
      this.materialList = [];
      // Liste Main d'ouvre
      this.workList = [];
      
      const orgCode = this.pExpertise.expertiseTaskList[active].component.code;
      const orgTraCode = this.pExpertise.expertiseTaskList[active].job.code;
      const wagonId = this.pExpertise.expertise.wagonId?this.pExpertise.expertise.wagonId:0;

      this.subscriptionRefs.push(
        forkJoin(
          this.expertiseService.getCamListsByExpertiseTask(orgCode, orgTraCode),
          this.expertiseService.getMaterialsByExpertise(orgCode, orgTraCode, wagonId),
          this.expertiseService.getWorksByExpertise(orgCode, orgTraCode)
        ).subscribe(([camexp, matexp, wrkexp]) => {
          this.camList = camexp.data;
          this.camList.forEach(op => op['codeLabel']=op.code + ' : ' + op.cam4Lib);
          this.materialList = matexp.data;
          this.materialList.forEach(op => op['codeLabel']=op.code + ' : ' + op.label);
          this.workList = wrkexp.data;
          this.workList.forEach(op => op['codeLabel']=op.code + ' : ' + op.label);
          this.workList.sort((a,b) => a.code.localeCompare(b.code));
          // Store in the static lists
          this.camListStatic[active] = camexp.data;
          this.materialListStatic[active] = matexp.data;
          this.workListStatic[active] = wrkexp.data;
          this.loadingSubject.next(false);
      }));
    } else {
      // Get the lists from the static lists
      this.camList = this.camListStatic[active];
      this.materialList = this.materialListStatic[active];
      this.workList = this.workListStatic[active];
    }

    this.nbPosList = [];
    this.addCamSelected = null;
    this.camNbPosSelected = null;
    this.materialSelected = null;
    this.addMaterialSelected = null;
    if (this.addMaterialQty) {
      this.addMaterialQty.setValue(null);
    }
    this.workSelected = null;
    this.addWorkSelected = null;
    if (this.addWorkQty) {
      this.addWorkQty.setValue(null);
      this.addWorkTpsExp.setValue(null);
      this.addWorkTpsReal.setValue(null);
    }
  }

  private initStaticLists(): void {
    if (this.camListStatic.length === 0) {
      this.pExpertise.expertiseTaskList.forEach(_ => {
        this.camListStatic.push([]);
        this.materialListStatic.push([]);
        this.workListStatic.push([]);
      })
    }
    
  }

  checkNewTask(): void {
    // Task added
    if (this.camListStatic.length !== this.pExpertise.expertiseTaskList.length) {
      this.camListStatic.push([]);
      this.materialListStatic.push([]);
      this.workListStatic.push([]);

      const numLastTask = this.pExpertise.expertiseTaskList.length - 1;

      this.camDS.push(new MatTableDataSource<ExpertiseTaskMaintenanceAid>(
        this.pExpertise.expertiseTaskList[numLastTask].taskMaintenanceAidList));

      const materials = this.formBuilder.array([]);
      materials.clear(); 
      this.pExpertise.expertiseTaskList[numLastTask].taskRefMaterialList.forEach(refm => {
        materials.push(this.addMaterialControl(refm));
      })
      this.materialDS.push(new MatTableDataSource<FormGroup>(materials.controls as FormGroup[]));


      const works = this.formBuilder.array([]);
      works.clear();
      
      this.pExpertise.expertiseTaskList[numLastTask].taskRefWorkList.forEach(refw => {
        works.push(this.addWorkControl(refw));
      })
      this.workDS.push(new MatTableDataSource<FormGroup>(works.controls as FormGroup[]));

      this.calculateTotals();
    }
  }


  updateCAM(event: Event, code: string, state: string): void {
    event.preventDefault();
    event.stopPropagation();

    const selectedCam = this.pExpertise.expertiseTaskList[this.activeTab].
                        taskMaintenanceAidList.filter(elem => elem.maintenanceAid.code === code)[0];
    if (state === 'I') {
      selectedCam.status = 'IE';
    } else if (state === 'IE' || state === 'E') {
      selectedCam.status = 'I';
    }
    this.tables.forEach(table => table.renderRows());
  }

  removeCamRow(event: Event, index: number): void {
    event.preventDefault();
    event.stopPropagation();
    
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('expertise-wagon.delete-message-title');
    const message = this.translateService.instant('expertise-wagon.delete-cam-message');
  
    dialogConfig.data = {
      namePopUp: 'cancellationOpenWagon',
      titlePopUp: title,
      msgPopUp: message
    };
  
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.pExpertise.expertiseTaskList[this.activeTab].taskMaintenanceAidList.splice(index, 1);
          this.tables.forEach(table => table.renderRows());
        }
    });
  }

  public onSelectCamToDo(): void {
    const camToDoSel = this.camList.filter(c => c['codeLabel'] === this.addCamSelected);
    this.camToDoSelected = (camToDoSel.length > 0) ? camToDoSel[0] : null;
    this.nbPosList = [];
    this.camNbPosSelected = null;
    if (this.camToDoSelected) {
      // CAM4
      if (this.camToDoSelected.code.length === 4) {
        this.subscriptionRefs.push(
          forkJoin(
            this.expertiseService.getCamListNbPos(this.camToDoSelected.code)
          ).subscribe(([nb]) => {
            this.nbPosList = nb.data;
          }));
      } else if (this.camToDoSelected.code.length === 6) {
        // CAM6
        this.nbPosList = [];
      }
    }
  }

  addCamRow():void {
    this.camErrorMessage = null;
    if (!this.camToDoSelected || this.addCamSelected!== (this.camToDoSelected.code + " : "+this.camToDoSelected.cam4Lib)) {
      return;
    }
    let codeToSearch = this.camToDoSelected.code;
    if (this.camToDoSelected.code.length === 4) {
      if (!this.camNbPosSelected) {
        this.camErrorMessage = this.translateService.instant('expertise-wagon.cam-nb-pos-required-message');
        return;
      }
      codeToSearch = this.camToDoSelected.code + this.camNbPosSelected;
    }
    let found = false;
    this.pExpertise.expertiseTaskList.forEach(t => {
      console.log("Pestaña: " +t.component.code +" : "
      +t.component.label + " Job: " + t.job.code+" : "+t.job.label);
      t.taskMaintenanceAidList.forEach(m => {
        console.log("maintenanceAid.code: "+ m.maintenanceAid.code + " maintenanceAid.label: "+ m.maintenanceAid.label );
      })
    })
    // On recherche si le CAM sélectionné existe déjà dans la liste lCAM
    console.log("Pestaña actual: " +this.pExpertise.expertiseTaskList[this.activeTab].component.code +" : "
    +this.pExpertise.expertiseTaskList[this.activeTab].component.label + " Job: " + this.pExpertise.expertiseTaskList[this.activeTab].job.code+" : "+this.pExpertise.expertiseTaskList[this.activeTab].job.label);
    /*this.pExpertise.expertiseTaskList[this.activeTab].taskMaintenanceAidList.forEach(t => {
      console.log("Petaña: " + this.activeTab + " ->t.maintenanceAid.code: "+ t.maintenanceAid.code + " codeToSearch: "+codeToSearch);
      if (t.maintenanceAid.code === codeToSearch) {
        found = true;
      }
    })*/
    this.pExpertise.expertiseTaskList.forEach(t => {
        t.taskMaintenanceAidList.forEach(m => {
          console.log("->t.maintenanceAid.code: "+ m.maintenanceAid.code + " codeToSearch:"+codeToSearch);
          if (m.maintenanceAid.code === codeToSearch) {
            found = true;
          }
        })
    });
    if (found) {
      // On recherche si le CAM trouvé est associé à une autre tâche que celle en cours,
      // dans ce cas, on affiche un message d’erreur sinon on ne fait rien.
      //let index = 0;
      found = false;
      let componentActiveTab = this.pExpertise.expertiseTaskList[this.activeTab].component;
      let jobActiveTab = this.pExpertise.expertiseTaskList[this.activeTab].job;
      this.pExpertise.expertiseTaskList.forEach(t => {
        console.log("Petaña: " + t.component.code+": "+t.component.label);
        if (componentActiveTab.code != t.component.code && jobActiveTab.code!=t.job.code){
          t.taskMaintenanceAidList.forEach(m => {
            console.log("->t.maintenanceAid.code: "+ m.maintenanceAid.code + " codeToSearch:"+codeToSearch);
            if (m.maintenanceAid.code === codeToSearch) {
              found = true;
            }
           
          })
        }
      });
      if (found) {
        this.camErrorMessage = this.translateService.instant('expertise-wagon.cam-duplicated-message');
      }
    } else {
      // Ajouter le CAM a la liste
      const expAid = new ExpertiseTaskMaintenanceAid();
      expAid.status = "I";
      expAid.maintenanceAid = new MaintenanceAid();
      expAid.maintenanceAid.code = codeToSearch;
      expAid.maintenanceAid.label = this.camToDoSelected.cam4Lib;
      this.pExpertise.expertiseTaskList[this.activeTab].taskMaintenanceAidList.push(expAid);
      this.pExpertise.expertiseTaskList[this.activeTab].taskMaintenanceAidList.sort((a,b) => a.maintenanceAid.code.localeCompare(b.maintenanceAid.code));
      this.tables.forEach(table => table.renderRows());

      this.addCamSelected = null;
      this.camNbPosSelected = null;
    }
  }

  private addMaterialControl(refMat: ExpertiseTaskRefMaterial) {
    return this.formBuilder.group({
      codesMat: this.formBuilder.control(refMat.refMaterial.code),
      labelsMat: this.formBuilder.control(refMat.refMaterial.label),
      qtyReservedMat: this.formBuilder.control(refMat.dedicatedAmount),
      qtyOrderedMat: this.formBuilder.control(refMat.inOrderAmount),
      qtyMat: this.formBuilder.control(refMat.amount)
    });
  }

  removeMaterialRow(event: Event, index: number): void {
    event.preventDefault();
    event.stopPropagation();
    
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('expertise-wagon.delete-message-title');
    const message = this.translateService.instant('expertise-wagon.delete-material-message');
  
    dialogConfig.data = {
      namePopUp: 'cancellationOpenWagon',
      titlePopUp: title,
      msgPopUp: message
    };
  
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.pExpertise.expertiseTaskList[this.activeTab].taskRefMaterialList.splice(index, 1);
          this.refreshMaterialDS();
        }
    });
  }

  private refreshMaterialDS(): void {
    const materials = this.formBuilder.array([]);
    this.pExpertise.expertiseTaskList[this.activeTab].taskRefMaterialList.forEach(refm => {
      materials.push(this.addMaterialControl(refm));
    });
    this.materialDS[this.activeTab]=new MatTableDataSource<FormGroup>(materials.controls as FormGroup[]);
    this.tables.forEach(table => table.renderRows());

    this.recalculateTotals();
  }

  public onSelectMaterial(): void {
    const materialSel = this.materialList.filter(m => m['codeLabel'] === this.addMaterialSelected);
    this.materialSelected = (materialSel.length > 0) ? materialSel[0] : null;
    if (this.materialSelected) {
      this.addMaterialQty.setValue(1);
    } else {
      this.addMaterialQty.setValue(null);
    }
  }

  addMaterialRow():void {
    this.materialErrorMessage = null;
    if (!this.materialSelected || this.addMaterialSelected!== (this.materialSelected.code + " : "+this.materialSelected.label)) {
      return;
    }
    if (!this.addMaterialQty.value || (this.addMaterialQty.value <= 0)) {
        this.materialErrorMessage = this.translateService.instant('expertise-wagon.material-quantity-message');
        return;
    }
    let found = false;
    
    /*this.pExpertise.expertiseTaskList[this.activeTab].taskRefMaterialList.forEach(m => {
      console.log("Petaña: " + this.activeTab + " ->m.refMaterial.code: "+ m.refMaterial.code + " materialSelected: "+this.materialSelected.code);
      if (m.refMaterial.code === this.materialSelected.code) {
        found = true;
      }
    });*/
    this.pExpertise.expertiseTaskList.forEach(t => {
      console.log("Petaña: " + t.component.code+": "+t.component.label);
        t.taskRefMaterialList.forEach(m => {
          console.log("->m.refMaterial.code: "+ m.refMaterial.code + " materialSelected:"+ this.materialSelected.code);
          if (m.refMaterial.code === this.materialSelected.code) {
            found = true;
          }
        })
    });
    if (found) {
     // let index = 0;
     // On recherche si la matière trouvée est associée à une autre tâche que celle en cours,
    // dans ce cas, on affiche un message d’erreur sinon on ne fait rien.
      found = false;
      let componentActiveTab = this.pExpertise.expertiseTaskList[this.activeTab].component;
      let jobActiveTab = this.pExpertise.expertiseTaskList[this.activeTab].job;
      this.pExpertise.expertiseTaskList.forEach(t => {
        console.log("Petaña: " + t.component.code+": "+t.component.label);
        if (componentActiveTab.code != t.component.code && jobActiveTab.code!=t.job.code){
          t.taskRefMaterialList.forEach(m => {
            console.log("->m.refMaterial.code: "+ m.refMaterial.code + " materialSelected:"+ this.materialSelected.code);
            if (m.refMaterial.code === this.materialSelected.code) {
              found = true;
            }
          })
        }
      });
      if (found) {
        this.materialErrorMessage = this.translateService.instant('expertise-wagon.material-duplicated-message');
      }
    } else {
      // Ajouter la matiere a la liste
      const expRefMat = new ExpertiseTaskRefMaterial();
      expRefMat.amount = this.addMaterialQty.value;
      expRefMat.dedicatedAmount = 0;
      expRefMat.inOrderAmount = 0;
      expRefMat.refMaterial = new RefMaterial();
      expRefMat.refMaterial.code = this.materialSelected.code;
      expRefMat.refMaterial.label = this.materialSelected.label;
      expRefMat.refMaterial.unitPrice = this.materialSelected.unitPrice;
      this.pExpertise.expertiseTaskList[this.activeTab].taskRefMaterialList.push(expRefMat);
      this.pExpertise.expertiseTaskList[this.activeTab].taskRefMaterialList.sort((a,b) => a.refMaterial.code.localeCompare(b.refMaterial.code));

      this.refreshMaterialDS();

      this.addMaterialSelected = null;
      this.addMaterialQty.setValue(null);
    }
  }

  private addWorkControl(refWork: ExpertiseTaskRefWork) {
    return this.formBuilder.group({
      codesWork: this.formBuilder.control(refWork.refWork.code),
      labelsWork: this.formBuilder.control(refWork.refWork.label),
      qtyWork: this.formBuilder.control(refWork.amount),
      tpsExpWork: this.formBuilder.control(this.convertTimeToDate(refWork.expertiseTime)),
      tpsRealWork: this.formBuilder.control(this.convertTimeToDate(refWork.realTime)),
      tpsUnit: this.formBuilder.control(this.convertTimeToDate(refWork.unitTime))
    });
  }

  private convertTimeToDate(time: number): Date {
    const numHours = Math.trunc(time);
    const decimalTime = time - Math.floor(time);
    const numMinutes = Math.round((decimalTime)*60);
    const dtTime = new Date();
    dtTime.setHours(numHours);
    dtTime.setMinutes(numMinutes);
    return dtTime;
  }

  private convertDateToTime(dtTime: Date): number {
    if (!dtTime) {
      return 0;
    }

    const nDate = new Date(dtTime);
    const numHours = nDate.getHours();
    const numMinutes = nDate.getMinutes();
    return numHours.valueOf() + ((Math.round((numMinutes.valueOf()/60)*100))/100);
  }

  public onSelectWork(): void {
    const workSel = this.workList.filter(w => w['codeLabel'] === this.addWorkSelected);
    this.workSelected = (workSel.length > 0) ? workSel[0] : null;
    if (this.workSelected) {
      this.addWorkQty.setValue(1);
      this.addWorkTpsExp.setValue(this.convertTimeToDate(this.workSelected.unitTime));
      this.addWorkTpsReal.setValue(this.convertTimeToDate(this.workSelected.unitTime));
    } else {
      this.addWorkQty.setValue(null);
      this.addWorkTpsExp.setValue(null);
      this.addWorkTpsReal.setValue(null);
    }
  }

  removeWorkRow(event: Event, index: number): void {
    event.preventDefault();
    event.stopPropagation();
    
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('expertise-wagon.delete-message-title');
    const message = this.translateService.instant('expertise-wagon.delete-work-message');
  
    dialogConfig.data = {
      namePopUp: 'cancellationOpenWagon',
      titlePopUp: title,
      msgPopUp: message
    };
  
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList.splice(index, 1);
          this.refreshWorkDS();
        }
    });
  }

  private refreshWorkDS(): void {
    const works = this.formBuilder.array([]);
    this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList.forEach(refw => {
      works.push(this.addWorkControl(refw));
    });
    this.workDS[this.activeTab]=new MatTableDataSource<FormGroup>(works.controls as FormGroup[]);
    this.tables.forEach(table => table.renderRows());

    this.recalculateTotals();
  }

  addWorkRow():void {
    this.workErrorMessage = null;
    if (!this.workSelected || this.addWorkSelected!== (this.workSelected.code + " : "+this.workSelected.label)) {
      return;
    }
    if (!this.addWorkQty.value || (this.addWorkQty.value <= 0)) {
        this.workErrorMessage = this.translateService.instant('expertise-wagon.work-quantity-message');
        return;
    }
    if (!this.addWorkTpsExp.value) {
      this.workErrorMessage = this.translateService.instant('expertise-wagon.expertise-time-message');
      return;
  }
    let found = false;
    // On recherche si la MO est présente dans la liste main d’œuvre
    /*this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList.forEach(w => {
      if (w.refWork.code === this.workSelected.code) {
        found = true;
      }
    });*/
    this.pExpertise.expertiseTaskList.forEach(t => {
      console.log("Petaña: " + t.component.code + ": " + t.component.label);
        t.taskRefWorkList.forEach(m => {
          console.log("->m.refWork.code: "+ m.refWork.code + " workSelected: "+ this.workSelected.code);
          if (m.refWork.code === this.workSelected.code) {
            found = true;
          }
        })
    });
    if (found) {
      // On recherche si la MO trouvée est associée à une autre tâche que celle en cours,
      // dans ce cas, on affiche un message d’erreur sinon on ne fait rien.
      //let index = 0;
      found = false;
      let componentActiveTab = this.pExpertise.expertiseTaskList[this.activeTab].component;
      let jobActiveTab = this.pExpertise.expertiseTaskList[this.activeTab].job;
      this.pExpertise.expertiseTaskList.forEach(t => {
        console.log("Petaña: " + t.component.code + ": " + t.component.label);
        if (componentActiveTab.code != t.component.code && jobActiveTab.code!=t.job.code){
          t.taskRefWorkList.forEach(m => {
            console.log("->m.refWork.code: "+ m.refWork.code + " workSelected: "+ this.workSelected.code);
            if (m.refWork.code === this.workSelected.code) {
              found = true;
            }
          })
        }
      });
      if (found) {
        this.workErrorMessage = this.translateService.instant('expertise-wagon.work-duplicated-message');
      }
    } else {
      // Ajouter le Main d'ouvre a la liste
      const expRefWork = new ExpertiseTaskRefWork();
      expRefWork.amount = this.addWorkQty.value;
      expRefWork.expertiseTime = this.convertDateToTime(this.addWorkTpsExp.value);
      expRefWork.realTime = this.convertDateToTime(this.addWorkTpsReal.value);
      expRefWork.unitTime = this.workSelected.unitTime;
      expRefWork.refWork = new RefWork();
      expRefWork.refWork.code = this.workSelected.code;
      expRefWork.refWork.label = this.workSelected.label;
      this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList.push(expRefWork);
      this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList.sort((a,b) => a.refWork.code.localeCompare(b.refWork.code));

      this.refreshWorkDS();

      this.addWorkSelected = null;
      this.addWorkQty.setValue(null);
      this.addWorkTpsExp.setValue(null);
      this.addWorkTpsReal.setValue(null);
    }
  }

  updateValueMaterial(): void {
    // Maximum quantity control
    let index = 0;
    this.materialDS[this.activeTab].data.forEach(m => {
      if (m.value.qtyMat > 999) {
        m.value.qtyMat = this.getMaxQuantity(m.value.qtyMat);
        this.materialDS[this.activeTab].data[index].controls.qtyMat.setValue(m.value.qtyMat);
        return;
      }else if (m.value.qtyMat < 0){
        m.value.qtyMat = 0;
        this.materialDS[this.activeTab].data[index].controls.qtyMat.setValue(m.value.qtyMat);
        return;
      }
      if (m.value.qtyReservedMat > 999) {
        m.value.qtyReservedMat = this.getMaxQuantity(m.value.qtyReservedMat);
        this.materialDS[this.activeTab].data[index].controls.qtyReservedMat.setValue(m.value.qtyReservedMat);
        return;
      }else if (m.value.qtyReservedMat < 0){
        m.value.qtyReservedMat = 0;
        this.materialDS[this.activeTab].data[index].controls.qtyReservedMat.setValue(m.value.qtyReservedMat);
        return;
      }
      if (m.value.qtyOrderedMat > 999) {
        m.value.qtyOrderedMat = this.getMaxQuantity(m.value.qtyOrderedMat);
        this.materialDS[this.activeTab].data[index].controls.qtyOrderedMat.setValue(m.value.qtyOrderedMat);
        return;
      }else if (m.value.qtyOrderedMat < 0){
        m.value.qtyOrderedMat = 0;
        this.materialDS[this.activeTab].data[index].controls.qtyOrderedMat.setValue(m.value.qtyOrderedMat);
        return;
      }
      index++;
    });

    index = 0;
    this.materialDS[this.activeTab].data.forEach(m => {
      this.pExpertise.expertiseTaskList[this.activeTab].taskRefMaterialList[index].amount = m.value.qtyMat;
      this.pExpertise.expertiseTaskList[this.activeTab].taskRefMaterialList[index].dedicatedAmount = m.value.qtyReservedMat;
      this.pExpertise.expertiseTaskList[this.activeTab].taskRefMaterialList[index].inOrderAmount = m.value.qtyOrderedMat;
      index++;
    });

    this.recalculateTotals();
  }

  private getMaxQuantity(value: number): number {
    let valueStr = value.toString();
    valueStr = valueStr.substr(0, 3);
    return new Number(valueStr).valueOf();
  }

  updateValueWork(): void {
    // Maximum quantity control
    let index = 0;
    this.workDS[this.activeTab].data.forEach(m => {
      if (m.value.qtyWork > 999) {
        m.value.qtyWork = this.getMaxQuantity(m.value.qtyWork);
        this.workDS[this.activeTab].data[index].controls.qtyWork.setValue(m.value.qtyWork);
        return;
      }
      index++;
    });
    
    index = 0;
    this.workDS[this.activeTab].data.forEach(m => {
      if (m.value.qtyWork !== this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList[index].amount) {
        this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList[index].amount = m.value.qtyWork;
        // Si la MO concernée a un temps barème renseigné, on recalcule le temps expertisé avec le temps barème * quantité
        const lMOTb = this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList[index].unitTime;
        if (lMOTb && lMOTb !== 0) {
           const nTpsExpWork = lMOTb * this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList[index].amount;
           m.setValue({
              codesWork: m.value.codesWork,
              labelsWork: m.value.labelsWork,
              qtyWork: m.value.qtyWork,
              tpsExpWork: this.convertTimeToDate(nTpsExpWork),
              tpsRealWork: m.value.tpsRealWork,
              tpsUnit: m.value.tpsUnit
           });
        }
      }
      this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList[index].expertiseTime = 
        this.convertDateToTime(m.value.tpsExpWork);
      this.pExpertise.expertiseTaskList[this.activeTab].taskRefWorkList[index].realTime = 
        this.convertDateToTime(m.value.tpsRealWork);
      
        index++;
    });

    this.recalculateTotals();
  }

  recalculateTotals(): void {
    this.calculateTotals();
    this.expertiseModified = true;
  }

  calculateTotals(): void {
    this.mMaT = 0;
    let mMoT = 0;
    this.pExpertise.expertiseTaskList.forEach(t=> {
      // On mémorise le total des matières de la liste lMat de chaque tâche
      t.taskRefMaterialList.forEach(m => {
        this.mMaT += m.refMaterial.unitPrice * (m.amount?m.amount:0);
      });

      // On mémorise le produit quantité * temps en heure de chaque main d’œuvre de la liste lMO de chaque tâche
      t.taskRefWorkList.forEach(w => {
        if (w.unitTime && w.unitTime > 0) {
          mMoT += ((w.amount?w.amount:0) * w.unitTime);
        } else {
          mMoT += w.expertiseTime;
        }
      });
    });

    // On mémorise le temps facto (HH :MM)
    this.mTemp = this.convertTimeToString(mMoT);

    // Calcul de l'estimation du coût MO en euros
    const mTotMo = mMoT * this.hourlyCost;

    // Calcul de l'estimation du devis
    this.mTotEst = this.mMaT + mTotMo + this.devisFixedPrice;
  }

  private convertTimeToString(time: number): string {
    const numHours = Math.trunc(time);
    const decimalTime = time - Math.floor(time);
    const numMinutes = Math.round((decimalTime)*60);
    const strHours = ("0" + numHours).slice(-2);
    const strMinutes = ("0" + numMinutes).slice(-2);
    const str = strHours + ':' + strMinutes;
    return str;
  }

  updateAddMaterialQuantity(): void {
    if (this.addMaterialQty.value > 999) {
      this.addMaterialQty.setValue(this.getMaxQuantity(this.addMaterialQty.value));
      return;
    }else if (this.addMaterialQty.value < 0){
      this.addMaterialQty.setValue(null);
      return;
    }
  }

  updateAddWorkQuantity(): void {
    if (this.addWorkQty.value > 999) {
      this.addWorkQty.setValue(this.getMaxQuantity(this.addWorkQty.value));
      return;
    }else if (this.addWorkQty.value < 0){
      this.addWorkQty.setValue(null);
      return;
    }
  }

  cancelEditExpertise(): void {
    this.cancelEvent.emit(this.expertiseModified);
  }

  saveExpertise(): void {
    this.validateForm();
    if (this.validationErrorMessages && this.validationErrorMessages.length > 0) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
  
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('expertise-wagon.btn-validate');
    const message = this.translateService.instant('expertise-wagon.confirm-validate-message');
  
    dialogConfig.data = {
      namePopUp: 'cancellationOpenWagon',
      titlePopUp: title,
      msgPopUp: message
    };
  
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
        this.wagonService.setDisplayStatus(false);
        if (confirm) {
          this.loadingSubject.next(true);
          this.subscriptionRefs.push(
            this.expertiseService.saveExpertise(this.pExpertise)
          .subscribe(exp => {
            this.loadingSubject.next(false);
            //BLM 4421
            if ( exp.data.messageError) {
              this.showSaveErrorPopUpDialog(exp.data.messageError.message);
              return;
            }else if (!exp || !exp.data || !exp.data.id) {
              this.showSaveErrorPopUpDialog();
              return;
            }
            //BLM-4424
            this.wagonService.setCurrentWagonId(exp.data.wagonId);
            this.pExpertise.expertise.id = exp.data.id;
            this.expertiseModified = false;
            this.saveEvent.emit(true);
          })
          );
        }
    });
  }

  printExpertise(): void {
    this.validateForm();
    if (this.validationErrorMessages && this.validationErrorMessages.length > 0) {
      return;
    }
    this.loadingSubject.next(true);
    this.subscriptionRefs.push(
      this.expertiseService.saveExpertise(this.pExpertise)
    .subscribe(exp => {
      if (!exp || !exp.data || !exp.data.id) {
        this.loadingSubject.next(false);
        this.showSaveErrorPopUpDialog();
        return;
      }
      this.pExpertise.expertise.id = exp.data.id;
      this.expertiseModified = false;
      this.saveEvent.emit(false);
      this.subscriptionRefs.push(
        this.expertiseService.downloadReport(this.pExpertise.expertise.id)
        .pipe(
          finalize(() => this.loadingSubject.next(false))
        )
        .subscribe(
          (report) => {
            saveAs(report.blob, report.filename);
        })
      );
    })
    );
  }

  initDevisExpertise(): void {
    this.validateForm();
    if (this.validationErrorMessages && this.validationErrorMessages.length > 0) {
      return;
    }
    this.loadingSubject.next(true);
    this.subscriptionRefs.push(
      this.expertiseService.saveExpertise(this.pExpertise)
    .subscribe(exp => {
      if (!exp || !exp.data || !exp.data.id) {
        this.showSaveErrorPopUpDialog();
        return;
      }
      this.pExpertise.expertise.id = exp.data.id;
      this.expertiseModified = false;
      this.saveEvent.emit(false);
      this.loadingSubject.next(false);
      this.subscriptionRefs.push(
        this.expertiseService.existsDevisExpertise(this.pExpertise.expertise.id)
        .subscribe(exDevis => {
          if (exDevis && exDevis.data) {
            this.showConfirmationInitDevis();
          } else {
            this.initNewDevisExpertise();
          }
        })
      );
    })
    );
  }

  initNewDevisExpertise(): void {
    this.loadingSubject.next(true);
    this.subscriptionRefs.push(
      this.expertiseService.initDevisExpertise(this.pExpertise.expertise.id)
        .subscribe(expdev => {
          this.loadingSubject.next(false);
          if (!expdev || !expdev.data) {
              this.showSaveErrorPopUpDialog();
              return;
            }
        })
    );
  }

  /**
   * Show Confirmation message
   * @param msg
   */
   showConfirmationInitDevis(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';

    dialogConfig.data = {
      titlePopUp: this.translateService.instant('expertise-wagon.btn-init-devis'),
      msgPopUp: this.translateService.instant('expertise-wagon.init-devis-confirmation-message'),
      btnCancelLabel: this.translateService.instant('wagon.button_dialog.no'),
      btnAcceptLabel: this.translateService.instant('wagon.button_dialog.yes')
    };

    const dialogRef = this.dialog.open(ConfirmationAlertComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
          if (confirm) {
            this.initNewDevisExpertise();
          }
          this.dialog.closeAll();
    });
  }

  /**
   * RG-P2-CU1-F06-001
   * Vérification saisie expertise
   */
  private validateForm(): void {
    this.validationErrorMessages = [];
    this.pExpertise.expertiseTaskList.forEach(t => {
      t.taskRefMaterialList.forEach(m => {
        if (!m.amount) {
          this.addValidationError(t, m.refMaterial.code, this.translateService.instant('expertise-wagon.valid-material-quantity'));
        }
        if (m.dedicatedAmount == null) {
          this.addValidationError(t, m.refMaterial.code, this.translateService.instant('expertise-wagon.valid-material-reserved'));
        }
        if (m.inOrderAmount == null) {
          this.addValidationError(t, m.refMaterial.code, this.translateService.instant('expertise-wagon.valid-material-ordered'));
        }
        if ((m.amount!=null) && (m.dedicatedAmount!=null) && (m.inOrderAmount!=null) && (m.amount < (m.dedicatedAmount + m.inOrderAmount))) {
          this.addValidationError(t, m.refMaterial.code, this.translateService.instant('expertise-wagon.valid-material-quantity-sum'));
        }
      })
      t.taskRefWorkList.forEach(w => {
        if (!w.amount) {
          this.addValidationError(t, w.refWork.code, this.translateService.instant('expertise-wagon.valid-work-quantity'));
        }
        if (!w.expertiseTime) {
          this.addValidationError(t, w.refWork.code, this.translateService.instant('expertise-wagon.valid-work-tpsExp'));
        }
        if (w.expertiseTime && w.expertiseTime > 999.9) {
          this.addValidationError(t, w.refWork.code, this.translateService.instant('expertise-wagon.valid-work-tpsExp-max'));
        }
        if (w.expertiseTime && w.realTime > 999.9) {
          this.addValidationError(t, w.refWork.code, this.translateService.instant('expertise-wagon.valid-work-tpsReal-max'));
        }
      })
    })
  }

  private addValidationError(task: ExpertiseTask, code: string, message: string): void {
    this.validationErrorMessages.push(task.component.label.toUpperCase() + ' : ' + task.job.label.toUpperCase()
                                + ' - ' + code + ' - ' + message);
  }

  /**
   * Method to show an error Dialog
   */
  showSaveErrorPopUpDialog(msg?: String): void {
    //BLM 4421
    const title = this.translateService.instant('expertise-wagon.save-error-title');
    var message;
    if (msg){
      message = this.translateService.instant('expertise-wagon.'+msg);
    }else{
      message = this.translateService.instant('expertise-wagon.save-error-message');
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minHeight = '12.5rem';
    dialogConfig.minWidth = '13.75rem';
    dialogConfig.data = {
      namePopUp: 'alert_msg',
      titlePopUp: title,
      msgPopUp: message
    };
    this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
  }

  refreshPhotos(): void {
    if (this.pExpertise.expertiseTaskList[this.activeTab]) {
      const lMedia = this.pExpertise.expertiseTaskList[this.activeTab].mediaList;
      this.taskPhotoUrlList = [];
      if (lMedia && lMedia.length > 0) {
        lMedia.forEach(m => {
          if (m.content) {
            const reader = new FileReader();
            reader.onload = (_event) => {
              this.taskPhotoUrlList.push(reader.result);
              m.contentAsBase64 = reader.result.toString();
            }
            reader.readAsDataURL(m.content);
          } else if (m.contentAsBase64) {
            
            this.convertBase64ToBlob(m.contentAsBase64).then(value =>  m.contentAsBlob = value)
            this.taskPhotoUrlList.push(this.domSanitizer.bypassSecurityTrustUrl(m.contentAsBase64));
          }
        })
      }
    }
  }

  convertBase64ToBlob(contentAsBase64: string): any {
    return fetch(contentAsBase64)
    .then(res => { return res.blob() });
  }

  /**
   * Supprimer photo
   */
  deleteImage(photoIndex: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';

    dialogConfig.data = {
      titlePopUp: this.translateService.instant('expertise-wagon.remove-tooltip'),
      msgPopUp: this.translateService.instant('expertise-wagon.delete-photo-message'),
      btnCancelLabel: this.translateService.instant('wagon.button_dialog.no'),
      btnAcceptLabel: this.translateService.instant('wagon.button_dialog.yes')
    };

    const dialogRef = this.dialog.open(ConfirmationAlertComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
          if (confirm) {
            this.pExpertise.expertiseTaskList[this.activeTab].mediaList.splice(photoIndex, 1);
            this.taskPhotoUrlList.splice(photoIndex, 1);
            this.expertiseModified = true;
          }
          this.dialog.closeAll();
    });
  }

  showMediaPreview(photoIndex: number): void {
    this.previewService.open({
      blobMediaList: [
        { blob: this.pExpertise.expertiseTaskList[this.activeTab].mediaList[photoIndex].content?
                this.pExpertise.expertiseTaskList[this.activeTab].mediaList[photoIndex].content:
                this.pExpertise.expertiseTaskList[this.activeTab].mediaList[photoIndex].contentAsBlob,
          media: this.pExpertise.expertiseTaskList[this.activeTab].mediaList[photoIndex]}
      ]
    });
  }

  /**
   * The component mat-datetimepicker modifies the value for cdk-overlay-container z-index
   * so we have to restore the original value (1033)
   */
   unfocusModal(): void {
    (document.querySelector('.cdk-overlay-container') as HTMLElement).style.zIndex = "1033";
  }

  // BLM - 4392
  /**
   * Allowing: Integers pour le pb d'incopatibilité sur Firefox
   * @param event 
   */
  filterInput(event: any) {
    /**
     * Allowing: Integers 
     **/
     let numberEntered = false;
     if ((event.which >= 48 && event.which <= 57)) { //input number entered
      //console.log('input number entered :' + event.which + ' ' + event.keyCode + ' ' + event.charCode);
      numberEntered = true;
     }else{
      event.preventDefault();
     }
  }

  // BLM - 4392
  /**
   * check here if length of term matches 2 or less
   * @param term 
   * @returns 
   */
  addTagFn = (term) => {
    if (term.length <=2) {
      // check here if length of term matches 2 or less
        return term;
    }
    return null;
  }

  addTagCAMMaterialAndWork= (term) => {
    if (term.length <=100) {
      // check here if length of term matches 100 or less
        return term;
    }
    return null;
  }
}
