import { Component, OnDestroy, AfterContentInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { AgentService } from '../../services/agent/agent.service';
import { first } from 'rxjs/operators';
import { PcMessageService } from '../../services/pc-message/pc-message.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * `DashboardComponent` display a grid of application widgets
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnDestroy, AfterContentInit {
  
  userMessages: SafeHtml[] = [];
  /** Media Query list to check against for detect xs screens */
  mobileQuery: MediaQueryList;
  /** Media Query list to check against for detect medium screens */
  mobileQueryMedium: MediaQueryList;
  /** Grid cards definitions */
  cards = [];
  /** Retain all subscriptions */
  subscriptionRefs: Subscription[] = [];
  /**
   * `DashboardComponent` constructor sets `mobileQuery`  to perform device detection
   * @param media Cdk utility to check if media matches conditions
   */
  constructor(private media: MediaMatcher,
    
    private pcMessageService: PcMessageService,
              private translateService: TranslateService,
              public agentService: AgentService,
              private sanitizer: DomSanitizer) {
    this.mobileQuery = this.media.matchMedia('(max-width: 768px)');
    this.mobileQueryMedium = this.media.matchMedia('(max-width: 1200px)');

  }

  async ngAfterContentInit(): Promise<void> {
    this.subscriptionRefs.push(
      forkJoin([
        this.translateService.get('dashboard.message_card.title'),
        this.translateService.get('dashboard.expertise_card.title'),
        this.translateService.get('dashboard.indicators_card.title'),
        this.translateService.get('dashboard.refential_update_card.title'),
        this.translateService.get('dashboard.production_card.title'),
        this.translateService.get('dashboard.missing_prod_card.title')
      ])
      .subscribe(( [messageTitle, expertiseTitle, indicatorsTitle, referentialUpdateTitle, productionTitle, missingProdTitle] ) => {
        this.cards.push(
          {
            title: messageTitle,
            cols: 1,
            rows: 1,
            routerLink: null,
            icon: 'info',
            id: 'messages',
            functionalProc: null
          }
        );
        this.subscriptionRefs.push(this.agentService.getAgentData().subscribe (agent => {
          if (agent) {
            this.cards[0].title = messageTitle + ': ' + agent.company.code;
          }
        }));
        this.agentService.getProcedureArray().subscribe( (pfoArray) => {
          if (pfoArray.indexOf('DWE01') !== -1) {
              this.cards.push(
                {
                  title: expertiseTitle,
                   cols: 1,
                   rows: 1,
                   routerLink: '/consultation/expertises',
                   icon: 'traffic', id: 'expertises',
                   functionalProc: 'DWE01'
                },
              );
          }
        });
        this.cards.push(
          {title: indicatorsTitle + new Date().getFullYear().toString(),
            cols: 1,
            rows: 1,
            routerLink: null,
            icon: 'insert_chart_outlined',
            id: 'indicators',
            functionalProc: null
          },
          {
            title: referentialUpdateTitle,
            cols: 1,
            rows: 1,
            routerLink: null,
            icon: 'insert_drive_file',
            id: 'referentialUpdate',
            functionalProc: null
          },
          {
            title: productionTitle,
            cols: 1,
            rows: 1,
            routerLink: null,
            icon: 'train',
            id: 'production',
            functionalProc: null
          },
          {
            title: missingProdTitle,
            cols: 1,
            rows: 1 ,
            routerLink: null,
            icon: 'access_time',
            id: 'missingProduction',
            functionalProc: null
          }
        );
      })
    );
    const msgs = await this.pcMessageService.getUserMessagesBanner().pipe(first()).toPromise();
      this.userMessages = msgs.data.filter(m => m.label !== null).map( e => this.sanitizer.bypassSecurityTrustHtml(e.label));
  }



  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {if (s && !s.closed) { s.unsubscribe(); }});
  }

}
