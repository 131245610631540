import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

export interface TableFiltersItem {
  /** item label */
  label: string;
  /** item value (checked/unchecked) */
  value: boolean;
  /** item model name */
  model: string;
}

export interface TableFiltersGroup  {
  /** group title */
  title: string;
  /** group items */
  items: TableFiltersItem[];
  /** whether group is initially expanded */
  expanded: boolean;
}

export interface TableFiltersConfig  {
  /** Whether filters menu is open */
  active: boolean;
  /** table filter title */
  title: string;
  /** table filter groups */
  groups: TableFiltersGroup[];
  /** table filter items */
  items: TableFiltersItem[];
}
/** `TableFiltersComponent` provide a configuration widget for `action bar buttons` */
@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss']
})
export class TableFiltersComponent implements OnInit {
  /** form model */
  model = {};
  /** configuration */
  @Input() config: TableFiltersConfig;
  /** Subscribable click outside event emitter */
  @Output() closed = new EventEmitter();
  /** filters form  */
  @ViewChild('filtersForm') filtersForm: NgForm;
  /** TableFiltersComponent constructor */
  constructor() { }

  ngOnInit(): void {
    this.config.items.forEach(item => {
      this.model[item.model] = item.value;
    });
    this.config.groups.forEach(group => {
      group.items.forEach(item => {
        this.model[item.model] = item.value;
      });
    });
  }

  onClose() {
    this.config.active = false;
    this.closed.emit(this.filtersForm.value);
  }
}
