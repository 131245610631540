import { RefMaterial } from './ref-material';

/** API model:Table: R_WPHY_MATREF_MAJ */
export class RefMaterialUpdate {
    /** WMATRMAJ_NBRE */
    number: number;
    /** refMaterialUpdate.code = WMATRMAJ_MATREF_CODE_PFK */
    refMaterial: RefMaterial;

    hhrModelNumber: string;

    sendingDate: Date;

    expectedDate: Date;

    receiptDate: Date;
}
