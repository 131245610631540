import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';

@Component({
  selector: 'app-confirmation-alert',
  template: `
  <h5 mat-dialog-title>{{ data.titlePopUp }}</h5>
  <div mat-dialog-content>
    <div mat-dialog-body>
      <p>{{ data.msgPopUp }}</p>
    </div>

    <div mat-dialog-footer class="wrapper-right">
      <button type="button" class="btn btn-secondary button-center" [mat-dialog-close]="false">{{ data.btnCancelLabel }}</button>
      <button type="button" class="btn btn-primary button-center" data-dismiss="modal" [mat-dialog-close]="true" >{{ data.btnAcceptLabel }}</button>
    </div>
  </div>`,
  styles: [`
      .button-center {
        margin-left:  2%;
        margin-right: 2%;
        margin-top: 3%;
        margin-bottom: 3%;
    }
    .wrapper-right {
        text-align: right;
    }
  `],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationAlertComponent {  

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private overlay: Overlay) {
  }
}
