import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MaskService } from 'ngx-mask';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { ExpertiseService } from 'src/app/modules/consultation/expertise/services/expertise.service';
import { Cam4 } from 'src/app/shared/models/cam4';
import { ComponentGroup } from 'src/app/shared/models/component-group';
import { Components } from 'src/app/shared/models/components';
import { ExpertiseTask } from 'src/app/shared/models/expertise-task';
import { ExpertiseTaskMaintenanceAid } from 'src/app/shared/models/expertise-task-maintenance-aid';
import { ExpertiseTaskRefMaterial } from 'src/app/shared/models/expertise-task-ref-material';
import { ExpertiseTaskRefWork } from 'src/app/shared/models/expertise-task-ref-work';
import { Job } from 'src/app/shared/models/job';
import { MaintenanceAid } from 'src/app/shared/models/maintenance-aid';
import { RefMaterial } from 'src/app/shared/models/ref-material';
import { RefWork } from 'src/app/shared/models/ref-work';
import { MaintenancePlanUpdateService } from 'src/app/shared/services/maintenance-plan-update/maintenance-plan-update.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';

@Component({
  selector: 'app-add-task-expertise-dialog',
  templateUrl: './add-task-expertise-dialog.component.html',
  styleUrls: ['./add-task-expertise-dialog.component.scss'],
  providers: [MaskService]
})
export class AddTaskExpertiseDialogComponent {

  /** langage du navigateur */
  lang: string;
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Physical wagon ID */
  currentWagonId: number;
  /** Wagon MEX */
  currentMex: string;
  /** Wagon MEX to display */
  currentMexMask: string;
  /** Current list of tasks (to validate if the task add already exists) */
  currentTaskList: ExpertiseTask[];
  /** Agent ID */
  currentAgentId: string;
  /** ComponentGroup selected */
  componentGroupSelected: ComponentGroup;
  /** ComponentGroup list */
  componentGroupList: ComponentGroup[];
  /** ComponentGroup list*/
  filteredComponentGroupList: ComponentGroup[];  //blm-4415 12/04/2022
  /** Component selected */
  componentSelected: Components;
  /** La liste des components */
  componentList: Components[];
  /** La liste des components */
  filteredComponentList: Components[];
  /** Job selected */
  jobSelected: Job;
  /** La liste des travails */
  jobList: Job[];
  /** Selected element in the component group list */
  modelComponentGroupSelected: string;
  /** Selected element in the component list */
  modelComponentSelected: string;
  /** Selected element in the job list */
  modelJobSelected: string;
  /** Liste « CAM à réaliser » */
  camList: Cam4[] = [];
  // Liste "Matieres"
  materialList: RefMaterial[] = [];
  // Liste "Main d'ouvre"
  workList: RefWork[] = [];
  // Liste "Nb/Pos"
  nbPosList: string[]= [];

  modifyExpertiseForm: FormGroup;
  addCamSelected: string;
  camToDoSelected: Cam4;
  camNbPosSelected: string;
  addMaterialSelected: string
  materialSelected: RefMaterial;
  addMaterialQty: FormControl;
  addWorkSelected: string;
  workSelected: RefWork;
  addWorkQty: FormControl;
  addWorkTpsExp: FormControl;
  addWorkTpsReal: FormControl;

  /** Flag to control activation/desactivation of the Validate button */
  btnValiderEnabled: boolean;

  jobErrorMessage: string;
  camErrorMessage: string;
  materialErrorMessage: string;
  workErrorMessage: string;
  
  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private maskService: MaskService,
    private expertiseService: ExpertiseService,
    private utilityService: UtilityService,
    private maintenancePlanUpdateService: MaintenancePlanUpdateService) {

    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
    this.currentMex = data.mex;
    this.currentWagonId = data.currentWagonId ? data.currentWagonId : 0;
    this.currentTaskList = data.currentTaskList;
    this.currentAgentId = data.agentId;
    this.btnValiderEnabled = false;
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.addMaterialQty = this.formBuilder.control('');
    this.addWorkQty = this.formBuilder.control('');
    this.addWorkTpsExp = this.formBuilder.control('');
    this.addWorkTpsReal = this.formBuilder.control('');
    this.modifyExpertiseForm = this.formBuilder.group({
      addMaterialQty: this.addMaterialQty,
      addWorkQty: this.addWorkQty,
      addWorkTpsExp: this.addWorkTpsExp,
      addWorkTpsReal: this.addWorkTpsReal,
    });

    this.currentMexMask = this.maskService.applyMask(this.currentMex, '00 00 0000 000 0');
    this.initComponentGroupList();
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
  }

  /**
   * RG-P2-CU1-IHM04-002
   * Component Group selection event
   */
   onComponentGroupSelection(): void {
    if (this.componentGroupList) {
      /** blm-4415 */
      if (this.modelComponentGroupSelected){
        const compGroupList = this.componentGroupList.filter(mOp => mOp['codeLabel'] === this.modelComponentGroupSelected);
        this.componentGroupSelected = compGroupList[0];
      }else{
        this.filteredComponentGroupList = this.componentGroupList;
        this.componentGroupSelected = undefined;
      }
      /** end blm-4415 */
      if (this.componentGroupSelected) {
        this.filteredComponentList = this.componentList.filter(c => c.group?c.group.code === this.componentGroupSelected.code:false);
      } else {
        this.filteredComponentList = this.componentList;  
      }
      this.modelComponentSelected = null;
      this.componentSelected = null;
      this.resetLists();
    }
  }

  /**
   * RG-P2-CU1-IHM04-001
   * Component selection event
   */
  onComponentSelection(): void {
    if (this.componentList) {
      const compList = this.componentList.filter(mOp => mOp['codeLabel'] === this.modelComponentSelected);
      this.componentSelected = compList[0];
      if (this.componentSelected) {
        const compGrList = this.componentGroupList.filter(c => this.componentSelected.group?c.code === this.componentSelected.group.code:false);
        if (compGrList.length > 0) {
          this.componentGroupSelected = compGrList[0]; 
          this.filteredComponentGroupList = compGrList;/** blm-4415 */
          this.modelComponentGroupSelected = this.componentGroupSelected['codeLabel'];
          /** blm-4415 */
          if (this.componentGroupSelected) {
            this.filteredComponentList = this.componentList.filter(c => c.group?c.group.code === this.componentGroupSelected.code:false);
          } else {
            this.filteredComponentList = this.componentList;  
          }
          /** end blm-4415 */
        }
        this.getJobList(this.componentSelected.code);
      } else {
        this.filteredComponentGroupList = this.componentGroupList; /** blm-4415 */
        this.resetLists();
      }
    } else {
      this.resetLists();
    }
  }

  /**
   * Initialiser la liste des opérations de maintenance 
   */
  initComponentGroupList(): void {
    this.subscriptionRefs.push(
      forkJoin(
        this.maintenancePlanUpdateService.findComponentGroup(undefined,false,undefined),
        this.maintenancePlanUpdateService.findComponent(undefined,false,undefined)
        ).subscribe(([compoGr, compo]) => {
          this.componentGroupList = compoGr.data; 
          this.componentGroupList.forEach(op => op['codeLabel']=op.code + ' : ' + op.label);
          this.componentGroupList.sort((a,b) => a.label.localeCompare(b.label));
          this.filteredComponentGroupList = this.componentGroupList; /** blm-4415 */
          
          this.componentList = compo.data;
          this.componentList.forEach(op => op['codeLabel']=op.code + ' : ' + op.label);
          this.filteredComponentList = this.componentList;

          this.loadingSubject.next(false);
        })
    );
  }

  getJobList(orgCode: string): void {
    this.subscriptionRefs.push(
        this.maintenancePlanUpdateService.findJobs(orgCode)
      .subscribe(j => {
        this.jobList = j.data;
        this.jobList.forEach(op => op['codeLabel']=op.code + ' : ' + op.label);
      })
    );
  }

  onJobSelection(): void {
    if (this.jobList) {
      const jList = this.jobList.filter(mOp => mOp['codeLabel'] === this.modelJobSelected);
      this.jobSelected = jList[0];
      if (this.jobSelected) {
        this.refreshLists(this.componentSelected.code, this.jobSelected.code);
      } else {
        this.resetListsJob();
      }
    }
  }

  refreshLists(orgCode: string, orgTraCode: string): void {
    // Liste « CAM à réaliser »
    this.camList = [];
    // Liste Matieres
    this.materialList = [];
    // Liste Main d'ouvre
    this.workList = [];
    // Reset selected values
    this.resetSelectedValues();
    
    this.subscriptionRefs.push(
      forkJoin(
        this.expertiseService.getCamListsByExpertiseTask(orgCode, orgTraCode),
        this.expertiseService.getMaterialsByExpertise(orgCode, orgTraCode, this.currentWagonId),
        this.expertiseService.getWorksByExpertise(orgCode, orgTraCode)
      ).subscribe(([camexp, matexp, wrkexp]) => {
        this.camList = camexp.data;
        this.camList.forEach(op => op['codeLabel']=op.code + ' : ' + op.cam4Lib);
        this.materialList = matexp.data;
        this.materialList.forEach(op => op['codeLabel']=op.code + ' : ' + op.label);
        this.workList = wrkexp.data;
        this.workList.forEach(op => op['codeLabel']=op.code + ' : ' + op.label);
        this.workList.sort((a,b) => a.code.localeCompare(b.code));
        this.btnValiderEnabled = true;
    }));
  }

  resetSelectedValues(): void {
    this.camList = [];
    this.camToDoSelected = null;
    this.addCamSelected = null;
    this.nbPosList = null;
    this.camNbPosSelected = null;
    this.materialList = [];
    this.addMaterialSelected = null;
    this.materialSelected = null;
    this.addMaterialQty.setValue(null);
    this.workList = [];
    this.addWorkSelected = null;
    this.workSelected = null;
    this.addWorkQty.setValue(null);
    this.addWorkTpsExp.setValue(null);
    this.addWorkTpsReal.setValue(null);
    this.jobErrorMessage = null;
    this.camErrorMessage = null;
    this.materialErrorMessage = null;
    this.workErrorMessage = null;
  }

  resetListsJob(): void {
    this.btnValiderEnabled = false;
    this.modelJobSelected = null;
    this.jobSelected = null;
    this.resetSelectedValues();
  }

  resetLists(): void {
    this.resetListsJob();
    this.jobList = null;
  }

  public onSelectCamToDo(): void {
    const camToDoSel = this.camList.filter(c => c['codeLabel'] === this.addCamSelected);
    this.camToDoSelected = camToDoSel[0];
    this.nbPosList = [];
    this.camNbPosSelected = null;
    if (this.camToDoSelected) {
      // CAM4
      if (this.camToDoSelected.code.length === 4) {
        this.subscriptionRefs.push(
          forkJoin(
            this.expertiseService.getCamListNbPos(this.camToDoSelected.code)
          ).subscribe(([nb]) => {
            this.nbPosList = nb.data;
          }));
      } else if (this.camToDoSelected.code.length === 6) {
        // CAM6
        this.nbPosList = [];
      }
    }
  }

  public onSelectMaterial(): void {
    const materialSel = this.materialList.filter(m => m['codeLabel'] === this.addMaterialSelected);
    this.materialSelected = materialSel[0];
    this.addMaterialQty.setValue(1);
  }

  public onSelectWork(): void {
    const workSel = this.workList.filter(w => w['codeLabel'] === this.addWorkSelected);
    this.workSelected = workSel[0];
    if (this.workSelected) {
      this.addWorkQty.setValue(1);
      this.addWorkTpsReal.setValue(this.convertTimeToDate(this.workSelected.unitTime));
      this.addWorkTpsExp.setValue(this.convertTimeToDate(this.workSelected.unitTime));
    } else {
      this.addWorkQty.setValue(null);
      this.addWorkTpsReal.setValue(null);
      this.addWorkTpsExp.setValue(null);
    }
  }

  private convertTimeToDate(time: number): Date {
    const numHours = Math.trunc(time);
    const decimalTime = time - Math.floor(time);
    const numMinutes = Math.round((decimalTime)*60);
    const dtTime = new Date();
    dtTime.setHours(numHours);
    dtTime.setMinutes(numMinutes);
    return dtTime;
  }

  private convertDateToTime(dtTime: Date): number {
    if (!dtTime) {
      return 0;
    }

    const nDate = new Date(dtTime);
    const numHours = nDate.getHours();
    const numMinutes = nDate.getMinutes();
    return numHours.valueOf() + ((Math.round((numMinutes.valueOf()/60)*100))/100);
  }

  /**
   * Method to submit the form
   */
  onSubmit(): void {
    this.jobErrorMessage = null;
    this.camErrorMessage = null;
    this.materialErrorMessage = null;
    this.workErrorMessage = null;

    let found = false;

    if (this.camToDoSelected && (this.camToDoSelected.code.length === 4) && !this.camNbPosSelected) {
      found = true;
      this.camErrorMessage = this.translateService.instant('expertise-wagon.cam-nb-pos-required-message');
    }
    if (this.materialSelected && !this.addMaterialQty.value) {
      found = true;
      this.materialErrorMessage = this.translateService.instant('expertise-wagon.material-quantity-message');
    }
    if (this.workSelected && !this.addWorkQty.value) {
      found = true;
      this.workErrorMessage = this.translateService.instant('expertise-wagon.work-quantity-message');
    }

    if (found) {
      return;
    }

    // RG-P2-CU1-F04-001
    this.currentTaskList.forEach(t => {
      if ((t.component.code === this.componentSelected.code) && (t.job.code === this.jobSelected.code)) {
        found = true;
        this.jobErrorMessage = this.translateService.instant('expertise-wagon.add-task-popup.task-exists-message');
      }
    });

    if (found) {
      return;
    }

    this.currentTaskList.forEach(t => {
      // RG-P2-CU1-F04-002
      if (this.camToDoSelected) {
        t.taskMaintenanceAidList.forEach(m => {
          
          if (this.camToDoSelected.code.length === 4) {
            if (m.maintenanceAid.code === (this.camToDoSelected.code+this.camNbPosSelected)) {
              found = true;
              this.camErrorMessage = this.translateService.instant('expertise-wagon.cam-duplicated-message');
            }
          } else if (this.camToDoSelected.code.length === 6) {
            if (m.maintenanceAid.code === this.camToDoSelected.code) {
              found = true;
              this.camErrorMessage = this.translateService.instant('expertise-wagon.cam-duplicated-message');
            }
          }
        });
      }
      // RG-P2-CU1-F04-003
      if (this.materialSelected) {
        t.taskRefMaterialList.forEach(m => {
          if (m.refMaterial.code === this.materialSelected.code) {
            found = true;
            this.materialErrorMessage = this.translateService.instant('expertise-wagon.material-duplicated-message');
          }
        });
      }
      // RG-P2-CU1-F04-004
      if (this.workSelected) {
        t.taskRefWorkList.forEach(m => {
          if (m.refWork.code === this.workSelected.code) {
            found = true;
            this.workErrorMessage = this.translateService.instant('expertise-wagon.work-duplicated-message');
          }
        });
      }
    });
    if (!found) {
      // Create the ExpertiseTask new object
      const newTask = new ExpertiseTask();
      newTask.component = this.componentSelected;
      newTask.job = this.jobSelected;
      newTask.updateAgentCp = this.currentAgentId;
      newTask.update = new Date();
      // Add CAM
      newTask.taskMaintenanceAidList = [];
      if (this.camToDoSelected) {
        const expAid = new ExpertiseTaskMaintenanceAid();
        expAid.status = "I";
        expAid.maintenanceAid = new MaintenanceAid();
        if (this.camToDoSelected.code.length === 4) {
          expAid.maintenanceAid.code = this.camToDoSelected.code + this.camNbPosSelected;
        } else if (this.camToDoSelected.code.length === 6) {
          expAid.maintenanceAid.code = this.camToDoSelected.code;
        }
        expAid.maintenanceAid.label = this.camToDoSelected.cam4Lib;
        newTask.taskMaintenanceAidList.push(expAid);
      }
      // Add Material
      newTask.taskRefMaterialList = [];
      if (this.materialSelected) {
        const expRefMat = new ExpertiseTaskRefMaterial();
        expRefMat.amount = this.addMaterialQty.value;
        expRefMat.dedicatedAmount = 0;
        expRefMat.inOrderAmount = 0;
        expRefMat.refMaterial = this.materialSelected;
        newTask.taskRefMaterialList.push(expRefMat);
      }
      // Add Work
      newTask.taskRefWorkList = [];
      if (this.workSelected) {
        const expRefWork = new ExpertiseTaskRefWork();
        expRefWork.amount = this.addWorkQty.value;
        expRefWork.unitTime = this.convertDateToTime(this.addWorkTpsReal.value);
        expRefWork.expertiseTime = this.convertDateToTime(this.addWorkTpsExp.value);
        expRefWork.realTime = null;
        expRefWork.refWork = this.workSelected;
        newTask.taskRefWorkList.push(expRefWork);
      }
      this.utilityService.setAddedExpertiseTask(newTask);
      this.dialog.closeAll();
    }
  }

  /**
   * The component mat-datetimepicker modifies the value for cdk-overlay-container z-index
   * so we have to restore the original value (1033)
   */
  unfocusModal(): void {
    (document.querySelector('.cdk-overlay-container') as HTMLElement).style.zIndex = "1033";
  }

  // BLM - 4392
  /**
   * Allowing: Integers pour le pb d'incopatibilité sur Firefox
   * @param event 
   */
   filterInput(event: any) {
    /**
     * Allowing: Integers 
     **/
     let numberEntered = false;
     if ((event.which >= 48 && event.which <= 57)) {
      numberEntered = true;
     }else{
      event.preventDefault();
     }
  }

  updateValueMaterial(): void {
    if (this.addMaterialQty.value > 999){
      this.addMaterialQty.setValue(this.getMaxQuantity(this.addMaterialQty.value ));
    }else if (this.addMaterialQty.value < 0){
      this.addMaterialQty.setValue(0);
    }

    if (this.addWorkQty.value > 999){
      this.addWorkQty.setValue(this.getMaxQuantity(this.addWorkQty.value ));
    }else if (this.addWorkQty.value < 0){
      this.addWorkQty.setValue(0);
    }
  }

  private getMaxQuantity(value: number): number {
    let valueStr = value.toString();
    valueStr = valueStr.substr(0, 3);
    return new Number(valueStr).valueOf();
  }
}
