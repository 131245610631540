import { Suspension } from './suspension';
import { WagonLocation } from './wagon-location';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_RESS */
export class WagonSuspension {
    /** WRESS_WCAR_MEX_PFK VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = WRESS_WCAR_INV_CODE_PFK VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** suspension.code = WRESS_RESS_CODE_PFK VARCHAR2(2 BYTE) */
    suspension: Suspension;
    /** location.code = WRESS_EMPL_CODE_FK VARCHAR2(3 BYTE) */
    location: WagonLocation;
    /** WRESS_NBRE NUMBER(3,0) */
    amount: number;
}
