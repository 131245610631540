import { Component, ViewEncapsulation, Input, HostListener, ElementRef, Output, EventEmitter } from '@angular/core';
import { ControlBarConfig } from './control-bar-config';


/**
 * `ControlBarComponent` provide an application configurable `controlbar` element
 *
 */
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'div.controlbar',
  templateUrl: './control-bar.component.html',
  styleUrls: ['./control-bar.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class ControlBarComponent {
  /** Whether control bar is in active state */
  active = -1;
  /** Input configuration items array */
  @Input() config: ControlBarConfig = { items: [] };
  /** Subscribable click outside event emitter */
  @Output() clickOutside = new EventEmitter();
  /**
   * ControlBarComponent constructor
   * @param elRef reference to this `ElmentRef`
   */
  constructor(private elRef: ElementRef) { }
  /**
   * Listener for click on document, disable active state & emit `clickOutside` if click is out of controlbar element
   * @param el Clicked element
   */
  @HostListener('document:click', ['$event.target']) onClick(el) {
    if (!this.elRef.nativeElement.contains(el)) {
      this.active = -1;
      this.clickOutside.emit();
    }
  }
}
