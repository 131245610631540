import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AgentService } from 'src/app/core/services/agent/agent.service';
import { Pageable } from 'src/app/core/services/app-api/pageable.model';
import { InitUpdateSpace } from 'src/app/shared/models/init-update-space';
import { OperationGroupRelation } from 'src/app/shared/models/operation-group-relation';
import { WagonUpdate } from 'src/app/shared/models/wagon-update';
import { RelationService } from 'src/app/shared/services/relation/relation.service';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { Agent } from '../../../models/agent';
import { Anomaly } from '../../../models/anomaly';
import { DamageReport } from '../../../models/damage-report';
import { Expertise } from '../../../models/expertise';
import { Imputation } from '../../../models/imputation';
import { InterventionCause } from '../../../models/intervention-cause';
import { InterventionReason } from '../../../models/intervention-reason';
import { LogicalWagon } from '../../../models/logical-wagon';
import { MaintenanceOperation } from '../../../models/maintenance-operation';
import { UpdateType } from '../../../models/update-type';
import { Wagon } from '../../../models/wagon';
import { Workshop } from '../../../models/workshop';
import { ReferenceService } from '../../../services/reference/reference.service';
import { WagonService } from '../../../services/wagon/wagon.service';
import { DialogCancelConfirmComponent } from '../../dialog-cancel-confirm/dialog-cancel-confirm.component';
import { TemplatePopupDialogComponent } from '../../template-popup-dialog/template-popup-dialog.component';

const MY_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-modify-wagon-dialog',
  templateUrl: './modify-wagon-dialog.component.html',
  styleUrls: ['./modify-wagon-dialog.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT }
    , DatePipe],
})
export class ModifyWagonDialogComponent implements OnInit, OnDestroy {
  agent: Agent;
  /** Open form group */
  modifyWagonForm: FormGroup;
  /** new updateType form control */
  maintenanceOperationCtrl: FormControl;
  /** new pattern form control */
  patternCtrl: FormControl;
  /** new cause form control */
  causeCtrl: FormControl;
  /** new responsability form control */
  responsabilityCtrl: FormControl;
  /** new Entry date form control */
  entryDateCtrl: FormControl;
  /** new Entry time form control */
  entryTimeCtrl: FormControl;
  /** new Estimated Release Date form control */
  estReleaseDateCtrl: FormControl;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** wether search return an error */
  searchError: number;
  /** Workshop Default*/
  //workshop: Workshop;
  /** Workshop Agent */
  // workshopAgent: Workshop;
  /** workshop selected */
  workshopSelected: Workshop;
  /** la liste des établissements valides */
  workshopList: Workshop[];
  /** filtered workshop list */
  filteredWorkshopList: Workshop[];
  /* case_number selected*/
  caseNumberSelected: number;
  /** Current mex */
  currentMex: string;
  /** loaded data */
  loadedData: boolean;
  /** physical wagon */
  wagon: Wagon;
  /** wagon update */
  wagonUpdate: WagonUpdate;
  /** Current inventory logical wagon */
  currentLogWagon: LogicalWagon;
  /** List Logical Wagon */
  // logicalWagons: LogicalWagon[];
  /** Anomalies datas */
  anomaly: Anomaly;
  /** Damage report datas */
  damageReports: DamageReport[];
  /** Expertise wagon */
  expertise: Expertise;
  /** Expertises wagon datas */
  expertises: Expertise[];
  /** Operation Group Relation */
  opGroupsRelation: OperationGroupRelation[];
  /** all types*/
  updateTypes: UpdateType[];
  reasons: InterventionReason[];
  imputations: Imputation[];
  causes: InterventionCause[];  
  maxDefect: string;
  isRevPlanned: boolean;
  /** submitted property */
  submitted: boolean;
  /** langage du navigateur */
  lang: string;
  /** updateType selected */
  updateTypeSelected: UpdateType;
  /** filtered Maintenance Operation list */
  filteredMaintenanceOperationList: MaintenanceOperation[];
  /** La liste des operations Maintenance  */
  maintenanceOperationList: MaintenanceOperation[];
  /** MaintenanceOperation selected*/
  maintenanceOperationSelected: MaintenanceOperation;
  /** La liste des motifs*/
  patternList: InterventionReason[];
  /** filtered intervention Reason list */
  filteredPatternList: InterventionReason[];
  patternSelected: InterventionReason;
  causeSelected: InterventionCause;
  responsabilitySelected: Imputation;
  /** La liste des causes */
  causeList: InterventionCause[];
  /** filtered intervention Cause list */
  filteredCauseList: InterventionCause[];
  /** La liste des responsabilities */
  responsibilityList: Imputation[];
  /** filtered Responsability list */
  filteredResponsibilityList: Imputation[];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** La date de la prochaine révision N est prévue dans les 12 mois  */
  isRevisionN: boolean;
  /** validité du wagon de référence */
  validWagonReference: boolean;

  isReasonMandatory: boolean;
  /** Show reason XXX */
  showPatternXXX: boolean;
  /** Date system */
  dateSystem: Date;
  /** Time entry System */
  entryTimeDf: string;
  /** Entry Date */
  entry: Date;
  /** Estimation Release Date */
  estRelease: Date;
  /** is Estiman. ReleaseDate mineur Entry Date*/
  isNotValidEstReleaseDate: boolean;
  /** Whether datas are still loading */
  isLoadingResults: boolean;
  /** Is Open Wagon */
  isOpenWagon: boolean;

  addObsrv2: string;
  addObsrv1: string;
  observations: string;
  referenceWagon: string;

  workshopLabel: string;
  typeLabel: string;
  pvcaLabel: string;
  errorMessage: string;
  razPlm: number;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ModifyWagonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private referenceService: ReferenceService,
    private wagonService: WagonService,
    private agentService: AgentService,
    private relationService: RelationService,
    private utilityService: UtilityService,
    private translateService: TranslateService,
    public datepipe: DatePipe) {
    this.currentMex = data.mex;

    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.agent = this.agentService.getAgentData().getValue();
    this.resetDatas();
    this.loadInitDatas();
    this.maintenanceOperationCtrl = this.formBuilder.control({ value: undefined, disabled: true }, Validators.required);
    this.patternCtrl = this.formBuilder.control({ value: undefined, disabled: true });
    this.causeCtrl = this.formBuilder.control({ value: undefined, disabled: true });
    this.responsabilityCtrl = this.formBuilder.control({ value: undefined, disabled: true });
    this.entryDateCtrl = this.formBuilder.control(this.dateSystem, Validators.required);
    this.entryTimeCtrl = this.formBuilder.control(this.entryTimeDf, Validators.required);
    this.estReleaseDateCtrl = this.formBuilder.control(null);
    this.modifyWagonForm = this.formBuilder.group({
      maintenanceOperation: this.maintenanceOperationCtrl,
      pattern: this.patternCtrl,
      cause: this.causeCtrl,
      responsability: this.responsabilityCtrl,
      entryDate: this.entryDateCtrl,
      entryTime: this.entryTimeCtrl,
      estReleaseDate: this.estReleaseDateCtrl,
      observations: ['',],
      additional_obsrv_1: ['',],
      additional_obsrv_2: ['',]
    }, {
      validator: [this.maintenanceOperationControlValidator('maintenanceOperation', 'pattern', 'cause', 'responsability'),
      this.releaseDateValidator('entryDate', 'entryTime', 'estReleaseDate')]
    });

    this.subscriptionRefs.push(this.maintenanceOperationCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredMaintenanceOperationList = this.filterMaintenanceOperation(value)
      ));

    this.subscriptionRefs.push(this.patternCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredPatternList = this.filterPattern(value)
      ));

    this.subscriptionRefs.push(this.causeCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredCauseList = this.filterCause(value)
      ));

    this.subscriptionRefs.push(this.responsabilityCtrl.valueChanges
      .pipe(debounceTime(10)).subscribe(
        value => this.filteredResponsibilityList = this.filterResponsability(value)
      ));
  }

  /**
  * Validator to check that InterventionReason, InterventionCause and 
  * imputation are requerid if MaintenanceOperation.isReasonMandatory =1
  * @param maintOpecontrolName 
  * @param reasonControlName 
  * @param causeControlName 
  * @param imputationControlName 
  */
  maintenanceOperationControlValidator(maintOpecontrolName: string, reasonControlName: string,
    causeControlName: string, imputationControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[maintOpecontrolName];
      const reasonControl = formGroup.controls[reasonControlName];
      const causeControl = formGroup.controls[causeControlName];
      const imputationControl = formGroup.controls[imputationControlName];
      if (control.value && control.value.isReasonMandatory) {
        if (!reasonControl.value) {
          reasonControl.setErrors(Validators.required);
        }
        if (!causeControl.value) {
          causeControl.setErrors(Validators.required);
        }
        if (!imputationControl.value) {
          imputationControl.setErrors(Validators.required);
        }
      }
    }
  }

  /**
   * Validator to check that ReleaseDate et estimatino ReleaseDate
   * @param entryDateControlName 
   * @param entryTimeControlName 
   * @param releaseDateControlName 
   * @param releaseTimeControlName 
   * @param estReleaseDateControlName
   */
  releaseDateValidator(entryDateControlName: string, entryTimeControlName: string, estReleaseDateControlName: string) {
    return (formGroup: FormGroup) => {
      const entryDateControl = formGroup.controls[entryDateControlName];
      const entryTimeControl = formGroup.controls[entryTimeControlName];
      const estReleaseDateControl = formGroup.controls[estReleaseDateControlName];

      const entryDate = this.getDateFromCtrl(entryDateControl);
      if (entryDate && entryTimeControl.value) {
        this.entry = this.addTimeToDateCtrl(entryDate, entryTimeControl);
      }

      if (estReleaseDateControl.valid && (estReleaseDateControl.value === undefined)) {
          estReleaseDateControl.setErrors(Validators.required);
      
      } else if (estReleaseDateControl.value !== null && entryDateControl.value && entryTimeControl.value) {

        let estReleaseDate = this.getDateFromCtrl(estReleaseDateControl);
        if (typeof estReleaseDate === 'string') {
          estReleaseDate = new Date(estReleaseDate);
        }
        
        const entryDateTime = this.addTimeToDateCtrl(entryDate, entryTimeControl);

        if (entryDateTime >= estReleaseDate) {
          estReleaseDateControl.setErrors(Validators.required);
          this.isNotValidEstReleaseDate = true;
        } else {
          this.estRelease = estReleaseDate;
          this.entry = entryDateTime;
          estReleaseDateControl.setErrors(null);
          this.isNotValidEstReleaseDate = false;
        }

      }
    }
  }

  /**
   * Return Date with Time in string
   * @param date 
   * @param ctrl 
   */
  private addTimeToDateCtrl(date: string | Date, ctrl: AbstractControl): Date {
    const indexT = date.toString().indexOf('T');
    const subStr = date.toString().substring(indexT, indexT + 6);
    const dateStr = date.toString().replace(subStr, 'T' + ctrl.value);
    const targetTime = new Date(dateStr);
    const hourZoneOffset =  targetTime.getTimezoneOffset()/60;
    targetTime.setHours(targetTime.getHours() + hourZoneOffset);
    return targetTime;
  }

  /**
   * Get the date of DatePicker control
   * @param ctrl 
   */
  getDateFromCtrl(ctrl: AbstractControl): string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : new Date(ctrl.value);
  }

  /**
   * Reset les donnes 
   */
  private resetDatas(): void {
    //this.workshop = undefined;
    this.currentLogWagon = undefined;
    this.wagon = undefined;
    this.anomaly = undefined;
    this.damageReports = undefined;
    this.expertise = undefined;
    this.expertises = undefined;
    this.submitted = false;
    this.workshopSelected = undefined;
    this.updateTypeSelected = undefined;
    this.maintenanceOperationSelected = undefined;
    this.loadedData = false;
    this.caseNumberSelected = undefined;
    this.isRevisionN = false;
    this.validWagonReference = true;
    this.isReasonMandatory = false;
    this.patternSelected = undefined;
    this.causeSelected = undefined;
    this.responsabilitySelected = undefined;
    this.showPatternXXX = true;
    this.dateSystem = new Date((new Date().getTime()));
    this.entryTimeDf = '';
    this.isNotValidEstReleaseDate = false;
    this.entry = null;
    this.estRelease = null;
    this.addObsrv2 = '';
    this.addObsrv1 = '';
    this.observations = '';
    this.referenceWagon = '';
    this.isLoadingResults = false;
    this.isOpenWagon = false;
    this.utilityService.setValue(this.isOpenWagon);
  }

  /**
   * Methode pour init datas 
   */
  private loadInitDatas() {
    const pageable = new Pageable();
    pageable.sort = 'id';
    pageable.order = 'desc';
    const pageableCode = new Pageable();
    pageableCode.sort = 'code';
    pageableCode.order = 'asc';
    // Rechercher l’établissement de l’opérateur de saisie
    /*this.subscriptionRefs.push(
      this.agentService.getWorkshop()
        .subscribe(workshop => {
        // this.workshopAgent = workshop.data;
        //this.workshop = workshop.data;
      })
    );*/

    // Rechercher les informations de Wagon logique
    this.currentLogWagon =  this.data.logicals.reduce(
        (prev, curr) => ['13', '14', 'WO'].indexOf(curr.inventory.code) === -1 ? curr : prev, null);

    if (this.currentLogWagon) {
      this.wagonService.setCurrentWagon(this.currentLogWagon.wagonId);
      this.wagon = this.wagonService.getSharedDatas().value.wagon;
      this.subscriptionRefs.push(
        forkJoin(
          // Rechercher les informations du Wagon MAJ
          // this.wagonService.getWagonUpdate(this.currentLogWagon.wagonId),
          // Rechercher les informations du Wagon Physique
          //this.wagonService.getWagon(this.currentLogWagon.wagonId),
          // Rechercher les informations Incidents et réformes du Wagon
          this.wagonService.findAnomalyUndepreciatedReform(this.currentLogWagon.wagonId),
          // Rechercher les informations PVCA du Wagon
          this.wagonService.getDamageReports(this.currentLogWagon.wagonId, pageable),
          // Rechercher la liste des expertises en cours sur le wagon
          this.wagonService.getWagonExpertise(this.currentLogWagon.wagonId, false),
          // Rechercher 
          this.relationService.getOperationGroups()

          ,
          this.wagonService.getMaxClassDefectCode(this.currentLogWagon.wagonId),
          this.wagon && this.wagon.maintenanceContract && this.wagon.maintenanceContract.isLevel34 ? 
              this.referenceService.getInterventionsReason(this.currentLogWagon.mex, this.currentLogWagon.inventory.code, pageableCode)
              : this.referenceService.getAllInterventionsReason(pageableCode, true),
          this.referenceService.getImputations(pageableCode, true),
          this.referenceService.getInterventionsCause(pageableCode, true),
          this.wagonService.isRevisionNplanned(this.currentLogWagon.mex, '10')
        )
          .subscribe(([anomaly, damageReports, expertisesWagons, opGroupsRelation, maxDefect, reasons, imputations, causes, isRevPlanned]) => {
            this.wagonUpdate = this.data.update;

            //Traitement pour mex a l'inventaire courante
            const operation = this.wagonUpdate && this.wagonUpdate.maintenanceOperation ?
            this.wagonUpdate.maintenanceOperation.code : undefined;
            const inventoryWagonWO = this.data.logicals.reduce(
                              (prev, curr) => curr.inventory.code === 'WO' ? curr : prev, null);
            if (this.exchangeSchemechanged(this.currentLogWagon, inventoryWagonWO)
                  && operation && operation !== '03') {
              this.currentMex = inventoryWagonWO.mex;
            } else {
              this.currentMex = this.currentLogWagon.mex;
            }
            this.anomaly = anomaly.data;
            this.damageReports = damageReports.data;
            this.expertises = expertisesWagons.data;
            this.opGroupsRelation = opGroupsRelation.data;
            this.maxDefect = maxDefect;
            this.reasons = reasons.data;
            this.imputations = imputations.data;
            this.causes = causes.data;
            this.isRevPlanned = isRevPlanned.data;
            this.setDefaultValues();

            this.loadingSubject.next(false);
          })
      );
    }

    this.loadedData = true;
  }

  setDefaultValues(): void {
    this.workshopSelected = this.wagonUpdate.interventionWorkshop;
    this.workshopLabel = this.wagonUpdate.interventionWorkshop.code + ' - ' + this.wagonUpdate.interventionWorkshop.label;
    this.updateTypeSelected = this.wagonUpdate.type;
    this.typeLabel = this.updateTypeSelected.code + ' - ' + this.updateTypeSelected.label;
    this.caseNumberSelected = this.wagonUpdate.dealId;
    this.observations = this.wagonUpdate.observation;
    this.addObsrv1 = this.wagonUpdate.option1;
    this.addObsrv2 = this.wagonUpdate.option2;
    this.referenceWagon = this.wagonUpdate.refMex;
    this.patternSelected = this.wagonUpdate.interventionReason;
    this.causeSelected = this.wagonUpdate.interventionCause;
    this.responsabilitySelected = this.wagonUpdate.imputation;
    this.entryDateCtrl.setValue(new Date(this.wagonUpdate.entry));
    this.entryTimeDf = this.getTimeFromDate(this.wagonUpdate.entry);
    this.entryTimeCtrl.setValue(this.entryTimeDf);
    if (this.wagonUpdate.exit) {
      this.estReleaseDateCtrl.setValue(new Date(this.wagonUpdate.exit));
    }

    if (this.wagonUpdate.pvcaId) {
      const damageReportFilter = this.damageReports.filter(dr => dr.id === this.wagonUpdate.pvcaId);
      if (damageReportFilter.length > 0) {
        const pipe = new DatePipe('en-US'); // Use your own locale
        const damageReportWagon = damageReportFilter[0];
        this.pvcaLabel = this.translateService.instant('wagon.opening.pvca_list_detail');
        this.pvcaLabel = this.pvcaLabel.replace('{{ id }}', damageReportWagon.id.toString());
        this.pvcaLabel = this.pvcaLabel.replace('{{arrival}}', (pipe.transform(damageReportWagon.arrival, 'dd/MM/yyyy')));
        if (damageReportWagon.gcuList.length > 0) {
          this.pvcaLabel = this.pvcaLabel.replace('{{gcu1}}', damageReportWagon.gcuList[0].code);
        } else {
          this.pvcaLabel = this.pvcaLabel.replace('{{gcu1}}', '');
        }
        if (damageReportWagon.gcuList.length > 1) {
          this.pvcaLabel = this.pvcaLabel.replace('{{gcu2}}', ';' + damageReportWagon.gcuList[1].code);
        } else {
          this.pvcaLabel = this.pvcaLabel.replace('{{gcu2}}', '');
        }
        if (damageReportWagon.gcuList.length > 2) {
          this.pvcaLabel = this.pvcaLabel.replace('{{gcu3}}', ';' + damageReportWagon.gcuList[2].code);
        } else {
          this.pvcaLabel = this.pvcaLabel.replace('{{gcu3}}', '');
        }
      }
    }
    if (this.expertises && this.expertises.length === 1) {
      this.expertise = this.expertises[0];
    }

    this.maintenanceOperationSelected = this.wagonUpdate.maintenanceOperation;
    this.initMaintenanceOperationList();
  }

  /**
   * convenience getter for easy access to form fields
   */
  get f() { return this.modifyWagonForm.controls; }

  private filterMaintenanceOperation(value: string | MaintenanceOperation): MaintenanceOperation[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return (filterValue && this.maintenanceOperationList) ? this.maintenanceOperationList
      .filter(
        ws => ((ws.code + ws.label).includes(filterValue))
      ) : this.maintenanceOperationList;
  }

  private filterPattern(value: string | InterventionReason): InterventionReason[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    let auxPatternList = this.patternList;
    if (!this.showPatternXXX) {
      auxPatternList = auxPatternList.filter(item => ['XXX'].indexOf(item.code) === -1);
    }
    return (filterValue && auxPatternList) ? auxPatternList
      .filter(
        ws => ((ws.code + ws.label).includes(filterValue))
      ) : auxPatternList;
  }

  private filterCause(value: string | InterventionCause): InterventionCause[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return (filterValue && this.causeList) ? this.causeList
      .filter(
        ws => ((ws.code + ws.label).includes(filterValue))
      ) : this.causeList;
  }

  private filterResponsability(value: string | Imputation): Imputation[] {
    const filterValue = !value ? '' : (typeof value === 'object' ? value.code : (value as string).toUpperCase());
    return (filterValue && this.responsibilityList) ? this.responsibilityList
      .filter(
        ws => ((ws.code).includes(filterValue))
      ) : this.responsibilityList;
  }

  /**
   * On click sur Maintenance Operation
   */
  onMainteanceOperationSelection(): void {
    let opeMaintList: MaintenanceOperation[];
    if (this.maintenanceOperationList) {
      if (!this.maintenanceOperationCtrl.value) {
        this.maintenanceOperationCtrl.setErrors(Validators.required);
        return;
      }
      opeMaintList = this.maintenanceOperationList.filter(mOp => mOp.code === this.maintenanceOperationCtrl.value.code);
      if (opeMaintList && opeMaintList.length > 0) {
        const found = opeMaintList.some(r => ['ATS', 'VSP', 'REVS', 'REVSUR', 'REVD', 'ATP120', 'ATPTMD', 'ATPSUR', 'ATPPR'].includes(r.code))
        if (found && this.currentLogWagon && this.currentLogWagon.inventory.code === '10') {
          // vérifier la date de la prochaine révision N.
         
          this.isRevisionN = this.isRevPlanned;
        }

        if (!opeMaintList[0].isReasonMandatory) {
          this.patternList = [];
          this.filteredPatternList = [];
          this.causeList = [];
          this.filteredCauseList = [];
          this.responsibilityList = [];
          this.filteredResponsibilityList = [];
          this.patternCtrl.setValue(null);
          this.causeCtrl.setValue(null);
          this.responsabilityCtrl.setValue(null);
        } else {
          if (this.wagon || !this.currentLogWagon) {
            this.patternList = this.reasons;              
            this.responsibilityList = this.imputations;
            this.causeList = this.causes;
            this.getInterventionReason();
            this.initInterventionsCause();
            this.initImputations();
          }
        }
      } else {
        this.maintenanceOperationCtrl.setErrors(Validators.required);
      }
    }
  }

  /** 
   * Get Time from Date
   * 
   */
  getTimeFromDate(date: Date): string {
    const now = new Date(date);
    const hours = ("0" + now.getHours()).slice(-2);
    const minutes = ("0" + now.getMinutes()).slice(-2);
    return hours + ':' + minutes;
  }

  /** 
   * Obtener la liste d'intervention Reason 
   */
  getInterventionReason(): void {
    if (this.patternList && this.f.maintenanceOperation.value && (this.f.maintenanceOperation.value.code === 'REP' ||
        this.f.maintenanceOperation.value.code === 'DEP')) {
      this.showPatternXXX = false;
      this.patternCtrl.enable();
      this.filteredPatternList = this.patternList.filter(item => ['XXX'].indexOf(item.code) === -1);
    } else if (!this.patternList || this.patternList.length === 0) {
      this.patternCtrl.disable();
      this.filteredPatternList = this.patternList;
    } else {
      this.patternCtrl.enable();
      this.filteredPatternList = this.patternList;
    }
    //Initialiser le motif à partir du code défaut RG_MAJ_001_11 
    if (this.currentLogWagon && this.currentLogWagon.wagonId) {
      if (this.maxDefect) {
        const interventionArray = this.patternList ? this.patternList.filter(item => [this.maxDefect].indexOf(item.code) > -1) : [];
        if (interventionArray && interventionArray.length === 1) {
          this.patternSelected = interventionArray[0];
        }
      }
    }
  }

  /** 
   * Recherche la liste des causes valides (dates début/fin) de la table  
   */
  initInterventionsCause(): void {
    this.causeCtrl.enable();
    this.filteredCauseList = this.causeList;
    
  }

  /** 
   * Recherche la liste des Responsabilité  
   */
  initImputations(): void {
    this.responsabilityCtrl.enable();
    this.filteredResponsibilityList = this.responsibilityList;
  }

 /**
  * Rechercher initialiser la liste des opérations de maintenance 
  */
  initMaintenanceOperationList(): void {
    const pageable = new Pageable();
    pageable.sort = 'code';
    pageable.order = 'asc';
    if (this.wagon && this.wagon.id) {
        this.subscriptionRefs.push(
          this.referenceService.getMaintenanceOperation(this.wagon.id, this.workshopSelected ? this.workshopSelected.code : undefined, this.updateTypeSelected ? this.updateTypeSelected.code : undefined, pageable)
            .subscribe(mOpe => {
              this.maintenanceOperationList = mOpe.data;
              this.maintenanceOperationCtrl.enable();
              this.filteredMaintenanceOperationList = this.maintenanceOperationList;

              this.onMainteanceOperationSelection();
            })
        );
      }
  }

  /**
   * Methode to show CancellationModifyWagon Dialog
   */
  openCancellationModifyWagonPopUpDialog(): void {

    if (!this.modifyWagonForm.dirty) {
      this.isOpenWagon = true;
      this.utilityService.setValue(this.isOpenWagon);
      this.dialogRef.close(false);
      return;
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const title = this.translateService.instant('wagon.title_cancellation_modification');
    const message = this.translateService.instant('wagon.cancellation_modification_message');

    dialogConfig.data = {
      namePopUp: 'cancellationModifyWagon',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.isOpenWagon = true;
        this.utilityService.setValue(this.isOpenWagon);
        this.dialogRef.close(false);
      }
    });
  }

  /**
   * Methode to show the new ENtry Date 
   */
  changeEntryDate(): string | Date {
    const request = this.getDateFromCtrl(this.modifyWagonForm.get('entryDate'));
    return request;
  }

  /**
   * Methode to show the new Entry Time
   */
  changeEntryTime(): void {
    this.modifyWagonForm.get('entryTime').value;

  }

  /**
   * Methode to show the new Est Release Date
   */
  changeEstReleaseDate(): void {
    this.getDateFromCtrl(this.modifyWagonForm.get('estReleaseDate'));
  }

  /**
   * Methode to submit the form
   */
  onSubmit(): void {
    
    this.submitted = true;

    if (!this.checkSelectComboValue() || this.modifyWagonForm.invalid) {
      this.errorMessage = this.translateService.instant('wagon.opening.impossible_validation_message');
      return;
    }

    this.errorMessage = null;
    this.razPlm = 0;

    if (!this.checkMandatoryFields()) {
      this.errorMessage = this.translateService.instant('wagon.opening.impossible_validation_message');
      return;
    }
    if (!this.checkOpGroupsRelation()) {
      this.errorMessage = this.translateService.instant('wagon.modification.RG_MAJ_001_1_5');
      return;
    }
    if (this.checkReinitialisation()) {
      return;
    }
    this.doSubmit();
  }

  /**
   * Appel vers le service back
   */
  doSubmit(): void {
    const initUpdateSpace = new InitUpdateSpace();
    initUpdateSpace.wagonId = this.wagon.id;
    initUpdateSpace.currentMex = this.currentMex;
    initUpdateSpace.maintenanceOperation = (this.f.maintenanceOperation && this.f.maintenanceOperation.value) ? this.f.maintenanceOperation.value : null;
    initUpdateSpace.interventionReason = (this.f.pattern && this.f.pattern.value) ? this.f.pattern.value : null;
    initUpdateSpace.interventionCause = (this.f.cause && this.f.cause.value) ? this.f.cause.value : null;
    initUpdateSpace.imputation = (this.f.responsability && this.f.responsability.value) ? this.f.responsability.value : null;
    initUpdateSpace.observation = (this.f.observations && this.f.observations.value && this.f.observations.value !== '') ? this.f.observations.value : null;
    initUpdateSpace.option1 = (this.f.additional_obsrv_1 && this.f.additional_obsrv_1.value && this.f.additional_obsrv_1.value !== '') ? this.f.additional_obsrv_1.value : null;
    initUpdateSpace.option2 = (this.f.additional_obsrv_2 && this.f.additional_obsrv_2.value && this.f.additional_obsrv_2.value !== '') ? this.f.additional_obsrv_2.value : null;
    initUpdateSpace.exit = this.estRelease;
    initUpdateSpace.entry = this.entry;
    
    initUpdateSpace.razPlm = this.razPlm;

    this.subscriptionRefs.push(
      this.wagonService.modifyUpdateSpace(initUpdateSpace)
        .subscribe(_ => {
          // Tracer l'avancement des Travaux
          this.subscriptionRefs.push(
            this.wagonService.updateStageWagonUpdate(this.wagon.id, 2)
              .subscribe(_ => this.updateDone()));
        }
    ));
  }

  /**
   * Message showed during 5 seconds
   */
  updateDone(): void {
    this.isOpenWagon = true;
    this.utilityService.setValue(this.isOpenWagon);
    
    const dialogConfig = new MatDialogConfig();  
    dialogConfig.disableClose = true;
    const title = this.translateService.instant('wagon-update.task.update-done.msg');
    const message = this.translateService.instant('wagon-update.task.update-done.msg');  
    const timeout = UtilityService.UPDATE_DONE_TIMEOUT;  
    dialogConfig.data = {
      namePopUp: 'update-done-alert',
      titlePopUp: title,
      msgPopUp: message
    };  
    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterOpened().subscribe(_ => {
      setTimeout(() => {
        dialogRef.close();
        this.dialogRef.close(this.razPlm);
      }, timeout)
    })

  }

  /**
   * Contrôle des informations obligatoires
   */
   private checkMandatoryFields(): boolean {
    let validForm = true;
    
    if (!this.maintenanceOperationCtrl.value || !this.entryDateCtrl.value || !this.entryTimeCtrl.value) {
      validForm = false;
    }

    const maintSelected = this.maintenanceOperationCtrl.value;
    if ((maintSelected && maintSelected.isReasonMandatory) &&
        (!this.patternCtrl.value || !this.causeCtrl.value || !this.responsabilityCtrl.value)) {
      validForm = false;
    }

    return validForm;
  }

  checkOpGroupsRelation(): boolean {
    const grp004OpeListIni = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '004' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    const grp010OpeListIni = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '010' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    const grp011OpeListIni = this.opGroupsRelation.filter(rel => 
      rel.operationGroup.code === '011' && rel.maintenanceOperation.code === this.wagonUpdate.maintenanceOperation.code);
    
    if ((grp004OpeListIni.length !== 0) || (grp010OpeListIni.length != 0) || (grp011OpeListIni.length !== 0)) {

      const grp004OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '004' && rel.maintenanceOperation.code === this.maintenanceOperationCtrl.value.code);
      const grp010OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '010' && rel.maintenanceOperation.code === this.maintenanceOperationCtrl.value.code);
      const grp011OpeList = this.opGroupsRelation.filter(rel => 
        rel.operationGroup.code === '011' && rel.maintenanceOperation.code === this.maintenanceOperationCtrl.value.code);

      if ((grp004OpeList.length !== 0) || (grp010OpeList.length !== 0) || (grp011OpeList.length !== 0)) {

        if ((grp004OpeListIni.length !== grp004OpeList.length)
            || (grp010OpeListIni.length !== grp010OpeList.length)
            || (grp011OpeListIni.length !== grp011OpeList.length)) {

          if (this.wagonUpdate.wmajGeoCrev !== 0 || this.wagonUpdate.wmajGeoVsp !== 0) {
            return false;
          }
        }
      }
    }

      return true;
  }

  checkReinitialisation(): boolean {
    let reinit = false;

    if (this.wagonUpdate.maintenanceOperation.code !== this.maintenanceOperationSelected.code
      || (this.wagonUpdate.interventionReason && this.wagonUpdate.interventionReason.code !== this.patternSelected.code)) {
        reinit = true;
        this.showMaintenancePlanChangePopUpDialog();
    }

    return reinit;
  }

  /**
   * le Régime d’Echange a été modifié (cf lot 3 / Famille et Restriction)
   * @param logWagon
   * @param logWagonWO
   */
   private exchangeSchemechanged(logWagon: LogicalWagon, logWagonWO: LogicalWagon): boolean {
    return logWagon && logWagonWO && (logWagon.mex != logWagonWO.mex);
  }

  showMaintenancePlanChangePopUpDialog(): void {
    const dialogRef = this.dialog.open(DialogCancelConfirmComponent, {
      data: {
        title: 'wagon-update.certification.maintenance_change',
        message: 'wagon.modification.RG_MAJ_001_1_6',
        cancelLabel: 'wagon.button_dialog.no',
        confirmLabel: 'wagon.button_dialog.yes',
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.razPlm = 1;
      }
      this.doSubmit();
    });
  }

  displayByCodLabel(data: any): string {
    return data ? ((data.code ? data.code : '') + (data.label ? ': ' + data.label : '')) : '';
  }

  /** Clean Date Entry */
  cleanEntryDate(): void {
    this.entryDateCtrl.setValue(undefined);
  }

  /** Clean Time Entry */
  cleanEntryTime(): void {
    this.entryTimeCtrl.setValue(undefined);
  }

  /** Clean Estimated Release Date entry */
  cleanEstReleaseDate(): void {
    this.estReleaseDateCtrl.setValue(undefined);
  }

  /** Clean Maintenance Operation */
  cleanMaintenanceOperation(): void {
    this.maintenanceOperationCtrl.setValue(undefined);
    this.patternCtrl.disable();
    this.causeCtrl.disable();
    this.responsabilityCtrl.disable();
    this.isRevisionN = false;
  }

  cleanPattern(): void {
    this.patternCtrl.setValue(undefined);
  }

  cleanCause(): void {
    this.causeCtrl.setValue(undefined);
  }

  cleanResponsability(): void {
    this.responsabilityCtrl.setValue(undefined);

  }

  /**
   * Methode to check the combo value selected
   */
  checkSelectComboValue(): boolean {
    if (this.maintenanceOperationCtrl.value && this.maintenanceOperationCtrl.value.isReasonMandatory) {
      const pattern = this.ctrlSelectValid(this.patternCtrl);
      const cause = this.ctrlSelectValid(this.causeCtrl);
      const responsability = this.ctrlSelectValid(this.responsabilityCtrl);
      return (pattern && cause && responsability);
    } else {
      return true;
    }
  }

  /**
   * Methode to check if select combo is valid
   * @param ctrl 
   */
  ctrlSelectValid(ctrl: FormControl): boolean {
    if (ctrl.value && ctrl.value.code) {
      return true;
    } else {
      ctrl.setErrors({ 'incorrect': true });
      return false;
    }
  }

  /**
   * Methode to clean alerts errors.
   */
  disableAlerts(): void {
    if (this.submitted) {
      this.submitted = false;
    }
    if (this.isRevisionN) {
      this.isRevisionN = false;
    }
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
    this.loadingSubject.complete();
  }
}
