import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RequestOptions } from './request-options.inteface';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../../../environments/environment';

/**
 * Service pour l'accès à l'API avec gestion de l'authentification
 */
@Injectable()
export class AppApiService {

  /**
   * URL de base de l'API
   */
  private readonly BASE_URL = environment.api_url;
  /**
   * AppApiService constructor
   * @param http angular Http
   * @param hauthServicettp app's authentication service
   */
  constructor(private http: HttpClient, private authService: AuthService) {
  }

  /**
   * Ajoute un header
   * @param headers - Les headers existants
   * @param key - Le nom du header
   * @param value - La valeur du header
   * @returns Le nouvel objet header
   */
  static appendHeader(headers: HttpHeaders | { [header: string]: string | string[]; }, key: string, value: any) {
    if (headers instanceof HttpHeaders) {
      return headers.set(key, value);
    } else if (headers) {
      headers[key] = value;
      return headers;
    } else {
      return { [key]: value };
    }
  }

  /**
   * Méthode générique pour l'appel d'un web service
   * @param method - Méthode HTTP (GET, POST, PUT, PATCH, OPTIONS, HEAD)
   * @param path - Chemin de la ressource
   * @param options - Paramètre de la requête HTTP - Paramètre de la requête HTTP
   */
  request<T>(method: string, path: string, options: RequestOptions = {}): Observable<T> {

    // Réponse au format JSON par défaut
    if (!options.responseType) {
      options.responseType = 'json';
    }

    // Ajout des headers d'authentification si connecté
    if (this.authService.isLogged().getValue()) {
      const authHeader = `Bearer ${this.authService.getAccessToken()}`;
      options.headers = AppApiService.appendHeader(options.headers, 'Authorization', authHeader);
    }
    return this.http.request(method, this.BASE_URL + path, options);
  }


  /**
   * Effectue une requête GET
   * @param path - Chemin de la ressource
   * @param options - Paramètre de la requête HTTP
   */
  get<T>(path: string, options?: RequestOptions): Observable<T> {
    return this.request<T>('GET', path, options);
  }

  /**
   * Effectue une requête POST
   * @param path - Chemin de la ressource
   * @param body - Corps de la réponse
   * @param options - Paramètre de la requête HTTP
   */
  post<T>(path: string, body: any, options: RequestOptions = {}): Observable<T> {
    if (options.body === undefined) {
      options.body = body;
    }
    return this.request<T>('POST', path, options);
  }

  /**
   * Effectue une requête PUT
   * @param path - Chemin de la ressource
   * @param body - Corps de la réponse
   * @param options - Paramètre de la requête HTTP
   */
  put<T>(path: string, body: any, options?: RequestOptions): Observable<T> {
    if (options.body === undefined) {
      options.body = body;
    }
    return this.request<T>('PUT', path, options);
  }

  /**
   * Effectue une requête PATCH
   * @param path - Chemin de la ressource
   * @param body - Corps de la réponse
   * @param options - Paramètre de la requête HTTP
   */
  patch<T>(path: string, body: any, options?: RequestOptions): Observable<T> {
    if (options.body === undefined) {
      options.body = body;
    }
    return this.request<T>('PATCH', path, options);
  }

  /**
   * Effectue une requête DELETE
   * @param path - Chemin de la ressource
   * @param options - Paramètre de la requête HTTP
   */
  delete<T>(path: string, options?: RequestOptions): Observable<T> {
    return this.request('DELETE', path, options);
  }

  /**
   * Effectue une requête HEAD
   * @param path - Chemin de la ressource
   * @param options - Paramètre de la requête HTTP
   */
  head<T>(path: string, options?: RequestOptions): Observable<T> {
    return this.request<T>('HEAD', path, options);
  }

  /**
   * Effectue une requête OPTIONS
   * @param path - Chemin de la ressource
   * @param options - Paramètre de la requête HTTP
   */
  options<T>(path: string, options?: RequestOptions): Observable<T> {
    return this.request<T>('OPTIONS', path, options);
  }

  /**
   * Effectue une requête JSONP
   * @param path - Chemin de la ressource
   * @param callbackParam - Callback jsonP
   */
  jsonp<T>(path: string, callbackParam: string): Observable<T> {
    return this.http.jsonp<T>(path, callbackParam);
  }


  /**
   * Genere un lien sur un WS
   * @param path - Chemin de la ressource
   */
  generateLink(path: string) {
    return this.BASE_URL + path;
  }
}
