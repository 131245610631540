import { Component, Injectable, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { PcMessageService } from '../../services/pc-message/pc-message.service';
import { first } from 'rxjs/operators';
import { FaqService } from './services/faq.service';
import { FaqTheme } from './models/faq-theme';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AgentService } from '../../services/agent/agent.service';
import { BarConfigService } from '../../services/bar-config/bar-config.service';
import { ControlBarConfig } from '../control-bar/control-bar-config';
import { ActionBarConfig } from '../action-bar/action-bar-config';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FaqQuestion } from './models/faq-question';
import { FormControl, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

/**
 * Node for faq item
 */
export class FaqItemNode {
  children?: FaqItemNode[];
  item: string;
  contact?: string;
  id: number;
}

/** Flat faq item node with expandable and level information */
export class FaqItemFlatNode {
  item: string;
  contact?: string;
  level: number;
  expandable: boolean;
  control: FormControl;
  edit: boolean;
  tmp?: string;
}

@Injectable()
export class FaqDatabase {
  faqList: FaqTheme[] = [];
  dataChange = new BehaviorSubject<FaqItemNode[]>([]);
  insertId = -1;
  get data(): FaqItemNode[] { return this.dataChange.value; }

  constructor(private faqService: FaqService) {
    this.initialize();
  }

  async initialize() {
    this.faqList = (await this.faqService.getAllFAQ().pipe(first()).toPromise()).data;
    // Build the tree nodes from Json object. The result is a list of `FaqItemNode` with nested
    //      node as children.
    const data = this.buildTree(this.faqList, 0);
    // Notify the change.
    this.dataChange.next(data);
  }

  /**
   * Build the  structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `FaqItemNode`.
   */
  buildTree(obj: any[], level: number): FaqItemNode[] {
    return obj.reduce<FaqItemNode[]>((accumulator, v) => {
      const node = new FaqItemNode();
      node.id = v.id;
      node.contact = v.contact || null;
      node.item = v.label || v.question;

      if (v != null) {
        if (v.questionList ) {
          node.children = this.buildTree(v.questionList, level + 1);
        } else if (v.response) {
          node.children = this.buildTree([v.response], level + 1);
        } else {
          node.item = v;
        }
      }
      return accumulator.concat(node);
    }, []);
  }

  addRootItem() {
    this.data.push({item: '', id: this.insertId--, children: []} );
    this.dataChange.next(this.data);
  }

  /** Add an item to faq list */
  insertItem(parent: FaqItemNode, name: string, level: number) {
    if (parent.children) {
      const newItem: FaqItemNode = level < 2 ?
        {item: name, id: this.insertId--, children: []}
        : {item: name, id: this.insertId--};
      if (level === 1) {
        newItem.children.push({item: name, id: this.insertId--});
      }
      parent.children.push(newItem);
      this.dataChange.next(this.data);
    }
  }

  updateItem(node: FaqItemNode, name: string, contact: string) {
    node.item = name;
    if (contact != null) {
      node.contact = contact;
    }
    this.dataChange.next(this.data);
  }

  deleteItem(node: FaqItemNode, parent: FaqItemNode) {
    if (parent && parent.id) {
      parent.children = parent.children.filter( n => n.id !== node.id);
      this.dataChange.next(this.data);
    } else {
      const data = this.data.filter( n => n.id !== node.id);
      this.dataChange.next(data);
    }
  }

   async save() {
    let result: FaqTheme[] = [];
    for (const t of this.data) {
      const theme = new FaqTheme();
      theme.id = t.id > 0 ? t.id : null;
      theme.label = t.item;
      theme.contact = t.contact;
      theme.questionList = [];
      for (const q of t.children) {
        const question = new FaqQuestion();
        question.id = q.id > 0 ? q.id : null;
        question.question = q.item;
        question.response = q.children[0].item;
        theme.questionList.push(question);
      }
      result.push(theme);
    }
    result = (await this.faqService.save(result).pipe(first()).toPromise()).data;
    const data = this.buildTree(result, 0);
    this.dataChange.next(data);
  }
}


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  providers: [FaqDatabase],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit, OnDestroy {

  userMessages: SafeHtml[] = [];

  editMode = false;

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<FaqItemFlatNode, FaqItemNode>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<FaqItemNode, FaqItemFlatNode>();

  treeControl: FlatTreeControl<FaqItemFlatNode>;

  treeFlattener: MatTreeFlattener<FaqItemNode, FaqItemFlatNode>;

  dataSource: MatTreeFlatDataSource<FaqItemNode, FaqItemFlatNode>;

  /** Wether agent has FAQ02 */
  hasFAQ02: boolean;

  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];

  isLoading: boolean;

  editorConfig: AngularEditorConfig = {
    editable: true,
    sanitize: false,
    toolbarHiddenButtons: [
      [],
      ['insertImage', 'insertVideo']
    ]
  };

  constructor(
    private database: FaqDatabase,
    private pcMessageService: PcMessageService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private agentService: AgentService,
    private barConfigService: BarConfigService,
    private sanitizer: DomSanitizer
  ) {
    this.hasFAQ02 = this.agentService.hasFunctionalProcedure('FAQ02');
    this.barConfigService.sendConfig(this.getActionConfig(), this.getControlBarConfig());
    this.subscriptionRefs.push( this.agentService.getHabilitationsLoaded()
      .subscribe(_ => {
          this.hasFAQ02 = this.agentService.hasFunctionalProcedure('FAQ02');
          this.barConfigService.sendConfig(this.getActionConfig(), this.getControlBarConfig());
        }
      )
    );
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<FaqItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.subscriptionRefs.push(database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    }));
  }

  async ngOnInit() {
      const msgs = await this.pcMessageService.getUserMessages().pipe(first()).toPromise();
      this.userMessages = msgs.data.filter(m => m.label !== null && m.ihmType !== 'B').map( e => this.sanitizer.bypassSecurityTrustHtml(e.label));
  }

  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
  }

  getLevel = (node: FaqItemFlatNode) => node.level;

  isExpandable = (node: FaqItemFlatNode) => node.expandable;

  getChildren = (node: FaqItemNode): FaqItemNode[] => node.children;

  hasChild = (_: number, nodeData: FaqItemFlatNode) => nodeData.expandable;

  hasNoContent = (_: number, nodeData: FaqItemFlatNode) => nodeData.item === '';

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: FaqItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.item === node.item
        ? existingNode
        : new FaqItemFlatNode();
    flatNode.item = node.item;
    flatNode.contact = node.contact;
    flatNode.level = level;
    flatNode.expandable = !!node.children;
    flatNode.control = new FormControl('', Validators.maxLength(level === 0 ? 100 : (level === 1 ? 250 : 1000)));
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  /* Get the parent node of a node */
  getParentNode(node: FaqItemFlatNode): FaqItemFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /** Select the category so we can insert the new item. */
  addNewItem(node: FaqItemFlatNode) {
    const parentNode = this.flatNodeMap.get(node);
    this.database.insertItem(parentNode, '', node.level + 1);
    this.treeControl.expand(node);
  }

  /** Save the node to database */
  saveNode(node: FaqItemFlatNode) {
    /*if(!this.checkSizes(node, node['tmp'])) {
      return;
    }*/
    const nestedNode = this.flatNodeMap.get(node);
    this.database.updateItem(nestedNode, node.tmp, node.level === 0 ? node.contact : null);
  }

  toggleEdit(node: FaqItemFlatNode) {
    /*if(!this.checkSizes(node, node.item)) {
      return;
    }*/
    node.edit = !node.edit;
    if (!node.edit) {
      const nestedNode = this.flatNodeMap.get(node);
      nestedNode.item = node.item;
      if (node.level === 0) {
        nestedNode.contact = node.contact;
      }
      // this._database.updateItem(nestedNode!, node.item, node.level === 0 ? node.contact : null);
    }
  }
/*
  checkSizes(node: FaqItemFlatNode, value: string) {
    if (node.level===0 && value?.length > 100) {
      this.popErrorMessage(this.translate.instant('core.faq.exceed_size', {size: value.length, maxSize: 100}));
      return false;
    }
    if (node.level===1 && value?.length > 250) {
      this.popErrorMessage(this.translate.instant('core.faq.exceed_size', {size: value.length, maxSize: 250}));
      return false;
    }
    if (node.level===2 && value?.length > 1000) {
      this.popErrorMessage(this.translate.instant('core.faq.exceed_size', {size: value.length, maxSize: 1000}));
      return false;
    }
    return true;
  }
*/
  async deleteNode(node: FaqItemFlatNode) {
    if (await this.popConfirmMessage(this.translate.instant('core.faq.cfmdelete')).pipe(first()).toPromise()) {
      const nestedNode = this.flatNodeMap.get(node);
      const parentNode = this.flatNodeMap.get(this.getParentNode(node));
      this.database.deleteItem(nestedNode, parentNode);
    }
  }
  addTheme() {
    this.database.addRootItem();
  }

  popErrorMessage(msg: string) {
    this.dialog.open(DialogComponent, {
      data: {
        title: this.translate.instant('axle.error'),
        message: msg
      }
    });
  }

  popConfirmMessage(msg: string) {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'actions.confirm',
        message: msg,
        confirm: true
      }
    });
    return dialogRef.afterClosed();
  }

  getActionConfig(): ActionBarConfig {
    return {
      sectionTitle: 'core.faq.title',
      hasBreadcrumb: true,
      actions: this.hasFAQ02 ? [
        {
          label: 'Mode',
          callback: () => this.toggleEditMode(),
          tooltip: this.translate.instant(this.editMode ? 'core.faq.consult_mode' : 'core.faq.edit_mode'),
          color: 'primary',
          icon: this.editMode ? 'visibility' : 'edit',
          icon_class: 'material-icons icons-29',
          class: 'd-none d-md-block'
        },
        {
          label: 'Save',
          callback: () => this.save(),
          tooltip: this.translate.instant('actions.save'),
          color: 'primary',
          icon: 'save',
          icon_class: 'material-icons icons-29',
          class: 'd-none d-md-block'
        }
      ] : undefined
    };
  }

  getControlBarConfig(): ControlBarConfig {
    /** Control bar configuration */
    return {
      items: this.hasFAQ02 ? [
        { label: this.editMode ? 'core.faq.consult_mode' : 'core.faq.edit_mode', callback: () => this.toggleEditMode()},
        { label: 'actions.save', callback: () => this.save()}
      ] : undefined
    };
  }

  toggleEditMode() {
    if (this.editMode && !this.isValid()) {
      return;
    }
    this.editMode =! this.editMode;
    this.barConfigService.sendConfig(this.getActionConfig(), this.getControlBarConfig());
  }

  async save() {
    if (!this.isValid()) {
      return;
    }
    this.isLoading = true;
    await this.database.save();
    this.isLoading = false;
    this.dialog.open(DialogComponent, {
      data: {
        title: 'core.faq.info',
        message: 'core.faq.saved'
      }
    });
  }

  isValid(): boolean {
    if (this.dataSource._flattenedData.value.some( n => n.edit || n.tmp)) {
      this.popErrorMessage('core.faq.wip');
      return false;
    }
    if (this.dataSource._flattenedData.value.some( n => !n.item)) {
      this.popErrorMessage('core.faq.void');
      return false;
    }
    return true;
  }

  sanitize(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
