import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from "moment";
import { BehaviorSubject, Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TemplatePopupDialogComponent } from 'src/app/shared/components/template-popup-dialog/template-popup-dialog.component';
import { UtilityService } from 'src/app/shared/services/Utility/utility.service';
import { TechSpecParticularities } from "../../../../../../models/tech-spec-particularities";
import { WagonTechnicalData } from "../../../../../../models/wagon-technical-data";
import { MonitoringWorkService } from "../../../../../../services/monitoring-work/monitoring-work.service";


@Component({
  selector: 'app-open-wagon-particularities',
  templateUrl: './open-wagon-particularities.component.html',
  styleUrls: ['./open-wagon-particularities.component.scss']
})
export class OpenWagonParticularitiesComponent implements OnInit, OnDestroy {

  @Input() public wagonTechnicalData: WagonTechnicalData;
  @Output() modifiedDataEvent = new EventEmitter();

  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  particularitiesForm: FormGroup;
  dateCtrl: FormControl;
  originalDiagramCtrl: FormControl;
  actualDiagramCtrl: FormControl;
  constructionUsageCtrl: FormControl;
  unloadingCtrl: FormControl;

  techSpecParticularities: TechSpecParticularities;

  dateToday = new Date();
  modifSub: Subscription;
  alert: boolean;
  constructor(private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private monitoringWorkService: MonitoringWorkService,
              private dialog: MatDialog) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);

    this.dateCtrl = this.formBuilder.control(new Date());
    this.originalDiagramCtrl = this.formBuilder.control(null, Validators.maxLength(16));
    this.actualDiagramCtrl = this.formBuilder.control(null, [Validators.required, Validators.maxLength(16)]);
    this.constructionUsageCtrl = this.formBuilder.control(null);
    this.unloadingCtrl = this.formBuilder.control(null);
    this.particularitiesForm = this.formBuilder.group({
      date: this.dateCtrl,
      originalDiagram: this.originalDiagramCtrl,
      actualDiagram: this.actualDiagramCtrl,
      constructionUsage: this.constructionUsageCtrl,
      unloading: this.unloadingCtrl
    });
    
    if (this.modifSub && !this.modifSub.closed) {
      this.modifSub.unsubscribe();
    }
    this.modifSub = this.particularitiesForm.valueChanges.subscribe(
      _ => this.modifiedDataEvent.emit(this.particularitiesForm.dirty));

    this.loadInitDatas();
  }

  /**
   * Reset les donnes
   */
  resetDatas(): void {
    this.dateCtrl.setValue(this.techSpecParticularities.realConstruction);
    this.originalDiagramCtrl.setValue(this.techSpecParticularities.additionalCounters ? this.techSpecParticularities.additionalCounters.originalDiagram : null);
    this.actualDiagramCtrl.setValue(this.techSpecParticularities.actualDiagram);
    this.constructionUsageCtrl.setValue(this.techSpecParticularities.constructionUsage?this.techSpecParticularities.constructionUsage.code:null);
    this.unloadingCtrl.setValue(this.techSpecParticularities.unloading?this.techSpecParticularities.unloading.code:null);

    const tmajCode = this.techSpecParticularities.wagonUpdate.type.code;
    // RI_MAJ_003_4_6_1 
    if (!(tmajCode === "00" || tmajCode === "01")) {
      this.dateCtrl.disable();
    }
    // RI_MAJ_003_4_6_2
    if (tmajCode !== "01") {
      this.originalDiagramCtrl.disable();
    }
    // RI_MAJ_003_4_6_3
    if (!(tmajCode === "00" || tmajCode === "01" || tmajCode === "03" || tmajCode === "05")) {
      this.actualDiagramCtrl.disable();
    }
    // RI_MAJ_003_4_6_4
    if (this.techSpecParticularities.wagonTechnicalDataDetail
       && this.techSpecParticularities.wagonTechnicalDataDetail.tranche
       && this.techSpecParticularities.wagonTechnicalDataDetail.tranche.technicalData.trpConstUtil === "0") {
      this.constructionUsageCtrl.setValue(null);
      this.constructionUsageCtrl.disable();
    }
    // RI_MAJ_003_4_6_5
    if (this.techSpecParticularities.wagonTechnicalDataDetail
      && this.techSpecParticularities.wagonTechnicalDataDetail.tranche
      && this.techSpecParticularities.wagonTechnicalDataDetail.tranche.technicalData.trpDechargement === "0") {
      this.unloadingCtrl.setValue(null);
      this.unloadingCtrl.disable();
    }
    this.particularitiesForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }

  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
    this.subscriptionRefs.push(
      this.monitoringWorkService.loadParticularitiesPageDatas(this.wagonTechnicalData.wagon.id)
        .subscribe(update => {
          this.techSpecParticularities = update.data;

          if (this.techSpecParticularities.constructionUsages) {
            this.techSpecParticularities.constructionUsages.forEach(f => f['codeLabel'] = f.code + ' - ' + f.label);
          }
          if (this.techSpecParticularities.unloadings) {
            this.techSpecParticularities.unloadings.forEach(f => f['codeLabel'] = f.code + ' - ' + f.label);
          }

          this.resetDatas();
          this.loadingSubject.next(false);
        })
    );
  }

  /**
   * Methode to submit the form
   */
  async onSubmit(alert?: boolean): Promise<void> {
    this.alert = alert === undefined ? true : alert;
    this.techSpecParticularities.wagonId = this.wagonTechnicalData.wagon.id;

    this.techSpecParticularities.realConstruction = this.dateCtrl.value;
    if (this.techSpecParticularities.additionalCounters) {
      this.techSpecParticularities.additionalCounters.originalDiagram = this.originalDiagramCtrl.value;
    }
    this.techSpecParticularities.actualDiagram = this.actualDiagramCtrl.value;
    this.techSpecParticularities.constructionUsage = this.techSpecParticularities.constructionUsages.filter(w => w.code === this.constructionUsageCtrl.value)[0];
    this.techSpecParticularities.unloading = this.techSpecParticularities.unloadings.filter(w => w.code === this.unloadingCtrl.value)[0];

    this.loadingSubject.next(true);
    const TechSpecParticularities = (await this.monitoringWorkService.updateTechSpecParticularities(this.techSpecParticularities).pipe(first()).toPromise()).data;
    this.loadingSubject.next(false);
    this.updateDone();
    this.particularitiesForm.markAsPristine();
    this.modifiedDataEvent.emit(false);
  }

  /**
   * Get the date of DatePicker control
   * @param ctrl
   */
  getDateFromCtrl(ctrl: AbstractControl): string | Date {
    return typeof ctrl.value === 'object' && ctrl.value !== null
      ? (ctrl.value as Moment).toISOString(true) : new Date(ctrl.value);
  }

  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy(): void {
    this.subscriptionRefs.forEach((s) => {
      if (s && !s.closed) {
        s.unsubscribe();
      }
    });
    this.loadingSubject.complete();
  }

   /**
  * Popup message. Closed after 5 seconds.
  */
    private updateDone() {
      this.ngOnInit();
      if (this.alert) {
        const dialogConfig = new MatDialogConfig();      
        dialogConfig.disableClose = true;
        const message = this.translateService.instant('wagon.technical.update-done.msg');      
        const timeout = UtilityService.UPDATE_DONE_TIMEOUT;      
        dialogConfig.data = {
          namePopUp: 'update-done-alert',
          titlePopUp: message,
          msgPopUp: message
        };      
        const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
        dialogRef.afterOpened().subscribe(_ => {
            setTimeout(() => {
            dialogRef.close();
            this.dialog.closeAll();
            }, timeout)
        });
      }
  }

}
