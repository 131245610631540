import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { LoggerService } from '../logging/logger.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  constructor(private logger: LoggerService) {
    this.logger = logger.newLogger('http');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.logger.useGroup(false);
    console.group('http');
    this.logger.debug('{} {}', request.method, request.url);
    const started = Date.now();
    return next.handle(request)
      .pipe(tap(event => {
        if (event instanceof HttpResponse) {
          const elapsed = Date.now() - started;
          this.logger.debug('{}ms - {} {}', elapsed, event.status, event.statusText, event.body);
          console.groupEnd();
          this.logger.useGroup(true);
        }
      }));
  }
}
