import { Components } from './components';
import { Job } from './job';
import { Defect } from './defect';
import { StateJob } from './state-job';

/** API model:Table: R_WPHY_TCH_MAJ  */
export class MaintenancePlanUpdate {
  /** WTCHMAJ_WPHY_ID_PFK	NUMBER(10,0) */
  wagonId: number;
  /** WTCHMAJ_TCH_ORG_CODE_PFK	VARCHAR2(4 BYTE) */
  component: Components;
  /** WTCHMAJ_TCH_TRA_CODE_PFK	VARCHAR2(3 BYTE) */
  job: Job;
  /** WTCHMAJ_DEF_CODE_PFK	VARCHAR2(3 BYTE) */
  defect: Defect;
  /** WTCHMAJ_NBR	NUMBER(3,0) */
  amount: number;
  /** WTCHMAJ_ETAT_CODE_FK	NUMBER(1,0) */
  state: StateJob;

}
