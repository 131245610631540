import { BumpOrgan } from './bump-organ';
import { WagonLocation } from './wagon-location';
import { Inventory } from './inventory';

/** API model:Table: R_WCAR_TAMP */
export class WagonBumpOrgan {
    /** WTAMP_WCAR_MEX_PFK VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = WTAMP_WCAR_INV_CODE_PFK VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** bumpOrgan.code = WTAMP_TAMP_CODE_PFK VARCHAR2(2 BYTE) */
    bumpOrgan: BumpOrgan;
    /** location.code = WTAMP_EMPL_CODE_FK VARCHAR2(3 BYTE) */
    location: WagonLocation;
    /** WTAMP_NBRE NUMBER(3,0) */
    amount: number;
}
