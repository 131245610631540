import { SpecialAgreement } from './special-agreement';
import { LineCategory } from './line-category';
import { TransitRegime } from './transit-regime';
import { Inventory } from './inventory';

/** API model:Table: REGIME_DEROG */
export class DerogationSystem {
    /** RDERO_WCAR_MEX_PFK VARCHAR2(14 BYTE) */
    mex: string;
    /** inventory.code = RDERO_WCAR_INV_CODE_PFK VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** to_number(RDERO_VALEUR_CHARGE_MAX) / 10 */
    maximumLoad: number;
    /** specialAgreement.code =	RDERO_ACSPE_CODE_PFK VARCHAR2(2 BYTE) */
    specialAgreement: SpecialAgreement;
    /** lineCategory.code = RDERO_CLIG_CODE_PFK VARCHAR2(5 BYTE) */
    lineCategory: LineCategory;
    /** transitRegime.code = RDERO_RACH_CODE_PFK VARCHAR2(5 BYTE) */
    transitRegime: TransitRegime;
}
