import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { finalize } from "rxjs/operators";
import { CertificationCompleted } from 'src/app/shared/models/certification-completed';
import { CertificationService } from 'src/app/shared/services/certification/certification.service';
import { DealService } from "../../../../modules/update/deal/services/deal.service";
import { DialogComponent } from "../../dialog/dialog.component";

@Component({
  selector: 'app-ares-dialog',
  templateUrl: './ares-dialog.component.html',
  styleUrls: ['./ares-dialog.component.scss']
})
export class AresDialogComponent {

  /** loading when work on page */
  isLoading = false;
  wagonId: number;
  mex: string;
  certificationCompleted: CertificationCompleted;
  mMessageBT: number;
  mMessageARES: number;
  mValidARES: number;
  mBtId: number;
  mNoticeId: number;
  mDevisIdExp: number;
  mDevisIdClient: number;
  mEtbIdSaisi: number;

  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dealService: DealService,
    private certificationService: CertificationService
  ) {
      this.wagonId = data.wagonId;
      this.mex = data.mex;
      this.certificationCompleted = data.certificationCompleted;
      this.mMessageBT = this.certificationCompleted.mMessageBT;
      this.mMessageARES = this.certificationCompleted.mMessageARES;
      this.mValidARES = this.certificationCompleted.mValidARES;
      this.mBtId = this.certificationCompleted.mBtId;
      this.mNoticeId = this.certificationCompleted.mNoticeId;
      this.mDevisIdExp = this.certificationCompleted.mDevisIdExp;
      this.mDevisIdClient = this.certificationCompleted.mDevisIdClient;
      this.mEtbIdSaisi = this.certificationCompleted.mEtbIdSaisi;
  }

  /**
   * Envoyer ARES
   */
  sendMail(): void {
    this.isLoading = true;
    this.certificationService.sendMailARES(this.wagonId, this.mNoticeId)
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(res => {
        if (res) {
          // nothing
        }
      });
  }

  downloadFile(): void {
    this.isLoading = true;
    this.dealService.downloadRecomReport([this.mNoticeId])
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(
        (report) => {
          saveAs(report.blob, report.filename);
        }
      );
  }
}
