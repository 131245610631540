/** API model:Table: HISTO_EVT */
export class EventsHistory {

    /** HEVT_ID_PK NUMBER(10,0) */
    id: number;
    /** HEVT_DH_EVT DATE */
    dateEvt: Date;
    /** HEVT_DH_SYST DATE */
    dateSyst: Date;
    /** HEVT_MEX VARCHAR2(14 BYTE) */
    mex: string;
    /** HEVT_SSEVT_CODE_FK VARCHAR2(2 BYTE) */
    sSevtCode: string;
    /** HEVT_WPHY_ID_FK NUMBER(10,0) */
    wphy: number;
}
