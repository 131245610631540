import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AxleElement } from 'src/app/shared/models/axle-element';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-select-axle-dialog',
  templateUrl: './select-axle-dialog.component.html',
  styleUrls: ['./select-axle-dialog.component.scss']
})
export class SelectAxleDialogComponent implements OnInit {  
  /** langage du navigateur */
  lang : string;

  /** index de l'essieu selectionne par l'utilisateur */
  selectedAxleIndex : number = null;

  ngOnInit(): void {
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public axleElements: AxleElement[],
    private translateService: TranslateService) {
      this.lang = this.translateService.getBrowserLang().match(/en|fr/)
                ? this.translateService.getBrowserLang() : 'en';
  }

  selectRadio(n: number){
    this.selectedAxleIndex = n;
  }

}
