import { ConstructionType } from './construction-type';
import { ConveyedProduct } from './conveyed-product';
import { DerogationSystem } from './derogation-system';
import { Envelope } from './envelope';
import { Inventory } from './inventory';
import { NoRIV } from './no-riv';
import { Rid } from './rid';
import { SpecialAgreement } from './special-agreement';
import { StatisticalCode } from './statistical-code';
import { Tranche } from './tranche';
import { UsageRestriction } from './usage-restriction';
import { WagonBearing } from './wagon-bearing';
import { WagonBrake } from './wagon-brake';
import { WagonBumpTraction } from './wagon-bump-traction';
import { WagonFrameSuspension } from './wagon-frame-suspension';
import { WagonParticularities } from './wagon-particularities';
import { WagonSuperstructure } from './wagon-superstructure';

/** API model:Table: WAGON_CARACT */
export class WagonTechnicalDataDetail {
// Technique
    /** WCAR_WLOG_MEX_PFK VARCHAR2(14 BYTE) */
    mex: string;
    /** WCAR_WLOG_INV_CODE_PFK VARCHAR2(2 BYTE) */
    inventory: Inventory;
    /** Cf. WagonSuperstructure for this record of WAGON_CARACT */
    superstructure: WagonSuperstructure;
    /** Cf. WagonBearing for this record of WAGON_CARACT */
    bearing: WagonBearing;
    /** Cf. WagonBrake for this record of WAGON_CARACT */
    brake: WagonBrake;
    /** Cf. WagonBumpTraction for this record of WAGON_CARACT */
    bumpTraction: WagonBumpTraction;
    /** Cf. WagonFrameSuspension for this record of WAGON_CARACT */
    frameSuspension: WagonFrameSuspension;
    /** Cf. WagonParticularities for this record of WAGON_CARACT */
    particularities: WagonParticularities;
// Exploitation
   // Tableau des charges
    /** WCAR_TARE NUMBER(10,0) */
    tare: number;
    /** envelope.id = WCAR_ENVTC_ID_FK NUMBER(5,0) */
    envelope: Envelope;
    /**     WAGON_CARACT.WCAR_WLOG_MEX_PFK= REGIME_DEROG.RDERO_WCAR_MEX_PFK
     *  AND WAGON_CARACT.WCAR_WLOG_INV_CODE_PFK= REGIME_DEROG.RDERO_WCAR_INV_CODE_PFK
     */
    derogationSystemList: DerogationSystem [];
    /** WCAR_LICHEX VARCHAR2(4 BYTE) */
    exceptionalLoadingLimit: number;
    /** WCAR_VIMAX NUMBER(5,0) */
    maximumSpeed: number;
    /** WCAR_VILIV NUMBER(5,0) */
    passengerTrainMaximumSpeed: number;
    /* tslint:disable:variable-name */
    /** WCAR_CH1_A VARCHAR2(4 BYTE) */
    lineValue1A: number;
    /** WCAR_CH1_B1 VARCHAR2(4 BYTE) */
    lineValue1B1: number;
    /** WCAR_CH1_B2 VARCHAR2(4 BYTE) */
    lineValue1B2: number;
    /** WCAR_CH1_C2 VARCHAR2(4 BYTE) */
    lineValue1C2: number;
    /** WCAR_CH1_C3 VARCHAR2(4 BYTE) */
    lineValue1C3: number;
    /** WCAR_CH1_C4 VARCHAR2(4 BYTE) */
    lineValue1C4: number;
    /** WCAR_CH1_D2 VARCHAR2(4 BYTE) */
    lineValue1D2: number;
    /** WCAR_CH1_D3 VARCHAR2(4 BYTE) */
    lineValue1D3: number;
    /** WCAR_CH1_D4 VARCHAR2(4 BYTE) */
    lineValue1D4: number;
    /** WCAR_CH2_C VARCHAR2(4 BYTE) */
    lineValue2C: number;
    /** WCAR_CH2_D VARCHAR2(4 BYTE) */
    lineValue2D: number;
    /** WCAR_CH3_C VARCHAR2(4 BYTE) */
    lineValue3C: number;
    /** WCAR_CH3_D VARCHAR2(4 BYTE) */
    lineValue3D: number;
    /* tslint:enable:variable-name */
    // Famille-restrictions
    /** WCAR_CR_CODE_FK VARCHAR2(3 BYTE) */
    divisionCode: string;
    /** statisticalCode.code = WCAR_CS_CODE_FK VARCHAR2(3 BYTE) */
    statisticalCode: StatisticalCode;
    /** constructionType.code = WCAR_TC_CODE_FK VARCHAR2(1 BYTE) */
    constructionType: ConstructionType;
    /** noRIV.code = WCAR_NRIV_CODE_FK VARCHAR2(2 BYTE) */
    noRIV: NoRIV;
    /** specialAgreement.code = WCAR_ACSPE_CODE_FK VARCHAR2(2 BYTE) */
    specialAgreement: SpecialAgreement;
    /** usageRestriction.code = WCAR_RUTIL_CODE_FK VARCHAR2(2 BYTE) */
    usageRestriction: UsageRestriction;
    // Condex
    /** WCAR_TP_DH_APPLIC DATE */
    ownerApplication: Date;
    /** WCAR_LO_DH_APPLIC DATE */
    tenantApplication: Date;
    /** WCAR_GE_DH_APPLIC DATE */
    managerApplication: Date;
    /** WCAR_GT_DH_APPLIC DATE */
    ecmApplication: Date;
    /** conveyedProduct.code = WCAR_PDT_CODE_FK VARCHAR2(4 BYTE) */
    conveyedProduct: ConveyedProduct;
    /** WCAR_PDT_DH_APPLIC DATE */
    conveyedProductApplication: Date;
    /** rid.code = WCAR_RID_CODE_FK VARCHAR2(7 BYTE) */
    rid: Rid;
    /** tranche.id = WCAR_TR_ID_FK NUMBER(10,0) */
    tranche: Tranche;
}
