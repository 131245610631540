import { Injectable } from '@angular/core';
import { AppApiService } from '../../../../core/services/app-api/app-api.service';
import { ApiResponse } from '../../../../core/services/app-api/api-response.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AxleElement } from '../../../../shared/models/axle-element'; 
import { AxleIntervention } from '../../../../shared/models/axle-intervention'; 
import { AxleSideElement } from '../../../../shared/models/axle-side-element';
import { AxleElementDamage } from '../../../../shared/models/axle-element-damage';
import { PoseHistory } from '../../../../shared/models/pose-history';
import { AxleStateHistory } from '../../../../shared/models/axle-state-history';
import { AxleInterventionHistory } from '../../../../shared/models/axle-intervention-history';
import { LogicalWagon } from 'src/app/shared/models/logical-wagon';
import { AxleType } from 'src/app/shared/models/axle-type';
import { TemplatePortal } from '@angular/cdk/portal';
import { Pageable } from '../../../../core/services/app-api/pageable.model';
import { HttpParams } from '@angular/common/http';
import { AxleMeasureHistory } from '../../../../shared/models/axle-measure-history';

/**
 * `AxleService` is responsible for retrieve axle related datas through API calls
 */
@Injectable({
    providedIn: 'root'
  })
  export class AxleService {

    /** Subject for axle change monitoring */
    private axleIdSubject = new BehaviorSubject<number>(undefined);
    /** Subject for axle display change monitoring */
    private displayStatusSubject = new BehaviorSubject<boolean>(false);
    /** Subject for axle portal change monitoring */
    private axleTemplates = new BehaviorSubject<TemplatePortal<any>[]>(null);

    private sharedSubject = new BehaviorSubject<{axle: AxleElement}>(undefined);

    /** Subject for axle change monitoring */
    private axleSubject = new BehaviorSubject<AxleElement>(undefined);
    /** Subject for damage change monitoring */
    private damageSubject = new BehaviorSubject<AxleElementDamage>(undefined);
    /** Subject for side Gauche change monitoring */
    private sideGSubject = new BehaviorSubject<AxleSideElement>(undefined);
    /** Subject for side Droite change monitoring */
    private sideDSubject = new BehaviorSubject<AxleSideElement>(undefined);
    /** Subject for wagon change monitoring */
    private wagonSubject = new BehaviorSubject<LogicalWagon>(undefined);
    /** Subject for intervention change monitoring */
    private interventionSubject = new BehaviorSubject<AxleIntervention>(undefined);

    /**
     * AxleService constructor
     * @param api Inject Api service to manage http calls
     */
    constructor(private api: AppApiService) {  }

    /**
     * Set current damage
     * @param damage the damage
     */
    public setCurrentDamage(damage: AxleElementDamage): void {
      this.damageSubject.next(damage);
    }
    /**
     * Retrieve current damage
     * @returns damage as `BehaviorSubject`
     */
    public getCurrentDamage(): BehaviorSubject<AxleElementDamage> {
      return this.damageSubject;
    }

    /**
     * Set current side Gauche
     * @param side the side gauche
     */
    public setCurrentSideG(side: AxleSideElement): void {
      this.sideGSubject.next(side);
    }
    /**
     * Retrieve current side Gauche
     * @returns side as `BehaviorSubject`
     */
    public getCurrentSideG(): BehaviorSubject<AxleSideElement> {
      return this.sideGSubject;
    }

    /**
     * Set current side droite
     * @param side the side gauche
     */
    public setCurrentSideD(side: AxleSideElement): void {
      this.sideDSubject.next(side);
    }
    /**
     * Retrieve current side Droite
     * @returns side as `BehaviorSubject`
     */
    public getCurrentSideD(): BehaviorSubject<AxleSideElement> {
      return this.sideDSubject;
    }

    /**
     * Set current wagon
     * @param wagon the wagon
     */
    public setCurrentWagon(wagon: LogicalWagon): void {
      this.wagonSubject.next(wagon);
    }
    /**
     * Retrieve current side Droite
     * @returns side as `BehaviorSubject`
     */
    public getCurrentWagon(): BehaviorSubject<LogicalWagon> {
      return this.wagonSubject;
    }

    /**
     * Set current Intervention
     * @param intervention the intervention
     */
    public setCurrentIntervention(intervention: AxleIntervention): void {
      this.interventionSubject.next(intervention);
    }
    /**
     * Retrieve current side Droite
     * @returns side as `BehaviorSubject`
     */
    public getCurrentIntervention(): BehaviorSubject<AxleIntervention> {
      return this.interventionSubject;
    }


    /**
     * Set current axle
     * @param axle the axle
     */
    public setCurrentAxle(axle: AxleElement): void {
      this.axleSubject.next(axle);
    }
    /**
     * Retrieve current axle
     * @returns axle as `BehaviorSubject`
     */
    public getCurrentAxle(): BehaviorSubject<AxleElement> {
      return this.axleSubject;
    }

    /**
     * Set current axle's id
     * @param id the axle's id
     */
    public setCurrentAxleId(id: number): void {
      this.axleIdSubject.next(id);
      this.sharedSubject.next({axle: null});
    }
    /**
     * Retrieve current axle's id
     * @returns axle's id as `BehaviorSubject`
     */
    public getCurrentAxleId(): BehaviorSubject<number> {
      return this.axleIdSubject;
    }
    /**
     * Set axle datas
     */
    public setSharedDatas(axle: AxleElement): void {
      this.sharedSubject.next({axle: axle});
    }
    /**
     * Retrieve axle datas
     * @returns current axle as `BehaviorSubject`
     */
    public getSharedDatas(): BehaviorSubject<{axle: AxleElement}> {
      return this.sharedSubject;
    }
    /**
     * Set current axle component display status
     * @param show the axle's display status
     */
    public setDisplayStatus(show: boolean): void {
      this.displayStatusSubject.next(show);
    }
    /**
     * Retrieve current axle component display status
     * @returns axle's display status as `Observable`
     */
    public getDisplayStatus(): Observable<boolean> {
      return this.displayStatusSubject.asObservable();
    }
    /**
     * Set current axle component page specifics element
     * @param templates templates portals to inject
     */
    public setCurrentPortalTemplates(templates: TemplatePortal<any>[]): void {
      this.axleTemplates.next(templates);
    }
    /**
     * Retrieve current page specifics element to inject
     * @returns templates portals as `Observable`
     */
    public getCurrentPortalTemplates(): Observable<TemplatePortal<any>[]> {
      return this.axleTemplates.asObservable();
    }
    /**
     * Retrieve axle-element with given id
     * @param id the axle's id
     * @returns encapsulated axleElement datas as `Observable`
     */
    public get(id: number): Observable<ApiResponse<AxleElement>> {
      return this.api.get<ApiResponse<AxleElement>>(`/axles/elements/${id}`)
      .pipe(
        catchError(this.handleError<any>('get', {data: null}))
      );
    }

    /**
     * Retrieve information on the sides of the axle
     * @param id the axle's id
     * @param side the side of the axle
     * @returns encapsulated SideAxleElement datas as `Observable`
     */
    public findSideAxleElement(id: number, side: string): Observable<ApiResponse<AxleSideElement[]>> {
      return this.api.get<ApiResponse<AxleSideElement[]>>(`/axles/elements/${id}/side-elements?side=${side} `)
      .pipe(
        catchError(this.handleError<any>('get', {data: []}))
      );
    }

    /**
     * Retrieve intial damage report
     * @param id the axle's id
     * @param type the type
     * @returns encapsulated SideAxleElement datas as `Observable`
     */
    public findDamageAxleElement(id: number, type: string): Observable<ApiResponse<AxleElementDamage>> {
      return this.api.get<ApiResponse<AxleElementDamage>>(`/axles/elements/${id}/damage-element?type=${type} `)
      .pipe(
        catchError(this.handleError<any>('findDamageAxleElement', {data: null}))
      );
    }

    /**
     * Retrieve information of the intervention label
     * @param code the code of the intervention
     * @returns encapsulated AxleIntervention datas as `Observable`
     */
    public findAxleIntervention(code: string): Observable<ApiResponse<AxleIntervention>> {
      return this.api.get<ApiResponse<AxleIntervention>>(`/references/axle-interventions/${code} `)
      .pipe(
        catchError(this.handleError<any>('findAxleIntervention', {data: null}))
      );
    }

    /**
     * Retrieve types of axle
     * @returns encapsulated AxleType[] datas as `Observable`
     */
    public findAxleTypes(): Observable<ApiResponse<AxleType[]>> {
      return this.api.get<ApiResponse<AxleType[]>>(`/references/axle-types`)
      .pipe(
        catchError(this.handleError<any>('findAxleTypes', {data: null}))
      );
    }

    /**
     * Retrieve a list of axle with an axis number and a type
     * @param num the axis number of the axle
     * @param type the type of axle
     * @returns encapsulated AxleElement[] datas as `Observable`
     */
    public findAxleByNumAndType(num: string, type : string, pageable?: Pageable): Observable<ApiResponse<AxleElement[]>> {
      let parameters: HttpParams = new HttpParams();
      if (pageable !== undefined) {
        const pageParameters: HttpParams = pageable.build();
        pageParameters.keys().forEach(key => {
          parameters = parameters.set(key, pageParameters.get(key) );
        });
      }
      return this.api.get<ApiResponse<AxleElement[]>>(`/axles/axle-elements?axisnumber=${num}` + (type ? `&type=${type}` : ''),{ params: parameters })
      .pipe(
        catchError(this.handleError<any>('findAxleByNumAndType', {data: null}))
      );
    }

    /**
     * Retrieve an axle with a mex and a position
     * @param mex the axis number of the axle
     * @param pos the postition of the axle
     * @returns encapsulated AxleElement datas as `Observable`
     */
    public findAxleByMexAndPos(mex: string, pos : string, pageable?: Pageable): Observable<ApiResponse<AxleElement[]>> {
      let parameters: HttpParams = new HttpParams();
      if (pageable !== undefined) {
        const pageParameters: HttpParams = pageable.build();
        pageParameters.keys().forEach(key => {
          parameters = parameters.set(key, pageParameters.get(key) );
        });
      }
      return this.api.get<ApiResponse<AxleElement[]>>(`/axles/axle-elements?mex=${mex}&position=${pos}`,{ params: parameters })
      .pipe(
        catchError(this.handleError<any>('findAxleByMexAndPos', {data: null}))
      );
    }

    /**
     * Retrive a list of historic poses /deposited of an axle
     * @param id 
     */
    public findHistoricPosesAxle(id: number,  pageable?: Pageable): Observable<ApiResponse<PoseHistory[]>>{
      let parameters: HttpParams = new HttpParams();
      if (pageable !== undefined) {
        const pageParameters: HttpParams = pageable.build();
        pageParameters.keys().forEach(key => {
          parameters = parameters.set(key, pageParameters.get(key) );
        });
      }
      return this.api.get<ApiResponse<PoseHistory[]>>(`/axles/elements/${id}/pose-history`,{ params: parameters })
      .pipe(
        catchError(this.handleError<any>('findHistoricPosesAxle', {data: []}))
      );
    }

    /**
     * Retrive a list of info of historic states
     * @param id 
     */
    public findHistoricStatesAxle(id: number,  pageable?: Pageable): Observable<ApiResponse<AxleStateHistory[]>>{
      let parameters: HttpParams = new HttpParams();
      if (pageable !== undefined) {
        const pageParameters: HttpParams = pageable.build();
        pageParameters.keys().forEach(key => {
          parameters = parameters.set(key, pageParameters.get(key) );
        });
      }
      return this.api.get<ApiResponse<AxleStateHistory[]>>(`/axles/elements/${id}/state-history`,{ params: parameters })
      .pipe(
        catchError(this.handleError<any>('findHistoricStatesAxle', {data: []}))
      );
    }

    /**
     * Retrive a list of info of historic interventions Axle
     * @param id 
     */
    public findHistoricInterventionsAxle(id: number,  pageable?: Pageable): Observable<ApiResponse<AxleInterventionHistory[]>>{
      let parameters: HttpParams = new HttpParams();
      if (pageable !== undefined) {
        const pageParameters: HttpParams = pageable.build();
        pageParameters.keys().forEach(key => {
          parameters = parameters.set(key, pageParameters.get(key) );
        });
      }
      return this.api.get<ApiResponse<AxleInterventionHistory[]>>(`/axles/elements/${id}/max-intervention-history`,{ params: parameters })
      .pipe(
        catchError(this.handleError<any>('findHistoricInterventionsAxle', {data: []}))
      );
    }

    /**
     * Retrive a list of info of historic interventions Axle
     * @param id 
     */
    public findHistoricMeasuresAxle(id: number,  pageable?: Pageable): Observable<ApiResponse<AxleMeasureHistory[]>>{
      let parameters: HttpParams = new HttpParams();
      if (pageable !== undefined) {
        const pageParameters: HttpParams = pageable.build();
        pageParameters.keys().forEach(key => {
          parameters = parameters.set(key, pageParameters.get(key) );
        });
      }
      return this.api.get<ApiResponse<AxleMeasureHistory[]>>(`/axles/elements/${id}/measures`,{ params: parameters })
      .pipe(
        catchError(this.handleError<any>('findHistoricMeasuresAxle', {data: []}))
      );
    }

    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T> (operation, result?: T) {
        return (error: any): Observable<T> => {
        // TODO: send the error to remote logging infrastructure
        // console.error(operation, error); // log to console instead
        // TODO: better job of transforming error for user consumption
        // this.log(`${operation} failed: ${error.message}`);
        // Let the app keep running by returning an empty result.
        return of(result as T);
        };
    }
}
