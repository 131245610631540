import { Workshop } from './workshop';
import { InterventionReason } from './intervention-reason';
import { MaintenanceOperation } from './maintenance-operation';
import { MessageError } from './message-error';

/** API model:Table: EXPERTISE */
export class Expertise {
  /** EXP_ID_PK	NUMBER(10,0) */
  id: number;
  /** EXP_WLOG_MEX_FK	VARCHAR2(14 BYTE) */
  mex: string;
  /** EXP_DH_CREATION	DATE */
  creation: Date;
  /** workshop.code	= EXP_ETAB_CODE_UG_FK VARCHAR2(5 BYTE) */
  workshop: Workshop;
  /** maintenanceOperation.code = EXP_OPE_CODE_FK	VARCHAR2(6 BYTE) */
  maintenanceOperation: MaintenanceOperation;
  /** interventionReason.code	= EXP_MTFINTV_CODE_FK VARCHAR2(3 BYTE) */
  interventionReason: InterventionReason;
  /** EXP_HINTV_ID_FK NUMBER(10,0) */
  interventionId: number;
  /** EXP_BT_ID_FK	NUMBER(10,0) */
  quoteId: number;
  /** EXP_FLAG_CLOSE	1 = closed expertise	NUMBER(1,0) */
  isClose: boolean;
  /** EXP_NUAGECP_FK VARCHAR2(8 BYTE) */
  agentCp: string;
  /** EXP_DH_DERN_MAJ	DATE */
  lastUpdate: Date;
  /** EXP_VOIE VARCHAR2(5 BYTE) */
  track: string;
  /** EXP_POSITION VARCHAR2(5 BYTE) */
  position: string;
  /** EXP_OBSERVATION VARCHAR2(360 BYTE) */
  observation: string;
  /** EXP_WPHY_ID_FK NUMBER(10,0) */
  wagonId: number;

  messageError: MessageError;
}
