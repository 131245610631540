import { Overlay } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Subscription } from 'rxjs';
import { ComponentGroup } from 'src/app/shared/models/component-group';
import { Components } from 'src/app/shared/models/components';
import { Defect } from 'src/app/shared/models/defect';
import { Job } from 'src/app/shared/models/job';
import { JobDefect } from 'src/app/shared/models/job-defect';
import { MaintenancePlanUpdate } from 'src/app/shared/models/maintenance-plan-update';
import { PreparationWorkCamsFaults } from 'src/app/shared/models/preparation-work-cams-faults';
import { StateJob } from 'src/app/shared/models/state-job';
import { TTache } from 'src/app/shared/models/t-tache';
import { WagonUpdate } from 'src/app/shared/models/wagon-update';
import { MaintenancePlanUpdateService } from 'src/app/shared/services/maintenance-plan-update/maintenance-plan-update.service';
import { TemplatePopupDialogComponent } from '../../template-popup-dialog/template-popup-dialog.component';
import { TaskDialogComponent } from '../task-dialog/task-dialog.component';


@Component({
  selector: 'app-maintenance-plan-table',
  templateUrl: './maintenance-plan-table.component.html',
  styleUrls: ['./maintenance-plan-table.component.scss']
})
export class MaintenancePlanTableComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public wagonId: number;
  @Input() public isMonitoringWork: boolean;
  @Input() public toDoColor: string;
  @Input() public inProgressColor: string;
  @Input() public waitingColor: string;
  @Input() public realizedColor: string;
  @Input() public maintenancePlanList: MaintenancePlanUpdate[];  
  @Input() public taskDialogDatas: {
    stateJobs: StateJob[],
    componentGroups: ComponentGroup[],
    components: Components[],
    jobs: Job[],
    defects: Defect[],
    jobDefects: JobDefect[],
    tTaches: TTache[]
  };
  /** Update datas */
  @Input() public update: WagonUpdate;
  @Output() maintenancePlanTableUpdated = new EventEmitter<boolean>();

  /** get the Material sort component ref  for maintenance Plan*/
  @ViewChild('maintenancePlanTableSort', { static: true }) maintenancePlanSort: MatSort;
  /** Create an empty Material MatTable datasource of MaintenancePlan */
  dataSourceMaintenancePlan = new MatTableDataSource<MaintenancePlanUpdate>();
  displayedColumnsMaintenancePlan = [
    { def: 'group', hide: false },
    { def: 'component', hide: false },
    { def: 'componentLabel', hide: false },
    { def: 'job', hide: false },
    { def: 'defect', hide: false },
    { def: 'amount', hide: false },
    { def: 'state', hide: false },
    { def: 'edition', hide: false }
  ];
  /** Preparation Work Cams Faults Datas page */
  prepWorkCamsFaultsDatasPage: PreparationWorkCamsFaults;
  /** langage du navigateur */
  lang: string;
  /** Retain all subscriptions */
  private subscriptionRefs: Subscription[] = [];
  /** Subject to manage loading status */
  loadingSubject = new BehaviorSubject<boolean>(false);
  /** Maintenance Plan Update */
  listMaintenancePlan: MaintenancePlanUpdate[];
  

  constructor(

    private translateService: TranslateService,
    private dialog: MatDialog,
    private overlay: Overlay,
    private maintenancePlanUpdateService: MaintenancePlanUpdateService) {
    this.lang = this.translateService.getBrowserLang().match(/en|fr/)
      ? this.translateService.getBrowserLang() : 'en';
  }

  ngOnInit(): void {
    this.loadingSubject.next(true);
    this.loadInitDatas();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.maintenancePlanList && !changes.maintenancePlanList.firstChange) {
      this.ngOnInit();
    }
  }
  /**
   * Methode pour init datas
   */
  private loadInitDatas() {
    this.listMaintenancePlan = this.maintenancePlanList;
    this.dataSourceMaintenancePlan.data = this.listMaintenancePlan;
    this.loadingSubject.next(false);
  }

  /**
  * Methode pour init datas
  */
  public filterMaintanancePlanUpdate(filterStatusToDO: boolean, filterStatusInProgress: boolean, filterStatusWaiting: boolean, filterStatusRealized: boolean) {
    this.loadingSubject.next(true);
    this.listMaintenancePlan = this.maintenancePlanList ? this.maintenancePlanList.filter(e => {
      return (filterStatusToDO && e.state.code === '1') || (filterStatusInProgress && e.state.code === '2')
          || (filterStatusWaiting && e.state.code === '3') || (filterStatusRealized && e.state.code === '4');
    }) : undefined;
    this.dataSourceMaintenancePlan.data = this.listMaintenancePlan;
    this.loadingSubject.next(false);
  }


  /** OnDestroy hook is responsible for clear subscriptions */
  ngOnDestroy() {
    this.subscriptionRefs.forEach((s) => { if (s && !s.closed) { s.unsubscribe(); } });
    this.loadingSubject.complete();
  }

  /**
   * Methode pour ajouter un Maintenance
   * @param wagon
   */
  addMaintenancePlan() {
    const scrollStrat = this.overlay.scrollStrategies.reposition();
    const dialogRef = this.dialog.open(TaskDialogComponent 
      , {
        data: {mpu: {}, wagonId: this.wagonId, taskDialogDatas: this.taskDialogDatas},
        disableClose: true,
        scrollStrategy: scrollStrat
      });
    dialogRef.afterClosed().subscribe(confirm => {
      this.maintenancePlanTableUpdated.emit(true);
    });
  }

  /**
   * Methode pour faire la mise a jour d'un Plan Maintenance Update
   * @param pMpu : MaintenancePlanUpdate
   * @param isMonitoringWork : boolean
   */
  updateMaintenancePlan(pMpu: MaintenancePlanUpdate, isMonitoringWork?: boolean) {
    const scrollStrat = this.overlay.scrollStrategies.reposition();
    const dialogRef = this.dialog.open(TaskDialogComponent
      , {
        data: {mpu: pMpu, fromAddLine: isMonitoringWork, taskDialogDatas: this.taskDialogDatas },
        disableClose: true,
        scrollStrategy: scrollStrat,
        autoFocus: false
      });
    dialogRef.afterClosed().subscribe(confirm => {
      this.maintenancePlanTableUpdated.emit(confirm);
    });
  }

  getDisplayedColumns(): string[] {
    this.displayedColumnsMaintenancePlan[6].hide = !this.isMonitoringWork;
    this.displayedColumnsMaintenancePlan[7].hide = this.isMonitoringWork;
    return this.displayedColumnsMaintenancePlan
      .filter(cd => !cd.hide)
      .map(cd => cd.def);
  }


  /**
   * Methode pour supprimer un plan maintenance
   * @param mpu : MaintenancePlanUpdate
   */
  deleteMaintenancePlan(mpu: MaintenancePlanUpdate, monitoringWork: boolean) {

    if (monitoringWork) {
      this.subscriptionRefs.push(
        forkJoin(
          this.maintenancePlanUpdateService.existsCAMrelatedCAMseries(this.wagonId, mpu.job.code, mpu.component.code),
          this.maintenancePlanUpdateService.existsNonOrganeCAMassociatedTask(this.wagonId, mpu.job.code, mpu.component.code)
        ).subscribe(([carriedOutCAMs, nonOrganeCAMs]) => {
          const carriedCAM = carriedOutCAMs.data;
          const nonOrganeCAM = nonOrganeCAMs.data;

          if (carriedCAM && carriedCAM.length) {

            const message = this.translateService.instant('wagon-update.task.delete_task.msg_interrupted', { cam: carriedCAM[0].camCode });
            this.showAlertMessage(message);

          } else if (nonOrganeCAM && nonOrganeCAM.length) {

            const message = this.translateService.instant('wagon-update.task.delete_task.msg_interrupted', { cam: nonOrganeCAM[0].camCode });
            this.showAlertMessage(message);

          } else {
            this.deleteTask(mpu);
          }
        })
      );

    } else {
      this.deleteTask(mpu);
    }

  }

  /**
   * Methode pour supprimer un task
   * @param mpu : MaintenancePlanUpdate
   */
  deleteTask(mpu: MaintenancePlanUpdate) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '38.125rem';
    const title = this.translateService.instant('wagon-update.task.delete_task.title');
    const message = this.translateService.instant('wagon-update.task.delete_task.msg', { component: mpu.component.label, job: mpu.job.label });

    dialogConfig.data = {
      namePopUp: 'delete_task',
      titlePopUp: title,
      msgPopUp: message
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.subscriptionRefs.push(
          this.maintenancePlanUpdateService.deleteMaintenancePlanUpdate(mpu.wagonId, mpu.job.code, mpu.component.code, mpu.defect.code, mpu.amount)
            .subscribe(() => {
              this.maintenancePlanTableUpdated.emit(true);
              this.dialog.closeAll();
            }, (error) => console.log(error))
        );
      } else {
        this.maintenancePlanTableUpdated.emit(false);
      }
      this.dialog.closeAll();
    });
  }

  /**
   * Show Alert message
   * @param msg
   */
  showAlertMessage(msg: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.minWidth = '20rem';

    dialogConfig.data = {
      namePopUp: 'alert_msg',
      msgPopUp: msg
    };

    const dialogRef = this.dialog.open(TemplatePopupDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(confirm => {
      this.dialog.closeAll();
    });

  }
}


