/** API model:Table Cams Default */
export class CamsDefault {

    type: string;

    code: string;

    label: string;

    origin: string;

    originLabel: string;

    flagOrigin: number;

    checked: boolean;

}
